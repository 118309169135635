"use strict"

function exportVTT ( vttFiles ) {
    let
         filenames = Object.keys ( vttFiles )
       , result = {}
       ;

    filenames.forEach ( filename => {
                let 
                     records = vttFiles[filename]
                   , vtt = toVTT ( records )
                   ;
                result [ filename ] = vtt
       })
    return result
} // exportVTT func.


function toVTT ( records ) {
    let vtt = 'WEBVTT\n\n';
    records.forEach ( rec => {
            let { begin, end, label, txt, cueStyles } = rec;
            if ( label )   vtt += `${label}\n`
            vtt += setTimestamps ( begin, end, cueStyles )
            vtt += `${txt}\n\n`
        })
    return vtt.trim ()
} // toVTT func.



function setTimestamps ( begin, end, cueStyles ) {
    let
           startTime = new Date ( begin * 1000 ).toISOString().substr ( 11, 12 )
         , endTime   = new Date ( end   * 1000 ).toISOString().substr ( 11, 12 )
         ;
    return `${startTime} --> ${endTime} ${cueStyles}`.trim()+'\n'
} // setTimestamps func.



module.exports = exportVTT


