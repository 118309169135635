/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'node': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 8c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4c0 .4.1.7.2 1L7.9 9.2C7.1 8.5 6.1 8 5 8c-2.2 0-4 1.8-4 4s1.8 4 4 4c1.1 0 2.1-.5 2.9-1.2l7.3 4.2c-.1.3-.2.7-.2 1 0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4c-1.1 0-2.1.5-2.9 1.2L8.8 13c.1-.3.2-.6.2-1s-.1-.7-.2-1l7.3-4.2c.8.7 1.8 1.2 2.9 1.2z" _fill="#5c7499"/>'
  }
})
