/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-remove': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 5H12.5l-3-4H1a1 1 0 00-1 1v18a3 3 0 003 3h18a3 3 0 003-3V6a1 1 0 00-1-1zm-7 10H8v-2h8z" _fill="#002c65"/>'
  }
})
