<template>
<div class="vod-presets-settings-component">
  <ioio-modal
    v-if="isContentModalOpened"
    class=""
    :show.sync="isContentModalOpened"
    size="medium"
    headerClasses=""
  >
    <template slot="header" >
      <div class="pb-3">
        PRESET CONTENT
      </div>
    </template>
    <div>
      <h3 class="my-2">
        Name:
      </h3>
      <span class="text-sm text-black-600">
        {{presetContent.Name}}
      </span>
      <h3 class="mt-4 mb-2">
        Category:
      </h3>
      <span class="text-sm text-black-600">
        {{presetContent.Category}}
      </span>
      <h3 class="mt-4 mb-2">
        Description:
      </h3>
      <p class="text-sm text-black-600">
        {{presetContent.Description}}
      </p>
      <json-preview-component :jsonData="presetContent.Settings"/>      
    </div>
  </ioio-modal>
  <h5>
    Here are all the VOD presents available in your organisation. A VOD preset is a pre-defined collection of encoding parameters. Only administrators with the necessary permissions can update the preset list.
  </h5>
  <div class="flex items-center my-4 h-8">
    <span class="text-sm">Category:</span>
    <ioio-dropdown
      :items="possibleCategory"
      titleProp="name"
      valueProp="value"
      v-model="selectedCategory"
      :multiple="false"
      size="small"
      @input="searchByCategory"
      alignX="right"
      class="mr-4 ml-2"
    />
    <span class="text-sm">Status:</span>
    <ioio-dropdown
      :items="activeCategories"
      titleProp="name"
      valueProp="value"
      v-model="activedCategory"
      :multiple="false"
      size="small"
      @input="searchByCategory"
      alignX="right"
      class="ml-2"
    />
    <ioio-button
      type="primary"
      variant="solid"
      size="small"
      class="btn-green ml-auto"
      :disabled="!userPermissions || !userPermissions.presets_update"
      @click="updateDefaultData()">Update
    </ioio-button>
  </div>
  <div class="presets-list" v-if="!isLoadingData">

    <div v-for="preset,i in presetsList" :key="i" class="p-2 flex items-center ml-2">
      <ioio-checkbox
        v-model="preset.isActive"
        :disabled="false"
        size="small"
        :label="preset.label"
      />
      <input
        class="copy-to-clipboard-input"
        type="text"
        :id="'preset-copy-' + i"
        :value="JSON.stringify(preset)">
      <ioio-tooltip text="Copy preset" initialPostion="right">
        <span
          @click.stop.prevent="copyToClipboard('preset-copy-' + i)">
          <ioio-icon
            icon="fas-clone"
            class="w-4 h-4 m-2 cursor-pointer text-black-200"
          />
        </span>
      </ioio-tooltip>
      <ioio-button
        type="primary"
        variant="solid"
        size="xs"
        class="ml-auto"
        @click="showContent(preset)">Content
      </ioio-button>

    </div>
    <h4 v-if="!presetsList.length"> No presets were found with these criteria</h4>
  </div>
  <div class="relative min-h-36" v-else>
    <ioio-loader isShown />
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({
    isLoadingData: false,
    presetsList: [],
    initialList: [],
    defaultList: [],
    isContentModalOpened: false,
    presetContent: {},
    possibleCategory: [
      {
        name: 'All',
        value: 'All'
      },
    ],
    selectedCategory: 'All',
    activeCategories: [
      {
        name: 'All',
        value: 'All'
      },
      {
        name: 'Active',
        value: 1
      },
      {
        name: 'Inactive',
        value: 0
      }
    ],
    activedCategory: 'All',
    copyElementInnerText: null,   
  }),
  props: {},
  created() {
    this.getDefaultData();
    this.copyElementInnerText = copyElementInnerText;
  },
  mounted() {

  },
  methods: {
    getDefaultData() {

      this.isLoadingData = true;

      this.presetsList= [];
      this.defaultList= [];
      this.selectedCategory= 'All';
      this.activedCategory= 'All';

      this.getBrokerPresets()
      .then((defaultData) => {

        defaultData.forEach(preset => {
          preset.isActive === 0 ? preset.isActive = false : preset.isActive = true;
          if (this.possibleCategory.find(cat => cat.name === preset.content.Category) == undefined) {
            this.possibleCategory.push(
              {
                name : preset.content.Category,
                value : preset.content.Category
              }
            )
          }
          this.presetsList.push(preset);
          this.defaultList.push(preset.isActive);
        })
        this.initialList = this.presetsList;
      })
      .finally(() => {

        this.isLoadingData = false;
      });

    },
    async updateDefaultData() {
      for (let index = 0; index < this.initialList.length;) {
        if (this.initialList[index].isActive === this.defaultList[index]) {

          index++;
          if (this.isLoadingData && index === this.initialList.length) {
            this.getDefaultData();
          }
        } else {

          this.isLoadingData = true;
          const isActive = this.initialList[index].isActive ? 1 : 0;
          const payload = {
            data: {
              isActive
            },
            guid: this.initialList[index].guid
          };

          await this.updateExistingPreset(payload)
          .then((resp)=>{
            this.$toasted.success('Your changes were saved successfully');
            index++;
          })
          .finally(() => {

            if (index === this.initialList.length) {
              this.getDefaultData();
            };
          });
        };
      }
    },
    showContent(preset) {

      this.presetContent = {...preset.content};
      this.isContentModalOpened = true;
    },
    searchByCategory() {

      if (this.selectedCategory === 'All') {

        if (this.activedCategory != 'All') {

          this.presetsList = this.initialList.filter(preset => preset.isActive == this.activedCategory);
        } else {

          this.presetsList = this.initialList;
        };
      } else {

        if (this.activedCategory != 'All') {

          this.presetsList = this.initialList.filter(preset => (preset.isActive == this.activedCategory &&  preset.content.Category === this.selectedCategory));
        } else {

          this.presetsList = this.initialList.filter(preset => preset.content.Category === this.selectedCategory);
        };

      };

    },

    copyToClipboard(elementId) {

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },   

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerPresets: "channelManager/getBrokerPresets",
      updateExistingPreset: "channelManager/updateExistingPreset"
    })
  },
  computed: {

    ...mapGetters({
      userPermissions: "app/userPermissions"
    })
  },  
}
</script>

<style lang="scss">
.vod-presets-settings-component{

  .btn-green{
    &.ds-btn.is-primary.is-solid{
      @apply
      bg-green-500;
    }
  }
  .ds-modal{
    .sticky {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0.5rem;
    }
  }
  .presets-list {
    @apply
    bg-white
    rounded-lg
    mb-4
    p-4
    overflow-y-scroll;
    max-height: 23rem;
    .ds-checkbox {
      label{
        @apply
        mb-0;
      }
    }
    .ds-tooltip__el {
      z-index: 5;
    }
    .copy-to-clipboard-input {
      @apply
      absolute
      -top-full;
    }
  }

  /**
   * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
   * Tailwind classes are present in the HTML, so deleting the following lines,
   * when styleberry is deprecated should still work.
  **/

  @import "@indexScss";


  /* @include overwrite-styleberry-styles-inputs; */

  @include overwrite-styleberry-styles-tags;
  @include overwrite-styleberry-styles-htags-margin;
  @include overwrite-styleberry-styles-htags-font-size;
}
</style>
