import { calcGaps, deepClone } from '@utils/helpers';



const SET_ACTIVE_SECTION = (state, newVal) => {

  state.activeSection = newVal;
};






const SET_SELECTED_CHANNEL = (state, newSelected) => {

  state.channelsMeta = {
    ...state.channelsMeta,
    selected: {
      ...newSelected
    }
  };

  /**
   * Null the affected timestamps, so that other checks,
   * if the channel has been edited, work as expected.
   */
  state.firstAffectedTimestamp = 0;
  state.lastAffectedTimestamp = 0;
};

const TOGGLE_DASHBOARD_PANELS_OPENED = (state, openedPanel) => {

  state.activeToolbarPanel = openedPanel;
};

const RESET_DASHBOARD_PANELS_STATE = state => {

  state.activeToolbarPanel = 'livePreview';
};

const UPDATE_LISTING_IN_PLACE = (state, { atIndex, newListingData }) => {
  state.selectedChannelListings.absolute[atIndex] = {
    ...newListingData
  };
};

const UPDATE_LISTINGS = (state, { atIndex, deleteCount, newListings }) => {
  /**
   * Add item to the absolute list (clean copy).
   * The list is used to generate all the service elements.
   */
  state.selectedChannelListings.absolute.splice(
    atIndex,
    deleteCount,
    ...newListings
  );
};

const UPDATE_TIMELINE_OVERFLOW_INDEX = (state, newValue) => {

  state.timelineOverflowIndex = newValue;

  /**
   * Adjust the perHourMarkerCount, that is used in the timeline,
   * based on the new value of the timelineOverflowIndex.
   *
   * NOTE: the following check can be tied to the possible
   * overlowIndex values, and adjusted based on percentages
   * (newVal : maxPossibleOverflowIndex)
   */
  let newPerHourMarkerCount = 1;

  if (newValue > 10) {

    newPerHourMarkerCount = 3

  } else if (newValue <= 10 && newValue > 5) {

    newPerHourMarkerCount = 2;

  } else {

    newPerHourMarkerCount = 1;
  }

  state.perHourMarkerCount = newPerHourMarkerCount;
};

const SET_CHANNELS_LIST = (state, listData) => {

  state.channelsList = [...listData.sort((a, b) => a.order < b.order ? -1 : 1)];
  state.channelsListPristine = deepClone(listData);
};

const TOGGLE_CHANNEL_LOCKED = (state, { guid, isLocked }) => {

  state.lockedChannelsGuids = {

    ...state.lockedChannelsGuids,
    [guid]: isLocked
  };
};

const REORDER_CHANNELS_LIST = (state, { oldChannelIndex, newChannelIndex, channelData }) => {

  const list = [...state.channelsList];

  list.splice(oldChannelIndex, 1);
  list.splice(newChannelIndex, 0, channelData);

  const startIndex = Math.min(oldChannelIndex, newChannelIndex);
  const endIndex = Math.max(oldChannelIndex, newChannelIndex);

  /**
   * Update the `order` prop of the affected channels, since the
   * BE sorts by GUID, but the order in the list should be displayed
   * based on `order`
   */
  for (let i = startIndex; i <= endIndex; i++) {

    list[i].order = i + 1;
  }

  state.channelsList = list;
};

const SET_PLAYLISTS = (state, listData) => {

  state.playlists = [...listData];
};

const SET_VOD_LIST = (state, listData) => {
  state.vodList = [...listData];
};

const UPDATE_FROM_SELECTED_VOD_CLIPS = (state) => {

  const fromSelectedVodClips = state.vodList.filter(c => (
    c.parentGuid && c.parentGuid === state.editedVod.guid)
  );

  state.fromSelectedVodClips = [...fromSelectedVodClips];
};

const ADD_VOD_LIST_ITEM = (state, itemData) => {

  const modifiedList = [...state.vodList];

  modifiedList.splice(0, 0, itemData);

  state.vodList = modifiedList;
};

const UPDATE_VOD_LIST_ITEM = (state, updatedVodData) => {

  const { guid } = updatedVodData;

  const itemIndex = state.vodList.findIndex(vod => vod.guid === guid);

  const modifiedList = [...state.vodList];

  modifiedList.splice(itemIndex, 1, updatedVodData);

  state.vodList = modifiedList;
};

const SET_SELECTED_CHANNEL_LISTINGS_PRISTINE = (state, listingsData) => {

  state.selectedChannelListings.pristine = deepClone(listingsData);
};

const SET_SELECTED_CHANNEL_LISTINGS = (state, payload) => {
  const dayInMs = 86400000;

  const listingsData = [...payload];

  const visiblePeriodStartStamp =
    new Date().setHours(state.currentHour, 0, 0, 0) - dayInMs;

  const visiblePeriodEndStamp = visiblePeriodStartStamp + 3 * dayInMs;

  let readySections = [];

  /**
   * Fill service elements in the whole period if no listings are found
   */
  if (!listingsData.length) {
    const serviceElements = calcGaps({
      startStamp: visiblePeriodStartStamp,
      endStamp: visiblePeriodEndStamp,
      gapInterval: 600000
    });

    readySections = readySections.concat(serviceElements);
  }

  /**
   * Fill service elements in the start of the period if needed
   */
  if (listingsData[0] && listingsData[0].start > visiblePeriodStartStamp) {
    /**
     * The calcGaps fn will return appropriate gaps given the criteria
     */
    const beginningServiceElements = calcGaps({
      startStamp: visiblePeriodStartStamp,
      endStamp: listingsData[0].start,
      gapInterval: 600000
    });

    readySections = readySections.concat(beginningServiceElements);
  }

  /**
   * Look for gaps between listings and fill them
   */
  for (var i = 0; i < listingsData.length; i++) {
    /**
     * There is a gap bethween prev listing end and current
     * listing start. Fill service elements between listings.
     */
    if (
      listingsData[i - 1] &&
      listingsData[i].start > listingsData[i - 1].end
    ) {
      /**
       * The calcGaps fn will return appropriate gaps given the criteria
       */
      const inBetweenServiceElements = calcGaps({
        startStamp: listingsData[i - 1].end,
        endStamp: listingsData[i].start,
        gapInterval: 600000
      });

      readySections = readySections.concat(inBetweenServiceElements);
    }

    listingsData[i].absoluteIndex = i;

    /**
     * Explicitly mark the listing overflowingData: false, since this
     * property will be evaluated at a later step and a previos record
     * of it may falsely be kept as present
     */
    listingsData[i].overflowingData = null;

    readySections.push({
      ...listingsData[i]
    });
  }

  /**
   * Fill service elements in the end of the period if needed
   */
  if (
    listingsData.length &&
    listingsData[listingsData.length - 1].end < visiblePeriodEndStamp
  ) {
    /**
     * The calcGaps fn will return appropriate gaps given the criteria
     */
    const endServiceElements = calcGaps({
      startStamp: listingsData[listingsData.length - 1].end,
      endStamp: visiblePeriodEndStamp,
      gapInterval: 600000
    });

    readySections = readySections.concat(endServiceElements);
  }

  const prevPeriodListingData = readySections.filter(entry => {
    return entry.end <= visiblePeriodStartStamp + dayInMs;
  });

  const currentPeriodListingData = [];
  const currentPeriodStart = visiblePeriodStartStamp + dayInMs;
  const currentPeriodEnd = visiblePeriodStartStamp + 2 * dayInMs;

  let nextPeriodStartIndex = 0;

  for (var i = 0; i < readySections.length; i++) {
    const entry = readySections[i];

    /**
     * A listing is reached, that starts in the next period ->
     * No need for further checks here, since the list is ordered
     */
    if (entry.start > currentPeriodEnd) {
      console.log("break here", i, readySections.length);
      /**
       * Cache nextPeriodStartIndex, so that it is used in the nextPeriod checks
       */
      nextPeriodStartIndex = i;

      break;
    }

    /**
     * The listing is entirely in the boundries of the period
     */
    if (entry.start >= currentPeriodStart && entry.end <= currentPeriodEnd) {
      currentPeriodListingData.push(entry);
    }

    /**
     * The listing starts in the prev period,
     * but ends in the boundries of this period
     */

    if (
      entry.start < currentPeriodStart &&
      entry.end > currentPeriodStart &&
      entry.end < currentPeriodEnd
    ) {
      const prevPeriodPartInMs = currentPeriodStart - entry.start;
      const currentPeriodPartInMs = entry.end - currentPeriodStart;

      /**
       * Push the part that fits in the prevPeriod
       */
      prevPeriodListingData.push({
        ...entry,
        overflowingData: {
          thisPeriodPartInMs: prevPeriodPartInMs,
          otherPeriodPartInMs: currentPeriodPartInMs,
          adjustedStart: entry.start,
          adjustedEnd: currentPeriodStart
        }
      });

      /**
       * Push the part that fits in the currentPeriod
       */
      currentPeriodListingData.push({
        ...entry,
        overflowingData: {
          thisPeriodPartInMs: currentPeriodPartInMs,
          otherPeriodPartInMs: prevPeriodPartInMs,
          adjustedStart: currentPeriodStart, // adjust the start, so that the overflowing part up front doesn't break the layout
          adjustedEnd: entry.end
        }
      });
    }
  }

  const nextPeriodListingData = [];
  const nextPeriodStart = visiblePeriodStartStamp + 2 * dayInMs;
  const nextPeriodEnd = visiblePeriodStartStamp + 3 * dayInMs;

  /**
   * Adjust nextPeriodStartIndex, so that the last entry, that might have started
   * in currentPeriod, but not have fitted there, finds it's place here
   */
  nextPeriodStartIndex -= 1;

  for (var i = nextPeriodStartIndex; i < readySections.length; i++) {
    const entry = readySections[i];

    /**
     * The listing is entirely in the boundries of the period
     */
    if (entry.start >= nextPeriodStart && entry.end <= nextPeriodEnd) {
      nextPeriodListingData.push(entry);
    }

    /**
     * The listing starts in the currentPeriod,
     * but ends in the boundries of the nextPeriod
     */
    if (
      entry.start < nextPeriodStart &&
      entry.end > nextPeriodStart &&
      entry.end < nextPeriodEnd
    ) {
      const currentPeriodPartInMs = nextPeriodStart - entry.start;
      const nextPeriodPartInMs = entry.end - nextPeriodStart;

      /**
       * Push the part that fits in the currentPeriod
       */
      currentPeriodListingData.push({
        ...entry,
        overflowingData: {
          thisPeriodPartInMs: currentPeriodPartInMs,
          otherPeriodPartInMs: nextPeriodPartInMs,
          adjustedStart: entry.start,
          adjustedEnd: nextPeriodStart
        }
      });

      /**
       * Push the part that fits in the nextPeriod
       */
      nextPeriodListingData.push({
        ...entry,
        overflowingData: {
          thisPeriodPartInMs: nextPeriodPartInMs,
          otherPeriodPartInMs: currentPeriodPartInMs,
          adjustedStart: nextPeriodStart, // adjust the start, so that the overflowing part up front doesn't break the layout
          adjustedEnd: entry.end
        }
      });
    }
  }

  state.selectedChannelListings.absolute = [...listingsData];
  state.selectedChannelListings.entirePeriod = [...readySections];
  state.selectedChannelListings.currentPeriod = [...currentPeriodListingData];
  state.selectedChannelListings.prevPeriod = [...prevPeriodListingData];
  state.selectedChannelListings.nextPeriod = [...nextPeriodListingData];

};

const CALC_TOTAL_LISTINGS_DURATION = (state, listingsData) => {
  let totalDuration = 0;

  listingsData.map(listing => {
    totalDuration += listing.end - listing.start;
  });

  state.selectedPeriodTotalListingsDuration = totalDuration;
};

const SET_SELECTED_LISTING = (state, listingData) => {
  state.selectedListing = { ...listingData };
};

const SET_MEDIATAILORS = (state, mediatailors) => {
  state.mediatailors = [...mediatailors];
};

const SET_ACTIVE_PERIOD = (state, periodName) => {
  state.activePeriods.selected = {
    ...state.activePeriods[periodName]
  };
};

const SET_TIMELINE_SLOTS = state => {
  // TODO: add logic, so that it is inline with the period selected
  const timelineSlotsAmount = 24 * 6;

  const timelineSlots = [];

  const currentHourStamp = new Date().setHours(state.currentHour, 0, 0, 0);

  for (var i = 0; i < timelineSlotsAmount; i++) {
    timelineSlots.push({
      isServiceElement: true,
      start: currentHourStamp + i * 600000,
      end: currentHourStamp + i * 600000 + 600000
    });
  }

  // state.timelineSlots = timelineSlots;

  state.selectedChannelListings.entirePeriod = [...timelineSlots];
};

const TRIGGER_SCHEDULE_SWAP = (state, dragData) => {
  const { removedIndex, addedIndex, payload } = dragData;

  const updatedSchedules = [...state.selectedListing.schedules];

  updatedSchedules.splice(removedIndex, 1);
  updatedSchedules.splice(addedIndex, 0, payload.item);

  state.selectedListing.schedules = updatedSchedules;
};

const TRIGGER_SCHEDULE_ADD = (state, dragData) => {
  const { addedIndex, payload } = dragData;

  const updatedSchedules = [...state.selectedListing.schedules];

  const newSchedule = {
    ...payload.item,
    start: state.selectedListing.end
  };

  updatedSchedules.splice(addedIndex, 0, newSchedule);

  state.selectedListing.schedules = updatedSchedules;
};

/**
 * Will be used if a Playlist (instead of a source) is added to the Playlist section
 */
const TRIGGER_MULTIPLE_SCHEDULES_ADD = (state, { addedIndex, schedules }) => {

  const updatedSchedules = [...state.selectedListing.schedules];

  const newSchedules = [];

  const currentScheduleStart = state.selectedListing.end;

  let accumulatedTime = 0;

  for (var i = 0; i < schedules.length; i++) {

    const newSchedule = {

      ...schedules[i],
      start: state.selectedListing.start + accumulatedTime,
      totalDuration: schedules[i].totalDuration,
      type: schedules[i].meta.type
    };

    newSchedules.push(newSchedule);

    accumulatedTime += schedules[i].srcDuration;
  }

  updatedSchedules.splice(addedIndex, 0, ...newSchedules);

  state.selectedListing.schedules = updatedSchedules;
};

const TRIGGER_SCHEDULES_STARTS_ADJUSTMENT = state => {
  let currentTotalSchedulesDuration = 0;

  const updatedSchedules = state.selectedListing.schedules.map(schedule => {

    const updatedSchedule = {
      ...schedule,
      start: state.selectedListing.start + currentTotalSchedulesDuration
    };

    if (schedule.type === 'live') {

      updatedSchedule.end = updatedSchedule.start + updatedSchedule.totalDuration;
    }

    currentTotalSchedulesDuration += schedule.totalDuration;

    return updatedSchedule;
  });

  state.selectedListing.end =
    state.selectedListing.start + currentTotalSchedulesDuration;

  state.selectedListing.schedules = updatedSchedules;
};

const TRIGGER_SCHEDULE_DELETE = (state, removedIndex) => {
  const updatedSchedules = [...state.selectedListing.schedules];

  updatedSchedules.splice(removedIndex, 1);

  state.selectedListing.schedules = updatedSchedules;
};

const TRIGGER_ALL_SCHEDULES_FOR_LISTING_DELETE = (state) => {

  state.selectedListing.schedules = [];
};

const SET_FIRST_AFFECTED_TIMESTAMP = (state, newUpdateStamp) => {
  if (
    !state.firstAffectedTimestamp ||
    newUpdateStamp < state.firstAffectedTimestamp
  ) {
    state.firstAffectedTimestamp = newUpdateStamp;
  }
};

const SET_LAST_AFFECTED_TIMESTAMP = (state, newUpdateStamp) => {
  if (
    !state.lastAffectedTimestamp ||
    newUpdateStamp > state.lastAffectedTimestamp
  ) {
    state.lastAffectedTimestamp = newUpdateStamp;
  }
};

const SET_IS_SYNC_PERIOD_IN_PROGRESS = (state, newVal) => {

  state.isSyncPeriodInProgress = newVal;
};

const SET_SINGLE_SOURCE_PLAYED = (state, sourceData) => {

  state.singleSourcePlayed = sourceData;
};

const SET_STAGED_VOD_AD_INSERTION_GUID = (state, guid) => {

  state.stagedVodAdInsertionGuid = guid;
};

const UPDATE_EDITED_VOD = (state, vod) => {

  state.editedVod = vod;
};

const SET_EXTERNAL_TIMELINE_CONTROL_OPTIONS = (state, options) => {

  const { start, end, isControlled } = options;

  state.timelineExternalStart = start;
  state.timelineExternalEnd = end;
  state.timelineExternalIsControlled = isControlled;
};

const SET_TEMP_SCHEDULES_SELECTION = (state, schedules) => {

  state.timelineTempSchedulesSelection = [...schedules];
};

const SET_TEMP_LISINGS_SELECTION = (state, listings) => {

  state.timelineTempListingsSelection = [...listings];
};

const SET_SCHEDULE_HOVER_DATA = (state, data) => {

  state.scheduleHoverData = data;
};

const SET_VOD_SETTINGS = (state, settings) => {

  state.vodSettings = {...settings};
}

export {

  // START refactoring new Props
  SET_ACTIVE_SECTION,
  SET_EXTERNAL_TIMELINE_CONTROL_OPTIONS,
  SET_TEMP_SCHEDULES_SELECTION,
  SET_TEMP_LISINGS_SELECTION,
  SET_SCHEDULE_HOVER_DATA,
  // END refactoring new props




  SET_SELECTED_CHANNEL,
  TOGGLE_DASHBOARD_PANELS_OPENED,
  RESET_DASHBOARD_PANELS_STATE,
  UPDATE_LISTING_IN_PLACE,
  UPDATE_LISTINGS,
  UPDATE_TIMELINE_OVERFLOW_INDEX,
  SET_CHANNELS_LIST,
  TOGGLE_CHANNEL_LOCKED,
  REORDER_CHANNELS_LIST,
  SET_PLAYLISTS,
  SET_VOD_LIST,
  UPDATE_FROM_SELECTED_VOD_CLIPS,
  ADD_VOD_LIST_ITEM,
  UPDATE_VOD_LIST_ITEM,
  SET_SELECTED_CHANNEL_LISTINGS_PRISTINE,
  SET_SELECTED_CHANNEL_LISTINGS,
  CALC_TOTAL_LISTINGS_DURATION,
  SET_SELECTED_LISTING,
  SET_MEDIATAILORS,
  SET_ACTIVE_PERIOD,
  SET_TIMELINE_SLOTS,
  TRIGGER_SCHEDULE_SWAP,
  TRIGGER_SCHEDULE_ADD,
  TRIGGER_MULTIPLE_SCHEDULES_ADD,
  TRIGGER_SCHEDULES_STARTS_ADJUSTMENT,
  TRIGGER_SCHEDULE_DELETE,
  TRIGGER_ALL_SCHEDULES_FOR_LISTING_DELETE,
  SET_FIRST_AFFECTED_TIMESTAMP,
  SET_LAST_AFFECTED_TIMESTAMP,
  SET_IS_SYNC_PERIOD_IN_PROGRESS,
  SET_SINGLE_SOURCE_PLAYED,
  SET_STAGED_VOD_AD_INSERTION_GUID,
  UPDATE_EDITED_VOD,
  SET_VOD_SETTINGS
};
