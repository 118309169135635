<template>
<div class="google-drive-integration-settings-component">
  <ioio-modal
    class=""
    :show.sync="isConfirmDisableModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        Are you sure you want to disable this integration?
      </div>
    </template>

    <h3 class="">
      All integrations with Google Drive will be disabled.
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelDisable"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmDisable"
        >
          Disable
        </ioio-button>
      </div>
    </template>

  </ioio-modal>

  <div v-if="isRequestPending" style="overflow:hidden" class="flex items-center flex-auto">
    <logo-loader-component width="100px" height="100px" />
  </div>

  <section v-if="!isRequestPending && isOpen" class="flex flex-column flex-auto">

    <section class="border-bottom border-black-200 mb-6 pb-4">

      <h1>Emails</h1>

      <h3 class="text-sm text-black-400 mb-2 mt-4">
        Here you can exclude user emails from the import of Google Drive videos . If you want to select an email that has already imported videos from Google Drive, please switch the toggle:
      </h3>
      <div class="flex items-center">
        <ioio-dropdown
          :disabled="!available"
          placeholder="Select email"
          :items="seedingsEmails"
          titleProp="email"
          valueProp="email"
          @change="addEmail"
          :multiselect="false"
          multiple>
        </ioio-dropdown>

        <ioio-toggle
          v-model="available"
          labelRight="Available Emails"
          color="blue"
          class="ml-auto"
        ></ioio-toggle>
      </div>

      <h3 class="text-sm text-black-400 mb-2 mt-6">or enter them manually.</h3>

      <div class="flex justify-between items-center mb-4" >
        <ioio-field
          @focus="duplicate = false"
          type="email"
          required
          topic="newEmail"
          size="small"
          placeholder="Email"
          :maximum=64
          v-model="newEmail"
          class="w-4/5 mr-6">
        </ioio-field>

        <ioio-button
          topic="newEmail"
          type="primary"
          size="small"
          class="ml-auto"
          @click.stop.prevent="addEmailManual"
        >
          Add
        </ioio-button>
      </div>
      <div class="ds-tags__error" v-if="duplicate">
        This email has already been added to the list!
      </div>

      <h3 ref="emails" v-if="emailFields.length" class="text-sm text-black-400 mb-2 mt-6">This is the list of Google Drive user emails that are excluded from the automated import.</h3>
      <section
        class="flex justify-between items-center mb-4"
        v-for="(emailField, index) in emailFields"
        :key="index">

        <ioio-field
          :disabled="true"
          type="email"
          required
          topic="integrationForm"
          size="small"
          placeholder="Email"
          :maximum=64
          v-model="emailFields[index]"
          class="w-4/5 mr-6">
        </ioio-field>

         <ioio-button
          type="danger"
          variant="tonal"
          size="small"
          @click.stop.prevent="removeEmail(index)"
        >
          Remove
        </ioio-button>
      </section>

    </section>

    <section class="tags-section border-bottom border-black-200 pb-4 mb-6" ref="tags">

      <ioio-tags
        topic="integrationForm"
        size="small"
        label="Tags"
        class="w-full"
        v-model="customMeta.tags"
        :tags="customMeta.tags"
      />

      <div class="ds-tags__error" v-if="!tagsLength">
        Tags length should not be more than 64 characters.
      </div>

      <ioio-tags
        topic="integrationForm"
        size="small"
        label="Categories"
        class="w-full mt-4"
        v-model="customMeta.categories"
        :tags="customMeta.categories"
      />

      <div class="ds-tags__error" v-if="!categoriesLength">
        Categories length should not be more than 64 characters.
      </div>

    </section>

    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6">

      <h1 class="text-md mb-4">Select hubs to integrate with Google Drive</h1>
      <div v-if="combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded">

        <ioio-checkbox
          v-for="hub in combinedSiteBuilderHubs"
          :key="hub.id"
          size="small"
          disclaimer=""
          v-model="addedHubsGuidsMap[hub.id]"
          @input="(newVal) => toggleAddHubToList(hub, newVal)"
        >
          <span class="text-sm">{{ hub.name }}</span>
        </ioio-checkbox>
      </div>
      <h3 v-else-if="!combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded" class="text-sm text-black-400">No hubs configured</h3>

    </section>


    <ioio-checkbox
      size="small"
      disclaimer="The integration will be disabled"
      v-model="isIntegrationDisabled"
      @input="toggleDisableIntegrationnewVal"
    >
      <span class="text-sm">Disable integration</span>
    </ioio-checkbox>

    <div class="flex justify-end mt-8">
      <ioio-button
        type="primary"
        size="medium"
        @click.stop.prevent="saveGoogleDriveSettings()"
      >Save
      </ioio-button>
    </div>

  </section>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import utils from '@ioiotv/ioio-kit-core/src/utils';

export default {
  data: () => ({
    isOpen: false,
    isConfirmDisableModalOpened: false,
    isRequestPending: false,

    googleDriveSettings: {},

    emailFields: [],
    seedingsEmails: [],

    available: false,
    customMeta: {

      hubs: [],
      tags: [],
      categories: []
    },

    addedHubsGuidsMap: {},
    addedHubsMap: {},

    isIntegrationDisabled: false,
    errors: [],
    newEmail: '',
    duplicate: false
  }),

  created() {

    this.setGoogleDriveSettings();
  },
  mounted() {

    window.a = this;
  },
  methods: {

    setGoogleDriveSettings() {

      this.isRequestPending = true;

      this.getIngestDistributors()
        .then((distributors) => {
          const google = distributors.find(d => d.guid === 'google-drive');

          if (!google) {

            this.$toasted.error('The Google Drive integration is not available in your organisation.')
            return;
          }

          this.isOpen = true;

          this.isIntegrationDisabled = !google.enabled;

          for (const key in google.seedings) {

            this.seedingsEmails.push({email:key});
          };

          if (google.googleDriveSettings) {

            this.googleDriveSettings = {

              ...google.googleDriveSettings
            };
          };


          if (this.googleDriveSettings.emails) {

            this.emailFields = [

              ...google.googleDriveSettings.emails
            ];
          }

          if (this.googleDriveSettings.customMeta) {

            const tags = this.googleDriveSettings.customMeta.tags || [];
            const categories = this.googleDriveSettings.customMeta.categories || [];

            this.customMeta = {

              ...this.googleDriveSettings.customMeta,
              tags: [...tags],
              categories: [...categories]
            };

            if (this.googleDriveSettings.customMeta.hasOwnProperty('hubs')) {

              this.googleDriveSettings.customMeta.hubs.forEach((hub) => this.toggleAddHubToList(hub, true));
            };
          }
        })

        .finally(() => {

            this.isRequestPending = false;
         });
    },

    cancelDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(false);
    },

    confirmDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(true, 'force');
    },

    addEmail(email) {

      if (this.emailFields.includes(email)) {
        return;
      }
      this.emailFields.unshift(email);
    },

    addEmailManual() {

      if (this.emailFields.includes(this.newEmail)) {

        this.duplicate = true;
        return;
      };
      this.duplicate = false;
      this.emailFields.unshift(this.newEmail);
      this.newEmail = '';
    },

    removeEmail(index) {

      this.emailFields.splice(index, 1);
    },

    toggleDisableIntegrationnewVal(newVal, force) {

      if (newVal && !force) {

        this.isConfirmDisableModalOpened = true;
        return;
      }

      this.isIntegrationDisabled = newVal;
    },

    toggleAddHubToList(hub, newVal) {

      if (newVal) {

        this.addedHubsGuidsMap[hub.id] = true;

        this.addedHubsMap[hub.id] = {

          id: hub.id,
          name: hub.name,
          permissions: []
        };

      } else {

        this.addedHubsGuidsMap[hub.id] = false;
        delete this.addedHubsMap[hub.id];
      }
    },

    saveGoogleDriveSettings() {

      this.errors = utils.submit.validate("integrationForm");

      if (this.errors.length) {

        this.$refs.emails.scrollIntoView();
        return;
      }

      if (!this.categoriesLength || !this.tagsLength) {

        this.$refs.tags.scrollIntoView();
        return;
      };

      const hubs = [];

      for (const hubId in this.addedHubsMap) {

        if (this.addedHubsMap[hubId]) {

          hubs.push(this.addedHubsMap[hubId]);
        }
      };

      const payload = {

        distributorGuid: 'google-drive',

        data: {

          enabled: !this.isIntegrationDisabled,
          googleDriveSettings: {

            ...this.googleDriveSettings,

            emails: this.emailFields,

            customMeta: {
              ...this.customMeta,
              hubs
            },
          }
        }
      };

      this.isRequestPending = true;

      this.updateIngestDistributors(payload)
        .then(() => {

          this.$toasted.success('Configuration saved successfully');
        })
        .finally(() => {
          this.seedingsEmails = [];
          this.setGoogleDriveSettings();
        });
    },

    ...mapActions({
      getIngestDistributors: "ingest/getIngestDistributors",
      updateIngestDistributors: "ingest/updateIngestDistributors"
    })
  },
  computed: {
    ...mapGetters({
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
      distributors: 'ingest/ingestDistributors'
    }),

    combinedSiteBuilderHubs() {

      const hubs = [];

      this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          a.hubs.forEach(hub =>{

             hubs.push(hub);
          });
        }
      });

      return hubs;
    },
    tagsLength() {

      let tags = this.customMeta.tags.reduce((a, b) => a + b,'');

      return tags.length < 64;
    },
    categoriesLength() {

      let categories = this.customMeta.categories.reduce((a, b) => a + b,'');

      return categories.length < 64;
    }
  }
}
</script>

<style lang="scss">
  .google-drive-integration-settings-component {
    @apply
    flex flex-col flex-auto overflow-auto mt-4;
  }

</style>
