function cancelChanges ( task, dependencies, stateData, data ) {
    const 
          { vttFiles } = stateData
        , response = { vttFiles }
        ;
    task.done ({
                  success : true
                , response
            })
} // cancelChanges func.
 
 
 
 export { cancelChanges }
 
 
 