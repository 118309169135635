/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete-forever': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M23 4h-6V1a1 1 0 00-1-1H8a1 1 0 00-1 1v3H1a1 1 0 000 2h22a1 1 0 000-2zM9 2h6v2H9z" data-color="color-2"/><path pid="1" d="M3 8v13a3 3 0 003 3h12a3 3 0 003-3V8zm13.414 10.985L15 20.4l-3-3-3 3-1.414-1.415 3-3-3-3L9 11.571l3 3 3-3 1.414 1.414-3 3z"/></g>'
  }
})
