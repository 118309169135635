<template>
  <section class="add-image-component h100 flex flex-column items-center">


    <vue-dropzone
      :options="dropzoneOptions"
      :include-styling="false"
      id="customdropzone"
      :class="{ 'staged-files-present': prompts.length }"
      ref="dropzoneComponent"
      :useCustomSlot="true"
    >


      <div v-if="!prompts.length" class="dropzone-custom-content flex items-center flex-column">
        <ioio-icon icon="fas-cloud-arrow-up" class="w-10 h-10 text-black-400"/>
        <h3 class="dropzone-custom-title size0">
          Drag and drop to upload images!
        </h3>
        <div class="subtitle size-2">
          ...or click to select a file from your computer
        </div>
        <h5 class="subtitle size-2">
          Supported file types: .jpeg, .jpg, .png, .webp, .tiff, .gif, .svg, .heic, .heif
        </h5>
      </div>



    </vue-dropzone>


    <div class="width+10 main-content" v-if="prompts.length">
      <div class="flex items-center justify-between width+10 my1 bg-blue+5 p1 rounded-2 width+10">
        <check-component
          type="checkbox"
          label="Select All"
          class="ml2 size-3"
          :val="isSelectAllOptionApplied"
          :on-change="() => toggleSelectAllOptionApplied()"/>
        <div class="actions flex items-center">

          <div class="flex items-center ml1">
            <span class="mr-1 size-3">Group</span>
            <input
              class="input size-s m0"
              placeholder="group"
              v-model="bulkGroup"/>
          </div>
          <div class="flex items-center ml1">
            <span class="mr-1 size-3">Genre</span>

            <input
              class="input size-s m0"
              placeholder="Genre"
              v-model="bulkGenre"/>
          </div>

          <check-component
            label="Logo"
            type="checkbox"
            class="size-3 ml1"
            :val="bulkLogo"
            :on-change="() => toggleBulkLogo()"/>

          <button-component
            class="ml1"
            :class="{'disabled': !bulkSelectedCount}"
            :variant="bulkSelectedCount ? 'primary' : ''"
            @click="applyToBulkSelected">Apply to Selected ({{ bulkSelectedCount }})
          </button-component>
        </div>
      </div>




      <div class="flex flex-column bg-gray+5 rounded-3 p1 staged-images-container">

        <div class="flex flex-column items-center p2 bg-white border-bottom" v-for="(prompt, index) in prompts"
          :key="index"
        >
          <section class="flex items-center width+10">
            <check-component
              type="checkbox"
              class="mr1"
              :val="!!bulkList[prompt.id]"
              :on-change="() => toggleAddPromptToBulkList(prompt)"/>

            <tag-component variant="image">image</tag-component>
            <h5 class="m0 mx1">{{prompt.file.name}}</h5>
            <div class="flex items-center ml-2">
              <check-component
                label="Logo"
                type="checkbox"
                class="size-3 mr1"
                :val="prompt.data.logo"
                :on-change="() => togglePromptLogoStatus(prompt, index)"/>
              <tag-component v-if="prompt.data.group" class="mr1">{{prompt.data.group}}</tag-component>
              <tag-component v-if="prompt.data.genre" class="">{{prompt.data.genre}}</tag-component>
            </div>

            <div class="ml-auto flex items-center">
              <!--<span class="size-2 mr1">{{prompt.status}}</span>-->
              <progress-component class="small mr2" style="width: 7.5rem" :progress="prompt.progressPercentage" v-if="prompt.status !== 'error'"/>
              <h6 v-else>There was an error processing this image.</h6>
            </div>

            <button-component
              class="mr1 flex-none"
              size="size-s"
              @click="togglePromptFormVisible(prompt)"
              v-tooltip="'edit file'"
              >
              <ioio-icon icon="fas-pen" class="w-4 h-4"/>

            </button-component>

            <button-component
              class="mr1 flex-none"
              size="size-s"
              @click="unstageFileFromUpload(index)"
              v-tooltip="'remove file'"
              >
              <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>

            </button-component>

          </section>


          <vue-form
            :state="prompt.formState"
            class="width+10 bg-blue+5 rounded-3 p2 mt2"
            v-show="prompt.isFormVisible"
          >

            <div class="flex items-start">

              <validate class="mr1">
                <label :for="`title_${index}`" class="size-2">Title
                  <span class="gray+1">(required)</span>
                </label>
                <input
                type="text"
                class="input size-s"
                :class="{'is-invalid': isValidTitle(index)}"
                :id="`title_${index}`"
                v-model="prompts[index].data.title"
                placeholder="Title"
                required
                maxlen="100"
                name="title" />
                <field-messages name="title" show="$submitted || $dirty && $touched">
                  <div class="red size-3" slot="required">Title is a required field</div>
                  <div class="size-3" slot="maxlen">Title length should not be more than 100 characters</div>
                </field-messages>
              </validate>

              <validate class="mr1">

                <label
                :for="`description_${index}`"
                class="size-2"
                >Description</label>
                <textarea
                class="input size-s"
                :class="{'is-invalid': isValidDescription(index)}"
                :id="`description_${index}`"
                v-model="prompts[index].data.description"
                placeholder="Description"
                maxlen="500"
                name="description" rows="3"/>
                <field-messages name="description" show="$submitted || $dirty && $touched">
                  <div class="size-3" slot="maxlen">Description length should not be more than 500 characters</div>
                </field-messages>
              </validate>

              <validate class="mr1">
                <label :for="`group_${index}`" class="size-2">Group
                </label>
                <input
                type="text"
                class="input size-s"
                :id="`group_${index}`"
                v-model="prompts[index].data.group"
                placeholder="Optional"
                maxlen="64"
                name="group" />
                <field-messages name="group" show="$submitted || $dirty && $touched">
                  <div class="size-3" slot="maxlen">Group length should not be more than 64 characters</div>
                </field-messages>
              </validate>

              <validate class="mr1">
                <label :for="`genre_${index}`" class="size-2">Genre
                </label>
                <input
                type="text"
                class="input size-s"
                :id="`genre_${index}`"
                v-model="prompts[index].data.genre"
                placeholder="Optional"
                maxlen="64"
                name="genre" />
                <field-messages name="genre" show="$submitted || $dirty && $touched">
                  <div class="size-3" slot="maxlen">Genre length should not be more than 64 characters</div>
                </field-messages>
              </validate>

            </div>
          </vue-form>

        </div>
      </div>
      <div class="flex items-center justify-end width+10 my1 bg-blue+5 p1 rounded-2 width+10">
        <button-component
          type="submit"
          variant="success"
          size="size-m"
          :class="{ disabled: isUploadInProgress }"
          @click="onSubmitAll()"
        >Upload</button-component>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import md5 from "md5";
import { CognitoIdentity } from "aws-sdk/clients/all";

export default {
  mounted() {
    window.asd = this;

    this.dropzoneComponent = this.$refs.dropzoneComponent.dropzone;
  },
  data() {

    const vm = this;

    return {


      uploadConfig: {},

      prompts: [],
      promptUniqueIDReached: 1,

      dropzoneComponent: {},

      dropzoneOptions: {
        // The URL will be changed for each new file being processing
        timeout: null,
        maxFilesize: null,
        url: "/",
        // Since we're going to do a `PUT` upload to S3 directly
        method: "put",
        addRemoveLinks: true,

        // // Content-Type should be included, otherwise you'll get a signature
        // // mismatch error from S3. We're going to update this for each file.
        // headers: {},
        // Here we request a signed upload URL when a file being accepted
        accept(file, done) {

          const acceptedFiles = '.jpeg, .jpg, .png, .webp, .tiff, .gif, .svg, .heic, .heif';

          var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);

          if (!acceptedFiles.includes(fileExtension.toLowerCase())) {

            const errMsg = `The file ${vm.$options.filters.truncate(file.name, 64, "...")} is not a supported type.`;

            vm.$toasted.error(errMsg)

            /**
             * Calling done(...) with a parameter (errMsg) to tell Dropzone,
             * that the file type check has failed.
             */
            done(errMsg);

            return;
          }

          file["promptIndex"] = vm.prompts.length;

          const trimmedName = file.name.substr(0, file.name.lastIndexOf('.'));

          vm.prompts.push({
            file,
            done,
            data: {
              title: trimmedName,
              description: '',
            },
            isDirty: false,
            status: "staged",
            formState: {},
            isFormVisible: false,
            id: vm.promptUniqueIDReached
          });

          vm.promptUniqueIDReached++;

          vm.toggleAddPromptToBulkList(vm.prompts[vm.prompts.length - 1]);
        },

        sending(file, xhr) {
          /**
           * Hijack the native send request, since it is not
           * used and only throws an error if present
           */
          xhr.send = () => null;
        },
      },

      isSelectAllOptionApplied: false,
      bulkList: {},
      bulkGroup: '',
      bulkGenre: '',
      bulkLogo: false,

      isUploadInProgress: false
    };

  },
  props: {},
  computed: {
    ...mapGetters({

    }),

    bulkSelectedCount() {

      return Object.keys(this.bulkList).length;
    },
  },
  methods: {
    ...mapActions({
      makeGetImageUploadConfig: "images/makeGetImageUploadConfig",
      makeUploadImageMetaRequest: "images/makeUploadImageMetaRequest",
    }),

    ...mapMutations({

      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      setPendingUploadAsset: 'app/SET_PENDING_UP_ASSET_KEY'
    }),

    togglePromptFormVisible(prompt) {

      const newVal = !prompt.isFormVisible;

      this.prompts.splice(prompt.file.promptIndex, 1, {

        ...prompt,
        isFormVisible: newVal
      });
    },

    toggleSelectAllOptionApplied() {

      const newVal = !this.isSelectAllOptionApplied;

      const updatedBulkList = {};

      if (newVal === true) {

        this.prompts.forEach((prompt) => {

          updatedBulkList[prompt.id] = prompt;
        });
      }

      this.bulkList = updatedBulkList;

      this.isSelectAllOptionApplied = newVal;
    },

    cleanPromptsState(errorsFound) {

      if (errorsFound) {

        const filteredPrompts = this.prompts.filter(p => p.status === 'error');

        this.prompts = filteredPrompts;
        this.promptUniqueIDReached = this.prompts.length + 1;

        return;
      }

      this.prompts = [];
      this.promptUniqueIDReached = 1;
    },

    toggleAddPromptToBulkList(prompt) {

      const updatedBulkList = {
        ...this.bulkList
      };

      if (!updatedBulkList[prompt.id]) {

        updatedBulkList[prompt.id] = prompt;

      } else {

        delete updatedBulkList[prompt.id];
      }

      this.bulkList = updatedBulkList;

      this.evaluateIsSelectAllApplied();
    },

    applyToBulkSelected() {

      const updatedPrompts = this.prompts.map(prompt => {

        if (!this.bulkList[prompt.id]) {

          return prompt;
        }

        return {

          ...prompt,

          data: {

            ...prompt.data,

            group: this.bulkGroup,
            genre: this.bulkGenre,
            logo: this.bulkLogo,
          }
        }
      });

      this.prompts = [...updatedPrompts];

      /**
       * Null the bulk inputs' values
       */
      this.bulkList = {};
      this.isSelectAllOptionApplied = false;
      this.bulkGroup = '';
      this.bulkGenre = '';
      this.bulkLogo = false;
    },

    toggleBulkLogo() {

      this.bulkLogo = !this.bulkLogo;
    },

    togglePromptLogoStatus(prompt, index) {

      const newLogoVal = !prompt.data.logo;

      const updatedPrompt = {

        ...this.prompts[index],

        data: {

          ...prompt.data,
          logo: newLogoVal
        }
      };

      this.prompts.splice(index, 1, updatedPrompt);
    },

    evaluateIsSelectAllApplied() {

      if (this.prompts.length === this.bulkSelectedCount) {

        this.isSelectAllOptionApplied = true;

      } else {

        this.isSelectAllOptionApplied = false;
      }
    },

    onSubmitAll() {

      let isAnyFormInvalid = false;

      for(var i = 0; i < this.prompts.length; i++) {

        const prompt = this.prompts[i];

        prompt.isDirty = true;

        if (prompt.formState.$invalid) {

          isAnyFormInvalid = true;

          /**
           * These calls will display the forms' validation messages
           */
          prompt.isFormVisible = true;
          prompt.formState._submit();
          prompt.formState._validate();
        }
      }

      if (isAnyFormInvalid) {

        this.$toasted.error('Some of the files you selected have incomplete data. Please fill the required fields.')
        return;
      }
      console.log(this.prompts)
// return;
      this.isUploadInProgress = true;

      this.$Amplify.Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;

        this.prompts.forEach(prompt => {

          this.sendUploadRequest(prompt, token);
        });
      });
    },

    sendUploadRequest(prompt, token) {

      prompt["status"] = "submitted";

      const file = prompt.file;

      const meta = {
        title: prompt.data.title,
        originalFileName: file.name,
      };

      if (prompt.data.description) {

        meta.description = prompt.data.description;
      }

      if (prompt.data.group) {

        meta.group = prompt.data.group;
      }

      if (prompt.data.genre) {

        meta.genre = prompt.data.genre;
      }

      if (prompt.data.logo) {

        meta.logo = 'true';
      }

      /**
       * There are problems, uploading NON ASCII strings to S3 in the meta.
       * The makeUploadImageMetaRequest makes it possible to first upload
       * the meta, regardless of the strings in it, then the main upload
       * only requires metaGuid
       */
      this.makeUploadImageMetaRequest({ meta }).then((data) => {

        const augmentedMeta = {

          metaGuid: data.guid
        };

        prompt.file["meta"] = augmentedMeta;

        const {
          bucket,
          bucketRegion,
          cognitoRegion,
          identityPoolId,
          logins
        } = this.uploadConfig;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
          {
            IdentityPoolId: identityPoolId,
            Logins: {
              [logins.cognito]: token
            }
          },
          {
            region: cognitoRegion
          }
        );

        /**
         * Get the file extension, generate an md5 hash of the file name.
         * Add a timestamp to insure an unique key will be generated.
         */
        var extension = file.name.substr(file.name.lastIndexOf(".") + 1);

        const key = `${md5(file.name + new Date().getTime())}.${extension}`;

        this.setPendingUploadAsset({

          key,
          assetData: meta,
          isPending: true
        });

        let params = {
          Bucket: bucket,
          Key: key,
          Body: file,
          Metadata: augmentedMeta
        };

        prompt.status = "loading";

        let s3 = new AWS.S3({
          apiVersion: "2006-03-01",
          httpOptions: { timeout: 0 }
        })
          .putObject( {
            ...params,
            ACL: "bucket-owner-full-control",
            StorageClass: "STANDARD_IA"
          })
          .on('httpUploadProgress', progress => {
            if (progress.total) {

              var percent = (progress.loaded * 100) / progress.total;

              this.dropzoneComponent.emit(
                "uploadprogress",
                file,
                percent,
                progress.loaded
              );

              const updatedFileWithProgress = {

                ...this.prompts[file.promptIndex],
                progressPercentage: percent
              };

              this.prompts.splice(file.promptIndex, 1, updatedFileWithProgress);
            }
          })
          .send((err, data) => {

            if (err) {

              console.error("s3upload err", err);

              const currentPromptIndex = prompt.file.promptIndex;

              this.prompts.splice(currentPromptIndex, 1, {
                ...prompt,
                status: 'error'
              });

              this.onFileUploadFinished(prompt, err);

            } else {

              const currentPromptIndex = prompt.file.promptIndex;

              this.prompts.splice(currentPromptIndex, 1, {
                ...prompt,
                file: {
                  ...prompt.file,
                  uploadURL: data
                },
                status: 'uploaded'
              });

              prompt.done();

              this.onFileUploadFinished(prompt);
            }

          })
      });
    },

    onFileUploadFinished(prompt, err) {

      let areAllFilesDoneUploading = true;
      let isErrorFoundUploading = false;

      for (var i = 0; i < this.prompts.length; i++) {
        /**
        * Prevent the Add Image Modal from closing, since there
        * are still images, that haven't uploaded yet
        */
        if (this.prompts[i].status !== "uploaded" &&
          this.prompts[i].status !== "error") {

          areAllFilesDoneUploading = false;
        }

        if (this.prompts[i].status === "error") {

          isErrorFoundUploading = true;
        }

        if (!areAllFilesDoneUploading && isErrorFoundUploading) {

          break;
        }
      }

      if (err) {

        this.$toasted.error(err);

      } else {

        this.$toasted.success(`${this.$options.filters.truncate(prompt.data.title, 64, "...")} was uploaded successfully.`);
      }

      if (areAllFilesDoneUploading) {

        this.cleanPromptsState(isErrorFoundUploading);

        this.isUploadInProgress = false;
      }
    },

    isValidTitle(index) {
      const prompt = this.prompts[index];

      return !prompt.data.title.trim().length && prompt.isDirty;
    },

    isValidDescription(index) {
      const prompt = this.prompts[index];

      return !prompt.data.description.trim().length && prompt.isDirty;
    },

    unstageFileFromUpload(fileIndex) {

      const prompt = this.prompts[fileIndex];

      const file = prompt.file;

      const updatedBulkList = {

        ...this.bulkList
      };

      delete updatedBulkList[prompt.id];

      this.bulkList = updatedBulkList;

      this.dropzoneComponent.removeFile(file);

      this.prompts.splice(fileIndex, 1);

      this.evaluateIsSelectAllApplied();
    },
  },

  watch: {

    prompts() {

      if (this.prompts.length) {

        this.setRedirectGuard({
          redirectMsg: 'Your image/images may not be uploaded if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    }
  },

  created() {

    this.makeGetImageUploadConfig()
      .then(c => this.uploadConfig = c);
  }
};
</script>

<style lang="scss">


$headerHeight: 46px;
$footerHeight: 68px;

.add-image-component {

  min-height: 200px;

  .staged-images-container {

    max-height: calc(100% - (#{$headerHeight} + #{$footerHeight}));
    overflow: auto;
  }

  .silver {

    @apply text-black-400;
  }

  .main-content {

    height: calc(100% - 6rem);
  }

  #customdropzone {
    background-color: var(--sceneBgColor);
    border-radius: 8px;
    border: 2px dashed var(--sceneBrColor);
    color: var(--sceneColor);
    transition: background-color 0.2s linear;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  #customdropzone:hover {
    background-color: var(--color-blue-50);
    border: 2px dashed var(--windowBrColor);
  }
  #customdropzone.staged-files-present {
    height: 6rem;
  }
  #customdropzone .dz-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  #customdropzone {

    .dz-preview.dz-file-preview,
    .dz-preview.dz-image-preview,
    .dz-error.dz-preview {

      display: none;
    }
  }
}
</style>
