<template>

<section
  class="assign-images-component flex flex-column"
>
<!-- NOTE: this component is working but it is NOT in use. The plan was to use it inside assign-images-modal and as a standalone component where needed. Since it is not certain how every function should work, when full on the fly upload is present, for now only the original <assign-images-modal> is used in the app.-->
  <header class="flex justify-between my2 mb2">


    <input-search-component :onSearchInput="handleInputSearch" placeholder="Search..." class="" style="max-width: 150px; background: transparent"/>

  </header>


  <section class="flex flex-auto flex-column rounded-3 bg-white py2 px3">

    <ul class="list-reset m0 overflow-auto flex flex-auto flex-column" v-if="!isRequestPending && filteredImages.length" ref="libraryScrollArea">
      <li class="size-1 p2 mt2 image-tile flex justify-between items-center border-bottom" :key="image.guid" v-for="image in filteredImages">

        <img :src="image.cdnPath" class="preview-img mr2"/>
        <span class="name medium truncate">{{ image.meta.title }}
        </span>

        <check-component
          type="checkbox"
          label=""
          class="ml2 size-3"
          :val="addedGuidsMap[image.guid]"
          :on-change="() => toggleAddImageToList(image)"/>

      </li>
    </ul>

    <p class="medium gray+1 center m3 p3" v-else-if="!isRequestPending && !filteredImages.length"
      >No images found
    </p>

    <div v-else style="overflow:hidden" class="flex items-center flex-auto">
      <logo-loader-component width="100px" height="100px" />
    </div>

    <footer class="flex items-center justify-center mt2">

      <pagination-component
        class="flex-none"
        :page-selected="imagesSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="imagesSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </footer>

    <footer class="flex justify-center mt2 ratio1-1" v-if="!isRequestPending && filteredImages.length">
      <button-component
        variant="primary"
        intent="success"
        size="size-m"
        @click.stop.prevent="getCollectedImages"
      >{{ addLabel }}</button-component>
    </footer>
  </section>

</section>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isRequestPending: false,
    addedGuidsMap: {},
    addedGuidsImageMap: {},
    collectedImagesCount: 0,
  }),
  props: {

    name: String,
    getAssignedImages: Function,
    initialImages: Object,
    singleChoise: Boolean,
    width: String,
    height: String,
    addLabel: {
      type: String,
      default: 'Add Images'
    }
  },

  created() {

    this.addedGuidsMap = {};
    this.addedGuidsImageMap = {};

    // check possible prev selected images
    for (let imageGuid in this.initialImages) {

      this.toggleAddImageToList(this.initialImages[imageGuid]);
    }

    this.triggerGetImages();
  },

  mounted() {
    window.as = this;
  },

  beforeDestroy() {

    this.unstageFromFiltering('imagesLibrary');
  },

  methods: {

    getCollectedImages() {

      const images = Object.keys(this.addedGuidsMap);

      const imagesFullData = this.addedGuidsImageMap;

      this.getAssignedImages(images, this.addedGuidsImageMap);
    },

    toggleAddImageToList(image) {

      if (this.addedGuidsMap[image.guid]) {

        delete this.addedGuidsImageMap[image.guid];
        delete this.addedGuidsMap[image.guid];

        this.collectedImagesCount--;

      } else {

        if (this.singleChoise) {

          this.addedGuidsMap = {};
          this.addedGuidsImageMap = {};
        }

        this.addedGuidsImageMap[image.guid] = {

          ...image
        };

        this.addedGuidsMap[image.guid] = true;
        this.collectedImagesCount++;
      }
    },



    triggerGetImages() {

      this.unstageFromFiltering('imagesLibrary');

      this.isRequestPending = true;

      return this.getImages().then(resp => {

        this.images = resp;

        this.stageForFiltering({
          items: this.images,
          division: 'imagesLibrary'
        });

        this.applyFiltering({

          filterType: 'imagesLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'imagesLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'imagesLibrary'
      });
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getImages: "images/makeGetImagesRequest",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
    })
  },
  computed: {
    ...mapGetters({
      filteredImages: "filterAndPaginate/filteredImages",
      filteredItemsCount: "filterAndPaginate/filteredImagesItemsCount",
      imagesSearchOptions: "filterAndPaginate/imagesSearchOptions",
    }),
  },
}
</script>

<style lang="scss">



.assign-images-component {
    height: 100%;
    ul {
      overflow: auto;
    }
  .preview-img {
    width: 70px;
    height: 70px;
  }
}
</style>
