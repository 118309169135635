/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" d="M9.029 18.448l.373-5.492 10.226-8.984c.453-.403-.093-.598-.692-.247L6.312 11.502.852 9.814C-.318 9.49-.331 8.698 1.12 8.126L22.385.13c.972-.429 1.904.233 1.531 1.687l-3.622 16.632c-.253 1.182-.985 1.468-1.997.922l-5.513-3.973-2.65 2.506c-.306.299-.56.545-1.105.545z" _fill="#5c7499"/>'
  }
})
