import Vue from 'vue'

import { flattenObject } from "@utils/helpers";

import { AUTH } from '@configs/apiUrls.config.js';



/**
 * Checks if a network request came back fine,
 * and throws an error if not
 */
const checkStatus = (response) => {

  /**
   * Handle successful requests
   */
  if (response.status >= 200 && response.status < 300) {

    return response;
  }

  /**
   * Throw an error if one occurs
   */
  throw {

    code: response.status,
    response
  };
}

/**
 * Parses the JSON returned by a network request
 */
const parseResponse = (response, returnFullResponse, returnResponseHeaders) => {

  // HTTP 204 No Content success status response code
  if (response.status === 204) {

    return response;
  }

  const contentType = response.headers.get("content-type");

  // json || text
  let parseMethod = '';

  if (contentType && contentType.indexOf("application/json") !== -1) {

    parseMethod = 'json';

  } else {

    parseMethod = 'text';
  }


  return response[parseMethod]()
    .then(parsedResp => {

      let finalisedData = {};

      if (returnFullResponse || !parsedResp || !parsedResp.data) {

        finalisedData = parsedResp;

      } else {

        finalisedData = parsedResp.data;
      }

      let finalisedResp = finalisedData;

      if (returnResponseHeaders) {

        finalisedResp = {
          data: finalisedData,
          headers: response.headers
        };
      }

      return finalisedResp;
    });
}

// Handle empty reponse without 204 code
const handleError = (err, url, noDefaultError) => {

  if (!err.response) {

    return;
  }

  return err.response.text().then(errText => {

    const parsedErr = JSON.parse(errText);

    /**
     * Log the error in the console.
     */
    console.error(`An error occured: ${parsedErr}. Code: ${err.code}. URL: ${url}`);

    // VOD throws `error`[Object] key and LIVE - `message`[String]
    const matchedErr = parsedErr.error || parsedErr.message;

    // To be filled with error strings found in the parsedErr
    let errMsgsArray = [];

    /**
     * The matchedErr is an Object, flatten it, save the values
     * to errMsgsArray, so that they can be used in toasts.
     */
    if (typeof matchedErr === 'object') {

      const errMessages = flattenObject(matchedErr);
      errMsgsArray = Object.values(errMessages);

    } else {

      // The matchedErr is a string, save it as an item in the errMsgsArray
      errMsgsArray = [matchedErr];
    }

    /**
     * If no error is found in the error body, stage a default err.
     * If this error is shown, probably the err code is 500
     */
    if (!errMsgsArray.length) {

      errMsgsArray[0] = `Something went wrong! Code: ${err.code}.`;
    }

    /**
     * Display an error toast to the user.
     */
    if (!noDefaultError) {

      errMsgsArray.forEach(msg => {

        Vue.toasted.error(msg);
      });
    }

    /**
     * Let the error bubble to the caller.
     */
    throw {

      code: err.code,
      error: matchedErr
    };
  });
}

/**
 * Requests an URL, options and a token, returns a promise
 */
const request = (params) => {

  const {

    url,
    options,
    token = '',
    returnFullResponse,
    returnResponseHeaders,
    noDefaultError

  } = params;

  // TODO: this env variable should come from a constant
  const env = AUTH.url.split('/').pop();

  const requestOptions = {

    ...options,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // NOTE: As per the following article, to upload files using
  // fetch and FormData, Content-Type header should NOT be set:
  // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
  if (options.headers && options.headers.formDataDeleteContentTypeHeader) {

    delete requestOptions.headers['Content-Type'];
  }

  if (options.headers && options.headers.env) {

    requestOptions.headers['x-api-env'] = env
  }

  if (token) {

    if (token.gitlab) {

      requestOptions.headers['PRIVATE-TOKEN'] = token.gitlab;

    } else {

      requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  return fetch(url, requestOptions)
    .then(checkStatus)
    .then((response) => parseResponse(response, returnFullResponse, returnResponseHeaders))
    .catch(err => handleError(err, url, noDefaultError));
}

export default request // only the `request` function should be used outside
