/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-sign': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 14.546C0 22.578 6.512 29.09 14.546 29.09c8.033 0 14.545-6.512 14.545-14.546C29.09 6.513 22.579 0 14.545 0 6.513 0 0 6.512 0 14.546zm14.546 11.313c6.248 0 11.313-5.066 11.313-11.314S20.793 3.232 14.545 3.232 3.232 8.297 3.232 14.546c0 6.248 5.065 11.313 11.314 11.313zm1.616-12.93h4.04a1.616 1.616 0 010 3.233h-4.04v4.04a1.616 1.616 0 11-3.233 0v-4.04H8.89a1.616 1.616 0 010-3.233h4.04V8.89a1.616 1.616 0 013.233 0v4.04z" _fill="#4F4F4F"/>'
  }
})
