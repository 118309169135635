<template>

<section  class="schedules-scheduler-component flex flex-column flex-auto calendar-section rounded-2">
  <modal
    name="save-as-playlist"
    width="1000"
    height="720"
    classes="v--modal p2 mt2"
    style="overflow: auto"
  >
    <section class="h100 flex flex-column">
      <header class="ratio1-1 flex justify-between p2">

        <h1 class="m0 size+1 mb2">
          New Playlist
        </h1>
        <button-component class="" variant="minimal" shape="circle" @click.stop.prevent="() => closeSaveAsPlaylistModal()">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
        </button-component>

      </header>

      <section class="flex flex-auto">

        <vue-form :state="savePlaylistFormState" @submit.prevent class="ratio3-5 mr2 overflow-y p2 flex flex-column flex-auto">



          <validate class="" tag="label">
            <label class="text-danger">Display Name <span class="text-danger">*</span></label>
            <input
              type="text"
              placeholder="Display Name"
              v-model="newPlaylist.name"
              required
              maxlen="100"
              name="newPlaylistName"/>
            <field-messages name="newPlaylistName" show="$submitted || $dirty && $touched">
              <div slot="required">Display Name is a required field</div>
              <div slot="maxlen">Display name length should not be more than 100 characters</div>
            </field-messages>
          </validate>

          <validate>
            <label>Playlist Name</label>
            <input
              type="text"
              placeholder="Playlist Name"
              v-model="newPlaylist.title"
              maxlen="100"
              name="playlistName"
            >
            <field-messages name="playlistName" show="$submitted || $dirty && $touched">
              <div slot="maxlen">Playlist Name length should not be more than 100 characters</div>
            </field-messages>
          </validate>
          <validate>
            <label>Description</label>
            <input
              type="text"
              placeholder="Description"
              v-model="newPlaylist.description"
              name="description"
              maxlen="500"
            >
            <field-messages name="description" show="$submitted || $dirty && $touched">
              <div slot="maxlen">Description length should not be more than 500 characters</div>
            </field-messages>
          </validate>

          <div>
            <label>Image url</label>
            <input
              type="text"
              placeholder="Image url"
              v-model="newPlaylist.imageUrl"
            >
          </div>

          <div>
            <label>Color</label>

            <swatches
              v-model="newPlaylist.color"
              :colors="possiblePlaylistColors"
              inline
            >
            </swatches>
          </div>

          <div>
            <label>External URL</label>
            <input
              type="text"
              placeholder="External URL"
              v-model="newPlaylist.meta.externalUrl"
            >
          </div>

          <custom-meta-fields-legacy-component
            class="pt2 mt3 border-top"
            :editedAsset="newPlaylist.meta"
            metaResourceType="playlist" />

        </vue-form>
        <aside class="ratio2-5 flex flex-column flex-auto p2">
          <h4 class="m0 mb2">Selected Videos</h4>
          <ul class="list-reset overflow-y flex flex-column flex-auto mb3">

            <li v-for="(source, index) in newPlaylist.sources" :key="index" class="flex justify-between items-center mb2">
              <section class="flex items-center">
                <thumbnail-component class="mr2 rounded-2 overflow-hidden" :duration="parseDuration(source.totalDuration)" :image="source.imageUrl"/>
                <h3 class="m0 size-2 gray">{{source.title}}</h3>
              </section>
              <button-component class="ml2 mr1" variant="minimal" shape="circle" @click.stop.prevent="() => removeSourceFromNewPlaylist(index)">
                <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
              </button-component>
            </li>
          </ul>
          <footer v-if="!isCreatePlaylistInProgress" class="flex justify-end">
            <button-component
              variant="default"
              size="size-m"
              @click.stop.prevent="closeSaveAsPlaylistModal"
            >Cancel</button-component>
            <button-component
              variant="primary"
              class="ml2"
              size="size-m"
              @click="saveRundownAsPlaylist"
            >Save Playlist</button-component>
          </footer>
          <loader-component
            size="size-xs"
            width="41px" v-else/>
        </aside>
      </section>
    </section>
  </modal>

  <modal
    name="schedule-time-edit"
    width="500"
    height="auto"
    classes="v--modal p2"
    style="overflow: auto"
  >

    <vue-form :state="scheduleTimeEditFormState" @submit.prevent class="overflow-y h100 p2">

      <h3 class="m0 mb1">Change where the schedule sits in the rundown</h3>
      <p class="m0 mb3 size-2">Adjusting the schedule's start {{ editedScheduleData.isServiceElement ? 'or end' : '' }} time can affect other schedules in the rundown.
      </p>

      <section>

        <div class="ratio1-1">
          <label class="mb1" for="start-date-time">Start Date & Time</label>

          <!--
            The logic will be difficult to implement with these controls
          -->
          <datetime
            v-model="editedScheduleStartDateTime"
            :min-datetime="editedScheduleMinStartDateTime"
            :max-datetime="editedScheduleMaxStartDateTime"
            input-id="editedStartDateTime"
            type="datetime"
            use12-hour
            placeholder="Start Date Time"
            format="DDD t ZZZZ"
          ></datetime>

        </div>

        <div class="ratio1-1" v-if="editedScheduleData.isServiceElement">
          <label class="mb1" for="end-date-time">End Date & Time</label>

          <datetime
            v-model="editedScheduleEndDateTime"
            :min-datetime="editedScheduleMinEndDateTime"
            :max-datetime="editedScheduleMaxEndDateTime"
            input-id="editedEndDateTime"
            type="datetime"
            use12-hour
            placeholder="End Date Time"
            format="DDD t ZZZZ"
          ></datetime>

        </div>
      </section>

      <footer class="mt3 flex justify-end">
        <button-component
          variant="default"
          @click.stop.prevent="closeScheduleTimeEditModal"
        >Cancel</button-component>
        <button-component
          variant="primary"
          class="ml2"
          @click.stop.prevent="editScheduleTime"
        >Edit</button-component>
      </footer>
    </vue-form>

  </modal>




  <header class="border-bottom tab-toggle flex justify-between mt2">
    <section class="flex">
      <button-component
        variant="tab"
        size="size-m"
        @click="handleProgramTabChange()"
        >Program
      </button-component>

      <button-component
        class="active dummy"
        variant="tab"
        size="size-m"
        >Videos
      </button-component>
    </section>

    <aside class="h100" v-if="isPeriodSelectionApplied && shouldSaveBeMade">
      <button-component
        variant="primary"
        size="size-m"
        class="mr2"
        intent="warning"
        @click="discardSchedulesForPeriod"
        >Discard
      </button-component>
      <button-component
        variant="primary"
        size="size-m"
        class="mr2"
        v-if="activeLibraryPanel !== 'powerBlocks'"
        @click="saveSchedulesForPeriod"
        >Save
      </button-component>

      <button-component
        variant="primary"
        size="size-m"
        v-if="shouldPowerBlocksBeSaved"
        class="mr2"
        @click="saveSchedulesAndListingsForPeriod"
        >Save With Program
      </button-component>

    </aside>

    <aside class="h100" v-if="isPeriodSelectionApplied && !shouldSaveBeMade">
      <button-component
        variant="primary"
        size="size-m"
        class="mr2"
        intent="warning"
        @click="discardSchedulesForPeriod"
        >Cancel
      </button-component>
    </aside>

  </header>

  <section class="flex flex-auto relative">

    <!-- library-panel-selector -->
    <aside class="library-panel-selector flex items-center flex-column flex-none pt3">


      <button-component
        variant="minimal"
        class="mt1 rounded-2 pointer"
        size="size-m"
        @click="() => toggleLibraryPanelOpened('vod')"
        :class="activeLibraryPanel !== 'vod' ? '' : 'active'"
        ><ioio-icon icon="fas-file-video"/></button-component>

      <button-component
        variant="minimal"
        class="mt1 rounded-2 pointer"
        size="size-m"
        @click="() => toggleLibraryPanelOpened('playlist')"
        :class="activeLibraryPanel !== 'playlist' ? '' : 'active'"
        ><ioio-icon icon="fas-list"/></button-component>

      <button-component
        variant="minimal"
        class="mt1 rounded-2 pointer"
        size="size-m"
        @click="() => toggleLibraryPanelOpened('powerBlocks')"
        :class="activeLibraryPanel !== 'powerBlocks' ? '' : 'active'"
        ><ioio-icon icon="fas-power-off"/></button-component>

      <button-component
        variant="minimal"
        class="mt1 rounded-2 pointer"
        size="size-m"
        @click="() => toggleLibraryPanelOpened('live')"
        :class="activeLibraryPanel !== 'live' ? '' : 'active'"
        ><ioio-icon icon="fas-share-nodes"/></button-component>

      <button-component
        variant="minimal"
        class="mt1 rounded-2 pointer"
        size="size-m"
        @click="() => toggleLibraryPanelOpened('midroll')"
        :class="activeLibraryPanel !== 'midroll' ? '' : 'active'"
        ><ioio-icon icon="fas-align-center"/></button-component>
    </aside>


    <!-- opened-library-type - vod, playlist, midroll... -->
    <article class="opened-library-section px1 pt2 flex flex-column flex-auto">
      <h3 class="m0">{{ libPanelConfig[activeLibraryPanel].header }}</h3>

      <library-component
        v-if="activeLibraryPanel === 'vod'"
        class="flex flex-column flex-auto"
        :shouldDisplaySourceCtrls="false"
        :shouldDisplayAddSourceCtrl="false"
        :shouldHideInactiveVods="true" />

      <playlists-tab-component
        class="flex flex-auto"
        v-if="activeLibraryPanel === 'playlist' || activeLibraryPanel === 'powerBlocks'"
        :on-playlist-edit="(p) => {}"
        selectedPlaylistIdForEdit="''"
        ref="playlistTab" />

      <live-tab-component
        class="flex flex-auto mb2"
        v-if="activeLibraryPanel === 'live'"
        ref="liveTab" />

      <p class="flex flex-auto"
        v-if="activeLibraryPanel === 'midroll'"
        ref="midrollTab" >Comming soon...</p>
    </article>


    <!-- rundown-scheduler -->
    <article class="rundown-scheduler flex flex-auto flex-column">

      <section v-if="!isPeriodSelectionApplied" class="flex flex-auto items-center justify-center initial-period-selector relative">

        <article class="flex flex-column flex-auto items-center ratio1-2">
          <h4 class="m0 mb1 size+1">Please select dates for your program</h4>
          <p class="m0 mb3 size-1">You can select up to 48 hours.</p>
          <header class="time-selector px2">
            <div class="mb2">
              <label class="mb1 medium" for="start-date-time">Start Date & Time</label>

              <!--
                The logic will be difficult to implement with these controls
                :min-datetime="minPeriodStart"
                :max-datetime="selectedPeriodEnd"
              -->
              <datetime
                v-model="selectedPeriodStart"
                :min-datetime="minPeriodStart"
                input-id="startDateTime"
                type="datetime"
                use12-hour
                placeholder="Start Date Time"
                format="DDD t ZZZZ"
              ></datetime>

            </div>

            <div class="mb3 pt1">
              <label class="mb1 medium" for="end-date-time">End Date & Time</label>

              <!--
                The logic will be difficult to implement with these controls

                :min-datetime="selectedPeriodStart"
                :max-datetime="maxPeriodEnd"
              -->
              <datetime
                v-model="selectedPeriodEnd"
                input-id="endDateTime"
                type="datetime"
                use12-hour
                placeholder="End Date Time"
                format="DDD t ZZZZ"
              ></datetime>

            </div>

          </header>
          <footer class="ratio1-2 px2 time-selector-footer">
            <button-component
              variant="primary"
              size="size-m ratio1-1"
              @click.stop.prevent="loadPeriod"
            >Set Time</button-component>
          </footer>

        </article>
      </section>

      <section v-if="isPeriodSelectionApplied" class="flex flex-column relative flex-auto rundown-list p2"
      :class="isSelectedChannelLocked() ? 'locked' : ''">

        <ioio-icon icon="fas-gear" class="locked-icon"/>

        <header class="flex justify-end items-center m2">
          <!--h3 class="m0">Video Program</h3-->

          <h4 class="m0 size-1 medium">
            <div class="period-change-label inline-block rounded-2"
              >{{ new Date(selectedPeriodStart).toDateString() }} - {{ new Date(selectedPeriodEnd).toDateString() }}
            </div>
          </h4>
        </header>

        <section v-if="!isRequestPending" class="h100 flex-auto flex flex-column relative width+10">
          <header class="flex px2 rundown-header gray+1 ml2 mb2 mt1">
            <div class="ml1 schedule-marker-header mr3">Start Time</div>
            <div class="schedule-header px2 mr3">Video</div>
            <div class="program-block-header ml1">Program Block</div>
          </header>
          <DraggableHolderComponent
            group-name="library-playlist"
            @drop="addScheduleToRundown"
            :should-accept-drop="shouldAcceptDrop"
            :get-child-payload="getChildPayload"
            :get-ghost-parent="getGhostParent"
            drag-class="schedule-ghost-element p1"
            class="schedules-list flex-auto flex px2 flex-column relative width+10 overflow-auto"
          >
            <DraggableComponent v-for="(schedule, i) in loadedSchedules" :key="i"
              class="schedule-tile-box-wrapper mb2"
            >
              <div
                class="schedule-tile-box flex items-center width+10 overflow-hidden rounded-2 pl2"
                :class="getScheduleClass(schedule)"
                @mouseenter.prevent="() => onScheduleHover(schedule, i)"
                @mouseleave.prevent="() => onScheduleHover(null)">

                <div class="schedule-marker rounded-1 px1 flex items-center justify-center pointer ml2 mr3" :class="[i === 0 ? 'schedule-start-end' : '', schedule.type ]" @click="() => openScheduleTimeEditModal(i)">
                  <span>{{getScheduleStartMarker(schedule)}}</span>
                </div>
                <div class="flex flex-auto" v-if="!schedule.isServiceElement">
                  <thumbnail-component class="mx2 schedule-thumb" :type="schedule.meta && schedule.meta.type" :duration="parseDuration(schedule.totalDuration + (schedule.startAdjustment || 0) + (schedule.endAdjustment || 0))" :image="schedule.meta && schedule.meta.imageUrl"/>

                  <div class="schedule-meta flex">

                    <div v-if="schedule.type === 'live'" class="ratio1-1 flex items-center flex-column mr2">
                      <h4 class="m0 mb1 truncate size-1">{{ schedule.meta.title }}</h4>
                      <h6 class="m0 truncate">{{ schedule.url }}</h6>
                    </div>
                    <h4 class="m0 break-word ratio1-1 flex items-center mr2 size-1" v-else>{{ (schedule.meta.title || schedule.meta.name) | truncate(45, "...")}}</h4>

                    <!--div class="ratio2-7 flex items-center">
                      <div v-if="schedule.startAdjustment" class="mr1 gray+1"
                        >Video overflow before visible period: {{ parseDuration(schedule.startAdjustment) }}
                      </div>


                      <div v-if="schedule.endAdjustment" class="ml1 gray+1"
                        >Video overflow after visible period: {{ parseDuration(schedule.endAdjustment) }}
                      </div>
                    </div-->
                  </div>

                  <div class="flex flex-auto items-center overflow-auto mr1">
                    <tag-component
                      v-for="(listing, index) in getUnderlayingListings(schedule)"
                      :key="index"
                      class="fileitem-group m1 white"
                      :style="{ backgroundColor: listing.color }">
                      {{ listing.title }}
                    </tag-component>
                  </div>
                </div>

                <div class="flex flex-auto items-center justify-between" v-else>
                  <h4 class="m0 mx2 size-1">
                    {{ parseDuration(schedule.totalDuration) }}
                  </h4>
                  <h4 class="m0 mx2">
                    <span class="gray+1 ml2">Empty time</span>
                  </h4>
                </div>


                <aside class="schedule-edit-options flex justify-end">

                  <button-component
                    variant="minimal"
                    size="size-xs"
                    v-for="option in availableScheduleOptions"
                    :key="option.icon"
                    @click="triggerScheduleAction([option.action], [i])"
                  >
                    <ioio-icon :icon="option.icon"/>
                  </button-component>
                </aside>
              </div>
            </DraggableComponent>


          </DraggableHolderComponent>

          <footer class="flex justify-end mt1">
            <button-component
              variant="default"
              size="size-s"
              @click.stop.prevent="openSaveSchedulesAsPlaylistModal"
            >Save As Playlist</button-component>
          </footer>
        </section>
        <div class="rundown-loader-container overflow-hidden flex items-center flex-auto" v-else>
          <logo-loader-component width="50px" height="50px" />
        </div>

      </section>

    </article>

  </section>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS, paletteColors } from "@utils/helpers";


export default {
  data: () => ({

    isRequestPending: false,

    activeLibraryPanel: 'vod',
    libPanelConfig: {
      vod: {
        header: 'All Videos',
        component: ''
      },
      playlist: {
        header: 'Playlists',
        component: ''
      },
      powerBlocks: {
        header: 'Power Block',
        component: ''
      },
      live: {
        header: 'Live Video',
        component: ''
      },
      midroll: {
        header: 'Midrolls',
        component: ''
      },
    },

    selectedPeriodStart: '',
    minPeriodStart: '',
    selectedPeriodEnd: '',

    allowLoadedPeriodSpanMs: 172800000, // 48 hours

    isPeriodSelectionApplied: false,
    isInitialSelectionMade: false,

    isFirstScheduleDropped: false,
    loadedSchedules: [],
    listingsForPeriod: [],

    dragOrigin: "schedules-scheduler",

    availableScheduleOptions: [{
      icon: 'fas-xmark',
      action: 'deleteSchedule'
    }],

    serviceElementsCount: 0,



    scheduleTimeEditFormState: {},
    editedScheduleData: {},
    editedScheduleIndex: 0,
    editedScheduleStartDateTime: null,
    editedScheduleMinStartDateTime: null,
    editedScheduleMaxStartDateTime: null,
    editedScheduleEndDateTime: null,
    editedScheduleMinEndDateTime: null,
    editedScheduleMaxEndDateTime: null,



    savePlaylistFormState: {},
    newPlaylist: { meta: {}, sources: [] },
    possiblePlaylistColors: [...paletteColors()],
    isCreatePlaylistInProgress: false,

    shouldSaveBeMade: false,

    shouldPowerBlocksBeSaved: false,
  }),
  props: {},

  mounted() {

    window.s= this;

    this.preselectDefaultStartEndDates();
    this.toggleSelectPeriodOpened(true);
  },
  methods: {

  isSelectedChannelLocked() {

    return this.lockedChannelsGuids[this.channelsMeta.selected.guid];
  },

   onScheduleHover(schedule, index) {

      this.setScheduleHoverData(schedule || null);
    },

    removeSourceFromNewPlaylist(index) {

      if (this.newPlaylist.sources.length <= 1) {

        this.$toasted.error('At least on source should be selected.');
        return;
      }

      const updatedSources = [...this.newPlaylist.sources];

      updatedSources.splice(index, 1);

      this.newPlaylist = {

        ...this.newPlaylist,
        sources: updatedSources
      };
    },

    preselectDefaultStartEndDates() {

      const hour = 1000 * 60 * 60;
      const now = new Date();

      // NOTE: forStartMinBufer will be twice as the deadzone, since once you choose a start
      // if it's just on the deadzone, you will enter it immediately and all checks will prevent
      // you from editing rundown chunks, that are affected.
      const forStartMinBufer =  this.deadzoneBufferMs * 2;
      const minPeriodStart = new Date(now.getTime() + forStartMinBufer);
      const selectedPeriodStart = new Date(Math.ceil(now.getTime() / hour) * hour); // round to hour to avoid weird loading of seconds and minutes in the UI
      // selectedPeriodStart.setTime(selectedPeriodStart.getTime() + this.deadzoneBufferMs); // adjust for the deadZone
      const selectedPeriodEnd = new Date(selectedPeriodStart.getTime());
      selectedPeriodEnd.setDate(selectedPeriodEnd.getDate() + 1);

      this.minPeriodStart = minPeriodStart.toISOString();
      this.selectedPeriodStart = selectedPeriodStart.toISOString();
      this.selectedPeriodEnd = selectedPeriodEnd.toISOString();
    },

    openSaveSchedulesAsPlaylistModal() {

      let firstRundownSchedule;

      const sources = this.loadedSchedules.reduce((filtered, s) => {

        if (!s.isServiceElement) {

          filtered.push({
            guid: s.guid,
            type: s.type,

            // needed only for the UI and NOT the request to BE
            title: s.meta.title,
            imageUrl: s.meta.imageUrl,
            totalDuration: s.totalDuration
          });

          if (!firstRundownSchedule) {

            firstRundownSchedule = s;
          }
        }

        return filtered;
      }, []);

      console.log(123, sources)

      if (!sources.length) {

        this.$toasted.error('No schedules in the rundown');
        return;
      }


      const firstRundownScheduleMeta =
        firstRundownSchedule.meta || {};

      this.newPlaylist = {

        title: firstRundownScheduleMeta.title || '',
        imageUrl: firstRundownScheduleMeta.imageUrl || '',
        description: firstRundownScheduleMeta.description || '',
        color: firstRundownScheduleMeta.color || '',
        name: firstRundownScheduleMeta.title || '',
        meta: {
          externalUrl: firstRundownScheduleMeta.externalUrl || ''
        },
        sources,
      };

      this.$modal.show('save-as-playlist');
    },

    closeSaveAsPlaylistModal() {

      this.$modal.hide('save-as-playlist');
      this.newPlaylist = {
        meta: {},
        sources: []
      };
    },

    saveRundownAsPlaylist() {

      if (this.savePlaylistFormState.$invalid) {

        this.savePlaylistFormState._submit();
        return;
      }

      this.isCreatePlaylistInProgress = true;

      this.makeSavePlaylistRequest(this.newPlaylist).then(s => {


        this.$toasted.success('Playlist saved successfully.');
        this.closeSaveAsPlaylistModal();
      })
      .finally(() => {

        this.isCreatePlaylistInProgress = false;
      });
    },

    openScheduleTimeEditModal(index) {

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the rundown.');

        return;
      }

      const forEditSchedule = this.loadedSchedules[index];

      if (forEditSchedule.isInDeadzone) {

        this.$toasted.error('You can not make changes to a running or protected rundown.');
        return;
      }

      if (forEditSchedule.endAdjustment || forEditSchedule.startAdjustment) {

        this.$toasted.error('Elements that span outside the loaded period can not be deleted.');
        return;
      }


      this.editedScheduleData = {

        ...forEditSchedule
      };

      this.editedScheduleIndex = index;

      const lastSchedule = this.loadedSchedules[this.loadedSchedules.length - 1];
      const gapAtEnd = lastSchedule.isServiceElement && (lastSchedule.totalDuration);

      if (!gapAtEnd) {
        this.$toasted.error('There is no gap at the and and the schedule doesn\'t have place to be adjusted.');
        return;
      }

      const editedScheduleEnd =
        this.editedScheduleData.start + this.editedScheduleData.totalDuration;


        this.editedScheduleStartDateTime = new Date(this.editedScheduleData.start).toISOString();
        this.editedScheduleEndDateTime = new Date(editedScheduleEnd).toISOString();
      // if (this.editedScheduleData.isServiceElement) {

        this.editedScheduleMinStartDateTime = this.editedScheduleStartDateTime;


        const maxStartTime = new Date(this.editedScheduleStartDateTime).getTime() + gapAtEnd; // + whatever gap is present at the end
        this.editedScheduleMaxStartDateTime = new Date(maxStartTime).toISOString();
      // } else {

        this.editedScheduleMinEndDateTime = this.editedScheduleStartDateTime;


        const maxEndTime = new Date(this.editedScheduleEndDateTime).getTime() + gapAtEnd; // + whatever gap is present at the end
        this.editedScheduleMaxEndDateTime = new Date(maxEndTime).toISOString();
      // }

      this.$modal.show('schedule-time-edit');
    },

    closeScheduleTimeEditModal() {

      this.$modal.hide('schedule-time-edit');
    },

    editScheduleTime() {

      if (this.scheduleTimeEditFormState.$invalid) {

        this.scheduleTimeEditFormState._submit();
        return;
      }

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the rundown.');

        return;
      }

      let schedulesForUpdate = [...this.loadedSchedules];

      const newStartTimestamp = new Date(this.editedScheduleStartDateTime).getTime();
      const newEndTimestamp = new Date(this.editedScheduleEndDateTime).getTime();

      let startGapAfterEdit = newStartTimestamp - this.editedScheduleData.start;

      const editedScheduleEnd =
        this.editedScheduleData.start + this.editedScheduleData.totalDuration;


      let endGapAfterEdit = newEndTimestamp - editedScheduleEnd; // can be negative!

      if (this.editedScheduleData.isServiceElement && newEndTimestamp <= newStartTimestamp) {

        this.$toasted.error('End date should be after start date.');
        return;
      }

      const lastSchedule = schedulesForUpdate[schedulesForUpdate.length - 1];


      let forAdjustmentMs = 0;

      // end has to be adjusted
      if (this.editedScheduleData.isServiceElement && endGapAfterEdit) {

        forAdjustmentMs += endGapAfterEdit;

        schedulesForUpdate[this.editedScheduleIndex].totalDuration += endGapAfterEdit;
      }

      // startElement has to be spliced in
      if (startGapAfterEdit) {

        if (this.editedScheduleData.isServiceElement) {

          forAdjustmentMs -= startGapAfterEdit;
          schedulesForUpdate[this.editedScheduleIndex].totalDuration -= startGapAfterEdit;
        }

        schedulesForUpdate.splice(this.editedScheduleIndex, 0, {
          isServiceElement: true,
          start: this.editedScheduleData.start,
          totalDuration: startGapAfterEdit
        });

        forAdjustmentMs += startGapAfterEdit;
      }

      let isLastScheduleRemoved = false;

      if (forAdjustmentMs >= lastSchedule.totalDuration) {

        // since the datepicker is not accurate to the second,
        // difference up to 1 minute can be rounded
        forAdjustmentMs = lastSchedule.totalDuration;

        // delete lastSchedule, since it's length will be 0
        schedulesForUpdate.pop();
        isLastScheduleRemoved = true;
      }

      if (!isLastScheduleRemoved) {

        lastSchedule.totalDuration -= forAdjustmentMs;
      }

      schedulesForUpdate = this.calcAdjustSchedulesStart(schedulesForUpdate);


      this.loadedSchedules = [...schedulesForUpdate];

      this.setTempSchedulesSelection(this.loadedSchedules);

      this.closeScheduleTimeEditModal();

      this.shouldSaveBeMade = true;
    },

    discardSchedulesForPeriod() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.shouldSaveBeMade = false;
            this.shouldPowerBlocksBeSaved = false;

            this.toggleSelectPeriodOpened(true);
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        this.shouldSaveBeMade = false;
        this.shouldPowerBlocksBeSaved = false;

        this.toggleSelectPeriodOpened(true);
      }
    },

    saveSchedulesForPeriod() {

      if (!this.loadedSchedules.length) {

        return;
      }

      const firstPossibleScheduleIndex =
        this.loadedSchedules[0].startAdjustment ? 1 : 0;

      const lastScheduleI = this.loadedSchedules.length - 1;

      const lastPossibleScheduleIndex =
        this.loadedSchedules[lastScheduleI].endAdjustment ? lastScheduleI - 1 : lastScheduleI;

      const firstSchedule = this.loadedSchedules[firstPossibleScheduleIndex];
      const lastSchedule = this.loadedSchedules[lastPossibleScheduleIndex];
      const lastScheduleEnd = lastSchedule.start + lastSchedule.totalDuration;

      // Nothing to save
      if (!firstSchedule || !lastSchedule || firstSchedule.start === lastSchedule.start) {

        return;
      }

      const newSchedules = [];

      const unableToBeSaved = [];

      let firstScheduleStartForSave = !firstSchedule.isInDeadzone && firstSchedule.start;

      let lastScheduleEndForSave = !firstSchedule.isInDeadzone && firstSchedule.start;

      for (let i = firstPossibleScheduleIndex; i <= lastPossibleScheduleIndex; i++) {

        const currentSchedule = this.loadedSchedules[i];

        if (currentSchedule.isServiceElement) {

          // If the forlast schedule isServiceElement, but the last one spans
          // outside the loaded period, the serviceElement's end should
          // be considered last (for the BE to overwrite whatever was there)
          lastScheduleEndForSave = currentSchedule.start + currentSchedule.totalDuration;

          continue;
        }

        if (currentSchedule.isInDeadzone) {

          unableToBeSaved.push(currentSchedule);
          continue;
        }

        const newSchedule = {

          start: currentSchedule.start,
          guid: currentSchedule.guid,
          type: currentSchedule.type,
        };

        currentSchedule.ads && (newSchedule.ads = currentSchedule.ads);

        if (currentSchedule.type === 'live') {

          newSchedule.end = currentSchedule.start + currentSchedule.totalDuration;
          newSchedule.url = currentSchedule.url;

          // TODO: question: if the schedule is of type LIVE, it's title should come from the currentSchedule.meta.title, but since the BE doesn't accept title, when guid is sent [slateSourceGuid], the meta for the live schedule automatically comes from the slateSourceGuid VOD and the title, that the user entered is lost.
        }

        if (!firstScheduleStartForSave) {

          firstScheduleStartForSave = currentSchedule.start;
        }

        lastScheduleEndForSave = currentSchedule.start + currentSchedule.totalDuration;

        newSchedules.push(newSchedule);
      }

      const payload = {

        start: firstScheduleStartForSave,
        end: lastScheduleEndForSave,
        schedules: newSchedules,
        channel: this.selectedChannel.guid,
      };

      console.log('storeSchedulesToBE;',payload)

      this.isRequestPending = true;

      this.saveMultipleSchedules(payload)
        .then(() => {

          this.toggleSelectPeriodOpened(true);

          this.shouldSaveBeMade = false;
          this.shouldPowerBlocksBeSaved = false;

          this.$toasted.success('The rundown was saved successfully');
        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },

    saveListingsAfterPowerBlockChange() {

      const listingStartAdjustment = this.listingsForPeriod[0].startAdjustment || 0;
      const listingEndAdjustment = this.listingsForPeriod[this.listingsForPeriod.length - 1].endAdjustment || 0;

      const payload = {

        start: this.listingsForPeriod[0].start - listingStartAdjustment,
        end: this.listingsForPeriod[this.listingsForPeriod.length - 1].end + listingEndAdjustment,
        listings: this.listingsForPeriod,
        channel: this.selectedChannel.guid,
      };

      // The firt element should NOT be modified, since it start outside the loaded area.
      if (listingStartAdjustment) {

        payload.listings.shift();
        payload.start = payload.listings[0].start;
      }

      // The last element should NOT be modified, since it start outside the loaded area.
      if (listingEndAdjustment) {

        payload.listings.pop();
        payload.end = payload.listings[payload.listings.length - 1].end;
      }

      this.areCalendarListingsLoading = true;

      this.saveMultipleListings(payload)
        .then(() => {

          this.$toasted.success('Your program block changes were saved successfully.');

          this.shouldPowerBlocksBeSaved = false;
        })
        .finally(() => {

          this.areCalendarListingsLoading = false;
        });
    },

    saveSchedulesAndListingsForPeriod() {

      console.log(98, 'saveSchedulesAndListingsForPeriod', this.loadedSchedules, this.listingsForPeriod)

      // return;

      this.saveSchedulesForPeriod();
      this.saveListingsAfterPowerBlockChange();
    },

    triggerScheduleAction(actionName, args) {

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the rundown.');

        return;
      }

      this[actionName](...args);
    },

    deleteSchedule(removedIndex) {

      console.log(removedIndex);

      let forUpdateSchedules = [...this.loadedSchedules];

      const removedElement = forUpdateSchedules[removedIndex];

      if (removedElement.isInDeadzone) {

        this.$toasted.error('You can not make changes to a running or protected rundown.');
        return;
      }

      if (removedElement.endAdjustment || removedElement.startAdjustment) {

        this.$toasted.error('Elements that span outside the loaded period can not be deleted.');
        return;
      }

      const removedElementDuration = removedElement.totalDuration;
      const removedElementEnd = removedElement.start + removedElementDuration;


      const elementAfterRemovedOne = forUpdateSchedules[removedIndex + 1];

      const currentLastElement = forUpdateSchedules[forUpdateSchedules.length - 1];
      const currentLastElementIndex = forUpdateSchedules.length - 1;
      const currentLastElementEnd = currentLastElement.start + currentLastElement.totalDuration;

      // If the deleted element isServiceElement, rearange every element after it with start -= deletedServiceEl.totalDuration and place a new service Element at the back(or enlarge the already present final service element)
      if (this.loadedSchedules[removedIndex].isServiceElement) {

        this.serviceElementsCount--;

        let currentLastEditableIndex = currentLastElementIndex;

        // The last element spans outside the loaded period and can not be moved or adjusted.
        // Use the one before it.
        if (currentLastElement.endAdjustment) {

          currentLastEditableIndex -= 1;
        }

        const currentLastEditableElement = forUpdateSchedules[currentLastEditableIndex];
        const currentLastEditableElementEnd =
          currentLastEditableElement.start + currentLastEditableElement.totalDuration;

        if (!currentLastEditableElement.isServiceElement) {

          // add service element at the back
          forUpdateSchedules.splice(currentLastEditableIndex + 1, 0, {

            isServiceElement: true,
            start: currentLastEditableElementEnd,
            totalDuration: removedElementDuration
          })

          this.serviceElementsCount++;

          forUpdateSchedules.splice(removedIndex, 1);

        } else if (currentLastEditableIndex !== removedIndex) {

          // The last element isServiceElement and should absorb the removedDuration
          currentLastEditableElement.start -= removedElementDuration;
          currentLastEditableElement.totalDuration += removedElementDuration;

          forUpdateSchedules.splice(removedIndex, 1);
        }

        forUpdateSchedules = this.calcAdjustSchedulesStart(forUpdateSchedules);

      } else {

        forUpdateSchedules.splice(removedIndex, 1, {

            isServiceElement: true,
            start: removedElement.start,
            totalDuration: removedElementDuration
        });

        this.serviceElementsCount++;
      }

      this.loadedSchedules = forUpdateSchedules;


      if (this.loadedSchedules.length === 0) {

        // replace last schedule with gap element
        this.loadedSchedules = this.calcSchedulesAndGaps(forUpdateSchedules);
      }

      this.setTempSchedulesSelection(this.loadedSchedules);

      this.shouldSaveBeMade = true;
    },

    getUnderlayingListings(schedule) {

      const underlayingListings = [];
      const scheduleEnd = schedule.start + schedule.totalDuration;

      for (let i = 0; i < this.listingsForPeriod.length; i++) {

        const currentL = this.listingsForPeriod[i];

        if (
          (schedule.start <= currentL.start && scheduleEnd > currentL.start) ||
          (schedule.start >= currentL.start && scheduleEnd <= currentL.end) ||
          (schedule.start < currentL.end && scheduleEnd > currentL.end)
        ) {

          underlayingListings.push(currentL);

        } else if (currentL.start > scheduleEnd) {

          break;
        }
      }

      if (!underlayingListings.length) {

        underlayingListings.push({
          title: 'No Program Block',
          color: 'black'
        });
      }

      return underlayingListings;
    },

    calcSchedulesAndGaps(schedules) {

      const startStamp = new Date(this.selectedPeriodStart).getTime();
      const endStamp = new Date(this.selectedPeriodEnd).getTime();

      let currentGap = endStamp - startStamp;

      const parsedSchedules = [];

      let prevScheduleEnd = startStamp;

      // schedules.splice(1, schedules.length-1)

      for (let i = 0; i < schedules.length; i++) {

        const currentSchedule = schedules[i];

        this.adjustIsScheduleInDeadzone(currentSchedule);

        const gap = currentSchedule.start - prevScheduleEnd;

        if (gap > 0) {

          // insert gap service schedule

          parsedSchedules.push({

            isServiceElement: true,
            start: prevScheduleEnd,
            totalDuration: gap
          });

          this.adjustIsScheduleInDeadzone(parsedSchedules[parsedSchedules.length - 1]);

          currentGap -= gap;

          this.serviceElementsCount++;

          prevScheduleEnd += gap;

          parsedSchedules.push(currentSchedule);

          prevScheduleEnd += currentSchedule.totalDuration;
        }

        if (gap < 0) {

          const startAdjustment = Math.abs(gap);

          // adjust schedule's start
          currentSchedule.start = prevScheduleEnd;
          currentSchedule.srcDuration && (currentSchedule.srcDuration -= startAdjustment);
          currentSchedule.totalDuration -= startAdjustment;
          currentSchedule.startAdjustment = startAdjustment;

          prevScheduleEnd += currentSchedule.totalDuration;

          parsedSchedules.push(currentSchedule);
        }


        if (gap === 0) {

          parsedSchedules.push(currentSchedule);

          prevScheduleEnd += currentSchedule.totalDuration;
        }

        currentGap -= currentSchedule.totalDuration;
      }

      if (currentGap > 0) {

        // insert gap service schedule at end
        parsedSchedules.push({

          isServiceElement: true,
          start: prevScheduleEnd,
          totalDuration: currentGap
        });

        this.adjustIsScheduleInDeadzone(parsedSchedules[parsedSchedules.length - 1]);

        this.serviceElementsCount++;

      } else {

        // adjust last schedule end to loaded period end

        const endAdjustment = Math.abs(currentGap);
        const lastSchedule = parsedSchedules[parsedSchedules.length - 1];

        lastSchedule.endAdjustment = endAdjustment;
        lastSchedule.end -= endAdjustment;
        lastSchedule.totalDuration -= endAdjustment;
        lastSchedule.srcDuration && (lastSchedule.srcDuration -= endAdjustment);
      }
      console.log(98, currentGap, this.serviceElementsCount, parsedSchedules)

      return parsedSchedules;
    },

    calcAdjustSchedulesStart(schedules) {

      let accumulatedStart =
        new Date(this.selectedPeriodStart).getTime();

      for (let i = 0; i < schedules.length; i++) {

        const currSchedule = schedules[i];
        currSchedule.start = accumulatedStart;

        this.adjustIsScheduleInDeadzone(currSchedule);

        accumulatedStart += currSchedule.totalDuration;
      }

      return schedules;
    },

    adjustIsScheduleInDeadzone(schedule) {

      if (!schedule.isInDeadzone) {

        const deadzoneEnd = new Date();
        deadzoneEnd.setTime(deadzoneEnd.getTime() + this.deadzoneBufferMs);

        const deadzoneEndStamp = deadzoneEnd.getTime();

        if (schedule.start <= deadzoneEndStamp) {

          schedule.isInDeadzone = true;
        }
      }
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the rundown.');

        return false;
      }

      if (payload &&
        (payload.origin === "library" ||
        payload.origin === "playlist" ||
        payload.origin === 'live-tab' ||
        payload.origin === 'schedules-scheduler')) {

        return true;
      }

      return false;
    },


    addScheduleToRundown(dragData) {

      console.log(123, dragData);

      if (dragData.addedIndex === null) {
        return;
      }

      /**
      Check if the  user is adding an item in a disabled
      period (past time || in the buffer period)
      */
      if (this.loadedSchedules[dragData.addedIndex] && this.loadedSchedules[dragData.addedIndex].isInDeadzone ||
        dragData.removedIndex !== null && this.loadedSchedules[dragData.removedIndex].isInDeadzone) {

        this.$toasted.error('You can not make changes to a running or protected rundown.');
        return;
      }

      if (dragData.payload.item.status === 'error') {

        this.$toasted.error('A video with status `Error` can not be used inside a rundown.');
        return;
      }

      if (dragData.payload.item.status === 'encode-later') {

        this.$toasted.error('A video with status `Encode Later` can not be used inside a rundown.');
        return;
      }

      if (dragData.payload.item.startAdjustment ||
        this.loadedSchedules[dragData.addedIndex] && this.loadedSchedules[dragData.addedIndex].startAdjustment) {

        this.$toasted.error('The video you are trying to move has it\'s start before the loaded period.');
        return;
      }

      if (dragData.payload.item.endAdjustment ||
        this.loadedSchedules[dragData.addedIndex] && this.loadedSchedules[dragData.addedIndex].endAdjustment) {

        this.$toasted.error('The video you are trying to move has it\'s end after the loaded period.');
        return;
      }

      //check the item on place of the drop and if it has endAdjustment return

      dragData.payload.item = {
        ...dragData.payload.item,
        totalDuration:
          dragData.payload.item.totalDuration ||
          dragData.payload.item.srcDuration,
        type: dragData.payload.item.type || dragData.payload.item.meta && dragData.payload.item.meta.type
      };

      let forUpdateSchedules = [...this.loadedSchedules];


      /**
       * The drop handler is triggered from a swap
       */
      if (dragData.removedIndex !== null) {
        console.log('swap');

        forUpdateSchedules.splice(dragData.removedIndex, 1);

      } else if (!this.isFirstScheduleDropped) {
      // check if is first real schedule

        this.isFirstScheduleDropped = true;
        forUpdateSchedules[0].totalDuration -= dragData.payload.item.totalDuration;

      } else if (this.isFirstScheduleDropped) {

        let isSpaceFound = false;
        // findNearestGapIndex
        for (let i = dragData.addedIndex; i < forUpdateSchedules.length; i++) {

          const currentSchedule = forUpdateSchedules[i];

          if (currentSchedule.isServiceElement) {

            const availableSpace = currentSchedule.totalDuration;

            // schedule wil NOT fit
            if (availableSpace < dragData.payload.item.totalDuration) {

              this.$toasted.error('Not enough space.');
              return;
            }

            isSpaceFound = true;
            currentSchedule.totalDuration -= dragData.payload.item.totalDuration;

            if (currentSchedule.totalDuration === 0) {

              // the empty space is taken entirely => remove the service element
              forUpdateSchedules.splice(i, 1);
            }
            break;
          }
        }

        if (!isSpaceFound) {

          this.$toasted.error('Schedule won\'t fit where you dropped it.');
          return;
        }
      }

      const prevSchedule = forUpdateSchedules[dragData.addedIndex] || {};

      const prevScheduleEnd = prevSchedule.start ?
        prevSchedule.start + prevSchedule.totalDuration :
        new Date(this.selectedPeriodStart).getTime();

      const newSchedules = [];

      /**
       * The drop handler is triggered from adding a playlist from outside
       */
      if (dragData.payload.item.meta && dragData.payload.item.meta.sources) {

        const playlistSources = dragData.payload.item.meta.sources;

        if (this.activeLibraryPanel === 'powerBlocks') {


          // check the listings and display error if new one won't fit
console.log(98,dragData.payload.item)


          this.shouldPowerBlocksBeSaved = true;
          console.log('POWER')
        }


        console.log('adding a playlist');

        let currentScheduleStart = prevScheduleEnd;

        for (let i = 0; i < playlistSources.length; i++) {

          const currentSource = playlistSources[i];

          newSchedules.push({
            ...currentSource,
            start: currentScheduleStart
          });

          currentScheduleStart += currentSource.srcDuration;
        }
      } else {

        /**
         * The drop handler is triggered from adding new schedule from outside
         */
        const newSchedule = {

          ...dragData.payload.item,
          start: prevScheduleEnd
        };

        dragData.payload.item.meta && (newSchedule.meta = {
          ...dragData.payload.item.meta
        });

        newSchedules[0] = newSchedule;
      }

      forUpdateSchedules.splice(dragData.addedIndex, 0, ...newSchedules);

      const updatedSchedules = this.calcAdjustSchedulesStart(forUpdateSchedules);

      if (this.shouldPowerBlocksBeSaved) {

        // calc if a listing can be created where the power block is placed and do it if possible, else display a msg that it is not.

        let newPowerBlockStart = updatedSchedules[dragData.addedIndex].start;
        let newPowerBlockEnd = newPowerBlockStart + dragData.payload.item.totalDuration;
        console.log('newPowerBlockStart',newPowerBlockStart)
        console.log('newPowerBlockEnd',newPowerBlockEnd)

        let isNewPowerBlockOverlappingListing = false;

        let i = 0;

        for (i; i < this.listingsForPeriod.length; i ++) {

          const currentListing = this.listingsForPeriod[i];

          console.log(currentListing)
          if (currentListing.end < newPowerBlockStart) {

            continue;
          }

          if (currentListing.start > newPowerBlockEnd) {

            // no need to check after this one
            break;
          }

          if (currentListing.start >= newPowerBlockStart && currentListing.start <= newPowerBlockEnd ||
              currentListing.end > newPowerBlockStart && currentListing.end <= newPowerBlockEnd ||
              currentListing.start <= newPowerBlockStart && currentListing.end > newPowerBlockStart ||
              currentListing.start <= newPowerBlockStart && currentListing.end >= newPowerBlockEnd) {

            isNewPowerBlockOverlappingListing = true;

            this.$toasted.error('The program block will not fit.');
            return;
          }
          console.log(111,currentListing)
        }

        if (!isNewPowerBlockOverlappingListing) {

          this.$toasted.info('Program Block created.')
          console.log('at listing index', i, this.listingsForPeriod)

          const playlistMeta = {
            ...dragData.payload.item.meta
          };
          console.log(22222222, dragData.payload.item)
          delete playlistMeta.sources; // not needed for the listing

          this.listingsForPeriod.splice(i, 0, {

            title: dragData.payload.item.meta.name || dragData.payload.item.meta.title,
            start: newPowerBlockStart,
            end: newPowerBlockEnd,
            color: dragData.payload.item.meta.color,
            imageUrl: dragData.payload.item.meta.imageUrl,
            meta: {
              ...playlistMeta
            }
          });
        }

        this.setTempListingsSelection(this.listingsForPeriod);
      }


      this.loadedSchedules = [...updatedSchedules];

      this.setTempSchedulesSelection(this.loadedSchedules);

      this.shouldSaveBeMade = true;

      // TODO: QUESTIONS:
        // if the schedule is of type LIVE, it's title should come from the currentSchedule.meta.title, but since the BE doesn't accept title, when guid is sent [slateSourceGuid], the meta for the live schedule automatically comes from the slateSourceGuid VOD and the title, that the user entered is lost.

        // @Moni License for Calendar

        // The DTO of a playlist is such as bellow and the view, where details about the included sources is seen can NOT be done
        /* {
          "duration": 0,
          "createdAt": 0,
          "color": "string",
          "sources": [
            {
              "ads": [
                {
                  "start": 0,
                  "guid": "string",
                  "substitute": 0
                }
              ],
              "guid": "string",
              "type": "source",
              "substitute": 0
            }
          ],
          "meta": {
            "externalUrl": "string"
          },
          "imageUrl": "string",
          "name": "string",
          "guid": "string",
          "description": "string",
          "title": "string",
          "updatedAt": 0
        }*/


      // TODO: continue here -
      //// reload the schdules list, when outside the component change occurs (channel change);
      //// timeline second row should load the current schedules;
      //// timeline video should play content;
      // //timeline add playhead;

      // //add gaps at will (adjust schedule start)
      // //adjust serviceElement.totalDuration (recalc all schedules after it)

      // NOTE: Neccessary FUNCs for v.1:
        // // timeline sync for preview;
        // // Save as playlist;
        //// handle DnD of playlists and live chunks;
        //// settings for channel btn to be present


      // NOTE: Good to have FUNCs for v.1:
        //// Dead zone for listings (currently present but not visualized)
        //// Dead zone for schedules (not present, not visualized)
        //// playhead to show up when managed view for timeline is enabled

        //


      // NOTE: IMPROVEMENTS TO CURRENT FUNCs BELLOW:
        //// Save as playlist modal to list videos that will be included (as per design) with option to delete some of them before saving
        // Live chunk [choose slate replacement video] to be from a list instead of text field
        // Channel settings to be devided into seperate modals, based on UI
        // Add logo (image or source) on the fly upload - extend existing functionality from Events
        // Save as midroll;
        // handle DnD of midrolls;
        // insert ads;
        // UI sync with Vicy;
        // confirmations for edits/deletions/leave before save


      // Feedback:
        // MONI:
          //// Deadzone to be 10 mins
          // // edit listing modal improvements - btns to be rearanged
          // // search in channels
          // Copy-paste of listings?
          //// thumbnails on schedules in rundown
          //// thumbs to display hh:mm:ss where needed - library rundown, etc;

          //// disable start of rundown picker to be now + deadzone
          // NOTE: forStartMinBufer will be twice as the deadzone, since once you choose a start
          // if it's just on the deadzone, you will enter it immediately and all checks will prevent
          // you from editing rundown chunks, that are affected.

          // //no block to be `no program block`,
          //// on hover in rundown - highlight timeline corresponding block
          //// Discard (Cancel) btn to be visible every time, since if you load a period in the past you can NOT make changes to the rundown and the discard btns will not be shown!
        // Vicy:
          // // Dont use wording `Listing` instead use `Block`
          // State of rundown to NOT be cleared when changing to Program view and vise-versa
          //// remove duration of video in rundown only leave it in the thumb


          // //playhead colors
          // //remove stars for required on presected date pickers
          // //add disclaimer, that only 48h can be selected for rundown
          // //form for selecting period in rundown to be as close as possible to the design
          //// label for selected time in rundown to be without background color and moved to right
          //// width of schedule title column in rundown to be fixed width 200px
          //// the start of schedule block label to be without border, and only show border on hover of the schedule
          //// rundown table to have headers for columns
          //// empty time to be with dashed border  and as per design
          //// remove labels of listings on empty rundown blocks
          //// icon for deleting of schedule to only be shown on hover and be X istead of trashcan


        // Joro:
          // when going to video tab the selected period shopuld be preselected (with option to change it in any time) and the timeline should be in Manageg_Mode, The timeline should be inside the Program and Video tab and should be seperate for them. The poiont of this is to say to the user that the edit mode is in Video section only and the timeline( with the arrows) is for program only.

        // Hristo
          //// Като правя нов канал в channel manager-a и съм бил на канал, който вече има програма. Новият канал ми го показва с програмата на този на който съм бил преди да създам новият.
          // //Успях да създам програма в която имах empty time 00:00
          // //Когато ховърнеш на thumbnails-a в timeline-a си мисля, че ще е добре да се изписват текста и времената както ги пиша в таймлайна, защото ако имаме по-малко видео не може да му се види заглавието и времето в таймлайна.



    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms, 'returnFullHour');
    },

    getScheduleStartMarker(schedule) {

      const startAdjustment = schedule.startAdjustment || 0;

      return new Date(schedule.start - startAdjustment).toLocaleTimeString();
    },

    getChildPayload(removedIndex) {

      return {
        item: this.loadedSchedules[removedIndex],
        origin: this.dragOrigin,
        removedIndex
      };
    },

    getGhostParent() {
      return document.body;
    },

    getScheduleClass(schedule) {

      const isSchedulePlaying =
        this.currentlyPlayingSchedule &&
        this.currentlyPlayingSchedule.guid === schedule.guid &&
        this.currentlyPlayingSchedule.start === schedule.start;

      const schedulePlayingClass = isSchedulePlaying ? 'schedule-playing' : '';
      const serviceElementClass = schedule.isServiceElement ? 'service-element' : '';
      const inDeadzoneClass = schedule.isInDeadzone ? 'in-deadzone' : '';

      return `${schedulePlayingClass} ${serviceElementClass} ${inDeadzoneClass}`;
    },

    toggleSelectPeriodOpened(newVal) {

      this.isPeriodSelectionApplied = !newVal;

      if (!this.isPeriodSelectionApplied) {

        // update default start / end selection in pickers
        this.preselectDefaultStartEndDates();

        // tell the timeline to exit managed mode
        this.setTimelineExternalControlOptions({
          start: 0,
          end: 0,
          isControlled: false
        });
      }
    },

    loadPeriod() {

      const newStartTimestamp = new Date(this.selectedPeriodStart).getTime();
      const newEndTimestamp = new Date(this.selectedPeriodEnd).getTime();

      if (newEndTimestamp <= newStartTimestamp) {

        this.$toasted.error('End date should be after start date.');
        return;
      }

      if ((newEndTimestamp - newStartTimestamp) > this.allowLoadedPeriodSpanMs) {

        this.$toasted.error('Allowed edit period is 48 hours.');
        return;
      }

      // NOTE: add some max period validation (1week? or better 2 days)

     // tell the timeline that it has to enter managed mode
      this.setTimelineExternalControlOptions({
        start: newStartTimestamp,
        end: newEndTimestamp,
        isControlled: true
      });

      this.isInitialSelectionMade = true;

      this.toggleSelectPeriodOpened(false);

      this.isRequestPending = true;

      const params = {
        start: newStartTimestamp,
        end: newEndTimestamp,
        channel: this.selectedChannel.guid,
        current: "1"
      };

      const requests = [

        this.getSchedulesForChannelRequest(params),
        this.getListings(params)
      ];

      Promise.all(requests).then(responses => {

        const schedules = responses[0];
        const listings = responses[1];

        this.loadedSchedules = this.calcSchedulesAndGaps(schedules);

        //  NOTE: add this line here if the get request call is ommited from staticTimeline (opening a period)
        //this.setTempSchedulesSelection(this.loadedSchedules);

        if (this.loadedSchedules.length) {

          this.isFirstScheduleDropped = true;

        } else {

          this.isFirstScheduleDropped = false;
        }

        this.listingsForPeriod = listings;

      }).finally(() => {

        this.isRequestPending = false;
      });
    },

    toggleLibraryPanelOpened(newVal) {

      if (this.activeLibraryPanel === 'powerBlocks' && this.shouldPowerBlocksBeSaved) {

        this.$toasted.error('Save or discard your Power Block changes in order to continue.');
        return;
      }

      if (this.activeLibraryPanel !== 'powerBlocks' && newVal === 'powerBlocks' && this.shouldSaveBeMade) {

        this.$toasted.error('Save or discard your changes in order to use Power Blocks.');
        return;
      }

      this.activeLibraryPanel = newVal;
    },

    openProgramTab() {

      this.setActiveSection('program');

      this.discardSchedulesForPeriod();

      this.$pushRoute({

        path: this.$route.fullPath,
        query: {
          'channel-guid': this.selectedChannel.guid,
          section: 'program'
        }
      });
    },

    handleProgramTabChange() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.openProgramTab()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.openProgramTab();
      }
    },

    ...mapMutations({
      setActiveSection: 'channelManager/SET_ACTIVE_SECTION',
      setTimelineExternalControlOptions: 'channelManager/SET_EXTERNAL_TIMELINE_CONTROL_OPTIONS',
      setTempSchedulesSelection: 'channelManager/SET_TEMP_SCHEDULES_SELECTION',
      setTempListingsSelection: 'channelManager/SET_TEMP_LISINGS_SELECTION',

      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
      setScheduleHoverData: 'channelManager/SET_SCHEDULE_HOVER_DATA',
    }),
    ...mapActions({
      getSchedulesForChannelRequest: "channelManager/makeGetSchedulesForChannelRequest",
      getListings: "channelManager/makeGetListingsForChannelRequest",
      saveMultipleSchedules: 'channelManager/saveMultipleSchedules',
      saveMultipleListings: 'channelManager/saveMultipleListings',
      makeSavePlaylistRequest: "channelManager/makeSavePlaylistRequest",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({

      channelsMeta: "channelManager/channelsMeta",
      lockedChannelsGuids: "channelManager/lockedChannelsGuids",
      availableDeployments: "app/availableDeployments",
      notificationSocket: "app/notificationSocket",
      currentlyPlayingSchedule: "playhead/currentlyPlayingSchedule",
      timelineWriteBufferPeriod: "channelManager/timelineWriteBufferPeriod",
      deadzoneBufferMs: 'channelManager/deadzoneBufferMs',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    selectedChannel() {

      return this.channelsMeta.selected;
    }

    // NOTE: The logic will be difficult to implement with these controls
    // minPeriodStart() {

    //   const endDate = new Date(this.selectedPeriodEnd);

    //   return endDate.setDate(endDate.getDate() - 7).toISOString();
    // },

    // maxPeriodEnd() {

    //   const startDate = new Date(this.selectedPeriodStart);

    //   return startDate.setDate(startDate.getDate() + 7).toISOString();
    // },
  },

  watch: {

    selectedChannel(newVal, oldVal) {

      if (newVal.guid === oldVal.guid) {

        return;
      }

      if (this.isPeriodSelectionApplied && !this.isActiveConfirmGuardSet) {

        this.loadPeriod();

        this.shouldSaveBeMade = false;

        this.shouldPowerBlocksBeSaved = false;
      }
    },

    shouldSaveBeMade(newVal) {

      const brokerDeployment = this.availableDeployments.find(d => d.type === 'broker');

      if (newVal) {

        this.setRedirectGuard({
          redirectMsg: 'Your rundown changes will not be saved if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

        /**
         * A modification in the program has occured =>
         * Lock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_lock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));

      } else {

        this.setRedirectGuard(false);

        /**
         * There was a modification in the program, that has now
         * resolved => Unlock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_unlock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));
      }
    }
  }
}
</script>

<style lang="scss">


.schedules-scheduler-component {

  background-color: #FFF;

  .btn.tab {

    min-width: auto !important;
    background: none !important;
    border: none !important;

    &:not(.active) {

      box-shadow: none !important;
    }
  }

  .btn.tab.active {
    box-shadow: 0 1px 0 0 var(--highlightColor), 0 -1px 0 0 var(--highlightColor) inset;
  }

  .tab-toggle section {

    width: 150px;
  }

  .library-panel-selector {

    width: 56px;
    border-right: 1px solid var(--windowBrColor);
  }

  .opened-library-section {

    min-width: 510px;
    max-width: 510px;
    border-right: 1px solid var(--windowBrColor);
  }

  .library-component {

    background: white;
  }

  .close-select-period-section-btn {

    position: absolute;
    top: 20px;
    right: 20px;
  }

  .rundown-scheduler {

  }

  .time-selector, .time-selector-footer {

    width: 350px;
  }

  .initial-period-selector {

  }

  .rundown-list {

    // background-color:  #FCFCFF;
  }
  .rundown-list {
    .locked-icon {

      display: none;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 275px;
      right: calc(50% - 25px);
      z-index: 4;
    }

    &.locked {

      .schedules-list {

        opacity: 0.5;
      }

      .locked-icon {

        display: block;
      }
    }
  }

  .smooth-dnd-container .smooth-dnd-draggable-wrapper {
    overflow: visible !important;
  }




  .schedules-list,
  .smooth-dnd-container {
    height: 100%;
  }
  .vertical.smooth-dnd-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .rundown-header {

    font-size: 12px;
  }

  .schedule-tile-box {
    height: 96px;
    display: flex !important;
    padding: 0.5rem;
    border: 1px solid #EFF0FC;
    cursor: grab;

    &:hover {

      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
    }

    &.in-deadzone {

      opacity: 0.5;
    }
  }

  .schedule-tile-box-wrapper {
    position: relative;

    &:last-of-type {
      margin-bottom: 100px;
    }

    &:hover {

      .schedule-marker {

        border-color: #0077FF;
      }
    }

    .schedule-tile-box:not(.in-deadzone):hover {

      .schedule-edit-options {

        visibility: visible;
      }
    }
  }

  .schedule-edit-options {
    min-width: 60px;
    width: 60px;
    visibility: hidden;
  }

  .schedule-marker {

    width: 86px;
    height: 28px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #D9DBE7;
    white-space: nowrap;
    background-color: #FFF;
  }

  .schedule-marker-header {

    width: 86px;
  }
  .schedule-header {

    width: 300px;
  }

  .schedule-marker-header {

  }


  .schedule-thumb {
    width: 100px !important;
  }

  .schedule-meta {

    width: 200px;
  }

  .schedule-playing {

    box-shadow: 0 0 0 0px #0080ff, 0 0 0 2px #0080ff inset !important;

    .schedule-marker .schedule-marker-bg {
      background: var(--playlistColor);
      box-shadow: 0 -2px 0 2px var(--playlistColor);
    }
  }

  .service-element {

    background-color: #F8F8FC;
    border: 1px dashed #9498A8;
  }


  .period-change-label {

    // background-color: #F6FAFF;
  }


}

.schedule-ghost-element.schedule-tile-box {

  height: 96px;
  border: 1px solid #EFF0FC;

  background-color: #FFF;

  .schedule-marker, .schedule-edit-options {

    visibility: hidden;
  }
}

.schedule-ghost-element {
  overflow: hidden;
}
</style>
