<template>
<section class="event-edit">

  <h1 class="event-edit__title">Edit event</h1>

  <section
    class="event-edit__wrapper"
  >
    <div class="event-edit__content">
      <ioio-field
        type="text"
        required
        topic="editEventForm"
        size="medium"
        label="Name"
        placeholder="Name"
        :maximum=100
        v-model="editedEventData.name"
        class="w-full mt-3 mb-6"
      ></ioio-field>

      <section class="flex flex-auto w-full mb-6">

        <ioio-field
          type="date"
          required
          topic="editEventForm"
          size="medium"
          label="Start date"
          @input="onStartDateChange"
          placeholder="Start date"
          v-model="editedEventData.startTime"
          class="w-full mr-4"></ioio-field>
        <ioio-field
          v-if="editedEventData.startTime"
          type="time"
          required
          topic="editEventForm"
          size="medium"
          label="Start time"
          placeholder="Start time"
          v-model="editedEventData.startTime"
          class="w-full mr-4"></ioio-field>
        <ioio-field
          v-if="editedEventData.endTime"
          type="time"
          required
          topic="editEventForm"
          size="medium"
          label="End time"
          placeholder="End time"
          v-model="editedEventData.endTime"
          class="w-full"></ioio-field>
      </section>

      <ioio-dropdown
        :items="possibleTimezones"
        v-model="editedEventData.timezone"
        valueProp="value"
        titleProp="label"
        required
        fullWidth
        topic="editEventForm"
        label="Timezone"
        placeholder="Timezone"
        size="medium"
        class="w-full mb-6"
      />

      <ioio-field
        type="textarea"
        topic="editEventForm"
        size="medium"
        label="Description"
        placeholder="Description"
        :editor="true"
        :maximum=500
        v-model="editedEventData.description" class="col-span-2"
      ></ioio-field>

      <ioio-radio
        topic="editEventForm"
        class="w-full mt-5"
        :options="accessOptions"
        name="access"
        size="large"
        v-model="editedEventData.access"
        :checkedVal="editedEventData.access"
        label="Who can access the event?"
      />

      <ioio-radio
        topic="editEventForm"
        class="w-full mt-5"
        :options="attendeesWatchOptions"
        name="watchOption"
        size="large"
        v-model="editedEventData.watchOption"
        :checkedVal="editedEventData.watchOption"
        label="When can attendees watch the event?"
      />


        <footer v-if="!isRequestPending">

          <section class="event-edit__delete">

            <div>
              <h5 class="event-edit__delete-title">Delete event</h5>
              <p class="event-edit__delete-text">Deleting the event deletes all the records and pages</p>
            </div>

            <delete-confirm-modal-component
              dialogWidth="500"
              dialogHeight="225"
              dialogOpenBtnLabel="Delete"
              dialogOpenBtnSize="medium"
              :dialogNewButton="true"
              :isDialogOpenBtnVisible="true"
              delConfirmBtnLabel="Delete event"
              delConfirmLabel="Are you sure you want to delete this event?"
              delConfirmSecondaryLabel="All of your current changes will be lost"
              :isDelConfirmBtnVisible="!isRequestPending"
              :onDeleteConfirm="() => triggerDeleteEvent(editedEventData)"
            />
          </section>

          <ioio-button
            topic="editEventForm"
            @click.stop.prevent="editEvent">Save changes</ioio-button>
      </footer>

        <div v-else class="overflow-hidden">

          <ioio-loader isShown/>
        </div>

    </div>
  </section>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { getEventsPossibleTimezones } from "@utils/helpers";

export default {
  data: () => ({
    editedEventData: {

      name: '',
      description: '',
      startTime: '',
      endTime: '',
      timezone: '',
      parentGuid: '', // hardcoded for this form
      resources: [], // hardcoded for this form
      watchOption: 'onDemand',
      // streamOption: '', // NOTE: check if this should be editable
      // broadcastType: 'single', // ENUM [single, multiple]
      access: 'public', // ENUM [public, private, open]
    },

    accessOptions: [
      {
        value: 'public',
        label: 'Public',
        disclaimer: 'Available to everyone who registers for the event'
      }, {
        value: 'private',
        label: 'Private',
        disclaimer: 'Only available to invited attendees with a password'
      }, {
        value: 'open',
        label: 'Open',
        disclaimer: 'Anyone with a link to the event can join'
      }
    ],
    attendeesWatchOptions: [
      {
        value: 'onDemand',
        label: 'On Demand',
        disclaimer: 'Visitors of event page can sign-ip and watch the recording of the event'
      }, {
        value: 'liveOnly',
        label: 'Live Only',
        disclaimer: 'Event can be watched only when it\'s live'
      }
    ],

    possibleTimezones: [],

    isRequestPending: false,
  }),

  props: {},

  created() {

    this.moment = moment;

    this.possibleTimezones = getEventsPossibleTimezones();
  },

  mounted() {

    window.qqq = this;
    this.setupEditData();
  },

  methods: {


    triggerDeleteEvent(event) {

      this.isRequestPending = true;

      const params = {

        guid: event.guid,
        children: true // NOTE: hardcoded for now Flag which indicates whether to delete all children
      };

      this.deleteEvent(params)
        .then(() => {

          this.activeSection = ''; // prevent undesired mounting of sections
          this.$toasted.success(`Event ${this.$options.filters.truncate(event.name, 64, "...")} was deleted successfully!`);

          this.openEventsList();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    openEventsList() {

      this.$pushRoute('/events-list');
    },

    onStartDateChange(newVal) {

      this.adjustEndTimeDate();
    },

    adjustEndTimeDate() {

      const currentEndDate = new Date(this.editedEventData.endTime);

      const currentEndHour = currentEndDate.getHours();
      const currentEndMins = currentEndDate.getMinutes();
      const adjustedEndTime = new Date(this.editedEventData.startTime);

      adjustedEndTime.setHours(currentEndHour);
      adjustedEndTime.setMinutes(currentEndMins);

      this.editedEventData.endTime = adjustedEndTime.toISOString();
    },

    setupEditData() {

      const watchOption = this.eventDetails.watchOption || this.editedEventData.watchOption;

      this.editedEventData = {

        ...this.eventDetails,

        startTime: new Date(this.eventDetails.startTime).toISOString(), // convert for date picker

        // If endTime is not set (old event, before this prop was required)
        // fallback to use startTime for the model of the input
        endTime: this.eventDetails.endTime ? new Date(this.eventDetails.endTime).toISOString() : new Date(this.eventDetails.startTime).toISOString(),
        watchOption
      };
    },

    editEvent() {

      const startStamp = new Date(this.editedEventData.startTime).getTime();
      const endStamp = new Date(this.editedEventData.endTime).getTime();

      if (startStamp > endStamp) {

        this.$toasted.error('Start time can not be after end time.');
        return;
      }

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.editedEventData,

          // convert to timestamp for BE
          startTime: startStamp,
          endTime: endStamp,
        },

        guid: this.eventDetails.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.eventDetails.name, 64, "...")} was edited successfully!`);

          // refresh event details page
          this.loadEventDetails(this.eventDetails.guid);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    loadEventDetails(guid) {

      this.isRequestPending = true;

      this.getEventById({ guid })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      updateEvent: "events/updateEvent",
      getEventById: "events/getEventById",
      deleteEvent: "events/deleteEvent",
    })
  },
  computed: {
    ...mapGetters({
      eventDetails: "events/eventDetails",
    })
  },
}
</script>

<style lang="scss">
.event-edit {
  @apply
  mx-auto
  max-w-7xl
  p-12;

  &__title {
    @apply
    flex
    items-center
    justify-between
    mb-5
    text-2xl;
  }

  &__wrapper {
    @apply
    flex
    flex-col
    bg-white
    p-8
    mb-6
    rounded-lg
    shadow-card
    border
    border-blue-grey-100;
  }

  &__content {
    @apply max-w-lg;

    .ds-dropdown__label {
      @apply pb-2;
    }
  }

  &__delete {
    @apply
    flex
    justify-between
    items-center
    py-5
    my-7
    border-b
    border-t
    border-black-900
    border-opacity-10;

    &-title {
      @apply text-black-800;
    }

    &-text {
      @apply text-black-400 text-sm mt-1;
    }

  }

  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/

  label {
    font-weight: 500;
    margin-bottom: 0;
  }
}

</style>
