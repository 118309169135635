<template>

<section class="pane libs-pane-component transition flex flex-column flex-none width+3">

  <header class="pane-header m0 flex items-center flex-none">
    <button-component v-for="tab in componentsConfig" :key="tab.name" class="caps" :class="{ 'active' : tab.name === selectedLibComponent }" variant="tab" @click="setSelectedLibComponent(tab.name)">{{ tab.label }}</button-component>
    <!-- <button-component class="flex-none ml-auto mr1 " variant="minimal" shape="circle">
      <ioio-icon icon="fas-ellipsis" class="h-4"/>
    </button-component> -->
  </header>
  <component :is="selectedLibComponent" class="flex flex-column flex-auto border-top" />
  <!-- <library-component /> -->
</section>
</template>

<script>
export default {
  data: () => ({

    componentsConfig: [
      {

        name: 'LibraryComponent',
        label: 'Library'
      },
      {
        name: 'PlaylistsTabComponent',
        label: 'Playlists'
      },
      {
        name: 'LiveTabOldComponent', // deprecate component after deleting the old channel manager page
        label: 'Live Programs'
      }
    ],
    selectedLibComponent: 'LibraryComponent',
  }),
  props: {},
  methods: {

    setSelectedLibComponent(name) {

      this.selectedLibComponent = name;
    }
  },
}
</script>

<style lang="scss" scoped>



.libs-pane-component {
  background: var(--panelBgColor);
  border-left: 1px solid var(--panelBrColor);
  .btn.tab.disabled {

    box-shadow: none;
  }
}

.btn.tab:not(.active) {

  box-shadow: none;
}

</style>
