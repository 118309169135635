<template>
  <div class="timeline-control p1 flex items-center justify-between">
    <!-- Toggle for visible time period in the viewport -->
    <div class="inline-flex items-center ratio1-3">
      <button-component :variant="areSaveCtrlsDisabled ? 'default' : 'primary'" :class="areSaveCtrlsDisabled ? 'disabled' : ''" @click="savePeriodChanges" class="blue+3 mr-4">{{ isSyncPeriodInProgress ? 'Saving...' : 'Save changes' }}</button-component>
      <button-component class="blue+3" :variant="areSaveCtrlsDisabled ? 'default' : 'primary'" :class="areSaveCtrlsDisabled ? 'disabled' : ''" @click="discardPeriodChanges" intent="danger">Discard</button-component>
    </div>
    <div class="ratio1-3 center">
      <h6 class="size-5 caps m0 gray+3">Playhead</h6>
      <span class="size-2">{{currentTime}}</span></div>
    <div class="ratio1-3"></div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters, mapMutations, mapActions } from "vuex";

import { deepClone } from '@utils/helpers';

export default {
  data: () => ({
    name: "TimelineControlComponent",
    currentTime: null,
    updateTimeIntervalId: null
  }),
  props: {},
  created() {
    window.ttt = this;
    this.currentTime = moment().format("h:mm:ss a");
    this.updateTimeIntervalId =  setInterval(() => this.updateCurrentTime(), 1 * 1000);
  },

  beforeDestroy() {

    clearInterval(this.updateTimeIntervalId);
  },
  methods: {
    ...mapMutations({

      setSelectedChannel: "channelManager/SET_SELECTED_CHANNEL",
      setSelectedChannelListings: "channelManager/SET_SELECTED_CHANNEL_LISTINGS",
      calcTotalListingsDuration: "channelManager/CALC_TOTAL_LISTINGS_DURATION",
      setSelectedListing: "channelManager/SET_SELECTED_LISTING",
    }),
    ...mapActions({
      syncEntrirePeriodToBE: "channelManager/syncEntrirePeriodToBE"
    }),

    savePeriodChanges() {

      if (this.areSaveCtrlsDisabled) {

        return;
      }

      this.syncEntrirePeriodToBE().then(() => {

        this.$toasted.success('Your changes were saved successfully');
      });
    },

    discardPeriodChanges() {

      if (this.areSaveCtrlsDisabled) {

        return;
      }

      this.setSelectedChannel(this.channelsMeta.selected);

      const pristineListings = [...deepClone(this.selectedChannelListingsPristine)];

      this.setSelectedChannelListings(pristineListings);

      this.calcTotalListingsDuration(this.selectedChannelListingsAbsolute);
      this.setSelectedListing(this.currentlyPlayingListing);
    },

    updateCurrentTime() {
      this.currentTime = moment().format("h:mm:ss a");
    }
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      isSyncPeriodInProgress: 'channelManager/isSyncPeriodInProgress',
      isSelectedChannelPlaylistModified:"channelManager/isSelectedChannelPlaylistModified",
      selectedChannelListingsPristine: "channelManager/selectedChannelListingsPristine",
      selectedChannelListingsAbsolute: "channelManager/selectedChannelListingsAbsolute",
      currentlyPlayingListing: "playhead/currentlyPlayingListing",
      availableDeployments: "app/availableDeployments",
      notificationSocket: "app/notificationSocket",
    }),

    areSaveCtrlsDisabled() {

      return !this.isSelectedChannelPlaylistModified || this.isSyncPeriodInProgress;
    }
  },

  watch: {

    areSaveCtrlsDisabled () {

      const brokerDeployment = this.availableDeployments.find(d => d.type === 'broker');

      if (this.areSaveCtrlsDisabled) {

        /**
         * There was a modification in the timeline, that has now
         * resolved => Unlock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_unlock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));

      } else {

        /**
         * A modification in the timeline has occured =>
         * Lock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_lock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>


.timeline-control {
  background: var(--windowBgColor);
  color: var(--windowColor);
  border-top: 1px solid var(--windowBrColor);
  max-height: 3rem;
}
</style>
