<template>
  <modal
    name="extend-duration-workflow"
    width="500"
    height="auto"
    :scrollable="true"
    classes="extend-duration-workflow v--modal p2"
  >
    <vue-form :state="extendDurationFormState" @submit.prevent >
      <div class="form-group mb2">
        <label class="mb2">
          Select a time to extend the duration of the workflow
        </label>
        <div class="">
          <label class="flex items-center pointer mb1">
            <input type="radio" name="duration" v-model="extraDuration" value=5>
            <aside class="ml2">
              <h3 class="m0 size-1">5 minutes</h3>
            </aside>
          </label>

          <label class="flex items-center pointer mb1">
            <input type="radio" name="duration" v-model="extraDuration"  value=10>
            <aside class="ml2">
              <h3 class="m0 size-1">10 minutes</h3>
            </aside>
          </label>

          <label class="flex items-center pointer mb1">
            <input type="radio" name="duration" v-model="extraDuration"  value=15>
            <aside class="ml2">
              <h3 class="m0 size-1">15 minutes</h3>
            </aside>
          </label>

          <label class="flex items-center pointer mb1">
            <input type="radio" name="duration" v-model="extraDuration"  value=30>
            <aside class="ml2">
              <h3 class="m0 size-1">30 minutes</h3>
            </aside>
          </label>

          <label class="flex items-center pointer mb1">
            <input type="radio" name="duration" v-model="extraDuration"  value=-1>
            <aside class="ml2">
              <h3 class="m0 size-1">Custom</h3>
            </aside>
          </label>
        </div>

        <validate class="mb2" tag="label" v-if="extraDuration == -1">
          <span class="block mb2"> Entering a value no more than {{maxExtension}} minutes</span>
          <input
            class="input"
            type="number"
            placeholder="duration"
            name="custom"
            v-model="customDuration"
            min="1"
            :max="maxExtension"/>
          <field-messages name="custom" show="$submitted || $dirty && $touched">
            <div slot="min">The extension time should be more than 0 minutes</div>
            <div slot="max">The extension time should not be more than {{maxExtension}} minutes</div>
          </field-messages>
        </validate>

      </div>

      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-4"
        size="small"
        @click.stop.prevent="cancel">Cancel
      </ioio-button>

      <ioio-button
        type="primary"
        size="small"
        @click.stop.prevent="edit"
        :class="{ disabled: extendDurationFormState.$submitted && extendDurationFormState.$invalid }">Save
      </ioio-button>
    </vue-form>
    <logo-loader-component width="28px" height="28px" v-if="isRequestPending"/>
 </modal>
</template>

<script>
export default {

  data: () => ({
    extraDuration:0,
    customDuration:0,
    extendDurationFormState:{}
  }),

  props: {
    duration: {
      type: Number,
      default:0
    },
    isRequestPending: {
      type: Boolean,
      default:false
    },
    onEdit: Function,
    onCancel: Function
  },

  computed: {

    maxExtension () {

      let maxExtension = 720 - this.duration;

      return maxExtension;

    }

  },

  methods: {

    edit () {

      this.extendDurationFormState._submit();
      this.extendDurationFormState._validate();

      if (this.extendDurationFormState.$invalid) {

        return;

      }

      this.extraDuration == -1 ? this.onEdit(this.customDuration) : this.onEdit(this.extraDuration);

    },

    cancel () {

      this.extraDuration = 0;
      this.customDuration = 0;

      this.onCancel();

    }
  }
}
</script>

<style>
.extend-duration-workflow {

  padding: 2.25rem !important;
  border-radius: 16px !important;
}
</style>