/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ioio_logo_colored': {
    width: 75,
    height: 34,
    viewBox: '0 0 75 34',
    data: '<path pid="0" d="M41.875 25.26c4.91 4.898 10.24 8.74 16.719 8.74C67.638 34 75 26.372 75 17S67.638 0 58.594 0c-6.525 0-11.887 3.898-16.828 8.847l4.459 2.486c3.791-3.547 7.68-6.044 12.038-6.044 6.477.056 11.685 5.457 11.63 12.065-.054 6.528-5.23 11.808-11.63 11.863-4.469 0-8.446-2.625-12.328-6.316l-4.06 2.358zm-17.319 6.642a2.05 2.05 0 01-.004-.139V26.41c-2.556 1.735-5.209 2.805-8.06 2.805-6.478-.056-11.685-5.457-11.63-12.064.053-6.53 5.229-11.808 11.63-11.864 2.852 0 5.504 1.07 8.06 2.805V2.741c0-.203.029-.405.087-.6A17.5 17.5 0 0016.406 0C7.362 0 0 7.628 0 17s7.362 17 16.406 17a17.462 17.462 0 008.15-2.098z" _fill="#D9D9D9"/><path pid="1" d="M27.406 10.904l12.295 5.966 5.41-3.022-14.99-8.53a1.806 1.806 0 00-2.484.72 1.89 1.89 0 00-.23.906v3.96z" _fill="#07F"/><path pid="2" d="M32.57 27.195l14.83-8.77a1.881 1.881 0 00.049-3.191l-2.584-1.638-12.295 7.591v6.008z" _fill="#FAC000"/><path pid="3" d="M32.557 27.196v-13.85l-5.15-2.52v16.29c-.001 1.027.816 1.862 1.824 1.862.342 0 .677-.098.967-.283l2.359-1.499z" _fill="#EC4C47"/><path pid="4" d="M44.863 13.596l-5.164 3.272-2.459-1.259s1.102.241 3.443-.378a20.342 20.342 0 004.18-1.635z" _fill="#0A67D2"/><path pid="5" opacity=".25" d="M32.63 27.195v-6.044l1.66-1.006a4.156 4.156 0 00-.983 2.014c-.334 1.41-.676 5.036-.676 5.036z" _fill="#8E6F08"/><path pid="6" opacity=".25" d="M32.57 13.345l-5.164-2.519s2.584 1.828 3.689 2.77a5.127 5.127 0 011.475 2.267v-2.518z" _fill="#9C322F"/>'
  }
})
