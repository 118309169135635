const appName = (state) => state.appName;
const appVersion = (state) => state.appVersion;
const pageMetadata = (state) => state.pageMetadata;
const currentRoute = (state) => state.currentRoute;
const isUserAuthenticated = (state) => state.isAuthSuccessful;
const isLoginSSO = (state) => state.isLoginSSO;
const userData = (state) => state.userData;
const isInitialAppStateReady = (state) => state.isInitialAppStateReady;
const deploymentTypesArr = (state) => state.deploymentTypesArr;
const gitlabProjectIdMap = (state) => state.gitlabProjectIdMap;
const createEditPossibleDeploymentTypes = (state) => state.createEditPossibleDeploymentTypes;
const createEditPossibleDeploymentTypesMasterOrgOnly = (state) => state.createEditPossibleDeploymentTypesMasterOrgOnly;
const BASE_BROKER_URL = (state) => state.BASE_BROKER_URL;

const isRequestPending = (state) => state.isRequestPending;
const areSiteBuilderAppsLoaded = (state) => state.areSiteBuilderAppsLoaded;
const sidebarState = (state) => state.sidebar;
const organizations = (state) => state.organizations;
const organizationSelected = (state) => state.organizationSelected;
const orgGuid = (state) => state.orgGuid;
const availableDeployments = (state) => state.availableDeployments;
const siteBuilderApps = (state) => state.siteBuilderApps;

const uploadConfig = (state) => state.uploadConfig;
const isUploadModalOpened = (state) => state.isUploadModalOpened;

const navItems = (state) => state.navItems;
const userNavItems = (state) => state.userNavItems;
const adminNavItems = (state) => state.adminNavItems;
const quickActionItems = (state) => state.quickActionItems;
const quickActionDashboardItems = (state) => state.quickActionDashboardItems;

const isAdminUser = (state) => state.isAdminUser;
const gitlabToken = (state) => state.gitlabToken;
const userPermissions = (state) => state.userPermissions;
const cognitoSettings = (state) => state.cognitoSettings;

const notificationSocket = (state) => state.NOTIFICATION_SOCKET;
const msgBusMsgList = (state) => state.msgBusMsgList;

const selectedPipelineDetails = (state) => state.selectedPipelineDetails;
const selectedPipelineJobs = (state) => state.selectedPipelineJobs;

const activeConfirmDialog = (state) => state.activeConfirmDialog;
const isActiveConfirmGuardSet = (state) => state.isActiveConfirmGuardSet;
const redirectMsg = (state) => state.redirectMsg;
const redirectSecondaryMsg = (state) => state.redirectSecondaryMsg;
const isRedirectFlagRaised = (state) => state.isRedirectFlagRaised;

const lastWorkedOnItems = (state) => state.lastWorkedOnItems;


export {
  appName,
  appVersion,
  pageMetadata,
  isUserAuthenticated,
  isLoginSSO,
  userData,
  isInitialAppStateReady,
  deploymentTypesArr,
  gitlabProjectIdMap,
  createEditPossibleDeploymentTypes,
  createEditPossibleDeploymentTypesMasterOrgOnly,
  BASE_BROKER_URL,
  isRequestPending,
  areSiteBuilderAppsLoaded,
  sidebarState,
  organizations,
  organizationSelected,
  orgGuid,
  availableDeployments,
  siteBuilderApps,
  uploadConfig,
  isUploadModalOpened,
  navItems,
  userNavItems,
  adminNavItems,
  quickActionItems,
  quickActionDashboardItems,
  isAdminUser,
  gitlabToken,
  userPermissions,
  cognitoSettings,
  notificationSocket,
  msgBusMsgList,

  selectedPipelineDetails,
  selectedPipelineJobs,

  activeConfirmDialog,
  isActiveConfirmGuardSet,
  redirectMsg,
  redirectSecondaryMsg,
  isRedirectFlagRaised,

  lastWorkedOnItems,
}