<template>
  <section class="deployment-pipeline-details-component">
    <modal
        name="jobs-details"
        width="1200"
        height="auto"
        :scrollable="true"
        classes="v--modal p2"
    >
      <header class="mb2 flex justify-between border-bottom">
        <h2 class="size+1 py1 m0">Jobs</h2>
        <button-component
          class="close-delete-modal-btn"
          variant="minimal"
          shape="circle"
          @click="$modal.hide('jobs-details')">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
        </button-component>
      </header>

      <header class="flex px2 py1 pt2 mb1 justify-between border-bottom bg-gray+5">
        <span class="medium ratio1-10">ID</span>
        <span class="medium ratio2-10">Created At</span>
        <span class="medium ratio2-10">Finished At</span>
        <span class="medium ratio1-10">Duration</span>
        <span class="medium ratio1-10">Status</span>
        <span class="medium ratio3-10">Url</span>
      </header>

      <ul class="list-reset m0 rounded border" v-if="activeJobs.length">
        <li class="hover-bg-blue+5 size-1 border-bottom p2 org-tile flex justify-between deployment-tile" v-for="job in activeJobs" :key="job.id">

          <span class="name mr1 ratio1-10">{{ job.id }}</span>
          <span class="name mr1 ratio2-10">{{ job.created_at }}</span>
          <span class="name mr1 ratio2-10">{{ job.finished_at || '--' }}</span>
          <span class="name mr1 ratio1-10">{{ job.duration ? parseDuration(job.duration) : '--' }}</span>
          <span class="name mr1 ratio1-10" :class="getColorBasedOnStatus(job.status)">{{ job.status }}</span>

          <span class="name mr1 ratio3-10 medium truncate">
          <a :href="job.web_url" target="_blank">{{ job.web_url }}</a>
        </span>
        </li>
      </ul>
    </modal>

    <modal
        name="pipe-details"
        width="800"
        height="800"
        classes="v--modal p2 flex flex-column"
    >
      <header class="mb2 flex justify-between">
        <h1 class="m0">Full Pipeline Details</h1>
        <button-component
          class="close-delete-modal-btn"
          variant="minimal"
          shape="circle"
          @click="$modal.hide('pipe-details')">
            <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
          </button-component>
      </header>
      <pre class="flex-auto overflow-auto">{{ selectedPipeDetails }}</pre>

    </modal>
  </section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    // NOTE: leave hardcoded for now
    baseUrl: 'https://gitlab.next-stream.com/api/v4',
    projectId: 24,

    isDeploymentDetailsLoading: false,
    selectedDeploymentDetails: {},
    token: '',

    activeJobs: [],
    selectedPipeDetails: {},
  }),

  created() {
    this.token = this.gitlabTokenInStore || '';
  },

  mounted() {
    window.d = this;
  },

  methods: {
    getColorBasedOnStatus(status) {
      let color = 'blue';

      switch (status) {
        case 'pending':
        case 'running':
          color = 'blue';
          break;
        case 'success':
          color = 'green';
          break;
        case 'failed':
          color = 'red';
          break;
      }

      return color;
    },

    parseDuration(s) {
      const calcedDurationInMS = s * 1000;

      return getHHMMSSFromMS(calcedDurationInMS);
    },

    openDeploymentDetails(orgGuid, deploymentGuid) {
      this.isDeploymentDetailsLoading = true;

      return this.getDeploymentDetails({
        orgGuid,
        deploymentGuid
      }).then(details => {
        this.selectedDeploymentDetails = details;
      }).finally(() => {
        this.isDeploymentDetailsLoading = false;
      });
    },

    openPipeJobDetails(pipe) {
      if (!this.gitlabTokenInStore) {
        this.$toasted.error('Please authenticate yourself from the form above in order to view job details.');
        return;
      }

      const { token, baseUrl, projectId } = this;

      const params = {
        requestData: {
          token,
          baseUrl
        },
        data: {
          projectId,
          pipelineId: pipe.id
        }
      };


      this.getGitlabJobs(params).then((jobs) => {
        this.activeJobs = [...jobs];

        this.$modal.show('jobs-details');
      });
    },

    openFullPipeDetails(pipe) {
      this.selectedPipeDetails = {
        ...pipe
      };

      this.$modal.show('pipe-details');
    },

    ...mapMutations({
    }),
    ...mapActions({
      getDeploymentDetails: "app/getDeploymentDetails",
      getGitlabJobs: 'app/getGitlabJobs',
    })
  },
  computed: {
    ...mapGetters({
      selectedPipelineDetails: 'app/selectedPipelineDetails',
      selectedPipelineJobs: 'app/selectedPipelineJobs',
      gitlabTokenInStore: 'app/gitlabToken'
    })
  },

  watch: {
    selectedPipelineDetails() {
      this.openFullPipeDetails(this.selectedPipelineDetails);
    },
    selectedPipelineJobs() {
      this.openPipeJobDetails(this.selectedPipelineJobs);
    },
    gitlabTokenInStore() {
      this.token = this.gitlabTokenInStore || '';
    },
  }
}
</script>

<style lang="scss">
</style>
