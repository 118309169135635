<template>
  <ioio-modal
    v-if="isCropModalOpened"
    :noClose="true"
    :clickOutside="false"
    :show="isCropModalOpened"
    size="large"
    externalClasses="video-crop-modal"
  >
    <ioio-drawer class="video-crop-modal-component" :size="500" :show="openMetaDataDrawer" @update="closeDrawer">
      <library-vod-meta-data-component
        v-if="openMetaDataDrawer"
        ref="vodMetaForm"
        @close="closeDrawer"
        @meta="addMeta"
      />
    </ioio-drawer>

    <div class=" flex items-center justify-between pb-3 mx-4 relative">

      <h1 v-if="vodDetails && vodDetails.meta" class="text-xl">Clipping {{vodDetails.meta.title}}</h1>
      <ioio-button
        v-if="!isClippingInProgress"
        class="text-large"
        size="large"
        type="button"
        @click="onCloseCrop()"
        data-dismiss="modal"
      >
        <ioio-icon icon="fal-times" />
      </ioio-button>
    </div>

    <video-crop-component v-if="isCropModalOpened" @open="openMetaDataDrawer = true" @inProgress="inProgress" @close="onUpdatedClose" :metaData="metaData"/>

  </ioio-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {

  data: () => ({
    name: 'VideoCropModal',
    openMetaDataDrawer: false,
    metaData: false,
    isClippingInProgress: false
  }),
  props: {

    vodDetails: {
      type: Object|Boolean
    },
    toggleCropModalOpened: Function,
    isModalOpened: Boolean
  },
  mounted() {

  },

  methods: {

    closeDrawer(val) {

      this.openMetaDataDrawer = val;
    },
    addMeta(data) {
      this.metaData = data;
      this.openMetaDataDrawer = false;
    },
    onCloseCrop() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.toggleCropModalOpened(false);
          }
        });

        this.raiseRedirectFlag(true);

        return;
      };

      this.toggleCropModalOpened(false);
    },
    onUpdatedClose(vod) {

      this.toggleCropModalOpened(false,vod);
    },

    inProgress(value) {
      this.isClippingInProgress = value;
      if (!value) {
        this.metaData = false;
      }
    },
    ...mapMutations({
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    isCropModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(newVal) {

        this.toggleCropModalOpened(!!newVal);
      }
    },
    ...mapGetters({

      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),
  },

}
</script>

<style lang="scss">
.video-crop-modal {
  .ds-modal__dialog.is-large {
    width: 100%;
    max-width: 77rem;
  }
  .ds-modal__dialog {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.video-crop-modal-component {

  .text-large {
    @apply
    text-lg;
  }
  &.ds-drawer{
    @apply
    absolute;
  }
  .ds-drawer__dialog.open.is-right {
    @apply
    bg-white
    overflow-hidden
    rounded-xl;

  }
  .ds-drawer__overlay {
    @apply
    rounded-xl;
  }
}
</style>
