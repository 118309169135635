<template>
<modal
  name="add-source"
  width="75%"
  height="auto"
  :scrollable="true"
  classes="v--modal add-source-modal p2 flex flex-column"
  :clickToClose="false"
>
  <div class="flex items-center justify-end mb1">
    <button-component
      variant="primary"
      @click="handleCloseModal"
    >Cancel</button-component>
  </div>
  <add-source-component/>
</modal>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  mounted() { },
  data() {
    return {}
  },
  props: {},
   computed: {
    ...mapGetters({

      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
     }),
  },
  methods: {
    ...mapActions({

      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
     }),

    ...mapMutations({

      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
     }),

    handleCloseModal() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.closeAddSourceModal()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.closeAddSourceModal();
      }
    },

    closeAddSourceModal() {

      this.$modal.hide("add-source");
    }
  }
}
</script>

<style lang="scss">



.add-source-modal {

  max-height: 80vh;

  .add-source-component .staged-vods-container {

    max-height: 30vh;
  }
}

</style>
