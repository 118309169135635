function applyChanges ( task, dependencies, stateData, data ) {
    const 
              { name, list } = data
            , { vttFiles   } = stateData
            ;
    vttFiles[name] = list
    stateData.vttFiles = vttFiles
    const response = { vttFiles }
    task.done ({ 
                      success: true
                    , stateData
                    , response
            })
} // applyChanges func.
 
 
 
 export { applyChanges }
 
 
 