export const getSearchOptions = (inputFields) => {

  const {
    searchWord,
    elasticOptions
  } = inputFields;

  const from =
    (elasticOptions.currentPage - 1) * elasticOptions.perPageItems;

  const size = elasticOptions.perPageItems;

  let sort = [];

  const search = {};

  const query = {
    bool: {
      must: [
        {
          term: {
            // Only return images (this is different from the `source.meta.type` field)
            resourceType: 'image'
          }
        }
      ]
    }
  };


  /**
   * searchWord
   */
  if (searchWord) {

    query.bool.must.push({
      query_string: {
        "fields": [
          "meta.title",
          "meta.title.keyword",
          "meta.description",
          "originalFileName.keyword",
          "guid.keyword",
          "format.keyword"
        ],
        query: `*${searchWord}*`
      }
    });
  }


  search.options = {
    from,
    size,
    sort,
    query,

    // Limit the response to just the needed fields in list UI in order to speed it up
    _source: [
      'guid',
      'meta.title',
      'meta.description',
      'originalFileName',
      'format',
      'cdnPath',
      'width',
      'height',
      'lang',
    ],
  };

  return search;
}