/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bucket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 4c0-2.748-5.184-4-10-4S2 1.252 2 4a2.147 2.147 0 00.033.279l1.985 16.412C4.248 22.965 8.317 24 12 24s7.752-1.035 7.98-3.288l1.987-16.433A2.147 2.147 0 0022 4zM12 2c5.25 0 8 1.486 8 2s-2.75 2-8 2-8-1.486-8-2 2.75-2 8-2z" _fill="#002c65"/>'
  }
})
