/* eslint-disable */
require('./-10m')
require('./-1m')
require('./-1s')
require('./+10m')
require('./+1m')
require('./+1s')
require('./bin')
require('./brightcove_logo')
require('./bucket')
require('./button-next')
require('./button-pause')
require('./button-play')
require('./button-power')
require('./button-previous')
require('./button-stop')
require('./c-warning')
require('./calendar-event')
require('./call_split')
require('./check-in')
require('./check-out')
require('./check')
require('./circle-add')
require('./circle-check')
require('./closed_caption')
require('./cloud_upload')
require('./connected-devices')
require('./copy-clipboard')
require('./crop')
require('./ctrl-backward')
require('./ctrl-forward')
require('./cut')
require('./data-cloud')
require('./data-download')
require('./delete-forever')
require('./Delete')
require('./elemental-link')
require('./event-confirm')
require('./event-create')
require('./filter_list_outlined')
require('./filter-tool')
require('./folder-cloud')
require('./folder-play')
require('./folder-remove')
require('./grid')
require('./hierarchy')
require('./house')
require('./image')
require('./ioio_logo_colored')
require('./ioio_play')
require('./key')
require('./launch')
require('./left-arrow')
require('./lines')
require('./managed-inputs')
require('./menu-dots')
require('./menu-lines')
require('./meta-fields')
require('./multiple-19')
require('./Next')
require('./node')
require('./notifications')
require('./opening-times')
require('./panorama_outlined')
require('./paragraph')
require('./pen')
require('./pin-time')
require('./play_circle_filled_outlined')
require('./Play')
require('./player')
require('./playlist')
require('./plus-sign')
require('./preview')
require('./Previous')
require('./privacy')
require('./refresh')
require('./remove')
require('./reorder')
require('./REV')
require('./right-arrow')
require('./scissors-dashed')
require('./scissors')
require('./segmentation')
require('./set-end')
require('./set-start')
require('./settings-gear')
require('./slack')
require('./slice')
require('./small-down')
require('./small-left')
require('./small-right')
require('./small-up')
require('./stack')
require('./star')
require('./subtitles')
require('./trash-can')
require('./video-player')
require('./video-playlist')
require('./zixi')
