function openFile ( task, dependencies, stateData, data ) {
    const
          { vttFiles, videoURL, audioDuration }  = stateData
        , { selection } = data
        , selectID = selection[0]  // Editor can open for edit only one file
        , theFile = vttFiles[selectID] // Array of cue objects
        ;
    let cueList = theFile.reduce ( (res,item) => {
                                    let copyItem = Object.assign ( {}, item )
                                    res.push ( copyItem )
                                    return res
                                },[])
    let response = {
                        name: selectID
                      , cueList
                      , videoURL
                      , audioDuration
                }
    task.done ({
                      success : true
                    , response
            })
} // openFile func.



 export { openFile }


