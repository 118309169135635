
const SET_DISTRIBUTORS = (state, distributors) => {

  state.distributors = [...distributors];
};

const SET_IMPORT_DISTRIBUTORS_LIST = (state,value) => {

  state.importDistributorsList = value;
}

const SET_CHOOSEN_DISTRIBUTOR = (state, guid) => {

  let index = 0;

  while(index < state.distributors.length) {

    if (state.distributors[index].guid === guid) {

      state.choosenDistributor = {...state.distributors[index]}
      break
    }

    index++;
  }
}

const SET_ZOOM_INTEGRATION = (state, value) => {

  localStorage.setItem('ZoomIntegrationRequestPending', value);
}

const STORE_USER_GOOGLE_DRIVE_DATA = (state, data) => {

  if (data != 'save') {

    state.googleDriveData = data;

    localStorage.setItem('IO_userGoogleDriveData', JSON.stringify(data));
  } else {

    state.googleDriveData = JSON.parse(localStorage.getItem('IO_userGoogleDriveData')) || null;
  };

};

const STORE_GOOGLE_DRIVE_TEMP_DATA = (state, data) => {

    state.googleDriveTempData = data;
};


export {
  SET_DISTRIBUTORS,
  SET_IMPORT_DISTRIBUTORS_LIST,
  SET_CHOOSEN_DISTRIBUTOR,
  SET_ZOOM_INTEGRATION,
  STORE_USER_GOOGLE_DRIVE_DATA,
  STORE_GOOGLE_DRIVE_TEMP_DATA
};