<template>

<section class="custom-meta-fields-legacy-component">
  <section class="meta-groups-section" :class="containerClass" v-if="possibleCustomFieldGroups.length">

    <select
      class="select ratio1-2 group-select"
      id="group-select"
      placeholder=""
      v-model="editedAsset.selectedMetaGroupGuid"
      name="type">

      <option :value="undefined" selected
      >Select Meta Fields Model...</option>

      <option
        v-for="grp in possibleCustomFieldGroups"
        :value="grp.guid"
        :key="grp.guid"
        >{{ grp.name }}</option
      >
    </select>

    <section v-for="grp in possibleCustomFieldGroups" :key="grp.guid">

      <article v-if="editedAsset.selectedMetaGroupGuid === grp.guid">

        <h4 class="border-bottom pb2 mt1 pl2">{{ grp.name }}</h4>

        <section class="fields-section">
          <h3 v-if="!grp.fields.length">This group doesn't have any fields associated with it.</h3>
          <div class="ratio1-2 mt2 custom-meta-field" v-for="(field, index) in grp.fields" :key="index">
            <validate class="" tag="label" v-if="field.type === 'string'">
              <div class="mb1">{{ field.name }} <span class="text-danger" v-if="field.required">*</span></div>
              <input
                class="input"
                type="text"
                :placeholder="field.name"
                :name="field.name + index"
                v-model="editedAsset[field.name]"
                :required="field.required"
                :minlength="field.min"
                :maxlen="field.max"
              />

              <field-messages show="$submitted || $dirty && $touched"
                :name="field.name + index">
                <div slot="required">Custom field `{{ field.name }}` is required</div>
                <div slot="minlength">Custom field `{{ field.name }}` length should not be less than {{ field.min }} characters</div>
                <div slot="maxlen">Custom field `{{ field.name }}` length should not be more than {{ field.max }} characters</div>
              </field-messages>
            </validate>

            <validate class="" tag="label" v-if="field.type === 'number'">
              <div class="mb1">{{ field.name }}</div>
              <input
                class="input"
                type="number"
                :placeholder="field.name"
                :name="field.name + index"
                v-model="editedAsset[field.name]"
                :required="field.required"
                :min="field.min"
                :max="field.max"
              />

              <field-messages show="$submitted || $dirty && $touched"
                :name="field.name + index">
                <div slot="required">Custom field `{{ field.name }}` is required</div>
                <div slot="min">Custom field `{{ field.name }}` should not be less than {{ field.min }}</div>
                <div slot="max">Custom field `{{ field.name }}` should not be more than {{ field.max }}</div>
              </field-messages>
            </validate>

            <validate class="" tag="label" v-if="field.type === 'enum'">
              <div class="mb1">{{ field.name }}</div>

              <select
                :name="field.name + index"
                v-model="editedAsset[field.name]"
                :required="field.required"
                class="select ratio1-1"
              >
                <option
                  v-for="option in field.additionalData.enumOptions"
                  :value="option"
                  >{{ option }}</option
                >
              </select>

              <field-messages
                :name="field.name + index"
                show="$submitted || $dirty && $touched"
              >
                <div slot="required">{{ field.name }} is a required field</div>
              </field-messages>
            </validate>

            <validate class="" tag="label" v-if="field.type === 'json'">
              <div class="mb1">{{ field.name }}</div>

              <textarea
                :name="field.name + index"
                v-model="editedAsset[field.name]"
                :required="field.required"
                class="textarea ratio1-1"
                rows="8"
                json
              />

              <field-messages
                :name="field.name + index"
                show="$submitted || $dirty && $touched"
              >
                <div slot="required">{{ field.name }} is a required field</div>
                <div slot="json">{{ field.name }} has to be a valid JSON object</div>
              </field-messages>
            </validate>

            <validate class="pointer" tag="label" v-if="field.type === 'boolean'">
              <div class="mb1">{{ field.name }}</div>
              <input
                type="checkbox"
                :label="field.name"
                :name="field.name + index"
                class="size-4 pointer"
                style="width: 20px"
                :checked="editedAsset[field.name]"
                :required="field.required"
                v-model="editedAsset[field.name]"
                :on-change="() => { editedAsset[field.name] = !editedAsset[field.name] }"/>

              <field-messages show="$submitted || $dirty && $touched"
                :name="field.name + index">
                <div slot="required">Custom field `{{ field.name }}` is required</div>
              </field-messages>
            </validate>

          </div>
        </section>
      </article>
    </section>
  </section>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    possibleCustomFieldGroups: [],
  }),
  props: {

    containerClass: {
      type: String
    },

    editedAsset: {
      type: Object,
      required: true
    },

    metaResourceType: {
      type: String,
      required: true
    },

    onGroupChange: Function
  },

  mounted() {


    // get custom fields here (ones, applicable for broker && this.metaResourceType [video, listing, playlist, channel])
    // if multiple groups should be visualized, additional requests are needed, since the BE doesn't support it
    const params = {

      guid: this.organizationSelected.guid,
      // resource: this.metaResourceType,
      deploymentType: 'broker'
    };

    const resourcesAsArr = this.metaResourceType.split('|');

    const requests = [];

    resourcesAsArr.forEach((r) => {

      requests.push(this.getMetaFieldsGroups({

        ...params,
        resource: r
      }));
    });


    Promise.all(requests).then(responses => {

      const fields = responses.flat();

      this.possibleCustomFieldGroups = fields;
    });

    window.c = this;
  },

  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getMetaFieldsGroups: "app/getMetaFieldsGroups",
    }),

    changeApplicableGroupFields(newGuid, oldGuid) {

      this.onGroupChange && this.onGroupChange(newGuid, oldGuid);

      /**
       * When the MetaGroupGuid changes, all custom fields from the old group,
       * that were saved on the editedAsset DTO have to be manually deleted,
       * so that they don't get saved with the newly selected MetaGroupGuid
       */
      if (oldGuid && newGuid !== oldGuid) {

        const currentSelectedGrp =
          this.possibleCustomFieldGroups.find((grp) => grp.guid === oldGuid);

        currentSelectedGrp && currentSelectedGrp.fields.forEach(f => {

          delete this.editedAsset[f.name];
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected"
    }),

    editedAssetSelectedMetaGroupGuid() {

      return this.editedAsset && this.editedAsset.selectedMetaGroupGuid
    }
  },
  watch: {

    editedAssetSelectedMetaGroupGuid(newVal, oldVal) {

      this.changeApplicableGroupFields(newVal, oldVal);
    }
  }
}
</script>

<style lang="scss">



.custom-meta-fields-legacy-component {

  .group-select {

    width: 260px;
    min-width: auto;
  }

  .fields-section {

    width: 100%;
    max-width: 600px;
  }

  .custom-meta-field {

    display: inline-table;
    width: calc(50% - 8px);

    &:nth-child(odd) {

      margin-right: 16px;
    }

    textarea {
      resize: none;
    }
  }
}
</style>
