/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M3 8v13a3 3 0 003 3h12a3 3 0 003-3V8zm6 11H7v-7h2zm4 0h-2v-7h2zm4 0h-2v-7h2z"/><path pid="1" data-color="color-2" d="M23 4h-6V1a1 1 0 00-1-1H8a1 1 0 00-1 1v3H1a1 1 0 000 2h22a1 1 0 000-2zM9 2h6v2H9z"/></g>'
  }
})
