/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closed_caption': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.222 0h15.556C19 0 20 .9 20 2v12c0 1.1-1 2-2.222 2H2.222C.99 16 0 15.1 0 14V2C0 .9.989 0 2.222 0zm0 14h15.556V2H2.222v12zm2.222-3h3.334c.61 0 1.11-.45 1.11-1V9H7.223v.5H5v-3h2.222V7H8.89V6c0-.55-.5-1-1.111-1H4.444c-.61 0-1.11.45-1.11 1v4c0 .55.5 1 1.11 1zm11.112 0h-3.334c-.61 0-1.11-.45-1.11-1V6c0-.55.5-1 1.11-1h3.334c.61 0 1.11.45 1.11 1v1H15v-.5h-2.222v3H15V9h1.667v1c0 .55-.5 1-1.111 1z" _fill="#4F4F4F"/>'
  }
})
