import { deploymentsFullMap } from '@utils/deploymentTypes.constants';

const SET_PAGE_METADATA = (state, newMeta) => {

  state.pageMetadata = {
    ...newMeta
  };
};

const LOG_CURRENT_ROUTE = (state, routeData) => {

  state.currentRoute = { ...routeData };
};

const LOGOUT = (state) => {

  localStorage.setItem('IO_isAuthSuccessful', false);
  localStorage.setItem('IO_isLoginSSO', false);
  localStorage.setItem('IO_isAdminUser', false);
  localStorage.setItem('IO_gitlabToken', JSON.stringify(null));
  localStorage.setItem('IO_userPermissions', null);
  localStorage.setItem('IO_deployments', JSON.stringify([]));
  localStorage.setItem('IO_organizations', null);
  localStorage.setItem('IO_organizationSelected', null);
  localStorage.setItem('IO_orgGuid', null);
  localStorage.setItem('IO_baseBrokerUrl', null);
  localStorage.setItem('IO_baseLiveUrl', null);
  localStorage.setItem('IO_baseLiveElementalUrl', null);
  localStorage.setItem('IO_baseImageUrl', null);
  localStorage.setItem('IO_baseSyndicationUrl', null);
  localStorage.setItem('IO_baseIngestUrl', null);
  localStorage.setItem('IO_baseEventsUrl', null);
  localStorage.setItem('IO_uploadConfig', null);
  localStorage.setItem('IO_userData', null);

  /**
   * Find and null the keys that are set by aws-sdk libraries,
   * so that they are properly refreshed for future logins
   */
  for (var key in localStorage) {

    if (key.indexOf('aws.cognito.identity') !== -1) {

      localStorage.setItem(key, null);
    }
  }

  state.isAuthSuccessful = false;
  state.isLoginSSO = false;
  state.isAdminUser = false;
  state.gitlabToken = null;
  state.userPermissions = null;
  state.availableDeployments = [];
  state.organizations = null;
  state.organizationSelected = null;
  state.orgGuid = null;
  state.BASE_BROKER_URL = null;
  state.BASE_LIVE_URL = null;
  state.BASE_LIVE_ELEMENTAL_URL = null;
  state.BASE_IMAGE_URL = null;
  state.BASE_SYNDICATION_URL = null;
  state.BASE_INGEST_URL = null;
  state.BASE_EVENTS_URL = null;


  state.uploadConfig = null;
  state.userData = null;
};

/**
 * Note that the mutation does the same as LOGOUT,
 * but the name is different intentionally
 */
const FORCE_LOGOUT = (state) => {

  localStorage.setItem('IO_isAuthSuccessful', false);
  localStorage.setItem('IO_isLoginSSO', false);
  localStorage.setItem('IO_isAdminUser', false);
  localStorage.setItem('IO_gitlabToken', JSON.stringify(null));
  localStorage.setItem('IO_userPermissions', null);
  localStorage.setItem('IO_deployments', JSON.stringify([]));
  localStorage.setItem('IO_organizations', null);
  localStorage.setItem('IO_organizationSelected', null);
  localStorage.setItem('IO_orgGuid', null);
  localStorage.setItem('IO_baseBrokerUrl', null);
  localStorage.setItem('IO_baseLiveUrl', null);
  localStorage.setItem('IO_baseLiveElementalUrl', null);
  localStorage.setItem('IO_baseImageUrl', null);
  localStorage.setItem('IO_baseSyndicationUrl', null);
  localStorage.setItem('IO_baseIngestUrl', null);
  localStorage.setItem('IO_baseEventsUrl', null);
  localStorage.setItem('IO_uploadConfig', null);
  localStorage.setItem('IO_userData', null);

  /**
   * Find and null the keys that are set by aws-sdk libraries,
   * so that they are properly refreshed for future logins
   */
  for (var key in localStorage) {

    if (key.indexOf('aws.cognito.identity') !== -1) {

      localStorage.setItem(key, null);
    }
  }

  state.isAuthSuccessful = false;
  state.isLoginSSO = false;
  state.isAdminUser = false;
  state.gitlabToken = null;
  state.userPermissions = null;
  state.availableDeployments = [];
  state.organizations = null;
  state.organizationSelected = null;
  state.orgGuid = null;
  state.BASE_BROKER_URL = null;
  state.BASE_LIVE_URL = null;
  state.BASE_LIVE_ELEMENTAL_URL = null;
  state.BASE_IMAGE_URL = null;
  state.BASE_SYNDICATION_URL = null;
  state.BASE_INGEST_URL = null;
  state.BASE_EVENTS_URL = null;

  state.uploadConfig = null;
  state.userData = null;
};

const TOGGLE_IS_REQUEST_PENDING = (state, isTriggered) => {

  state.isRequestPending = !!isTriggered;
};

const TOGGLE_ARE_SITEBUILDER_APPS_LOADED = (state, isTriggered) => {

  state.areSiteBuilderAppsLoaded = !!isTriggered;
};

const INITIALISE_STORE = (state, token) => {

  const isAuthSuccessful = JSON.parse(localStorage.getItem('IO_isAuthSuccessful')) || false;
  const isLoginSSO = JSON.parse(localStorage.getItem('IO_isLoginSSO')) || false;

  const lastWorkedOnItemsInStorage = JSON.parse(localStorage.getItem('IO_lastWorkedOnItems')) || [];

  state.isAuthSuccessful = isAuthSuccessful;
  state.isLoginSSO = isLoginSSO;

  state.lastWorkedOnItems = lastWorkedOnItemsInStorage;


  if (isAuthSuccessful) {

    const roleInStorage = JSON.parse(localStorage.getItem('IO_isAdminUser')) || false;

    state.isAdminUser = roleInStorage;

    const gitlabTokenInStorage = JSON.parse(localStorage.getItem('IO_gitlabToken')) || null;

    state.gitlabToken = gitlabTokenInStorage;

    const permissionsInStorage = JSON.parse(localStorage.getItem('IO_userPermissions')) || null;

    state.userPermissions = permissionsInStorage;

    const deploymentsInStorage = JSON.parse(localStorage.getItem('IO_deployments')) || [];

    state.availableDeployments = deploymentsInStorage;

    const organizationsInStorage = JSON.parse(localStorage.getItem('IO_organizations'));

    state.organizations = organizationsInStorage ? [
      ...organizationsInStorage
    ] : null;

    const organizationSelectedInStorage = JSON.parse(localStorage.getItem('IO_organizationSelected'));

    state.organizationSelected = organizationSelectedInStorage ? {
      ...organizationSelectedInStorage
    } : null;

    const orgGuidInStorage = localStorage.getItem('IO_orgGuid');

    const baseBrokerUrlInStorage = localStorage.getItem('IO_baseBrokerUrl');

    const baseLiveUrlInStorage = localStorage.getItem('IO_baseLiveUrl');

    const baseLiveElementalUrlInStorage = localStorage.getItem('IO_baseLiveElementalUrl');

    const baseImageUrlInStorage = localStorage.getItem('IO_baseImageUrl');

    const baseSyndicationUrlInStorage =
      localStorage.getItem('IO_baseSyndicationUrl');

    const baseIngestUrlInStorage =
      localStorage.getItem('IO_baseIngestUrl');

    const baseEventsUrlInStorage =
      localStorage.getItem('IO_baseEventsUrl');


    let uploadConfigInStorage;

    try {

      uploadConfigInStorage =
        JSON.parse(localStorage.getItem('IO_uploadConfig'));

    } catch(err) {

      uploadConfigInStorage = null;
    }

    const userDataInStorage = JSON.parse(localStorage.getItem('IO_userData'));


    state.orgGuid = orgGuidInStorage;
    state.BASE_BROKER_URL = baseBrokerUrlInStorage || '';
    state.BASE_LIVE_URL = baseLiveUrlInStorage || '';
    state.BASE_LIVE_ELEMENTAL_URL = baseLiveElementalUrlInStorage || '';
    state.BASE_IMAGE_URL = baseImageUrlInStorage || '';
    state.BASE_SYNDICATION_URL = baseSyndicationUrlInStorage || '';
    state.BASE_INGEST_URL = baseIngestUrlInStorage || '';
    state.BASE_EVENTS_URL = baseEventsUrlInStorage || '';

    state.uploadConfig = uploadConfigInStorage;
    state.userData = userDataInStorage;
  }
};

const INITIALISE_STORE_READY = (state) => {

  state.isInitialAppStateReady = true;
};

const TOGGLE_UPLOAD_MODAL_OPENED = (state, newVal) => {

  state.isUploadModalOpened = newVal !== undefined ?
    newVal : !state.isUploadModalOpened;
};

const LOGIN_SUCCESS = (state) => {

  state.isAuthSuccessful = true;

  localStorage.setItem('IO_isAuthSuccessful', true);
};

const SET_IS_LOGIN_SSO = (state, newVal) => {

  state.isLoginSSO = newVal;

  localStorage.setItem('IO_isLoginSSO', newVal);
};

const SET_IS_ADMIN_USER = (state) => {

  state.isAdminUser = true;

  localStorage.setItem('IO_isAdminUser', true);
};

const SET_GITLAB_TOKEN = (state, token) => {

  state.gitlabToken = token;

  localStorage.setItem('IO_gitlabToken', JSON.stringify(token));
};

const SET_USER_PERMISSIONS = (state, deployments) => {

  const permissions = {};

  /**
   * Note: since the BE doesn't provide unique permission for the admin
   * user, manually add one here, based on the `isAdminUser` store property.
   * Doing this will negate the need for additional checks in the `$auth` mixin.
   *
   * Delete this addition if a permission comes from the BE in the future.
   */
  if (state.isAdminUser) {

    permissions['access_admin_routes'] = true;
  }

  deployments.forEach(d => {

    d.permissions.forEach(p => {

      permissions[p] = true;
    });
  });

  state.userPermissions = permissions;

  localStorage.setItem('IO_userPermissions', JSON.stringify(permissions));
};

const SET_AVAILABLE_DEPLOYMENTS = (state, deployments) => {

  state.availableDeployments = deployments;

  localStorage.setItem('IO_deployments', JSON.stringify(deployments));
};

const SET_DEPLOYMENT_CONSTANTS = (state) => {

  const _deploymentTypesArr = [];

  const _gitlabProjectIdMap = {};

  const _createEditPossibleDeploymentTypes = [];
  const _createEditPossibleDeploymentTypesMasterOrgOnly = [];

  // Prepare the different configs for later use in various pages
  for (const [deploymentName, deployment] of Object.entries(deploymentsFullMap)) {

    _gitlabProjectIdMap[deploymentName] = deployment.id;

    _deploymentTypesArr.push(deploymentName);

    // Master org can not be created from the UI.
    if (deploymentName === 'master') {

      continue;
    }

    // Some deployments like `search`, `live-observer`, `live-blueprints-api`,
    // etc. can only be deployed withing the MASTER org and have the
    // `isDeployedForMasterOrgOnly` key in the deploymentTypes.constant config.
    // They will be inserted dynamically in the component
    if (deployment.isDeployedForMasterOrgOnly) {

      _createEditPossibleDeploymentTypesMasterOrgOnly.push({

        value: deploymentName,
        label: deployment.label
      });

    } else {

      _createEditPossibleDeploymentTypes.push({

        value: deploymentName,
        label: deployment.label
      });
    }
  }

  state.deploymentTypesArr = _deploymentTypesArr;
  state.gitlabProjectIdMap = _gitlabProjectIdMap;
  state.createEditPossibleDeploymentTypes = _createEditPossibleDeploymentTypes;
  state.createEditPossibleDeploymentTypesMasterOrgOnly =
    _createEditPossibleDeploymentTypesMasterOrgOnly;
}

const SET_ORGANIZATIONS_LIST = (state, organizations) => {

  state.organizations = organizations;

  localStorage.setItem('IO_organizations', JSON.stringify(organizations));
};

const SET_ORGANIZATION_SELECTED = (state, organization) => {

  state.organizationSelected = organization;

  localStorage.setItem('IO_organizationSelected', JSON.stringify(organization));
};

const SET_ORG_GUID = (state, guid) => {

  state.orgGuid = guid;

  localStorage.setItem('IO_orgGuid', guid);
};

const SET_BASE_BROKER_URL = (state, baseBrokerUrl) => {

  const trimmedBaseBrokerUrl = baseBrokerUrl && baseBrokerUrl.slice(0, -1); // remove the trailing `/`;

  state.BASE_BROKER_URL = trimmedBaseBrokerUrl;

  localStorage.setItem('IO_baseBrokerUrl', trimmedBaseBrokerUrl);
};

const SET_BASE_LIVE_URL = (state, baseLiveUrl) => {

  state.BASE_LIVE_URL = baseLiveUrl;

  localStorage.setItem('IO_baseLiveUrl', baseLiveUrl);
};

const SET_BASE_LIVE_ELEMENTAL_URL = (state, baseLiveElementalUrl) => {

  state.BASE_LIVE_ELEMENTAL_URL = baseLiveElementalUrl;

  localStorage.setItem('IO_baseLiveElementalUrl', baseLiveElementalUrl);
};

const SET_BASE_IMAGE_URL = (state, baseImageUrl) => {

  const trimmedBaseImageUrl = baseImageUrl && baseImageUrl.slice(0, -1); // remove the trailing `/`;

  state.BASE_IMAGE_URL = trimmedBaseImageUrl;

  localStorage.setItem('IO_baseImageUrl', trimmedBaseImageUrl);
};

const SET_BASE_SYNDICATION_URL = (state, baseSyndicationUrl) => {

  const trimmedBaseSyndicationUrl =
    baseSyndicationUrl && baseSyndicationUrl.slice(0, -1); // remove the trailing `/`;

  state.BASE_SYNDICATION_URL = trimmedBaseSyndicationUrl;

  localStorage.setItem('IO_baseSyndicationUrl', trimmedBaseSyndicationUrl);
};

const SET_BASE_INGEST_URL = (state, baseIngestUrl) => {

  const trimmedBaseIngestUrl =
  baseIngestUrl && baseIngestUrl.slice(0, -1); // remove the trailing `/`;

  state.BASE_INGEST_URL = trimmedBaseIngestUrl;

  localStorage.setItem('IO_baseIngestUrl', trimmedBaseIngestUrl);
};

const SET_BASE_EVENTS_URL = (state, baseEventsUrl) => {

  const trimmedBaseEventsUrl =
    baseEventsUrl && baseEventsUrl.slice(0, -1); // remove the trailing `/`;

  state.BASE_EVENTS_URL = trimmedBaseEventsUrl;

  localStorage.setItem('IO_baseEventsUrl', trimmedBaseEventsUrl);
};

const STORE_UPLOAD_CONFIG = (state, config) => {

  state.uploadConfig = config || {};

  localStorage.setItem('IO_uploadConfig', JSON.stringify(state.uploadConfig));
};

const STORE_USER_DATA = (state, data) => {

  state.userData = data;

  localStorage.setItem('IO_userData', JSON.stringify(data));
};

const TOGGLE_SIDEBAR_OPENED = (state) => {

  const isCollapsed = state.sidebar.isCollapsed;

  state.sidebar = {

    ...state.sidebar,
    isCollapsed: !isCollapsed
  };
};

const SET_TOAST_CTRL_IN_STORE = (state, toastCtrlInstance) => {

  state.toastCtrl = toastCtrlInstance;
};

const ADD_MSG_TO_MSG_BUS = (state, msg) => {

  state.msgBusMsgList = [
    ...state.msgBusMsgList,
    msg
  ];
};

const SET_SELECTED_PIPELINE_DETAILS = (state, selectedPipelineDetails) => {
  state.selectedPipelineDetails = {
    ...selectedPipelineDetails,
  };
};

const SET_SELECTED_PIPELINE_JOBS = (state, selectedPipelineJobs) => {
  state.selectedPipelineJobs = {
    ...selectedPipelineJobs,
  };
};


const SET_REDIRECT_GUARD = (state, payload) => {

  if (!payload) {

    state.isActiveConfirmGuardSet = false;
    state.redirectMsg = '';
    state.redirectSecondaryMsg = '';

    return;
  }

  const { redirectMsg, redirectSecondaryMsg } = payload;

  state.isActiveConfirmGuardSet = true;
  state.redirectMsg = redirectMsg;
  state.redirectSecondaryMsg = redirectSecondaryMsg;
};

const RAISE_REDIRECT_FLAG = (state, newVal) => {

  state.isRedirectFlagRaised = newVal;
};

const LOG_WORKED_ON_ITEM = (state, newItem) => {

  if (!newItem) {

    return;
  }

// add the item first in the list
// iterate items and check if the same type is allready present. if so - delete it
// just in case newItems = allItems.splice(0, 5)
  const currentItems = [...state.lastWorkedOnItems];

  for (let i = 0; i < currentItems.length; i++) {

    if (currentItems[i].type === newItem.type) {

      currentItems.splice(i, 1);
      break;
    }
  }

  currentItems.unshift(newItem);

  const newItems = currentItems.splice(0, 5);

  state.lastWorkedOnItems = newItems;

  localStorage.setItem('IO_lastWorkedOnItems', JSON.stringify(state.lastWorkedOnItems));
}

const SET_PENDING_UP_ASSET_KEY = (state, payload) => {

  const { key, assetData, isPending } = payload;

  if (isPending) {

    state.pendingUploadAssetKeys = {

      ...state.pendingUploadAssetKeys,
      [key]: assetData
    };

  } else {

    delete state.pendingUploadAssetKeys[key];
  }
}


export {
  SET_PAGE_METADATA,
  LOG_CURRENT_ROUTE,
  LOGIN_SUCCESS,
  SET_IS_LOGIN_SSO,
  SET_IS_ADMIN_USER,
  SET_GITLAB_TOKEN,
  SET_USER_PERMISSIONS,
  SET_AVAILABLE_DEPLOYMENTS,
  SET_DEPLOYMENT_CONSTANTS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_SELECTED,
  SET_ORG_GUID,
  SET_BASE_BROKER_URL,
  SET_BASE_LIVE_URL,
  SET_BASE_LIVE_ELEMENTAL_URL,
  SET_BASE_IMAGE_URL,
  SET_BASE_SYNDICATION_URL,
  SET_BASE_INGEST_URL,
  SET_BASE_EVENTS_URL,
  STORE_UPLOAD_CONFIG,
  STORE_USER_DATA,
  LOGOUT,
  FORCE_LOGOUT,
  TOGGLE_IS_REQUEST_PENDING,
  TOGGLE_ARE_SITEBUILDER_APPS_LOADED,
  INITIALISE_STORE,
  INITIALISE_STORE_READY,
  TOGGLE_UPLOAD_MODAL_OPENED,
  TOGGLE_SIDEBAR_OPENED,
  SET_TOAST_CTRL_IN_STORE,
  ADD_MSG_TO_MSG_BUS,

  SET_SELECTED_PIPELINE_DETAILS,
  SET_SELECTED_PIPELINE_JOBS,

  SET_REDIRECT_GUARD,
  RAISE_REDIRECT_FLAG,

  LOG_WORKED_ON_ITEM,
  SET_PENDING_UP_ASSET_KEY
}

