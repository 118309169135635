export const languagesThreeLetterConfig = {
  ENG: "English",
  SPA: "Spanish; Castilian",
  FRA: "French",
  DEU: "German (DEU)",
  GER: "German (GER)",
  ZHO: "Chinese",
  ARA: "Arabic",
  HIN: "Hindi",
  JPN: "Japanese",
  RUS: "Russian",
  POR: "Portuguese",
  ITA: "Italian",
  URD: "Urdu",
  VIE: "Vietnamese",
  KOR: "Korean",
  PAN: "Panjabi; Punjabi",
  ABK: "Abkhazian",
  AAR: "Afar",
  AFR: "Afrikaans",
  AKA: "Akan",
  SQI: "Albanian",
  AMH: "Amharic",
  ARG: "Aragonese",
  HYE: "Armenian",
  ASM: "Assamese",
  AVA: "Avaric",
  AVE: "Avestan",
  AYM: "Aymara",
  AZE: "Azerbaijani",
  BAM: "Bambara",
  BAK: "Bashkir",
  EUS: "Basque",
  BEL: "Belarusian",
  BEN: "Bengali",
  BIH: "Bihari languages",
  BIS: "Bislama",
  BOS: "Bosnian",
  BRE: "Breton",
  BUL: "Bulgarian",
  MYA: "Burmese",
  CAT: "Catalan; Valencian",
  KHM: "Central Khmer",
  CHA: "Chamorro",
  CHE: "Chechen",
  NYA: "Chichewa; Chewa; Nyanja",
  CHU: "Church Slavic; Old Bulgarian",
  CHV: "Chuvash",
  COR: "Cornish",
  COS: "Corsican",
  CRE: "Cree",
  HRV: "Croatian",
  CES: "Czech",
  DAN: "Danish",
  DIV: "Divehi; Dhivehi; Maldivian",
  NLD: "Dutch; Flemish",
  DZO: "Dzongkha",
  ENM: "English, Middle (1100-1500)",
  EPO: "Esperanto",
  EST: "Estonian",
  EWE: "Ewe",
  FAO: "Faroese",
  FIJ: "Fijian",
  FIN: "Finnish",
  FRM: "French, Middle (1400-1600)",
  FUL: "Fulah",
  GLA: "Gaelic; Scottish Gaelic",
  GLG: "Galician",
  LUG: "Ganda",
  KAT: "Georgian",
  ELL: "Greek, Modern (1453-)",
  GRN: "Guarani",
  GUJ: "Gujarati",
  HAT: "Haitian; Haitian Cr",
  HAU: "Hausa",
  HEB: "Hebrew",
  HER: "Herero",
  HMO: "Hiri Motu",
  HUN: "Hungarian",
  ISL: "Icelandic",
  IDO: "Ido",
  IBO: "Igbo",
  IND: "Indonesian",
  INA: "Interlingua (IALA)",
  ILE: "Interlingue; Occidental",
  IKU: "Inuktitut",
  IPK: "Inupiaq",
  GLE: "Irish",
  JAV: "Javanese",
  KAL: "Kalaallisut; Greenlandic",
  KAN: "Kannada",
  KAU: "Kanuri",
  KAS: "Kashmiri",
  KAZ: "Kazakh",
  KIK: "Kikuyu; Gikuyu",
  KIN: "Kinyarwanda",
  KIR: "Kirghiz; Kyrgyz",
  KOM: "Komi",
  KON: "Kongo",
  KUA: "Kuanyama; Kwanyama",
  KUR: "Kurdish",
  LAO: "Lao",
  LAT: "Latin",
  LAV: "Latvian",
  LIM: "Limburgan; Limburger; Limburgish",
  LIN: "Lingala",
  LIT: "Lithuanian",
  LUB: "Luba-Katanga",
  LTZ: "Luxembourgish; Letzeburgesch",
  MKD: "Macedonian",
  MLG: "Malagasy",
  MSA: "Malay",
  MAL: "Malayalam",
  MLT: "Malte",
  GLV: "Manx",
  MRI: "Maori",
  MAR: "Marathi",
  MAH: "Marshallese",
  MON: "Mongolian",
  NAU: "Nauru",
  NAV: "Navajo; Navaho",
  NDE: "Ndebele, North; No",
  NBL: "Ndebele, South; South Ndebe",
  NDO: "Ndonga",
  NEP: "Nepali",
  SME: "Northern Sami",
  NOR: "Norwegian",
  NOB: "Norwegian Bokmål",
  NNO: "Norwegian Nynorsk; Nyn",
  OCI: "Occitan (post 1500); Provençal",
  OJI: "Ojibwa",
  ORI: "Oriya",
  ORM: "Oromo",
  OSS: "Ossetian; Ossetic",
  PLI: "Pali",
  FAS: "Persian",
  POL: "Polish",
  PUS: "Pushto; Pashto",
  QUE: "Quechua",
  QAA: "Reserved",
  RON: "Romanian; Moldavian; Moldovan",
  ROH: "Romansh",
  RUN: "Rundi",
  SMO: "Samoan",
  SAG: "Sango",
  SAN: "Sanskrit",
  SRD: "Sardinian",
  SRB: "Serbian",
  SNA: "Shona",
  III: "Sichuan Yi; Nuosu",
  SND: "Sindhi",
  SIN: "Sinhala; Sinhalese",
  SLK: "Slovak",
  SLV: "Slovenian",
  SOM: "Somali",
  SOT: "Sotho, Southern",
  SUN: "Sundanese",
  SWA: "Swahili",
  SSW: "Swati",
  SWE: "Swedish",
  TGL: "Tagalog",
  TAH: "Tahitian",
  TGK: "Tajik",
  TAM: "Tamil",
  TAT: "Tatar",
  TEL: "Telugu",
  THA: "Thai",
  BOD: "Tibetan",
  TIR: "Tigrinya",
  TON: "Tonga (Tonga Islands)",
  TSO: "Tsonga",
  TSN: "Tswana",
  TUR: "Turkish",
  TUK: "Turkmen",
  TWI: "Twi",
  UIG: "Uighur; Uyghur",
  UKR: "Ukrainian",
  UZB: "Uzbek",
  VEN: "Venda",
  VOL: "Volapük",
  WLN: "Walloon",
  CYM: "Welsh",
  FRY: "Western Frisian",
  WOL: "Wolof",
  XHO: "Xhosa",
  YID: "Yiddish",
  YOR: "Yoruba",
  ZHA: "Zhuang; Chuang",
  ZUL: "Zulu",
  ORJ: "Custom (ORJ)",
  QPC: "Custom (QPC)",
  TNG: "Custom (TNG)"
};


export const languagesTwoLetterConfig = {
  AF: 'Afrikaans',
  SQ: 'Albanian',
  AR: 'Arabic',
  HY: 'Armenian',
  EU: 'Basque',
  BN: 'Bengali',
  BG: 'Bulgarian',
  CA: 'Catalan',
  ZH: 'Chinese (Mandarin)',
  HR: 'Croatian',
  CS: 'Czech',
  DA: 'Danish',
  NL: 'Dutch',
  EN: 'English',
  ET: 'Estonian',
  FJ: 'Fiji',
  FI: 'Finnish',
  FR: 'French',
  KA: 'Georgian',
  DE: 'German',
  EL: 'Greek',
  GU: 'Gujarati',
  HE: 'Hebrew',
  HI: 'Hindi',
  HU: 'Hungarian',
  IS: 'Icelandic',
  ID: 'Indonesian',
  GA: 'Irish',
  IT: 'Italian',
  JA: 'Japanese',
  JW: 'Javanese',
  KO: 'Korean',
  LA: 'Latin',
  LV: 'Latvian',
  LT: 'Lithuanian',
  MK: 'Macedonian',
  MS: 'Malay',
  ML: 'Malayalam',
  MT: 'Maltese',
  MI: 'Maori',
  MR: 'Marathi',
  MN: 'Mongolian',
  NE: 'Nepali',
  NO: 'Norwegian',
  FA: 'Persian',
  PL: 'Polish',
  PT: 'Portuguese',
  PA: 'Punjabi',
  QU: 'Quechua',
  RO: 'Romanian',
  RU: 'Russian',
  SM: 'Samoan',
  SR: 'Serbian',
  SK: 'Slovak',
  SL: 'Slovenian',
  ES: 'Spanish',
  SW: 'Swahili',
  SV: 'Swedish',
  TA: 'Tamil',
  TT: 'Tatar',
  TE: 'Telugu',
  TH: 'Thai',
  BO: 'Tibetan',
  TO: 'Tonga',
  TR: 'Turkish',
  UK: 'Ukrainian',
  UR: 'Urdu',
  UZ: 'Uzbek',
  VI: 'Vietnamese',
  CY: 'Welsh',
  XH: 'Xhosa',
};

export const languagesTranslateConfig = {
  AFR: 'Afrikaans',
  SQI: 'Albanian',
  AMH: 'Amharic',
  ARA: 'Arabic',
  AZE: 'Azerbaijani',
  BEN: 'Bengali',
  BOS: 'Bosnian',
  BUL: 'Bulgarian',
  ZHO: 'Chinese',
  HRV: 'Croatian',
  CES: 'Czech',
  DAN: 'Danish',
  FAS: 'Persian',
  NLD: 'Dutch',
  ENG: 'English',
  EST: 'Estonian',
  FIN: 'Finnish',
  FRA: 'French',
  KAT: 'Georgian',
  GER: 'German',
  ELL: 'Greek',
  HAU: 'Hausa',
  HEB: 'Hebrew',
  HIN: 'Hindi',
  HUN: 'Hungarian',
  IND: 'Indonesian',
  ITA: 'Italian',
  JPN: 'Japanese',
  KOR: 'Korean',
  LAV: 'Latvian',
  MSA: 'Malay',
  NOR: 'Norwegian',
  PUS: 'Pashto',
  POL: 'Polish',
  POR: 'Portuguese',
  RON: 'Romanian',
  RUS: 'Russian',
  SRB: 'Serbian',
  SLK: 'Slovak',
  SLV: 'Slovenian',
  SOM: 'Somali',
  SPA: 'Spanish',
  SWA: 'Swahili',
  SWE: 'Swedish',
  TGL: 'Tagalog',
  TAM: 'Tamil',
  THA: 'Thai',
  TUR: 'Turkish',
  UKR: 'Ukrainian',
  URD: 'Urdu',
  VIE: 'Vietnamese'
}

export const translationStatusesConfig = {
  'IN_PROGRESS': 'In Progress',
  'SUBMITTED': 'Submitted',
  'COMPLETED': 'Completed',
}

export const distributorMap = {
  'brightcove-api': {
    icon: 'brightcove_logo',
    name: 'Brightcove',
    header: 'Brightcove'
  },
  'sling': {
    icon: '',
    name: 'Sling',
    header: 'Sling TV'
  },
  'sinclair': {
    icon: '',
    name: 'Sinclair',
    header: 'Sinclair TV'
  },
  'xumo': { // TBD...
    icon: '',
    name: 'xumo',
    header: 'Xumo'
  }
}






