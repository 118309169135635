/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slack': {
    width: 62,
    height: 16,
    viewBox: '0 0 62 16',
    data: '<path pid="0" d="M20.051 12.68l.758-1.755c.818.61 1.905.926 2.98.926.793 0 1.294-.305 1.294-.768-.012-1.291-4.75-.28-4.787-3.521-.013-1.645 1.453-2.913 3.529-2.913 1.233 0 2.467.305 3.346 1l-.708 1.79c-.806-.51-1.808-.876-2.76-.876-.647 0-1.075.304-1.075.694.012 1.267 4.788.573 4.836 3.668 0 1.681-1.428 2.864-3.48 2.864-1.502 0-2.882-.354-3.933-1.11zM49.105 10.291a2.198 2.198 0 01-1.905 1.11 2.181 2.181 0 110-4.363c.819 0 1.527.45 1.905 1.109l2.089-1.158a4.593 4.593 0 00-3.994-2.34 4.576 4.576 0 00-4.58 4.57 4.576 4.576 0 004.58 4.57 4.554 4.554 0 003.994-2.34l-2.089-1.158zM31.128.86h-2.613v12.758h2.613V.86zM52.22.86v12.758h2.613V9.792l3.102 3.826h3.346l-3.944-4.545 3.651-4.24h-3.2l-2.955 3.52V.86H52.22zM38.895 10.316c-.378.621-1.16 1.084-2.039 1.084a2.181 2.181 0 110-4.362c.88 0 1.66.487 2.04 1.12v2.158zm0-5.484v1.036c-.427-.719-1.49-1.219-2.6-1.219-2.297 0-4.104 2.023-4.104 4.558 0 2.534 1.807 4.582 4.103 4.582 1.112 0 2.174-.5 2.602-1.219v1.036h2.613V4.832h-2.614z" _fill="#4F4F4F"/><path pid="1" d="M3.894 10.303c0 .89-.72 1.609-1.612 1.609a1.609 1.609 0 110-3.217h1.612v1.608zM4.7 10.303c0-.89.72-1.608 1.612-1.608.891 0 1.612.719 1.612 1.608v4.022c0 .89-.72 1.608-1.612 1.608A1.609 1.609 0 014.7 14.325v-4.021z" _fill="#85879B"/><path pid="2" d="M6.312 3.845a1.609 1.609 0 110-3.217c.891 0 1.612.72 1.612 1.609v1.608H6.312zM6.312 4.662a1.609 1.609 0 110 3.217H2.269a1.609 1.609 0 110-3.217h4.043z" _fill="#E0E0E0"/><path pid="3" d="M12.773 6.27c0-.89.72-1.608 1.611-1.608a1.609 1.609 0 110 3.217h-1.611V6.27zM11.966 6.27c0 .89-.72 1.609-1.612 1.609A1.609 1.609 0 018.742 6.27V2.237c0-.89.72-1.609 1.612-1.609.892 0 1.612.72 1.612 1.609V6.27z" _fill="#828282"/><path pid="4" d="M10.354 12.716a1.609 1.609 0 110 3.217 1.609 1.609 0 01-1.612-1.608v-1.609h1.612zM10.354 11.912a1.609 1.609 0 110-3.217h4.043a1.609 1.609 0 110 3.217h-4.043z" _fill="#E0E1E7"/>'
  }
})
