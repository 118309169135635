<template>

<modal
  name="mediatailor-delete-error"
  width="600"
  height="auto"
  :scrollable="true"
  classes="v--modal p2 mediatailor-delete-error-modal-component"
  :clickToClose="false"
  @before-open="beforeOpen"
>
  <button-component
    class="close-modal-btn"
    variant="minimal"
    shape="circle"
    @click="closeModal()">
      <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
  </button-component>

  <section>

    <h3>This mediatailor is used in the following channels:</h3>

    <h6>(click to navigate)</h6>


    <section class="vods-for-deletion-list">
      <a class="pointer block truncate size-1 mb2" v-for="channel in inUseChannels"
        @click="navigateToChannel(channel.guid)"
        >{{ channel.name }}
      </a>

    </section>

    <h4 class="mb2">Please remove it from the channels above in order to delete it.</h4>
  </section>

</modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    inUseChannels: [],
  }),
  props: {},
  methods: {

    ...mapActions({

      makeArchiveVodRequest: 'channelManager/makeArchiveVodRequest',
    }),

    beforeOpen (event) {

    	this.inUseChannels = event.params;
    },

    closeModal() {

      this.$modal.hide('mediatailor-delete-error');

      this.inUseChannels = [];
    },

    navigateToChannel(guid) {

      this.$pushRoute(`/vod?channel-guid=${guid}`);
    }
  },

}
</script>

<style lang="scss" scoped>



.vods-for-deletion-list {

  max-height: 450px;
  overflow: auto;
}

.close-modal-btn {

  float: right;
}

</style>
