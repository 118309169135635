import { routesConfig } from '../configs/routesAuth.config'

/**
 * Checks if a route is permitted, considering both
 * user's auth status and the route auth spec.
 * Used ONLY on initial page load. For checks, comming from
 * route changes, use `shouldRedirectToUnauth` && `shouldRedirectToAuth`
 */
export const isRoutePermitted = (routeName, userState) => {

  const {

    isUserAuthenticated,
    isOrganizationSelected,
    isAdminUser,
    userPermissions

  } = userState;

  /**
   * There is not such path defined in the app, the user
   * will be redirected to one of the default paths.
   */
  if (!routesConfig[routeName]) {

    return false;
  }

  const routePrerequisites = routesConfig[routeName];

  let isUserAllowed = true;

  /**
   * The user is authenticated, but the route should only be accessed
   * from NON-authenticated user, or the other way around
   */
  if (routePrerequisites.requiresAuth !== null &&
      routePrerequisites.requiresAuth !== isUserAuthenticated) {

    isUserAllowed = false;
  }


  if (routePrerequisites.isAdminRoute && !isAdminUser) {

    isUserAllowed = false;
  }


  if (routePrerequisites.requiresOrganizationSelected && !isOrganizationSelected) {

    isUserAllowed = false;
  }

  /**
   * If this point is reach and still isUserAllowed, check all permissions
   */
  if (isUserAllowed) {

    for (let permission in routePrerequisites.permissions) {


      // NOTE: This check is in order to please IC-1628. It should be removed once all organizations are migrated on the BE to have the new permissions. Then the new permissions have to be added in these files:
      // routesConfig[-route-].permissions (instead of `WORKFLOW`)
      // store.modules.app -> navItems[-navitem-].permission

      // Implementing this check means, that if a user has either `live_workflows_list` or `WORKFLOW` in their userPermissions (comming from BE), they can see ALL live routes

      // The userPermissions['live_workflows_list'] || userPermissions['WORKFLOW'] is beacuse on organizations, that are migrated, it is basically mandatory for users, that have LIVE installed to have permission `live_workflows_list`. And on organizations, that use the old LIVE version `WORKFLOW` is present.
      if (routePrerequisites.permissions['WORKFLOW'] &&
        (userPermissions['live_workflows_list'] || userPermissions['WORKFLOW'])) {

        return true;
      }
      // END note

      if (!userPermissions[permission]) {

        isUserAllowed = false;
        break;
      }
    }
  }

  return isUserAllowed;
}

/**
 * - Returns a Boolean, considering provided `userPermissions`
 * and `requiredPermissions`.
 * - `requiredPermissions` Can be an Array of strings, or a string.
 * - If any of the provided strings in the Array isn't matched to
 * the `userPermissions` the check will fail.
 * - `userPermissions` should come from the store.
 * - This function is aimed to be used by the `v-auth` directive,
 * defined in enhanceApp.js.
 */
export const isPermissionGranted = (requiredPermissions, userPermissions) => {

  let isUserAllowed = true;

  const permissionsToTestAgainst =
    Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];



  // NOTE: This check is in order to please IC-1628. It should be removed once all organizations are migrated on the BE to have the new permissions. Then the new permissions have to be added in these files:
  // routesConfig[-route-].permissions (instead of `WORKFLOW`)
  // store.modules.app -> navItems[-navitem-].permission

  // Implementing this check means, that if a user has either `live_workflows_list` or `WORKFLOW` in their userPermissions (comming from BE), they can see ALL live routes

  // The userPermissions['live_workflows_list'] || userPermissions['WORKFLOW'] is beacuse on organizations, that are migrated, it is basically mandatory for users, that have LIVE installed to have permission `live_workflows_list`. And on organizations, that use the old LIVE version `WORKFLOW` is present.
  if (permissionsToTestAgainst.includes('WORKFLOW') &&
    (userPermissions['live_workflows_list'] || userPermissions['WORKFLOW'])) {

    return true;
  }
  // END note

  for (let permission of permissionsToTestAgainst) {

    if (!userPermissions || !userPermissions[permission]) {

      isUserAllowed = false;
      break;
    }
  }

  return isUserAllowed;
}

