const _logWorkedOnItem = (commit, rootState) => {

  commit('app/LOG_WORKED_ON_ITEM', {
    title: rootState.events.eventDetails.name,
    type: 'Event',
    loggedAt: new Date().getTime(),
    url: rootState.app.currentRoute.fullPath
  }, {
    root: true
  });
};

const getEventsApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getEventsApiKeys',
    baseUrlType: 'BASE_EVENTS_URL',
  }, {
    root: true
  });
}

const getEventsApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getEventsApiKey',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  });
}

const storeNewEventsApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewEventsApiKey',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const updateEventsApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateEventsApiKey',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const deleteEventsApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteEventsApiKey',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  });
}

const getEvents = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getEvents',
    baseUrlType: 'BASE_EVENTS_URL',
  }, {
    root: true
  });
}

const getEventsElastic = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getEventsElastic',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  });
}

const getEventById = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getEventById',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  }).then(resp => {

    commit('SET_EVENT_DETAILS', resp);
  });
}

const storeNewEvent = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const updateEvent = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}

const deleteEvent = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  });
}

const getAttendees = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getAttendees',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  });
}

const addAttendee = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'addAttendee',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}

const updateAttendee = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateAttendee',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}

const deleteAttendee = ({ state, commit, dispatch, rootState }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteAttendee',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}

const importAttendeesCsv = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'importAttendeesCsv',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}

const exportAttendeesCsv = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'exportAttendeesCsv',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}


const duplicateAttendees = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'duplicateAttendees',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {   

    return response;
  });
}

const getEventSessions = ({ state, commit, dispatch }, params) => {
  // NOTE: the BE doesn't support sessions standalone, instead they are saved on the event
  return dispatch('app/makeRequest', {
    type: 'getEventById',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  })
  .then((data) => {

    commit('SET_EVENT_DETAILS', data);

    return data;
  });
}

const addEventSession = ({ state, commit, dispatch }, payload) => {

  // NOTE: the BE doesn't support sessions standalone, instead they are saved on the event
  return dispatch('app/makeRequest', {
    type: 'updateEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const updateEventSession = ({ state, commit, dispatch }, payload) => {

  // NOTE: the BE doesn't support sessions standalone, instead they are saved on the event
  return dispatch('app/makeRequest', {
    type: 'updateEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const deleteEventSession = ({ state, commit, dispatch }, payload) => {

  // NOTE: the BE doesn't support sessions standalone, instead they are saved on the event
  return dispatch('app/makeRequest', {
    type: 'updateEvent',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  });
}

const getMixerSettings = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getMixerSettings',
    baseUrlType: 'BASE_EVENTS_URL',
    params
  }, {
    root: true
  })
  .then(resp => {

    commit('SET_MIXER_SETTINGS', resp);

    return resp;
  });
}

const updateMixerSettings = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateMixerSettings',
    baseUrlType: 'BASE_EVENTS_URL',
    payload
  }, {
    root: true
  })
  .then((response) => {

    _logWorkedOnItem(commit, rootState);

    return response;
  });
}



export {

  getEventsApiKeys,
  getEventsApiKey,
  storeNewEventsApiKey,
  updateEventsApiKey,
  deleteEventsApiKey,

  getEvents,
  getEventsElastic,
  getEventById,
  storeNewEvent,
  updateEvent,
  deleteEvent,

  getAttendees,
  addAttendee,
  updateAttendee,
  deleteAttendee,
  importAttendeesCsv,
  exportAttendeesCsv,
  duplicateAttendees,

  getEventSessions,
  addEventSession,
  updateEventSession,
  deleteEventSession,
  getMixerSettings,
  updateMixerSettings
}