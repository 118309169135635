/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-event': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M11 18a1 1 0 01-.707-.293L6.586 14 8 12.586l3 3 6-6L18.414 11l-6.707 6.707A1 1 0 0111 18z"/><path pid="1" d="M23 3h-5V1a1 1 0 00-2 0v2H8V1a1 1 0 00-2 0v2H1a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V4a1 1 0 00-1-1zm-1 18H2V7h20z"/></g>'
  }
})
