<template>

<modal
  :name="modalName"
  width="600"
  height="auto"
  :scrollable="true"
  classes="v--modal p2 deployment-settings-form-component"
  @before-close="beforeModalClose"
>

  <h1>{{ modalName === 'edit-deployment' ? 'Edit Deployment' : 'Create Deployment' }}</h1>
  <vue-form @submit.prevent :state="formState">

    <validate class="" tag="label">
      <span class="">Name <span class="text-danger">*</span></span>
      <input
        class="input"
        type="text"
        placeholder="Name"
        required
        name="name"
        maxlen="100"
        v-model="deploymentData.name"/>
      <field-messages name="name" show="$submitted || $dirty && $touched">
        <div slot="required">Name is a required field</div>
        <div slot="maxlen">Name length should not be more than 100 characters</div>
      </field-messages>
    </validate>

    <validate class="" tag="label">
      <span class="">Type <span class="text-danger">*</span></span>

      <select
        required
        name="type"
        v-model="deploymentData.type"
        >
          <option
            v-for="option,i in possibleDeploymentTypes"
            :value="option.value"
            :key="i"
          >{{ option.label }}</option>
      </select>

      <field-messages name="type" show="$submitted || $dirty && $touched">
        <div slot="required">Type is a required field</div>
      </field-messages>
    </validate>

    <validate class="" tag="label">
      <span class="">Region <span class="text-danger">*</span></span>

      <select
        required
        name="region"
        v-model="deploymentData.region"
        @change="onStagedDeploymentRegionChange($event)"
      >

        <option
          v-for="option,i in possibleAwsRegions"
          :value="option.value"
          :key="i"
        >{{ option.label }}</option>
      </select>

      <field-messages name="region" show="$submitted || $dirty && $touched">
        <div slot="required">Default Region is a required field</div>
      </field-messages>
    </validate>

    <div class="mt-2 w-max" :class="dropdownMargin" v-if="deploymentData.type === 'live'">
      <div class="label">Additional Regions</div>
      <ioio-dropdown
        @open="openDropdown"
        @close="closeDropdown"
        v-model="deploymentData.additionalRegionsSelected"
        :items="possibleAdditionalRegions"
        :multiple="true"
        placeholder=""
        titleProp="label"
        valueProp="value"
      />
    </div>

    <label class="mt1" v-if="deploymentData.type === 'search'">
      <span class="">AWS Account</span>

      <select
        v-model="deploymentData.awsAccountGuid"
        required
        name="awsAccountGuid"
      >
        <option :value="''"></option>
        <option
          v-for="option,i in awsAccounts"
          :value="option.guid"
          :key="i"
        >{{ option.label }} : {{ option.profileName }}</option>
      </select>
    </label>

    <footer class="mt3">

      <article v-if="!isRequestPending">
        <button-component
          variant="primary"
          @click="onEditDone"
          class="mr2"
        >{{submitLabel}}</button-component>
        <button-component
          @click.stop.prevent="$modal.hide(modalName)"
        >Cancel</button-component>
      </article>

      <logo-loader-component width="40px" height="40px" v-else/>

    </footer>

  </vue-form>
</modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    possibleDeploymentTypes: [],

    possibleAwsRegions: [{
        value: "us-east-1",
        label: "US East (N. Virginia) - us-east-1"
      }, {
        value: "us-west-2",
        label: "US West (Oregon) - us-west-2"
      }, {
        value: "eu-west-1",
        label: "EU (Ireland) - eu-west-1"
    }, {
        value: "ca-central-1",
        label: "Canada (Central) - ca-central-1"
    }],

    awsAccounts: [],
    dropdownMargin: ''
  }),
  props: {
    formState: Object,
    deploymentData: Object,
    onStagedDeploymentRegionChange: Function,
    possibleAdditionalRegions: Array,
    onEditDone: Function,
    submitLabel: String,
    modalName: String,
    beforeModalClose: Function,
    isRequestPending: Boolean,
    selectedOrg: Object
  },

  mounted() {

    let availableDeploymentTypes = [...this.createEditPossibleDeploymentTypes];

    // The following deployment options should only be visible for MASTER organization
    if (this.selectedOrg && this.selectedOrg.guid === '8381a5f2-fb11-4900-99d6-ee8fcdd44d00') {

      availableDeploymentTypes = [

        ...availableDeploymentTypes,
        ...this.createEditPossibleDeploymentTypesMasterOrgOnly
      ];


      this.getAwsAccounts().then(awsAccounts => {

        this.awsAccounts = awsAccounts;
      });
    }

    this.possibleDeploymentTypes = [...availableDeploymentTypes];
  },

  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getAwsAccounts: "app/getAwsAccounts",
    }),
    openDropdown() {

      this.dropdownMargin='mb-24';
    },
    closeDropdown() {

      this.dropdownMargin='';
    }

  },
  computed: {
    ...mapGetters({
      createEditPossibleDeploymentTypes: "app/createEditPossibleDeploymentTypes",
      createEditPossibleDeploymentTypesMasterOrgOnly: "app/createEditPossibleDeploymentTypesMasterOrgOnly"
    })
  },
}
</script>

<style lang="scss">



.deployment-settings-form-component {

  .multiselect__tag {
    color: #35495e;
  }

  .multiselect__tag-icon:after {
    content: none;
  }

  .multiselect__option--highlight {
    background: rgb(0, 128, 255);
    outline: none;
    color: #fff;
  }

  .multiselect__option:after {
    background: rgb(0, 128, 255);
  }

  .label {
    font-size: .875em;
    font-weight: 700;
  }

  .multiselect--active {
    z-index: 50;
  }

}
</style>
