const backgroundColors = [
  '#0077FF',
  '#0F855C',
  '#EC4C47',
  '#7057FF',
  '#557391',
  '#5271B7',
  '#505462',
  '#8A6A00',
  '#0056B8',
  '#0B6546',
  '#AB1412',
  '#4D2EFF',
  '#425970',
  '#3E5793',
  '#353741',
  '#6B5200',
];
export default {
  backgroundColors
}