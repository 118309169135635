import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const state = {

  distributors: [],
  importDistributorsList: false,
  choosenDistributor: {},
  googleDriveData: null,
  googleDriveTempData: null
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}