<template>
  <transition name="menu-popover">
    <section class="main-section">
      <div class="dropdown-overlay" @click.stop="onClose"></div>

      <div class="dropdown-container" :class="[variant, size, shape, align]" @click.stop="onClose" :width="[width]" v-bind:style="{ '--width': width }">
        <div class="dropdown">
          <!--div class="dropdown-arrow"></div-->
          <div class="dropdown-content pointer">
            <slot />
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  props: {
    variant: {
      type: String,
      required: false,
      default: "default"
    },
    size: {
      type: String,
      required: false,
      default: "size-s"
    },
    shape: {
      type: String,
      required: false,
      default: ""
    },
    width: {
      type: String,
      required: false,
      default: "10rem"
    },
    onClose: {
      type: Function,
      required: true
    },
    align: {
      type: String,
      required: false,
    }
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  computed: {
    ...mapGetters({})
  }
};
</script>

<style lang="scss" scoped>


.menu-popover-enter,
.menu-popover-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.menu-popover-enter-to,
.menu-popover-leave {
  opacity: 1;
  transform: scale(1);
}

.menu-popover-enter-active {
  transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1),
    transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
}

.menu-popover-leave-active {
  transition: opacity 180ms ease-out,
    transform 180ms ease-out;
}

.main-section {
  position: relative;
  z-index: 9999;
}

.dropdown-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdown-arrow-container {
  display: flex;
  align-self: center;
}

.dropdown-container {
  position: absolute;
  z-index: 9999;
  left: calc(var(--width) / -2);
  width: var(--width);
  margin-left: 50%;
}

.dropdown-arrow {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMjEgOSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBmaWxsPSIjODg5OEFBIiBmaWxsLW9wYWNpdHk9Ii4xIiBkPSJNMSA5LjA5MmgxOWwtNi40MDItNi43NGMtMS43MTctMS44MDYtNC40ODUtMS44LTYuMTk2IDBMMSA5LjA5M3pNMjAuMzQyIDhsLTYuMDItNi4zMzZjLTIuMTA4LTIuMjItNS41MzgtMi4yMTgtNy42NDUgMEwuNjU4IDhoMTkuNjg0eiIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik03LjQwMiAyLjM1M2MxLjcxMS0xLjgwMSA0LjQ4LTEuODA3IDYuMTk2IDBMMjAgOS4wOTNIMWw2LjQwMi02Ljc0eiIvPjwvZz48L3N2Zz4=")
    no-repeat;
  background-position: center center;
  height: 21px;
  width: 21px;
  margin: -7px;
  z-index: 1;
  display: flex;
  align-self: center;
}

.dropdown-container.align-right {
  left: auto;
  right: 0;
  margin-left: 0%;
  .dropdown-arrow {
    align-self: end;
    margin-right: .25rem;
  }
}

.dropdown-content {
  border-radius: 4px;
  overflow: hidden;
  background: var(--windowBgColor);
  box-shadow: 0px 0px 0px 1px rgba(136, 152, 170, 0.1),
    0px 15px 35px 0px rgba(49, 49, 93, 0.1),
    0px 5px 15px 0px rgba(0, 0, 0, 0.08);
  z-index: 0;
  position: relative;
}

.dropdown-content .disabled { pointer-events: none; opacity: .6;}

</style>
