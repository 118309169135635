<template>
<section class="action-confirm-modal-component">

  <ioio-button
    v-if="isDialogOpenBtnVisible"
    :type="dialogOpenBtnVariant"
    :size="dialogOpenBtnSize"
    class="dialog-open-btn"
    :class="dialogOpenBtnClass"
    @click.stop.prevent="() => openActionModal()"
    :topic="btnTopic"
  >{{ dialogOpenBtnLabel }}
  </ioio-button>

  <ioio-modal
    :show.sync="isModalOpened"
    classes="v--modal action-confirm-modal-component flex flex-column justify-between">

    <template slot="header">

      <div class="">Are you sure?</div>

    </template>
    <section class="flex-none">

    <h1 class="text-lg mt-8 mb-4">{{ actionConfirmLabel }}</h1>
    <h2 class="text-md text-black-400 mb-4" v-if="actionConfirmSecondaryLabel">{{ actionConfirmSecondaryLabel }}</h2>

    <logo-loader-component width="50px" height="50px" v-if="isLoadingIndicatorVisible"/>
    </section>

    <footer class="flex items-center justify-end mt-8">


      <ioio-button
        @click.stop="closeActionModal"
        class="mr-4"
        type="secondary"
        variant="outline"
      >Cancel</ioio-button>

      <ioio-button
        :type="actionConfirmBtnVariant"
        @click.stop="confirmAction"
      >{{ actionConfirmBtnLabel }}</ioio-button>

    </footer>

  </ioio-modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isModalOpened: false,
  }),
  props: {

    // NOTE: Needed when there are more the one modals on a page!
    // If there are more than one modals with the same name on a given page,
    // they will all open up at once, leading to unexpected behavior.
    dialogId: {
      // type: String,
      default: '1',
    },

    dialogOpenBtnLabel: {
      type: String,
      default: 'Action',
    },

    dialogOpenBtnVariant: {
      type: String,
      default: 'default',
    },

    dialogOpenBtnSize: {
      type: String,
      default: 'size-xs',
    },

    dialogWidth: {
      type: String,
      default: '500px',
    },

    dialogHeight: {
      type: String,
      default: 'auto',
    },

    dialogOpenBtnClass: {
      type: String
    },

    btnTopic: {
      type: String
    },

    isDialogOpenBtnVisible: {
      type: Boolean
    },

    actionConfirmLabel: {

      type: String,
      default: 'Are you sure you want to action?'
    },

    actionConfirmSecondaryLabel: {

      type: String
    },

    actionConfirmBtnLabel: {
      type: String,
      default: 'Confirm',
    },

    actionConfirmBtnVariant: {
      type: String,
      default: 'danger',
    },

    isActionConfirmBtnVisible: {
      type: Boolean
    },

    onActionConfirm: {
      type: Function
    },

    onActionReject: {
      type: Function
    },

    isLoadingIndicatorVisible: {

      type: Boolean,
      default: false
    }
  },
  methods: {

    openActionModal() {
      console.log(1, this.dialogId)

      this.isModalOpened = true;
    },

    closeActionModal() {

      this.onActionReject && this.onActionReject();
      this.isModalOpened = false;
    },

    confirmAction() {

      this.isModalOpened = false;
      this.onActionConfirm && this.onActionConfirm();
    }
  }
}
</script>

<style lang="scss">



.action-confirm-modal-component {

  /* Needed so that it aligns with adjascent elements outside the component */
  .dialog-open-btn {

    float: left;
  }
}

</style>
