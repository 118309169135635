<template>
<section class="live-tab-component">

  <div class="pane-content flex-auto p1" style="overflow: auto">
    <ul class="list-reset m0">
      <DraggableHolderComponent
        group-name="library-playlist"
        :get-child-payload="getChildPayload"
        :should-accept-drop="shouldAcceptDrop"
        :get-ghost-parent="getGhostParent"
        drag-class="ghost-element"
      >
        <DraggableComponent>
          <li
            class="fileitem flex mb1 box border rounded-4">
            <div class="fileitem-info ml1 flex flex-column flex-auto">
              <div class="flex items-center mb-025">
                <tag-component size="size-s" class="">{{ parseDuration(liveChunk.srcDuration) }}</tag-component>
              </div>

              <div class="flex items-center">
                <div
                  class="rounded-2 mr1" style="width: 1rem; height: 6px" :style="{ width: '1rem', height: '6px', background: liveChunk.color }">
                </div>
                <h4
                  class="fileitem-title size-1 m0"
                  >{{ (liveChunk.meta.name) | truncate(64, '...') }}
                </h4>
              </div>
              <h4
                class="fileitem-title size-3 gray+1 italic">{{ liveChunk.meta.description | truncate(64, '...') }}
              </h4>
            </div>
          </li>
        </DraggableComponent>
      </DraggableHolderComponent>
    </ul>

    <vue-form :state="formState" @submit.prevent>

      <h4>Enter the details of the Live Chunk</h4>

      <div>
        <label>Duration (ms)</label>
        <input
          type="number"
          min="60000"
          max="720000"
          placeholder="Duration"
          v-model="liveChunk.srcDuration"
        >
      </div>

      <validate class="" tag="label">
        <span class="text-danger">Name <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="Name"
          v-model="liveChunk.meta.name"
          required
          name="liveChunkName"/>
        <field-messages name="liveChunkName" show="$submitted || $dirty && $touched">
          <div slot="required">Name is a required field</div>
        </field-messages>
      </validate>

      <validate class="" tag="label">
        <span class="text-danger">HLS URL <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="HLS URL"
          v-model="liveChunk.url"
          required
          name="liveChunkUrl"/>
        <field-messages name="liveChunkUrl" show="$submitted || $dirty && $touched">
          <div slot="required">HLS URL is a required field</div>
        </field-messages>
      </validate>

      <validate class="" tag="label">
        <span class="text-danger">Slate Source GUID <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="Name"
          v-model="liveChunk.guid"
          required
          name="liveChunkSlateGuid"/>
        <field-messages name="liveChunkSlateGuid" show="$submitted || $dirty && $touched">
          <div slot="required">Slate Source GUID is a required field</div>
        </field-messages>
      </validate>

      <div>
        <label>Description</label>
        <input
          type="text"
          placeholder="Description"
          v-model="liveChunk.meta.description"
        >
      </div>

      <div>
        <label>Color</label>

        <swatches
          v-model="liveChunk.color"
          :colors="possibleLiveChunkColors"
          inline
        >
        </swatches>
      </div>
      <footer class="mt1">
        <button-component
          variant="primary"
          @click="clearChunkData"
        >Clear Chunk Data</button-component>
      </footer>
    </vue-form>
  </div>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS, paletteColors } from "@utils/helpers";

export default {
  data: () => ({

    possibleLiveChunkColors: [...paletteColors()],
    dragOrigin: 'live-tab',
    liveChunk: {

      srcDuration: 60000,
      color: '#8E43AD', // bg-purple
      meta: {
        name: 'Live Program',
        description: '',
        type: 'live',
      },
      // a guid should be added : `When schedule is of type ‘source’ or ‘ad’ it represents the source GUID which will be used in the HSL stream. When schedule is of type ‘live’ it represent slate source GUID`
      guid: '',

      // Master HLS URL for the ‘live’ schedules
      url: ''


    },
    formState: {},
  }),
  props: {},
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    }),

    clearChunkData() {

      this.liveChunk = {
        srcDuration: 60000,
        color: '#8E43AD',
        meta: {
          name: 'Live Program',
          description: '',
          type: 'live',
        },
        guid: '',
        url: ''
      };
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(+ms);
    },

    getChildPayload(removedIndex) {

      return {

        item: {
          ...this.liveChunk,
          srcDuration: +this.liveChunk.srcDuration
        },
        origin: this.dragOrigin,
      };
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {
      return false;
    },

    getGhostParent() {
      return document.body;
    },
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>
/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";
@include overwrite-styleberry-styles-inputs;
@include overwrite-styleberry-styles-htags-margin;
@include overwrite-styleberry-styles-htags-font-size;

.fileitem {

  display: flex;
  margin-bottom: 2px;
  border-radius: 0;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;
  &:hover {
    background: var(--highlightBgColor);
  }
}
</style>
