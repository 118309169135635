/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-player': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 2H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V5a3 3 0 00-3-3zM9 17V7l8 5z" _fill="#5c7499"/>'
  }
})
