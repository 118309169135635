<template>
<div class="zoom-integration-user-settings-component flex flex-column flex-auto">
  <ioio-modal
    class=""
    :show.sync="isConfirmDisableModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        Are you sure you want to disable this integration?
      </div>
    </template>

    <h3 class="">
      All integrations with Zoom will be disabled.
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelDisable"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmDisable"
        >
          Disable
        </ioio-button>
      </div>
    </template>

  </ioio-modal>
  <section v-if="!isRequestPending " class="flex flex-column flex-auto mt-16">
    <ioio-checkbox
      size="small"
      disclaimer="The integration will be disabled"
      v-model="isIntegrationDisabled"
      @input="toggleDisableIntegrationnewVal"
    >
      <span class="text-sm">Disable integration</span>
    </ioio-checkbox>

    <footer class="flex justify-end my-8">

      <ioio-button
        type="primary"
        size="medium"
        @click.stop.prevent="saveZoomSettings"
        topic="integrationForm"
      >Save
      </ioio-button>
    </footer>
  </section>
  <div v-else class="flex items-center flex-auto overflow-hidden">
    <logo-loader-component width="100px" height="100px" />
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isConfirmDisableModalOpened: false,
    isRequestPending: false,
    isIntegrationDisabled: false,
    zoomIaSettings: {},
  }),
  props: {},
  created() {

    this.setZoomSettings();
  },
  methods: {

    setZoomSettings() {

      this.isRequestPending = true;

      this.getIngestDistributors()
        .then((distributors) => {
          const zoom = distributors.find(d => d.guid === 'zoom-ia');

          if (!zoom) {

            this.$toasted.error('The Zoom integration is not available in your organisation')
            return;
          }

          this.isIntegrationDisabled = !zoom.enabled;

          this.zoomIaSettings = {

            ...zoom.zoomIaSettings
          };
        })

        .finally(() => {

          this.isRequestPending = false;
        });
    },

    saveZoomSettings() {

      const payload = {

        distributorGuid: 'zoom-ia',

        data: {

          enabled: !this.isIntegrationDisabled,
          zoomIaSettings: {

            ...this.zoomIaSettings
          }
        }
      };

      this.isRequestPending = true;

      this.updateIngestDistributors(payload)
        .then(() => {

          this.$toasted.success('Configuration saved successfully');
        })
        .finally(() => {

          this.setZoomSettings();
        });
    },

    cancelDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(false);
    },

    confirmDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(true, 'force');
    },

    toggleDisableIntegrationnewVal(newVal, force) {

      if (newVal && !force) {

        this.isConfirmDisableModalOpened = true;
        return;
      }

      this.isIntegrationDisabled = newVal;
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getIngestDistributors: "ingest/getIngestDistributors",
      updateIngestDistributors: "ingest/updateIngestDistributors",
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
