<template>
  <svg
    :width="width"
    :height="height"
    class="mx-auto ratio1-1"
    viewBox="0 0 200 100"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      id="outline"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
    />
    <path
      id="outline-bg"
      opacity="0.1"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="				M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
    />
  </svg>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  props: {
    width: {
      type: String,
      required: false,
      default: "100%"
    },
    height: {
      type: Number | String,
      required: false,
      default: "100%"
    }
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  }
};
</script>

<style lang="scss" scoped>


svg {
  stroke-width: 6px;
  transform: scale(2);
  #outline {
    stroke-dasharray: calc(242.776657104492px * 0.01), 242.776657104492px;
    stroke-dashoffset: 0;
    animation: anim 1.2s linear infinite;
    stroke: blue;
  }
  @keyframes anim {
    12.5% {
      stroke: red;
      stroke-dasharray: calc(242.776657104492px * 0.14), 242.776657104492px;
      stroke-dashoffset: calc(-242.776657104492px * 0.11);
    }
    43.75% {
      stroke: blue;
      stroke-dasharray: calc(242.776657104492px * 0.35), 242.776657104492px;
      stroke-dashoffset: calc(-242.776657104492px * 0.35);
    }
    100% {
      stroke: orange;
      stroke-dasharray: calc(242.776657104492px * 0.01), 242.776657104492px;
      stroke-dashoffset: calc(-242.776657104492px * 0.99);
    }
  }
}

</style>
