<template>
<tr v-if="!isDefaultDataUpdating" class="auto-subs-component">
  <td>
    <span class="size-3 truncate">
      Auto Subtitles and Closed Captions
    </span>
  </td>
  <td>
    <check-component
      type="checkbox"
      class="size-0"
      :val="defaultData.value"
      :on-change="() => defaultData.value = !defaultData.value"/>
  </td>
  <td>
  </td>
  <td>
  </td>
  <td>
    <ioio-button
      type="primary"
      variant="solid"
      size="xs"
      class="btn-green"
      @click="updateDefaultData()">Update
    </ioio-button>
  </td>
</tr>
<tr v-else>
  <td colspan="5">
    <logo-loader-component width="50px" height="50px" class="mt3"/>
  </td>
</tr>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isDefaultDataUpdating: false,
    defaultData: {}
  }),
  props: {},

  created () {

    this.$emit('loading', true);

    this.getDefaultData();
  },

  methods: {

    getDefaultData() {

      this.getBrokerSettings('auto-subs').then((defaultData) => {

        this.defaultData = defaultData;

        this.$emit('autoSubs', defaultData.value)

      })
      .finally(() => {

        this.$emit('loading', false),

        this.isDefaultDataUpdating = false
      });

    },

    async updateDefaultData(){

      this.isDefaultDataUpdating = true;

      const updateVal = this.defaultData.value;

      await this.updateBrokerSettings({
        params: 'auto-subs',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultData();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
