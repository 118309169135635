<template>
  <div class="save-new-mediatailor-component">

    <header class="mb-6">
      <h3 v-if="!editedMediatailor.guid">Create New Mediatailor</h3>

      <section v-else class="">
        <h3>{{ editedMediatailor.name }}</h3>
        <h6 class="truncate">{{ editedMediatailor.masterEndpoint }}</h6>
      </section>
    </header>
    <ioio-field
      class="mb-4"
      type="text"
      placeholder="Name"
      v-model="newMediatailor.name"
      required
      label="Name"
      topic="MediatailorSubmit"
    />
    <ioio-dropdown
      class="mb-4"
      label="Type"
      v-model="newMediatailor.type"
      required
      :items="possibleMediatailorTypes"
      titleProp="label"
      valueProp="value"
      topic="MediatailorSubmit"
    />
    <ioio-field
      class="mb-4 oversize"
      type="textarea"
      placeholder="Ad Server URL"
      v-model="newMediatailor.ad_server"
      required
      label="Ad Server URL"
      topic="MediatailorSubmit"
    />
    <!-- Hidden field for validation of Url -->
    <ioio-field
      type="url"
      class="mb-4 hidden-field"
      placeholder="Ad Server URL"
      v-model="newMediatailor.ad_server"
      topic="MediatailorSubmit"
    />
    <!-- End of hidden field -->
    <ioio-field
      type="url"
      class="mb-4"
      placeholder="Slate Ad URL"
      v-model="newMediatailor.slate_ad"
      label="Slate Ad URL"
      topic="MediatailorSubmit"
    />
    <ioio-field
      type="url"
      class="mb-4"
      placeholder="Source URL"
      v-model="newMediatailor.source_url"
      label="Source URL"
      topic="MediatailorSubmit"
    />
    <ioio-field
      type="number"
      class="mb-4"
      placeholder="Log Configuration percent"
      v-model="newMediatailor.logConfigurationPercentage"
      :minimum=0
      :maximum=100
      min="0"
      max="100"
      label="Log Configuration percent"
      topic="MediatailorSubmit"
    />
    <ioio-toggle
      v-model="newMediatailor.adMarkerPassthrough"
      class="mb-4"
      labelLeft="Ad Marker Passthrough"
      topic="MediatailorSubmit"
      color="blue"
    />
    <footer class="flex justify-center">

      <section v-if="!isRequestPending" class="flex justify-end w-full">
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          size="small"
          @click.stop.prevent="closeAddMediatailorForm"
        >
          Cancel
        </ioio-button>
        <ioio-button
          topic="MediatailorSubmit"
          type="primary"
          variant="solid"
          size="small"
          class="btn-green"
          @click="editedMediatailor.guid ? triggerEditMediatailor() : saveNewMediatailor()"
        >
          {{editedMediatailor.guid ? 'Edit' : 'Save'}}
        </ioio-button>
      </section>
      <div class="width+10 flex justify-center" v-else style="width: 140px; height: 40px">
        <logo-loader-component height="40px" class="mt2" />
      </div>
    </footer>
  </div>

</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({

    mediatailorFormState: {},

    newMediatailor: {

      name: "",
      type: 'broker',
      slate_ad: "",
      ad_server: "",
      source_url: "",
      adMarkerPassthrough: false,
      logConfigurationPercentage: 0
    },

    possibleMediatailorTypes: [{
      value: 'broker',
      label: 'Broker',
    }, {
      value: 'live',
      label: 'Live',
    }],

    isRequestPending: false,
  }),
  props: {
    onSaveMediatailor: Function,
    onCloseAddMediatailorForm: Function,
    editedMediatailor: Object
  },

  created() {

    if (this.editedMediatailor.guid) {

      const mappedMediatailorData = {
        name: this.editedMediatailor.name,
        type: this.editedMediatailor.type,
        ad_server: this.editedMediatailor.adServer,
        adMarkerPassthrough: this.editedMediatailor.adMarkerPassthrough,
        logConfigurationPercentage: this.editedMediatailor.logConfigurationPercentage
      };

      const { slateAd, sourceUrl } = this.editedMediatailor;

      slateAd && (
        mappedMediatailorData.slate_ad = slateAd
      );

      sourceUrl && (
        mappedMediatailorData.source_url = sourceUrl
      );

      this.newMediatailor = {
        ...mappedMediatailorData
      };
    }
  },

  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      makeSaveNewMediatailorRequest: "channelManager/makeSaveNewMediatailorRequest",

      editMediatailor: "channelManager/editMediatailor",
    }),

    expandTextarea() {

      let textarea = document.querySelector(".ds-fields__textarea ");

      textarea.style.height = 'auto';
      textarea.style.height = (textarea.scrollHeight+10)+'px';
    },

    saveNewMediatailor() {

      this.isRequestPending = true;

      this.makeSaveNewMediatailorRequest(this.newMediatailor).then(() => {

        this.$toasted.success(`Mediatailor ${this.$options.filters.truncate(this.newMediatailor.name, 64, "...")} was saved successfully.`);

        if (this.onSaveMediatailor) {

          this.onSaveMediatailor(this.newMediatailor);

          this.closeAddMediatailorForm();
        }
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    triggerEditMediatailor() {

      const payload = {

        data: this.newMediatailor,
        guid: this.editedMediatailor.guid
      };

      this.isRequestPending = true;

      this.editMediatailor(payload).then(() => {

        this.$toasted.success(`Mediatailor ${this.$options.filters.truncate(this.newMediatailor.name, 64, "...")} was updated successfully.`);

        if (this.onSaveMediatailor) {

          this.onSaveMediatailor(this.newMediatailor);

          this.closeAddMediatailorForm();
        }
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    closeAddMediatailorForm() {

      this.newMediatailor = {

        name: "",
        type: 'broker',
        slate_ad: "",
        ad_server: "",
        source_url: ""
      };

      if (this.onCloseAddMediatailorForm) {

        this.onCloseAddMediatailorForm();
      }
    }
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
  watch: {
    'newMediatailor.ad_server'() {
      this.expandTextarea();
    }
  }
};
</script>

<style lang="scss">
  /**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

@import "@indexScss";

.save-new-mediatailor-component {
  @include overwrite-styleberry-styles-htags-font-size;
  .ds-fields__label {
    @apply mb-0;
  }
  .hidden-field {
    .relative {
      @apply hidden;
    }
  }
}
</style>
