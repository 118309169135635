<template>
   <ioio-modal
    :noClose="true"
    :clickOutside="false"
    :show.sync="isDistributeModalOpened"
    size="large"

  >
    <div class="video-distribute-modal-component">
      <div class=" flex items-center justify-between pb-3 relative">
        <h1 v-if="vodDetails && vodDetails.meta" class="text-2xl">Distribute {{vodDetails.meta.title}}</h1>
        <ioio-button
          v-if="!isDistributeInProgress"
          class="text-large"
          size="large"
          type="button"
          @click="toggleDistributeModalOpened(false)"
          data-dismiss="modal"
        >
          <ioio-icon icon="fal-times" />
        </ioio-button>
      </div>

    </div>
    <library-vod-distributions-component v-if="isDistributeModalOpened"/>
  </ioio-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isDistributeInProgress: false
  }),
  props: {
    vodDetails: {
      type: Object|Boolean
    },
    toggleDistributeModalOpened: Function,
    isModalOpened: Boolean
  },
  methods: {

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    isDistributeModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(newVal) {

        this.toggleDistributeModalOpened(!!newVal);
      }
    },
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
