<template>
<div
  class="library-side-view-single-vod-component flex flex-auto flex-column"
  :class="{ 'is-edit-mode-on' : isVodPanelInEditMode }"
>


  <ioio-modal
    @close="cancelGenerateUrl"
    class=""
    :show.sync="openGenerateUrlModal"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >

    <template slot="header">

      <h3 class="flex mb-8">Generate Signed HLS URL</h3>

    </template>

    <ioio-field
      type="text"
      topic="generateUrl"
      size="medium"
      label="IP address"
      placeholder="IP address"
      format="ip"
      v-model="signedUrlParams.ip" class="w-full mb-6"></ioio-field>

    <span class="flex mb-12 text-xs">The IP address that will access this HLS URL. If not entered the default IP address will be the requester's IP address</span>

    <ioio-field
      type="number"
      required
      topic="generateUrl"
      min="5"
      max="86400"
      size="medium"
      :hasClearBtn="false"
      label="Time in seconds"
      placeholder="Seconds"
      :maximum=86400
      :minimum=5
      v-model="signedUrlParams.seconds" class="w-full mb-6"></ioio-field>

    <span class="flex mb-8 text-xs">The amount of time in seconds between 5 and 86400 (24 hours) this URL can be accessed</span>


    <template slot="footer">
      <footer class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="danger"
          variant="tonal"
          class="mr-4"
          @click.stop.prevent="cancelGenerateUrl">Cancel</ioio-button>

        <ioio-button
          v-if="editedVod.hlsSignedUrl"
          class="mr-4 btn-green"
          type="primary"
          variant="solid"
          iconLeft="fa-solid fa-link"
          @click.stop.prevent="copyUrl(true)">Copy URL</ioio-button>

        <ioio-button
          topic="generateUrl"
          @click.stop.prevent="generateUrl">{{editedVod.hlsSignedUrl ? 'Generate New URL' : 'Generate URL'}}</ioio-button>

      </footer>
      <div class="relative min-h-36" v-else>
        <ioio-loader isShown />
      </div>
    </template>
  </ioio-modal>

  <ioio-modal
    externalClasses=""
    :show.sync="isConfirmEncodeCaptionsModalOpened"
    :clickOutside="false"
    noScroll
  >
    <template slot="header">
      <h3 class="">Before we proceed</h3>
    </template>

    <h5 class="mb-5 text-black-700 text-xs">
      This video has the following captions assigned to it. Before encoding, please review the list of:
    </h5>

    <div class="my-5 text-sm flex">

      <div class="w-1/2 mr-8">
        <h5 class="mb-5 text-black-600">
          Captions that would be encoded
        </h5>
        <div v-for="(ccLanguageGroup, lang, index) in editedVodCaptions" :key="index" class="mb-4">
          <h3 v-if="hasShouldEncodedCaptions(ccLanguageGroup)" class="text-sm uppercase text-black-900 border-b border-black-100 pb-2 mb-4">{{ languagesTranslateMap[lang] }}</h3>
          <div v-for="file in ccLanguageGroup" class="mb-5" :key="file.guid">
            <h4 v-if="file.meta.shouldEncode" class="text-sm text-black-400 break-normal">{{ file.meta.screenName || file.guid }}</h4>
          </div>
        </div>
      </div>

      <div class="w-1/2 pr-2">
        <h5 class="mb-5 text-black-600">
          Captions that wouldn't be encoded
        </h5>
        <div v-for="(ccLanguageGroup, lang, index) in editedVodCaptions" :key="index" class="mb-4">
          <h3 v-if="!hasShouldEncodedCaptions(ccLanguageGroup)" class="text-sm uppercase text-black-900 border-b border-black-100 pb-2 mb-6">{{ languagesTranslateMap[lang] }}</h3>
          <div v-for="file in ccLanguageGroup" class="mb-5" :key="file.guid">
            <h4 v-if="!file.meta.shouldEncode" class="text-sm text-black-400 break-normal">{{ file.meta.screenName || file.guid }}</h4>
          </div>
        </div>
      </div>

    </div>
    <h6 class="mb-5 text-xs">In case you want to make changes to the captions please visit the Subtitles tab.</h6>
    <h4> Are you sure you want to continue?</h4>

    <template slot="footer">

      <footer class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click="() => isConfirmEncodeCaptionsModalOpened = false"
        >Cancel</ioio-button>

        <ioio-button
          type="primary"
          @click="triggerEncodeFileRequest(editedVod),isConfirmEncodeCaptionsModalOpened = false"
        >Continue</ioio-button>
      </footer>
    </template>
  </ioio-modal>

  <div class="in-edit-mode-overlay" @click="onCancelEdit"></div>

  <section class="flex flex-column flex-auto z-1">

    <ioio-loader :isShown="areVodDetailsLoading || isEditInProgress" class="z-1" />

    <header class="flex justify-end items-center mb-2" v-if="!isVodPanelInEditMode">

      <ioio-button
        type="minimal"
        size="small"
        @click="closeEditVodPanel();setVodCaptions()">
        <ioio-icon
          icon="fa-solid fa-xmark"
        />
      </ioio-button>

    </header>
    <header class="flex justify-between items-center mb-6 mt-2" v-else>
      <h2 class="text-xl">Editing video</h2>
      <aside v-if="!isEditInProgress">

        <ioio-button
          type="secondary"
          variant="outline"
          size="small"
          @click="onCancelEdit"
          class="mr-2">
          Cancel
        </ioio-button>
        <ioio-button
          type="primary"
          size="small"
          @click="saveEditedVod">
          Save
        </ioio-button>
      </aside>
    </header>

    <section class="flex flex-column flex-auto edited-vod-info">

      <video-player-component
        :onVodLoadCompleteStatus="getVodThumbs"
        ref="videoPlayer"
        :previewVod="editedVod"
        :thumbnail="editedVodSelectedThumb"
        :vodSettings="vodSettings"
      />

      <header
        v-if="!isVodPanelInEditMode"
      >

        <h2 class="text-md text-black-700 mt-2 mb-2 truncate" style="minHeight: 20px">{{ editedVod.meta && editedVod.meta.title }}</h2>
        <p class="edit-vod-description text-sm text-black-500 mb-4 truncate" style="minHeight: 20px">{{ editedVod.meta && editedVod.meta.description || '--' }}</p>
      </header>

      <section
        v-if="!isVodPanelInEditMode"
        class="flex justify-between border-b border-black-100 pb-4 mb-2"
      >
        <aside class="flex">


          <ioio-dropdown-base
            alignX="right"
            :fullWidth="false"
            class="flex items-center justify-center mr-2"
          >
            <template slot="title">
              <ioio-button
                type="secondary"
                variant="outline"
                iconLeft="fa-solid fa-gear"
                class=""
                size="small">Manage
              </ioio-button>
            </template>

            <ioio-button
              v-for="vodAction in editedVodActions"
              :key="vodAction.label"
              class="dropdown__menu-item px-4 overflow-x-hidden w-48"
              :class="vodAction.class"
              type="minimal"
              @click.stop.prevent="() => actionHandler(vodAction.method, editedVod)"
            >{{ vodAction.label }}
            </ioio-button>

            <modal-vod-action-btn-component
              :vod="editedVod"
              openFlagName="isDownloadModalOpened"
              v-if="editedVod.status !== 'error'"
            >
              <ioio-button
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
              >Download
              </ioio-button>
            </modal-vod-action-btn-component>
            <delete-vod-component
              v-if="editedVod.status !== 'delete' && hasDeletePermission"
              :vod="editedVod"
            >
              <ioio-button
                class="border-top mt-2 dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
              >Delete
              </ioio-button>
            </delete-vod-component>
            <archive-vod-component
              v-if="hasArchivePermission"
              :vod="editedVod"
            >
              <ioio-button
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
              >{{ editedVod.status !== 'archive' ? 'Archive' : 'Unarchive' }}
              </ioio-button>
            </archive-vod-component>
          </ioio-dropdown-base>

          <ioio-dropdown-base
            v-if="editedVod.status != 'encode-later'"
            alignX="left"
            :fullWidth="false"
            class="flex items-center justify-center mr-2"
          >
            <template slot="title">
              <ioio-button
                type="secondary"
                variant="outline"
                iconLeft="fa-solid fa-link"
                class=""
                size="small">Copy URL
              </ioio-button>
            </template>

            <div>
              <ioio-button
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
                @click.stop.prevent="copyUrl(false)">{{vodSettings['signed-urls'] ? 'Generate Signed URL' : 'HLS URL'}}
              </ioio-button>
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="channel-url"
                :value="editedVod.hlsSignedUrl || editedVod.hlsUrl">
            </div>
            <div v-if="editedVod.mp4Outputs">
              <ioio-button
                v-for="(mp4, index) in editedVodMp4"
                :key="index"
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
                @click.stop.prevent="copyToClipboard(mp4.format)"
              >{{ mp4.format }}
                <input
                  class="copy-to-clipboard-input"
                  type="text"
                  :id="mp4.format"
                  :value="mp4.output">
              </ioio-button>
            </div>
            <div v-if="editedVod.dashUrl">
              <ioio-button
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
                @click.stop.prevent="copyToClipboard('dash-url')"
              >Dash URL
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="dash-url"
                :value="editedVod.dashUrl">
              </ioio-button>
            </div>

            <div>
              <ioio-button
                class="dropdown__menu-item px-4 overflow-x-hidden w-48"
                type="minimal"
                @click.stop.prevent="copyToClipboard('vod-preview-url')"
              >Share preview
              </ioio-button>
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="vod-preview-url"
                :value="editedVodPreviewUrl">
            </div>

          </ioio-dropdown-base>

          <ioio-button
            type="secondary"
            variant="outline"
            iconLeft="fa fa-pencil"
            class="mr-2"
            size="small"
            @click.stop.prevent="toggleIsVodPanelInEditMode()">Edit
          </ioio-button>

        </aside>


        <ioio-button
          v-if="(
            editedVod.status === 'encode-later' &&
            !encodingGuids[editedVodGuid] &&
            !editedVod.meta.captionsGuid
          )"
          type="primary"
          iconLeft="fa-solid fa-repeat"
          size="small"
          @click.stop.prevent="triggerEncodeFileRequest(editedVod)">Encode
        </ioio-button>


        <ioio-button
          v-else-if="(
            editedVod.status === 'complete' &&
            editedVod.meta.captionsGuid &&
            !encodingGuids[editedVodGuid] &&
            $refs.vodDetailsOverview &&
            $refs.vodDetailsOverview.activeTabName === 'Subtitles'
          ) || (
            editedVod.status === 'encode-later' &&
            !encodingGuids[editedVodGuid] &&
            editedVod.meta.captionsGuid
          )"
          type="primary"
          iconLeft="fa-solid fa-repeat"
          size="small"
          @click.stop.prevent="isConfirmEncodeCaptionsModalOpened = true">Encode
        </ioio-button>


        <ioio-button
          v-if="editedVod.status === 'encoding' || encodingGuids[editedVodGuid]"
          type="primary"
          disabled
          iconLeft="fa-duotone fa-loader"
          size="small">Encoding
        </ioio-button>
      </section>


      <library-vod-details-overview-component
        ref="vodDetailsOverview"
        v-if="editedVodGuid && !isVodPanelInEditMode"
        :editedVod="editedVod"
        :areVodDetailsLoading="areVodDetailsLoading"
      />

      <library-vod-details-edit-component
        ref="editVodForm"
        v-if="editedVodGuid && isVodPanelInEditMode"
        :editedVod="editedVod"
        :editedVodGuid="editedVodGuid"
        :editedVodSelectedThumb="editedVodSelectedThumb"
        :editedVodThumbs="editedVodThumbs"
        :editVodData="editVodData"
        @slugChanged="isSlugChanged = true"
        :save="saveEditedVod"
      />

    </section>
  </section>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { validateTabsFormAndHighlight } from '@utils/helpers';
import { languagesTranslateConfig } from "@utils/constants";

export default {
  data: () => ({
    areVodDetailsLoading: false,
    isEditInProgress: false,

    editedVodThumbs: [],

    pristineVodData: {},

    editedVodActions: [
      {

        label: 'Insights',
        method: 'toggleInsightsModalOpened'
      }, {

        label: 'Video clipper',
        method: 'toggleCropModalOpened'
      }
    ],
    openGenerateUrlModal: false,
    signedUrlParams:{
      ip: '',
      seconds: 86400
    },
    languagesTranslateMap: languagesTranslateConfig,
    isRequestPending: false,
    isSlugChanged: false,
    isConfirmEncodeCaptionsModalOpened: false
  }),
  props: {

    isVodPanelInEditMode: Boolean,
    newSelectedVod: Object,
    isEditVodPadelOpened: Boolean,
    closeEditVodPanel: Function,
    hasDeletePermission: Boolean,
    hasArchivePermission: Boolean,
    encodingGuids: Object,
    copyToClipboard: Function,
    toggleIsVodPanelInEditMode: Function,
    triggerEncodeFileRequest: Function,
    toggleInsightsModalOpened: Function,
    toggleCropModalOpened: Function,
    toggleDistributeModalOpened: Function
  },

  mounted() {

    window.re = this;
    if (this.availableDeployments.some(el => el.type === 'syndication')) {
      this.editedVodActions.push(
        {
          label: 'Distribute',
          method: 'toggleDistributeModalOpened'
        }
      )
    }
  },

  methods: {

    generateUrl() {

      const params = {

        guid: this.editedVodGuid,
        query: {

          seconds: this.signedUrlParams.seconds
        }
      };

      if (this.signedUrlParams.ip !== '') {

        params.query.ip = this.signedUrlParams.ip;
      };

      this.isRequestPending = true;

      this.getSignedHlsUrl(params)
        .then((resp) =>{

          const hlsSignedUrl = resp['signed-url'];

          const singleSrcData = {...this.editedVod};

          singleSrcData.hlsSignedUrl = hlsSignedUrl;

          this.editVodData(singleSrcData);
        })
        .finally(() =>{

          this.isRequestPending = false;
        })
    },

     cancelGenerateUrl() {

      this.openGenerateUrlModal = false;

      this.signedUrlParams.ip = '';
      this.signedUrlParams.seconds = 86400;
    },

    copyUrl(signed) {
      if (this.vodSettings['signed-urls'] && !signed) {

        this.openGenerateUrlModal = true;
      } else {

        this.copyToClipboard('channel-url');
        this.openGenerateUrlModal = false;
      }
    },

    actionHandler(method, vod) {

      this[method](vod);
    },

    onCancelEdit() {

      if (this.isActiveConfirmGuardSet && this.$refs.editVodForm && this.$refs.editVodForm.isFormDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.cancelEditVod();
          }
        });

        this.raiseRedirectFlag(true);

        return;
      }

      this.cancelEditVod();
    },

    cancelEditVod() {

      this.toggleIsVodPanelInEditMode();

      this.editVodData({

        ...this.pristineVodData
      });

      this.pristineVodData = {};
    },

    saveEditedVod() {

      if (validateTabsFormAndHighlight(this.$refs.editVodForm.$refs.tabForm, 'editVod')) {

        return;
      }

      const params = {

        ...this.editedVod.meta,
      };

      // NOTE: if origin and user are missing (old vods that don't have them set upon creation) the BE throws an error
      if (!params.user) {

        params.user = {};
      }

      if (!params.origin) {

        params.origin = {};
      }

      if (params.expires) {

        params.expires = new Date(params.expires).getTime();

      } else {

        delete params.expires;
      }

      const payload = {

        params,
        vodGuid: this.editedVodGuid
      };

      if (this.isSlugChanged && this.editedVod.slug) {

        this.isEditInProgress = true;

        const slugPayload = {
          params: {
            slug: this.editedVod.slug
          },
          vodGuid: this.editedVodGuid
        }
        this.makeEditVodSlugRequest(slugPayload)
        .then(() => {

          this.isSlugChanged = false;

          if (this.$refs.editVodForm.isFormChanged) {

            this.isEditInProgress = true;

            this.makeEditVodMetadataRequest(payload)
              .then(() => {

                this.$toasted.success("Your changes were saved successfully");

                this.toggleIsVodPanelInEditMode();
              })
              .finally(() => {

                this.isEditInProgress = false;
              })
          } else {
            this.$toasted.success("Your changes were saved successfully");

            this.toggleIsVodPanelInEditMode();
            this.isEditInProgress = false;
          }
        })
        .catch(errPayload => {

          const err = errPayload.error;

          if (typeof err === 'string') {

            this.$toasted.error(err);
          } else {

            for (let key in err) {

              const e = err[key];
              this.$toasted.error(e);
            }
          }
          this.isEditInProgress = false;
        })

      } else if (this.$refs.editVodForm.isFormChanged) {

        this.isEditInProgress = true;

        this.makeEditVodMetadataRequest(payload)
          .then(() => {

            this.$toasted.success("Your changes were saved successfully");

            this.toggleIsVodPanelInEditMode();
          })
          .finally(() => {

            this.isEditInProgress = false;
          });
      }
    },

    refreshEditedVodState() {

      this.editedVodThumbs = [];
    },

    getVodThumbs() {

      this.makeGetVodThumbsRequest(this.editedVodGuid).then(thumbs => {

        this.editedVodThumbs = thumbs;
      });
    },

    hasShouldEncodedCaptions(group) {

      for (const file in group) {
        if (group[file].meta.shouldEncode) {
          return true
        }
      }
      return false
    },

    ...mapMutations({

      editVodData: 'library/EDIT_VOD_DATA',
      setVodCaptions: 'library/SET_VOD_CAPTIONS',
      setRedirectGuard: 'app/SET_REDIRECT_GUARD',
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      getFullVodDetails: 'channelManager/makeGetVodDetailsRequest',
      makeGetVodThumbsRequest: 'channelManager/makeGetVodThumbsRequest',
      makeEditVodMetadataRequest: 'channelManager/makeEditVodMetadataRequest',
      makeEditVodSlugRequest: 'channelManager/makeEditVodSlugRequest',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
      getSignedHlsUrl: "channelManager/getSignedHlsUrl"
    })
  },
  computed: {
    ...mapGetters({

      editedVod: 'library/editedVod',
      editedVodGuid: 'library/editedVodGuid',
      editedVodCaptions: 'library/editedVodCaptions',
      shouldEncodeCaptions: 'library/shouldEncodeCaptions',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',
      availableDeployments: 'app/availableDeployments',
      vodSettings: 'channelManager/vodSettings',
      BASE_BROKER_URL: 'app/BASE_BROKER_URL',
    }),

    editedVodPreviewUrl() {

      return `${this.BASE_BROKER_URL.replace('/broker', '')}/vod-preview?id=${this.editedVod.guid}`
    },

    editedVodSelectedThumb() {

      return this.editedVod && this.editedVod.meta && this.editedVod.meta.imageUrl && `${this.editedVod.meta.imageUrl}?w=524&f=jpeg`;
    },
    editedVodMp4() {
      let mp4Outputs = [];
      if (this.editedVod.mp4Outputs) {

        this.editedVod.mp4Outputs.forEach(output =>{

          let splitFormat = output.replaceAll('.mp4','').split('_');
          let format = 'Mp4-'+ splitFormat[splitFormat.length-1];

          mp4Outputs.push({
            format: format,
            output: output
          })
        })
      }
      return mp4Outputs;
    }
  },

  watch: {

    isVodPanelInEditMode() {

      if (this.isVodPanelInEditMode) {

        this.pristineVodData = {

          ...this.editedVod,
          meta: {
            ...this.editedVod.meta
          }
        }

        this.setRedirectGuard({
          redirectMsg: 'Your changes are not saved.',
          redirectSecondaryMsg: 'Are you sure you want to cancel?'
        });

      } else {
        this.setRedirectGuard(false);
      }
    },

    newSelectedVod() {

      this.setVodCaptions();

      if (this.editedVod.guid) {

        this.areVodDetailsLoading = true;

        this.getFullVodDetails({ guid: this.editedVod.guid })
          .then((vodDetails) => {

            const hubs = vodDetails.meta.hubs || [];

            this.editVodData({

              ...vodDetails,
              meta: {
                ...vodDetails.meta,
                hubs
              }
            });
          })
          .catch(err => {

            this.$toasted.error('Unable to load vod details.');
          })
          .finally(() => {

            this.areVodDetailsLoading = false;
          });
      }

    },

    editedVodGuid(newVal, oldVal) {

      this.refreshEditedVodState();
    },
  }
}
</script>

<style lang="scss">

.library-side-view-single-vod-component {

  .btn-green{
    &.ds-btn.is-primary.is-solid{
      @apply
      bg-green-500;
    }
  }

  &.is-edit-mode-on {

    position: relative;

    .in-edit-mode-overlay {

      width: 100vw;
      height: 100%;
      background-color: rgba(53, 55, 65, var(--tw-bg-opacity));
      --tw-bg-opacity: 0.6;
      display: block;
      position: fixed;
      top: 0;
      right: 572px;
      z-index: 1;
    }
  }
}
</style>
