<template>
<ioio-tabs class="flex flex-auto flex-column library-vod-details-overview" @input="onTabChange">
  <ioio-tab
    name="Details"
    :selected="true"
    class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
  >

    <section class="flex justify-between mb-6 mt-6">
      <p>Video type</p>
      <p>{{ parseVodType(editedVod.meta.type) }}</p>
    </section>
    <section class="details-overview">
      <p class="mr-36">Slug</p>
      <p>{{ editedVod.slug || 'N/A' }}</p>
    </section>
    <section class="details-overview">
      <p>Status</p>
      <p>{{ editedVod.status }}</p>
    </section>
    <section class="details-overview">
      <p>Categories</p>
      <p class="flex flex-auto flex-wrap justify-end">
        <ioio-label
          :text="label"
          size="small"
          v-for="(label, index) in editedVod.meta.categories"
          :key="index"
          class="ml-2 mb-2 float-right"
        >
        </ioio-label>
        <span v-if="!editedVod.meta.categories || !editedVod.meta.categories.length">--</span>
      </p>
    </section>
    <section class="details-overview">
      <p>Group</p>

      <p class="flex flex-auto flex-wrap justify-end">
        <ioio-label
          v-if="editedVod.meta.group"
          :text="editedVod.meta.group"
          size="small"
          class="ml-2"
        >
        </ioio-label>
        <span v-else>--</span>
      </p>
    </section>
    <section class="details-overview">
      <p>Tags</p>
      <p class="flex flex-auto flex-wrap justify-end">
        <ioio-label
          :text="`#${label}`"
          size="small"
          v-for="(label, index) in editedVod.meta.tags"
          :key="index"
          class="ml-2"
          type="minimal"
        >
        </ioio-label>
        <span v-if="!editedVod.meta.tags || !editedVod.meta.tags.length">--</span>
      </p>
    </section>
    <section class="details-overview">
      <p>Genre</p>

      <p class="flex flex-auto flex-wrap justify-end">
        <ioio-label
          v-if="editedVod.meta.genre"
          :text="`#${editedVod.meta.genre}`"
          size="small"
          class="ml-2"
          type="minimal"
        >
        </ioio-label>
        <span v-else>--</span>
      </p>
    </section>
    <section class="details-overview">
      <p>Privacy</p>
      <p>{{ editedVod.meta.privacy ? 'Private' : 'Public' }}</p>
    </section>
    <section class="details-overview">
      <p>Featured</p>
      <p>{{ editedVod.meta.featured ? 'Yes' : 'No' }}</p>
    </section>
    <section class="details-overview">
      <p>Listed in Video Portals</p>
      <p>{{ editedVod.meta.listedInPortals ? 'Yes' : 'No' }}</p>
    </section>
    <section class="details-overview">
      <p>Expires at</p>
      <p>{{ (editedVod.meta.expires && new Date(editedVod.meta.expires).toLocaleString()) || '--' }}</p>
    </section>
    <section class="details-overview">
      <p>Audio</p>
      <ioio-button
        v-if="editedVod.audio"
          size="xxs"
          type="primary"
        >
          <a target="_blank" :href="editedVod.audio.downloadLink">Download</a>
      </ioio-button>
      <p v-else>N/A</p>
    </section>

  </ioio-tab>


  <ioio-tab name="Metadata" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

    <custom-meta-fields-component
      displayMode="showcase"
      :editedAsset="editedVod.meta"
      metaResourceType="video"
      secondaryNoMetaDisclaimer="You can add a metadata group in edit mode"
    />
  </ioio-tab>


  <ioio-tab name="Hubs" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

    <p v-for="hub in editedVod.meta.hubs" :key="hub.name" class="mb-4 text-sm">
      {{ hub.name }}
    </p>

    <p v-if="!editedVod.meta.hubs || !editedVod.meta.hubs.length" class="text-sm">No hubs added yet.</p>

  </ioio-tab>


  <ioio-tab name="Info" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">
    <section class="details-overview mt-6">

      <p>Created at</p>
      <p>{{ new Date(editedVod.createdAt).toLocaleString() }}</p>
    </section>
    <section class="details-overview">
      <p>Encoded at</p>
      <p>{{ editedVod.encodedAt ? new Date(editedVod.encodedAt).toLocaleString() : 'Not encoded' }}</p>
    </section>
    <section class="details-overview">
      <p>Uploaded by</p>
      <p>{{ editedVod.meta.user && editedVod.meta.user.name || '--' }}</p>
    </section>
    <section class="details-overview" v-if="editedVod.meta.hostEmail">
      <p>Imported by</p>
      <p>{{ editedVod.meta.hostEmail }}</p>
    </section>
    <section class="details-overview">
      <p>Source</p>
      <p>{{ editedVod.meta.origin && editedVod.meta.origin.source || '--' }}</p>
    </section>
    <section class="details-overview">
      <p>Media info</p>
      <ioio-button
        v-if="editedVod.mediaInfo"
        size="xxs"
        type="primary"
        @click.stop="toggleVodActionModalOpened({ flagName: 'isMediaInfoModalOpened', newVal: true }), formats = false"
      >
        Show
      </ioio-button>
      <p v-else>N/A</p>
    </section>
    <section class="details-overview" v-if="editedVod.status != 'encode-later'">
      <p>Encoded formats</p>
      <ioio-button
        size="xxs"
        type="primary"
        @click.stop="toggleVodActionModalOpened({ flagName: 'isMediaInfoModalOpened', newVal: true }), formats = true"
      >
        Show
      </ioio-button>
    </section>
    <media-info-modal-component v-if="editedVod.mediaInfo || formats" :formats="formats"/>
  </ioio-tab>

  <ioio-tab name="Subtitles" class="text-xs text-black-600 flex flex-auto flex-column pr-2">
    <vod-subtitles-tab-component
      :editedVod="editedVod"
      :isTabActive="activeTabName === 'Subtitles'"
      v-if="!areVodDetailsLoading"
    />
  </ioio-tab>

  <ioio-tab name="Cropped images" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

    <assigned-images-to-vod-component
      class="flex flex-column flex-auto"
      :stagedImages="editedVod.meta.croppedImages"
      :isEditable="false"
    />
  </ioio-tab>

</ioio-tabs>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { parseVodType } from "@utils/helpers";

export default {
  data: () => ({
    parseVodType,
    activeTabName: 'Details',
    formats: false
  }),
  props: {
    editedVod: Object,
    areVodDetailsLoading: Boolean
  },
  created() {
    window.w = this;
  },
  methods: {

    onTabChange(tabName) {

      this.activeTabName = tabName;
    },

    ...mapMutations({
      toggleVodActionModalOpened: "library/TOGGLE_ACTION_MODAL_OPENED",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({

    })
  },
}
</script>

<style lang="scss">

.library-vod-details-overview {
    .ds-tabs__nav{
      overflow: unset;
    }

  .details-overview {
    @apply
    flex
    justify-between
    mb-6;
  }
}

</style>
