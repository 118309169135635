/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data-download': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M6 10l6 7 6-7h-5V1h-2v9z"/><path pid="1" d="M22 21H2v-6H0v7a1 1 0 001 1h22a1 1 0 001-1v-7h-2v6z"/></g>'
  }
})
