const getIngestDistributors = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getIngestDistributors',
      baseUrlType: 'BASE_INGEST_URL'
    }, {
      root: true
    })
    .then((distributors) => {

      // !!!!!!!!Remove enabledDistributors when google application is approved!!!!!!!!!!!!!!!!!

      let enabledDistributors = distributors.filter(distributor => (distributor.guid != 'google-drive' || distributor.enabled))

      commit('SET_DISTRIBUTORS', enabledDistributors);
      commit('SET_ZOOM_INTEGRATION', false);
      commit('STORE_USER_GOOGLE_DRIVE_DATA', 'save');

      return distributors;
    });
};

const updateIngestDistributors = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateIngestDistributors',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const seedIngestDistributor = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'seedIngestDistributor',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const checkIngestDistributorStatus = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'checkIngestDistributorStatus',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const listIngestDistributorSources = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'listIngestDistributorSources',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const getIngestElastic = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getIngestElastic',
    baseUrlType: 'BASE_INGEST_URL',
    params
  }, {
    root: true
  });
}

const ingestDistributorsSources = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'ingestDistributorsSources',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const getGoogleDriveIoAuth = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getGoogleDriveIoAuth',
    baseUrlType: 'BASE_INGEST_URL',
    params
  }, {
    root: true
  });
};

const googleDriveRedirectGuid = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'googleDriveRedirectGuid',
    params
  }, {
    root: true
  });
};

const getGoogleEmail = ({ state, commit, dispatch }, params) => {

  const requestData = {
    type: 'getGoogleEmail',
    baseUrl: 'https://www.googleapis.com/oauth2/v2/userinfo'
  }

  return dispatch('app/makeExternalApiRequest', {
    requestData,
    params
  }, {
    root: true
  });
};

const getGoogleDriveFolders = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getGoogleDriveFolders',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const saveGoogleDriveFolders = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'saveGoogleDriveFolders',
      baseUrlType: 'BASE_INGEST_URL',
      payload
    }, {
      root: true
    });
};

const getIngestApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getIngestApiKeys',
    baseUrlType: 'BASE_INGEST_URL',
  }, {
    root: true
  });
}

const getIngestApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getIngestApiKey',
    baseUrlType: 'BASE_INGEST_URL',
    params
  }, {
    root: true
  });
}

const storeNewIngestApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewIngestApiKey',
    baseUrlType: 'BASE_INGEST_URL',
    payload
  }, {
    root: true
  });
}

const updateIngestApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateIngestApiKey',
    baseUrlType: 'BASE_INGEST_URL',
    payload
  }, {
    root: true
  });
}

const deleteIngestApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteIngestApiKey',
    baseUrlType: 'BASE_INGEST_URL',
    params
  }, {
    root: true
  });
}



export {
  getIngestDistributors,
  updateIngestDistributors,
  seedIngestDistributor,
  listIngestDistributorSources,
  checkIngestDistributorStatus,
  getIngestElastic,
  ingestDistributorsSources,
  getGoogleDriveIoAuth,
  getGoogleEmail,
  googleDriveRedirectGuid,
  getGoogleDriveFolders,
  saveGoogleDriveFolders,
  getIngestApiKeys,
  getIngestApiKey,
  storeNewIngestApiKey,
  updateIngestApiKey,
  deleteIngestApiKey,
}