<template>
  <section class="deployment-pipeline-component" v-show="show" :class="{shown: show}">
    <header class="flex px2 py1 pt2 mb1 justify-between border-bottom bg-gray+5">
      <span class="medium ratio1-6">ID</span>
      <span class="medium ratio1-6">Created At</span>
      <span class="medium ratio1-6">Finished At</span>
      <span class="medium ratio1-6">Duration</span>
      <span class="medium ratio1-6">Status</span>
      <span class="medium ratio1-6"></span>
    </header>

    <ul class="list-reset m0 rounded border" v-if="!arePipelinesLoading && deploymentPipelines.length">
      <li class="hover-bg-blue+5 size-1 border-bottom p2 org-tile flex justify-between deployment-tile" v-for="pipe in deploymentPipelines" :key="pipe.id">

        <span class="name mr1 ratio1-6">{{ pipe.id }}</span>
        <span class="name mr1 ratio1-6">{{ pipe.created_at }}</span>
        <span class="name mr1 ratio1-6">{{ pipe.finished_at || '--' }}</span>
        <span class="name mr1 ratio1-6">{{ pipe.duration ? parseDuration(pipe.duration) : '--' }}</span>
        <span class="name mr1 ratio1-6" :class="getColorBasedOnStatus(pipe.status)">{{ pipe.status }}</span>
        <aside class="ratio1-6 flex">
          <button-component variant="default" size="size-xs" class="ratio1-2 mr1" @click="openFullPipeDetails(pipe)">Details</button-component>
          <button-component variant="primary" size="size-xs" class="ratio1-2 mr1" @click="openPipeJobDetails(pipe)">Jobs</button-component>
        </aside>
      </li>
    </ul>

    <p v-else-if="!arePipelinesLoading && !gitlabTokenInStore">
      You should authenticate yourself first in order to see the pipelines.
    </p>

    <p v-else-if="!arePipelinesLoading && !deploymentPipelines.length"
    >There are no pipelines for this deploy.
    </p>

    <logo-loader-component v-else width="50px" height="50px" class="mt1" />
  </section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
      default: {}
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    arePipelinesLoading: false,
    deploymentPipelines: [],
  }),

  created() {
    if (this.gitlabTokenInStore) {
      this.loadPipelines();
    }
  },

  mounted() {
    window.d = this;
  },

  methods: {
    loadPipelines() {
      const params = {
        orgGuid: this.deployment.organizationGuid,
        deploymentGuid: this.deployment.guid,
      };

      this.arePipelinesLoading = true;

      this.getDeploymentPipelines(params).then(p => {
        p.sort((a, b) => b.id - a.id);
        // get latest 3 items
        this.deploymentPipelines = p.slice(0, 3);
      }).finally(() => {
        this.arePipelinesLoading = false;
      })
    },

    getColorBasedOnStatus(status) {
      let color = 'blue';

      switch (status) {
        case 'pending':
        case 'running':
          color = 'blue';
          break;
        case 'success':
          color = 'green';
          break;
        case 'failed':
          color = 'red';
          break;
      }

      return color;
    },

    parseDuration(s) {
      const calcedDurationInMS = s * 1000;

      return getHHMMSSFromMS(calcedDurationInMS);
    },

    openPipeJobDetails(pipe) {
      this.setSelectedPipelineJobs(pipe);
    },

    openFullPipeDetails(pipe) {
      this.setSelectedPipelineDetails(pipe);
    },

    ...mapMutations({
      setSelectedPipelineDetails: 'app/SET_SELECTED_PIPELINE_DETAILS',
      setSelectedPipelineJobs: 'app/SET_SELECTED_PIPELINE_JOBS',
    }),
    ...mapActions({
      getDeploymentPipelines: 'app/getDeploymentPipelines',
    })
  },
  computed: {
    ...mapGetters({
      msgBusMsgList: 'app/msgBusMsgList',
      gitlabTokenInStore: 'app/gitlabToken'
    })
  },

  watch: {
    gitlabTokenInStore() {
      if (this.gitlabTokenInStore) {
        this.loadPipelines();
      }
    },

    msgBusMsgList() {
      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type !== 'deployment_pipeline_status_change') return;

      if (newestMsg.data.itemType === 'deployment' &&
          newestMsg.data.deploymentGuid === this.deployment.guid &&
          newestMsg.data.organizationGuid === this.deployment.organizationGuid
      ) {
        this.loadPipelines();
      }
    }
  }
}
</script>

<style lang="scss">
.shown {
  border: 1px solid black;
  padding: 5px;
}
</style>
