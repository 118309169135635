/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '+10m': {
    width: 20,
    height: 8,
    viewBox: '0 0 20 8',
    data: '<path pid="0" d="M.81 3.913h1.701V2.122h.756v1.791h1.746v.648H3.267v1.8h-.756v-1.8H.81v-.648zM6.594 6.28h1.395V1.519l-1.54.477-.206-.531L8.196.592 8.835.7v5.58h1.25V7H6.595v-.72zm6.754.792c-.78 0-1.365-.288-1.755-.864-.384-.576-.576-1.377-.576-2.403s.192-1.824.576-2.394c.384-.57.969-.855 1.755-.855.786 0 1.37.285 1.755.855.384.57.576 1.368.576 2.394s-.195 1.827-.585 2.403c-.384.576-.966.864-1.746.864zm0-.72c.462 0 .816-.213 1.062-.639.246-.432.369-1.068.369-1.908 0-.834-.123-1.464-.37-1.89-.245-.426-.6-.639-1.061-.639-.456 0-.81.213-1.062.639-.246.426-.37 1.056-.37 1.89 0 .84.124 1.476.37 1.908.252.426.606.639 1.062.639zm3.623-.306c.27.114.5.198.693.252.198.054.402.081.612.081.27 0 .48-.057.63-.171.156-.114.234-.282.234-.504a.45.45 0 00-.126-.333.716.716 0 00-.27-.198 7.746 7.746 0 00-.55-.198 17.027 17.027 0 01-.476-.162 1.294 1.294 0 01-.64-.45c-.143-.204-.215-.456-.215-.756 0-.372.144-.684.432-.936.288-.252.71-.378 1.269-.378.234 0 .453.027.657.081.21.054.429.126.657.216l-.225.666a5.58 5.58 0 00-.558-.189 1.924 1.924 0 00-.531-.072c-.282 0-.495.057-.64.171a.513.513 0 00-.215.432c0 .168.057.294.17.378.115.078.298.162.55.252.066.018.126.039.18.063l.216.081c.252.084.453.168.603.252.156.084.288.213.396.387.108.168.162.393.162.675 0 .444-.16.789-.477 1.035-.312.24-.72.36-1.224.36-.294 0-.567-.03-.82-.09a5.377 5.377 0 01-.755-.261l.26-.684z" _fill="#000"/>'
  }
})
