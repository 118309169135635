<template>

  <ioio-field
    externalClasses="input-search"
    type="text"
    isSearch
    :size="size"
    :value="value"
    :placeholder="placeholder"
    :isBorderless="isBorderless"
    v-model="inputSearchStr"
    @onChange="handleInputSearch"
    :threshold="threshold"
    ref="searchField"
    ></ioio-field>

</template>

<script>

export default {

  data: () => ({

    inputSearchStr: ''
  }),

  props: {
    value: String,
    onSearchInput: Function,
    placeholder: {
      type: String,
      default: 'Search'
    },
    isBorderless: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium'
    },
    threshold: Number
  },

  mounted() {

    this.inputSearchStr = this.value || '';
  },

  methods: {

    handleInputSearch(str) {

      this.onSearchInput(str);
    },

    onExternalClear() {

      this.$refs.searchField.clear();
    }
  }
}
</script>

<style lang="scss">
.input-search .ds-fields__input.is-small {

  font-size: 12px !important;
}
</style>
