/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lines': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#fca311"><path pid="0" _fill="#002c65" d="M24 12l-5-4v3H1a1 1 0 100 2h18v3l5-4z"/><path pid="1" data-color="color-2" d="M12 18H1a1 1 0 100 2h11v3l5-4-5-4v3zM1 6h5v3l5-4-5-4v3H1a1 1 0 100 2z"/></g>'
  }
})
