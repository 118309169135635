const getDistributions = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getDistributions',
      baseUrlType: 'BASE_SYNDICATION_URL'
    }, {
      root: true
    })
    .then((distributors) => {

      console.log('distributors', distributors)

      return distributors;
    });
};

const updateDistribution = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateDistribution',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    });
};

const getSyndicationLists = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getSyndicationLists',
      baseUrlType: 'BASE_SYNDICATION_URL'
    }, {
      root: true
    })
    .then((syndicationLists) => {

      console.log('syndicationLists', syndicationLists)

      return syndicationLists;
    });
};

const storeSyndicationList = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'storeSyndicationList',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    })
    .then((response) => {

      const newListUrl = response.url;
      const listUrlAsArr = newListUrl.split('/')
      const listGuid = listUrlAsArr[listUrlAsArr.length - 1];

      return listGuid;
    });
};

const updateSyndicationList = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateSyndicationList',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    });
};

const deleteSyndicationList = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteSyndicationList',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    });
};

const executeSyndicationOnList = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'executeSyndicationOnList',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    });
};

const getSyndicationAssetsForVod = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getSyndicationAssetsForVod',
      baseUrlType: 'BASE_SYNDICATION_URL',
      params
    }, {
      root: true
    })
    .then((getSyndicationAssetsForVod) => {

      console.log('getSyndicationAssetsForVod', getSyndicationAssetsForVod)

      return getSyndicationAssetsForVod;
    });
};


const sendAssetToDistribution = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'sendAssetToDistribution',
      baseUrlType: 'BASE_SYNDICATION_URL',
      payload
    }, {
      root: true
    });
};

const getSyndicationApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getSyndicationApiKeys',
    baseUrlType: 'BASE_SYNDICATION_URL',
  }, {
    root: true
  });
}

const getSyndicationApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getSyndicationApiKey',
    baseUrlType: 'BASE_SYNDICATION_URL',
    params
  }, {
    root: true
  });
}

const storeNewSyndicationApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewSyndicationApiKey',
    baseUrlType: 'BASE_SYNDICATION_URL',
    payload
  }, {
    root: true
  });
}

const updateSyndicationApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateSyndicationApiKey',
    baseUrlType: 'BASE_SYNDICATION_URL',
    payload
  }, {
    root: true
  });
}

const deleteSyndicationApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteSyndicationApiKey',
    baseUrlType: 'BASE_SYNDICATION_URL',
    params
  }, {
    root: true
  });
}

export {
  getDistributions,
  updateDistribution,
  getSyndicationLists,
  storeSyndicationList,
  updateSyndicationList,
  deleteSyndicationList,
  executeSyndicationOnList,
  getSyndicationAssetsForVod,
  sendAssetToDistribution,
  getSyndicationApiKeys,
  getSyndicationApiKey,
  storeNewSyndicationApiKey,
  updateSyndicationApiKey,
  deleteSyndicationApiKey,
}