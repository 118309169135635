<template>
<section class="create-workflows-filter-modal-component" @click.stop>

  <ioio-button
    type="primary"
    variant="outline"
    size="small"
    class="text-center"
    style="text-align:center; border-radius: 0.25rem"
    @click.stop.prevent="openCreateFilterModal"
  >
    {{ dialogOpenBtnLabel }}
  </ioio-button>

  <modal
    name="create-workflows-filter-modal"
    classes="v--modal p-16 create-workflows-filter-modal flex flex-column"
    width="600px"
    height="700px"
    :clickToClose="false">

    <header class="flex justify-between p-4">
      <h1 class="mr-4 text-xl">Create Filter</h1>
      <button-component
        class="close-create-modal-btn"
        variant="minimal"
        shape="circle"
        @click.stop.prevent="closeCreateFilterModal">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
      </button-component>
    </header>

    <div class="flex flex-column items-center justify-center w-full p-4 my-12">
      <section class="flex w-full pb-6">

        <ioio-field
          type="text"
          required
          topic="newFilterForm"
          size="medium"
          label="Filter Label"
          placeholder="Filter Label"
          v-model="newFilterData.label"
          class="w-1/2 mr-4">
        </ioio-field>

        <ioio-dropdown
          class="w-1/2"
          size="medium"
          topic="newFilterForm"
          label="Statuses"
          v-model="selectedStatuses"
          :items="possibleFilterStatuses"
          :multiple="true"
          placeholder=""
          titleProp="label"
          valueProp="originalLabel"
          :limit=1
        />

      </section>

      <section class="flex w-full pb-6">


        <ioio-field
          topic="newFilterForm"
          label="Date"
          :disabled="isUseCurrentDateApplied"
          class="dates-picker w-1/2 mr-4"
          v-model="newFilterData.date"
          type="date"
          size="medium"
          hasClearBtn>
        </ioio-field>

        <ioio-checkbox
          topic="newFilterForm"
          class="w-1/2 mt-11"
          size="medium"
          v-model="isUseCurrentDateApplied"
          @input="(newVal) => toggleUseCurrentDate"
        >Always use current date
        </ioio-checkbox>
      </section>


      <section class="flex w-full pb-10">
        <ioio-dropdown
          class="w-1/2"
          topic="newFilterForm"
          label="Sort"
          required
          v-model="sortDirection"
          :items="[{ label: 'Relevance', value: 'RELEVANCE' },{ label: 'Newest', value: 'NEWEST' },{ label: 'Oldest', value: 'OLDEST' }]"
          placeholder=""
          titleProp="label"
          valueProp="value"
        />
      </section>



      <logo-loader-component width="50px" height="50px" v-if="isRequestPending"/>

      <footer v-else class="w-full my-8">
        <p class="mb-6 whitespace-normal" v-if="isNewFilterNameInUse">

          A filter with the name {{ this.newFilterData.label }} already exists. If you save, the settings of the filter will be overwritten.
        </p>

        <section class="flex justify-end">
          <div class="flex">
            <ioio-button
              type="secondary"
              variant="outline"
              class="mr-4"
              noScroll
              @click.stop.prevent="closeCreateFilterModal"
              style="border-radius: 0.25rem"
            >
              Cancel
            </ioio-button>
            <ioio-button
              topic="newFilterForm"
              @click.stop.prevent="createFilter"
              style="border-radius: 0.25rem">{{ createConfirmBtnLabel }}
            </ioio-button>
          </div>
        </section>
      </footer>
    </div>


  </modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { liveWorkflowsStatusMap } from "@utils/helpers";

export default {
  data: () => ({

    isRequestPending: false,

    sortDirection: 'RELEVANCE',
    isUseCurrentDateApplied: false,
    selectedStatuses: [],
    newFilterData: {
      label: '',
      statuses: [], // add from selectedStatuses on POST
      date: '',
    },

  }),
  props: {

    dialogOpenBtnLabel: {
      type: String,
      default: 'CreateFilter',
    },

    dialogOpenBtnSize: {
      type: String,
      default: 'size-xs',
    },

    dialogOpenBtnClass: {
      type: String
    },

    createConfirmSecondaryLabel: {

      type: String
    },

    createConfirmBtnLabel: {
      type: String,
      default: 'CreateFilter',
    },

    isCreateConfirmBtnVisible: {
      type: Boolean
    },

    onCreateFilterSuccess: {
      type: Function
    },

    getPredefinedFilterProps: {
      type: Function
    },

    currentFiltersList: {
      type: Array
    }
  },

  created() {
window.q = this;


  },

  methods: {

    toggleUseCurrentDate() {

      this.isUseCurrentDateApplied = !this.isUseCurrentDateApplied;
    },

    openCreateFilterModal() {

      const {
        selectedStatuses,
        filterDate,
        sortDirection
      } = this.getPredefinedFilterProps();

      this.sortDirection = sortDirection;

      this.selectedStatuses = selectedStatuses;

      this.newFilterData = {

        ...this.newFilterData,
        date: filterDate
      };

      this.$modal.show('create-workflows-filter-modal');
    },

    closeCreateFilterModal() {

      this.$modal.hide('create-workflows-filter-modal');
    },

    createFilter() {

      console.log(123, this.newFilterData)

      const payload = {

        label: this.newFilterData.label,
        sort: this.sortDirection
      };

      this.newFilterData.date && (payload.date = this.newFilterData.date.split('T')[0]);

      this.selectedStatuses.length &&
        (payload.statuses = this.selectedStatuses.map(item => item.originalLabel));

      this.isUseCurrentDateApplied && (payload.date = 'CURRENT_DATE');

      this.isRequestPending = true;

      console.log(payload)

      this.createWorkflowFilter(payload)
        .then(() => {

          // onSuccessCB - to refresh the filters list?
          this.onCreateFilterSuccess && this.onCreateFilterSuccess();

          this.$modal.hide('create-workflows-filter-modal');
        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },


    ...mapActions({

      createWorkflowFilter: 'live/createWorkflowFilter',
    }),
  },

  computed: {

    possibleFilterStatuses() {

      const keys = Object.keys(liveWorkflowsStatusMap);

      return keys.reduce((result, k) => {

        if (k !== 'DEFAULT') {


          result.push({
            ...liveWorkflowsStatusMap[k],
            originalLabel: k
          });
        }

        return result;
      }, []);
    },

    currentFiltersMap() {

      const map = {};

      this.currentFiltersList.forEach(f => {

        map[f.label.toLowerCase()] = true;
      });

      return map;
    },

    isNewFilterNameInUse() {

      const parsedNewFilterName = this.newFilterData.label.toLowerCase();

      return this.currentFiltersMap[parsedNewFilterName];
    }


  }
}
</script>

<style lang="scss">



.create-workflows-filter-modal-component {

  /* Needed so that it aligns with adjascent elements outside the component */
  .dialog-open-btn {

    float: left;
  }

  .create-workflows-filter-modal {

    height: auto !important;
    min-height: 600px;
  }

  .multiselect {

    width: auto;
  }

  .ds-dropdown__label {

    padding-bottom: 3px;
  }

  .ds-checkbox {

    padding: 0 !important;
    margin-bottom: 0 !important;
  }
}

</style>
