/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event-create': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M18 12a6 6 0 106 6 6 6 0 00-6-6zm3 7h-2v2h-2v-2h-2v-2h2v-2h2v2h2z"/><path pid="1" d="M23 3h-5V1a1 1 0 00-2 0v2H8V1a1 1 0 00-2 0v2H1a1 1 0 00-1 1v18a1 1 0 001 1h9v-2H2V7h20v4h2V4a1 1 0 00-1-1z"/></g>'
  }
})
