<template>
<aside class="library-filters-component flex">

  <ioio-dropdown-base
    alignX="right"
    :fullWidth="false"
    class="main-filter flex items-center justify-center"
    v-for="filter in mainFiltersConfig"
    :key="filter.label">
    <template slot="title">
      <div
        class="inline-flex items-center justify-center cursor-pointer pr-2 mr-4 text-black-400"
        :class="{ 'text-black-900': $data[filter.filterAppliedPropName].label !== filter.label }"
      >
        <ioio-icon :icon="filter.icon"></ioio-icon>
        <p class="mx-2">{{ $data[filter.filterAppliedPropName].label }}</p>
        <ioio-icon icon="fal-chevron-down"></ioio-icon>
      </div>
    </template>

    <ioio-button
      v-for="filterOption in $data[filter.filterOptionsConfigName]"
      :key="filterOption.label"
      class="dropdown__menu-item"
      type="minimal"
      @click="applyMainFilter(filter.applyMethod, filterOption)"
    >{{ filterOption.label }}
    </ioio-button>

  </ioio-dropdown-base>

  <ioio-dropdown
    class="multiselect__alldown dropdown-created-width mr-4"
    :class="{'multiselect__alldown__select': createdByFilterApplied.length }"
    fullWidth
    v-model="createdByFilterApplied"
    :items="aggs.createdBy"
    multiple
    placeholder="Created by"
    size="small"
    titleProp="label"
    valueProp="value"
    @input="(value) =>applyCustomFilter(value,'createdBy')"
    :limit="1"
    :allInline="false"
    :closeOnSelect="false"
    alignX="left"
    alignY="bottom"
    iconLeft="fa-regular fa-user"
    iconRight="fal-chevron-down"
    isBorderless
  />

  <ioio-dropdown
    class="multiselect__alldown dropdown-category-width mr-4"
    :class="{'multiselect__alldown__select': categoriesFilterApplied.length }"
    fullWidth
    v-model="categoriesFilterApplied"
    :items="aggs.categories"
    multiple
    placeholder="Category"
    size="small"
    titleProp="label"
    valueProp="value"
    @input="(value) =>applyCustomFilter(value,'categories')"
    :limit="1"
    :allInline="false"
    :closeOnSelect="false"
    iconLeft="fa fa-list-alt"
    iconRight="fal-chevron-down"
    isBorderless
  />

  <ioio-dropdown
    class="multiselect__alldown dropdown-tag-width mr-4"
    :class="{'multiselect__alldown__select': tagsFilterApplied.length }"
    fullWidth
    v-model="tagsFilterApplied"
    :items="aggs.tags"
    multiple
    placeholder="Tags"
    size="small"
    titleProp="label"
    valueProp="value"
    @input="(value) => applyCustomFilter(value,'tags')"
    :limit="1"
    :allInline="false"
    :closeOnSelect="false"
    iconLeft="fa-regular fa-tags"
    iconRight="fal-chevron-down"
    isBorderless
  />

</aside>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    mainFiltersConfig: [

      {
        label: 'Status',
        icon: 'fa-solid fa-filter',
        applyMethod: 'applyByStatusFilter',
        filterAppliedPropName: 'statusFilterApplied',
        filterOptionsConfigName: 'statusFiltersConfig'
      },
      {
        label: 'Source',
        icon: 'fa-regular fa-right-from-bracket',
        applyMethod: 'applyBySourceFilter',
        filterAppliedPropName: 'sourceFilterApplied',
        filterOptionsConfigName: 'sourceFiltersConfig'
      }
    ],



    /**
     * Status filter
     */
    statusFilterApplied: {
      value: [],
      label: 'Status'
    },

    statusFiltersConfig: [
      {
        value: ['complete'],
        label: 'Encoded'
      }, {
        value: ['encode-later'],
        label: 'Non-encoded'
      }, {
        value: ['encoding'],
        label: 'In progress'
      }, {
        value: ['error'],
        label: 'Failed'
      }, {
        value: '',
        label: 'All (No filter)'
      }
    ],

    /**
     * Source filter
     */
    sourceFilterApplied: {
      value: '',
      label: 'Source'
    },

    sourceFilterDefault: {
      value: '',
      label: 'Source'
    },

    sourceFiltersConfig: [
      {
        value: ['uploaded'],
        label: 'Uploaded'
      }, {
        value: '',
        label: 'All (No filter)'
      }
    ],

    /**
     * Created by filter
     */
    createdByFilterApplied: [],

    /**
     * Categories filter
     */
    categoriesFilterApplied: [],

    /**
     * Tags filter
     */
    tagsFilterApplied: [],

    appliedMainFiltersCount: 0,
    lastAppliedFilter:''

  }),
  props: {
    loadVodsElastic: Function,
    aggs: {
      type: Object,
      default: {}
    }
  },

  created() {
    window.q = this;

    this.resetFilters();

    this.setupSourceFilterOptions();
  },
  methods: {

    resetFilters() {

      this.sourceFilterApplied = {
        value: '',
        label: 'Source'
      };

      this.statusFilterApplied = {
        value: [],
        label: 'Status'
      };

      this.createdByFilterApplied = [];

      this.categoriesFilterApplied = [];

      this.tagsFilterApplied = [];

      this.appliedMainFiltersCount = 0;

      this.lastAppliedFilter = '';
    },

    setupSourceFilterOptions() {

      const dynamicSourceFilterOptions = [];
      const dynamicSourceFilterValues = [];

      this.distributors.forEach(d => {

        dynamicSourceFilterOptions.push({
          label: d.title,
          value: [d.guid]
        });

        dynamicSourceFilterValues.push(d.guid);
      });

      this.sourceFilterApplied.value = [

        ...this.sourceFilterDefault.value
      ];

      this.sourceFiltersConfig.splice(1, 0, ...dynamicSourceFilterOptions);
    },

    applyMainFilter(filterName, filterOption) {

      if (filterOption.value) {

        this.appliedMainFiltersCount++;
      } else {

        this.appliedMainFiltersCount > 0 && this.appliedMainFiltersCount--;
      }

      this[filterName](filterOption);
    },

    applyByStatusFilter(filter) {

      if (filter.value) {

        this.statusFilterApplied = filter;

      } else {

        this.statusFilterApplied = {
          value: [],
          label: 'Status'
        };
      }
      this.lastAppliedFilter = 'Status'
      this.loadVodsElastic();
    },

    applyBySourceFilter(filter) {

      if (filter.value) {

        this.sourceFilterApplied = filter;

      } else {

        this.sourceFilterApplied = {
          value: '',
          label: 'Source'
        };
      }
      this.lastAppliedFilter = 'Source';
      this.loadVodsElastic();
    },

    applyCustomFilter(filter, type) {

      if (filter.length) {

        this.appliedMainFiltersCount++;

        this.lastAppliedFilter = type;

      } else {

        this.appliedMainFiltersCount > 0 && this.appliedMainFiltersCount--;

        this.lastAppliedFilter = '';
      }

      this.loadVodsElastic();
    },

    applyChangedLabelCount(type) {
      const filter = type + 'FilterApplied';
      this[filter].forEach(app=>{
        let select = this.aggs[type].find(el => el.value === app.value)
        if (select) {
          app.label = select.label
        } else {
          app.label = app.value
        }
      })
    },


    gatherFilterData() {

      return {
        statusFilterApplied: this.statusFilterApplied,
        sourceFilterApplied: this.sourceFilterApplied,
        createdByFilterApplied: this.createdByFilterApplied,
        categoriesFilterApplied: this.categoriesFilterApplied,
        tagsFilterApplied: this.tagsFilterApplied
      };
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

    })
  },
  computed: {
    ...mapGetters({
      distributors: 'ingest/ingestDistributors',
      organizationSelected: "app/organizationSelected",
    }),
  },
  watch: {

    distributors() {

      if (this.distributors.length) {

        this.setupSourceFilterOptions();
      }
    }
  },
}
</script>

<style lang="scss">

.library-filters-component {

  .multiselect__tags {
    @apply
    text-black-400;
  }

  .multiselect__icon-left.is-small,
  .multiselect__icon-right.is-small {
    @apply
    text-black-400
    w-3.5
    h-3.5;

  }
  .multiselect__alldown__select {
    .multiselect__icon-left.is-small,
    .multiselect__icon-right.is-small {
      @apply
      text-black-900;
    }
  }
  .multiselect__icon-right.is-small {
    top: 24%;
  }
  .multiselect__placeholder,
  .multiselect__input{
    @apply
    mb-0;
  }
  .multiselect__tags-wrap.inline {
    @apply
    flex;
  }

  .multiselect__selected {

    max-width: 180px;
  }
}
</style>
