/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'small-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 17.414L8.586 16l4-4-4-4L10 6.586l4.707 4.707a.999.999 0 010 1.414L10 17.414z" _fill="#111"/>'
  }
})
