<template>
<div class="progress-component">

  <div class="progress-bar" style="text-align: center">

    <div class="bar" :style="{width: progress + '%'}" />
  </div>
  <div class="text" v-if='showText'>{{parseInt(progress)}}%</div>
</div>
</template>

<script>
export default {
  data: () => ({
  }),
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0
    },
    showText: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>


.progress-component {
  display: flex;
  align-items: center;

.progress-bar {

  @apply
  rounded-sm
  bg-black-50;

  height: 0.5rem;

  position: relative;
  display: block;
  border-radius: 2px;
  overflow: hidden;
  flex: auto;

  .bar {
    @apply
    bg-blue-600;
    height: 100%;
    left: 0;
    top: 0;
    width: 0%;
    position: absolute;
    transition: width 0.5s ease-out;
  }
&.small { height: .25rem}
}
.text {
font-size: .75rem;
font-weight: 500;
margin-left: .25rem;
}
}
</style>
