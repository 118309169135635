import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import moment from "moment-timezone";

const state = {
  possibleRegions: [],
  blueprintsList: [],
  selectedBlueprint: null,
  selectedBlueprintModel: {},
  selectedBlueprintMeta: {},

  workflowsList: [],
  workflowsListPaginationInfo: {},

  isWorkflowsListLoading: false,
  workflowsListPristine: [],
  isWorkflowsListFilteringApplied: false,

  selectedWorkflow: {
    blueprint: {}
  },
  selectedWorkflowChannelData: {},
  selectedWorkflowChannelAlerts: [],
  selectedWorkflowChannelAlertsHistory: [],
  selectedWorkflowChannelLogs: {},
  selectedWorkflowChannelHealth: null,
  latestChangedWorkflowId: null,

  areGeneralDetailsLoading: false,
  areChannelDetailsLoading: false,
  areAlertsCurrentlyLoading: false,
  areChannelLogsLoading: false,

  selectedWorkflowForPreview: {},
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
