import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app'
import loginModule from './modules/login'
import channelManagerModule from './modules/channelManager'
import playheadModule from './modules/playhead'
import liveModule from './modules/live'
import elementalLiveModule from './modules/elementalLive'
import filterAndPaginateModule from './modules/filterAndPaginate'
import imagesModule from './modules/images'
import syndicationModule from './modules/syndication'
import eventsModule from './modules/events'
import statisticsModule from './modules/statistics'
import ingestModule from './modules/ingest'
import libraryModule from './modules/library'



Vue.use(Vuex)

const store = new Vuex.Store({

  modules: {

    app: appModule,
    login: loginModule,
    channelManager: channelManagerModule,
    playhead: playheadModule,
    live: liveModule,
    elementalLive: elementalLiveModule,
    filterAndPaginate: filterAndPaginateModule,
    images: imagesModule,
    syndication: syndicationModule,
    events: eventsModule,
    statistics: statisticsModule,
    ingest: ingestModule,
    library: libraryModule
  }
});

export default store;
