/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reorder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#111"><path pid="0" d="M14 3h10v2H14zM14 10h10v2H14zM14 17h10v2H14z"/><path pid="1" data-color="color-2" d="M12 18l-5-4v2.91c-2.833-.479-5-2.943-5-5.91 0-3.309 2.691-6 6-6h3V3H8c-4.411 0-8 3.589-8 8 0 4.072 3.06 7.436 7 7.931V22l5-4z"/></g>'
  }
})
