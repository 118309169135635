<template>
<div class="thumb-selector-component w-full">

  <ul ref="frame" class="flex w-full pointer overflow-x-clip">
    <li
      v-for="(thumb, i) in thumbs"
      :key="i"
      @click="onSelect(thumb)"
      :style="{
        width: thumbWidth
      }"
      class="thumb-container"
      :class="thumb === selectedThumb ? 'selected' : ''"
    >
      <div
        :style="{
          backgroundImage: 'url(' + thumb + ')'
        }"
        class="thumb-image"
        :class="{ 'display-left': i > halfOfThumbs }">

        <div class="thumb-timestamp">{{ getThumbTimestamp(i) }}</div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    thumbWidth: '50px',
    thumbSpacing: 4
  }),
  mounted() {

    window.t = this;
    // NOTE: timeout is needed for the element to expand
    setTimeout(() => this.calcThumbWidth())
  },
  props: {
    thumbs: Array,
    selectedThumb: String,
    onSelect: Function,
  },
  methods: {

    calcThumbWidth() {

      this.thumbWidth =
        `${this.$refs.frame.offsetWidth  / this.thumbs.length}px`;
    },

    getThumbTimestamp(index) {

      return getHHMMSSFromMS(index * this.thumbSpacing * 1000);
    }
  },

  computed: {

    halfOfThumbs() {

      return this.thumbs.length / 2;
    }
  },

  watch: {

    thumbs() {

      this.calcThumbWidth();
    }
  }
}
</script>

<style lang="scss" scoped>



.thumb-selector-component {

  @apply
  bg-black-100
  border
  border-black-200
  rounded-sm;

  .thumb-container {
    display: inline-block;
    height: 30px;
    position: relative;

    .thumb-image {
      min-width: 80px;
      max-width: 80px;
      width: 80px;
      height: 45px;
      visibility: hidden;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: -50px;

      @apply
      shadow-xl;

      &.display-left {

        left: calc(100% - 80px);
      }
    }

    &:hover, &.selected {
      @apply
      bg-blue-600;
    }

    &:hover .thumb-image {
      visibility: visible;
    }
  }

  .thumb-timestamp {

    position: absolute;
    bottom: -60px;
    font-size: 8px;

    @apply
    text-black-600;
  }
}

</style>
