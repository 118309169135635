<template>
<div class="dashboard-my-hubs-component">
  <div class="hubs">

    <h2 class="hubs__title">My hubs{{availableHubs.length > 3 ? `(${availableHubs.length})` : '' }}</h2>

    <div v-if="availableHubs.length > 0 && areSiteBuilderAppsLoaded" :class="{'hubs__container': availableHubs.length > 3}">

      <div
        @click="openHubPage(hub)"
        class="hubs__el"
        v-for="hub in availableHubs"
        :key="hub.id"
      >

        <span
          class="hubs__el-thumb"
          :style="{ backgroundColor: hub.color }"
        >{{ hub.initials }}
        </span>

        <div v-if="hub.name.length < 32" class="hubs__el-name">
          <div>{{ hub.name }}</div>
          <ioio-label :text="hub.parentApp.name" size="small" class="mt-2" v-if="hub.isHubNameDuplicate"></ioio-label>
        </div>

        <ioio-tooltip
          v-else
          initialPostion="top-left"
          class="w-72"
          :text="hub.name"
        >

          <div class="hubs__el-name truncate">{{ hub.name }}</div>
        </ioio-tooltip>
      </div>

    </div>

    <div
      class="hubs__no-items"
      v-else-if="availableHubs.length == 0 && areSiteBuilderAppsLoaded"
    >
      <div class="hubs__thumb">
        <ioio-icon icon="fa-browser"/>
      </div>
      <p class="hubs__nodata">
        You have no hubs
      </p>
    </div>

    <div class="relative min-h-9" v-else>
      <ioio-loader isShown />
    </div>

  </div>
</div>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getNameInitials } from '@utils/helpers';
import  backgroundColors from "@configs/colorsThumbMyHubsDashboard";

export default {
  data: () => ({
    getNameInitials,
    randomBackgroundColors: backgroundColors
  }),

  methods: {

    openHubPage(hub) {

      window.open(hub.route);
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },

  computed: {
    ...mapGetters({
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
    }),

    availableHubs() {

      const siteBuilderHubs = [];

      const hubsNamesMap = { };

      let colorsLength = this.randomBackgroundColors.backgroundColors.length;

      this.siteBuilderApps.forEach((app, i) => {

        if (app.hasOwnProperty('hubs')) {

          app.hubs.forEach((h) => {

            const hubRandomColor =
              this.randomBackgroundColors.backgroundColors[ i - colorsLength * Math.floor(i / colorsLength)];

            if (!hubsNamesMap[h.name]) {

              hubsNamesMap[h.name] = [];
            }

            if (hubsNamesMap[h.name]) {

              hubsNamesMap[h.name].push(siteBuilderHubs.length);
            }

            siteBuilderHubs.push({
              name: h.name,
              initials: getNameInitials(h.name),
              route: `https://${app.hostnames[0]}/vh`,
              permission: '',
              icon: 'far-external-link',
              isExternalRedirect: true,
              color: hubRandomColor,
              parentApp: {
                name: app.name,
                id: app.id
              }
            });
          });
        }


      });

      const duplicateHubNames = Object.keys(hubsNamesMap);

      duplicateHubNames.forEach(name => {

        const affectedIndeces = hubsNamesMap[name];

        if (affectedIndeces.length < 2) {

          return;
        }

        affectedIndeces.forEach(index => {

          siteBuilderHubs[index].isHubNameDuplicate = true;
        });
      });

      return siteBuilderHubs;
    }
  },
}
</script>

<style lang="scss" scoped>
.hubs {
  @apply
  flex
  flex-col;

  &__title {
    @apply mt-7 mb-6 text-lg;
  }
  &__container {
    @apply h-52 overflow-auto;
  }

  &__el {
    @apply
    flex
    items-center
    cursor-pointer
    pt-2
    ml-4
    mb-6;

    &-thumb {
      @apply
      flex
      flex-shrink
      items-center
      justify-center
      min-w-9
      min-h-9
      mr-4
      text-lg
      bg-black-50
      text-white
      rounded-lg;
    }

    &-name {
      @apply
      flex-1
      text-black-700
      truncate;
    }
  }

  &__no-items {
    @apply
    flex
    items-center
    mb-5
    pb-4
    border-b
    border-black-100;
  }

  &__thumb {
    @apply
    flex
    justify-center
    items-center
    rounded-lg
    w-10
    h-10
    bg-black-50
    text-black-500
    text-lg;
  }

  &__nodata {
    @apply
    ml-4
    text-sm
    text-black-500;
  }
}

</style>
