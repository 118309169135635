<template>

<ioio-modal
  v-if="activeModalDivision"
  externalClasses="assign-images"
  :show.sync="activeModalDivision"
  size="large"
  class="assign-images"
  noScroll
>
  <template slot="header">
    <div class="assign-images__title">{{ addLabel }}</div>

    <div class="mt-5">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
        class=""
      />
    </div>
  </template>


  <section class="assign-images__content h-full flex flex-column flex-auto">

    <section class="assign-images__list" ref="libraryScrollArea" v-if="!isRequestPending && imagesList.length">

      <div class="items-center img-item overflow-hidden mb-2 rounded cursor-pointer"
        v-for="img in imagesList"
        @click="toggleAddImageToList(img._source, !addedGuidsMap[img._source.guid])"
        :key="img._source.guid"
        :class="{ 'selected': addedGuidsMap[img._source.guid] }"
        :style="{ background:`url(${img._source.cdnPath}?d=160x90&q=80&f=jpeg) no-repeat center center / contain` }">
        <div class="img-selected">
          Selected
        </div>
        <div class="img-meta w-full h-full text-xs px-4 py-2">
          <h6 class="img-title truncate mb-2">
            {{ img._source.meta && img._source.meta.title || img._source.originalFileName }}
          </h6>
          <div class="flex flex-column">
            <span class="">{{ img._source.width }}x{{ img._source.height }}</span>
            <span class="truncate">
              {{ Number(img._source.imageMeta && img._source.imageMeta.size / 1024 / 1024).toFixed(2) }} MB
            </span>
            <span class="">{{ img._source.format }}</span>
          </div>
        </div>
      </div>
    </section>
    <p
      class="assign-images__nodata m-12 text-center"
      v-else-if="!isRequestPending && !imagesList.length"
    >
      No images found
    </p>

    <div v-else class="loader">
      <ioio-loader isShown></ioio-loader>
    </div>

    <footer class="flex items-center justify-center mt-4">

      <pagination-component
        v-if="!isRequestPending && imagesList.length"
        class="flex-none"
        :page-selected="elasticOptions.currentPage"
        :click-callback="onPaginationClicked"
        :per-page-items="elasticOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

      <div class="flex items-center" v-if="!isRequestPending && !imagesList.length">
        <div class="mr-4 items-center text-black-500">
          To add new media, you need to upload it first in Library.
        </div>

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="navigate('/images-library')"
        >
          Go to Library
        </ioio-button>
      </div>

    </footer>

  </section>
  <template slot="footer">
    <footer
      class="flex justify-between mt-4"
      v-if="!isRequestPending && imagesList.length"
    >
      <div class="flex">
        <div class="mr-4 w-60 text-black-500">
          To add new media, you need to upload it first in Library.
        </div>

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="navigate('/images-library')"
        >
          Go to Library
        </ioio-button>
      </div>
      <div>
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelAssignImages"
        >
          Cancel
        </ioio-button>

        <ioio-button
          @click.stop.prevent="getCollectedImages"
        >
          {{ addLabel }}
        </ioio-button>
      </div>
    </footer>
    <div v-if="isRequestPending" class="mt-2 overflow-hidden">
      <ioio-loader isShown></ioio-loader>
    </div>
  </template>

</ioio-modal>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getSearchOptions } from './elasticSearchOptions';

export default {
  data: () => ({

    searchWord: '',
    filteredItemsCount: 0,
    imagesList: [],

    elasticOptions: {

      currentPage: 1,
      perPageItems: 20
    },

    isRequestPending: false,
    addedGuidsMap: {},
    addedGuidsImageMap: {},
    collectedImagesCount: 0,
  }),
  props: {

    raiseFlagName: String,
    getAssignedImages: Function,
    initialImages: Object,
    singleChoise: Boolean,
    width: String,
    height: String,
    addLabel: {
      type: String,
      default: 'Add Images'
    }
  },

  mounted() {
    window.asdq = this;
  },

  methods: {

    cancelAssignImages() {

      this.toggleAssignImagesModalFlag({

        division: this.raiseFlagName,
        newVal: false
      });
    },

    getCollectedImages() {

      const images = Object.keys(this.addedGuidsMap);

      const imagesFullData = this.addedGuidsImageMap;

      this.getAssignedImages(images, this.addedGuidsImageMap);
    },

    toggleAddImageToList(image, newVal) {

      if (newVal) {

        if (this.singleChoise) {

          this.addedGuidsMap = {};
          this.addedGuidsImageMap = {};
        }

        this.addedGuidsImageMap[image.guid] = {

          ...image
        };

        this.addedGuidsMap = {

          ...this.addedGuidsMap,
          [image.guid]: true
        };

        this.collectedImagesCount++;

      } else {

        delete this.addedGuidsImageMap[image.guid];

        const updatedGuids = {
          ...this.addedGuidsMap
        };

        delete updatedGuids[image.guid];

        this.addedGuidsMap = {

          ...updatedGuids
        };

        this.collectedImagesCount--;
      }
    },

    beforeOpen() {

      this.addedGuidsMap = {};
      this.addedGuidsImageMap = {};

      // check possible prev selected images
      for (let imageGuid in this.initialImages) {

        this.toggleAddImageToList(this.initialImages[imageGuid], true);
      }

      this.loadImagesElastic();
    },

    loadImagesElastic() {

      const searchOptions = getSearchOptions({

        searchWord: this.searchWord,
        elasticOptions: this.elasticOptions
      });

      this.isRequestPending = true;

      this.getImagesElastic(searchOptions)
        .then((response) => {

          this.filteredItemsCount = response.hits.total.value;

          this.imagesList = response.hits.hits;
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    onPaginationClicked(pageNum) {

      this.elasticOptions.currentPage = pageNum;

      this.loadImagesElastic();
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'imagesLibrary'
      });
    },

    handleInputSearch(input) {

      console.log('input srch', input)
      this.searchWord = input;

      this.loadImagesElastic();
    },

    navigate(link) {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            // Navigate to the requested state
            this.$pushRoute(link);
            this.cancelAssignImages();
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        // Navigate to the requested state
        this.$pushRoute(link);
        this.cancelAssignImages();
      }
    },
    ...mapMutations({

      toggleAssignImagesModalFlag: 'events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE',
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
      getImagesElastic: "images/getImagesElastic",
    })
  },
  computed: {
    ...mapGetters({

      isAssignImagesOpened: 'events/isAssignImagesOpened',
      isOverlayBgImagesOpened: 'events/isOverlayBgImagesOpened',
      isOverlayImagesOpened: 'events/isOverlayImagesOpened',
      isEventCoverImageModalOpened: 'events/isEventCoverImageModalOpened',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    activeModalDivision: {

      get() {

        return this[this.raiseFlagName];
      },

      set(newVal) {

        this.toggleAssignImagesModalFlag({

          division: this.raiseFlagName,
          newVal
        });
      }
    }
  },

  watch: {

    activeModalDivision() {

      if (this.activeModalDivision) {

        this.beforeOpen();
      }
    }
  }
}
</script>

<style lang="scss">

.assign-images {

  .ds-modal__dialog.is-large {
    max-width: 58.5rem;
  }
  &__content {
    @apply
    flex
    flex-auto
    flex-col
    py-4;

    .loader {
      @apply
      flex
      items-center
      flex-auto;
      height: 550px;
    }
  }
  &__list {
    @apply
    grid
    gap-4
    mb-8
    h-full
    overflow-auto;
    grid-template-columns: repeat(5, minmax(160px, 160px));

    .img-item {
      max-width: 160px;
      height: 90px;

      &.selected {
        @apply
        border-2
        border-blue-600;

        .img-selected {
          @apply
          flex
          items-center
          pl-2;
          background-color: rgba(21, 30, 51, 0.64);
        }
      }
      .img-selected {
        @apply
        text-sm
        text-white
        hidden
        h-1/3;
      }
    }
    .img-item .img-meta {
      display: none;
      background: #ffffffeb;
    }
    .img-item:hover {
      .img-selected {
        display: none;
      }
      .img-meta {
        display: block;
      }
    }
  }
  &__list-el {
    @apply
    flex
    items-center
    justify-between
    mt-5;
  }
  &__list-el-image {
    @apply
    w-36
    h-20
    mr-5
    rounded
    overflow-hidden
    object-cover
    border
    border-dark-blue-100;
  }
  &__list-el-text {
    @apply flex flex-col;

    span {
      @apply text-blue-grey-500;
    }
  }
}
</style>
