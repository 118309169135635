export const registrationsOptions = {
  chart: {
    type: 'bar',
    id: 'registrations',
    height: 400,
    stacked: true,
    toolbar: {
      show: false,
    }
  },

  legend: {
    position: 'top',
    horizontalAlign: 'left'
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    bar: {
      columnWidth: '45%'
    }
  },

  xaxis: {
    categories: [
      "Feb 19",
      "Feb 20",
      "Feb 21",
      "Feb 22",
      "Feb 23",
      "Feb 24",
      "Feb 25"
    ],

    labels: {
      rotate: 0
    },

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },

  },

  yaxis: {
    tickAmount: 4,
    min: 0,
    max: 100,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },

  },

  tooltip: {
    theme: "dark",
    shared: true,
    intersect:false
  },

  colors: ['#6179FF','#6FCF97']


}

export const liveEngagementOptions = {
  chart: {
    id: 'liveEngagement',
    height: 400,
    type: 'area',

    toolbar: {
      show: false,
    }
  },

  legend: {
    showForSingleSeries: true,
    position: 'top',
    horizontalAlign: 'left'
  },

  // These are the labels
  xaxis: {
    categories: [
      "1%",
      "25%",
      "50%",
      "75%",
      "100%"
    ],
    position: 'bottom',
    axisBorder: {
      show: false
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,
    },

    labels: {
      rotate: 0
    }
  },

  // labels on the left
  yaxis: {
    tickAmount: 4,
    min: 0,
    max: 400,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },

  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },



  stroke: {
    width: 2,
    curve: 'straight'
  },

  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    }
  },

  tooltip: {
    theme: "dark"
  },

  colors: ['#6179FF', '#6FCF97']

  // Options for labels on each bar

}