import { getReadableChannelStatus } from '@utils/helpers';

const SET_POSSIBLE_REGIONS = (state, regions) => {

  state.possibleRegions = regions;
};

const SET_BLUEPRINTS_LIST = (state, blueprintsList) => {

  state.blueprintsList = blueprintsList;
};

const SET_SELECTED_BLUEPRINT = (state, blueprint) => {

  state.selectedBlueprint = blueprint;
};

const SET_SELECTED_BLUEPRINT_MODEL = (state, blueprintModel) => {

    state.selectedBlueprintModel = blueprintModel;
};

const SET_SELECTED_BLUEPRINT_META = (state, blueprintMeta) => {

  state.selectedBlueprintMeta = blueprintMeta;
};

const SET_WORKFLOWS_LIST = (state, workflowsList) => {

  state.workflowsList = workflowsList;
};

const SET_WORKFLOWS_PAGINATION_INFO = (state, workflowsListPaginationInfo) => {

  state.workflowsListPaginationInfo = workflowsListPaginationInfo;
};

const SET_SELECTED_WORKFLOW = (state, workflow) => {

  state.selectedWorkflow = {
    ...workflow
  };
};

const SET_SELECTED_WORKFLOW_FOR_PREVIEW = (state, workflow) => {

  state.selectedWorkflowForPreview = {
    ...workflow
  };
};

const SET_LATEST_CHANGED_WORKFLOW_ID = (state, workflowId) => {

  state.latestChangedWorkflowId = workflowId;
};

const SET_SELECTED_WORKFLOW_CHANNEL_DATA = (state, data) => {

  state.selectedWorkflowChannelData = data;
};

const SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_DATA = (state, data) => {

  state.selectedWorkflowChannelAlerts = data;
};

const SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_HISTORY_DATA = (state, data) => {

  state.selectedWorkflowChannelAlertsHistory = data;
};

const SET_SELECTED_WORKFLOW_CHANNEL_LOGS_DATA = (state, data) => {

  state.selectedWorkflowChannelLogs = data;
};

const SET_SELECTED_WORKFLOW_CHANNEL_HEALTH_DATA = (state, data) => {

  state.selectedWorkflowChannelHealth = data.items;
};

const SET_WORKFLOWS_LIST_LOADING = (state, loading) => {

  state.isWorkflowsListLoading = loading;
};


export {

  SET_POSSIBLE_REGIONS,
  SET_BLUEPRINTS_LIST,
  SET_SELECTED_BLUEPRINT,
  SET_SELECTED_BLUEPRINT_MODEL,
  SET_SELECTED_BLUEPRINT_META,
  SET_WORKFLOWS_LIST,
  SET_WORKFLOWS_PAGINATION_INFO,
  SET_SELECTED_WORKFLOW,
  SET_SELECTED_WORKFLOW_CHANNEL_DATA,
  SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_DATA,
  SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_HISTORY_DATA,
  SET_SELECTED_WORKFLOW_CHANNEL_LOGS_DATA,
  SET_SELECTED_WORKFLOW_CHANNEL_HEALTH_DATA,
  SET_SELECTED_WORKFLOW_FOR_PREVIEW,
  SET_LATEST_CHANGED_WORKFLOW_ID,
  SET_WORKFLOWS_LIST_LOADING
}
