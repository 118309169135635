<template>
  <section class="timeline-component relative"
    :class="isSelectedChannelLocked() ? 'locked' : ''">
    <!-- controls -->
    <div class="flex items-center justify-between p1 absolute top-0 width+10 z4">
      <div class="flex items-center">
        <div class="btn-group bordered">
          <button-component
            class="size-xs"
            @click="setActivePeriod('prev')"
            :class="{'active': activePeriods.selected.label === 'prev'}"
          >
            <ioio-icon icon="fas-angle-left" class="w-3 h-3"/>
            <span>-24h</span>
          </button-component>

          <button-component
            class="size-xs"
            @click="setActivePeriod('current')"
            :class="{'active': activePeriods.selected.label === 'current'}"
          >
            <ioio-icon icon="fas-location-dot" class="w-4 h-4"/>
          </button-component>
          <button-component
            class="size-xs"
            @click="setActivePeriod('next')"
            :class="{'active': activePeriods.selected.label === 'next'}"
          ><span>+24h</span>
            <ioio-icon icon="fas-angle-right" class="w-3 h-3"/>
          </button-component>
        </div>
        <span class="ml2 size-3 gray-3">
          <b>Showing:</b>
          {{ activePeriods.selected.date }}
        </span>

      </div>
      <div class="flex items-center flex-none">
        <span class="flex-none size-4 mr1">Zoom</span>
        <vue-slider
          style="width:10rem"
          :lazy="true"
          @dragging="val => updateTimelineOverflowIndex(val)"
          :clickable="false"
          v-model="timelineOverflowIndex"
          :min="2"
          :max="15"
        ></vue-slider>
      </div>
    </div>
    <!-- timeline -->
    <ioio-icon icon="fas-gear" class="locked-icon"/>
    <div class="timeline flex flex-column flex-none overflow-x relative z3"
      :class="activePeriods.selected.className">
      <div class="playhead z3" :style="{ transform: this.playheadOffset, transitionDuration: playheadTransitionDuration }">
          <div class="playhead-dead-zone" :style="{width: playheadDeadZoneWidth }"></div>

      </div>

      <DraggableHolderComponent
        @drop="addListingToTimeline"
        :should-accept-drop="shouldAcceptDrop"
        :get-child-payload="getChildPayload"
        group-name="library-playlist"
        orientation="horizontal"
        class="cards flex h100 py1 z2 relative"
        style="padding-top:3rem"
        :shouldAnimateDrop="() => false"
        non-drag-area-selector=".service-element"
      >
        <DraggableComponent
          v-for="(listing, index) in visibleListings"
          :key="index"
          href="#"
          class="card relative overflow-hidden flex-none rounded-2 tester"
          :class="{ active: listing.start === selectedListing.start, 'empty service-element': listing.isServiceElement }"
          :style="{ width: calcListingTilePercentageWidth(listing) + '%'}"
        >
          <div
            class="relative z2 flex flex-column justify-between h100 p1"
            v-if="!listing.isServiceElement"
            @click="(е) => setSelectedListing(listing)"
          >
            <div class="flex items-center justify-between">

              <h4 class="card-title truncate m0 size-1">{{ listing.title }}</h4>
            </div>
            <div class="size-2">
              <span>{{ calcListingDuration(listing) }}</span>
            </div>
            <div  class="flex items-center ratio1-1">
                <div v-for="item in listing.schedules" class="tag flex-auto mr1" :class="item.type">

                </div>
            </div>
          </div>
          <div
            class="empty-placeholder relative z2 flex flex-column justify-between h100 p1"
            v-if="listing.isServiceElement"
          ></div>
          <div
            class="cardbg absolute top-0 left-0 width+10 h100 z1"
            :style="{ backgroundColor: listing.color }"
            v-if="!listing.isServiceElement"
          ></div>
        </DraggableComponent>
      </DraggableHolderComponent>
      <div class="timelinebg absolute h100 top-0 left-0 flex flex-none z1">
        <div
          class="timeslot h100 flex-none size-4 align-left flex flex-column justify-end border-left"
          :style="{ width: timespaceSectionWidth }"
          v-for="absoluteHour in totalMarkerCount"
          :key="absoluteHour"
        >
          <span class="size-4">{{ getTimespaceHourVisual(absoluteHour - 1) }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    name: "Timeline",
    dayInMs: 86400000,
    startHour: new Date().getHours(),
    dragOrigin: "timeline",
    cancelUpdatePlayhead: null,
    playheadOffset: 0,
    playheadUpdateTime: 0,
    defaultPlayheadUpdateTime: 10000,
    overflowIndexTimer: null
  }),
  props: {},
  created() {
    this.setTimelineSlots();
  },
  mounted() {
    window.t = this;
    console.log("mounted");

    this.updatePlayheadPosition();

    this.startPlayheadMoving();
  },

  beforeDestroy() {

    this.stopPlayheadMoving();
  },

  methods: {
    ...mapMutations({
      setSelectedListing: "channelManager/SET_SELECTED_LISTING",
      setTimelineSlots: "channelManager/SET_TIMELINE_SLOTS",
      setActivePeriod: "channelManager/SET_ACTIVE_PERIOD",
      updateTimelineOverflowIndex: "channelManager/UPDATE_TIMELINE_OVERFLOW_INDEX"
    }),
    ...mapActions({
      makeTriggerMultipleListingsUpdateRequest:
        "channelManager/makeTriggerMultipleListingsUpdateRequest",
      makeTriggerListingsSwapUpdateRequest:
        "channelManager/makeTriggerListingsSwapUpdateRequest"
    }),

    addListingToTimeline(dragData) {

      /**
       * Note: the isSelectedChannelLocked is checked on
       * shouldAcceptDrop, but additional check here is needed,
       * since if the user has dragged an item and is hovering
       * over the timeline at the time of LOCKING, the drop will
       * be accepted
       */
      if (this.isSelectedChannelLocked()) {

        return;
      }

      if (
        !dragData.payload ||
        (dragData.addedIndex === null && dragData.removedIndex === null)
      ) {
        return;
      }

      /**
        Check if the  user is adding an item in a disabled
        period (past time || in the buffer period)
       */
      const now = new Date().getTime();

      if (dragData.addedIndex !== null) {
        if (!this.visibleListings[dragData.addedIndex]) {

          return;
        }

        const timeThatWillBeWrittenOn = this.visibleListings[
          dragData.addedIndex
        ].start;

        if (timeThatWillBeWrittenOn < now + this.timelineWriteBufferPeriod) {

          this.$toasted.error('Adding a playlist to a protected zone in the timeline is not allowed.');
          return;
        }

        const lastAvailableTime = this.loadedListingsAndSchedulesBounds.visibleEndBound;

        const itemPlayload = dragData.payload.item;

        const itemTotalDuration = itemPlayload.srcDuration ||
          itemPlayload.end - itemPlayload.start;

console.log(8, itemTotalDuration, lastAvailableTime)
        if (timeThatWillBeWrittenOn + itemTotalDuration > lastAvailableTime) {

          this.$toasted.error('Your listing can not overflow outside the visible timeline.');
          return;
        }

        /**
         * The following check is a temporary workaround for
         * problems when a timeline's item oveflows the next period
         */
        if (this.activePeriods.selected.label === 'next') {

          let timeNeededToFit = itemTotalDuration;

          for (var i = dragData.addedIndex; i < this.visibleListings.length; i++) {

            const isCurrentItemTheMovedItem =
              this.visibleListings[i].absoluteIndex !== undefined &&
              this.visibleListings[i].absoluteIndex === itemPlayload.absoluteIndex;

            if (this.visibleListings[i].isServiceElement ||
              isCurrentItemTheMovedItem) {

              const currentElementDuration =
                this.visibleListings[i].end - this.visibleListings[i].start;

              timeNeededToFit -= currentElementDuration;
            }

            if (timeNeededToFit <= 0) {

              break;
            }
          }

          if (timeNeededToFit > 0) {

console.log(9, itemTotalDuration, timeNeededToFit)
            this.$toasted.error('Your listing can not overflow outside the visible timeline.');

            return;
          }
        }
      }

      /**
       * removedIndex suggests, that an item is being moved from one
       * place of the timeline to another.
       */
      if (dragData.removedIndex !== null) {
        const timeDraggedListingBegins = this.visibleListings[
          dragData.removedIndex
        ].start;

        if (timeDraggedListingBegins < now + this.timelineWriteBufferPeriod) {

          this.$toasted.error('Adding a playlist to a protected zone in the timeline is not allowed.');
          return;
        }
      }

      if (dragData.payload.item.status === 'error') {

        this.$toasted.error('A video with status `Error` can not be used to create a listing.');

        return;
      }

      if (dragData.payload.item.status === 'encode-later') {

        this.$toasted.error('A video with status `Encode Later` can not be used to create a listing.');
        return;
      }

      if (dragData.payload.item.status === 'archive') {

        this.$toasted.error('A video with status `Archive` can not be used to create a listing.');
        return;
      }

      this.$toasted.info('Playlist added to timeline. Click on Save changes to keep playlist.');

      /**
       * The drop handler is triggered from a swap
       */
      if (dragData.removedIndex !== null) {
        this.makeTriggerListingsSwapUpdateRequest(dragData);

        return;
      }

      this.makeTriggerMultipleListingsUpdateRequest(dragData);
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {

      if (!this.isSelectedChannelLocked() && payload &&
        (payload.origin === "library" ||
        payload.origin === "timeline" ||
        payload.origin === 'live-tab')) {

        return true;
      }

      return false;
    },

    calcListingDuration(listing) {

      let listingDurationInMs = listing.end - listing.start;

      if (listing.overflowingData) {
        const {
          thisPeriodPartInMs,
          otherPeriodPartInMs
        } = listing.overflowingData;

        listingDurationInMs = thisPeriodPartInMs + otherPeriodPartInMs;
      }

      return getHHMMSSFromMS(listingDurationInMs);
    },

    calcListingTilePercentageWidth(listing) {
      // const listingDurationInMs = listing.end - listing.start;

      let listingDurationInMs = listing.end - listing.start;

      if (listing.overflowingData) {
        const { adjustedStart, adjustedEnd } = listing.overflowingData;

        listingDurationInMs = adjustedEnd - adjustedStart;
      }

      // TODO: this.dayInMs will be period (10min, 1week, etc.)
      const timeNotFilledInPeriod =
        this.dayInMs - this.selectedPeriodTotalListingsDuration;

      /**
       * Calc the percentage for a given tile, taking into account:
       *
       * - it's duration
       * - the duration of the dayInMs (in the future this should be the selected timePeriod, and not straight dayInMs)
       * - the time left from the day, that is not yet filled with listing
       */

      return (
        ((listingDurationInMs * 100) / this.dayInMs) *
        this.timelineOverflowIndex
      );
    },

    getTimespaceHourVisual(markerIndex) {

      let markerDistanceFromHourInMins = 0;
      let markerDistanceFromHourRest = markerIndex % this.perHourMarkerCount;

      if (markerDistanceFromHourRest !== 0) {

        markerDistanceFromHourInMins = 60 * markerDistanceFromHourRest / this.perHourMarkerCount;
      }

      const hourIndex = Math.floor(markerIndex / this.perHourMarkerCount);

      const hourDeviation = this.startHour + hourIndex;

      let parsedHour = hourDeviation < 24
        ? hourDeviation
        : Math.abs(24 - this.startHour - hourIndex);

      let parsedHourUS =
        parsedHour > 12 ? parsedHour - 12 :  parsedHour;

      if (parsedHourUS === 0) {

        parsedHourUS = 12;
      }

      const hourSigniture = parsedHour > 11 ? 'PM' : 'AM';

      if (markerDistanceFromHourInMins) {

        return `${parsedHourUS}:${markerDistanceFromHourInMins}${hourSigniture}`;
      }

      return `${parsedHourUS}${hourSigniture}`;
    },

    getChildPayload(removedIndex) {
      const payload = {
        item: this.visibleListings[removedIndex],
        origin: this.dragOrigin,
        removedIndex
      };

      if (payload.item.isServiceElement) {
        return null;
      }

      return payload;
    },

    calcPlayheadOffsetLeft() {
      const startHourStamp = new Date().setHours(this.startHour, 0, 0, 0);

      const endHourStamp = startHourStamp + this.dayInMs;

      const visiblePeriodInMs = endHourStamp - startHourStamp;

      const now = new Date().getTime();

      const nowAdjusted = now + this.streamToNowOffset;

      const playheadToStartHourOffset = nowAdjusted - startHourStamp;

      const playheadOffset =
        100 *
        (playheadToStartHourOffset /
          (visiblePeriodInMs / this.timelineOverflowIndex));

      this.playheadOffset = `translate3d(${playheadOffset}%, 0, 0)`;
    },

    setPlayheadUpdateTime(newVal) {
      this.playheadUpdateTime =
        newVal !== undefined ? newVal : this.defaultPlayheadUpdateTime;
    },

    updatePlayheadPosition() {

      this.stopPlayheadMoving();

      this.setPlayheadUpdateTime(400);

      this.calcPlayheadOffsetLeft();

      setTimeout(() => {

        this.setPlayheadUpdateTime(this.defaultPlayheadUpdateTime);

      }, 100);

      this.startPlayheadMoving();
    },

    startPlayheadMoving() {

      this.cancelUpdatePlayhead = setInterval(() => {

        this.calcPlayheadOffsetLeft();

      }, this.defaultPlayheadUpdateTime);
    },

    stopPlayheadMoving() {

      clearInterval(this.cancelUpdatePlayhead);
    },

    isSelectedChannelLocked() {

      return this.lockedChannelsGuids[this.channelsMeta.selected.guid];
    }
  },


  watch: {
    timelineOverflowIndex() {

      /**
       * Prevent the recalculation until the drag on the slider has finished
       */
      clearTimeout(this.overflowIndexTimer);

      this.overflowIndexTimer = setTimeout(() => {

        this.updatePlayheadPosition();

      }, 100)
    },

    streamToNowOffset() {

      this.updatePlayheadPosition();
    }
  },
  computed: {
    ...mapGetters({
      listingsTimespacesSelected: "channelManager/listingsTimespacesSelected",
      listingsTimespacesSelectedMSRepresentation: "channelManager/listingsTimespacesSelectedMSRepresentation",
      perHourMarkerCount: "channelManager/perHourMarkerCount",
      selectedChannelListingsAbsolute:
        "channelManager/selectedChannelListingsAbsolute",
      selectedChannelListingsEntire: "channelManager/selectedChannelListingsEntire",
      selectedChannelListingsNext: "channelManager/selectedChannelListingsNext",
      selectedChannelListingsPrev: "channelManager/selectedChannelListingsPrev",
      selectedChannelListingsCurrent:
        "channelManager/selectedChannelListingsCurrent",
      selectedListing: "channelManager/selectedListing",
      selectedPeriodTotalListingsDuration:
        "channelManager/selectedPeriodTotalListingsDuration",

      activePeriods: "channelManager/activePeriods",
      timelineOverflowIndex: "channelManager/timelineOverflowIndex",
      timelineSlots: "channelManager/timelineSlots",
      timelineWriteBufferPeriod: "channelManager/timelineWriteBufferPeriod",

      streamToNowOffset: "playhead/streamToNowOffset",
      channelsMeta: "channelManager/channelsMeta",
      lockedChannelsGuids: "channelManager/lockedChannelsGuids",
      loadedListingsAndSchedulesBounds: "channelManager/loadedListingsAndSchedulesBounds",
    }),

    visibleListings() {
      return this[this.activePeriods.selected.listingType];
    },

    timespaceSectionWidth() {
      return `${(100 / this.totalMarkerCount) *
        this.timelineOverflowIndex}vw`;
    },

    playheadDeadZoneWidth() {

      const burrefToTimespaceSelectedRatio =
        this.listingsTimespacesSelectedMSRepresentation / this.timelineWriteBufferPeriod;

      return `${(100 /
        this.listingsTimespacesSelected /
        burrefToTimespaceSelectedRatio) *
        this.timelineOverflowIndex}vw`;
    },

    playheadTransitionDuration() {
      return `${this.playheadUpdateTime / 1000}s`;
    },

    totalMarkerCount() {

      return this.listingsTimespacesSelected * this.perHourMarkerCount;
    }
  }
};
</script>

<style lang="scss" scoped>


/* timeline related styles */
.timeline-component {

  .smooth-dnd-container.horizontal {
    display: flex;
    white-space: nowrap;
  }

  .locked-icon {

    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 48px;
    right: calc(50% - 25px);
    z-index: 4;
  }

  &.locked {

    .timeline {

      opacity: 0.5;
    }

    .locked-icon {

      display: block;
    }
  }
}

.timeline {
  height: 9rem;
}


.period-next,
.period-prev {
  .playhead, .playhead-dead-zone {

    display: none;
  }
}

.period-current {
  .playhead, .playhead-dead-zone {

    display: block;
  }
}

.playhead {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: transform 0s linear; // the transition-duration is comming from this.playheadTransitionDuration

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: 100%;
    background-color: var(--color-red-400);
  }
}

.playhead-dead-zone {
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  opacity: 0.3;
  transition: transform 0s linear;
  background: repeating-linear-gradient(
    -45deg,
    var(--color-red-600),
    var(--color-red-600) 8px,
    transparent 8px,
    transparent 16px
  );
}

.cards {
  height: calc(100% - 0.75rem);
}
.card {
  height: 100%;
  color: white;

  &.smooth-dnd-draggable-wrapper {
    white-space: nowrap;
  }
}

.card:hover {
  z-index: 99;
  box-shadow: 0px 0px 0px 1px var(--color-white),
    0px 0px 0px 2px rgba(0, 0, 0, 0.125), 0px 16px 32px rgba(0, 0, 0, 0.35);
}

.card:not(.empty) {
  cursor: pointer;
}

.cardbg {
  opacity: 0.75;
}

.card.active .cardbg,
.card:hover .cardbg {
  opacity: 1;
}

.card.active {
  box-shadow: 0px 0px 0px 2px var(--color-white),
    0px 0px 0px 4px rgba(0, 0, 0, 0.125), 0px 8px 16px rgba(0, 0, 0, 0.25);
  z-index: 9;
}

.card.empty {

  background: rgba(255, 255, 255, 0.5);
  border: 1px dashed var(--sceneBrColor);
  border-width: 1px 0;
  border-radius: 0;
}
.card.empty:hover {
  background: rgba(255, 255, 255, 0.75);
  opacity: 1;
  box-shadow: none;
}

.tag {
  height: 0.25rem;
  margin-right: 1px;
  background: white;
  transition: all 0.125s ease-out;
  opacity: 0.7;
}

.ad {
  background: yellow;
  max-width: 1rem;
}

.card:hover .tag {
  height: 0.75rem;
}

.timeslot {
  position: relative;
  border-color: var(--sceneBrColor);
  /* NOTE: removed, since it was discussed, that it may not
  be neccesary with the new dynamic timeline indicators

  background: linear-gradient(
    to right,
    transparent,
    transparent calc(calc(100% / 6) - 1px),
    var(--sceneBrColor) calc(100% / 6),
    transparent calc(calc(100% / 6) + 1px),
    transparent calc(calc(100% / 6 * 2) - 1px),
    var(--sceneBrColor) calc(100% / 6 * 2),
    transparent calc(calc(100% / 6 * 2) + 1px),
    transparent calc(calc(100% / 6 * 3) - 1px),
    var(--sceneBrColor) calc(100% / 6 * 3),
    transparent calc(calc(100% / 6 * 3) + 1px),
    transparent calc(calc(100% / 6 * 4) - 1px),
    var(--sceneBrColor) calc(100% / 6 * 4),
    transparent calc(calc(100% / 6 * 4) + 1px),
    transparent calc(calc(100% / 6 * 5) - 1px),
    var(--sceneBrColor) calc(100% / 6 * 5),
    transparent calc(calc(100% / 6 * 5) + 1px),
    transparent 100%
  );
  */
}

.timeslot span {
  padding: 0.125rem 0.25rem 0;
  line-height: 1.5;
}
.timeslot:not(:last-of-type):before {
  content: "";
  width: 6px;
  position: absolute;
  bottom: 0;
  right: -3px;
  height: 4px;
  background: var(--sceneBrColor);
  z-index: 3;
}

// .timeslot:nth-child(3n) span {
//   background: red;
// }

.timelinebg {
  background: var(--sceneBgColor);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05) inset;
}
</style>
