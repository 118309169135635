<template>
<div class="channel-subs-lang-selector-component">

  <header class="flex mb2">
    <h3 class="size-1 m0">Supported CC and Subtitles Languages</h3>
    <button-component
      id="lang"
      class="ml1 flex-none"
      size="size-xs"
      type="minimal"
      @click.stop.prevent=""
      v-tooltip="'add language'"
      >
      <ioio-icon
        icon="fas-circle-plus"
        class="h-3.5 text-green-600"
      />
    </button-component>
  </header>

  <section v-for="(subData, index) in parsedSubsData" :key="index" class="flex">

    <label for="subsLang" :class="{ 'size-3': small }">
      <span class="pb1 block">Language <span>*</span></span>

      <select

        v-model="parsedSubsData[index].lang"
        @change="changeAvailableLanguages($event, index)"
        name="subsLang"
        class="select size-s" :style="small ? 'minWidth: 70px' : ''"
      >
        <option
          :key="'langOption-' + item"
          :disabled="inUseLangs[item] !== false && inUseLangs[item] !== undefined"
          :value="item"
          v-for="item in availableLanguages"
        >{{ item }}</option>
      </select>

    </label>

    <validate class="ml1" tag="label" :class="{ 'size-3': small }">
      <span class="pb1 block">Subtitles Name</span>
      <input
        type="text"
        placeholder="Name"
        v-model="parsedSubsData[index].name"
        class="input size-s"
        :name="'subsName' + index"/>
      <field-messages :name="'subsName' + index" show="$submitted || $dirty && $touched">
      </field-messages>
    </validate>

    <check-component
      type="checkbox"
      label="Autoselect"
      :class="small ? 'size-3' : 'ml2'"
      :val="parsedSubsData[index].autoselect"
      :on-change="() => toggleAutoselectForEntry(index)"/>

    <check-component
      type="checkbox"
      label="Forced"
      :class="small ? 'size-3' : 'ml2'"
      :val="parsedSubsData[index].forced"
      :on-change="() => toggleForcedForEntry(index)"/>

    <check-component
      type="checkbox"
      label="Default"
      :class="small ? 'size-3' : 'ml2'"
      :val="parsedSubsData[index].isDefault"
      :on-change="() => toggleIsDefaultForEntry(index)"/>

    <button-component
      class="mt2 flex-none"
      :class="small ? 'ml1' : 'ml2'"
      size="size-s"
      variant="minimal"
      @click.stop.prevent="removeRow(index)"
      v-tooltip="'remove language'"
      >
      <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>

    </button-component>
  </section>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    // language codes
    availableLanguages: ['ENG', 'SPA', 'FRA', 'DEU', 'GER', 'ZHO', 'ARA', 'HIN', 'JPN', 'RUS',
      'POR', 'ITA', 'URD', 'VIE', 'KOR', 'PAN', 'ABK', 'AAR', 'AFR', 'AKA', 'SQI', 'AMH',
      'ARG', 'HYE', 'ASM', 'AVA', 'AVE', 'AYM', 'AZE', 'BAM', 'BAK', 'EUS', 'BEL', 'BEN',
      'BIH', 'BIS', 'BOS', 'BRE', 'BUL', 'MYA', 'CAT', 'KHM', 'CHA', 'CHE', 'NYA', 'CHU', 'CHV', 'COR',
      'COS', 'CRE', 'HRV', 'CES', 'DAN', 'DIV', 'NLD', 'DZO', 'ENM', 'EPO', 'EST', 'EWE', 'FAO', 'FIJ',
      'FIN', 'FRM', 'FUL', 'GLA', 'GLG', 'LUG', 'KAT', 'ELL', 'GRN', 'GUJ', 'HAT', 'HAU', 'HEB', 'HER',
      'HMO', 'HUN', 'ISL', 'IDO', 'IBO', 'IND', 'INA', 'ILE', 'IKU', 'IPK', 'GLE', 'JAV', 'KAL', 'KAN',
      'KAU', 'KAS', 'KAZ', 'KIK', 'KIN', 'KIR', 'KOM', 'KON', 'KUA', 'KUR', 'LAO', 'LAT', 'LAV', 'LIM',
      'LIN', 'LIT', 'LUB', 'LTZ', 'MKD', 'MLG', 'MSA', 'MAL', 'MLT', 'GLV', 'MRI', 'MAR', 'MAH', 'MON',
      'NAU', 'NAV', 'NDE', 'NBL', 'NDO', 'NEP', 'SME', 'NOR', 'NOB', 'NNO', 'OCI', 'OJI', 'ORI', 'ORM',
      'OSS', 'PLI', 'FAS', 'POL', 'PUS', 'QUE', 'QAA', 'RON', 'ROH', 'RUN', 'SMO', 'SAG', 'SAN', 'SRD',
      'SRB', 'SNA', 'III', 'SND', 'SIN', 'SLK', 'SLV', 'SOM', 'SOT', 'SUN', 'SWA', 'SSW', 'SWE', 'TGL',
      'TAH', 'TGK', 'TAM', 'TAT', 'TEL', 'THA', 'BOD', 'TIR', 'TON', 'TSO', 'TSN', 'TUR', 'TUK', 'TWI',
      'UIG', 'UKR', 'UZB', 'VEN', 'VOL', 'WLN', 'CYM', 'FRY', 'WOL', 'XHO', 'YID', 'YOR', 'ZHA', 'ZUL',
      'ORJ', 'QPC', 'TNG'
    ],

    inUseLangs: {},
    inUseIds: {},
    reachedId: 0,

    parsedSubsData: [],
  }),
  props: {
    subtitlesData: Object,
    small: Boolean
  },

  mounted() {

    window.w = this;

    const langButton = document.getElementById('lang');

    langButton.addEventListener('mousedown', (e) => {
      if (e instanceof MouseEvent) {
        this.addRow();
      }
    });

    this.parseSubtitlesData();
  },

  methods: {

    toggleAutoselectForEntry(index) {

      const newVal = !this.parsedSubsData[index].autoselect;

      this.parsedSubsData[index] = {
        ...this.parsedSubsData[index],
        autoselect: newVal
      };
    },

    toggleForcedForEntry(index) {

      const newVal = !this.parsedSubsData[index].forced;

      this.parsedSubsData[index] = {
        ...this.parsedSubsData[index],
        forced: newVal
      };
    },

    toggleIsDefaultForEntry(index) {

      const newVal = !this.parsedSubsData[index].isDefault;

      if (newVal) {

        const updatedData = this.parsedSubsData.map((entry, entryIndex) => {

          const isDefault = entryIndex === index ? true : false;

          return {
            ...entry,
            isDefault
          };
        });

        this.parsedSubsData = [...updatedData];

      } else {

        this.parsedSubsData[index] = {
          ...this.parsedSubsData[index],
          isDefault: newVal
        };
      }
    },

    parseSubtitlesData() {

      const parsedSubsData = [];

      const subsKeys = Object.keys(this.subtitlesData);

      let index = 0;

      for (let key of subsKeys) {

        parsedSubsData.push({

          ...this.subtitlesData[key],
          lang: key,
          id: index
        });

        this.setLangInUse(key, index);

        index++;
      }

      this.reachedId = index;

      this.parsedSubsData = parsedSubsData;
    },

    gatherSubsData() {

      const subsData = {};

      this.parsedSubsData.forEach(entry => {

        subsData[entry.lang] = {};

        entry.name && (subsData[entry.lang].name = entry.name);
        entry.isDefault && (subsData[entry.lang].isDefault = entry.isDefault);
        entry.forced && (subsData[entry.lang].forced = entry.forced);
        entry.autoselect && (subsData[entry.lang].autoselect = entry.autoselect);
      });

      return subsData;
    },


    changeAvailableLanguages(event, index) {

      const newVal = event.target.value;

      const affectedId = this.parsedSubsData[index].id;
      const prevSelectedLang = this.inUseIds[affectedId];

      if (prevSelectedLang !== false && prevSelectedLang !== undefined) {

        delete this.inUseLangs[prevSelectedLang];
        delete this.inUseIds[affectedId];
      }

      this.setLangInUse(newVal, affectedId);
    },

    setLangInUse(lang, id) {

      this.inUseLangs[lang] = id;
      this.inUseIds[id] = lang;
    },

    removeRow(index) {

      const affectedId = this.parsedSubsData[index].id;

      const lang = this.parsedSubsData[index].lang;

      delete this.inUseLangs[lang];
      delete this.inUseIds[affectedId];

      this.parsedSubsData.splice(index, 1);
    },

    addRow() {
       
      const lastRowData = this.parsedSubsData[this.parsedSubsData.length - 1];

        // Dont add row if in a prev added row lang is not selected
      if (lastRowData && !lastRowData.lang) {

        return;
      }

      this.parsedSubsData.push({

        id: this.reachedId,
        lang: "",
        name: "",
        isDefault: false,
        forced: false,
        autoselect: false
      });

      this.reachedId += 1;
    
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },

  watch: {

    subtitlesData() {

      // Null possible prev data
      this.inUseLangs = {};
      this.inUseIds = {};
      this.reachedId = 0;

      this.parseSubtitlesData();
    }
  },
}
</script>

<style lang="scss">



.channel-subs-lang-selector-component {

  .check-component.checkbox span {

    top: 30px;
    left: 50%;
  }

   /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/
  
  .green-1 {
    color: #57ad00;
  }
}

</style>
