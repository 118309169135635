<template>
<div class="">
  <ioio-modal
    externalClasses="media-info-modal-component"
    :show.sync="isModalOpened"
    size="small"
    headerClasses=""
  >

    <template slot="header" >
      <h1 v-if="!formats" class="pr-6 break-words">{{ editedVod.meta.title }}'s media information </h1>
      <h1 v-else class="pr-6 break-words">{{ editedVod.meta.title }}'s encoded formats </h1>
    </template>

    <ioio-tabs class="flex flex-auto flex-column mt-6" @input="onTabChange" v-if="formats && !isVodListLoading">
      <ioio-tab
        v-for="tab,i in formatsConfig"
        :key="i"
        :name="tab.name"
        :selected="tab.name === 'HLS'"
        class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
      >
        <ioio-table
          responsive
          :responsiveFlip="false"
          class="vod-table rounded-md mt-4"
        >
          <template slot="thead">
            <tr class="">
              <th class="w-1/12"></th>
              <th class="w-1/5">BitRate</th>
              <th class="w-1/5">Width</th>
              <th class="w-1/5">Height</th>
              <th v-if="tab.name === 'MP4'" class="w-1/5">Duration</th>
              <th v-if="tab.name === 'MP4'" class="w-1/5">Size(in MB)</th>
            </tr>
          </template>

          <template slot="tbody">
            <tr v-for="format, i in tab.data" :key="i">
              <td>
                <span class="text-sm"> {{ i+1 }} </span>
              </td>
              <td>
                <span class="text-sm"> {{ format.bitRate }} </span>
              </td>
              <td>
                <span class="text-sm"> {{ format.width }} </span>
              </td>
              <td>
                <span class="text-sm"> {{ format.height }} </span>
              </td>
              <td v-if="tab.name === 'MP4'">
                <span class="text-sm"> {{ parseDuration(format.duration) }} </span>
              </td>
              <td v-if="tab.name === 'MP4'">
                <span class="text-sm"> {{ parseSize(format.size) }} </span>
              </td>
            </tr>
          </template>
        </ioio-table>
      </ioio-tab>
    </ioio-tabs>

    <ioio-tabs v-if="hasData" class="flex flex-auto flex-column mt-6" @input="onTabChange" v-show="!formats && !isVodListLoading">
      <ioio-tab
        :selected="activeTabName === 'Video'"
        v-if="editedVod.mediaInfo.video"
        name="Video"
        class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
      >
        <div v-for="info, key, i in editedVod.mediaInfo.video[0]" :key="i" class="details-overview">
          <p>{{key}}</p>
          <p>{{info}}</p>
        </div>
      </ioio-tab>
      <ioio-tab
        v-if="editedVod.mediaInfo.audio"
        name="Audio"
        class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
      >
        <div v-for="info, key, i in editedVod.mediaInfo.audio[0]" :key="i" class="details-overview">
          <p>{{key}}</p>
          <p>{{info}}</p>
        </div>
      </ioio-tab>
      <ioio-tab
        v-if="editedVod.mediaInfo.container"
        name="Container"
        class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
      >
        <div v-for="info, key, i in editedVod.mediaInfo.container" :key="i" class="details-overview">
          <p>{{key}}</p>
          <p>{{info}}</p>
        </div>
      </ioio-tab>
    </ioio-tabs>

    <ioio-loader :isShown="isVodListLoading"/>

  </ioio-modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    activeTabName: 'Video',
    allFormats: {},
    isVodListLoading: false
  }),
  props: {
    formats: {
      type: Boolean,
      default: false
    }
  },

  created () {
    if (this.formats) {
      this.getVodFormats();
    }
  },

  methods: {
    getVodFormats() {
      this.isVodListLoading = true;
      let params = {
        guid: this.editedVod.guid
      }
      this.getAllEncodedVodFormats(params)
      .then (data => {
        this.allFormats = data;
      })
      .finally(() => {
        this.activeTabName = "HLS";
        this.isVodListLoading = false;
      });
    },

     onTabChange(tabName) {

      this.activeTabName = tabName;
    },

    parseDuration(sc) {

      let ms = (sc*1000).toFixed(3).toString().split('.')[1];

      return getHHMMSSFromMS(sc*1000,true) + '.' + ms;
    },

    parseSize(size) {

      return (size/1048576).toFixed(4);
    },
    ...mapMutations({
      toggleVodActionModalOpened: "library/TOGGLE_ACTION_MODAL_OPENED",
    }),
    ...mapActions({
      getAllEncodedVodFormats: "channelManager/getAllEncodedVodFormats"
    })
  },
  computed: {
    ...mapGetters({
      editedVod: 'library/editedVod',
      isMediaInfoModalOpened: 'library/isMediaInfoModalOpened'
    }),

    isModalOpened: {

      get() {

        return this.isMediaInfoModalOpened;
      },

      set(newVal) {
        this.activeTabName = 'Video';
        this.toggleVodActionModalOpened({ flagName: 'isMediaInfoModalOpened', newVal });
      }
    },

    formatsConfig() {

      let config = [];

      this.allFormats.hasHls ? config.push({name: 'HLS', data: this.allFormats.hls.variants}) : null;
      this.allFormats.hasMp4 ? config.push({name: 'MP4', data: this.allFormats.mp4}) : null;
      this.allFormats.hasDash ? config.push({name: 'DASH', data: this.allFormats.dash.variants}) : null;

      return config;
    },

    hasData() {
      return this.editedVod.mediaInfo;
    }

  },
  watch: {
    formats() {
      if (this.formats) {
        this.getVodFormats();
      } else {
        this.activeTabName = "Video";
      }
    }
  }
}
</script>

<style lang="scss">

.media-info-modal-component {

  .details-overview {
    @apply
    flex
    justify-between
    my-2;
  }
  .ds-modal__header{
    @apply
    overflow-hidden;
  }
  .ds-modal__dialog {
    height: 650px;
  }
  .vod-table {
    @apply
    p-0
    border
    border-black-100;
  }
  .rtable th, .rtable td {
    @apply min-w-0;
  }
  .rtable tbody tr {
    &:last-child {
      @apply border-none;
    }
  }
}
</style>
