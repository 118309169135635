import Vue from 'vue'

import Fsm              from '@peter.naydenov/fsm'
import { logic }        from './logic'
import lib              from './transitions/index'
import VisualController from './visualController'

import importVTT from './modules/importVTT'
import exportVTT from './modules/exportVTT'
import { eBus } from './eBus'

import VModal from 'vue-js-modal'



Vue.config.productionTip = false
Vue.use ( VModal )

let components = {};
const strEditor = new Fsm ( logic, lib );



strEditor.setDependencies ({
                                eBus
                              , importVTT
                              , exportVTT
                      })



const html = new VisualController ({eBus, Vue});

// FSM Events
eBus.$on ( 'reset', data => {
                  const { containerID } = data;
                  html.destroy ( 'subsContent' )
                  html.destroy ( containerID )
            })


// Interface Events
eBus.$on ( 'selectFiles'      , data => {
                                        strEditor.update ( 'edit', data )
                                                 .then ( response => html.publish ( components.CueEditor, response,'subsContent')   )
                  })
eBus.$on ( 'createSubtitles'  , ()   => {
                                        strEditor.update ( 'createNew' )
                                                 .then ( response =>  html.publish ( components.CueEditor, response, 'subsContent' )   )
                  })
eBus.$on ( 'save', result => {
                              strEditor.update ( 'save', result )
                                       .then   ( response =>  eBus.$emit ( 'exit', true ))
                  })
eBus.$on ( 'cancel', () => {
                              strEditor.update ( 'cancel' )
                                       .then   ( response => eBus.$emit ( 'exit', false ))
                  })
eBus.$on ( 'exit', changes => {
                              strEditor.update ( 'exit', { changes } )
                                       .then ( data => {
                                                      const { containerID } = data
                                                      html.destroy ( 'subsContent' )
                                                      html.destroy ( containerID )
                                                      console.log ( "Subtitles editor was closed" )
                                                })
                  })



eBus.$on ( 'cueStart', x =>  html.publish ( components.SubtitlePreview, x, 'sub-preview')   )
eBus.$on ( 'cueEnd'  , () => html.destroy ( 'sub-preview')   )
eBus.$on ( 'seek'    , () => html.destroy ( 'sub-preview')   )










function subtitlesEditor ( config ) {

      if ( 'none' != strEditor.getState() ) {
                        strEditor.update ( 'reset' )   // reset dependency objects
                                 .then ( () => {
                                            strEditor.reset ()
                                            getStarted ( strEditor, config )
                                    })
                }
      else    getStarted ( strEditor, config )
} // subtitlesEditor func.



function getStarted ( editor, config ) {
   let { containerID } = config;
   strEditor
      .update ( 'start', config ) // { task, containerID, components, vtt, videoURL } = config
      .then ( response => { // { components }
                  const
                        {
                             components: incomingComponents
                           , vttFiles
                         } = response
                      , fileNames = Object.keys ( vttFiles ).sort()
                      ;
                  components = incomingComponents
                  html.publish ( components.MainPage,{}, containerID )
                  if ( fileNames.length > 0 )    eBus.$emit ( 'selectFiles', { selection: fileNames})
                  else                           eBus.$emit ( 'createSubtitles' )
              })
} // getStarted func.



export { subtitlesEditor }


