<template>
  <section class="devices-stats-widget-component flex">

    <aside class="table-section flex-1 border-right pt-10 pr-20 pb-6 pl-8 mr-12">
      <h1 class="m-0 mb-12 text-2xl text-black-700 font-semibold section-title">Device</h1>

      <ioio-table class="p-0 border-0 shadow-none mb-8">
        <template slot="thead">
          <tr class="w-full grid grid-cols-3">
            <th class="text-left text-base text-black-400 font-normal">Device</th>
            <th class="text-left text-base text-black-400 font-normal">
              Total views
            </th>
            <th class="text-left text-base text-black-400 font-normal">
              Unique views
            </th>
          </tr>
        </template>
        <template slot="tbody">
          <tr class="w-full grid grid-cols-3 items-center" v-for="stat in deviceTableStats">
            <td class="text-base text-black-900">{{ stat.name }}</td>

            <td class="text-base text-black-800 font-normal flex items-center"
              >{{ stat.total }}
            </td>

            <td class="text-base text-black-800 font-normal flex items-center"
              >{{ stat.unique }}
            </td>
          </tr>

          <tr v-if="!deviceTableStats.length">No information found.</tr>
        </template>
      </ioio-table>
    </aside>
    <apexchart
      width="100%"
      height="380"
      class="chart-section flex-1 pt-24 pb-4 pr-16"
      :options="deviceChartOptions"
      :series="deviceChartSeries"
      v-if="deviceChartSeries.length"></apexchart>

    <p v-else
      class="flex-1 pt-12 pb-4 pr-16">No information found.</p>
  </section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {
    deviceTableStats: Array,
    deviceChartOptions: Object,
    deviceChartSeries: Array,
  },
  mounted() {
window.q=this;
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
