/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Next': {
    width: 30,
    height: 20,
    viewBox: '0 0 30 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.387 1.304a1.123 1.123 0 00-.556-.148c-.61 0-1.104.484-1.104 1.08v5.38L1.66 1.305a1.123 1.123 0 00-.556-.148C.494 1.156 0 1.64 0 2.236V17.35c0 .192.052.38.15.544.307.515.983.69 1.51.389l11.067-6.313v5.38c0 .192.052.38.15.544.308.515.984.69 1.51.389l11.067-6.313v4.642a1.818 1.818 0 003.637 0V2.975a1.818 1.818 0 10-3.637 0v4.642L14.387 1.304z" _fill="#4F4F4F"/>'
  }
})
