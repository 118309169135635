
const activeSection = (state) => state.activeSection;
const timelineExternalStart = (state) => state.timelineExternalStart;
const timelineExternalEnd = (state) => state.timelineExternalEnd;
const timelineExternalIsControlled = (state) => state.timelineExternalIsControlled;
const timelineTempSchedulesSelection = (state) => state.timelineTempSchedulesSelection;
const timelineTempListingsSelection = (state) => state.timelineTempListingsSelection;

const deadzoneBufferMs = (state) => state.deadzoneBufferMs;
const scheduleHoverData = (state) => state.scheduleHoverData;





const channelsMeta = (state) => state.channelsMeta;
const channelsList = (state) => state.channelsList;
const channelsListPristine = (state) => state.channelsListPristine;

const generatedPlaylistData = (state) => state.generatedPlaylistData;

// const library = (state) => state.library;
const activeToolbarPanel = (state) => state.activeToolbarPanel


const selectedChannelListingsAbsolute = (state) => state.selectedChannelListings.absolute;
const selectedChannelListingsEntire = (state) => state.selectedChannelListings.entirePeriod;
const selectedChannelListingsNext = (state) => state.selectedChannelListings.nextPeriod;
const selectedChannelListingsPrev = (state) => state.selectedChannelListings.prevPeriod;
const selectedChannelListingsCurrent = (state) => state.selectedChannelListings.currentPeriod;
const selectedChannelListingsPristine = (state) => state.selectedChannelListings.pristine;



const selectedPeriodTotalListingsDuration = (state) => state.selectedPeriodTotalListingsDuration;
const listingsTimespacesSelected = (state) => state.listingsTimespacesSelected;
const listingsTimespacesSelectedMSRepresentation = (state) => state.listingsTimespacesSelectedMSRepresentation;
const perHourMarkerCount = (state) => state.perHourMarkerCount;
const loadedListingsAndSchedulesBounds = (state) => state.loadedListingsAndSchedulesBounds;
const selectedListing = (state) => state.selectedListing;
const mediatailors = (state) => state.mediatailors;
const vodList = (state) => state.vodList;
const fromSelectedVodClips = (state) => state.fromSelectedVodClips;
const playlists = (state) => state.playlists;
const activePeriods = (state) => state.activePeriods;
const timelineOverflowIndex = (state) => state.timelineOverflowIndex;
const timelineSlots = (state) => state.timelineSlots;
const timelineWriteBufferPeriod = (state) => state.timelineWriteBufferPeriod;

const isSyncPeriodInProgress = (state) => state.isSyncPeriodInProgress;
const singleSourcePlayed = (state) => state.singleSourcePlayed;

const isSelectedChannelPlaylistModified = (state) => !!state.firstAffectedTimestamp;

const lockedChannelsGuids = (state) => state.lockedChannelsGuids;

const possibleVodTypes = (state) => state.possibleVodTypes;
const possibleVodTypesArr = (state) => state.possibleVodTypesArr;
const accessPrivacyOptions = (state) => state.accessPrivacyOptions;

const stagedVodAdInsertionGuid = (state) => state.stagedVodAdInsertionGuid;

const editedVod = (state) => state.editedVod;
const editedVodGuid = (state) => state.editedVod.guid || null;
const vodSettings = (state) => state.vodSettings;

export {

  // START refactoring new Props
  activeSection,
  timelineExternalStart,
  timelineExternalEnd,
  timelineExternalIsControlled,
  timelineTempSchedulesSelection,
  timelineTempListingsSelection,
  deadzoneBufferMs,
  scheduleHoverData,
  // END refactoring new props



  channelsMeta,
  channelsList,
  channelsListPristine,
  generatedPlaylistData,
  playlists,
  // library,
  activeToolbarPanel,

  selectedChannelListingsAbsolute,
  selectedChannelListingsEntire,
  selectedChannelListingsNext,
  selectedChannelListingsPrev,
  selectedChannelListingsCurrent,
  selectedChannelListingsPristine,
  selectedPeriodTotalListingsDuration,
  listingsTimespacesSelected,
  listingsTimespacesSelectedMSRepresentation,
  perHourMarkerCount,
  loadedListingsAndSchedulesBounds,
  selectedListing,
  mediatailors,
  vodList,
  fromSelectedVodClips,
  activePeriods,
  timelineOverflowIndex,
  timelineSlots,
  timelineWriteBufferPeriod,
  isSyncPeriodInProgress,
  singleSourcePlayed,
  isSelectedChannelPlaylistModified,
  lockedChannelsGuids,
  possibleVodTypes,
  possibleVodTypesArr,
  accessPrivacyOptions,
  stagedVodAdInsertionGuid,

  editedVod,
  editedVodGuid,
  vodSettings
}
