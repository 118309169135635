<template>

<section class="event-overview">

  <configure-stream-modal-component :event="eventDetails" />

  <ioio-modal
    class="event-description-modal"
    :show.sync="isDescriptionModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">Add description</template>
    <p class="my-5">This description appears on your registration page</p>
    <section>

      <ioio-field
        type="textarea"
        error=""
        required
        size="medium"
        placeholder="Description"
        :editor="true"
        v-model="description"></ioio-field>
    </section>
    <template slot="footer">
      <div class="flex justify-end mt-5">
        <ioio-button
          class="mr-4"
          type="secondary"
          variant="outline"
          @click="toggleAddDescriptionModalOpened"
        >Cancel</ioio-button>
        <ioio-button @click="saveEventDescription">Save</ioio-button>
      </div>
    </template>
  </ioio-modal>

  <ioio-modal
    class="invite-guests-modal"
    :show.sync="isInviteGuestsModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">Invite guests</template>

    <section class="flex mt-9">

      <ioio-field
        :value="shareUrl"
        :hasClearBtn="false"
        type="text"
        label=""
        size="medium"
        class="flex-grow touch-disabled"
        id="inviteGuestsLink"
      />
      <ioio-button
        type="primary"
        class="ml-2 mr-2"
        iconLeft="copy"
        @click="copyToClipboard('inviteGuestsLink')"
        v-if="siteBuilderSettings"
      >
        Copy
      </ioio-button>
    </section>

    <div class="event-overview__social mb-7" v-if="siteBuilderSettings">

      <ShareNetwork
        network="linkedin"
        :url="shareUrl"
        :title="eventDetails.title || ''"
        :description="eventDetails.description"
      >
        <a href="javascript:void(0);" class="event-overview__social-link">
          <ioio-icon icon="fab-linkedin-in" class="event-overview__social-link-icon "/>
        </a>
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        :url="shareUrl"
        :title="eventDetails.title || ''"
        :description="eventDetails.description"
      >
        <a href="javascript:void(0);" class="event-overview__social-link">
          <ioio-icon icon="fab-twitter" class="event-overview__social-link-icon "/>
        </a>
      </ShareNetwork>
      <ShareNetwork
        network="email"
        :url="shareUrl"
        :title="eventDetails.title || ''"
        :description="eventDetails.description"
      >
        <a href="javascript:void(0);" class="event-overview__social-link">
          <ioio-icon icon="fas-envelope" class="event-overview__social-link-icon "/>
        </a>
      </ShareNetwork>
    </div>

    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          @click="toggleInviteGuestsModalOpened"
        >Cancel</ioio-button>

      </div>
    </template>
  </ioio-modal>

  <assign-images-modal-component
    id="eventCoverImage"
    :getAssignedImages="getCoverSelectedImage"
    :initialImages="currentCoverImage"
    singleChoise
    raiseFlagName="isEventCoverImageModalOpened"
    width="1100"
    height="700" />

  <header class="event-overview__header">
    <div
      class="event-overview__header-background"
      :style="selectedCoverImageStyles">
        <ioio-button
          type="secondary"
          variant="outline"
          iconLeft="camera"
          class="event-overview__change-background-btn"
          @click.stop.prevent="toggleEventCoverImageModalOpened(true)"
        >
          <span>Change cover</span>
        </ioio-button>
    </div>
    <div class="event-overview__header-container">
      <h3 class="event-overview__title">{{ eventDetails.name }}</h3>
      <div class="flex items-center">
        <div v-if="eventDetails.startTime" class="event-overview__date">
          <ioio-icon
            icon="calendar"
            class="event-overview__date-icon"
          />
          <p class="event-overview__date-text">
            {{ moment(eventDetails.startTime).format('LLLL') }} ({{ moment().tz(eventDetails.timezone).format('z') }})
          </p>
        </div>
        <p v-else class="event-overview__date">No start time provided</p>
        <p class="event-overview__disclamer">
          {{ eventDetails.access }} event <span>&bull;</span> Created on {{ new Date(eventDetails.createdAt).toDateString() }}
        </p>
      </div>
    </div>
  </header>

  <section class="event-overview__content">

    <div class="event-overview__boxes">
      <aside class="event-overview__box">
        <h2 class="event-overview__box-title">Share</h2>
        <div class="event-overview__link">
          <ioio-field
            :value="shareUrl"
            :hasClearBtn="false"
            type="text"
            label=""
            size="medium"
            externalClasses=""
            class="flex-grow touch-disabled"
            id="shareLink"
          />
          <ioio-button
            type="primary"
            class="ml-2 mr-2"
            iconLeft="copy"
            @click="copyToClipboard('shareLink')"
            v-if="siteBuilderSettings"
          >
            Copy
          </ioio-button>

          <ioio-button
            type="secondary"
            variant="outline"
            @click="visitShareUrl"
            v-if="siteBuilderSettings"
          >
            Visit
          </ioio-button>
        </div>
        <div class="event-overview__social"  v-if="siteBuilderSettings">

          <ShareNetwork
            network="linkedin"
            :url="shareUrl"
            :title="eventDetails.title || ''"
            :description="eventDetails.description"
          >
            <a href="javascript:void(0);" class="event-overview__social-link">
              <ioio-icon icon="fab-linkedin-in" class="event-overview__social-link-icon "/>
            </a>
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            :url="shareUrl"
            :title="eventDetails.title || ''"
            :description="eventDetails.description"
          >
            <a href="javascript:void(0);" class="event-overview__social-link">
              <ioio-icon icon="fab-twitter" class="event-overview__social-link-icon "/>
            </a>
          </ShareNetwork>
          <ShareNetwork
            network="email"
            :url="shareUrl"
            :title="eventDetails.title || ''"
            :description="eventDetails.description"
          >
            <a href="javascript:void(0);" class="event-overview__social-link">
              <ioio-icon icon="fas-envelope" class="event-overview__social-link-icon "/>
            </a>
          </ShareNetwork>
        </div>

      </aside>

      <aside class="event-overview__box">
        <h2 class="event-overview__box-title">Stats</h2>

        <section class="event-overview__stat" v-if="!isRequestPending">
          <div class="event-overview__stat-el">
            <h4 class="event-overview__stat-value">{{ pageVisits }}</h4>
            <p class="event-overview__stat-name">Page Visits</p>
          </div>
          <div class="event-overview__stat-el">
            <h4 class="event-overview__stat-value">{{ uniqueViews }}</h4>
            <p class="event-overview__stat-name">Unique Views</p>
          </div>
          <div class="event-overview__stat-el">
            <h4 class="event-overview__stat-value">{{ registrations }} </h4>
            <p class="event-overview__stat-name">Registrations</p>
          </div>
        </section>
        <div class="relative mt-4" v-else>

          <ioio-loader isShown width="100" style="height: 50px;"/>
        </div>
      </aside>

    </div>

    <ioio-collapsibles autoclose size="large">
      <ioio-collapsible active>
        <template slot="title">
          <span class="ds-collapsible__number">1</span>
          Organize
        </template>
        <template slot="content">
          <section class="ds-collapsible__els">
          <div
            class="ds-collapsible__el"
            @click.stop.prevent="navigate('sessions')"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventDetails.sessions && eventDetails.sessions.length"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/agenda.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Add agenda</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="navigate('speakers')"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="speakers.length"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/speaker.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Add speakers</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="toggleAddDescriptionModalOpened"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventDetails.description"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/description.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Add description</h6>
          </div>
        </section>

        </template>
      </ioio-collapsible>
      <ioio-collapsible>
        <template slot="title">
          <span class="ds-collapsible__number">2</span>
          Promote
        </template>
        <template slot="content">
          <section class="ds-collapsible__els">

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="navigate('appearance')"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  class="ds-collapsible__el-icon"
                  icon="fa-check"
                  v-if="false"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/registration_2.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Customize event page</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="navigate('appearance')"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  class="ds-collapsible__el-icon"
                  icon="fa-check"
                  v-if="false"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/registration.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Customize event room</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="toggleInviteGuestsModalOpened"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  class="ds-collapsible__el-icon"
                  icon="fa-check"
                  v-if="false"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/invite.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Invite guests</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="navigate('attendees')"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="attendees.length"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/import.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Import guests</h6>
          </div>

        </section>
        </template>
      </ioio-collapsible>
      <ioio-collapsible class="relative">
        <template slot="title">
          <span class="ds-collapsible__number">3</span>
          Prepare to go live

          <div
            class="required-step-disclaimer"
            v-if="!eventDetails.streamOption"
          >Required step
          </div>
        </template>
        <template slot="content">
          <section class="ds-collapsible__els">

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="openConfigureModal"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventDetails.streamOption"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span :class="{ 'text-red-600': !eventDetails.streamOption }">Required</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/configure.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Configure stream</h6>
          </div>

          <!-- Options only for streamOption === ioConnect -->
          <div
            class="ds-collapsible__el"
            @click.stop.prevent="openAssignAssetsModal"
            v-if="eventDetails.streamOption && eventDetails.streamOption === 'ioConnect'"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventAssetsConfigured"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/frame_19954.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Attach media</h6>
          </div>

          <div
            class="ds-collapsible__el"
            @click.stop.prevent="openCustomizeStreamModal"
            v-if="eventDetails.streamOption && eventDetails.streamOption === 'ioConnect'"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventStageConfigured"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/stream.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Customize stage</h6>
          </div>

          <!-- Options only for streamOption === preRecorded -->
          <div
            class="ds-collapsible__el"
            @click.stop.prevent="openSelectPreRecordModal"
            v-if="eventDetails.streamOption && eventDetails.streamOption === 'preRecorded'"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventDetails.streamChannel"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/stream.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Select Pre-record</h6>
          </div>

          <!-- Options only for streamOption === liveStream -->
          <div
            class="ds-collapsible__el"
            @click.stop.prevent="openSelectStreamUrlModal"
            v-if="eventDetails.streamOption && eventDetails.streamOption === 'liveStream'"
          >
            <header class="ds-collapsible__el-header">
              <span>
                <ioio-icon
                  icon="fa-check"
                  v-if="eventDetails.streamUrl"
                  class="ds-collapsible__el-icon"
                />
              </span>
              <span>Optional</span>
            </header>
            <div></div>
            <img
              class="ds-collapsible__el-image"
              src="~@assets/eventStyling/pages/events_creation/stream.svg"
              alt=""
            />
            <h6 class="ds-collapsible__el-title">Add link to your HLS stream</h6>
          </div>


        </section>
          <section
            v-if="eventDetails.streamOption && eventDetails.streamOption === 'ioConnect'"
            class="ds-collapsible__el-footer"
          >
            <ioio-button
              type="primary"
              @click.stop.prevent="enterStudio"
            >
              Enter studio
            </ioio-button>
            <p>Explore the backstage. Enter at least 10 min earlier to prepare for going live.</p>
          </section>
        </template>
      </ioio-collapsible>
    </ioio-collapsibles>
  </section>

  <section class="border rounded-3 mb2 accordeon">




  </section>


</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({

    pageVisits: 0,
    uniqueViews: 0,
    registrations: 0,
    isRequestPending: false,

    description: '',

    isDescriptionModalVisible: false,
    isInviteGuestsModalVisible: false,

    speakers:[],
    attendees: [],

    moment: {},

    currentCoverImage: {},
    newCoverImg: {}
  }),
  props: {

    onActiveSectionChanged: {
      type: Function,
      required: true
    },

    siteBuilderSettings: [Object, Boolean]
  },

  created() {

    this.getAttendeesData();

    this.moment = moment;
  },

  mounted() {
    window.o = this;

    this.copyElementInnerText = copyElementInnerText;

    this.getEventStats();
  },
  methods: {

    toggleEventCoverImageModalOpened(newVal) {

      if (newVal) {

        this.currentCoverImage = this.eventDetails.coverImage ?
          { [this.eventDetails.coverImage.guid]: this.eventDetails.coverImage } :
          {};
      }

      this.toggleAssignImagesModalFlag({

        division: 'isEventCoverImageModalOpened',
        newVal
      });
    },

    getCoverSelectedImage(images, imagesMap) {

      this.newCoverImg = imagesMap[images[0]];

      this.saveEvent()
        .then(() => {

          this.$toasted.success(`Event cover image was saved successfully!`);

          this.newCoverImg = {};
        });

      this.toggleEventCoverImageModalOpened(false);
    },

    getEventStats() {

      if (!this.siteBuilderSettings) {

        return;
      }

      const params = {

        appId: this.siteBuilderSettings.id,
        eventGuid: this.eventDetails.guid
      };

      this.isRequestPending = true;

      this.getEventViewsStats(params)
        .then(stats => {

          this.pageVisits = stats.total;
          this.uniqueViews = stats.unique;

        }).finally(() =>{

          this.isRequestPending = false;
        })
    },

    getAttendeesData() {

      this.getAttendees({ eventGuid: this.eventDetails.guid }).then(resp => {

        const allAttendees = resp;
        const speakers = [];
        const attendees = [];

        allAttendees.forEach(a => {

          if (a.role === 'talent') {

            speakers.push(a);

          } else if (a.role === 'attendee') {

            attendees.push(a);
          }
        });

        this.attendees = attendees;
        this.speakers = speakers;
        this.registrations = this.attendees.length;
      });
    },

    openConfigureModal() {

      this.toggleEventStreamOptionModalOpened(true);
    },

    openAssignAssetsModal() {

      this.toggleEventStreamAssetsModalOpened(true);
    },

    openCustomizeStreamModal() {

      this.toggleEventStreamOverlaysModalOpened(true);
    },

    openSelectPreRecordModal() {

      this.toggleEventStreamChannelModalOpened(true);
    },

    openSelectStreamUrlModal() {

      this.toggleEventStreamUrlModalOpened(true);
    },

    toggleAddDescriptionModalOpened() {

      this.description = this.eventDetails.description;

      this.isDescriptionModalVisible = !this.isDescriptionModalVisible;
    },

    toggleInviteGuestsModalOpened() {

      this.isInviteGuestsModalVisible = !this.isInviteGuestsModalVisible;
    },

    saveEventDescription() {

      this.saveEvent()
        .then(() => {

          this.$toasted.success(`Event description was saved successfully!`);
          this.toggleAddDescriptionModalOpened();
        });
    },

    saveEvent() {

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.eventDetails,
        },

        guid: this.eventDetails.guid
      };

      if (this.isDescriptionModalVisible) {

        payload.data.description = this.description;
      }

      if (this.isEventCoverImageModalOpened) {

        payload.data.coverImage = this.newCoverImg;
      }

      return this.updateEvent(payload)
        .then(() => {

          this.getEventById({ guid: this.eventDetails.guid });
        })
        .finally(() => {

          this.isRequestPending = false;
        });


    },

    navigate(sectionId) {

    // NOTE: mayde will NOT be used for every Get Started btn and this check can be deleted
      if (!sectionId) return;

      const eventId = this.$route.query.id;

      const queries = {
        id: eventId,
        sectionId: sectionId || undefined
      };

      this.onActiveSectionChanged(sectionId);

      this.$pushRoute({

        path: this.$route.fullPath,
        query: queries
      });
    },

    enterStudio() {

      window.open(this.eventDetails.cloudMixerUrl);
    },

    visitShareUrl() {

      window.open(this.shareUrl);
    },

    copyToClipboard(elementId) {

      if (!this.eventDetails.cloudMixerBaseDomain) {

        this.$toasted.error('Setup your event experience before you can copy the access link.')
        return;
      }

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    ...mapMutations({
      toggleEventStreamOptionModalOpened: 'events/TOGGLE_EVENTS_STREAM_OPTION_MODAL_FLAG_VISIBLE',
      toggleEventStreamAssetsModalOpened: 'events/TOGGLE_EVENTS_STREAM_ASSETS_MODAL_FLAG_VISIBLE',
      toggleEventStreamOverlaysModalOpened: 'events/TOGGLE_EVENTS_STREAM_OVERLAYS_MODAL_FLAG_VISIBLE',
      toggleEventStreamChannelModalOpened: 'events/TOGGLE_EVENTS_STREAM_CHANNEL_MODAL_FLAG_VISIBLE',
      toggleEventStreamUrlModalOpened: 'events/TOGGLE_EVENTS_STREAM_URL_MODAL_FLAG_VISIBLE',
      toggleAssignImagesModalFlag: 'events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE',
    }),
    ...mapActions({
      getEventById: 'events/getEventById',
      updateEvent: 'events/updateEvent',
      getAttendees: "events/getAttendees",
      getEventViewsStats: "statistics/getEventViewsStats",
    })
  },
  computed: {
    ...mapGetters({
      eventDetails: "events/eventDetails",
      organizationSelected: "app/organizationSelected",
      mixerSettings: "events/mixerSettings",
      isEventCoverImageModalOpened: 'events/isEventCoverImageModalOpened'
    }),

    selectedCoverImageStyles() {

      return this.eventDetails.coverImage ?

        {
          backgroundImage: `url(${this.eventDetails.coverImage.cdnPath})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        } :

        { // default image
          backgroundImage: `url(${require('@assets/eventStyling/pages/events_creation/particle.png')})`,
          backgroundPosition: '115% bottom',
          backgroundSize: 'auto 270px'
        };
    },

    shareUrl() {

      if (this.siteBuilderSettings === false) {

        return 'No Site builder is configured for this organization';
      }

      return `https://${this.siteBuilderSettings.basePath}/event/${this.eventDetails.guid}`
    },

    eventAssetsConfigured() {

      return this.eventDetails && this.eventDetails.assets ?
        (this.eventDetails.assets.images && this.eventDetails.assets.images.length) ||
        (this.eventDetails.assets.videos && this.eventDetails.assets.videos.length) : false;
    },


    eventStageConfigured() {

      return this.mixerSettings ? Object.keys(this.mixerSettings).length : false;
    }
  },

  watch: {

    siteBuilderSettings() {

      this.getEventStats();
    }
  }
}
</script>

<style lang="scss" scoped>

.event-overview {
  @apply w-full;

  &__header {
    @apply
    relative
    flex
    items-end
    pb-32
    min-h-96
    overflow-hidden;
  }

  &__change-background-btn  {
    &.is-tertiary {
      @apply
      absolute
      right-6
      top-6
      bg-black-900
      bg-opacity-60
      border-0
      transition-all
      text-sm
      text-black-50
      hover:text-white
      hover:bg-opacity-80;
    }
  }

  // &__header-img {
  //   @apply
  //   absolute
  //   w-full
  //   h-full
  //   object-contain;
  // }

  &__header-background {
    @apply
    absolute
    top-0
    left-0
    h-full
    w-full
    bg-no-repeat;
    background-color: #2f3347;

    &::before {
      content: "";
      @apply
      absolute
      w-full
      h-full
      top-0
      left-0
      bg-gradient-to-r
      bg-opacity-40
      bg-black-900
      from-black-900
      via-transparent;
    }
  }

  &__header-container {
    @apply
    relative
    flex
    flex-col
    max-w-7xl
    w-full
    h-full
    px-12
    mx-auto;
  }

  &__content {
    @apply
    relative
    max-w-7xl
    w-full
    mx-auto
    px-12
    -mt-24;
  }

  &__title {
    @apply
    pb-10
    text-4xl
    text-white;
  }

  &__date {
    @apply
    flex
    px-5
    py-3
    mr-5
    bg-white
    rounded
    bg-opacity-10
    text-white;
  }

  &__date-icon {
    @apply mr-3;
  }

  &__date-text {
    @apply opacity-60;
  }

  &__disclamer {
    @apply
    text-sm
    opacity-60
    text-white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

    span {
      color: #8E93B4;
    }
  }

  &__boxes {
    @apply
    grid
    grid-cols-1
    xl:grid-cols-2
    gap-4
    mb-4;
  }

  &__box {
    @apply
    flex
    flex-col
    flex-1
    p-8
    bg-white
    rounded
    shadow-card;
  }

  &__link,
  &__social {
    @apply flex;
  }

  &__social {
    @apply mt-4;
  }

  &__social-link {
    @apply
    flex
    justify-center
    items-center
    mr-3
    w-8
    h-8
    text-blue-grey-800
    rounded-full
    border
    border-dark-blue-200;
    transition: background-color .3s ease;

    &:hover {
      @apply
      bg-blue-grey-400
      text-white
      border-white;
    }
  }

  &__box-title {
    @apply text-lg mb-5;
  }

  &__stat {
    @apply flex;
  }

  &__stat-el {
    @apply
    pl-8
    ml-8
    border-l
    border-black-100;

    &:first-child {
      @apply
      border-none
      pl-0
      ml-0;
    }
  }

  &__stat-value {
    @apply text-2xl mb-1;
  }

  &__stat-name {
    @apply
    text-black-400
    text-sm

    whitespace-nowrap;
  }
}

.ds-collapsible {
  &__number {
    @apply
    flex
    items-center
    justify-center
    w-5
    h-5
    mr-2
    text-sm
    rounded-full
    bg-blue-grey-100;
  }
  &__els {
    @apply
    grid
    grid-cols-1
    gap-4
    justify-items-stretch
    md:grid-cols-2
    xl:grid-cols-4;
  }

  &__el {
    @apply
    flex
    flex-col
    flex-1
    items-center
    p-4
    rounded
    border
    border-black-50
    cursor-pointer
    overflow-hidden;
    transition: background-color .2s ease;

    &:hover {
      @apply bg-blue-grey-100;
    }
  }

  &__el-header {
    @apply
    flex
    justify-between
    items-center
    w-full
    h-6
    mb-1
    text-xs
    text-black-400;

    span {
      @apply justify-items-end;
    }
  }

  &__el-icon {
    @apply
    flex
    justify-center
    items-center
    rounded-full
    p-1
    w-4
    h-4
    text-green-500
    bg-green-100
    box-border;
  }

  &__el-image {
    @apply mb-3;
  }

  &__el-title {
    @apply
    text-base
    truncate
    mb-3;
  }

  &__el-footer {
    @apply
    flex
    flex-wrap
    md:flex-nowrap
    items-center
    mt-4;

    .ds-btn {
      @apply
      w-full
      md:w-auto
      justify-center
      mb-4
      md:mb-0
      whitespace-nowrap;
    }

    p {
      @apply
      w-full
      md:w-auto
      md:ml-5
      text-black-400
      text-sm;
    }
  }


}

.required-step-disclaimer {

  position: absolute;
  right: 0;

  @apply
  mr-16
  pr-2
  text-xs
  uppercase
  text-red-600;
  /* before:text-red-600; */

  &:before {

    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    position: relative;
    top: 0;
    left: -8px;
    border-radius: 50%;
    background-color: rgba(236, 76, 71, var(--tw-text-opacity));
  }
}

// normaly disabled field prevents copy-to-clipboard from working
.touch-disabled {

  pointer-events: none;
  opacity: 0.5;
}

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
.border {
  border: 1px solid rgba(238, 239, 241, var(--tw-border-opacity)) !important;
}
</style>
