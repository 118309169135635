<template>

<ioio-modal

  class="select-speaker"
  :show.sync="isModalOpened"
  size="medium"
  :noScroll="false"
  headerClasses=""
  footerClasses=""
>

  <template slot="header">
    <h3 class="select-speaker__title">Select Speakers</h3>

    <div class="mt-5">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
        class=""
      />

    </div>
  </template>



    <ul
      class="select-speaker__list"
      v-if="!isRequestPending && filteredEventSpeakers.length"
      ref="libraryScrollArea"
    >
      <li
        class="select-speaker__el"
        :key="speaker.guid"
        v-for="speaker in filteredEventSpeakers"
      >
        <div class="flex items-center">

          <img
            v-if="speaker.meta.avatar && speaker.meta.avatar.guid"
            :src="speaker.meta.avatar.src"
            class="select-speaker__avatar"
          />

          <div
            class="select-speaker__thumb"
            v-else
          >
            <ioio-icon icon="fas-user"/>
          </div>

          <p class="select-speaker__name">{{ speaker.name }}</p>

        </div>

        <ioio-checkbox
          size="medium"
          disclaimer=""
          v-model="addedGuidsMap[speaker.guid]"
          @input="(newVal) => toggleAddSpeakerToList(speaker, newVal)"
        >
        </ioio-checkbox>
      </li>
    </ul>

    <div
      v-else-if="!isRequestPending && !filteredEventSpeakers.length"
      class="flex flex-col items-center justify-center"
    >
      <p class="mb-8 text-center px-7 mt-14">
        You haven't created any speaker profiles in this event. <br>  Create the first one by visiting the <strong>Speakers section</strong>.
      </p>

      <ioio-button
        type="secondary"
        variant="outline"
        class="mb-10"
        iconLeft="plus"
        @click.stop.prevent="handleNavigateToCreateSpeakers">Add speaker</ioio-button>
    </div>

    <ioio-loader v-else isShown></ioio-loader>


    <footer class="flex items-center justify-center">

      <pagination-component
        class="flex-none"
        :page-selected="eventSpeakersSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="eventSpeakersSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </footer>

  <template slot="footer">

    <footer class="flex justify-end" v-if="!isRequestPending && filteredEventSpeakers.length">

      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-4"
        @click.stop.prevent="isModalOpened = false">Cancel</ioio-button>

      <ioio-button
        type="primary"
        @click.stop.prevent="getCollectedSpeakers">Add speaker</ioio-button>
    </footer>
  </template>
</ioio-modal>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isRequestPending: false,
    addedGuidsMap: {},
    addedGuidsSpeakerMap: {},
    collectedSpeakersCount: 0,
  }),
  props: {
    eventGuid: String,
    getAssignedSpeakers: Function,
    initialSpeakers: Object,
    onActiveSectionChanged: Function
  },

  mounted() {

  },

  beforeDestroy() {

    this.unstageFromFiltering('eventSpeakersLibrary');
  },

  methods: {

    handleNavigateToCreateSpeakers() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.navigateToCreateSpeakers();
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        this.navigateToCreateSpeakers();
      }
    },

    navigateToCreateSpeakers() {

      const sectionId = 'speakers';
      const eventId = this.$route.query.id;

      this.isModalOpened = false;

      const queries = {
        id: eventId,
        sectionId
      };

      // Needed so that the modal has time to close
      setTimeout(() => {

        this.onActiveSectionChanged(sectionId);

        this.$pushRoute({

          path: this.$route.fullPath,
          query: queries
        });
      });
    },

    getCollectedSpeakers() {

      const speakers = Object.keys(this.addedGuidsMap);

      const speakersFullData = this.addedGuidsSpeakerMap;

      this.getAssignedSpeakers(speakers, this.addedGuidsSpeakerMap);
    },

    toggleAddSpeakerToList(speaker, newVal) {

      if (newVal) {

        this.addedGuidsSpeakerMap[speaker.guid] = {

          ...speaker
        };

        this.addedGuidsMap[speaker.guid] = true;
        this.collectedSpeakersCount++;

      } else {

        delete this.addedGuidsSpeakerMap[speaker.guid];
        delete this.addedGuidsMap[speaker.guid];

        this.collectedSpeakersCount--;
      }
    },

    beforeOpen() {

      this.addedGuidsMap = {};
      this.addedGuidsSpeakerMap = {};

      for (let speakerGuid in this.initialSpeakers) {

        this.toggleAddSpeakerToList(this.initialSpeakers[speakerGuid], true);
      }

      this.triggerGetSpeakers(this.eventGuid);
    },

    triggerGetSpeakers(eventGuid) {

      this.unstageFromFiltering('eventSpeakersLibrary');

      this.isRequestPending = true;

      return this.getAttendees({ eventGuid }).then(resp => {

        this.speakers = resp;

        this.stageForFiltering({
          items: this.speakers,
          division: 'eventSpeakersLibrary'
        });

        this.applyFiltering({

          filterType: 'eventSpeakersLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    onPaginationClicked(pageNum) {

      this.$refs.libraryScrollArea.scrollTop = 0;

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'eventSpeakersLibrary'
      });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'eventSpeakersLibrary'
      });
    },

    ...mapMutations({

      toggleAssignSpeakersToEventModalVisible: "events/TOGGLE_ASSIGN_SPEAKERS_TO_EVENT_MODAL_VISIBLE",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      getAttendees: "events/getAttendees",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({
      filteredEventSpeakers: "filterAndPaginate/filteredEventSpeakers",
      filteredItemsCount: "filterAndPaginate/filteredEventSpeakersItemsCount",
      eventSpeakersSearchOptions: "filterAndPaginate/eventSpeakersSearchOptions",
      isAssignSpeakersToEventModalVisible: 'events/isAssignSpeakersToEventModalVisible',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    isModalOpened: {

      get() {

        return this.isAssignSpeakersToEventModalVisible;
      },

      set(newVal) {

        this.toggleAssignSpeakersToEventModalVisible(newVal);
      }
    }
  },

  watch: {

    isModalOpened() {

      if (this.isModalOpened) {

        this.beforeOpen();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.select-speaker {
  &__list {
    @apply mt-5;
  }

  &__el {
    @apply
    flex
    justify-between
    items-center
    mb-4
    pb-4
    border-b
    border-dark-blue-100;

    &:last-child {
      @apply border-none;
    }
  }
  &__avatar,
  &__thumb {
    @apply
    w-20
    h-20
    mr-4
    object-cover
    overflow-hidden
    rounded-full
    border
    border-blue-grey-100;
  }
  &__thumb {
    @apply
    flex
    mr-7
    text-2xl
    items-center
    justify-center
    text-dark-blue-500
    bg-dark-blue-100;
  }
  &__name {
    @apply
    text-sm
    font-semibold;
  }


}
</style>
