/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '-1s': {
    width: 13,
    height: 8,
    viewBox: '0 0 13 8',
    data: '<path pid="0" d="M.45 3.769h3.303v.72H.45v-.72zM5.038 6.28h1.395V1.519l-1.539.477-.207-.531L6.64.592 7.28.7v5.58h1.25V7H5.038v-.72zm4.585-.234c.27.114.501.198.693.252.198.054.402.081.612.081.27 0 .48-.057.63-.171.156-.114.234-.282.234-.504a.45.45 0 00-.126-.333.716.716 0 00-.27-.198 7.746 7.746 0 00-.549-.198 17.027 17.027 0 01-.477-.162 1.294 1.294 0 01-.639-.45c-.144-.204-.216-.456-.216-.756 0-.372.144-.684.432-.936.288-.252.711-.378 1.27-.378.233 0 .452.027.656.081.21.054.43.126.657.216l-.225.666a5.58 5.58 0 00-.558-.189 1.924 1.924 0 00-.53-.072c-.283 0-.496.057-.64.171a.513.513 0 00-.216.432c0 .168.057.294.171.378.114.078.297.162.55.252.065.018.125.039.18.063l.215.081c.252.084.453.168.603.252.156.084.288.213.396.387.108.168.162.393.162.675 0 .444-.159.789-.477 1.035-.312.24-.72.36-1.224.36-.294 0-.567-.03-.819-.09a5.378 5.378 0 01-.756-.261l.261-.684z" _fill="#000"/>'
  }
})
