/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'left-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 23.414L6.293 12.707a.999.999 0 010-1.414L17 .586 18.414 2l-10 10 10 10L17 23.414z" _fill="#111"/>'
  }
})
