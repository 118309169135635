<template>
<div class="avatar-component">
  {{ truncatedUsername }}
</div>



</template>

<script>
export default {
  data: () => ({}),
  props: {
    username: {
      type: String,
      required: false,
      default: ""
    }
  },
  created() {

    window.ko = this
  },
  computed: {

    truncatedUsername() {

      const name = this.username || '';
      const nameAsArr = name.split(' ');
      const initials = nameAsArr.length > 1 ?
        `${nameAsArr[0].charAt(0)}${nameAsArr[1].charAt(0)}` :
        nameAsArr.length ?
        `${nameAsArr[0].split(0, 2)}` : 'IO';

      return initials;
    }
  }
}
</script>

<style lang="scss" scoped>

.avatar-component {

  @apply
  rounded-full
  bg-yellow-50
  text-yellow-600
  text-sm
  text-center
  font-semibold;

  width: 32px;
  height: 32px;
  line-height: 32px;

  &.is-big {

    @apply
    text-lg;

    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}

</style>
