<template>

<ioio-modal
  :show.sync="isRedirectPreventConfirmModalVisible"
  size="medium"
  noScroll
>
  <template slot="header">
    {{ redirectMsg || 'Progress will be lost if you proceed.' }}
  </template>

  <h2 class="mt-2 mb-10 text-black-600" v-if="redirectSecondaryMsg">{{ redirectSecondaryMsg }}</h2>

  <template  slot="footer">
    <div class="flex justify-end">
      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-4"
        size="small"
        @click.stop.prevent="activeConfirmDialog.reject()">Cancel</ioio-button>

      <ioio-button
        type="danger"
        variant="tonal"
        size="small"
        @click.stop.prevent="activeConfirmDialog.resolve()">Leave</ioio-button>
    </div>

  </template>

</ioio-modal>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {},
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      activeConfirmDialog: 'app/activeConfirmDialog',
      redirectMsg: 'app/redirectMsg',
      redirectSecondaryMsg: 'app/redirectSecondaryMsg',
      isRedirectFlagRaised: 'app/isRedirectFlagRaised',
    }),

    isRedirectPreventConfirmModalVisible: {

      get() {

        return this.isRedirectFlagRaised;
      },

      set(newVal) {

        if (newVal === false) {

          this.activeConfirmDialog && this.activeConfirmDialog.reject();
        }
      }
    }
  },

  watch: {

    isRedirectFlagRaised() {

      if (this.isRedirectFlagRaised) {

        this.isRedirectPreventConfirmModalVisible = true;

      } else {

        this.isRedirectPreventConfirmModalVisible = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>



</style>
