<template>
  <aside class="event-time">
    <div
      v-if="event.startTime"
      class="event-time__el"
    >
      <p class="event-time__month">
        {{ new Date(event.startTime).toLocaleString('en-us', { month: 'short' }) }}
      </p>

      <p class="event-time__day">
        {{ new Date(event.startTime).getDate() }}
      </p> <!-- 1, 2... 30, 31 -->

      <p class="event-time__hour">
        {{ parseStartTime(event.startTime, moment().tz(event.timezone).format('z')) }}
      </p>
    </div>

    <p class="event-time__nodata" v-else>No start time provided</p>
  </aside>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { getHHMMSSFromTimestamp } from "@utils/helpers";

export default {
  data: () => ({
    moment: {}
  }),
  props: {
    event: Object
  },

  created() {

    this.moment = moment;
  },

  methods: {

    parseStartTime(ms, timezone) {

      // NOTE: uncomment if 12Hour time will be displayed instead of timezone
      // return getHHMMSSFromTimestamp(ms, 'omitSeconds', 'use12Hour');

      return `${ getHHMMSSFromTimestamp(ms, 'omitSeconds') } ${ timezone }`;
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">

.event-time {

  &__el {
    @apply
    flex
    flex-col
    items-center
    justify-center
    w-24;
  }

  &__month {
    @apply text-sm uppercase;
  }

  &__day {
    @apply text-2xl leading-7;
  }

  &__hour {
    @apply
    text-center
    text-xs
    uppercase
    text-black-400;
  }

  &__nodata {
    @apply text-xs;
  }
}
</style>
