/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy-clipboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" d="M9 8a.945.945 0 011-1h8V1a.945.945 0 00-1-1H2a.945.945 0 00-1 1v18a.945.945 0 001 1h7z"/><path pid="1" data-color="color-2" d="M22 9H12a.945.945 0 00-1 1v13a.945.945 0 001 1h10a.945.945 0 001-1V10a.945.945 0 00-1-1z"/></g>'
  }
})
