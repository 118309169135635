<template>
<div class="custom-vocabularies-settings-component">
  <custom-vocabularies-modal-component
    v-if="isEditVocabulariesModalOpened"
    :isEditVocabulariesModalOpened="isEditVocabulariesModalOpened"
    :toggleEditModalOpened="toggleEditModalOpened"
    :editType="editType"
    :selectedVoc="selectedVoc"
    :allPossibleLanguages="allPossibleLanguages"
    :isVocabularyUpdating="isVocabularyUpdating"
    :createVocabulary="createVocabulary"
  />

  <ioio-modal
    v-if="isConfirmModalOpened"
    class=""
    :show.sync="isConfirmModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        {{ confirmHeaderText }}
      </div>
    </template>

    <div v-if="confirmType === 'delete'" class="">
      <h3 class="mb-3">
        This is the list of vocabularies that will be deleted
      </h3>
      <div v-for="voc, index in selectedToDeleteVoc" :key="index" class="mb-3">
        <div class="flex text-sm items-center text-black-400">
          <ioio-icon icon="fal-times" class="mr-2 text-red-600" />
          {{ voc.name }}
        </div>
        <span class="text-red-600 text-xs">
          {{ voc.default ? `Please note that '${voc.name}' is the default Custom vocabulary for this organisation!` : ''}}
        </span>
      </div>
      <h3 class="mt-4">
        Are you sure you want to continue?
      </h3>
    </div>

    <div v-if="confirmType === 'default' && !selectedVoc.default" class="">
      <h3 class="mb-3">
        Please note that all future transcriptions will be processed using this vocabulary as a reference.
      </h3>
    </div>
    <template slot="footer">
      <div class="flex justify-end" v-if="!isVocabularyUpdating">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isConfirmModalOpened = false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmType === 'default' ? setDefaultVoc(isDefault) : deleteVoc()"
        >
          Continue
        </ioio-button>
      </div>
      <ioio-loader v-else isShown />
    </template>

  </ioio-modal>

  <header class="flex justify-between border-bottom pl-4 pb-4 mb-2">

    <h4 class="mt-2">Custom Vocabularies</h4>

    <div class="flex">
      <ioio-button
        type="secondary"
        variant="outline"
        size="small"
        class="mr-4"
        @click.stop="addVoc"
      >
        Add New Vocabulary
      </ioio-button>

      <ioio-button
        type="secondary"
        variant="outline"
        size="small"
        @click.stop="downloadCsvTemplate"
      >
        CSV Template
      </ioio-button>
    </div>

  </header>

  <section v-if="!isVocabulariesDataUpdating" class="mt-4">

    <div v-if="initialVocabularies.length" class="flex items-center mb-2 h-8">
      <ioio-field
        v-model="searchText"
        type="text"
        placeholder="Search for name"
        size="small"
        isSearch
        :hasClearBtn="true"
        @input="searchByName"
        class="w-48 mr-2"
      />

      <ioio-dropdown
        :items="possibleLanguages"
        titleProp="name"
        valueProp="name"
        v-model="selectedLanguage"
        placeholder="Select language"
        :multiple="false"
        size="small"
        @input="searchByLang"
        alignX="right"
        class="mr-2"
      />

      <div v-if="isActionsOpened" class="flex active-buttons items-center w-max ml-auto">
        <ioio-button
          v-if="countSelected === 1"
          size="small"
          type="primary"
          variant="tonal"
          class="mr-2"
          iconLeft="fas-check"
          @click.stop="openConfirmModal"
        >
          {{ isDefault ? 'Unset as default' : 'Set as default' }}
        </ioio-button>
        <ioio-button
          v-if="countSelected === 1"
          size="small"
          type="secondary"
          variant="tonal"
          iconLeft="fa-light fa-pen"
          class="mr-2 border-yellow"
          @click.stop="editVoc"
        >
          Edit
        </ioio-button>
        <ioio-button
          size="small"
          type="danger"
          variant="tonal"
          iconLeft="fal-trash"
          @click.stop="openConfirmDeleteModal"
        >
          Delete
        </ioio-button>
      </div>
    </div>


    <ioio-responsive-table selectableRows tableHeadID="headerScroll" tableBodyID="bodyScroll" v-if="initialVocabularies.length && vocabularies.length">
      <template slot="thead">
        <ioio-responsive-table-row  selectableRows >
          <ioio-responsive-table-cell
            typeOfCell="th-checkbox"
            checkboxSize="small"
            :isChecked.sync="checkAll"
            className="checkbox" >
            Select all
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="sm:min-w-28 w-1/6 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'name' }"
          >
            Name
            <ioio-icon
              @click="triggerSort('name')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'name' || selectedSortByOption !== 'name'" />
            <ioio-icon
              @click="triggerSort('name')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="sm:min-w-20 w-24 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'lang' }"
          >
            Language
            <ioio-icon
              @click="triggerSort('lang')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'lang' || selectedSortByOption !== 'lang'" />
            <ioio-icon
              @click="triggerSort('lang')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="w-24 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'type' }">
            Type
            <ioio-icon
              @click="triggerSort('type')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'type' || selectedSortByOption !== 'type'" />
            <ioio-icon
              @click="triggerSort('type')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="sm:min-w-24 w-1/6 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'createdAt' }">
            Created date
            <ioio-icon
              @click="triggerSort('createdAt')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'createdAt' || selectedSortByOption !== 'createdAt'" />
            <ioio-icon
              @click="triggerSort('createdAt')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="sm:min-w-28 w-1/6 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'updatedAt' }">
            Last updated
            <ioio-icon
              @click="triggerSort('updatedAt')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'updatedAt' || selectedSortByOption !== 'updatedAt'" />
            <ioio-icon
              @click="triggerSort('updatedAt')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="th"
            className="w-20 items-center justify-between"
            :class="{ 'is-column-sorted-by': selectedSortByOption === 'default' }">
            Default
            <ioio-icon
              @click="triggerSort('default')"
              icon="fa-solid fa-angle-down"
              class="ml-1"
              v-if="selectedSortDirection === 'desc' && selectedSortByOption === 'default' || selectedSortByOption !== 'default'" />
            <ioio-icon
              @click="triggerSort('default')"
              icon="fa-solid fa-angle-up"
              class="ml-1"
              v-else />
          </ioio-responsive-table-cell>
        </ioio-responsive-table-row>
      </template>
      <template slot="tbody">
        <ioio-responsive-table-row
          v-for="(data, index) in vocabularies"
          :key="index"
          :class="rowClass(data)"
          >
          <ioio-responsive-table-cell
            typeOfCell="td-checkbox"
            checkboxSize="small"
            :isChecked.sync="data.check"
            className="checkbox"
            @onChecked="rowCheck(data)" />
          <ioio-responsive-table-cell
            ref="vocName"
            typeOfCell="td"
            tableHead="Name"
            className="sm:min-w-28 w-1/6 cursor-pointer"
          >
            <span v-if="data.name.length < 15" class="truncate"  @click.stop="openPreview(data)">{{ data.name }}</span>
            <ioio-tooltip
              v-else
              :text="data.name"
              initialPostion="right"
              class="w-full"

            >
              <span @click.stop="openPreview(data)" class="truncate block">{{ data.name }}</span>
            </ioio-tooltip>

          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="td"
            tableHead="Language"
            className="w-24"
          >
            {{ data.lang }}
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="td"
            tableHead="Type"
            className="w-24"
          >
            {{ data.type }}
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="td"
            tableHead="Created date"
            className="w-1/6"
          >
            <ioio-tooltip
              :text="new Date(data.createdAt).toLocaleString()"
              initialPostion="left"
            >
              {{ new Date(data.createdAt).toLocaleDateString() }}
            </ioio-tooltip>

          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="td"
            tableHead="Last update"
            className="w-1/6"
          >
            <ioio-tooltip
              :text="new Date(data.updatedAt).toLocaleString()"
              initialPostion="left"
            >
              {{ new Date(data.updatedAt).toLocaleDateString() }}
            </ioio-tooltip>
          </ioio-responsive-table-cell>
          <ioio-responsive-table-cell
            typeOfCell="td"
            tableHead="Default"
            className="w-20"
          >
            {{ data.default ? 'yes' : 'no'}}
          </ioio-responsive-table-cell>
        </ioio-responsive-table-row>
      </template>
    </ioio-responsive-table>

    <h3 v-else-if="!initialVocabularies.length" class="text-sm">No vocabularies has been saved.</h3>
    <h3 v-else-if="initialVocabularies.length && !vocabularies.length" class="text-sm">No vocabularies has been found with this these criteria.</h3>
  </section>

  <div v-else class="relative min-h-36">
    <ioio-loader isShown />
  </div>

</div>
</template>

<script>
import { downloadCSV } from "@/utils/helpers";
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {

  data() {

    return {
      isVocabulariesDataUpdating: false,
      checkAll: false,
      defaultData: [],
      vocabularies: [],
      selectedSortByOption: 'updatedAt',
      selectedSortDirection: 'desc',
      searchText: '',
      selectedLanguage: '',
      possibleLanguages: [
        {name: 'All'}
      ],
      allPossibleLanguages: [],
      initialVocabularies: [],
      selectedVoc: {},
      selectedToDeleteVoc: [],
      isEditVocabulariesModalOpened: false,
      isConfirmModalOpened: false,
      isVocabularyUpdating: false,
      editType: 'preview',
      confirmType: '',
      isActionsOpened: false,
      countSelected: 0,
    }
  },
  props: {},
  created() {

    this.getOrganizationsSettings();
  },
  mounted() {

  },
  methods: {

    downloadCsvTemplate() {

      let props = 'phrase,displayAs,ipa,soundsLike'

      downloadCSV(props, 'template.csv')
    },

    openConfirmModal() {
      this.confirmType = 'default';
      this.selectedVoc = this.vocabularies.find(voc => voc.check === true);
      this.isConfirmModalOpened = true;
    },

    editVoc() {
      this.isVocabulariesDataUpdating = true;
      this.selectedVoc = this.vocabularies.find(voc => voc.check === true);
      const payload = this.selectedVoc.guid;
      this.getSingleVocabulary(payload)
      .then((response) => {
        this.selectedVoc = response;
      })
      .finally(() => {
        this.isVocabulariesDataUpdating = false;
        this.isEditVocabulariesModalOpened = true;
        this.editType = 'edit';
      })
    },
    addVoc() {
      this.selectedVoc = {
        type : 'normal',
        lang: 'en-US'
      };
      this.isEditVocabulariesModalOpened = true;
      this.editType = 'new';
      this.checkAll = false;
    },

    deleteVoc () {
      this.isConfirmModalOpened = false;
      this.isVocabulariesDataUpdating = true;
      this.selectedVoc = this.selectedToDeleteVoc.shift();

      if (this.selectedVoc.default) {

        this.setDefaultVoc(true);
      }
      const payload = this.selectedVoc.guid;
      this.deleteSingleVocabulary(payload)
      .then(() => {
        if(this.selectedToDeleteVoc.length) {
          this.deleteVoc();
        } else {
          this.getOrganizationsSettings();
        }
      })

    },
    openConfirmDeleteModal() {
      this.confirmType = 'delete';
      this.selectedToDeleteVoc = this.vocabularies.filter(voc => voc.check === true);
      this.isConfirmModalOpened = true;
    },

    toggleEditModalOpened(value) {

      this.isEditVocabulariesModalOpened = value;
    },

    searchByName() {

      this.vocabularies = this.initialVocabularies.filter(voc => voc.name.includes(this.searchText))
    },

    searchByLang() {

      if (this.selectedLanguage === 'All') {

        this.vocabularies = this.initialVocabularies
      } else {

        this.vocabularies = this.initialVocabularies.filter(voc => voc.lang === this.selectedLanguage)
      };

    },
    rowCheck(data) {
      if (data.check) {

        this.countSelected++ ;
        this.isActionsOpened = true;

      } else {

        this.countSelected-- ;

        if (this.countSelected === 0) {
          this.isActionsOpened = false;
        }
      }
    },
    openPreview(voc) {

      this.isVocabulariesDataUpdating = true;
      this.selectedVoc = voc;
      const payload = this.selectedVoc.guid;
      this.getSingleVocabulary(payload)
      .then((response) => {
        this.selectedVoc = response;
      })
      .finally(() => {
        this.isVocabulariesDataUpdating = false;
        this.isEditVocabulariesModalOpened = true;
        this.editType = 'preview';
      })

    },
    getOrganizationsSettings() {

      this.possibleLanguages = [{name: 'All'}];
      this.allPossibleLanguages = [];
      this.isActionsOpened = false;
      this.countSelected = 0;

      const requests = [

        this.getBrokerSettings(''),
        this.getBrokerVocabularies()
      ];

      this.isVocabulariesDataUpdating = true;

      Promise.all(requests).then(responses => {

        const settings = responses[0];
        this.vocabularies = responses[1];

        this.vocabularies.forEach(voc => {
          voc.check = false;
          voc.default = false;
          if (this.possibleLanguages.find(lang => lang.name === voc.lang) == undefined) {
            this.possibleLanguages.push( {name : voc.lang})
          }

        })

        this.initialVocabularies = this.vocabularies;
        this.sortVocabularies();

        this.defaultData = settings.reduce((acc,item) => {
          acc[item.guid] = item.value;
          if (item.guid === 'transcribe-vocabularies' ) {
            for (const lang in item.value) {
              let newlang = {name: lang}
              this.allPossibleLanguages.push(newlang);
              if (item.value[lang]) {
                let index = this.vocabularies.findIndex(voc => voc.guid === item.value[lang]);
                this.vocabularies[index].default = true;
              };
            };
          };
          if (item.guid === 'medical-transcribe-vocabularies' && item.value['en-US']) {
            let index = this.vocabularies.findIndex(voc => voc.guid === item.value['en-US']);
            this.vocabularies[index].default = true;
          }
          return acc;
        },{} );

      })
      .finally(() => {

        this.isVocabulariesDataUpdating = false;

      });


    },
    triggerSort(option) {

      const oppositeDirection = this.selectedSortDirection === 'asc' ? 'desc' : 'asc';

      // The filtered column has not changed, but the direction has
      if (option === this.selectedSortByOption) {

        this.selectedSortDirection = oppositeDirection;

      } else {

        // The filter column has changed => enter default mode for the sorting
        this.selectedSortDirection = 'desc';
      }

      this.selectedSortByOption = option;

      this.sortVocabularies();
    },
    sortVocabularies() {

      if (this.selectedSortByOption != 'updatedAt' && this.selectedSortByOption != 'createdAt') {

        this.vocabularies.sort((a,b) => this.selectedSortDirection === 'desc'
        ? a[this.selectedSortByOption].localeCompare(b[this.selectedSortByOption])
        : b[this.selectedSortByOption].localeCompare(a[this.selectedSortByOption]))
      } else {

        this.vocabularies.sort((a,b) => this.selectedSortDirection === 'desc'
        ? b[this.selectedSortByOption] - a[this.selectedSortByOption]
        : a[this.selectedSortByOption] - b[this.selectedSortByOption])
      }

    },
    rowClass(data) {
      let rowClass ='';

      data.default ?  rowClass = 'text-black-900' : rowClass = 'text-black-400';
      data.check ?  rowClass += ' bg-blue-50':  rowClass += ' bg-white';

      return rowClass;
    },
    setDefaultVoc(deleteDefault = false){

      this.isConfirmModalOpened = false;
      this.isVocabulariesDataUpdating = true;
      let params = '';
      let updateVal = {};

      if (this.selectedVoc.type === 'normal') {
        updateVal = this.defaultData['transcribe-vocabularies'];
        params = 'transcribe-vocabularies';
      } else {
        updateVal = this.defaultData['medical-transcribe-vocabularies'];
        params = 'medical-transcribe-vocabularies';
      }

      deleteDefault ? updateVal[this.selectedVoc.lang] = '' : updateVal[this.selectedVoc.lang] = this.selectedVoc.guid;

      this.updateBrokerSettings({
        params,

        data: {
          value: updateVal
        }
      })
      .then(() => {

        this.$toasted.success('Your changes were saved successfully');

        deleteDefault && this.confirmType === 'delete' ? null : this.getOrganizationsSettings();
      });


    },

    createVocabulary(addedPhrases){

      this.isVocabularyUpdating = true;

      const name = this.selectedVoc.name;
      const type = this.selectedVoc.type;
      const lang = this.selectedVoc.lang;

      let words = [];
      for (const key in addedPhrases) {

        addedPhrases[key].forEach(word => {
          delete word.checked;
          delete word.validate;
          words.push(word);
        });
      };
      let payload = {};
      let request = '';
      let answer = ''
      if (this.editType === 'new') {
        request = 'createBrokerVocabulary';
        payload = {
          name,
          type,
          lang,
          words
        };
        answer = 'Your new vocabulary was created successfully';
      }  else if  (this.editType === 'edit') {
        request = 'updateBrokerVocabulary';
        payload.data = {
          name,
          words
        };
        payload.guid = this.selectedVoc.guid;
        answer = 'Your changes were saved successfully';
      }
      this[request](payload)
        .then((response) => {
          this.$toasted.success(answer);
        })
        .finally(() => {

          this.isEditVocabulariesModalOpened = false
          this.isVocabularyUpdating = false;
          this.getOrganizationsSettings();
        });

    },
    ...mapMutations({

    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings",
      getBrokerVocabularies: "channelManager/getBrokerVocabularies",
      createBrokerVocabulary: "channelManager/createBrokerVocabulary",
      updateBrokerVocabulary: "channelManager/updateBrokerVocabulary",
      getSingleVocabulary: "channelManager/getSingleVocabulary",
      deleteSingleVocabulary: "channelManager/deleteSingleVocabulary",
    })
  },
  computed: {

    confirmHeaderText() {
      return this.confirmType === 'delete'
        ? 'Deleting custom vocabularies'
        : this.selectedVoc.default
          ? `Are you sure you want to unset ${this.selectedVoc.name} to not being default!`
          : `Are you sure you want to apply ${this.selectedVoc.name} as the default vocabulary for your organisation?`

    },

    isDefault() {
      let selectedVoc = this.vocabularies.find(voc => voc.check === true);
      return this.countSelected === 1 ? selectedVoc.default : false;
    },

    ...mapGetters({

    })
  },
  watch: {
    checkAll(val) {
      this.vocabularies.forEach((x) => (x.check = val));
      if (val) {
        this.isActionsOpened = true;
        this.countSelected = this.vocabularies.length;
      } else {
        this.isActionsOpened = false;
        this.countSelected = 0;
      }
    },
  },
}
</script>

<style lang="scss">

  .custom-vocabularies-settings-component {
    .ds-r-table__td, .ds-r-table__th, .phrases{

      .ds-checkbox [type="checkbox"]:not(:checked) + label.is-small:before,
      .ds-checkbox [type="checkbox"]:checked + label.is-small:before {

        margin-top: 0.5rem;
      }

      .ds-checkbox [type="checkbox"]:not(:checked) + label.is-small:after,
      .ds-checkbox [type="checkbox"]:checked + label.is-small:after {

        top: calc(50% + 4px);
      }

    }

    .ds-r-table__body {
      overflow-y: scroll;
      max-height: 25rem;
    }

    @media (min-width: 1530px) {
      .ds-r-table__tr .ds-r-table__td, .ds-r-table__th {
        @apply
        w-1/6;
        &.checkbox {
          @apply
          w-8;
        }
      }
    }


    .ds-r-table__td {
      color: inherit;
    }

    .ds-r-table__header {

      z-index: 15;
    }
    .is-column-sorted-by {

      @apply
      text-black-900;
    }
    .ds-fields__input.is-small,
    .ds-fields__textarea.is-small {
      min-height: 2rem;
    }

    .active-buttons {
      .ds-btn.is-small {

        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .ds-btn__icon-left {

        margin-right: 0.25rem;
      }
    }


    .ds-tooltip__el {
      z-index: 5;
    }
    .ds-dropdown__menu {
      z-index: 30;
    }
    .border-yellow {
      &.ds-btn.is-secondary.is-tonal {
        background-color: #fffde7;
        color: #f9a825;
      }
      &.ds-btn.is-secondary.is-tonal:hover {
        background-color: #fff59d;
      }
    }

  }

</style>
