/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ioio_play': {
    width: 16,
    height: 18,
    viewBox: '0 0 28 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M0 4.385L9.41 8.88l4.142-2.277L2.077.178a1.394 1.394 0 00-1.9.542A1.407 1.407 0 000 1.402v2.983z" _fill="#828282"/><path pid="1" d="M3.952 16.658l11.351-6.607a1.405 1.405 0 00.037-2.404l-1.977-1.233-9.41 5.718v4.526z" _fill="#E0E0E0"/><path pid="2" d="M3.942 16.658V6.224L0 4.327v12.27a1.4 1.4 0 002.137 1.19l1.805-1.129z" _fill="#BDBDBD"/><path pid="3" opacity=".25" d="M13.363 6.413L9.411 8.878 7.529 7.93s.843.181 2.635-.285a15.74 15.74 0 003.2-1.232zM4.001 16.658v-4.553l1.27-.758a3.11 3.11 0 00-.753 1.517c-.255 1.063-.517 3.794-.517 3.794zM3.952 6.224L0 4.327s1.977 1.376 2.823 2.086a3.856 3.856 0 011.13 1.708V6.224z" _fill="#010101"/></g><defs><clipPath id="clip0"><path pid="4" _fill="#fff" d="M0 0h16v18H0z"/></clipPath></defs>'
  }
})
