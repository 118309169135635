/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'launch': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" data-color="color-2" d="M24 0H13l4.793 4.793-6.5 6.5 1.414 1.414 6.5-6.5L24 11z"/><path pid="1" d="M19 24H1a1 1 0 01-1-1V5a1 1 0 011-1h9v2H2v16h16v-8h2v9a1 1 0 01-1 1z"/></g>'
  }
})
