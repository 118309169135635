/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'c-warning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm1.645 5L13 14h-2l-.608-9h3.253zM12 20a2 2 0 11-.001-3.999A2 2 0 0112 20z" _fill="#5c7499"/>'
  }
})
