<template>
<section class="schedule-channel-pause-modal-component">

  <ioio-button
    v-if="isDialogOpenBtnVisible"
    :type="dialogOpenBtnVariant"
    :size="dialogOpenBtnSize"
    class="dialog-open-btn"
    :class="dialogOpenBtnClass"
    @click.stop.prevent="() => openActionModal()"
  >{{ dialogOpenBtnLabel }}
  </ioio-button>

  <ioio-modal
    :show.sync="isModalOpened"
    size="small"
    externalClasses="schedule-channel-pause-modal-component">

    <template slot="header">

      <div class="">{{ dialogHeaderLabel }}</div>

    </template>

    <section class="flex flex-column h-full">

      <section class="flex flex-column flex-auto">
        <div class="my-8">
          <ioio-field
            class=""
            v-model="selectedDateTime"
            type="datetime"
            required
            placeholder="Select Date Time"
            label="Select Date Time"
            topic="selectedDateTime"
            size="medium"
            v-if="isModalOpened"
            :minimum="currentDate"
          ></ioio-field>
        </div>
        <section class="flex-none">

          <h1 class="text-lg mt-8 mb-4">{{ actionConfirmLabel }}</h1>
          <h2 class="text-md text-black-400 mb-4" v-if="actionConfirmSecondaryLabel">{{ actionConfirmSecondaryLabel }}</h2>

          <logo-loader-component width="50px" height="50px" v-if="isLoadingIndicatorVisible"/>
        </section>

      </section>
      <footer class="flex items-center justify-end mt-8">


        <ioio-button
          @click.stop="closeActionModal"
          class="mr-4"
          type="secondary"
          variant="outline"
        >Cancel</ioio-button>

        <ioio-button
          type="primary"
          topic="selectedDateTime"
          @click.stop="confirmAction"
        >{{ actionConfirmBtnLabel }}</ioio-button>

      </footer>
    </section>
  </ioio-modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

const currentDate = new Date().toISOString();

export default {
  data: () => ({

    isModalOpened: false,
    selectedDateTime: '',
    currentDate: currentDate
  }),
  props: {

    // NOTE: Needed when there are more the one modals on a page!
    // If there are more than one modals with the same name on a given page,
    // they will all open up at once, leading to unexpected behavior.
    dialogId: {
      // type: String,
      default: '1',
    },

    dialogHeaderLabel: String,

    dialogOpenBtnLabel: {
      type: String,
      default: 'Action',
    },

    dialogOpenBtnVariant: {
      type: String,
      default: 'default',
    },

    dialogOpenBtnSize: {
      type: String,
      default: 'size-xs',
    },

    dialogWidth: {
      type: String,
      default: '500px',
    },

    dialogHeight: {
      type: String,
      default: 'auto',
    },

    dialogOpenBtnClass: {
      type: String
    },

    isDialogOpenBtnVisible: {
      type: Boolean
    },

    actionConfirmLabel: {

      type: String,
      default: 'Are you sure you want to action?'
    },

    actionConfirmSecondaryLabel: {

      type: String
    },

    actionConfirmBtnLabel: {
      type: String,
      default: 'Confirm',
    },

    actionConfirmBtnVariant: {
      type: String,
      default: 'default',
    },

    isActionConfirmBtnVisible: {
      type: Boolean
    },

    onActionConfirm: {
      type: Function
    },

    onActionReject: {
      type: Function
    },

    isLoadingIndicatorVisible: {

      type: Boolean,
      default: false
    },

    selectedWorkflow: {
      type: Object
    }
  },
  mounted() {
    window.asdd = this;
  },
  methods: {

    openActionModal() {

      this.isModalOpened = true;
    },

    closeActionModal() {

      this.onActionReject && this.onActionReject();
      this.isModalOpened = false;
    },

    confirmAction() {

      this.isModalOpened = false;
      this.onActionConfirm && this.onActionConfirm({
        pauseRequestTime: this.selectedDateTime,
        id: this.selectedWorkflow.id
      });
    }
  }
}
</script>

<style lang="scss">



.schedule-channel-pause-modal-component {

  .ds-modal__dialog {

    min-height: 600px;
  }

  /* Needed so that it aligns with adjascent elements outside the component */
  .dialog-open-btn {

    float: left;
  }

}

</style>
