<template>
  <button
    class="btn"
    :class="[variant, size, shape, intent]"
    :type="type"
    :intent="intent"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  props: {
    variant: {
      type: String,
      required: false,
      default: "default"
    },
    size: {
      type: String,
      required: false,
      default: "size-s"
    },
    shape: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      required: false,
      default: ""
    },
    intent: {
      type: String,
      required: false,
      default: ""
    }
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  }
};
</script>

<style lang="scss" scoped>

/* Reset the default browser btn styles */
button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btn {
  --primaryColor: var(--primarySurface);
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 4px;
  height: auto;
  vertical-align: middle;
  -webkit-appearance: none;
  z-index: 1;
  transition: 0.125s ease-in-out;
  &.circle {
    border-radius: 50%;
  }
}

.btn:hover {
  text-decoration: none;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

// Sizes

.btn.size-xs {
  height: 1.5rem;
  font-size: 0.75rem;
  padding: 0 0.5rem;
  &.circle {
    width: 1.5rem;
    padding: 0;
  }
}

.btn.size-s {
  height: 1.75rem;
  font-size: 0.75rem;
  padding: 0 0.625rem;
  &.circle {
    width: 1.75rem;
    padding: 0;
  }
}

.btn.size-m {
  height: 2.25rem;
  font-size: 0.875rem;
  padding: 0 0.875rem;
  &.circle {
    width: 2.25rem;
    padding: 0;
  }
}

.btn.size-l {
  height: 3rem;
  font-size: 1.25rem;
  padding: 0 1.25rem;
  &.circle {
    width: 3rem;
    padding: 0;
  }
}

// Variants

.btn.default {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
    0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: var(--controlBgColor);
  color: var(--controlColor);
}
.btn.default:hover {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1),
    0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.btn.default.active {
  background: var(--selectedBgColor);
  color: var(--selectedColor);
}

.btn.primary {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
    0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.075) inset;
  background: var(--primarySurface);
  color: white;
}
.btn.primary:hover {
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1),
    0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.075) inset;
  background: var(--controlHoverColor);
}

.btn.tab {
  border-radius: 0;
  min-height: 3rem;
  min-width: calc(100% / 3);
  margin: 0;
  padding: 0 1rem;
  box-shadow: 0 1px 0 0 var(--highlightColor),
    0 -1px 0 0 var(--highlightColor) inset;
  border-right: 1px solid;
  border-color: var(--sceneBrColor);
}
.btn.tab.active {
  box-shadow: 0 1px 0 0 var(--selectedBgColor),
    0 -1px 0 0 var(--selectedBgColor) inset;
  background: var(--windowBgColor);
  color: var(--windowColor);
}
.btn.tab.disabled {
  background: var(--disabledBgColor);
  color: var(--disabledColor) !important;
  box-shadow: 0 1px 0 0 var(--disabledColor),
    0 -1px 0 0 var(--disabledColor) inset;
  border-radius: 0;
  min-height: 3rem;
}
.btn.tab:last-of-type {
  border-right-width: 0;
}

.btn.subtle {
  box-shadow: none;
  background: var(--subtleBgColor);
  color: var(--subtleColor);
}
.btn.subtle:hover,
.btn.subtle.active {
  box-shadow: none;
  background: var(--selectedBgColor);
  color: var(--selectedColor);
}
.btn.minimal {
  color: inherit;
  opacity: 0.7;
}

.btn.minimal:hover {
  background: rgba(43, 45, 80, 0.1);
  opacity: 1;
}
.btn.minimal.active {
  opacity: 1;
  box-shadow: none;
  background: var(--selectedBgColor);
  color: var(--selectedColor);
}

.disabled {
  background: var(--disabledBgColor) !important;
  color: var(--disabledColor) !important;
  pointer-events: none;
  opacity: 0.5;
}

// intents
.btn.primary.success {
  background: var(--color-green-500);
  &:hover {
    background: var(--color-green-700);
  }
}
.btn.primary.danger {
  color: white;
  background: var(--color-red-500);
  &:hover {
    background: var(--color-red-700);
  }
}

.btn.subtle.danger {
  color: var(--color-red-700);
  background: var(--color-red-50);
  &:hover {
    background: var(--color-red-700);
    color: white;
  }
}

.btn.primary.warning {
  color: white;
  background: var(--color-orange-500);
  &:hover {
    background: var(--color-orange-700);
  }
}

.btn.success {
  color: white;
  background: var(--color-green-500);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.075) inset,
    0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
  &:hover {
    background: var(--color-green-700);
  }
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}
</style>
