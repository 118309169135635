/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'small-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15a.997.997 0 01-.707-.293L6.586 10 8 8.586l4 4 4-4L17.414 10l-4.707 4.707A.997.997 0 0112 15z" _fill="#5c7499"/>'
  }
})
