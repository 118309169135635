<template>
<div class="vod-list-filters-component flex flex-column items-start">
  <div class="flex items-center">

    <select
      class="status-select"
      v-model="filterByTypeOptionSelected"
      required
      name="type"
      @change="event => setFilterByVideoType(event.target.value)">

        <option
          v-for="(btn, index) in filterByTypeBtnConfig" :key="index"
          :value="btn.value"
        >{{ btn.label }}</option>
    </select>

    <select
      class="status-select"
      v-model="filterByStatusOptionSelected"
      required
      name="status"
      @change="event => setFilterByVideoStatus(event.target.value)">

        <option
          v-for="(btn, index) in filterByStatusBtnConfig" :key="index"
          :value="btn.value"
        >{{ btn.label }}</option>
    </select>

    <!-- <select
      class="status-select"
      v-model="filterByPrivacyOptionSelected"
      required
      name="privacy"
      @change="event => setFilterByVideoPrivacy(event.target.value)">

        <option
          v-for="(btn, index) in filterByPrivacyConfig" :key="index"
          :value="btn.value"
        >{{ btn.label }}</option>
    </select> -->
  </div>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    filterByTypeBtnConfig: [
      {
        value: "",
        label: "All"
      },
      {
        value: "ad",
        label: "Ads"
      },
      {
        value: "source",
        label: "Video"
      }
    ],

    filterByStatusBtnConfig: [
      {
        value: "content",
        label: "Content"
      },
      {
        value: "",
        label: "All"
      },
      {
        value: "complete",
        label: "Active"
      },
      {
        value: "archive",
        label: "Archive"
      },
      {
        value: "error",
        label: "Error"
      },
      {
        value: "encode-later",
        label: "Encode later"
      }
    ],

    filterByPrivacyConfig: [
      {
        value: "",
        label: "All"
      },
      {
        value: "privacy",
        label: "Private"
      },
      {
        value: "public",
        label: "Public"
      }
    ],

    filterByPrivacyOptionSelected: '',
    filterByTypeOptionSelected: '',
    filterByStatusOptionSelected: "content",
  }),
  props: {
    applyFiltering: Function
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    }),

    setFilterByVideoType(newVal) {

      this.filterByTypeOptionSelected = newVal;

      this.applyFiltering({

        searchOpts: {

          vodType: newVal,
          pageNum: 1, // The pageNum should be 1 every time the vodType changes
        },

        filterType: 'vodLibrary'
      });
    },


    setFilterByVideoStatus(newVal) {

      this.filterByStatusOptionSelected = newVal;

      this.applyFiltering({

        searchOpts: {

          vodStatus: newVal,
          pageNum: 1, // The pageNum should be 1 every time the vodType changes
        },

        filterType: 'vodLibrary'
      });
    },

    setFilterByVideoPrivacy(newVal) {

      this.filterByPrivacyOptionSelected = newVal;

      this.applyFiltering({

        searchOpts: {

          vodPrivacy: newVal,
          pageNum: 1, // The pageNum should be 1 every time the vodType changes
        },

        filterType: 'vodLibrary'
      });
    },
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>


select {
  height: 35px;
  min-height: auto;
  font-size: 12px;
  width: 74px;
  margin: 0;
  border: none;
  border-right: 1px solid var(--color-gray-400);
}

.status-select {
  padding-left: 4px;
  border-radius: 4px;
  width: fit-content;
}
</style>
