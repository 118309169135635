<template>
<section class="augmented-vod-meta-component">

  <ioio-button
    type="primary"
    size="small"
    @click.stop.prevent="isModalVisible = true">Add Meta</ioio-button>

  <ioio-modal
    class="augmented-meta-modal"
    :show.sync="isModalVisible"
    size="large"
    :noScroll="false"
  >

    <template slot="header">Add Meta</template>

    <!-- Privacy && Featured -->
    <section class="flex my-4">

      <ioio-checkbox
        size="small"
        disclaimer="Only visible to current user"
        class="mr-2"
        v-model="privacy"
        @input="(newVal) => privacy = newVal"
      >Private
      </ioio-checkbox>

      <ioio-checkbox
        size="small"
        class="mr-2"
        disclaimer="This video will appear as featured in other applications"
        v-model="featured"
        @input="(newVal) => featured = newVal"
      >Featured
      </ioio-checkbox>

      <ioio-checkbox
        size="small"
        disclaimer="This video will appear in video portal results"
        v-model="listedInPortals"
        @input="(newVal) => listedInPortals = newVal"
      >List in Video Portals
      </ioio-checkbox>
    </section>

    <!-- Tags && Categories -->
    <section class="flex mb-4">

      <ioio-tags

        size="small"
        label="Tags"
        class="mr-4"
        v-model="tags"
        :tags="tags"
      />

      <ioio-tags

        size="small"
        label="Categories"
        class=""
        v-model="categories"
        :tags="categories"
      />
    </section>

    <!-- Hubs -->
    <section class="mb-4" v-if="combinedSiteBuilderHubs.length">
      <div class="ratio1-1 mb-4">
        <span class="block mb1">Hubs</span>
        <ioio-dropdown
          v-model="shortlistedHubs"
          :items="combinedSiteBuilderHubs"
          :multiple="true"
          placeholder=""
          titleProp="name"
          valueProp="id"
          :limit=4
          class="augmented-meta-multiselect"
          @select="onHubShortlisted"
          @remove="onHubRemoved"
         />
      </div>

      <ioio-table class="border-0 shadow-none">
        <template slot="thead">
          <tr class="w-full grid grid-cols-3">
            <th class="text-left text-base text-black-400 font-normal">Hub</th>
            <th class="text-left text-base text-black-400 font-normal">
              Internal
            </th>
            <th class="text-left text-base text-black-400 font-normal">
              External
            </th>
          </tr>
        </template>
        <template slot="tbody">
          <tr class="w-full grid grid-cols-3 items-center"
            v-for="(hub) in shortlistedHubs"
            :key="hub.id">
            <td class="text-base text-black-900">{{ hub.name }}</td>

            <td class="text-base text-black-800 font-normal flex items-center">
              <ioio-checkbox
                size="medium"
                class="mr-4"
                v-model="intExtHubMap[hub.id].internal"
                @input="(newVal) => toggleHubIntExtValue(hub, 'internal', newVal)"
              >
              </ioio-checkbox>
            </td>

            <td class="text-base text-black-800 font-normal flex items-center">
              <ioio-checkbox
                size="medium"
                v-model="intExtHubMap[hub.id].external"
                @input="(newVal) => toggleHubIntExtValue(hub, 'external', newVal)"
              >
              </ioio-checkbox>
            </td>
          </tr>

          <tr v-if="!shortlistedHubs.length">No Hubs selected.</tr>
        </template>
      </ioio-table>
    </section>

    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          @click.stop.prevent="isModalVisible = false">Done</ioio-button>
      </div>
    </template>
  </ioio-modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isModalVisible: false,

    shortlistedHubs: [],
    intExtHubMap: {},

    // Props for the BE meta model bellow
    privacy: false, //bool false by default, // checkbox from DS static for upload - editable in edit Vod meta page

    featured: false, //bool false by default // checkbox from DS static for upload - editable in edit Vod meta page

    listedInPortals: false,

    // publishing: "", //published, in-review, rejected MUST CHANGE KEY // Dont add for now

    categories: [], //needed for spotlights // tagComponent from DS static for upload - editable in edit Vod meta page

    tags: [], // tagComponent from DS static for upload - editable in edit Vod meta page
  }),

  props: {

    // Some inputs should only be visible to the user when
    // meta is edited, and not when a new VOD is uploaded
    isMetaInEditMode: '',

    originalMeta: {

      type: Object,

      default() {

        return {};
      }
    }
  },

  mounted() {
    window.q = this;
  },

  methods: {

    onHubShortlisted(hub) {

      this.intExtHubMap[hub.id] = {

        name: hub.name,
        internal: false,
        external: false
      };
    },

    onHubRemoved(hub) {

      delete this.intExtHubMap[hub.id];
    },

    toggleHubIntExtValue(hub, division, newVal) {

      this.intExtHubMap[hub.id] = {

        ...this.intExtHubMap[hub.id],
        [division]: newVal
      };
    },

    getUserData() {

      // The VOD already has userData
      if (this.originalMeta.user) {

        return this.originalMeta.user;
      }

      // For old VODs, that do not have such data
      if (this.isMetaInEditMode) {

        return {};
      }

      // A new VOD is created, add the user's data
      return this.userData;
    },

    getOriginData() {

      // The VOD already has origin
      if (this.originalMeta.origin) {

        return this.originalMeta.origin;
      }

      // For old VODs, that do not have such data
      if (this.isMetaInEditMode) {

        return {};
      }

      // A new VOD is created, add the user's data
      return {

        type: this.appName,
        appVersion: this.appVersion,
        userAgent: window.navigator.userAgent, // any versioning of the origination
        source: "upload", //recording, import, upload
      };
    },

    populateInitialData() {

      if (!this.isMetaInEditMode) {

        return;
      }

      this.tags = this.originalMeta.tags || [];

      this.categories = this.originalMeta.categories || [];

      this.privacy = this.originalMeta.privacy || false;

      this.featured = this.originalMeta.featured || false;

      this.listedInPortals = this.originalMeta.listedInPortals || false;

      // Null the values
      this.shortlistedHubs = [];
      this.intExtHubMap = {};

      const hubs = this.originalMeta.hubs || [];

      if (hubs.length) {

        hubs.forEach(h => {

          this.onHubShortlisted(h);

          for (let i = 0; i < h.permissions.length; i++) {

            const permission = h.permissions[i];

            this.toggleHubIntExtValue(h, permission, true);
          }
        });

        this.shortlistedHubs = this.combinedSiteBuilderHubs.filter(h => this.intExtHubMap[h.id]);
      }
    },

    gatherMeta() {

      const hubsArr = [];
      const hubsIds = Object.keys(this.intExtHubMap);

      for (let i = 0; i < hubsIds.length; i++) {

        const currHub = this.intExtHubMap[hubsIds[i]];
        const currPermissions = [];

        currHub.internal && currPermissions.push('internal');
        currHub.external && currPermissions.push('external');

        hubsArr.push({

          id: hubsIds[i],
          name: currHub.name,
          permissions: currPermissions
        });
      }

      return {

        privacy: this.privacy,
        featured: this.featured,
        listedInPortals: this.listedInPortals,
        user: this.getUserData(),
        categories: this.categories,
        tags: this.tags,
        origin: this.getOriginData(),
        hubs: hubsArr
      };
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {

    ...mapGetters({

      appName: "app/appName",
      appVersion: "app/appVersion",

      userData: 'app/userData',
      siteBuilderApps: 'app/siteBuilderApps',
      organizationSelected: "app/organizationSelected",
      editedVodGuid: 'channelManager/editedVodGuid',
    }),

    combinedSiteBuilderHubs() {

      let hubs = [];

      this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          hubs = hubs.concat(a.hubs);
        }
      });

      return hubs;
    }
  },

  watch: {

    editedVodGuid() {

      if (!this.isMetaInEditMode) {

        return;
      }

      this.populateInitialData();
    },

    // This will handle possible race condition (the vod has loaded, but the hubs list has not)
    combinedSiteBuilderHubs(newVal, oldVal) {

      if (newVal.length !== oldVal.length) {

        this.populateInitialData();
      }
    }
  }
}
</script>

<style lang="scss">

.augmented-vod-meta-component {

  .ds-tags {

    background: white;
  }
}

/**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/
.augmented-meta-multiselect {
    width: 300px;

  .multiselect__option .multiselect__option--highlight .multiselect__option--selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    @apply text-blue-grey-800;
  }

  .multiselect__option.multiselect__option--highlight, .multiselect__option.multiselect__option--highlight:after {
    @apply text-blue-grey-800;

    /* background: orange !important; */
  }
  .multiselect__tag {

    margin-top: -3px;
    @apply text-blue-grey-800;
  }
  .multiselect__select {
    height: 18px;
  }
  .multiselect__tag-icon {

    line-height: 18px;
    margin-top: 2px;
  }

  .multiselect__tag-icon:after {

    content: none;
  }

  .multiselect__single {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .multiselect__content-wrapper {

    background-color: var(--color-blue-50);
  }
}
</style>
