/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10 17a1 1 0 01-.707-.293L4.586 12 6 10.586l4 4 8-8L19.414 8l-8.707 8.707A1 1 0 0110 17z" _fill="#5c7499"/>'
  }
})
