<template>
<div class="channel-settings-component">

  <button-component variant="minimal" shape="circle" @click="() => toggleModalOpened(true)">
    <ioio-icon icon="fas-ellipsis" class="h-6 w-6"/>
  </button-component>


  <modal
    name="channel-settings-modal"
    width="650"
    height="760"
    classes="v--modal p2 channel-settings-modal"
  >
    <div class="flex flex-column flex-auto h100 pt2">

      <header class="ratio1-1 flex justify-between">

        <h1 class="m0 size+1 mb2 px2">
          Channel Settings
        </h1>
        <button-component class="" variant="minimal" shape="circle" @click.stop.prevent="() => toggleModalOpened(false)">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
        </button-component>

      </header>

      <vue-form :state="formState" @submit.prevent class="flex flex-column mt1 px2 flex-auto">

        <validate class="" tag="label">
          <span class="">Name <span>*</span></span>
          <input
            class="input"
            type="text"
            id="name"
            v-model="channelName"
            name="channelName"
            placeholder="Name"
            maxlen="100"
            required/>
          <field-messages name="channelName" show="$submitted || $dirty && $touched">
            <div slot="required">Name is a required field</div>
            <div slot="maxlen">Name length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <validate class="" tag="label">
          <span class="">Description <span>*</span></span>
          <input
            class="input"
            type="text"
            id="channelDescription"
            v-model="channelDescription"
            maxlen="500"
            name="channelDescription"
            placeholder="Description"
            required/>
          <field-messages name="channelDescription" show="$submitted || $dirty && $touched">
            <div slot="required">Description is a required field</div>
            <div slot="maxlen">Description length should not be more than 500 characters</div>

          </field-messages>
        </validate>

        <div>
          <label for="type">
            Type
            <!--<span>*</span>-->
          </label>

          <select
            class="select"
            id="type"
            v-model="channelTypeSelected"
            disabled
            name="channelType">
            <option
              :key="item.value"
              :value="item.value"
              v-for="item in channelTypes"
              >{{ item.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="type">
            CDN
            <!--<span>*</span>-->
          </label>

          <select
            class="select"
            id="cdnGuid"
            v-model="cdnGuid"
            name="cdnGuid">

            <option :value="undefined"
            >Default CDN</option>

            <option
              :key="item.guid"
              :value="item.guid"
              v-for="item in availableCdns"
              >{{ item.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="isActive">
            Status
            <span>*</span>
          </label>

          <div>
            <label>
              <input type="radio" name="status" v-model="isActive" id="isActive" value="1">
              Active
            </label>
          </div>

          <div>
            <label>
              <input type="radio" name="status" v-model="isActive" id="isInactive" value="0">
              Inactive
            </label>
          </div>
        </div>

        <div>
          <label for="image-url">Image url</label>

          <input class="input" type="text" id="image-url" v-model="imageUrl" name="imageUrl" placeholder="Image url">
        </div>

        <div v-if="isExternalHLSChannelTypeSelected">

          <validate class="" tag="label">
            <span class="">External URL <span class="text-danger">*</span></span>
            <input
              type="url"
              id="externalUrl"
              placeholder="External URL"
              required
              v-model="externalUrl"
              name="externalUrl"/>
            <field-messages name="externalUrl" show="$submitted || $dirty && $touched">
              <div slot="required">External URL is a required field</div>                <div slot="url">External URL should be a valid URL</div>
            </field-messages>
          </validate>
        </div>

        <div v-if="isVirtualChannelTypeSelected">
          <label for="loopInterval">Loop interval (hours)</label>

          <select class="select" name="loopInterval" id="loopInterval" v-model="loopOptionSelected">
            <option v-for="option in loopOptions" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <div>
          <label for="hasExternalAds">
            Has external ads?
            <span>*</span>
          </label>

          <div>
            <label>
              <input
              type="radio"
              name="hasExternalAds"
              id="hasExternalAds"
              v-model="hasExternalAds"
              value="1"
              checked="checked"
              >
              Yes
            </label>
          </div>

          <div>
            <label>
              <input
              type="radio"
              name="hasExternalAds"
              id="hasNotExternalAds"
              v-model="hasExternalAds"
              value="0"
              >
              No
            </label>
          </div>
        </div>

        <div v-if="isHasExternalAdsSelected">


          <label for="mediatailors">Mediatailor</label>

          <validate class="" tag="label">
            <div class="flex items-center">
            <select
              class="select size-s m0 mr2"
              name="mediatailors"
              id="mediatailors"
              v-model="mediatailorSelected"
              required>
              <option v-for="mtailor in mediatailors" :value="mtailor.guid">{{ mtailor.name }}</option>
            </select>
          </div>
            <field-messages name="mediatailors" show="$submitted || $dirty && $touched">
              <div slot="required">Mediatailor is a required field</div>
            </field-messages>
          </validate>

        </div>

        <custom-meta-fields-legacy-component
          class="pt2 mt3 border-top"
          :editedAsset="channelMeta"
          metaResourceType="channel" />


        <channel-subs-lang-selector-component
          class="pt2 mt3 border-top"
          :subtitlesData="channelSubsLangData"
          small
          ref="subsLangSelectorEdit" />


        <ioio-dropdown
          :items="possibleTimezones"
          v-model="channelTimezone"
          valueProp="value"
          titleProp="label"
          required
          fullWidth
          label="Timezone"
          placeholder="Timezone"
          size="medium"
          class="w-full mb-8"
        />

      </vue-form>
      <footer class="flex justify-end mt2 mb1" v-if="!isEditInProgress && !isChannelDeleteInProgress">

        <button-component @click="getFormValues" variant="primary" size="size-s" class="mr2"
          :class="isEditInProgress || (formState.$submitted && formState.$invalid) ? 'disabled' : ''">Save</button-component>

        <delete-confirm-modal-component

          v-if="!isChannelDeleteInProgress"
          dialogWidth="500"
          dialogHeight="225"
          dialogOpenBtnLabel="Delete"
          dialogOpenBtnSize="size-s"
          :isDialogOpenBtnVisible="true"
          delConfirmBtnLabel="Delete"
          :delConfirmLabel="`Are you sure you want to delete channel ${channelsMeta.selected.name} ?`"
          :isDelConfirmBtnVisible="!isChannelDeleteInProgress"
          :onDeleteConfirm="() => triggerChannelDelete()"
        />

      </footer>
      <article v-else class="overflow-hidden">
        <logo-loader-component width="40px" height="22px" />
      </article>
    </div>
  </modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getEventsPossibleTimezones } from "@utils/helpers";

export default {
  data: () => ({


    formState: {},

    channelName: '',
    channelDescription: '',
    channelTypeSelected: '',
    isActive: '',
    imageUrl: '',
    externalUrl: '',
    loopOptionSelected: '',
    hasExternalAds: '',
    mediatailorSelected: '',
    channelMeta: {},
    channelSubsLangData: {},
    cdnGuid: '',
    channelTimezone: '',

    channelTypes: [
      {
        label: "Media Live",

        value: "mediaLive"
      },

      {
        label: "External HLS",

        value: "externalHLS"
      },

      {
        label: "Virtual",

        value: "virtual"
      }
    ],

    loopOptions: [
      {
        label: "Without loop",

        value: 0
      },

      {
        label: "1",

        value: 1
      },

      {
        label: "3",

        value: 3
      },

      {
        label: "6",

        value: 6
      },

      {
        label: "12",

        value: 12
      },

      {
        label: "24",

        value: 24
      }
    ],

    prompts: [],

    isEditInProgress: false,

    isChannelDeleteInProgress: false,

    availableCdns: [],

    possibleTimezones: []
  }),

  created() {

    this.makeGetMediatailorsRequest();

    this.getCDNs()
      .then(cdns => {

        this.availableCdns = cdns;
      });
  },

  mounted() {

    this.loadInitialEditValues();

    this.possibleTimezones = getEventsPossibleTimezones();
  },
  props: {},
  methods: {

    toggleModalOpened(newVal) {

      const actionType = newVal ? 'show' : 'hide';

      this.$modal[actionType]('channel-settings-modal');
    },

    triggerChannelDelete() {

      this.isChannelDeleteInProgress = true;

      const cachedForDelChannelName = this.channelsMeta.selected.name;

      this.makeDeleteChannelRequest({ guid: this.channelsMeta.selected.guid })
        .then(() => {

          this.$toasted.success(`Channel ${ this.$options.filters.truncate(cachedForDelChannelName, 64, "...")} is successfully deleted.`);

          /**
           * Update the query param with the selected channel guid,
           * since it will be used and loaded if a page reload occurs
           */
          this.$router.replace({

            path: this.$route.fullPath,
            query: {
              'channel-guid': this.channelsMeta.selected.guid
            }
          });

          this.toggleModalOpened(false);
        })
        .finally(() => {

          this.isChannelDeleteInProgress = false;
        });
    },

    getFormValues() {

      if(this.formState.$invalid) {
        this.formState._submit();
        return;
      }

      const params = {
        hasAds: +this.hasExternalAds,

        imageUrl: this.imageUrl,

        name: this.channelName,

        description: this.channelDescription,

        active: +this.isActive,

        cdnGuid: this.cdnGuid || '',

        timezone: this.channelTimezone
      };

      if (this.isExternalHLSChannelTypeSelected) {
        params.externalUrl = this.externalUrl;
      }

      if (this.isVirtualChannelTypeSelected) {

        params.loopInterval = +this.loopOptionSelected;
      }

      if (this.isHasExternalAdsSelected) {
        params.mediatailor = this.mediatailorSelected;
      }

      // Check if custom meta was added to the channel
      Object.keys(this.channelMeta).length && (params.meta = this.channelMeta);

      // Check if subtitles data was added to the channel
      const subtitles  = this.$refs.subsLangSelectorEdit.gatherSubsData();

      params.subtitles = subtitles

      this.isEditInProgress = true;

      this.makeEditChannelRequest({
        guid: this.channelsMeta.selected.guid,
        params
      })
        .then(() => {

          this.$toasted.success('Your changes were saved successfully');
          this.toggleModalOpened(false);
        })
        .finally(() => {

          this.isEditInProgress = false;
        });
    },

    loadInitialEditValues() {

      const channelData = this.channelsMeta.selected;

      this.channelName = channelData.name;
      this.channelDescription = channelData.description;
      this.channelTypeSelected = channelData.type;
      this.isActive = channelData.active;
      this.imageUrl = channelData.imageUrl;
      this.externalUrl = channelData.externalUrl || '';
      this.loopOptionSelected = channelData.loopInterval || 0;
      this.hasExternalAds = `${channelData.hasAds}`;
      this.mediatailorSelected = channelData.mediatailor || '';
      this.channelMeta = channelData.meta || {};
      this.channelSubsLangData = channelData.subtitles || {};
      this.cdnGuid = channelData.cdnGuid;
      channelData.timezone ? this.channelTimezone = channelData.timezone : this.channelTimezone = '';
    },

    ...mapMutations({}),
    ...mapActions({

      makeEditChannelRequest: "channelManager/makeEditChannelRequest",
      makeDeleteChannelRequest: "channelManager/makeDeleteChannelRequest",
      makeGetMediatailorsRequest: "channelManager/makeGetMediatailorsRequest",
      getCDNs: "channelManager/getCDNs",
    }),
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      mediatailors: "channelManager/mediatailors",
    }),

    isExternalHLSChannelTypeSelected() {
      return this.channelTypeSelected === "externalHLS";
    },

    isHasExternalAdsSelected() {
      // The value is a string per BE

      return this.hasExternalAds === "1";
    },

    isVirtualChannelTypeSelected() {
      return this.channelTypeSelected === "virtual";
    },
  },

  watch: {
    channelsMeta() {

      console.log(this.channelsMeta.selected);

      this.loadInitialEditValues();
    },
  }
};
</script>

<style lang="scss">



.channel-settings-modal {

  form {

    overflow: auto !important;
  }
}
</style>
