<template>
  <!-- <svg viewBox="0 0 223 99" xmlns="http://www.w3.org/2000/svg" class="mx-auto ratio1-1" :width="width" :height="height"> -->
  <svg :width="width" :height="height" viewBox="0 0 75 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.875 25.2593C46.7851 30.158 52.1159 34 58.5937 34C67.6383 34 75 26.3716 75 17C75 7.62824 67.6383 0 58.5937 0C52.0687 0 46.7073 3.89823 41.7663 8.84722L46.2248 11.3331C50.0163 7.78594 53.9059 5.2891 58.2626 5.2891C64.7404 5.34468 69.9476 10.7463 69.893 17.3536C69.8389 23.8822 64.6631 29.1616 58.2626 29.2167C53.794 29.2167 49.8169 26.5915 45.935 22.901L41.875 25.2593ZM24.5563 31.9016C24.5532 31.856 24.5516 31.8095 24.5516 31.763V26.4098C21.9962 28.1447 19.3433 29.2146 16.4917 29.2146C10.0139 29.159 4.80674 23.7577 4.86127 17.1505C4.91535 10.6215 10.091 5.3426 16.4917 5.28743C19.3437 5.28743 21.9962 6.35761 24.5516 8.09246V2.74072C24.5512 2.53781 24.5807 2.33564 24.6391 2.14163C22.105 0.759282 19.28 0.0247012 16.4063 0C7.36162 0 0 7.62824 0 17C0 26.3716 7.36162 34 16.4063 34C19.2484 33.9767 22.0434 33.2571 24.5563 31.9016Z" fill="#D9D9D9"/>
<path d="M27.4062 10.9037L39.7014 16.8699L45.1112 13.8477L30.1201 5.31807C29.2398 4.81732 28.1281 5.13941 27.6372 6.0374C27.4858 6.31442 27.4062 6.62632 27.4062 6.94361V10.9037V10.9037Z" fill="#0077FF"/>
<path d="M32.5703 27.1955L47.4006 18.4249C48.2727 17.9094 48.5697 16.7704 48.0643 15.8814C47.9142 15.6171 47.7029 15.3949 47.4487 15.2339L44.8652 13.5957L32.5703 21.1873V27.1955V27.1955Z" fill="#FAC000"/>
<path d="M32.5566 27.1963V13.3455L27.4063 10.8262V27.1159C27.4059 28.1435 28.2225 28.9777 29.2306 28.9777C29.5726 28.9777 29.9077 28.8798 30.1978 28.6955L32.5566 27.1963Z" fill="#EC4C47"/>
<path d="M44.8627 13.5957L39.6987 16.8682L37.2402 15.609C37.2402 15.609 38.3421 15.85 40.6826 15.2311C42.1304 14.8425 43.5317 14.2942 44.8627 13.5957Z" fill="#0A67D2"/>
<path opacity="0.25" d="M32.6309 27.1953V21.1506L34.2904 20.1445C33.7856 20.7099 33.4451 21.408 33.3067 22.1592C32.9735 23.5695 32.6309 27.1953 32.6309 27.1953Z" fill="#8E6F08"/>
<path opacity="0.25" d="M32.57 13.3455L27.4062 10.8262C27.4062 10.8262 29.9895 12.6536 31.095 13.5962C31.7782 14.2021 32.2881 14.9853 32.5704 15.8627L32.57 13.3455Z" fill="#9C322F"/>
</svg>

</template>

<script>
export default {
  data: () => ({}),
  props: {
    width: {
      type: String,
      required: false,
      default: "100%"
    },
    height: {
      type: Number | String,
      required: false,
      default: "100%"
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
