import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";

import components from "./components";
import genericComponents from "./genericComponents";

// NOTE: this import is needed until the SVGIcons library is deprecated
import "./assets/FOR_DEPRECATION_SVGIcons";

import store from "./store";
import router from "./router";

import "isomorphic-fetch";

import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";

import { Container, Draggable } from "vue-smooth-dnd";

import Vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import VModal from "vue-js-modal/dist/ssr.index";
import "vue-js-modal/dist/styles.css";

import VTooltip from "v-tooltip";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.min.css";

import Toasted from "vue-toasted";

import FullCalendar from "@fullcalendar/vue";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import VueApexCharts from "vue-apexcharts";

import SvgIcon from "vue-svgicon";

import VueForm from "vue-form";

import Datetime from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import isCidr from "is-cidr";

import { Cropper } from "vue-advanced-cropper";

import veeno from "veeno";
import "nouislider/distribute/nouislider.min.css";

import VueGoogleCharts from "vue-google-charts";

import VueSocialSharing from "vue-social-sharing";

import ioioFrameVue from "@ioiotv/ioio-frame-vue";

import ToastSanitizeWrapper from "@basePath/plugins/toast-sanitize-wrapper.js";

import {
  isRoutePermitted,
  isPermissionGranted,
} from "@basePath/utils/routesManager";

import {
  DEFAULT_AUTH_PATH,
  DEFAULT_UNAUTH_PATH,
  DEFAULT_ADMIN_PATH,
} from "@basePath/configs/routesAuth.config";

/**
 * Import the styleberry library last, so that it's
 * styles won't become overwritten by other libs
 */
import "styleberry/dist/styleberry.css"; //libs_for_deprecation

import ioioKitVue from "@ioiotv/ioio-kit-vue";

//Import base kit css
import "@basePath/tailwind.css";

/**
 * Setup `$auth` mixin, to be used inline in the DOM for ACL.
 * Should be used together with `v-if`.  When the authorize
 * check fails, it returns `false` and the `v-if` handles the rest.
 *
 * Note: initially the `$auth` mixin was intented to be a directive,
 * but a directive can't prevent firing of lifecycle hooks
 * if it was used on a component, thus possibly triggering API calls.
 *
 * Also note: The mixin definition is intentionally before the
 * registration of components, since otherwise it isn't always
 * available on time.
 */
Vue.mixin({
  methods: {
    $auth(requiredPermissions) {
      /**
       * This check is needed, since there are places (v-for in templates),
       * that will automatically call the mixin, sometimes with no value.
       */
      if (!requiredPermissions) {
        return true;
      }

      const getters = this.$store.getters;

      const userPermissions = getters["app/userPermissions"];

      return isPermissionGranted(requiredPermissions, userPermissions);
    },

    $pushRoute(route) {
      router
        .push(route)
        .then((r) => {
          if (r.query.createNew) {
            // replace withour the ?createNew query, so that the route remains
            // clean and will accept future possible createNew events
            router
              .replace({
                ...router.currentRoute,
                query: {
                  createNew: undefined,
                },
              })
              .catch((e) => {}); // expecting NavigationDuplicated error => ignore
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (err.name !== "NavigationDuplicated") {
            // But print any other errors to the console
            throw err;
          }
        });
    },
  },
});

/**
 * Register vue-smooth-dnd components
 */
Vue.component("DraggableHolderComponent", Container);
Vue.component("DraggableComponent", Draggable);

Vue.use(VueSocialSharing);

/**
 * Register the SVG components library
 */
Vue.use(SvgIcon, {
  tagName: "svgIcon",
});

Vue.use(VModal);

Vue.use(VTooltip);

Vue.use(Datetime);

Vue.use(VueForm, {
  // Register custom validators here, if needed
  validators: {
    /**
     * Since the native max-length validator is buggy, use this custom one
     */
    maxlen: function (value, attrValue, vnode) {
      return value.length <= +attrValue;
    },

    json: function (value, attrValue, vnode) {
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    },

    /**
     * The logic bellow compares a `compareProp` against all inputs,
     * that are provided in `attrValue`.
     */
    duplicateinput: function (value, attrValue, vnode) {
      const { fields, compareProp, fieldIndex } = attrValue;

      for (let i = 0; i < fields.length; i++) {
        // don't check against the current field
        if (i === fieldIndex) {
          continue;
        }

        if (fields[i][compareProp] === value) {
          return false;
        }
      }

      return true;
    },

    reservedwordsinput: function (value, attrValue, vnode) {
      const reservedWords = attrValue;

      for (const key of reservedWords) {
        if (key === value) {
          return false;
        }
      }

      return true;
    },

    cidr: function (value, attrValue, vnode) {
      return isCidr(value);
    },

    integer: function (value, attrValue, vnode) {
      return Number.isInteger(+value);
    },
  },

  formClasses: {
    dirty: "vf-form-dirty",
    pristine: "vf-form-pristine",
    valid: "vf-form-valid",
    invalid: "vf-form-invalid",
    touched: "vf-form-touched",
    untouched: "vf-form-untouched",
    focused: "vf-form-focused",
    submitted: "vf-form-submitted",
    pending: "vf-form-pending",
  },
  validateClasses: {
    dirty: "vf-field-dirty",
    pristine: "vf-field-pristine",
    valid: "vf-field-valid",
    invalid: "vf-field-invalid",
    touched: "vf-field-touched",
    untouched: "vf-field-untouched",
    focused: "vf-field-focused",
    submitted: "vf-field-submitted",
    pending: "vf-field-pending",
  },
  inputClasses: {
    dirty: "vf-dirty",
    pristine: "vf-pristine",
    valid: "vf-valid",
    invalid: "vf-invalid",
    touched: "vf-touched",
    untouched: "vf-untouched",
    focused: "vf-focused",
    submitted: "vf-submitted",
    pending: "vf-pending",
  },
});

/** Global Filters Start */
Vue.filter("truncate", function (text, length, suffix) {
  if (text && text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});
/** Global Filters End */

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.component("FullCalendar", FullCalendar);

/**
 * Add Toasted options in this object
 */
Vue.use(Toasted, {
  position: "bottom-right",
  duration: 8000,
});

// NOTE: ToastSanitizeWrapper will modify the original vue-toasted
// plugin methods in order to sanitize them and prevent SSX attacks.
// Continue using the vue-toasted in a normal way
Vue.use(ToastSanitizeWrapper);

Vue.component("VueDropzone", Vue2Dropzone);
Vue.component("VueSlider", VueSlider);
Vue.component("Swatches", VSwatches);


Vue.component("Cropper", Cropper);
Vue.component("veeno", veeno);

Vue.use(VueGoogleCharts);

/**
 * Register global components && genericComponents
 */

for (let componentName in components) {
  Vue.component(componentName, components[componentName]);
}

for (let genericComponentName in genericComponents) {
  Vue.component(genericComponentName, genericComponents[genericComponentName]);
}

Vue.component("ioioFrameVue", ioioFrameVue);
Vue.use(ioioKitVue);
ioioKitVue.setStore(store);

const app = new Vue({
  store,
  router,
  render: (h) => h(App),

  created() {
    let params = null;

    if (
      window.location.host.indexOf("cloud") === -1 &&
      window.location.host.indexOf("localhost") === -1
    ) {
      // NOTE: to bypass the top check (if the hostname should be hardcoded on localhost for debugging) use this if instead of the top one
      // if (true) {

      params = {
        slug: window.location.hostname.split(".")[0],

        // NOTE: to bypass the top check (if the hostname should be hardcoded on localhost for debugging) use this slug instead of the top one
        // slug: 'tcvetomir'.split('.')[0]
      };
    }

    this.$store.dispatch("app/getCognitoSettings", params).then((config) => {
      const currentDomain = window.location.origin;

      const defaultLoginPage = "/login";

      const redirectSignOutUrl = `${currentDomain}${defaultLoginPage}`;

      const oauthSettings = config.oauth || {};

      Amplify.configure({
        Auth: {
          ...config,

          oauth: {
            ...oauthSettings,

            redirectSignIn: defaultLoginPage,
            redirectSignOut: redirectSignOutUrl,
          },
        },
      });

      if (router.currentRoute.hash.indexOf("access_token") === -1) {
        // original init logic
        Amplify.Auth.currentSession()
          .then((sessionDetails) => {
            const token = sessionDetails.idToken.jwtToken;

            initApp(token);
          })
          .catch((authErr) => {
            console.error(
              "Comming from local $Amplify object - authErr on init: ",
              authErr
            );

            if (router.currentRoute.hash) {
              let trimmedHash = router.currentRoute.hash;

              trimmedHash = trimmedHash.replace("#", "");

              const hashParams = {};

              trimmedHash.split("&").forEach((param) => {
                hashParams[param.split("=")[0]] = decodeURIComponent(
                  param.split("=")[1]
                );
              });

              const errMsg = hashParams.error_description
                ? hashParams.error_description.replace(/\+/g, " ")
                : "Unhandled error.";

              Vue.toasted.error(errMsg);
            }

            initApp(null);

            const getters = this.$store.getters;
            const isAdminUser = getters["app/isAdminUser"];
            const isUserAuthenticated = getters["app/isUserAuthenticated"];
            const isOrganizationSelected = getters["app/organizationSelected"];
            const userPermissions = getters["app/userPermissions"];

            const userState = {
              isAdminUser,
              isUserAuthenticated,
              isOrganizationSelected,
              userPermissions,
            };

            if (!isRoutePermitted(router.currentRoute.path, userState)) {

              this.$store.commit("app/FORCE_LOGOUT");
            }
          });

        return;
      }

      // else redirect to login screen
      initApp(null);
      this.$store.commit("app/FORCE_LOGOUT");
    });

    /**
     * Register AWS Amplify library
     */
    Vue.use(AmplifyPlugin, AmplifyModules);

    const initApp = (token) => {
      this.$store.commit("app/INITIALISE_STORE", token);

      this.$store.commit("app/LOG_CURRENT_ROUTE", router.currentRoute);

      this.$store.commit("app/SET_DEPLOYMENT_CONSTANTS");

      /**
       * Make the initial check on page load. This will happen after <App/>
       * is mounted, so that we have access to browser API-s (localStorage, DOM...).
       * Once we have the state of the app, the logic in <Layout/> will decide what
       * will be rendered. If the route, that is accessed here, isn't allowed
       * for the current app state, redirect to one of the default auth/unauth routes.
       */
      const getters = this.$store.getters;

      const isAdminUser = getters["app/isAdminUser"];
      const isUserAuthenticated = getters["app/isUserAuthenticated"];
      const isOrganizationSelected = getters["app/organizationSelected"];
      const userPermissions = getters["app/userPermissions"];
      const orgGuid = getters["app/orgGuid"];

      const userState = {
        isAdminUser,
        isUserAuthenticated,
        isOrganizationSelected,
        userPermissions,
      };

      if (!isRoutePermitted(router.currentRoute.path, userState)) {

        /**
         * The user is rerouted to adequate route, based on auth and permissions.
         * Finish the initialisation process.
         */
        this.$store.commit("app/INITIALISE_STORE_READY");

        router.push({
          path: isUserAuthenticated
            ? isAdminUser
              ? DEFAULT_ADMIN_PATH
              : DEFAULT_AUTH_PATH
            : DEFAULT_UNAUTH_PATH,
        });
      } else {

        /**
         * The initial path's permissions are inline with the user's.
         * Finish the initialisation process.
         */
        this.$store.commit("app/INITIALISE_STORE_READY");
      }

      /**
       * Setup the Notification Socket connection
       * (only applicable for authenticated users)
       */
      if (isUserAuthenticated) {

        const socketParams =
          isAdminUser && isOrganizationSelected && isOrganizationSelected.guid
            ? {
                organization: isOrganizationSelected.guid,
              }
            : null;

        this.$store.dispatch("app/setupSocketChannel", socketParams);

        if (isOrganizationSelected) {
          this.$store.dispatch("app/getSiteBuilderApps", {
            guid: isOrganizationSelected.guid,
          });

          isPermissionGranted('distributors_list', userPermissions) ? this.$store.dispatch('ingest/getIngestDistributors') : null;
        }
      }
    };

    /**
     * Setup auth checks on following route changes:
     * Use the data in the store, to figure out if the user is
     * allowed to view certain pages in any given time, before
     * the page transition happens. The store get's initialized
     * with the proper data from localStorage on creation.
     */
    router.beforeEach((to, from, next) => {
      const getters = this.$store.getters;

      const isAdminUser = getters["app/isAdminUser"];
      const isUserAuthenticated = getters["app/isUserAuthenticated"];
      const isOrganizationSelected = getters["app/organizationSelected"];
      const userPermissions = getters["app/userPermissions"];
      const isActiveConfirmGuardSet = getters["app/isActiveConfirmGuardSet"];

      const userState = {
        isAdminUser,
        isUserAuthenticated,
        isOrganizationSelected,
        userPermissions,
      };

      /**
       * The route beeing accessed isn't allowed for the user, based on
       * the routePrerequisites. Redirect to a save place.
       */
      if (!isRoutePermitted(to.path, userState)) {
        const toPath = isUserAuthenticated
          ? isAdminUser
            ? DEFAULT_ADMIN_PATH
            : DEFAULT_AUTH_PATH
          : DEFAULT_UNAUTH_PATH;

        return next({
          path: toPath,
          query: {
            redirect: to.fullPath,
          },
        });
      }

      /**
       * Next route is allowed for the current app state.
       * Check for active redirect confirm guards, that
       * may be setup from an active component.
       */
      if (isActiveConfirmGuardSet) {
        this.$store.dispatch("app/setupRedirectConfirmGuard", {
          successFn: () => {
            next();

            this.$store.commit("app/RAISE_REDIRECT_FLAG", false);
          },
          rejFn: () => {
            next(false);

            this.$store.commit("app/RAISE_REDIRECT_FLAG", false);
          },
        });

        this.$store.commit("app/RAISE_REDIRECT_FLAG", true);
      } else {
        // No confirm guards set. Just go to next route
        next();
      }
    });

    router.afterEach((to, from, next) => {
      this.$store.commit("app/LOG_CURRENT_ROUTE", router.currentRoute);
    });
  },
}).$mount("#app");
