/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'player': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#444" d="M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V1a1 1 0 00-1-1zM13 21a2.99 2.99 0 01-2.816-2H3v-2h7.184A2.99 2.99 0 0113 15a3 3 0 110 6zm-5-7V4l8 5-8 5zm13 5h-3v-2h3v2z"/>'
  }
})
