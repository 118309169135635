/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'grid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#111"><rect pid="0" x="1" y="1" width="6" height="6" rx="1" ry="1"/><rect pid="1" x="1" y="9" width="6" height="6" rx="1" ry="1"/><rect pid="2" data-color="color-2" x="1" y="17" width="6" height="6" rx="1" ry="1"/><rect pid="3" x="9" y="1" width="6" height="6" rx="1" ry="1"/><rect pid="4" data-color="color-2" x="9" y="9" width="6" height="6" rx="1" ry="1"/><rect pid="5" x="9" y="17" width="6" height="6" rx="1" ry="1"/><rect pid="6" data-color="color-2" x="17" y="1" width="6" height="6" rx="1" ry="1"/><rect pid="7" x="17" y="9" width="6" height="6" rx="1" ry="1"/><rect pid="8" x="17" y="17" width="6" height="6" rx="1" ry="1"/></g>'
  }
})
