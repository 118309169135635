<template>
<section class="library-tab-edit-source-component">

  <ioio-modal
    @close="cancelGenerateUrl"
    class=""
    :show.sync="openGenerateUrlModal"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >

    <template slot="header">

      <h3 class="flex mb-8">Generate Signed HLS URL</h3>

    </template>

    <ioio-field
      type="text"
      topic="generateUrl"
      size="medium"
      label="IP address"
      placeholder="IP address"
      format="ip"
      v-model="signedUrlParams.ip" class="w-full mb-6"></ioio-field>

    <span class="flex mb-12 text-xs">The IP address that will access this HLS URL. If not entered the default IP address will be the requester's IP address</span>

    <ioio-field
      type="number"
      required
      topic="generateUrl"
      min="5"
      max="86400"
      size="medium"
      :hasClearBtn="false"
      label="Time in seconds"
      placeholder="Seconds"
      :maximum=86400
      :minimum=5
      v-model="signedUrlParams.seconds" class="w-full mb-6"></ioio-field>

    <span class="flex mb-8 text-xs">The amount of time in seconds between 5 and 86400 (24 hours) this URL can be accessed</span>


    <template slot="footer">
      <footer class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelGenerateUrl">Cancel</ioio-button>

        <ioio-button
          topic="generateUrl"
          @click.stop.prevent="generateUrl">Generate URL</ioio-button>

      </footer>
      <div class="relative min-h-36" v-else>
        <ioio-loader isShown />
      </div>
    </template>
  </ioio-modal>

  <header class="flex justify-between border-bottom pb2 mt1 mb2">

    <h1 class="m0 size+1">General</h1>
    <aside>

      <p class="size-3 m0">Created At: {{ moment.tz(editedVod.createdAt, localTimezone).format("MMM Do YYYY h:mma z Z") }}</p>
      <p class="size-3 m0">
        <span v-if="editedVod.encodedAt"
          >Encoded At: {{ moment.tz(editedVod.encodedAt, localTimezone).format("MMM Do YYYY h:mma z Z") }}
        </span>
      </p>
    </aside>
  </header>

  <section class="edit-form-section">

    <vue-form :state="formState" @submit.prevent class="flex flex-column">
      <section class="flex top-form-section">
        <div style="width:100%; maxWidth: 480px;">
          <video
            ref="previewVid"

            id="video-container"
            width="100%"
            controls
            :poster="editedVodSelectedThumb"
          ></video>

          <div class="flex justify-between" v-if="editedVodThumbs.length">
            <div class="width+2 pr2">
              <label for="meta['imageUrl']">Thumbnail</label>
              <div class="bg-gray+5">
                <div class="ratio-16-9">
                  <div
                    :style="{
                      background: `url(${editedVodSelectedThumb}) no-repeat center center / cover`
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="width+8">
              <label class="mb2">Select thumbnail</label>

              <thumb-selector-component
                :thumbs="this.editedVodThumbs"
                :onSelect="this.onThumbSelect"
              />
            </div>
          </div>
        </div>
        <section class="right-form-section flex-auto ml2">
          <div v-if="fullPreviewVodData.status === 'encode-later'" class="mb2">
            <h4>This video has not yet been encoded.</h4>
            <button-component
              @click="onReEncode(fullPreviewVodData)">Encode Now
            </button-component>
          </div>

          <div v-if="fullPreviewVodData.status === 'ingest' || fullPreviewVodData.status === 'encoding'" class="mb2">
            <h4>This video is encoding.</h4>

            <progress-component class="" style="width: 100%" :progress="ingestProgress" showText/>
          </div>
          <div class="flex">
            <validate class="ratio2-3 mr1" tag="label">
              <div class="mb1">Title <span class="text-danger">*</span></div>
              <input
                v-if="!editedVod.meta.title || editedVod.meta.title.length < textWidth*0.7"
                class="input"
                type="text"
                placeholder="Title"
                required
                name="title"
                v-model="editedVod.meta.title"
              />
              <ioio-tooltip v-else class="w-full" :text="editedVod.meta.title" initialPostion="top-center" :multiline="true">
                <input
                 class="input truncate"
                 type="text"
                 placeholder="Title"
                 required
                 name="title"
                 v-model="editedVod.meta.title"
                />
              </ioio-tooltip>
              <field-messages
                name="title"
                show="$submitted || $dirty && $touched"
              >
                <div slot="required">Title is a required field</div>
              </field-messages>
            </validate>

            <validate class="ratio1-3 ml1" tag="label">
              <div class="mb1">Type <span class="text-danger">*</span></div>

              <select
                class="select ratio1-1"
                id="meta['type']"
                v-model="editedVod.meta.type"
                required
                name="type"
              >
                <option
                  v-for="(option,i) in possibleVodTypes"
                  :key="i"
                  :value="option.value"
                  >{{ option.label }}</option
                >
              </select>

              <field-messages
                name="type"
                show="$submitted || $dirty && $touched"
              >
                <div slot="required">Type is a required field</div>
              </field-messages>
            </validate>
          </div>

          <validate class="" tag="label">
            <div class="mb1">Description</div>
            <input
              v-if="!editedVod.meta.description || editedVod.meta.description.length < textWidth*1.1"
              class="input"
              type="text"
              placeholder="Description"
              name="description"
              v-model="editedVod.meta.description"
            />
            <ioio-tooltip v-else class="w-full" :text="editedVod.meta.description" initialPostion="top-center" :multiline="true">
              <input
                class="input truncate"
                type="text"
                placeholder="Description"
                name="description"
                v-model="editedVod.meta.description"
              />
            </ioio-tooltip>
          </validate>

          <div class="flex mt1">
            <div class="ratio1-2 mr1">
              <validate class="" tag="label">
                <div class="mb1">Group</div>
                <input
                  v-if="!editedVod.meta.group || editedVod.meta.group.length < textWidth*0.5"
                  class="input"
                  type="text"
                  id="meta['group']"
                  name="group"
                  placeholder="Group"
                  v-model="editedVod.meta.group"
                />
                <ioio-tooltip v-else class="w-full" :text="editedVod.meta.group" initialPostion="top-center" :multiline="true">
                  <input
                    class="input truncate"
                    type="text"
                    id="meta['group']"
                    name="group"
                    placeholder="Group"
                    v-model="editedVod.meta.group"
                  />
                </ioio-tooltip>
              </validate>
            </div>
            <div class="ratio1-2 ml1">
              <validate class="" tag="label">
                <div class="mb1">Genre</div>
                <input
                  v-if="!editedVod.meta.genre || editedVod.meta.genre.length < textWidth*0.5"
                  class="input"
                  type="text"
                  id="meta['genre']"
                  placeholder="Genre"
                  name="genre"
                  v-model="editedVod.meta.genre"
                />
                <ioio-tooltip v-else class="w-full" :text="editedVod.meta.genre" initialPostion="top-center" :multiline="true">
                  <input
                    class="input truncate"
                    type="text"
                    id="meta['genre']"
                    placeholder="Genre"
                    name="genre"
                    v-model="editedVod.meta.genre"
                  />
                </ioio-tooltip>
              </validate>
            </div>

          </div>
          <div class="flex mt1">

            <div class="ratio1-2 mr2">
              <validate class="" tag="label">
                <div class="mb1">Image url (or select from the thumbnail picker)</div>
                <input
                  class="input"
                  type="text"
                  id="meta['imageUrl']"
                  placeholder="Image url"
                  name="imageUrl"
                  v-model="editedVod.meta.imageUrl"
                />
              </validate>
            </div>

            <div class="ratio1-2">
              <label class="mb1" for="start-date-time">Expires At
              </label>

              <section class="flex">
                <datetime
                  class="flex-auto"
                  v-model="expiresAt"
                  input-id="expiresDateTime"
                  type="datetime"
                  use12-hour
                  placeholder="Expires At"
                  format="DDD t ZZZZ"
                  name="expiresDateTime"
                ></datetime>
                <button-component
                  class="ml2"
                  variant="minimal"
                  shape="circle"
                  @click.stop.prevent="clearExpiresStamp">
                    <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
                </button-component>
              </section>
            </div>
          </div>
          <div class="flex mt1" v-if="!vodSettings['signed-urls']">
            <div class="flex-auto mr1">
              <validate class="" tag="label">

                <div class="mb1">Playback URL {{ previewVodDurationInMs }}</div>
                <input
                  class="input"
                  type="text"
                  placeholder="Playback URL"
                  name="hlsUrl"
                  disabled
                  v-model="fullPreviewVodData.hlsUrl"
                />
              </validate>
            </div>
            <div class="mt2 pt2">
              <span
                @click.stop.prevent="copyToClipboard('channel-url')">
                <ioio-icon
                  icon="fas-clone"
                  class="w-5 h-5 text-black-200"
                />
              </span>
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="channel-url"
                :value="fullPreviewVodData.hlsUrl">
            </div>
          </div>
          <div class="flex mt1" v-else-if="fullPreviewVodData.status != 'encode-later'" >
            <div class="flex-auto mr1">
              <validate class="" tag="label">

                <div class="mb1">{{fullPreviewVodData.hlsSignedUrl ? `Signed HLS URL ${previewVodDurationInMs}`: "No Signed HLS URL generated"}}</div>
                <input
                  class="input"
                  type="text"
                  placeholder="No Signed HLS URL"
                  name="hlsUrl"
                  disabled
                  v-model="fullPreviewVodData.hlsSignedUrl"
                />
              </validate>
            </div>
            <div class="mt-4 pt-4" v-if="fullPreviewVodData.hlsSignedUrl">
              <span
                @click.stop.prevent="copyToClipboard('channel-url')">
                <ioio-icon
                  icon="fas-clone"
                  class="w-5 h-5 text-black-200"
                />
              </span>
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="channel-url"
                :value="fullPreviewVodData.hlsSignedUrl">
            </div>
            <div class="mt-4 pt-3" v-else>
              <ioio-button
                type="primary"
                style="height:2.25rem"
                @click="openGenerateUrlModal = true">
                Generate URL
              </ioio-button>
            </div>
          </div>
          <div class="mb1" v-if="fullPreviewVodData.mp4Outputs">
            <span>MP4 URL</span>
            <div
              class="flex mt1"
              v-for="(outputs, index) in fullPreviewVodData.mp4Outputs"
              :key="index">

              <div class="flex-auto mr1">
                <validate class="" tag="label">
                  <input
                    class="input"
                    type="text"
                    placeholder="MP4"
                    name="MP4"
                    disabled
                    v-model="fullPreviewVodData.mp4Outputs[index]"
                  />
                </validate>
              </div>

              <div class="mt-025">
                <span
                  @click.stop.prevent="copyToClipboard(`${index}`)">
                  <ioio-icon
                    icon="fas-clone"
                    class="w-5 h-5 text-black-200"
                  />
                </span>
                <input
                  class="copy-to-clipboard-input"
                  type="text"
                  :id="index"
                  :value="fullPreviewVodData.mp4Outputs[index]">
              </div>
            </div>
          </div>

          <div class="flex mt1" v-if="fullPreviewVodData.dashUrl">
            <div class="flex-auto mr1">
              <validate class="" tag="label">

                <div class="mb1">Dash URL</div>
                <input
                  class="input"
                  type="text"
                  placeholder="Playback URL"
                  name="hlsUrl"
                  disabled
                  v-model="fullPreviewVodData.dashUrl"
                />
              </validate>
            </div>
            <div class="mt2 pt2">
              <span
                @click.stop.prevent="copyToClipboard('dash-url')">
                <ioio-icon
                  icon="fas-clone"
                  class="w-5 h-5 text-black-200"
                />
              </span>
              <input
                class="copy-to-clipboard-input"
                type="text"
                id="dash-url"
                :value="fullPreviewVodData.dashUrl">
            </div>
          </div>

        </section>
      </section>

      <section class="bottom-form-section">

        <custom-meta-fields-legacy-component
          :editedAsset="editedVod.meta"
          :onGroupChange="onGroupChange"
          metaResourceType="video" />

        <augmented-vod-meta-component
          isMetaInEditMode="true"
          :originalMeta="editedVod.meta"
          ref="augMetaComponent"
          class="augmented-vod-meta" />

          <!-- v-show="formState.$dirty || isThumbChanged() || isMetaGrpChanged || isExpiresAtChanged" -->
        <footer
          class="flex items-center justify-end mt2 mb2"
        >
          <!-- <button-component
            variant="default"
            size="size-m"
            class="mr1"
            @click="discardEditVodChanges"
            :class="isEditInProgress ? 'disabled' : ''"
            >Discard</button-component
          > -->
          <button-component
            type="submit"
            variant="primary"
            size="size-m"
            class=""
            @click="saveEditedVod"
            :class="isEditInProgress || formState.$invalid && formState.$submitted ? 'disabled' : ''"
            >Save</button-component
          >
        </footer>
      </section>
    </vue-form>
  </section>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Hls from "hls.js";

import moment from "moment-timezone";

import { getHHMMSSFromMS, copyElementInnerText, getLocalTimezone } from "@utils/helpers";

export default {
  data: () => ({

    localTimezone: '',
    moment: {},

    cachedPristineVodMetadata: null,

    isMetaGrpChanged: false,
    isExpiresAtChanged: false,
    expiresAt: '',

    formState: {},

    ingestProgress: 0,

    activeIngestProgressMsg: {},

    editedVodThumbs: [],

    hls: null,
    hlsjsConfig: {
      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },

    isEditInProgress: false,
    openGenerateUrlModal: false,
    signedUrlParams:{
      ip: '',
      seconds: 86400
    },
    isRequestPending: false
  }),
  props: {

    onVodEditDone: Function,
    onReEncode: Function,
    visibleSection: String,
  },

  created() {

    this.moment = moment;
    this.localTimezone = getLocalTimezone();
  },

  mounted() {

    window.ss = this;

    if (this.editedVodGuid) {

      this.refreshEditedVodState();
    }

    this.copyElementInnerText = copyElementInnerText;
  },
  methods: {

    ...mapMutations({

      updateEditedVod: "channelManager/UPDATE_EDITED_VOD",
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
    }),

    ...mapActions({

      getVodThumbs: "channelManager/makeGetVodThumbsRequest",
      makeEditVodMetadataRequest: "channelManager/makeEditVodMetadataRequest",
      getSignedHlsUrl: "channelManager/getSignedHlsUrl"
    }),

    clearExpiresStamp() {

      if (!this.expiresAt) {

        return;
      }

      const newMeta = {
        ...this.editedVod.meta
      };

      delete newMeta.expires;

      this.expiresAt = '';

      this.updateEditedVod({

        ...this.editedVod,

        meta: {

          ...newMeta
        }
      });

      this.isExpiresAtChanged = true;
    },

    onThumbSelect(thumb) {

      this.updateEditedVod({

        ...this.editedVod,

        meta: {

          ...this.editedVod.meta,
          imageUrl: thumb
        }
      });
    },

    onGroupChange(newGuid, oldGuid) {

      if (newGuid !== this.cachedPristineVodMetadata.selectedMetaGroupGuid) {

        this.isMetaGrpChanged = true;

      } else {

        this.isMetaGrpChanged = false;
      }
    },

    discardEditVodChanges() {

      this.updateEditedVod({

        ...this.editedVod,

        meta: {

          ...this.cachedPristineVodMetadata
        }
      });

      this.formState._reset();

      this.isMetaGrpChanged = false;

      if (this.editedVod.meta.expires) {

        this.expiresAt = new Date(this.editedVod.meta.expires).toISOString();

      } else {

        this.expiresAt = '';
      }

      this.isExpiresAtChanged = false;
    },

    generateUrl() {

      const params = {

        guid: this.editedVodGuid,
        query: {

          seconds: this.signedUrlParams.seconds
        }
      };

      if (this.signedUrlParams.ip !== '') {

        params.query.ip = this.signedUrlParams.ip;
      };

      this.isRequestPending = true;

      this.getSignedHlsUrl(params)
        .then((resp) =>{

          const hlsSignedUrl = resp['signed-url'];

          const singleSrcData = {...this.singleSourcePlayed};

          singleSrcData.hlsSignedUrl = hlsSignedUrl;

          this.setSingleSourcePlayed(singleSrcData);
        })
        .finally(() =>{

          this.isRequestPending = false;
          this.openGenerateUrlModal = false;
        })
    },
    cancelGenerateUrl() {

      this.openGenerateUrlModal = false;

      this.signedUrlParams.ip = '';
      this.signedUrlParams.seconds = 86400;
    },
    loadVidPlayback() {

      if (this.fullPreviewVodData.status === 'complete') {

        this.getVodThumbs(this.editedVodGuid).then(thumbs => {

          this.editedVodThumbs = thumbs;
        });
      }

      if (Hls.isSupported()) {

        let videoPlaybackUrl = null;

        if (this.vodSettings['signed-urls']) {

          videoPlaybackUrl = this.fullPreviewVodData.hlsUrlAdmin;
        } else {

          videoPlaybackUrl = this.fullPreviewVodData.hlsUrl;
        }


        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;

          if (this.fullPreviewVodData.status !== 'complete' &&
            this.fullPreviewVodData.status !== 'encode-later') {

            return;
          }
        }

        const video = this.$refs.previewVid;

        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        if (!videoPlaybackUrl) {

          return;
        }

        /**
         * Test if the videoPlaybackUrl is .mp4 instead of .m3u8
         */
        if (this.singleSourcePlayed && this.singleSourcePlayed.isMp4) {

          const source = document.createElement('source');

          source.src = videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);
          video.play();

          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });


        this.hls.loadSource(videoPlaybackUrl);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          this.hls.attachMedia(video);

          this.hls.media.muted = true;
          this.hls.media.play();
        });
      }
    },

    isThumbChanged() {

      if (!this.cachedPristineVodMetadata || !this.editedVod) {

        return false;
      }

      return this.cachedPristineVodMetadata.imageUrl !== this.editedVod.meta.imageUrl;
    },

    saveEditedVod() {

      if (this.formState.$invalid) {

        return;
      }

      const params = {

        ...this.editedVod.meta,
        ...this.$refs.augMetaComponent.gatherMeta()
      };

      if (this.expiresAt) {

        params.expires = new Date(this.expiresAt).getTime();

      } else {

        delete params.expires;
      }

      const payload = {

        params,
        vodGuid: this.editedVodGuid
      };

      this.isEditInProgress = true;

      this.makeEditVodMetadataRequest(payload)
        .then(() => {

          this.onVodEditDone();

          this.cachedPristineVodMetadata = {

            ...params
          };

          this.formState._reset();

          this.isMetaGrpChanged = false;

          this.isExpiresAtChanged = false;

          this.$toasted.success("Your changes were saved successfully");
        })
        .finally(() => {

          this.isEditInProgress = false;
        });
    },

    copyToClipboard(elementId) {

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    delayedLoadVidPlayback() {

      /**
        * A timeout is required, so that the video tag has time to render
        */
      setTimeout(() => {

        this.loadVidPlayback();
      }, 300)
    },

    refreshEditedVodState() {

      this.formState._reset();

      this.isMetaGrpChanged = false;

      this.editedVodThumbs = [];

      this.cachedPristineVodMetadata = {

        ...this.editedVod.meta
      };

      if (this.editedVod.meta.expires) {

        this.expiresAt = new Date(this.editedVod.meta.expires).toISOString();

      } else {

        this.expiresAt = '';
      }

      this.isExpiresAtChanged = false;

      const singleSrcData = {

        ...this.editedVod
      };

      if (this.editedVod.status !== 'complete') {

        singleSrcData.isMp4 = true;

        this.editedVod.sourceProxy &&
          (singleSrcData.hlsUrl = this.editedVod.sourceProxy.proxyUrl);
      }

      this.setSingleSourcePlayed(singleSrcData);

      this.loadVidPlayback();
    }
  },
  computed: {
    ...mapGetters({

      editedVod: 'channelManager/editedVod',
      editedVodGuid: 'channelManager/editedVodGuid',
      singleSourcePlayed: 'channelManager/singleSourcePlayed',
      possibleVodTypes: "channelManager/possibleVodTypes",
      msgBusMsgList: 'app/msgBusMsgList',
      vodSettings: 'channelManager/vodSettings'
    }),

    textWidth () {
      return window.innerWidth / 24 ;
    },

    isIngestDataForVisibleVod() {

      return this.activeIngestProgressMsg.guid === this.fullPreviewVodData.guid;
    },

    fullPreviewVodData() {

      return this.singleSourcePlayed || { status: 'complete' };
    },

    editedVodSelectedThumb() {

      return this.editedVod.meta.imageUrl;
    },

    previewVodDurationInMs() {

      return getHHMMSSFromMS(this.fullPreviewVodData.srcDuration);
    },
  },

  watch: {

    expiresAt(newVal, oldVal) {

      if (!this.cachedPristineVodMetadata || !this.editedVod) {

        this.isExpiresAtChanged = false;
        return;
      }

      if (!this.expiresAt && !this.cachedPristineVodMetadata.expires) {

        this.isExpiresAtChanged = false;
        return;
      }

      if (this.expiresAt && new Date(this.expiresAt).getTime() === this.cachedPristineVodMetadata.expires) {

        this.isExpiresAtChanged = false;
        return;
      }

      this.isExpiresAtChanged = true;
    },

    editedVodGuid() {

      this.refreshEditedVodState();
    },

    fullPreviewVodData(newVal, oldVal) {

      if (this.visibleSection !== 'editSource') {

        return;
      }

      if (newVal.guid === oldVal.guid) {

        if (newVal.status === 'complete' &&
          oldVal.status !== 'complete') {

          this.delayedLoadVidPlayback();

          return;
        }

        // Preview can only be done via proxy since the VOD is NOT encoded
        // The proxyUrl was received by the socket => use it for preview
        if (newVal.sourceProxy && newVal.sourceProxy.proxyUrl &&
          !(oldVal.sourceProxy && oldVal.sourceProxy.proxyUrl)) {

          this.setSingleSourcePlayed({

            ...this.fullPreviewVodData,
            isMp4: true,
            hlsUrl: newVal.sourceProxy.proxyUrl
          });

          this.delayedLoadVidPlayback();
        }
      }
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'vod_progress_change') {

        this.activeIngestProgressMsg = { ...newestMsg.data };

        if (this.isIngestDataForVisibleVod) {

          this.ingestProgress = newestMsg.data.mediaConvertJobProgress;
        }
      }
    }
  }
}
</script>

<style lang="scss">

.library-tab-edit-source-component {

  label {
    @apply
    font-medium;
  }

  .vdatetime-input {

    max-height: 36px !important;
    min-height: auto;
    padding: 0.5rem !important;
    background-color: #fafafa;
  }

  .bottom-form-section {

    position: relative;
  }

  .augmented-vod-meta {

    position: absolute;
    position: absolute;
    top: 2px;
    left: 275px;
  }
}

</style>
