<template>
<div class="playlists-tab-component px1 pt1 flex flex-column">

    <div class="flex items-center pb-2 border-bottom">

      <input-search-component :onSearchInput="handleInputSearch" size="small" class="border w-full"/>
    </div>

    <div class="pane-content flex-auto mt-2" style="overflow: auto" ref="libraryScrollArea">
      <ul class="list-reset m0" :class="listType" v-if="!isPlaylistListLoading">
          <DraggableHolderComponent
          group-name="library-playlist"
          :get-child-payload="getChildPayload"
          :should-accept-drop="shouldAcceptDrop"
          :get-ghost-parent="getGhostParent"
          drag-class="ghost-element"
          >
          <DraggableComponent v-for="(playlist, index) in filteredPlaylists" :key="playlist.guid">
              <li
                class="fileitem flex mb1"
                :class="{ selected: selectedPlaylistIdForEdit === playlist.guid }">
                  <div class="fileitem-info ml1 flex flex-column flex-auto">
                      <div class="flex items-center mb-025">
                          <tag-component size="size-s" class="">{{ parseDuration(playlist.duration) }}</tag-component>
                          <tag-component size="size-s ml1" class="">{{ playlist.sources.length }} items</tag-component>
                          <div class="fileitem-actions btn-group ml-auto">
                              <button-component size="size-xs" @click="deletePlaylist(index, playlist.guid)" :class="{ 'disabled': loadingGuids[playlist.guid] }" >
                                <ioio-icon
                                  icon="fas-trash-can"
                                  class="w-3.5 h-3.5"
                                />
                              </button-component>
                              <button-component size="size-xs" @click="onPlaylistEdit(playlist)" v-if="onPlaylistEdit">
                                <ioio-icon icon="fas-pen" class="w-3.5 h-3.5"/>
                              </button-component>
                          </div>
                      </div>
                      <div class="flex items-center">
                          <div class="bg-purple rounded-2 mr1" style="width: 1rem; height: 6px" :style="{ width: '1rem', height: '6px', background: playlist.color }"></div>
                          <h4
                          class="fileitem-title size-1 m0"
                          :class="{ pointer: onPlaylistEdit }"
                          @click="onPlaylistEdit && onPlaylistEdit(playlist)">{{ (playlist.name || playlist.title) | truncate(45, '...') }}</h4>
                      </div>
                      <h4 class="fileitem-title size-3 gray+1 italic">{{ playlist.description | truncate(64, '...') }}</h4>
                  </div>

              </li>
          </DraggableComponent>
      </DraggableHolderComponent>
      </ul>
        <div v-else class="h100 width+10 p2"><loader-component height="100%" spinner="logo" size="size-m"  /></div>

    </div>

    <div class="pane-footer mx2 py1 mt-2 border-top flex items-center justify-between flex-none">

      <pagination-component
        :page-selected="playlistsSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="playlistsSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    listType: "blocklist",

    dragOrigin: "library",

    loadingGuids: {},

    isPlaylistListLoading: false
  }),
  created() {

    this.getPlaylists()
      .then(() => {

        this.checkAndStagePlaylistForEdit();
      });
  },

  beforeDestroy() {

    this.unstageFromFiltering('playlistLibrary');
  },

  props: {

    onPlaylistEdit: {
      type: Function
    },

    selectedPlaylistIdForEdit: {
      type: String
    }
  },

  mounted() {

    window.li = this;
  },

  methods: {

    ...mapMutations({

    }),
    ...mapActions({

      makeGetPlaylistsRequest: "channelManager/makeGetPlaylistsRequest",
      makeDeletePlaylistRequest: "channelManager/makeDeletePlaylistRequest",

      // new filtering actions
      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
    }),

    getPlaylists() {

      this.isPlaylistListLoading = true;

      return this.makeGetPlaylistsRequest()
        .then(list => {

          this.stageForFiltering({
            items: list,
            division: 'playlistLibrary'
          });

          this.applyFiltering({

            filterType: 'playlistLibrary'
          });
        })
        .finally(() => {

          this.isPlaylistListLoading = false;
        });
    },

    deletePlaylist(removedIndex, guid) {

      if (this.loadingGuids[guid]) {

        return;
      }

      this.loadingGuids[guid] = true;

      const deletedPlaylistName = this.filteredPlaylists[removedIndex].name;

      this.makeDeletePlaylistRequest({ guid, removedIndex })
        .then(() => {

          this.$toasted.success(`${this.$options.filters.truncate(deletedPlaylistName, 64, "...")} was deleted successfully.`);

          if (this.onPlaylistEdit) {

            this.onPlaylistEdit(false);
          }


          this.getPlaylists();
        })
        .finally(() => {

          this.loadingGuids[guid] = false;
        });
    },

    getChildPayload(removedIndex) {

      console.log(this.filteredPlaylists[removedIndex])

      const playlistMeta = {

        ...this.filteredPlaylists[removedIndex],
        ...this.filteredPlaylists[removedIndex].meta
      };

      delete playlistMeta.meta;

      const parsedItem = {

        ...this.filteredPlaylists[removedIndex],

        meta: {

          ...playlistMeta
        },

        srcDuration: this.filteredPlaylists[removedIndex].duration
      };

      return {
        item: parsedItem,
        origin: this.dragOrigin,
        removedIndex
      };
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {
      return false;
    },

    getGhostParent() {
      return document.body;
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'playlistLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'playlistLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    parseDuration(ms) {
      return getHHMMSSFromMS(ms, 'returnFullHour');
    },

    checkAndStagePlaylistForEdit() {

      if (this.selectedPlaylistIdForEdit) {

        const matchedPlaylist =
          this.playlists.find(p => p.guid === this.selectedPlaylistIdForEdit);

        if (matchedPlaylist) {

          this.onPlaylistEdit(matchedPlaylist);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      playlists: "channelManager/playlists",
      filteredPlaylists: "filterAndPaginate/filteredPlaylists",
      filteredItemsCount: "filterAndPaginate/filteredPlaylistsItemsCount",
      playlistsSearchOptions: "filterAndPaginate/playlistsSearchOptions",
    })
  },

  watch: {

    playlists() {

      this.stageForFiltering({
        items: this.playlists,
        division: 'playlistLibrary'
      });

      this.applyFiltering({

        filterType: 'playlistLibrary'
      });
    },
  }
};
</script>

<style lang="scss">


.playlists-tab-component {

  .library-component {
    background: var(--windowBgColor);
    color: var(--windowColor);
  }

  .pane-header {
    background: var(--sceneBgColor);
  }

  .fileitem {
    display: flex;
    margin-bottom: 2px;
    border-radius: 0;
    padding: 0.5rem;
    position: relative;

    .fileitem-actions {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      display: none;
      align-items: center;
      margin-top: auto;
    }
    &:hover {
      background: var(--highlightBgColor);
      .fileitem-actions {
        display: flex;
      }
      .tag {
         background: var(--windowBgColor);
      }
    }
    .fileitem-title {
      word-wrap: break-word;
      margin: 0;
      // margin-bottom: 0.25rem;
    }
    .fileitem-type {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
    }
    .fileitem-duration {
      position: absolute;
      bottom: 0.25rem;
      right: 0.25rem;
    }

    &.selected {

      border: 1px solid;
    }
  }

  .list {
    .fileitem {
      border-bottom: 1px solid #eee;
      border-radius: 0;
      margin: 0;
      padding: 0.5rem 0;
      position: relative;
      .fileitem-image,
      .tag {
        display: none;
      }
      .fileitem-info {
        width: 100%;
        display: flex;
      }
      .fileitem-duration {
        order: 1;
        margin-right: 0.5rem;
      }
      .fileitem-title {

        margin-right: auto;
      }
      .fileitem-type {
        display: inline-flex;
        order: 3;
      }
    }
  }
}
// the element that is being dragged
.ghost-element {
  width: 25rem;
  border-radius: 4px;
  overflow: visible;
  background: white;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--highlightColor) !important;
  padding: .5rem;
}
</style>
