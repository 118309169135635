<template>
<nav class="event-sidenav">
  <a
    class="event-sidenav__el"
    :class="activeSection === item.name ? 'text-blue-600' : 'text-black-600'"
    v-for="(item,i) in sideNavItems"
    :key="i"
    href="" @click="(e) => navigate(e, item.sectionId)">
    <ioio-icon :icon="item.iconName" class="event-sidenav__el-icon" />
    {{ item.label }}
  </a>
</nav>

</template>

<script>

import {
  mapGetters
} from "vuex";

export default {
  data: () => ({

    sideNavItems: [
      {
        iconName: 'home',
        name: 'overview',
        label: 'Overview',
        sectionId: 'overview'
      },
      {
        iconName: 'pen',
        name: 'edit',
        label: 'Event details',
        sectionId: 'edit'
      },
      {
        iconName: 'list',
        name: 'sessions',
        label: 'Agenda',
        sectionId: 'sessions'
      },
      {
        iconName: 'microphone',
        name: 'speakers',
        label: 'Speakers',
        sectionId: 'speakers'
      },
      {
        iconName: 'browser',
        name: 'appearance',
        label: 'Event pages',
        sectionId: 'appearance'
      },
      {
        iconName: 'user',
        name: 'attendees',
        label: 'Attendees',
        sectionId: 'attendees'
      },


      // {
      //   iconName: 'recordings',
      //   name: 'recordings',
      //   label: 'Recordings',
      //   sectionId: 'recordings'
      // }


      {
        iconName: 'fal-clock-three',
        name: 'reports',
        label: 'Reports',
        sectionId: 'reports'
      }
    ]
  }),
  props: {
    activeSection: {
      type: String,
      required: true,
      default: "overview"
    },
    onActiveSectionChanged: {
      type: Function,
      required: true
    },
  },

  computed: {
    ...mapGetters({

    })
  },
  methods: {
    navigate(e, sectionId) {

      e.preventDefault();

      this.onActiveSectionChanged(sectionId);

      const eventId = this.$route.query.id;

      const queries = {
        id: eventId,
        sectionId: sectionId || undefined
      };

      this.$pushRoute({

        path: this.$route.fullPath,
        query: queries
      });
    },
  }
}
</script>

<style lang="scss">

.event-sidenav {
  @apply
  w-60
  bg-white
  flex
  flex-col;

  &__el {
    @apply
    flex
    items-center
    text-black-600
    mt-10
    transition-colors
    pl-14;

    &:hover {
      @apply text-blue-600;
    }

    .event-sidenav__el-icon {
      @apply
      absolute
      left-6
      flex
      items-center
      justify-end
      w-5
      h-5
      mr-4;
    }
  }

}
</style>
