<template>
  <div class="vod-lib-actions-component relative">
    <button-component
    variant="minimal"
    size="size-s"
    class="enable-btn"
    shape="circle"
    @click.stop="toggleActionsVisible(true)"
    >
    <ioio-icon icon="fas-ellipsis" class="h-4"/>
  </button-component>
  <dropdown-component v-if="areActionsVisible" align="align-right" :onClose="() => toggleActionsVisible(false)">
    <ul class="list-reset m0">
      <li
        v-if="initLibraryItemPlayback && vod.status === 'complete'"
        @click="initLibraryItemPlayback(vod)"
        class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer">
        <ioio-icon icon="fas-eye" class="mr-2 h-3.5 w-3.5"/>{{ previewLabel }}
      </li>
      <li
        v-if="showEdit && (vod.status === 'complete') || areEditAndPreviewSeperate"
        @click="showEdit(vod)"
        class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer">
      <ioio-icon icon="fas-pen" class="w-3.5 h-3.5 mr-2"/>{{ editLabel }}
      </li>
      <li class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer"
        v-if="toggleAdInsertionModeOpened && vod.status === 'complete' && vod.meta && vod.meta.type !== 'ad'" @click="toggleAdInsertionModeOpened(vod)">
          <ioio-icon icon="fas-scissors" class="w-3.5 h-3.5 mr-2"/>{{ addInsertionLabel }}
      </li>
      <download-preview-proxy-url-vod-component
        v-if="onNotEncodedVodPreview && true"
        :stagedVod="vod"
        :onPreview="onNotEncodedVodPreview"
        :previewLabel="proxyPreviewLabel"/>
      <archive-vod-component
        v-if="hasArchivePermission"
        :vod="vod"
        @close="toggleActionsVisible(false)" />
      <delete-vod-component
        v-if="vod.status !== 'delete' && hasDeletePermission"
        :vod="vod"
        @close="toggleActionsVisible(false)"/>
    </ul>
  </dropdown-component>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    areActionsVisible: false
  }),
  props: {
    vod: Object,
    initLibraryItemPlayback: {
      type: Function,
      default: null
    },
    showEdit: {
      type: Function,
      default: null
    },
    onNotEncodedVodPreview: {
      type: Function,
      default: null
    },
    toggleAdInsertionModeOpened: {
      type: Function,
      default: null
    },

    editLabel: {
      type: String,
      default: 'Edit'
    },
    previewLabel: {
      type: String,
      default: 'Preview'
    },
    proxyPreviewLabel: {
      type: String,
      default: 'Proxy Preview'
    },
    addInsertionLabel: {
      type: String,
      default: 'Insert Ads'
    },

    areEditAndPreviewSeperate: {
      type: Boolean,
      default: true
    }
  },
  methods: {

    toggleActionsVisible(newVal) {

      this.areActionsVisible = newVal;
    }
  },
  computed: {

    hasArchivePermission() {

      return this.$auth('vod_archive');
    },

    hasDeletePermission() {

      return this.$auth('vod_delete');
    },
  }
};
</script>

<style lang="scss" scoped>



</style>
