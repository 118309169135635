import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

const state = {

  appName: process.env.APP_NAME,
  appVersion: process.env.APP_VERSION,

  pageMetadata: {},

  currentRoute: {},

  userData: null,
  isAuthSuccessful: false,
  isLoginSSO: false,
  isAdminUser: false,
  gitlabToken: null,
  userPermissions: null,
  cognitoSettings: null,
  isInitialAppStateReady: false,

  deploymentTypesRequiringAdditionalSetup: {
    broker: {
      action: 'setupBrokerDeployment'
    },
    live: {
      action: 'setupLiveDeployment'
    },
    'live-elemental': {
      action: 'setupLiveElementalDeployment'
    },
    image: {
      action: 'setupImageDeployment'
    },
    syndication: {
      action: 'setupSyndicationDeployment'
    },
    ingest: {
      action: 'setupIngestDeployment'
    },
    events: {
      action: 'setupEventsDeployment'
    },
  },

  // Will be set dynamically on app creation from deploymentTypes.constants.js
  deploymentTypesArr: [],
  gitlabProjectIdMap: {},
  createEditPossibleDeploymentTypes: [],
  createEditPossibleDeploymentTypesMasterOrgOnly: [],

  NOTIFICATION_SOCKET: null,
  socketPingInterval: null,
  // socketReconnectInterval: null,

  BASE_BROKER_URL: "",
  BASE_LIVE_URL: "",
  BASE_LIVE_ELEMENTAL_URL: "",
  BASE_IMAGE_URL: "",
  BASE_SYNDICATION_URL: '',
  BASE_INGEST_URL: '',
  BASE_EVENTS_URL: '',
  BASE_STATISTICS_URL: 'https://api-v2-stats.dev.ioio.tv/v1',

  uploadConfig: {},

  isUploadModalOpened: false,

  // Used when an asset is signed to S3, but a confirm is not yet received by the socket
  pendingUploadAssetKeys: {},

  isRequestPending: false,

  toastCtrl: null,

  sidebar: {
    isCollapsed: false
  },

  /**
   * Organizations && deployments
   */
  organizations: [],
  organizationSelected: null,
  orgGuid: null,

  availableDeployments: [],

  siteBuilderApps: [],
  areSiteBuilderAppsLoaded: false,

  navItems: [

    {
      label: "Home",
      name: "dashboard",
      route: "/"
    },

    {
      label: "Library",
      name: "library",
      permission: 'listings_list',

      secondaryItems: [

        {
          label: "Videos",
          name: "video",
          route: "/vod/library",
          permission: 'listings_list',
        },

        {
          label: "Videos 2.0",
          name: "videoNew",
          route: "/vod/library-new",
          permission: 'listings_list',
        },

        {
          label: "Playlists",
          name: "playlists",
          route: "/vod/library/playlists",
          permission: ["listings_list"]
        },

        {
          label: "Images",
          name: "images",
          route: "/images-library",
          permission: ["images_list"]
        },

        {
          label: "Syndication",
          name: "librarySyndication",
          route: "/vod/library/syndication",
          permission: 'syndication_lists_list',
        },

        {
          label: "Import",
          name: "ingest",
          route: "/vod/library/ingest",
          permission: ['sources_list','sources_ingest'],
        },

        {
          label: "Insights",
          name: "libraryInsights",
          route: "/statistics/vod",
          permission: 'listings_list',
        },
      ]
    },

    {
      label: "Events",
      name: "events",
      route: "/events-list",
      permission: 'events_list'
    },

    {
      label: "Hubs",
      name: "hubs",
      permission: '', // No permissions needed for now

      // dynamicly loaded
      secondaryItems: [ ]
    },

    {
      label: "Channels",
      name: "channels",
      permission: 'listings_list',

      secondaryItems: [

        {
          label: "Channel Manager",
          name: "channelManager",
          route: "/vod",
          permission: 'listings_list',
        },
        {
          label: "Insights",
          name: "statistics",
          route: "/statistics/channels",
          // permission: 'access_admin_routes' // Note: add permission if needed
        },
      ]
    },

    {
      label: "Live",
      name: "live",
      permission: 'WORKFLOW',

      secondaryItems: [

        {
          label: "MediaLive Workflows",
          name: "liveWorkflows",
          route: "/live/workflows",
          permission: 'WORKFLOW',
        },
        {
          label: "Connected Devices",
          name: "connectedDevices",
          route: "/live/connected-devices",
          permission: 'WORKFLOW',
        },
        {
          label: "MediaLive Blueprints",
          name: "blueprints",
          route: "/live/blueprints",
          permission: 'WORKFLOW',
        },
        {
          label: "Elemental Live Workflows",
          name: "elementalWorkflows",
          route: "/live/elemental-workflows",
          permission: 'elive_workflows_list',
        },
        {
          label: "Elemental Live Devices",
          name: "elementalLiveDevices",
          route: "/live/elemental-devices",
          permission: 'elive_workflows_list',
        },
        {
          label: "Elemental Blueprints",
          name: "elementalBlueprints",
          route: "/live/elemental-blueprints",
          permission: 'elive_workflows_list',
        },
        {
          label: "Archives",
          name: "libraryWorkflows",
          route: "/vod/library/workflows",
          permission: 'WORKFLOW',
        }
      ]
    },

    {
      label: "Manage Users",
      name: "manageUsers",
      route: "/manage/users",
      hidden: true
    },
  ],
  userNavItems: [
    // uncomment, when dev on the feature is complete,
    //  also look at routesAuth.config.js
    // {
    //   label: "Profile",
    //   name: "profile",
    //   route: "/profile"
    // },
    {
      label: "Switch organization",
      name: "selectOrganization",
      route: "/admin/manage-organizations",
      permission: 'access_admin_routes'
    },
    {
      label: "Settings",
      name: "settings",
      route: "/manage/settings"
    },
    {
      label: "Users",
      name: "users",
      route: "/manage/users"
    },
    {
      label: "API Keys",
      name: "apiKeys",
      route: "/manage/api-keys"
    },
    {
      label: "Roles & permissions",
      name: "rolesAndPermissions",
      route: "/manage/roles-and-permissions"
    }
  ],
  adminNavItems: [

    {
      label: "Organizations",
      name: "organizations",
      route: "/admin/manage-organizations"
    },
    {
      label: "AWS Accounts",
      name: "awsAccounts",
      route: "/admin/manage-aws-accounts"
    },
    {
      label: "WebApps Settings",
      name: "sitebuilderSettings",
      route: "/admin/sitebuilder-settings"
    }
  ],

  quickActionItems: [

    {
      label: "Upload",
      name: "quickUpload",
      permission: 'listings_list',
      route: "/vod/library",
      queries: { uploadNew: true },
      icon: 'fal fa-cloud-upload'
    },

    {
      label: "Event",
      name: "quickEvent",
      route: "/events-list",
      permission: 'events_list',
      queries: { createNew: true },
      icon: 'fal fa-calendar'
    },

    {
      label: "Playlist",
      name: "quickPlaylist",
      permission: 'listings_list',
      route: "/vod/library/playlists",
      queries: { createNew: true },
      icon: 'fal fa-play'
    },

    {
      label: "Channel",
      name: "quickChannel",
      permission: 'listings_list',
      route: "/vod",
      queries: { createNew: true },
      icon: 'fas fa-tv'
    },

    {
      label: "Import",
      name: "quickImport",
      permission: ['sources_list','sources_ingest'],
      route: "/vod/library/ingest",
      queries: { createNew: true },
      icon: 'download'
    }

  ],

  quickActionDashboardItems: [

    {
      label: "Upload videos",
      name: "quickUpload",
      permission: 'listings_list',
      route: "/vod/library",
      icon: 'fa-cloud-upload'
    },

    {
      label: "Create Event",
      name: "quickEvent",
      route: "/events-list",
      permission: 'events_list',
      queries: { createNew: true },
      icon: 'fa-calendar'
    },

    {
      label: "Record video",
      name: "recording",
      permission: '',
      route: '',
      isExternalRedirect: true,
      icon: 'fa-video'
    },

    {
      label: "Create page",
      name: "quickPage",
      route: '',
      permission: '',
      icon: 'fa-browser',
      isExternalRedirect: true
    },

  ],
  msgBusMsgList: [],
  selectedPipelineDetails: {},
  selectedPipelineJobs: {},


  isActiveConfirmGuardSet: false,
  activeConfirmDialog: null,
  isRedirectFlagRaised: false,
  redirectMsg: 'Progress will be lost if you proceed.',
  redirectSecondaryMsg: 'Are you sure',

  lastWorkedOnItems: []
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
