/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Delete': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.714 1.818H2.64A1.825 1.825 0 00.808 3.636c0 1.005.82 1.819 1.832 1.819H26.45a1.825 1.825 0 001.832-1.819c0-1.004-.82-1.818-1.832-1.818H16.377C16.377.814 15.557 0 14.545 0a1.825 1.825 0 00-1.831 1.818zM4.472 7.273h20.147a.913.913 0 01.907 1.038l-2.77 19.22a1.827 1.827 0 01-1.813 1.56H8.148a1.827 1.827 0 01-1.813-1.56L3.565 8.31a.91.91 0 01.907-1.038zM14.545 11.8a.884.884 0 00-.887.881v10.916c0 .487.397.881.887.881s.888-.394.888-.88V12.68a.884.884 0 00-.888-.88zm6.398.142a.887.887 0 00-1.016.732l-1.72 10.782a.882.882 0 00.738 1.008.887.887 0 001.015-.733l1.72-10.78a.882.882 0 00-.737-1.009zm-12.648 0a.882.882 0 00-.738 1.008l1.72 10.781a.887.887 0 001.016.733.882.882 0 00.737-1.008L9.31 12.674a.887.887 0 00-1.015-.732z" _fill="#4F4F4F"/>'
  }
})
