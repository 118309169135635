/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'call_split': {
    width: 24,
    height: 24,
    viewBox: '8 4 24 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.333 6.667v10l-3.816-3.817-8.834 8.816H6.667v-3.333h12.65l7.833-7.85-3.817-3.817h10zM29.517 27.15l3.816-3.817v10h-10l3.817-3.817-4.8-4.8 2.367-2.366 4.8 4.8z" _fill="#4F4F4F"/>'
  }
})
