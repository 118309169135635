/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin-time': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#111"><path pid="0" d="M10 18c0-4.381 3.542-7.948 7.912-7.996C17.969 9.661 18 9.325 18 9c0-5.634-4.576-9-9-9S0 3.366 0 9c0 5.289 7.952 13.363 8.29 13.705a1 1 0 001.42 0 45.38 45.38 0 001.116-1.187A7.937 7.937 0 0110 18zm-1-6a3 3 0 110-6 3 3 0 010 6z"/><path pid="1" data-color="color-2" d="M18 24a6 6 0 100-12 6 6 0 000 12zm-1-10h2v3h3v2h-5v-5z"/></g>'
  }
})
