<template>
<div class="vod-archive-settings-component">
  <div class="box bg-white rounded-lg mb-4">
    <div v-if="!isLoadingData" class="flex items-center">
      <span class="size-3 p-3 w-1/2">
        Retention period before delete in days: {{defaultData.value}}
      </span>

      <div class="p-2 flex items-center ml-2">

        <div class="text-xs">1</div>
        <ioio-field
          type="range"
          min="1"
          max="30"
          step="1"
          v-model="defaultData.value"
          class="mx-2"
          size="small"
          :hasClearBtn="false"
        />
        <div class="text-xs">30 </div>
      </div>
      <div class="p-3 ml-auto">
        <button-component
          variant="primary"
          intent="success"
          size="size-s"
          @click="updateDefaultData()">Update
        </button-component>
      </div>
    </div>
    <div class="relative min-h-36" v-else>
      <ioio-loader isShown />
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isLoadingData: true,
    defaultData: {}
  }),
  props: {},
  created () {

    this.getDefaultData();
  },
  methods: {
     getDefaultData() {

      this.getBrokerSettings('archive-retention-period').then((defaultData) => {

        this.defaultData = defaultData;

      })
      .finally(() => {

        this.isLoadingData = false;
      });

    },

    async updateDefaultData(){

      this.isLoadingData = true;

      const updateVal = this.defaultData.value.toString();

      await this.updateBrokerSettings({

        params: 'archive-retention-period',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultData();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
