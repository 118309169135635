<template>
<div class="assign-videos-component">
  {{ name }}
</div>
</template>

<script>
import {
  mapGetters, 
  mapMutations, 
  mapActions  
} from "vuex";

export default {
  data: () => ({
    name: 'AssignVideos'
  }),
  props: {},
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
