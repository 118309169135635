import { getRandomColor } from "@utils/helpers";

const _logWorkedOnVodItem = (commit, rootState, vod) => {

  /**
   * NOTE: when working on a VOD, the _logWorkedOnVodItem method can be called
   * from numerous places in the app. It will be the safest option NOT to navigate
   * exactly to the route the action was called from (rootState.currentRoute),
   * but instead redirect to the library, staging the vod.guid for editing.
   */
  commit('app/LOG_WORKED_ON_ITEM', {
    title: vod.meta.title,
    type: 'Video',
    loggedAt: new Date().getTime(),
    url: `/vod/library?id=${vod.guid}`
  }, {
    root: true
  });
};

const _logWorkedOnChannelItem = (commit, channel) => {

  /**
   * NOTE: when working on a VOD, the _logWorkedOnVodItem method can be called
   * from numerous places in the app. It will be the safest option NOT to navigate
   * exactly to the route the action was called from (rootState.currentRoute),
   * but instead redirect to the library, staging the vod.guid for editing.
   */
  commit('app/LOG_WORKED_ON_ITEM', {
    title: channel.name,
    type: 'Channel',
    loggedAt: new Date().getTime(),
    url: `/vod?channel-guid=${channel.guid}`
  }, {
    root: true
  });
};

const _logWorkedOnPlaylistItem = (commit, playlist) => {

  commit('app/LOG_WORKED_ON_ITEM', {
    title: playlist.name,
    type: 'Playlist',
    loggedAt: new Date().getTime(),
    url: `/vod/library/playlists?id=${playlist.guid}`
  }, {
    root: true
  });
};

const _getUpdatedSchedules = (schedules, listingStartTime) => {

  const prevStartTime = schedules[0].start;
  const adjustAmount = listingStartTime - prevStartTime;

  return schedules.map(schedule => {

    const newScheduleStart = schedule.start + adjustAmount;

    return {
      ...schedule,
      start: newScheduleStart,
      end: newScheduleStart + schedule.totalDuration
    };
  });
};

const makeGetChannelsListRequest = ({ state, commit, dispatch }, newSelectedChannelGuid) => {

  let newSelectedChannelIndex = 0;

  return dispatch('app/makeRequest', {
      type: 'getChannelsList'
    }, {
      root: true
    })
    .then(channelsList => {

      commit('SET_CHANNELS_LIST', channelsList);

      if (newSelectedChannelGuid) {

        for (var i = 0; i < channelsList.length; i++) {

          if (channelsList[i].guid === newSelectedChannelGuid) {

            newSelectedChannelIndex = i;
            break;
          }
        }
      }

      commit('SET_SELECTED_CHANNEL', channelsList[newSelectedChannelIndex]);
    });
};

const saveReorderedChannelsList = ({ state, commit, dispatch }) => {

  const channelsGuids = state.channelsList.map(channel => channel.guid);

  return dispatch('app/makeRequest', {
      type: 'reorderChannelsList',
      payload: {
        channels: channelsGuids
      }
    }, {
      root: true
    })
    .then(() => {

      commit('SET_CHANNELS_LIST', state.channelsList);
    });
};

const makeCreateNewChannelRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createNewChannel',
      payload
    }, {
      root: true
    })
    .then(response => {

      const newChanelUrl = response.url;
      const channelUrlAsArr = newChanelUrl.split('/')
      const channelGuid = channelUrlAsArr[channelUrlAsArr.length - 1];

      return dispatch('makeGetChannelsListRequest').then(() => {

        const newlyCreatedChannel = state.channelsList.find(ch => ch.guid === channelGuid);

        dispatch('triggerChangeSelectedChannel', newlyCreatedChannel);

        _logWorkedOnChannelItem(commit, newlyCreatedChannel);

        return newlyCreatedChannel;
      });
    });
};

const getChannelById = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getChannelById',
      params
    }, {
      root: true
    });
};

const makeEditChannelRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'editChannel',
      payload
    }, {
      root: true
    })
    .then(response => {

      _logWorkedOnChannelItem(commit, state.channelsMeta.selected);

      dispatch('makeGetChannelsListRequest', state.channelsMeta.selected.guid);
    });
};

const makeDeleteChannelRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteChannel',
      payload
    }, {
      root: true
    })
    .then(response => {

      return dispatch('makeGetChannelsListRequest', state.channelsMeta.selected.guid)
        .then(() => {

          const firstChannel = state.channelsList[0];

          if (firstChannel) {

            dispatch('triggerChangeSelectedChannel', firstChannel);

            /**
             * If the channel is locked for editing by another user
             * The controls to drag in the timeline will be disabled
             */
            dispatch('makeCheckIsChannelLockedRequest', firstChannel.guid);
          }

          /**
           * This will trigger a change in the playbackUrl, used in
           * LivePreview component. (If a preview was beeing played,
           * it will now play the newly opened channel preview)
           */
          commit('SET_SINGLE_SOURCE_PLAYED', null);
        });
    });
};

const makeCheckIsChannelLockedRequest = ({ state, commit, dispatch }, guid) => {

  return dispatch('app/makeRequest', {
      type: 'checkIsChannelLocked',
      params: {
        guid
      }
    }, {
      root: true
    })
    .then(response => {

      const isLocked = response.locked;

      if (isLocked) {

        commit('TOGGLE_CHANNEL_LOCKED', {
          guid: guid,
          isLocked
        });
      }
    });
};

const triggerChangeSelectedChannel = ({ state, rootState, commit, dispatch }, channel) => {

  if (state.channelsMeta.selected.guid) {

    const brokerDeployment =
      rootState.app.availableDeployments.find(d => d.type === 'broker');

    rootState.app.NOTIFICATION_SOCKET.send(JSON.stringify({

      action:"channel_unlock",
      deployment: brokerDeployment.guid,
      data: {

        channelGuid: state.channelsMeta.selected.guid
      }
    }));
  }

  commit('SET_SELECTED_CHANNEL', channel);

  commit('SET_SELECTED_LISTING', {});

  // TODO: DELETE this dispatch after the old channelManager is deprecated! It duplicates the get listings and schedules requests, they are now handled in the components!
  return dispatch('makeGetListingsAndSchedulesForChannelRequest', {
    start: new Date().getTime() - 1000 * 60 * 60 * 24,

    end: new Date().getTime() + 1000 * 60 * 60 * 48, // from now till the day after tomorrow (48h)

    channel: state.channelsMeta.selected.guid,

    current: "1"
  }).then(() => {

    dispatch('playhead/initPlayingScheduleTracker', {
      selectedChannelListingsAbsolute: state.selectedChannelListings.absolute,
      selectedChannelListingsCurrent: state.selectedChannelListings.currentPeriod
    }, {
      root: true
    });
    commit('SET_SELECTED_LISTING', rootState.playhead.currentlyPlayingListing);

    commit('SET_SELECTED_CHANNEL_LISTINGS_PRISTINE', state.selectedChannelListings.absolute);
  });
};

const makeSavePlaylistRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'savePlaylist',
      payload
    }, {
      root: true
    })
    .then(response => {

      const responseAsArr = response.url.split('/');
      const newPlasylistGuid = responseAsArr[responseAsArr.length - 1];

      _logWorkedOnPlaylistItem(commit, {
        name: payload.name,
        guid: newPlasylistGuid
      });

      return response.data;
    });
};

const makeUpdatePlaylistRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updatePlaylist',
      payload
    }, {
      root: true
    })
    .then(response => {

      _logWorkedOnPlaylistItem(commit, {

        name: payload.data.name,
        guid: payload.guid
      });

      return response;
    });
};

const makeDeletePlaylistRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deletePlaylist',
      payload
    }, {
      root: true
    })
    .then(() => {

      const updatedPlaylists = [...state.playlists];

      updatedPlaylists.splice(payload.removedIndex, 1);

      commit('SET_PLAYLISTS', updatedPlaylists);
    });
};

const makeGetVodListRequest = ({ state, commit, dispatch }, payload = {}) => {

  const currentFetchedList = payload.currentFetchedList || [];

  let combinedVodList = [...currentFetchedList];

  return dispatch('app/makeRequest', {
      type: 'getVodList',
      params: payload.params
    }, {
      root: true
    })
    .then(resp => {

      // if the `sources` key is present, this is the new way the GET /list request work on the BE
      if (resp.sources) {

        combinedVodList = combinedVodList.concat(resp.sources);

        // additional request should be made for the next list part
        if (resp.lastEvaluatedKey) {

          const initialParams = payload.params || {};

          return dispatch('makeGetVodListRequest', {

            params: {

              ...initialParams,
              exclusiveStartKey: resp.lastEvaluatedKey,
            },
            currentFetchedList: combinedVodList
          });
        }
        //Private videos should not be shown-ICON-2484
        let publicVodList = combinedVodList.filter(vod => !vod.meta || vod.meta && !vod.meta.privacy);

        const sortedVodList =
          publicVodList.sort((a, b) => b.createdAt - a.createdAt);

        commit('SET_VOD_LIST', sortedVodList);

        return publicVodList;

      } else {

        // NOTE: this else should be deprecated once the new
        // logic for getting the vod list is deployed on all instances of the BE!

        console.log('--------------- OLD logic!!!', resp)

        //Private videos should not be shown- ICON-2484
        const vodList = resp.filter(vod => !vod.meta || vod.meta && !vod.meta.privacy);

        const sortedVodList =
          vodList.sort((a, b) => b.createdAt - a.createdAt);

        commit('SET_VOD_LIST', sortedVodList);

        return vodList;
      }
    });
};

const getSignedHlsUrl = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getSignedHlsUrl',

    params
  }, {
    root: true
  });
};

const getVodElastic = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getVodElastic',
    params
  }, {
    root: true
  });
};

const makeGetVodDetailsRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodDetails',
      params
    }, {
      root: true
    })
    .then(vodData => {

      // NOTE: Possibly store it in the store here if needed

      return vodData;
    });
};

const makeGetVodDetailsExternalRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeExternalApiRequest', {
      requestData: {
        type: 'getVodDetails',
        baseUrl: params.baseUrl
      },
      params: {
        guid: params.vodId
      }
    }, {
      root: true
    })
    .then(vodData => {

      return vodData;
    });
};

const getVodCompressedFormats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodCompressedFormats',
      params
    }, {
      root: true
    });
};

const getAllEncodedVodFormats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getAllEncodedVodFormats',
      params
    }, {
      root: true
    });
};

const makeEditVodMetadataRequest = ({ state, commit, dispatch, rootState }, payload) => {

  const { params, vodGuid } = payload;

  return dispatch('app/makeRequest', {
      type: 'editVodMetadata',
      payload
    }, {
      root: true
    })
    .then(() => {

      const updatedVodList = [...state.vodList];

      for (let i = 0; i < updatedVodList.length; i++) {


        if (updatedVodList[i].guid === vodGuid) {

          updatedVodList[i].meta = {
            ...params
          };

          _logWorkedOnVodItem(commit, rootState, updatedVodList[i]);

          break;
        }

      }

      commit('SET_VOD_LIST', updatedVodList);
    });
};

const makeEditVodSlugRequest = ({ state, commit, dispatch}, payload) => {

  return dispatch('app/makeRequest', {
      type: 'editVodSlug',
      payload
    }, {
      root: true
    })
};

const makeArchiveVodRequest = ({ state, commit, dispatch }, payload) => {

  const { vodGuid } = payload;

  return dispatch('app/makeRequest', {
      type: 'archiveVod',
      payload
    }, {
      root: true
    })
    .then(() => {

      const updatedVodList = [...state.vodList];

      for (let i = 0; i < updatedVodList.length; i++) {


        if (updatedVodList[i].guid === vodGuid) {

          updatedVodList[i] = {
            ...updatedVodList[i],
            status: 'archive'
          };

          break;
        }

      }

      commit('SET_VOD_LIST', updatedVodList);
    });
};

const makeUnarchiveVodRequest = ({ state, commit, dispatch }, payload) => {

  const { vodGuid } = payload;

  return dispatch('app/makeRequest', {
      type: 'unarchiveVod',
      payload
    }, {
      root: true
    })
    .then(() => {

      return dispatch('makeGetVodDetailsRequest', {guid: vodGuid})

        .then((vodData) => {

          const updatedVodList = [...state.vodList];

          for (let i = 0; i < updatedVodList.length; i++) {

            if (updatedVodList[i].guid === vodGuid) {

              updatedVodList[i] = {
                ...updatedVodList[i],
                status: vodData.status
              };

              break;
            }

          };

          commit('SET_VOD_LIST', updatedVodList);
        });
    });


};

const makeDeleteVodRequest = ({ state, commit, dispatch }, payload) => {

  const { vodGuid } = payload;

  return dispatch('app/makeRequest', {
      type: 'deleteVod',
      payload
    }, {
      root: true
    })
    .then(() => {

      const updatedVodList = [...state.vodList];

      for (let i = 0; i < updatedVodList.length; i++) {


        if (updatedVodList[i].guid === vodGuid) {

          updatedVodList.splice(i, 1);

          break;
        }

      }

      commit('SET_VOD_LIST', updatedVodList);
    });
};

const clipVod = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'clipVod',
      payload
    }, {
      root: true
    })
    .then((response) => {

      _logWorkedOnVodItem(commit, rootState, rootState.channelManager.editedVod);

      return response;
    });
};

const makeGetPlaylistsRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getPlaylists',
      params
    }, {
      root: true
    })
    .then(playlists => {

      commit('SET_PLAYLISTS', playlists);

      return playlists;
    });
};

const makeGetListingsForChannelRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getListingsForChannel',
      params
    }, {
      root: true
    });
};

const saveMultipleListings = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'saveUpdateNewListingsForChannel',
      payload
    }, {
      root: true
    });
};

const saveMultipleSchedules = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'saveUpdateNewSchedulesForChannel',
      payload
    }, {
      root: true
    });
};

const makeDeleteListingFromChannelRequest = ({ state, commit, dispatch }, listing) => {


  const prevListing =
    state.selectedChannelListings.absolute[listing.absoluteIndex - 1];

  const nextListing =
    state.selectedChannelListings.absolute[listing.absoluteIndex + 1];

  const affectedPeriod = {
    start: prevListing ? prevListing.start : listing.start,
    end: nextListing ? nextListing.end : listing.end
  };


  commit('TRIGGER_ALL_SCHEDULES_FOR_LISTING_DELETE');

  commit('TRIGGER_SCHEDULES_STARTS_ADJUSTMENT');

  commit('UPDATE_LISTINGS', { atIndex: listing.absoluteIndex, deleteCount: 1, newListings: [] });

  return dispatch('channelManager/updateListingsAndCalcTimeline', affectedPeriod, {
    root: true
  });

  // return dispatch('app/makeRequest', {
  //     type: 'deleteListingForChannel',
  //     params
  //   }, {
  //     root: true
  //   });
};

const makeGetListingsAndSchedulesForChannelRequest = ({ state, commit, dispatch }, params) => {

  const { start, end } = params;

  const endHour = new Date(end).getHours();

  const visibleEndBound = new Date(end).setHours(endHour, 0, 0, 0);

  state.loadedListingsAndSchedulesBounds = {
    start,
    end,
    visibleEndBound
  };

  const promises = [
    makeGetListingsForChannelRequest({ state, commit, dispatch }, params),
    makeGetSchedulesForChannelRequest({ state, commit, dispatch }, params)
  ];

  return Promise.all(promises)
    .then(responses => {

      const pristineListings = responses[0];
      const schedules = responses[1];
      const listings = [];

      let currentListing = null;

      // let iterations = 0;

      for (var i = 0; i < schedules.length; i++) {

        const schedule = schedules[i];

        if (!currentListing) {

          currentListing = {
            ...pristineListings[listings.length],
            schedules: []
          };
        }

        if (schedule.start >= currentListing.start &&
            schedule.start < currentListing.end) {

          currentListing.schedules.push(schedule);

        } else {

          listings.push(currentListing);

          /**
           * If this check is NOT met, the currently evaluated schedule
           * doesn't have a corresponding listing to it. This means,
           * that it should NOT be displayed and a possible bug in the
           * schedule/listing creation is present. Log the schedule data
           * and it's startTime, in order to track what the problem is.
           */
          const nextListing = pristineListings[listings.length];

          /**
           * There are schedules, that don't belong to any listing
           * and start or continue after the last known listing has ended.
           * There is no need to try to match them => break;
           */
          if (!nextListing) {

            console.error(`There is a mismatch between schedules and listings.
              There are schedules, that start or continue after the last listing has ended.
              The schedule that caused the error (start time / end time / guid / index):
              ${new Date(schedule.start).toUTCString()} /  ${new Date(schedule.start + schedule.totalDuration).toUTCString()} / ${schedule.guid} / ${i}.
              Last working listing (start time / end time / index):
              ${new Date(currentListing.start).toUTCString()} / ${new Date(currentListing.end).toUTCString()} / ${listings.length - 1}`
            );

            break;
          }

          if (schedule.start >= nextListing.start &&
            schedule.start < nextListing.end) {


            i--;

          } else {

            console.error(`There is a mismatch between schedules and listings.
              The schedule that caused the error (start time / end time / guid / index):
              ${new Date(schedule.start).toUTCString()} /  ${new Date(schedule.start + schedule.totalDuration).toUTCString()} / ${schedule.guid} / ${i}.
              Last working listing (start time / end time / index):
              ${new Date(currentListing.start).toUTCString()} / ${new Date(currentListing.end).toUTCString()} / ${listings.length - 1}`
            );
          }


          /**
           * Null the currentListing, because the reached schedule belongs
           * to the next listing. This will trigger the creation of a new
           * currentListing object in the next iteration, with the appropriate meta.
           */
          currentListing = null;
        }
      }

      /**
       * Push the last updated listing manually
       */
      if (currentListing) {

        listings.push(currentListing);
      }

      commit('SET_SELECTED_CHANNEL_LISTINGS', listings);
      commit('CALC_TOTAL_LISTINGS_DURATION', listings);
    });
};



const updateListingsAndCalcTimeline = ({ state, commit, dispatch }, affectedPeriod) => {

  const listings = state.selectedChannelListings.absolute;

  commit('SET_SELECTED_CHANNEL_LISTINGS', listings);
  commit('CALC_TOTAL_LISTINGS_DURATION', listings);

  if (affectedPeriod) {

    commit('SET_FIRST_AFFECTED_TIMESTAMP', affectedPeriod.start);
    commit('SET_LAST_AFFECTED_TIMESTAMP', affectedPeriod.end);
  }
};

const makeTriggerMultipleListingsUpdateRequest = ({ state, commit, dispatch }, listingData) => {

  const { addedIndex } = listingData;
  const item = listingData.payload.item;

  const selectedPeriod = state.activePeriods.selected.periodKey;

  /**
   * prevSegment can be a real listing, or a serviceElement
   */
  const prevSegment =
    state.selectedChannelListings[selectedPeriod][addedIndex - 1];

  /**
   * New listing start should be after the prev one ends
   */
  const newListingStart = prevSegment ?
    prevSegment.end :
    state.selectedChannelListings[selectedPeriod][addedIndex].start;

  const newListingEnd = newListingStart + item.srcDuration;

  /**
   * Find the next real listing. To be used when making
   * the checks if the new listing can fit.
   */
  let nextListing;

  const allSections = state.selectedChannelListings.entirePeriod;
  const listingSegments = state.selectedChannelListings.absolute;

  /**
   * Adjust the start index for periods, that are not applicable, to avoid iterations
   */
   const irrelevantElementsCount =
    state.activePeriods.selected.periodKey === 'currentPeriod' ?
    state.selectedChannelListings.prevPeriod.length :
    state.selectedChannelListings.prevPeriod.length + state.selectedChannelListings.currentPeriod.length;

  const startIndex = irrelevantElementsCount + addedIndex;

  for (var i = startIndex; i < allSections.length; i++) {

    if (!allSections[i].isServiceElement) {
      nextListing = allSections[i];

      break;
    }
  }

  /**
   * No next listing is found. Setting it as a last element, ensures
   * the new listing will be added last as well
   */
  if (!nextListing) {

    nextListing = {

      start: 0,
      absoluteIndex: listingSegments.length
    };
  }

  let newListingEndAdjustAmount = nextListing.start ? newListingEnd - nextListing.start : 0;

  /**
   * Make a check to see if the listing to be added can fit
   * in the free space and adjust the request if it can't.
   */
  const listingsForUpdate = [];

  for (var i = nextListing.absoluteIndex; i < listingSegments.length; i++) {

    let shouldAddListingForUpdate = false;

    /**
     * Listing is reached, which normally start away enough
     * so that it doesn't overlap with the newly added listing
     * on it's own, but prev listings will probably be adjusted
     * as well. Check against the prev listing, keeping in mind
     * this adjustment, so that it is sure, there will not be overlap
     */
    if (listingSegments[i].start > newListingEnd) {

      const prevListing = listingSegments[i - 1];

      /**
       * It's safe to make a request to the BE, to write the new listing
       */
      if (!prevListing || prevListing.end + newListingEndAdjustAmount <= listingSegments[i].start) {

        break;

      } else {

        shouldAddListingForUpdate = true;
      }

    } else {

      shouldAddListingForUpdate = true;
    }

    /**
     * If shouldAddListingForUpdate flag is set from prev checks,
     * add the listing, to the ones that will have to be updated,
     * when the new one is saved
     */
    if (shouldAddListingForUpdate) {

      const listingStartAdjusted = listingSegments[i].start + newListingEndAdjustAmount;
      const listingEndAdjusted = listingSegments[i].end + newListingEndAdjustAmount;

      /**
       * Update the schedules for the listing
       */
      const updatedSchedules = _getUpdatedSchedules(listingSegments[i].schedules, listingStartAdjusted);
console.log('scenario 1', updatedSchedules)
      listingsForUpdate.push({
        ...listingSegments[i],
        start: listingStartAdjusted,
        end: listingEndAdjusted,
        absoluteIndex: i,
        schedules: updatedSchedules
      });
    }
  }

  /**
   * Save the new one and triggler request to update multiple listings
   */
  if (listingsForUpdate.length) {

    let newListing = {

      color: item.color || getRandomColor(),
      channel: state.channelsMeta.selected.guid,
      title: item.meta.name || item.meta.title,
      description: item.meta.description,
      start: newListingStart,
      end: newListingEnd,
      meta: {
        externalUrl: ''
      }
    };

    /**
     * The item, that triggered the change is a playlist.
     * _getPlaylistAsListingWithSchedules will return a listing,
     * populated with schedules, based on the ones in the playlist
     */
    if (item.meta && item.meta.sources) {

      if (item.meta.color) {

        newListing.color = item.meta.color;
      }

      if (item.meta.externalUrl) {
        newListing.meta.externalUrl = item.meta.externalUrl;
      }

      newListing = _getPlaylistAsListingWithSchedules({
        newListing,
        schedulesData: item.meta.sources
      });

    } else {

      /**
       * The item, that triggered the change is a normal source
       */
      const newSchedule = {

        ...item,

        start: newListing.start,
        totalDuration: item.srcDuration,
        channel: newListing.channel,
        type: item.meta.type,
        meta: {

          ...item.meta
        }
      };
console.log(111, newSchedule)
      if (newSchedule.meta && newSchedule.meta.type === 'live') {
        // a check here should be made if a guid is added : `When schedule is of type ‘source’ or ‘ad’ it represents the source GUID which will be used in the HSL stream. When schedule is of type ‘live’ it represent slate source GUID`
        newSchedule.end = newSchedule.start + newSchedule.srcDuration;
      }

      newListing.schedules = [ newSchedule ];
    }

    const payload = {

      listings: [newListing, ...listingsForUpdate],
      channel: state.channelsMeta.selected.guid
    };

    return dispatch('channelManager/makeSaveListingsUpdateRequest', {
        payload,
        startUpdateIndex: payload.listings[1].absoluteIndex,
        listingsSpliceCount: payload.listings.length - 1
      },
      {
        root: true
      });
  }

  /**
   * This is the normal save request, no need to update multiple
   */
  let payload = {

    color: item.color || getRandomColor(),
    channel: state.channelsMeta.selected.guid,
    title: item.meta.name || item.meta.title,
    description: item.meta.description,
    start: newListingStart,
    end: newListingEnd,
    meta: {
      externalUrl: ''
    }
  };

  /**
   * The item, that triggered the change is a playlist.
   * _getPlaylistAsListingWithSchedules will return a listing,
   * populated with schedules, based on the ones in the playlist
   */
  if (item.meta && item.meta.sources) {

    if (item.meta.color) {

      payload.color = item.meta.color;
    }

    if (item.meta && item.meta.externalUrl) {

      payload.meta.externalUrl = item.meta.externalUrl;
    }

    payload = _getPlaylistAsListingWithSchedules({

      newListing: payload,
      schedulesData: item.meta.sources
    });

    return dispatch('channelManager/makeSavePlaylistAsListingWithSchedules', {

      newListingWithSchedules: payload,
      addIndex: nextListing.absoluteIndex
    }, {
      root: true
    });
  }

  /**
   * The item, that triggered the change is a normal Source
   */

  console.log(222, item, payload)
  if (item.meta && item.meta.type === 'live') {
    // a check here should be made if a guid is added : `When schedule is of type ‘source’ or ‘ad’ it represents the source GUID which will be used in the HSL stream. When schedule is of type ‘live’ it represent slate source GUID`
    item.end = payload.end;
  }

  return dispatch('channelManager/makeSaveListingRequest', {

    newListing: payload,
    firstScheduleData: item,
    addIndex: nextListing.absoluteIndex
  }, {
    root: true
  });
};


const _getPlaylistAsListingWithSchedules = ({ newListing, schedulesData }) => {

  /**
   * Update the clean listing data, by using schedulesData, that
   * comes from a Playlist.
   */
  const newSchedules = [];

  let currentScheduleStart = newListing.start;
  let accumulatedSchedulesDuration = 0;

  /**
   * Calc when each schedule will start
   */
  for (var i = 0; i < schedulesData.length; i++) {

    const currentSchedule = {

      ...schedulesData[i],

      start: currentScheduleStart + accumulatedSchedulesDuration,
      totalDuration: schedulesData[i].totalDuration,
      channel: newListing.channel,
      type: schedulesData[i].meta.type,
      meta: {

        ...schedulesData[i].meta
      }
    };

    newSchedules.push(currentSchedule);

    accumulatedSchedulesDuration += currentSchedule.totalDuration;
  }

  const newListingWithSchedules = {
    ...newListing,
    schedules: newSchedules
  };

  return newListingWithSchedules;
};

const makeSavePlaylistAsListingWithSchedules = ({ state, commit, dispatch }, { newListingWithSchedules, addIndex }) => {

  commit('UPDATE_LISTINGS', {
    atIndex: addIndex,
    deleteCount: 0,
    newListings: [ newListingWithSchedules ]
  });


  commit('SET_FIRST_AFFECTED_TIMESTAMP', newListingWithSchedules.start);

  /**
   * Trigger the recalculation of service elements' durations
   */
  commit('SET_SELECTED_CHANNEL_LISTINGS', state.selectedChannelListings.absolute);
  commit('CALC_TOTAL_LISTINGS_DURATION', state.selectedChannelListings.absolute);
  commit('SET_SELECTED_LISTING', newListingWithSchedules);

  commit('SET_LAST_AFFECTED_TIMESTAMP', newListingWithSchedules.end);
};

const makeSaveListingRequest = ({ state, commit, dispatch }, { newListing, firstScheduleData, addIndex }) => {

  /**
   * Update the clean listing data
   */

  const newSchedule = {

    ...firstScheduleData,

    start: newListing.start,
    totalDuration: firstScheduleData.srcDuration,
    channel: newListing.channel,
    type: firstScheduleData.meta.type,
    meta: {

      ...firstScheduleData.meta
    }
  };

  const newListingWithSchedule = {
    ...newListing,
    schedules: [ newSchedule ]
  };

  commit('UPDATE_LISTINGS', {
    atIndex: addIndex,
    deleteCount: 0,
    newListings: [ newListingWithSchedule ]
  });


  commit('SET_FIRST_AFFECTED_TIMESTAMP', newListingWithSchedule.start);

  /**
   * Trigger the recalculation of service elements' durations
   */
  commit('SET_SELECTED_CHANNEL_LISTINGS', state.selectedChannelListings.absolute);
  commit('CALC_TOTAL_LISTINGS_DURATION', state.selectedChannelListings.absolute);
  commit('SET_SELECTED_LISTING', newListingWithSchedule);

  commit('SET_LAST_AFFECTED_TIMESTAMP', newListingWithSchedule.end);
};

const makeTriggerListingsSwapUpdateRequest = ({ state, rootState, commit, dispatch }, listingsData) => {

  const { addedIndex, removedIndex } = listingsData;

  const selectedPeriodKey = state.activePeriods.selected.periodKey;
  const selectedPeriodListings = state.selectedChannelListings[selectedPeriodKey];

  const currentPeriodListings = state.selectedChannelListings.currentPeriod;
  const nextPeriodListings = state.selectedChannelListings.nextPeriod;

  const startIndex = addedIndex;
  const endIndex = selectedPeriodKey === 'currentPeriod' ?
    currentPeriodListings.length + nextPeriodListings.length :
    currentPeriodListings.length;

  const allListings = state.selectedChannelListings.absolute;

  const movedListing = listingsData.payload.item;

  let movedListingNewIndex;

  const listingDuration = movedListing.end - movedListing.start;

  const newStartTime = selectedPeriodListings[addedIndex].start;
  const newEndTime = newStartTime + listingDuration;


  const updatedSchedules = _getUpdatedSchedules(movedListing.schedules, newStartTime);

  console.log('scenario 2', updatedSchedules)
  const adjustedListing = {

    ...movedListing,
    start: newStartTime,
    end: newEndTime,
    schedules: updatedSchedules
  };

  const smallestAffectedTimestamp =
    Math.min(movedListing.start, adjustedListing.start);

  const biggestAffectedTimestamp =
    Math.max(movedListing.end, adjustedListing.end);

  let nextListing = null;

  /**
   * The new listing place is at the back, no need to check for nextListing
   */
  if (allListings[allListings.length - 1].end <= newStartTime) {

    nextListing = false;
  }

  if (nextListing !== false) {

    for (var i = startIndex; i < endIndex; i++) {

      const segment = selectedPeriodListings[i] || nextPeriodListings[i - currentPeriodListings.length];

      if (!segment.isServiceElement &&
        segment.absoluteIndex !== movedListing.absoluteIndex) {

        nextListing = segment;
        break;
      }
    }
  }

  /**
   * Check if the moved listing will fit in the new place without further adjustments
   */
  if (!nextListing || newEndTime <= nextListing.start) {

    if (nextListing) {

      /**
       * The new listing absolute index should not change
       */
      if (movedListing.end <= nextListing.start) {

        movedListingNewIndex = movedListing.absoluteIndex;

        /**
         * The listing position changed so that it hopped over at least one other listing
         */
        if (movedListing.absoluteIndex + 1 !== nextListing.absoluteIndex) {

          movedListingNewIndex = nextListing.absoluteIndex - 1;
        }

      } else {

        movedListingNewIndex = nextListing.absoluteIndex;
      }

    } else {

      movedListingNewIndex = allListings.length;
    }

    /**
     * Delete the listing from it's old place
     */
    allListings.splice(movedListing.absoluteIndex, 1);

    /**
     * Insert the listing in it's new place
     */
    allListings.splice(movedListingNewIndex, 0, adjustedListing);


    commit('SET_FIRST_AFFECTED_TIMESTAMP', smallestAffectedTimestamp);

    commit('SET_SELECTED_CHANNEL_LISTINGS', state.selectedChannelListings.absolute);
    commit('CALC_TOTAL_LISTINGS_DURATION', state.selectedChannelListings.absolute);

    commit('SET_SELECTED_LISTING', adjustedListing);


    commit('SET_LAST_AFFECTED_TIMESTAMP', biggestAffectedTimestamp);

    return;
  }


  if (nextListing.overflowingData) {

    rootState.app.toastCtrl.error('Please move the next listing since it\'s overflowing in the next period, in order to make place.');

    return;
  }

  /**
   * If the item will not fit, find all the listings that will need to be adjusted
   */
  let gapAmount = 0;

  let lastListingToUpdate;
  let nextListingAbsoluteIndex;

  for (var i = startIndex; i < endIndex; i++) {

    const segment = selectedPeriodListings[i];

    /**
     * Keep track of the gapAmount, and break when it's sufficient so
     * that only listings that will be overlapped are selected to be moved.
     *
     * Note: if not enough gapAmount is found, the lastListingToUpdate
     * will point to the last listing in the loaded listings.
     */
    if (gapAmount >= listingDuration) {

      break;
    }

    if (segment.isServiceElement || segment.absoluteIndex === movedListing.absoluteIndex) {

      gapAmount += segment.end - segment.start;

    } else {

      if (nextListingAbsoluteIndex === undefined) {

        nextListingAbsoluteIndex = segment.absoluteIndex;
      }

      lastListingToUpdate = segment;
    }
  }

  /**
   * Calc all the listings, that will need to be adjusted.
   * starting from the one, next to the newly moved one.
   */
  const listingsForUpdate = [adjustedListing];

  for (var i = nextListingAbsoluteIndex; i <= lastListingToUpdate.absoluteIndex; i++) {

    const segment = state.selectedChannelListings.absolute[i];

    const prevListingToUpdate = listingsForUpdate[listingsForUpdate.length - 1];

    const segmentDuration = segment.end - segment.start;

    /**
     * This is the newly moved listing and it's added
     * manually at the beggining of the calculations
     */
    if (i === movedListing.absoluteIndex) {

      continue;
    }


    const updatedSchedules = _getUpdatedSchedules(segment.schedules, prevListingToUpdate.end);

    console.log('scenario 3', updatedSchedules)
    /**
     * Stack the listings one after another
     */
    listingsForUpdate.push({
      ...segment,
      start: prevListingToUpdate.end,
      end: prevListingToUpdate.end + segmentDuration,
      schedules: updatedSchedules
    });
  }


  /**
   * Check when the listing should be deleted from it's old
   * place first, based on addedIndex / removedIndex (move direction)
   */
  let deleteCount = listingsForUpdate.length - 1;
  let spliceInIndex = nextListingAbsoluteIndex;

  if (addedIndex > removedIndex) {

    state.selectedChannelListings.absolute.splice(spliceInIndex, deleteCount, ...listingsForUpdate);

    /**
     * Remove the listing from it's old place, after the splicing is completed
     */
    state.selectedChannelListings.absolute.splice(movedListing.absoluteIndex, 1);

  } else {

    /**
     * Remove the listing from it's old place, before the splicing is done
     */
    state.selectedChannelListings.absolute.splice(movedListing.absoluteIndex, 1);

    state.selectedChannelListings.absolute.splice(spliceInIndex, deleteCount, ...listingsForUpdate);
  }


  commit('SET_FIRST_AFFECTED_TIMESTAMP', smallestAffectedTimestamp);

  /**
   * Trigger the recalculation of service elements' durations
   */
  commit('SET_SELECTED_CHANNEL_LISTINGS', state.selectedChannelListings.absolute);
  commit('CALC_TOTAL_LISTINGS_DURATION', state.selectedChannelListings.absolute);

  commit('SET_SELECTED_LISTING', listingsForUpdate[0]);


  commit('SET_LAST_AFFECTED_TIMESTAMP', biggestAffectedTimestamp);
};

const makeSaveListingsUpdateRequest = ({ state, commit, dispatch }, {
  payload,
  startUpdateIndex,
  listingsSpliceCount
}) => {

  /**
   * Update the clean listing data
   */
  commit('UPDATE_LISTINGS', {
    atIndex: startUpdateIndex,
    deleteCount: listingsSpliceCount,
    newListings: payload.listings
  });


  commit('SET_FIRST_AFFECTED_TIMESTAMP', payload.listings[0].start);

  /**
   * Trigger the recalculation of service elements' durations
   */
  commit('SET_SELECTED_CHANNEL_LISTINGS', state.selectedChannelListings.absolute);
  commit('CALC_TOTAL_LISTINGS_DURATION', state.selectedChannelListings.absolute);

  commit('SET_SELECTED_LISTING', payload.listings[0]);

  commit('SET_LAST_AFFECTED_TIMESTAMP', payload.listings[payload.listings.length - 1].end);

};

const syncEntrirePeriodToBE = ({ state, rootState, commit, dispatch }) => {

  const now = new Date().getTime();

  const bufferPeriod = state.timelineWriteBufferPeriod;

  const firstPermittedUpdateStamp = now + bufferPeriod;

  /**
   * A record of firstAffectedTimestamp is kept to be passed to the BE call,
   * but it may be in the past (if time passes since your last sync to BE).
   * This check should prevent sending such requests, since listingsForUpdate
   * ONLY contains applicable listings, not ones in the past.
   */
  const startUpdateStamp = firstPermittedUpdateStamp < state.firstAffectedTimestamp ?
    state.firstAffectedTimestamp : firstPermittedUpdateStamp;

  /**
   * Check if the listing starts at least one hour (bufferPeriod) from now
   * and is within the stamps that have been affected by timeline swaps
   */
  const listingsApplicableForUpdate = state.selectedChannelListings.absolute.filter(listing => {

    return listing.start >= startUpdateStamp && listing.end <= state.lastAffectedTimestamp;
  });

  if (!listingsApplicableForUpdate.length && state.selectedChannelListings.absolute.length) {

    return;
  }

  // TODO: keep track of the listings that are updated and if their length doesn't match the
  // listingsApplicableForUpdate.length fire a message, that some progress will be lost?
  // if (listingsApplicableForUpdate.length) {

  // }

  const listingsForUpdate = [...listingsApplicableForUpdate];

  const listingsPayload = {
    start: startUpdateStamp,
    end: state.lastAffectedTimestamp,
    listings: listingsForUpdate,
    channel: state.channelsMeta.selected.guid,

    // NOTE: left so that one can easily clean wrongly saved listings.
    // Should be used together with the one in schedulesPayload
    // ONLY possible while the playhead position is not over them.
    // start: 1569413002898,  // change to appropriate stamps
    // end: 1569429978202,  // change to appropriate stamps
    // listings: [],
  };

  const schedulesForUpdate = [];

  listingsForUpdate.forEach(listing => {

    listing.schedules.forEach(schedule => {

      //TODO: this wil need to be revised if the playlist is saved in the timeline as a listing with schedules instead of a playlist !

      /**
       * The listing is created from a Playlist => gather all the
       * sources' data, used to create it, and use that data to
       * calculate the needed properties in order to create schedules
       */
      if (schedule.meta && schedule.meta.sources) {

        const playlistSources = schedule.meta.sources;

        const scheduleStart = schedule.start;

        let currentAccumulatedDuration = 0;

        playlistSources.forEach(source => {

          schedulesForUpdate.push({
            start: scheduleStart + currentAccumulatedDuration,
            guid: source.guid,
            type: source.type
          });

          currentAccumulatedDuration += source.duration;
        });

      } else {

        // NOTE: limit the meta to only fields, that are visible in the UI. This can cause some problems - watch for them. Also it is unclear when the condition in the if statement above is met, though it seems that it deosn't receive such meta and won't cause the problem we try to fix here.

        const {
          genre,
          group,
          imageUrl,
          privacy,
          title,
          type
        } = schedule.meta;

        const conciseMeta = {};

        genre && (conciseMeta.genre = genre);
        group && (conciseMeta.group = group);
        imageUrl && (conciseMeta.imageUrl = imageUrl);
        privacy && (conciseMeta.privacy = privacy);
        title && (conciseMeta.title = title);
        type && (conciseMeta.type = type);

        const conciseSchedule = {

          ...schedule,

          meta: conciseMeta
        };

        schedulesForUpdate.push(conciseSchedule);
      }
    });
  });

  const schedulesPayload = {
    start: startUpdateStamp,
    end: state.lastAffectedTimestamp,
    schedules: schedulesForUpdate,
    channel: state.channelsMeta.selected.guid,


    // NOTE: left so that one can easily clean wrongly saved schedules.
    // Should be used together with the one in listingsPayload
    // ONLY possible while the playhead position is not over them.
    // start: 1569413002898,  // change to appropriate stamps
    // end: 1569429978202,  // change to appropriate stamps
    // schedules: [],
  };
console.log(listingsPayload, schedulesPayload)


  // NOTE: the following checks should be redundant if everything above works right.
  // After the mechanism is truly tested, it can be deleted.
  // If all is OK with the period changes, this checks won't be met
  // and the syncing of the period to BE will be made.
  let reachedListingIndex = 0;

  let isErrorFound = false;

  schedulesForUpdate.forEach(schedule => {

    // if the schedule has no totalDuration prop, it is comming from a playlist.
    // calc the scheduleEnd as schedule.start + 1, thus the check bellow will only
    // really test the start. Since the schedule is already in a playlist, it should
    // be OK and it's end allready checked
    const scheduleEnd = schedule.totalDuration ? schedule.start + schedule.totalDuration : schedule.start + 1;

    if (schedule.start < listingsForUpdate[reachedListingIndex].start) {

      rootState.app.toastCtrl.error('There is a schedule in the Timeline, that doesn\'t belong to any listing. The changes can\'t be saved. Contact your administrator. Error: Schedule starts before it\'s corresponding Listing.');

      isErrorFound = true;
      return;
    }

    if (schedule.start >= listingsForUpdate[reachedListingIndex].start &&
      scheduleEnd <= listingsForUpdate[reachedListingIndex].end) {

        // the schedule belongs to the current listing, all is OK
    } else {

      // Chech if the schedule fits in the next listing

      reachedListingIndex++;

      // Check if next listing is setup to be updated first
      if (!listingsForUpdate[reachedListingIndex]) {

        rootState.app.toastCtrl.error('There is a schedule in the Timeline, that doesn\'t belong to any listing. The changes can\'t be saved. Contact your administrator. Error: Schedule starts or continues after the last Listings has ended');

        isErrorFound = true;
        return;
      }

      if (schedule.start >= listingsForUpdate[reachedListingIndex].start &&
        scheduleEnd <= listingsForUpdate[reachedListingIndex].end) {

          // the schedule belongs to the next listing, all is OK
      } else {

        rootState.app.toastCtrl.error('There is a schedule in the Timeline, that doesn\'t belong to any listing. The changes can\'t be saved. Contact your administrator. Error: The schedule starts or ends in between adjacent listings boundaries');

        isErrorFound = true;
        return;
      }
    }
  });

  if (isErrorFound) {
    // Prevent the BE requests from being made

    throw new Error('syncEntrirePeriodToBE unsuccessful! isErrorFound flag prevents timeline changes from saving. Check channelManager actions.js');
  }

  const savePromises = [

    dispatch('app/makeRequest', {
      type: 'saveUpdateNewSchedulesForChannel',
      payload: schedulesPayload
    }, {
      root: true
    }),

    dispatch('app/makeRequest', {
      type: 'saveUpdateNewListingsForChannel',
      payload: listingsPayload
    }, {
      root: true
    })
  ];

  commit('SET_IS_SYNC_PERIOD_IN_PROGRESS', true);

  return Promise.all(savePromises)
    .then(responses => {

      commit('SET_IS_SYNC_PERIOD_IN_PROGRESS', false);

      _logWorkedOnChannelItem(commit, state.channelsMeta.selected);

      /**
       * The absolute listings have just been successfully updated =>
       * sync the pristineListings with them
       */
      commit('SET_SELECTED_CHANNEL_LISTINGS_PRISTINE', state.selectedChannelListings.absolute);

      commit('SET_FIRST_AFFECTED_TIMESTAMP', 0);
      commit('SET_LAST_AFFECTED_TIMESTAMP', 0);
    })
    .finally(() => {

      commit('SET_IS_SYNC_PERIOD_IN_PROGRESS', false);
    });
};

const makeGetMediatailorsRequest = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getMediatailors'
    }, {
      root: true
    })
    .then(mediatailors => {

      commit('SET_MEDIATAILORS', mediatailors);
    });
};

const makeSaveNewMediatailorRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'saveNewMediatailor',
      payload
    }, {
      root: true
    });
};

const editMediatailor = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'editMediatailor',
      payload
    }, {
      root: true
    });
};

const deleteMediatailor = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteMediatailor',
      payload
    }, {
      root: true
    });
};

const makeGetSchedulesForChannelRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getSchedulesForChannel',
      params
    }, {
      root: true
    });
};

const makeUploadFileRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'uploadFile',
      payload
    }, {
      root: true
    });
};

const makeUploadFileMetaRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'uploadFileMeta',
      payload
    }, {
      root: true
    });
};

const makeUploadCaptionsMetaRequest = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'uploadCaptionsMeta',
      payload
    }, {
      root: true
    })
    .then((response) => {

      _logWorkedOnVodItem(commit, rootState, rootState.channelManager.editedVod);

      return response;
    });
};

const getCaptionsVttFile = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getCaptionsVttFile',
      payload
    }, {
      root: true
    });
};

const makeEncodeFileRequest = ({ state, rootState, commit, dispatch }, vod) => {

  commit('SET_SINGLE_SOURCE_PLAYED', {

    ...vod,
    status: 'ingest'
  });

  return dispatch('app/makeRequest', {
      type: 'encodeFile',
      payload: {
        guid: vod.guid
      }
    }, {
      root: true
    })
    .then(() => {

      commit('UPDATE_VOD_LIST_ITEM', {
        ...vod,
        status: 'ingest'
      });

      rootState.app.toastCtrl.info(`We started processing ${vod.meta.title}. Please stand by…`);

      _logWorkedOnVodItem(commit, rootState, vod);
    });
};

const encodeWithAutoSubs = ({ state, rootState, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'encodeWithAutoSubs',
      payload: {
        guid: params.vod.guid
      },
      params: params.query
    }, {
      root: true
    })
    .then(() => {

      _logWorkedOnVodItem(commit, rootState, rootState.channelManager.editedVod);

      rootState.app.toastCtrl.info(`We started processing ${params.vod.meta.title} in order to add automatic subtitles. Please stand by…`);
    });
};


const makeGetVodThumbsRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodThumbs',
      params
    }, {
      root: true
    });
};

const makeGetVodSignedUrlRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getVodSignedUrl',
      payload
    }, {
      root: true
    });
};

const getFutureStoredCaptionsGuid = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getFutureStoredCaptionsGuid',
      payload
    }, {
      root: true
    });
};

const getFileCaptionsById = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getFileCaptionsById',
      payload
    }, {
      root: true
    });
};

const updateFileCaptions = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateFileCaptions',
      payload
    }, {
      root: true
    })
    .then((response) => {

      _logWorkedOnVodItem(commit, rootState, rootState.channelManager.editedVod);

      return response;
    });
};

const translateCaptions = ({ state, commit, dispatch, rootState }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'translateCaptions',
      payload
    }, {
      root: true
    })
    .then((response) => {

      _logWorkedOnVodItem(commit, rootState, rootState.channelManager.editedVod);

      return response;
    });
};

const getBrokerSettings = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getBrokerSettings',
      params
    }, {
      root: true
    });
};

const updateBrokerSettings = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateBrokerSettings',
      payload
    }, {
      root: true
    });
};

const getCDNs = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getCDNs'
    }, {
      root: true
    });
};

const createCDN = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createCDN',
      payload
    }, {
      root: true
    }).then(response => {

      const newResourceUrl = response.url;
      const resourceUrlAsArr = newResourceUrl.split('/')
      const cdnGuid = resourceUrlAsArr[resourceUrlAsArr.length - 1];

      return cdnGuid;
    });
};

const deleteCDN = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'deleteCDN',
      params
    }, {
      root: true
    });
};

const getSourcePullerJobs = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getSourcePullerJobs'
    }, {
      root: true
    });
};

const createSourcePullerJob = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createSourcePullerJob',
      payload
    }, {
      root: true
    });
};

const getBrokerApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getBrokerApiKeys'
  }, {
    root: true
  });
}

const getBrokerApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getBrokerApiKey',
    params
  }, {
    root: true
  });
}

const storeNewBrokerApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewBrokerApiKey',
    payload
  }, {
    root: true
  });
}

const updateBrokerApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateBrokerApiKey',
    payload
  }, {
    root: true
  });
}

const deleteBrokerApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteBrokerApiKey',
    params
  }, {
    root: true
  });
}

const generateEpgAndDownload = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'generateEpgAndDownload',
    params
  }, {
    root: true
  });
}

const getBrokerVocabularies = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getBrokerVocabularies'
    }, {
      root: true
    });
};

const createBrokerVocabulary = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createBrokerVocabulary',
      payload
    }, {
      root: true
    });
};

const updateBrokerVocabulary = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateBrokerVocabulary',
    payload
  }, {
    root: true
  });
}

const getSingleVocabulary = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'getSingleVocabulary',
    payload
  }, {
    root: true
  });
}

const deleteSingleVocabulary = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'deleteSingleVocabulary',
    payload
  }, {
    root: true
  });
}

const getBrokerVocabularyFilters = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getBrokerVocabularyFilters'
    }, {
      root: true
    });
};

const createBrokerVocabularyFilter = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createBrokerVocabularyFilter',
      payload
    }, {
      root: true
    });
};

const updateBrokerVocabularyFilter = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateBrokerVocabularyFilter',
    payload
  }, {
    root: true
  });
}

const getSingleVocabularyFilter = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'getSingleVocabularyFilter',
    payload
  }, {
    root: true
  });
}

const deleteSingleVocabularyFilter = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'deleteSingleVocabularyFilter',
    payload
  }, {
    root: true
  });
}

const getBrokerPresets = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getBrokerPresets'
    }, {
      root: true
    });
};

const updateExistingPreset = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateExistingPreset',
    payload
  }, {
    root: true
  });
}

export {
  makeGetChannelsListRequest,
  saveReorderedChannelsList,
  makeCreateNewChannelRequest,
  getChannelById,
  makeEditChannelRequest,
  makeDeleteChannelRequest,
  makeCheckIsChannelLockedRequest,
  triggerChangeSelectedChannel,
  makeGetPlaylistsRequest,
  makeSavePlaylistRequest,
  makeUpdatePlaylistRequest,
  makeDeletePlaylistRequest,
  makeGetVodListRequest,
  getSignedHlsUrl,
  getVodElastic,
  makeGetVodDetailsRequest,
  makeGetVodDetailsExternalRequest,
  getVodCompressedFormats,
  getAllEncodedVodFormats,
  makeEditVodMetadataRequest,
  makeEditVodSlugRequest,
  makeArchiveVodRequest,
  makeUnarchiveVodRequest,
  makeDeleteVodRequest,
  clipVod,

  makeTriggerMultipleListingsUpdateRequest,
  makeSaveListingRequest,
  makeSavePlaylistAsListingWithSchedules,
  makeSaveListingsUpdateRequest,
  syncEntrirePeriodToBE,

  makeTriggerListingsSwapUpdateRequest,
  makeGetListingsForChannelRequest,
  saveMultipleListings,
  saveMultipleSchedules,

  makeDeleteListingFromChannelRequest,

  makeGetSchedulesForChannelRequest,
  makeGetListingsAndSchedulesForChannelRequest,
  makeGetMediatailorsRequest,
  makeSaveNewMediatailorRequest,
  editMediatailor,
  deleteMediatailor,

  updateListingsAndCalcTimeline,
  makeUploadFileRequest,
  makeUploadFileMetaRequest,
  makeUploadCaptionsMetaRequest,
  getCaptionsVttFile,
  makeEncodeFileRequest,
  encodeWithAutoSubs,
  makeGetVodThumbsRequest,
  makeGetVodSignedUrlRequest,
  getFutureStoredCaptionsGuid,
  getFileCaptionsById,
  updateFileCaptions,
  translateCaptions,
  getBrokerSettings,
  updateBrokerSettings,
  getCDNs,
  createCDN,
  deleteCDN,
  getSourcePullerJobs,
  createSourcePullerJob,

  getBrokerApiKeys,
  getBrokerApiKey,
  storeNewBrokerApiKey,
  updateBrokerApiKey,
  deleteBrokerApiKey,

  generateEpgAndDownload,

  getBrokerVocabularies,
  createBrokerVocabulary,
  updateBrokerVocabulary,
  getSingleVocabulary,
  deleteSingleVocabulary,

  getBrokerVocabularyFilters,
  createBrokerVocabularyFilter,
  updateBrokerVocabularyFilter,
  getSingleVocabularyFilter,
  deleteSingleVocabularyFilter,

  getBrokerPresets,
  updateExistingPreset
}