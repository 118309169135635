<template>

<ioio-modal
  class="unarchive-vod-confirm-modal-component"
  :show.sync="isUnarchiveModalOpened"
  size="medium"
  headerClasses="border-bottom"
>

  <template slot="header" >
    <div class="pb-3">
      Unarchive Video Asset
    </div>
  </template>

  <h3 class="pt-6 overflow-y-hidden">
    You are about to unarchive this video asset.
  </h3>

  <template slot="footer">
    <div class="flex justify-end" v-if="!isUnarchiveInProgress">
      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-4"
        @click.stop="toggleUnarchiveModalOpened(false)"
      >
        Cancel
      </ioio-button>
      <ioio-button
        type="primary"
        @click.stop="onConfirmUnarchive"
      >
        Unarchive
      </ioio-button>
    </div>
    <div class="relative min-h-36" v-else>
      <ioio-loader isShown />
    </div>
  </template>
</ioio-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {

  data: () => ({

    isUnarchiveInProgress: false
  }),

  props: {
    vod: Object,
    toggleUnarchiveModalOpened: Function,
    isModalOpened: Boolean
  },

  methods: {

    onConfirmUnarchive() {

      this.isUnarchiveInProgress = true;

      this.makeUnarchiveVodRequest({ vodGuid: this.vod.guid })
        .then(() => {

          this.$toasted.success('Video asset has been unarchived.');
          this.toggleUnarchiveModalOpened(false);
        })
        .finally(() => {

          this.isUnarchiveInProgress = false;
        });
    },

    ...mapActions({
      makeUnarchiveVodRequest: 'channelManager/makeUnarchiveVodRequest'
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    }),

    isUnarchiveModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(newVal) {

        this.toggleUnarchiveModalOpened(false);
      }
    }
  },
}
</script>

<style lang="scss">


</style>
