<template>

<section class="custom-meta-fields-component">

  <section class="meta-groups-section"
    :class="containerClass"
    v-if="possibleCustomFieldGroups.length && displayMode === 'showcase'">

    <section v-if="editedAssetSelectedMetaGroupGuid">

      <section class="flex justify-between mb-6">
        <ioio-label
          :text="selectedMetaGroup.name"
          size="small"
        />
      </section>

      <h3 class="text-m" v-if="selectedMetaGroup.fields && !selectedMetaGroup.fields.length">No metadata groups for this video.</h3>

      <section
        class="flex justify-between mb-6"
        v-for="(field, index) in selectedMetaGroup.fields"
        :key="index"
      >

        <p class="text-black-600 truncate">{{ field.name }}</p>

        <aside class="text-black-500 ml-2 w-3/4 flex justify-end">

          <ioio-label
            v-if="field.type === 'enum'"
            :text="editedAsset[field.name]"
            size="small"
          />

          <pre v-else-if="field.type === 'json'" class="max-h-40 overflow-auto">
            {{ JSON.parse(editedAsset[field.name]) }}
          </pre>

          <!-- Default view -->
          <span
            v-else
          >{{ editedAsset[field.name] }}
          </span>

          <span v-if="!editedAsset[field.name]">--</span>
        </aside>

      </section>
    </section>
    <div v-else>

      <h3 class="text-sm mb-2">
        No metadata
      </h3>
      <p class="text-xs text-black-400">{{ secondaryNoMetaDisclaimer }}</p>
    </div>
  </section>

  <section class="meta-groups-section"
    :class="containerClass"
    v-if="possibleCustomFieldGroups.length && displayMode !== 'showcase'">

    <div class="text-sm text-black-900 mb-1">{{ label }}</div>
    <div class="text-xs text-blue-grey-400 mb-3">{{ disclaimer }}</div>

    <ioio-dropdown
      v-model="editedAsset.selectedMetaGroupGuid"
      :items="possibleCustomFieldGroups"
      placeholder="Select a meta group"
      titleProp="name"
      valueProp="guid"
      class="pb-5 mb-5 border-bottom border-black-100"
      fullWidth

      :topic="formTopic"
    />

    <section v-if="editedAssetSelectedMetaGroupGuid">

      <h3 v-if="selectedMetaGroup.fields && !selectedMetaGroup.fields.length">No metadata groups for this video.</h3>

      <div class="custom-meta-field" v-for="(field, index) in selectedMetaGroup.fields" :key="index">

        <ioio-field
          v-if="field.type === 'string'"
          type="string"
          :topic="formTopic"
          v-model="editedAsset[field.name]"
          :required="field.required"
          :minimum="field.min"
          :maximum="field.max"
          :label="field.name"
          :placeholder="field.name"
          :name="field.name + index"
          fullWidth
          size="medium"
          class="mb-5 complete"
        />

        <ioio-field
          v-if="field.type === 'number'"
          type="number"
          :minimum="`${field.min}`"
          :maximum="`${field.max}`"
          :topic="formTopic"
          v-model="editedAsset[field.name]"
          :required="field.required"
          :label="field.name"
          :placeholder="field.name"
          :name="field.name + index"
          fullWidth
          size="medium"
          class="mb-5 complete"
        />

        <ioio-dropdown
          v-if="field.type === 'enum'"
          :topic="formTopic"
          fullWidth
          v-model="editedAsset[field.name]"
          :items="getEnumOptionsAsObjects(field.additionalData.enumOptions)"
          titleProp="label"
          valueProp="value"
          :required="field.required"
          :label="field.name"
          placeholder="Select"
          :name="field.name + index"
          class="mb-5"
        />

        <ioio-checkbox

          v-if="field.type === 'boolean'"
          :topic="formTopic"
          v-model="editedAsset[field.name]"
          :required="field.required"
          :name="field.name + index"
          size="medium"
          class="mb-5"
        >{{ field.name }}
        </ioio-checkbox>

        <ioio-field
          v-if="field.type === 'json'"
          type="textarea"
          format="json"
          :topic="formTopic"
          v-model="editedAsset[field.name]"
          :required="field.required"
          :label="field.name"
          :placeholder="field.name"
          :name="field.name + index"
          fullWidth
          size="medium"
          class="mb-5"
        />
      </div>
    </section>
  </section>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    possibleCustomFieldGroups: [],
  }),
  props: {

    label: {
      type: String,
      default: 'Select meta group'
    },
    disclaimer: {

      type: String,
      default: 'You can have only one meta group per video'
    },

    secondaryNoMetaDisclaimer: {

      type: String,
      default: ''
    },

    displayMode: {
      type: String,
      default: 'edit'
    },

    formTopic: String,

    containerClass: {
      type: String
    },

    editedAsset: {
      type: Object,
      required: true
    },

    metaResourceType: {
      type: String,
      required: true
    },

    onGroupChange: Function
  },

  mounted() {

    this.readyPossibleFieldGroups();

    window.c = this;
  },

  methods: {

    readyPossibleFieldGroups() {

      // get custom fields here (ones, applicable for broker && this.metaResourceType [video, listing, playlist, channel])
      // if multiple groups should be visualized, additional requests are needed, since the BE doesn't support it
      const params = {

        guid: this.organizationSelected.guid,
        deploymentType: 'broker'
      };

      const resourcesAsArr = this.metaResourceType.split('|');

      const requests = [];

      resourcesAsArr.forEach((r) => {

        requests.push(this.getMetaFieldsGroups({

          ...params,
          resource: r
        }));
      });

      Promise.all(requests).then(responses => {

        const fields = responses.flat();

        this.possibleCustomFieldGroups = [
          {
            name: 'Select',
            guid: ''
          },
          ...fields
        ];
      })
      .then(() => {
        this.addAutocompleteAttribute();
      });
    },

    addAutocompleteAttribute() {
      let inputs = document.querySelectorAll("div.complete > div > input");
      inputs.forEach((el) =>{
        let name = el.name;
        el.setAttribute("autocomplete", "on");
        el.setAttribute("id", name);
      })
    },

    getEnumOptionsAsObjects(stringsArray) {

      // add an empty option
      const objectsArray = [{
        label: 'Select',
        value: ''
      }];

      stringsArray.forEach(str => {

        objectsArray.push({
          label: str,
          value: str
        });
      });

      return objectsArray;
    },

    async changeApplicableGroupFields(newGuid, oldGuid) {

      this.onGroupChange && this.onGroupChange(newGuid, oldGuid);

      await this.$nextTick();

      this.addAutocompleteAttribute();

      /**
       * When the MetaGroupGuid changes, all custom fields from the old group,
       * that were saved on the editedAsset DTO have to be manually deleted,
       * so that they don't get saved with the newly selected MetaGroupGuid
       */
      if (oldGuid && newGuid !== oldGuid) {

        const currentSelectedGrp =
          this.possibleCustomFieldGroups.find((grp) => grp.guid === oldGuid);

        currentSelectedGrp && currentSelectedGrp.fields.forEach(f => {

          delete this.editedAsset[f.name];
        });
      }
    },

    ...mapActions({

      getMetaFieldsGroups: "app/getMetaFieldsGroups",
    }),
  },
  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected"
    }),

    editedAssetSelectedMetaGroupGuid() {

      return this.editedAsset && this.editedAsset.selectedMetaGroupGuid
    },

    selectedMetaGroup() {

      return this.possibleCustomFieldGroups
        .find(grp => grp.guid === this.editedAssetSelectedMetaGroupGuid);
    }
  },
  watch: {

    editedAssetSelectedMetaGroupGuid:{
      handler(newVal, oldVal) {
        this.changeApplicableGroupFields(newVal, oldVal);
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

.custom-meta-fields-legacy-component {

}
</style>
