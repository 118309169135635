<template>
<section class="event-sessions">

  <ioio-modal
    class="speakers-modal"
    :show.sync="isCreateSessionModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">

      <h3 class="speakers-modal__title">New Session</h3>

    </template>

    <section class="speakers-modal__form">
      <ioio-field
        type="text"
        required
        topic="newSessionForm"
        size="medium"
        label="Name"
        placeholder="Name"
        :maximum=100
        v-model="newSessionData.name" class="col-span-2"></ioio-field>

      <p class="m-0 mt-2 font-bold text-xs col-span-2">
        The event timezone is set to <span class="font-black">{{ eventData.timezone }}</span>. You can edit it from <span class="text-blue-500 cursor-pointer" @click="onOpenEditEventModal">here</span>
      </p>

      <ioio-field
        type="time"
        required
        topic="newSessionForm"
        size="medium"
        label="Start Time"
        placeholder="Start Time"
        v-model="newSessionData.startTime" class="w-full"></ioio-field>

      <ioio-field
        type="time"
        required
        topic="newSessionForm"
        size="medium"
        label="End Time"
        placeholder="End Time"
        v-model="newSessionData.endTime" class="w-full"></ioio-field>

      <p v-if="isNewSessionEndOutsideEventBounds"
        class="m-0 mb-2 font-bold text-xs col-span-2">
        * Since the end time of this session is set after the event ends the event end will change as well.
      </p>

      <ioio-tags

        topic="newSessionForm"
        label="Tags"
        class="col-span-2"
        v-model="newSessionData.tags"
        :tags="newSessionData.tags"
      />

    </section>

    <section class="speakers-modal__list">
      <header class="speakers-modal__list-header">
        <h3 class="speakers-modal__list-title">Speakers</h3>
        <ioio-button
          type="secondary"
          variant="outline"
          @click.stop.prevent="openAssignSpeakersModal()"
        >
          Add Speaker
        </ioio-button>
      </header>

      <ul class="speakers-modal__list-els">
        <li
          v-for="(s, key, index) in assignedSpeakersMap"
          :key="key"
          class="speakers-modal__list-el"
        >
          <div class="flex items-center">
            <img
              v-if="s.meta.avatar && s.meta.avatar.guid" :src="s.meta.avatar.src"
              class="speakers-modal__avatar"
            />
            <div
              class="speakers-modal__thumb"
              v-else
            >
              <ioio-icon icon="fas-user"/>
            </div>
            <p class="speakers-modal__list-el-name">{{ s.name }}</p>
          </div>

          <ioio-button
            type="danger"
            variant="tonal"
            class="mr-4"
            @click.stop.prevent="removeShortlistedSpeaker(index)"
          >
            remove
          </ioio-button>
        </li>
      </ul>
      <p
      v-if="!assignedSpeakersGuids.length"
      class="mb-32"
      >You haven't selected speakers for this session</p>
    </section>

    <template slot="footer">

      <p v-if="isNewSessionStartBeforeEventStart"
        class="m-0 mb-4 font-bold text-sm col-span-2">
        * The current session is set to start before the start of the event.
        Adjust the start of the event before you can create it this way.
      </p>

      <footer class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelCreateNewSession">Cancel</ioio-button>

        <ioio-button
          v-if="!isNewSessionStartBeforeEventStart"
          topic="newSessionForm"
          @click.stop.prevent="createSession">Create session</ioio-button>

        <ioio-button
          v-else
          type="primary"
          variant="outline"
          @click.stop.prevent="onOpenEditEventModal">Adjust</ioio-button>
      </footer>

      <ioio-loader v-else isShown></ioio-loader>

    </template>
  </ioio-modal>

  <ioio-modal
    class="speakers-modal"
    :show.sync="isEditSessionModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >

    <template slot="header">

      <h3 class="speakers-modal__title">Edit Session</h3>

    </template>

    <section class="speakers-modal__form">

      <ioio-field
        type="text"
        required
        topic="editSessionForm"
        size="medium"
        label="Name"
        placeholder="Name"
        v-model="editedSessionData.name" class="col-span-2"></ioio-field>

      <p class="m-0 mt-2 font-bold text-xs col-span-2">
        The event timezone is set to <span class="font-black">{{ eventData.timezone }}</span>. You can edit it from <span class="text-blue-500 cursor-pointer" @click="onOpenEditEventModal">here</span>
      </p>

      <ioio-field
        type="time"
        required
        topic="editSessionForm"
        size="medium"
        label="Start Time"
        placeholder="Start Time"
        v-model="editedSessionData.startTime" class="w-full"></ioio-field>

      <ioio-field
        type="time"
        required
        topic="editSessionForm"
        size="medium"
        label="End Time"
        placeholder="End Time"
        v-model="editedSessionData.endTime" class="w-full"></ioio-field>

      <p v-if="isEditedSessionEndOutsideEventBounds"
        class="m-0 mb-2 font-bold text-xs col-span-2">
        * Since the end time of this session is set after the event ends the event end will change as well.
      </p>

      <ioio-tags
        topic="editSessionForm"
        label="Tags"
        class="col-span-2"
        v-model="editedSessionData.tags"
        :tags="editedSessionData.tags"
      />
    </section>
    <section class="speakers-modal__list">
      <header class="speakers-modal__list-header">
        <h3 class="speakers-modal__list-title">Speakers</h3>

        <ioio-button
          type="secondary"
          variant="outline"
          @click.stop.prevent="openAssignSpeakersModal()"
        >
          Add Speaker
        </ioio-button>
      </header>

      <ul class="speakers-modal__list-els">
        <li
          v-for="(s, key, index) in assignedSpeakersMap"
          :key="key"
          class="speakers-modal__list-el"
        >
        <div class="flex items-center">
          <img
            v-if="s.meta.avatar && s.meta.avatar.guid" :src="s.meta.avatar.src"
            class="speakers-modal__avatar"
          />
          <div
            class="speakers-modal__thumb"
            v-else
          >
            <ioio-icon icon="fas-user"/>
          </div>
          <p class="speakers-modal__list-el-name">{{ s.name }}</p>
        </div>

        <ioio-button
          type="danger"
          variant="tonal"
          class="mr-4"
          @click.stop.prevent="removeShortlistedSpeaker(index)"
        >
          remove
        </ioio-button>
        </li>
      </ul>
      <p
      class="mb-28"
      v-if="!assignedSpeakersGuids.length"
      >You haven't selected speakers for this session</p>
    </section>


    <template slot="footer">

      <p v-if="isEditedSessionStartBeforeEventStart"
        class="m-0 mb-4 font-bold text-sm col-span-2">
        * The current session is set to start before the start of the event.
        Adjust the start of the event before you can create it this way.
      </p>

      <footer class="flex justify-between" v-if="!isRequestPending">

        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="triggerDeleteSession">Delete</ioio-button>

        <div>
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-4"
            @click.stop.prevent="cancelEditSession">Cancel</ioio-button>

          <ioio-button
            v-if="!isEditedSessionStartBeforeEventStart"
            topic="editSessionForm"
            @click.stop.prevent="editSession">Edit session</ioio-button>

          <ioio-button
            v-else
            type="primary"
            variant="outline"
            @click.stop.prevent="onOpenEditEventModal">Adjust</ioio-button>
        </div>


      </footer>

      <ioio-loader v-else isShown></ioio-loader>
    </template>
  </ioio-modal>

  <assign-speakers-to-event-modal-component
    :eventGuid="$route.query.id"
    :getAssignedSpeakers="getAssignedSpeakers"
    :initialSpeakers="assignedSpeakersMap"
    :onActiveSectionChanged="onActiveSectionChanged" />

  <header class="event-sessions__header">

    <h1 class="event-sessions__header-title">Agenda</h1>
    <div class="event-sessions__header-tools">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
        class="mr-2"
      />

      <ioio-button
        type="primary"
        class="ml-2"
        iconLeft="plus"
        @click="openCreateModal()"
      >
        Add session
      </ioio-button>

    </div>
  </header>




    <ul
      class="event-sessions__list"
      v-if="!isRequestPending && filteredEventSessions.length"
      ref="libraryScrollArea"
    >
      <li
        class="event-sessions__el"
        :key="index" v-for="(session, index) in filteredEventSessions"
      >
        <article>
          <p class="event-sessions__el-interval">
            {{ moment(session.startTime).format('MMM Do YYYY h:mma z Z') }} - {{ moment(session.endTime).format('MMM Do YYYY h:mma z Z') }}
          </p>
          <h3 class="event-sessions__el-title">{{ session.name }}</h3>
          <p class="event-sessions__el-speakers" v-if="Object.keys(session.speakers).length">
            <span
              class="event-sessions__el-speaker"
              v-for="(s, i) in session.speakers"
              :key="i"
            >
              <img
                class="event-sessions__avatar"
                v-if="s.meta.avatar && s.meta.avatar.guid"
                :src="s.meta.avatar.src"
              />

              <div
                class="event-sessions__thumb"
                v-else
              >
                <ioio-icon icon="fas-user"/>
              </div>
              <p>{{ s.name }}</p>
            </span>
          </p>

          <p class="event-sessions__el-speakers" v-else>No speakers assigned</p>

          <div class="" v-if="session.tags.length">
            <ioio-label
              v-for="(tag, i) in session.tags"
              :key="i"
              :text="tag"
              class="mr-2"
            >
              {{ tag }}
            </ioio-label>
          </div>

        </article>

        <ioio-button
          @click="openEditSessionModal(session, index)"
          type="secondary"
          variant="outline"
          class="ml-2"
        >
          Edit
        </ioio-button>


      </li>
    </ul>

    <p
      class="flex-col items-center event-sessions__el"
      v-else-if="!isRequestPending && !filteredEventSessions.length"
    >
      <img
        class="mb-5"
        src="~@assets/eventStyling/pages/events_creation/agenda.svg"
        alt=""
      />
      <span class="text-black-500">You haven't added any sessions yet</span>

      <ioio-button
        type="primary"
        variant="outline"
        class="mt-5"
        @click="openCreateModal()"
      >
        New session
      </ioio-button>
    </p>

    <div v-else style="overflow:hidden">
      <ioio-loader isShown />
    </div>



  <footer class="flex justify-center py1">
    <div v-if="!isRequestPending && filteredEventSessions.length" class="">

      <pagination-component
        :page-selected="eventSessionsSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="eventSessionsSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </div>
  </footer>

</section>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

export default {
  data: () => ({

    isRequestPending: false,
    eventData: {},
    sessions: [],

    newSessionData: {
      startTime: '',
      endTime: '',
      speakers: [],
      tags: [],

    },
    isCreateSessionModalVisible: false,

    editedSessionData: {

      name: '',
      startTime: '',
      endTime: '',
      speakers: [],
      tags: [],

    },
    isEditSessionModalVisible: false,
    editedSessionIndex: 0,

    assignedSpeakersGuids: [],
    assignedSpeakersMap: {},
  }),
  props: {
    onActiveSectionChanged: Function
  },

  mounted() {

    window.a = this
  },

  created() {

    this.moment = moment;

    const eventGuid = this.$route.query.id;

    this.triggerGetSessions(eventGuid);
  },

  beforeDestroy() {

    this.unstageFromFiltering('eventSessionsLibrary');
  },

  methods: {

    triggerDeleteSession() {

      // NOTE: possibly add confirm

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const updatedSessions = [...this.sessions];

      updatedSessions.splice(this.editedSessionIndex, 1);

      const payload = {

        data: {
          ...this.eventData,
          sessions: updatedSessions
        },
        guid: eventGuid
      };

      this.deleteEventSession(payload)
        .then(() => {

          this.$toasted.success(`Session ${this.$options.filters.truncate(this.editedSessionData.name, 64, "...")} was deleted successfully!`);

          this.cancelEditSession();

          this.triggerGetSessions(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });
    },

    removeShortlistedSpeaker(index) {

      const removedGuid = this.assignedSpeakersGuids[index];

      this.assignedSpeakersGuids.splice(index, 1);

      const updatedSpeakersMap = {

        ...this.assignedSpeakersMap
      };

      delete updatedSpeakersMap[removedGuid];

      this.assignedSpeakersMap = {
        ...updatedSpeakersMap
      };
    },

    openEditSessionModal(session) {

      this.setRedirectGuard({
        redirectMsg: 'Are you sure you want to leave this page?',
        redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
      });

      // _originalIndex is set when staging the sessions arr for filtering and is
      // not changed, regardless of filters and pagination applied at the moment
      this.editedSessionIndex = session._originalIndex;

      this.editedSessionData = {

        ...session
      };

      this.assignedSpeakersGuids = Object.keys(this.editedSessionData.speakers);

      this.assignedSpeakersMap = {
        ...this.editedSessionData.speakers
      };

      this.isEditSessionModalVisible = true;
    },

    cancelEditSession() {

      this.setRedirectGuard(false);

      this.isEditSessionModalVisible = false;

      this.editedSessionData = {

        name: '',
        startTime: '',
        endTime: '',
        speakers: [],
        tags: [],

      };

      this.editedSessionIndex = 0;
    },

    editSession() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const updatedSessions = [...this.sessions];

      const eventData = {

        ...this.eventData
      };

      if (this.isEditedSessionEndOutsideEventBounds) {

        eventData.endTime = new Date(this.editedSessionData.endTime).getTime();
      }

      updatedSessions.splice(this.editedSessionIndex, 1, {

        ...this.editedSessionData,
        speakers: this.assignedSpeakersMap
      });

      const payload = {

        data: {
          ...eventData,
          sessions: updatedSessions
        },
        guid: eventGuid
      };


      this.updateEventSession(payload)
        .then((resp) => {

          this.$toasted.success(`Session ${this.$options.filters.truncate(this.editedSessionData.name, 64, "...")} was successfully updated!`);

          this.cancelEditSession();

          this.triggerGetSessions(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });

    },

    openCreateModal() {

      this.setRedirectGuard({
        redirectMsg: 'Are you sure you want to leave this page?',
        redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
      });

      let prevSessionEnd = false;

      if (this.eventData.sessions && this.eventData.sessions.length) {

        const currentSessions = this.eventData.sessions;

        const lastSessionIndex = currentSessions.length - 1;

        const prevSession = this.eventData.sessions[lastSessionIndex];

        prevSessionEnd = new Date(prevSession.endTime).getTime();
      }


      // If prev sessions exist, the start should be prev session end, if no -> event start
      const defaultStart = prevSessionEnd || this.eventData.startTime;

      const defaultStartStamp = new Date(defaultStart).toISOString();
      const defaultEndStamp = new Date(defaultStart + 1800000).toISOString();

      // Set needed start values
      this.newSessionData = {

        ...this.newSessionData,
        startTime: defaultStartStamp,
        endTime: defaultEndStamp // default to 10mins after now
      };

      this.assignedSpeakersGuids = [];
      this.assignedSpeakersMap = {};

      this.isCreateSessionModalVisible = true;
    },

    openAssignSpeakersModal() {

      this.toggleAssignSpeakersToEventModalVisible(true);
    },

    getAssignedSpeakers(speakers, speakersMap) {

      this.assignedSpeakersGuids = speakers;
      this.assignedSpeakersMap = speakersMap;

      this.toggleAssignSpeakersToEventModalVisible(false);
    },

    cancelCreateNewSession() {

      this.setRedirectGuard(false);

      this.isCreateSessionModalVisible = false;

      this.newSessionData = {

        name: '',
        startTime: '',
        endTime: '',
        speakers: [],
        tags: [],

      };
    },

    onOpenEditEventModal() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            // Needed so that the modal has time to close
            setTimeout(() => {

              this.cancelCreateNewSession();
              this.cancelEditSession();

              // redirect to EditEvent seciton
              this.onActiveSectionChanged('edit');
            });
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        this.cancelCreateNewSession();
        this.cancelEditSession();

        // redirect to EditEvent seciton
        this.onActiveSectionChanged('edit');
      }
    },

    createSession() {

      const startStamp = new Date(this.newSessionData.startTime).getTime();
      const endStamp = new Date(this.newSessionData.endTime).getTime();

      if (startStamp >= endStamp) {

        this.$toasted.error('Start time should be after end time.');
        return;
      }

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const sessionsPayload = {

        ...this.newSessionData,
        speakers: this.assignedSpeakersMap
      };

      const eventData = {

        ...this.eventData
      };

      if (this.isNewSessionEndOutsideEventBounds) {

        eventData.endTime = new Date(this.newSessionData.endTime).getTime();
      }

      const payload = {

        data: {
          ...eventData,
          sessions: [
            ...this.sessions,
            sessionsPayload
          ]
        },
        guid: eventGuid
      };


      this.addEventSession(payload)
        .then((resp) => {

          this.$toasted.success(`Session ${this.$options.filters.truncate(this.newSessionData.name, 64, "...")} was successfully created!`);

          this.cancelCreateNewSession();

          this.triggerGetSessions(eventGuid);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    triggerGetSessions(eventGuid) {

      this.isRequestPending = true;

      this.getEventSessions({ guid: eventGuid }).then(resp => {

        this.eventData = { ...resp };
        this.sessions = resp.sessions || [];

        this.stageForFiltering({
          items: this.sessions,
          division: 'eventSessionsLibrary',
          addOriginalOrderIndex: true
        });

        this.applyFiltering({

          filterType: 'eventSessionsLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'eventSessionsLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'eventSessionsLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    ...mapMutations({
      toggleAssignSpeakersToEventModalVisible: "events/TOGGLE_ASSIGN_SPEAKERS_TO_EVENT_MODAL_VISIBLE",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      getEventSessions: "events/getEventSessions",
      addEventSession: "events/addEventSession",
      updateEventSession: "events/updateEventSession",
      deleteEventSession: "events/deleteEventSession",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({

      filteredEventSessions: "filterAndPaginate/filteredEventSessions",
      filteredItemsCount: "filterAndPaginate/filteredEventSessionsItemsCount",
      eventSessionsSearchOptions: "filterAndPaginate/eventSessionsSearchOptions",
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    isNewSessionEndOutsideEventBounds() {

      return new Date(this.newSessionData.endTime).getTime() > this.eventData.endTime;
    },

    isNewSessionStartBeforeEventStart() {

      return new Date(this.newSessionData.startTime).getTime() < this.eventData.startTime;
    },

    isEditedSessionEndOutsideEventBounds() {

      return new Date(this.editedSessionData.endTime).getTime() > this.eventData.endTime;
    },

    isEditedSessionStartBeforeEventStart() {

      return new Date(this.editedSessionData.startTime).getTime() < this.eventData.startTime;
    }


  },
}
</script>

<style lang="scss">

.event-sessions {
  @apply
  mx-auto
  max-w-7xl
  p-12;

  &__header {
    @apply
    flex
    items-center
    justify-between
    my-5;
  }

  &__header-title {
    @apply
    text-2xl;
  }

  &__header-tools {
    @apply flex;
  }

  &__list {
    @apply
    flex
    flex-col;
  }

  &__el {
    @apply
    flex
    bg-white
    p-8
    mb-5
    rounded-lg
    justify-between
    items-center
    shadow-card
    border
    border-blue-grey-100;
  }

  &__el-interval {
    @apply
    text-blue-grey-500
    mb-5;
  }

  &__el-speakers {
    @apply
    flex
    mt-4
    mb-6;
  }

  &__el-speaker {
    @apply
    mr-4
    pr-4
    flex
    items-center
    border-r
    border-black-100;

    &:last-child {
      @apply border-none;
    }

    p {
      @apply text-sm;
    }
  }

  &__avatar,
    &__thumb {
    @apply
    w-10
    h-10
    mr-2
    object-cover
    overflow-hidden
    rounded-full
    border
    border-blue-grey-100;
  }
  &__thumb {
    @apply
    flex
    text-base
    items-center
    justify-center
    text-dark-blue-500
    bg-dark-blue-100;
  }
}

.speakers-modal {

  &__form {
    @apply
    grid
    grid-cols-2
    gap-5
    mt-5;
  }

  &__list {
    @apply
    mt-5;
  }

  &__list-header {
    @apply
    flex
    mb-5
    justify-between
    items-center
    w-full;
  }

  &__list-els {
    @apply
    flex
    flex-col;
  }

  &__list-el {
    @apply
    flex
    mb-5
    pb-5
    justify-between
    items-center
    border-b
    border-dark-blue-100;

    &:last-child {
      @apply border-none;
    }
  }

  &__avatar,
  &__thumb {
    @apply
    w-20
    h-20
    mr-4
    object-cover
    overflow-hidden
    rounded-full
    border
    border-blue-grey-100;
  }
  &__thumb {
    @apply
    flex
    mr-7
    text-2xl
    items-center
    justify-center
    text-dark-blue-500
    bg-dark-blue-100;
  }
}

</style>
