<template>

<div class="library-vod-quick-actions-component vod-actions flex justify-end">

  <ioio-button
    type="secondary"
    variant="outline"
    size="small"
    class="mr-2 w-8 flex justify-center"
    @click="onEditClick"
  >
    <ioio-icon icon="fa-light fa-pen" />
  </ioio-button>


  <ioio-dropdown-base
    alignX="right"
    :fullWidth="false"
    class="flex items-center justify-center"
    ref="base"
    @change="toggle"
  >
    <template slot="title">
      <ioio-button
        type="secondary"
        variant="outline"
        size="small"
        class="w-8 flex justify-center"
      >
        <ioio-icon icon="fas fa-ellipsis-h" />
      </ioio-button>
    </template>


    <ioio-button
      v-for="vodAction in vodActions"
      :key="vodAction.label"
      class="dropdown__menu-item px-4 overflow-x-hidden w-48"
      :class="vodAction.class"
      type="minimal"
      @click.stop.prevent="() => actionHandler(vodAction.method, vod._source)"
    >{{ vodAction.label }}
    </ioio-button>

    <modal-vod-action-btn-component
      :vod="vod._source"
      openFlagName="isDownloadModalOpened"
      v-if="vod._source.status !== 'error'"
    >
      <ioio-button
        class="dropdown__menu-item px-4 overflow-x-hidden w-48"
        type="minimal"
      >Download
      </ioio-button>
    </modal-vod-action-btn-component>
    <delete-vod-component
      v-if="vod._source.status !== 'delete' && hasDeletePermission"
      :vod="vod._source"
    >
      <ioio-button
        class="border-top mt-2 dropdown__menu-item px-4 overflow-x-hidden w-48"
        type="minimal"
      >Delete
      </ioio-button>
    </delete-vod-component>
    <archive-vod-component
      v-if="hasArchivePermission"
      :vod="vod._source"
    >
      <ioio-button
        class="dropdown__menu-item px-4 overflow-x-hidden w-48"
        type="minimal"
      >{{ vod._source.status !== 'archive' ? 'Archive' : 'Unarchive' }}
      </ioio-button>
    </archive-vod-component>
  </ioio-dropdown-base>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    vodActions: [{

      label: 'Share',
      method: ''
    }, {

      label: 'Insights',
      method: 'openInsights'
    }],
  }),
  props: {
    vod: Object,
    hasDeletePermission: Boolean,
    hasArchivePermission: Boolean,
    onEditClick: Function,
    openInsights: Function
  },
  methods: {

    actionHandler(method, vod) {

      this[method](vod);
    },

    toggle(value) {
      if (value) {
        this.setClickedVodId(this.vod._id);
      }
    },

    ...mapMutations({
      setClickedVodId: 'library/SET_CLICKED_VOD_ID'
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      clickedVodId : 'library/clickedVodId'
    })
  },
  watch : {
    clickedVodId() {
      if (this.clickedVodId != this.vod._id) {

        this.$refs.base.close();
      }
    }
  }
}
</script>

<style lang="scss">


</style>
