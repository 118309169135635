<template>
<div class="custom-vocabularies-modal-component">
  <ioio-modal
    v-if="isEditVocabulariesModalOpened"
    externalClasses="custom-vocabularies-modal-component"
    :show="isEditVocabulariesModalOpened"
    size="medium"
    :noClose="true"
    :clickOutside="false"
  >
    <ioio-drawer class="custom-vocabularies-edit-drawer" :size="400" :show="openWordsEditDrawer">

      <div class="flex justify-between items-center p-4 mb-8">
        <h1>
          {{'Edit '+ editedWord.name}}
        </h1>
        <ioio-button
          class="text-large"
          size="large"
          type="button"
          @click="onCloseEditDrawer"
        >
          <ioio-icon icon="fal-times" />
        </ioio-button>
      </div>
      <ioio-logical-edit @changed="changeEditWord" class="px-4 mb-12">

        <ioio-field
          :disabled="editType === 'preview'"
          v-model="editedWord.phrase"
          type="text"
          placeholder="Type phrase"
          required
          label="Phrase"
          class="mb-2 w-full"
          topic="editWord"
          :error="phraseError"
        />
        <h5 class="text-xs mb-4 text-black-400"> *The Phrase can only consist of letters and dashes!</h5>

        <ioio-field
          :disabled="editType === 'preview'"
          v-model="editedWord.displayAs"
          type="text"
          placeholder="Type how to display phrase"
          :hasClearBtn="true"
          label="Display As"
          class="mb-2 w-full"
        />
        <ioio-toggle
          :disabled="editType === 'preview'"
          v-model="applyPhrase"
          labelRight="Apply the same Phrase as a Display As"
          size="medium"
          color="blue"
          class="mb-8"
          @input="applyPhraseAtDisplay"
        />
        <h3 class="my-4 text-xs text-red-600">*Note: You cannot have an entry for both Sounds Like and Phonetic spellings for the same phrase</h3>
        <ioio-field
          v-model="editedWord.ipa"
          type="text"
          placeholder="Type how to spelling"
          :hasClearBtn="true"
          label="Phonetic spellings"
          class="mb-2 "
          :disabled="editedWord.soundsLike != '' || editType === 'preview'"
          topic="editWord"
          :error="ipaError"
        />
        <h5 class="text-xs mb-4 text-black-400" > *The Phonetic spellings can only consist of characters of the International Phonetic Alphabet (IPA)!</h5>
        <ioio-field
          v-model="editedWord.soundsLike"
          type="text"
          placeholder="Type how to soundsLike"
          :hasClearBtn="true"
          label="Sounds like"
          class="mb-2"
          :disabled="editedWord.ipa != '' || editType === 'preview'"
          topic="editWord"
          :error="soundsLikeError"
        />
        <h5 class="text-xs mb-4 text-black-400"> *The Sounds like can only consist of letters and dashes!</h5>
      </ioio-logical-edit>
      <div class="flex drawer-buttons">
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="onCloseEditDrawer"
        >
          Cancel
        </ioio-button>
        <ioio-button
          :disabled="editType === 'preview'"
          type="primary"
          variant="outline"
          topic="editWord"
          @click.stop.prevent="applyEditedWord"
        >
          Apply
        </ioio-button>
      </div>

    </ioio-drawer>

    <template slot="header" >
      <div class="pb-3 flex items-center justify-between">
        <h1 class="text-2xl">
          {{ editHeaderText}}
        </h1>
        <ioio-button
          class="text-large"
          size="large"
          type="button"
          @click="onCloseEditModal()"
          data-dismiss="modal"
        >
          <ioio-icon icon="fal-times" />
        </ioio-button>
      </div>
    </template>

    <ioio-logical-edit  @changed="changeCreate">

      <ioio-tabs size="medium" :notification="false" class="" @input="setSelectedTabName">
        <ioio-tab name="Details" :selected="selectedTabName === 'Details'" class="pr-4" ref="details">
          <ioio-field
            v-model="selectedVoc.name"
            type="text"
            placeholder="Name"
            required
            :disabled="editType === 'preview'"
            :hasClearBtn="true"
            label="Name"
            class="mb-4"
            maximum="200"
            regex="[a-zA-Z0-9-_\s]+"
            topic="createVoc"
            @onValidate="showNameField"
          />
          <ioio-dropdown
            v-if="!filterModel"
            :items=vocTypes
            titleProp="name"
            valueProp="name"
            :disabled="editType != 'new'"
            v-model="selectedVoc.type"
            placeholder="Select type"
            :multiple="false"
            label="Type"
            class="mb-4"
            @input="checkType"
            topic="createVoc"
          />
          <ioio-dropdown
            :items="allPossibleLanguages"
            titleProp="name"
            valueProp="name"
            :disabled="selectedVoc.type === 'medical' || editType != 'new'"
            v-model="selectedVoc.lang"
            placeholder="Select language"
            :multiple="false"
            label="Language"
            class="mb-8"
            topic="createVoc"
          />
        </ioio-tab>
        <ioio-tab name="Words" :selected="selectedTabName === 'Words'" ref="words" >
          <div class="flex mb-2 w-full pr-4">
            <ioio-field
              v-model="addedWords"
              type="text"
              placeholder="Type words and phrase, separated by comma"
              :disabled="editType === 'preview'"
              :hasClearBtn="true"
              required
              label="Add phrase"
              class="mr-2 w-full"
              topic="addPhrase"
              requered
              @submit="addingWords"
            />
            <ioio-button
              :disabled="editType === 'preview'"
              type="primary"
              variant="tonal"
              topic="addPhrase"
              class="add-button"
              @click.stop.prevent="addingWords"
            >
              Add
            </ioio-button>
          </div>
          <h5 v-if="errorPhrase.length" class="text-xs mb-2 text-red-600"> *Invalid character found in phrases <span v-for="err,i in errorPhrase" :key=i>'{{ err }}'{{ i < errorPhrase.length -1 ? ', ': '' }}</span></h5>
          <h5 v-if="longPhrase" class="text-xs mb-2 text-red-600 break-words" > *The {{ longPhrase }} phrase contains more than 256 characters! </h5>
          <h5 class="text-xs mb-6 text-black-400"> *The field 'Add phrase' can only consist of letters and dashes!</h5>
          <div class="flex items-center mb-4 pr-4">
            <span v-for="value, letter in addedPhrases" :key="letter" class="text-blue-800 mr-2 pointer" @click="scrollToLetter(letter)">{{ letter }}</span>

          </div>
          <div class="flex items-center mb-4 pr-4" v-if="Object.keys(addedPhrases).length">
            <ioio-checkbox
              :disabled="editType === 'preview'"
              class="pt-1"
              :label="wordCount > 1 ? `Check all ${wordCount} words` : 'Check 1 word'"
              size="small"
              v-model="allChecked"
              @input="checkAllWords"
            />

            <ioio-button
              v-if="wordChecked > 0"
              class="ml-auto"
              type="danger"
              variant="tonal"
              size="small"
              @click.stop.prevent="removeWords"
            >
              Delete
            </ioio-button>
          </div>
          <div class="overflow-y-scroll h-96 pr-2 pb-12">
            <div v-for="words, letter, i in addedPhrases" :key="i" >
              <h3 class="pl-2 w-full bg-black-200 mb-4" :ref="letter">
                {{ letter }}
              </h3>
              <div class="pl-2 mb-4 " v-for="word,i in words" :key="word.phrase">
                <div class="flex phrases">
                  <ioio-checkbox
                    :disabled="editType === 'preview'"
                    size="small"
                    v-model="word.checked"
                    @input="checkWord"
                  />

                  <h3
                    class="phrases-text"
                    data-text
                    :contenteditable="filterModel"
                    required
                    @input="(ev) =>changeText(ev,letter,i)"
                    @click="filterModel ? null : openDrawer(letter,i)"
                  >
                    {{ word.phrase }}
                  </h3>
                  <ioio-button
                    :disabled="editType === 'preview'"
                    class="ml-auto"
                    size="xs"
                    type="danger"
                    variant="tonal"
                    icon="fal-trash"
                    @click.stop="deleteWord(letter,i)"
                  />
                </div>

                <h5
                  v-if="word.validate === false"
                  class="text-xs mb-2 text-red-600"
                > {{ word.phrase ? wordValidateError : 'The phrase must contain at least one letter!' }}</h5>

              </div>
            </div>
          </div>
        </ioio-tab>
        <ioio-tab v-if="editType != 'preview'" name="Import CSV" ref="csv" class="dropzone-tab" :selected="selectedTabName === 'Import CSV'">
          <div class="h-full dropzone-container" :class="{ 'staged-files-present': isUpload}">
            <vue-dropzone
              :options="dropzoneOptions"
              :include-styling="false"
              id="customdropzone"
              class="h-full"
              ref="dropzoneComponent"
              :useCustomSlot="true"
            >
              <div  class="dropzone-custom-content flex flex-column items-center">

                <h3 class="text-base text-black-800 mb-2">
                  Drag and drop your file here
                </h3>
                <h5 class="text-xs text-black-500 mb-4">
                  We support .csv
                </h5>

                <section class="flex">

                  <ioio-button
                    type="primary"
                    variant="solid"
                    size="small"
                    iconLeft="fad-cloud"
                  >
                    Browse
                  </ioio-button>
                </section>
              </div>
            </vue-dropzone>
          </div>
          <div v-if="isUpload" class="mt-16">
            <h3 class="mb-8">
              Vocabulary file uploaded successfully!
            </h3>
            <h3 class="mb-16">
              Press the Apply button to apply the data or Browse button to upload another file.
            </h3>
            <div class="flex justify-end">

              <ioio-button
                type="secondary"
                variant="outline"
                class="mr-4"
                @click.stop.prevent="applyCsvData"
              >
                Apply
              </ioio-button>
              <ioio-button
                type="primary"
                variant="outline"
                @click.stop.prevent="browseFile"
              >
                Browse
              </ioio-button>
            </div>
          </div>
        </ioio-tab>
      </ioio-tabs>

    </ioio-logical-edit>

    <template slot="footer" v-if="selectedTabName != 'Import CSV'">
      <div class="flex justify-end" v-if="!isVocabularyUpdating">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="onCloseEditModal()"
        >
          Cancel
        </ioio-button>
        <ioio-button
          v-if="editType != 'preview'"
          :disabled="!isValidWords"
          type="danger"
          variant="tonal"
          topic="createVoc"
          @click.stop.prevent="saveVocabulary"
        >
          Save
        </ioio-button>
      </div>
      <ioio-loader v-else isShown />
    </template>

  </ioio-modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data() {
    const _this = this;
    return {

      addedPhrases: {},
      openWordsEditDrawer: false,
      editedWord: {},
      applyPhrase: false,
      phraseError: '',
      ipaError: '',
      soundsLikeError: '',
      selectedTabName: 'Details',
      vocTypes: [
        {name:'normal'},
        {name:'medical'}
      ],
      addedWords: '',
      errorPhrase: [],
      longPhrase: '',
      changeTextTimer: null,
      isValidWords: true,
      allChecked: false,
      wordChecked: 0,
      csvData:[],
      isUpload: false,
      isFormDirty: false,
      dropzoneOptions: {

        maxFilesize: null,
        url: '/',
        addRemoveLinks: true,

        accept(file, done) {

          const acceptedFiles = '.csv';

          var fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);

          if (!acceptedFiles.includes(fileExtension.toLowerCase())) {

            _this.$refs.dropzoneComponent.removeAllFiles()

            const errMsg = `The file ${_this.$options.filters.truncate(file.name, 64, '...')} is not a supported type.`;

            _this.$toasted.error(errMsg);

            /**
            * Calling done(...) with a parameter (errMsg) to tell Dropzone,
            * that the file type check has failed.
            */
            done(errMsg);

            return;
          }
          _this.isUpload = true;
          _this.getFile(file);
        }
      }
    }
  },
  props: {
    isEditVocabulariesModalOpened: {
      type: Boolean,
      default: false,
    },
    toggleEditModalOpened: {
      type: Function
    },
    editType: {
      type: String
    },
    selectedVoc: {
      type: Object,
    },
    allPossibleLanguages: {
      type: Array,
    },
    createVocabulary: {
      type: Function
    },
    isVocabularyUpdating: {
      type: Boolean
    },
    filterModel: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.selectedVoc.words) {

      this.selectedVoc.words.forEach(word =>{
        if (this.filterModel) {
          word.phrase = word.text;
        };
        this.addingToList(word);
      });
    };
  },
  methods: {
    changeText(ev, letter, i) {

      clearTimeout(this.changeTextTimer);

      this.changeTextTimer = setTimeout(() => {

        this.addedPhrases[letter][i].phrase = ev.target.innerText;

        if (this.addedPhrases[letter][i].phrase && this.testPhrase(this.addedPhrases[letter][i].phrase)) {
          delete this.addedPhrases[letter][i].validate;
        } else {
          this.addedPhrases[letter][i].validate = false;
        };
        this.validatedWords();

      }, 700);

    },
    saveVocabulary() {

      this.createVocabulary(this.addedPhrases);
      this.setRedirectGuard(false);
    },
    applyCsvData() {
      this.csvData.forEach(row => {
        let word = row.split(',');
        if (row === '' || !word.length || !word[0]) {
          return
        }
        let readyWord = {
          phrase: word[0].trim(),
          displayAs: word[1] ? word[1].trim() : '',
          ipa: word[2] ? word[2].trim() : '',
          soundsLike: word[2] || !word[3] ? '' : word[3].trim(),
          checked: false
        }

        if (!this.testPhrase(readyWord.phrase)
          || (!this.filterModel && readyWord.ipa && (!this.testConsecutiveWhitespace(readyWord.ipa) || !this.testIpa(readyWord.ipa)))
          || (!this.filterModel && readyWord.soundsLike && !this.testPhrase(readyWord.soundsLike)))
        {
          readyWord.validate = false;
        };

        this.addingToList(readyWord);
      })
      this.selectedTabName = 'Words';
      this.$refs.csv.isActive = false;
      this.$refs.words.isActive = true;
      this.validatedWords();
      this.isUpload = false;
    },
    browseFile() {
      this.csvData = [];
      this.isUpload = false;
    },
    getFile(file){
      if (file ) {
        const reader = new FileReader()
        reader.onload = () => {
          this.csvData = reader.result.split('\n');
          this.$refs.dropzoneComponent.removeAllFiles();
        }
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(file)
      }

    },
    checkWord (value) {

      if (value) {
        this.wordChecked ++;

      } else {
        this.wordChecked --;
      }
      if (this.wordChecked === 0) {
        this.allChecked = false;
      }
    },
    removeWords() {

      for (const key in this.addedPhrases) {

        this.addedPhrases[key] = this.addedPhrases[key].filter(word => word.checked == false);
        if (!this.addedPhrases[key].length) {
          delete this.addedPhrases[key];
        }
      };

      this.wordChecked = 0;
      this.allChecked = false;
    },
    checkAllWords(value) {

      this.allChecked = value;

      if (value) {

        for (const key in this.addedPhrases) {

          this.addedPhrases[key].forEach(word =>{
            word.checked = true;
            this.wordChecked++;
          })
        }
      } else {
        for (const key in this.addedPhrases) {

          this.addedPhrases[key].forEach(word =>{
            word.checked = false;

          })
        }
        this.wordChecked = 0;
      }
    },
    scrollToLetter(letter) {
      let element = this.$refs[letter][0];
      element.scrollIntoView({ behavior: "smooth"});
    },
    addingWords() {

      this.errorPhrase = [];
      this.longPhrase = '';
      let phrases = this.addedWords.split(',');

      for (let index = 0; index < phrases.length; index++) {
        const element = phrases[index];
        let phrase = element.trim()
        let checked = true;
        if (phrase.length > 256) {
          this.longPhrase = phrase;
          break
        }
        if (!this.testPhrase(phrase)) {
          this.errorPhrase.push(phrase)
          checked = false
        }
        if (phrase && checked) {
          let word = {
            phrase,
            soundsLike: "",
            displayAs: "",
            ipa: "",
            checked: this.allChecked,
            validate: true
          }
          this.addingToList(word);
        }

      };

      if (this.errorPhrase.length) {
        this.addedWords = this.errorPhrase.join(', ');
      } else {
        this.addedWords = '';
      };
    },
    checkType(value) {
      if (value === 'medical') {
        this.selectedVoc.lang = 'en-US'
      }
    },
    showNameField(value) {

      if (!value && this.selectedTabName != 'Details') {
        this.selectedTabName = 'Details';
        this.$refs.words.isActive = false;
        this.$refs.details.isActive = true;
      }
    },
    setSelectedTabName(name) {

      this.selectedTabName = name;
    },
    openDrawer(letter, index) {

      this.editedWord = {...this.addedPhrases[letter][index]};
      this.editedWord.name = this.addedPhrases[letter][index].phrase;
      this.editedWord.letter = letter;
      this.editedWord.index = index;
      this.openWordsEditDrawer = true;

    },
    applyPhraseAtDisplay(value) {
      if (value) {
        this.editedWord.displayAs = this.editedWord.phrase;
      } else {
        this.editedWord.displayAs = ''
      }
    },
    applyEditedWord() {
      // Manual validation of the Phrase, Ipa and SoundsLike property.
      // To be removed when we add user-friendly text for validation with regex in the design system.
      if (!this.testPhrase(this.editedWord.phrase)) {
        this.phraseError = `Invalid character found in phrase '${this.editedWord.phrase}'`;
        return
      } else {
        this.phraseError = '';
      }

      this.editedWord.ipa = this.editedWord.ipa.trim();
      if (!this.testConsecutiveWhitespace(this.editedWord.ipa)) {
        this.ipaError = 'Invalid value. Consecutive whitespace characters in "IPA" data are not allowed';
        return
      } else {
        this.ipaError = '';
      }

      if (!this.testIpa(this.editedWord.ipa)) {
        this.ipaError = 'Invalid value. Invalid character found in "IPA" data';
        return
      } else {
        this.ipaError = '';
      }

      if (!this.testPhrase(this.editedWord.soundsLike)) {
        this.soundsLikeError = `Invalid character found in phrase '${this.editedWord.soundsLike}'`;
        return
      } else {
        this.soundsLikeError = '';
      }
      const letter = this.editedWord.letter;
      const index = this.editedWord.index;
      this.deleteWord(letter, index);
      delete this.editedWord.name;
      delete this.editedWord.letter;
      delete this.editedWord.index;
      delete this.editedWord.validate;
      const newWord = {...this.editedWord};

      this.addingToList(newWord);
      this.openWordsEditDrawer = false;
      this.applyPhrase = false;
      this.editedWord = {};
      this.validatedWords();
      this.changeCreate();
    },
    deleteWord(letter, index) {
      this.addedPhrases[letter].splice(index,1);
      if (!this.addedPhrases[letter].length) {
        delete this.addedPhrases[letter]
      };
    },
    testPhrase(phrase) {
      if (/^[a-zA-Z'\-.]*$/g.test(phrase)) {
        return true;
      } else {
        return false;
      }
    },
    testIpa(ipa) {
      const charsIpa = [
        'aʊ', 'aɪ', 'b', 'd', 'eɪ', 'f', 'g', 'h', 'i', 'j', 'k',
        'l', 'l̩', 'm', 'n', 'n̩', 'oʊ', 'p', 's', 't', 'u', 'v',
        'w', 'z', 'æ', 'ð', 'ŋ', 'ɑ', 'ɔ', 'ɔɪ', 'ə', 'ɛ', 'ɝ',
        'ɡ', 'ɪ', 'ɹ', 'ʃ', 'ʊ', 'ʌ', 'ʍ', 'ʒ', 'ʤ', 'ʧ', 'θ',
      ];
      if (ipa && ipa.split(' ').some(char => !charsIpa.includes(char))) {
        return false;
      } else {
        return true;
      }
    },
    testConsecutiveWhitespace (ipa) {
      if (/\s{2,}/g.test(ipa)) {
        return false;
      } else {
        return true;
      }
    },
    addingToList(word) {

      let firstLetter =  word.phrase.charAt(0).toUpperCase();

      if (this.addedPhrases.hasOwnProperty(firstLetter)) {
        if (this.addedPhrases[firstLetter].some(el => el.phrase === word.phrase)) {
          this.$toasted.error(`This phrase "${word.phrase}" already exist`);
          this.scrollToLetter(firstLetter)
          return
        } else {
          this.addedPhrases[firstLetter].push(word);
        }
      } else {
        this.addedPhrases[firstLetter] = [word];
      }

      const ordered = Object.keys(this.addedPhrases).sort().reduce(
        (obj, key) => {
          obj[key] = this.addedPhrases[key];
          return obj;
        },
        {}
      );
      this.addedPhrases = ordered;
    },
    changeEditWord() {
      this.isFormDirty = true;
      this.setRedirectGuard({
        redirectMsg: 'Are you sure you want to close edit drawer?',
        redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
      });
    },

    changeCreate() {
      if (this.editType != 'preview')
      this.setRedirectGuard({
        redirectMsg: `Are you sure you want to close ${this.editType === 'new' ? 'Create vocabulary' : 'Edit' } modal?`,
        redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
      });
    },

    onCloseEditModal() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.toggleEditModalOpened(false);
            this.addedPhrases = {};
          }
        });

        this.raiseRedirectFlag(true);

        return;
      };

      this.toggleEditModalOpened(false);
      this.addedPhrases = {};
    },
    onCloseEditDrawer() {

      if (this.isActiveConfirmGuardSet && this.isFormDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

             // Reestablish the original videos not crop guard,
            // since it was overwritten by the metaDataForm guard
            this.changeCreate();
            this.isFormDirty = false;
            this.openWordsEditDrawer = false;
            this.editedWord = {};
          }
        });

        this.raiseRedirectFlag(true);

        return;
      };
      this.isFormDirty = false;
      this.openWordsEditDrawer = false;
      this.editedWord = {};
    },
    validatedWords() {
      let valid = true;

      for (const key in this.addedPhrases) {

        if (this.addedPhrases[key].some(phrase => phrase.validate == false)) {
          valid =  false;
          break
        }
      };

      this.isValidWords = valid;
    },
    ...mapMutations({
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
    }),
    ...mapActions({
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    editHeaderText() {
      let header = ''
      switch (this.editType) {
        case 'preview':
          header = 'Preview '+ this.selectedVoc.name;
          break;

        case 'edit':
          header = 'Edit '+ this.selectedVoc.name;
          break;

        case 'new':
          header = `Create new vocabulary ${this.filterModel ? 'filter' : ''}`;
          break;
      }

      return header;
    },
    wordCount() {
      let count = 0;

      for (const key in this.addedPhrases) {

        count += this.addedPhrases[key].length;
      };

      return count;
    },
    wordValidateError() {
      let text = ''
      this.filterModel
        ? text = 'Invalid character found in phrase.'
        : text ='There are errors in the applied data from the CSV file.';
        return text = text + 'Please click on the phrase to edit.'
    },
    ...mapGetters({
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    })
  },
}
</script>

<style lang="scss">
  .custom-vocabularies-modal-component{
    .phrases{

      .ds-checkbox [type="checkbox"]:not(:checked) + label.is-small:before,
      .ds-checkbox [type="checkbox"]:checked + label.is-small:before {

        margin-top: 0.5rem;
      }

      .ds-checkbox [type="checkbox"]:not(:checked) + label.is-small:after,
      .ds-checkbox [type="checkbox"]:checked + label.is-small:after {

        top: calc(50% + 4px);
      }
      .phrases-text {
        @apply
        cursor-pointer
        truncate
        outline-none;
        width: fit-content;
      }
      .phrases-text:focus-visible {
        @apply
        bg-black-200;
      }
    }
    .ds-modal__body,
    .ds-modal__header {
      padding-right: 1.25rem;
    }

    .ds-modal__dialog {

      height: 90%;
    }
    .custom-vocabularies-edit-drawer {
      &.ds-drawer{
        @apply
        absolute;
      }
      .ds-drawer__dialog.open.is-right {
        @apply
        bg-white
        overflow-hidden
        rounded-xl;

      }
      .ds-drawer__overlay {
        @apply
        rounded-xl;
      }
      .drawer-buttons {
        position: absolute;
        bottom: 2rem;
        right: 1rem;
      }
    }

    .dropzone-tab {
      position: relative;
      height: 35rem;
      margin-right: 1rem;
    }
    .dropzone-container {

      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;


    }
     #customdropzone {

      border: 1.5px dashed;
      @apply
      rounded-xl
      border-black-200
      flex
      justify-center
      items-center;
    }

    #customdropzone:hover {
      background-color: var(--color-blue-50);
      border: 2px dashed var(--windowBrColor);
    }

    .staged-files-present {

      position: absolute;
      height: 0;
      width: 0;
      overflow: hidden;
    }

    .add-button {
      margin-top: 35px;
    }
  }

</style>
