<template>
<div class="encoding-component">
  <div class="box bg-white rounded-3 mb2">
    <div v-show="!isLoadingData" class="flex items-center">
      <span class="size-3 truncate p-3">
        Auto Encode Video
      </span>
      <div class="p-3">
      <check-component
        type="checkbox"
        class="size-0"
        :val="!defaultData.value"
        :on-change="() => defaultData.value = !defaultData.value"/>
      </div>
      <div class="p-3">
      <button-component
        variant="primary"
        intent="success"
        size="size-s"
        @click="updateDefaultData()">Update
      </button-component>
      </div>
    </div>
    <div v-show="isLoadingData">
      <logo-loader-component width="50px" height="50px" class="mt3"/>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isLoadingData: true,
    defaultData: {}
  }),
  props: {},

  created () {


    this.getDefaultData();
  },
  methods: {

    getDefaultData() {

      this.getBrokerSettings('encode-later').then((defaultData) => {

        this.defaultData = defaultData;

      })
      .finally(() => {

        this.isLoadingData = false;
      });

    },

    async updateDefaultData(){

      this.isLoadingData = true;

      const updateVal = this.defaultData.value;

      await this.updateBrokerSettings({

        params: 'encode-later',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultData();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
