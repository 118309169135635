<template>
<div class="dashboard-upcoming-events-component">
  <div class="ue">
    <div class="ue__title">
      Upcoming events
      <ioio-button
        v-if="this.eventsList.length && !isRequestPending"
        type="minimal"
        class="ue__title-button"
        @click="openEventsList"
      >
        See all
      </ioio-button>
    </div>
    <div v-if="this.eventsList.length && !isRequestPending">
      <div
        class="ue__el"
        v-for="(event, i) in this.eventsList"
        :key="i"
      >
        <div class="ue__start-date">
          <p class="ue__start-date-month">
            {{ new Date(event.startTime).toLocaleString('en-us', { month: 'short' }) }}
          </p>
          <p class="ue__start-date-day">
            {{ new Date(event.startTime).getDate() }}
          </p>
          <p class="ue__start-date-time">
            {{ parseStartTime(event.startTime, moment().tz(event.timezone).format('z')) }}
          </p>
        </div>
        <div
          class="ue__name"
          @click="openEventDetails(event.guid)"
        >
          {{ event.name }}
        </div>
      </div>
    </div>
    <div
      class="ue__no-items"
      v-else-if="!this.eventsList.length && !isRequestPending"
    >
      <div class="ue__thumb">
        <ioio-icon icon="fa-calendar"/>
      </div>
      <p class="ue__nodata">
        No upcoming events
      </p>
    </div>
    <div class="ue__loader" v-else>
      <ioio-loader isShown />
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";
import { getHHMMSSFromTimestamp } from "@utils/helpers";

export default {
  data: () => ({
    isRequestPending: false,
    eventsList: [],
    // TO USE WHEN EVENTS ARE INDEXED FOR ELASTICK
    elasticOptions: {
      currentPage: 1,
      perPageItems: 3,
      sortBy: [{"startTime": "asc"}]
    },
    moment: {}
  }),
  props: {},
  created() {
    this.loadEventsList();
    this.moment = moment;
  },
  methods: {

    loadEventsList() {

      this.isRequestPending = true;


      const from =
        (this.elasticOptions.currentPage - 1) * this.elasticOptions.perPageItems;
      const size = this.elasticOptions.perPageItems;
      const sort = this.elasticOptions.sortBy;
      const searchOptions = {
        from,
        size,
        sort
      };
      searchOptions.query =  {
        "range": {
          "startTime": {
            "gte": Date.now()
          }
        }
      }

      this.getEventsElastic(searchOptions)
        .then((response) => {

          const events  = response.hits.hits.map(i => {
            return i._source;
          });

          this.eventsList = events;
        })
        .finally(() => {

          this.isRequestPending = false;
        });



    },

    parseStartTime(ms, timezone) {

      // NOTE: uncomment if 12Hour time will be displayed instead of timezone
      // return getHHMMSSFromTimestamp(ms, 'omitSeconds', 'use12Hour');

      return `${ getHHMMSSFromTimestamp(ms, 'omitSeconds') } ${ timezone }`;
    },

    openEventDetails(id) {

      this.$pushRoute(`/events-list/details?id=${id}`);
    },

    openEventsList() {

      this.$pushRoute(`/events-list`);
    },


    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // TO USE WHEN EVENTS ARE INDEXED FOR ELASTICK
      getEventsElastic: "events/getEventsElastic",
      getEvents: "events/getEvents",
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>
// ***********************************************
// Upcoming events
// ***********************************************
.ue {
  @apply flex flex-col;

  &__title {
    @apply
    flex
    justify-between
    items-center
    text-lg
    mt-6
    mb-3;

    &-button.ds-btn.is-minimal {
      @apply text-blue-600;
    }
  }

  &__el {
    @apply
    flex
    mt-3
    py-4
    px-2
    rounded
    shadow-card
    border
    border-black-100;
  }

  &__start-date {
    @apply
    w-24
    flex
    flex-shrink
    flex-col
    items-center
    justify-center;

    &-month {
      @apply text-xs uppercase;
    }

    &-day {
      @apply text-xl leading-7;
    }

    &-time {
      @apply
      text-center
      text-xs
      uppercase
      text-black-400;
    }
  }

  &__name {
    @apply
    flex
    items-center
    pl-4
    border-l
    border-black-100
    cursor-pointer;
  }

  &__no-items {
    @apply
    flex
    items-center
    mt-4;
  }

  &__thumb {
    @apply
    flex
    justify-center
    items-center
    rounded-lg
    w-10
    h-10
    bg-black-50
    text-black-500
    text-lg;
  }

  &__nodata {
    @apply
    ml-4
    text-sm
    text-black-500;
  }

  &__loader {
    @apply
    relative
    h-10
    mt-5;
  }
}

</style>
