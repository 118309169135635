/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><circle pid="0" data-color="color-2" cx="9" cy="8" r="2"/><path pid="1" d="M23 1H1a1 1 0 00-1 1v20a1 1 0 001 1h22a1 1 0 001-1V2a1 1 0 00-1-1zm-1 2v12l-5-5-6 7-5-4-4 4V3h20z"/></g>'
  }
})
