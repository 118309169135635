/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'managed-inputs': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 7.636a4.364 4.364 0 110 8.728 4.364 4.364 0 010-8.728zm-9.764 5.455H0v-2.182h2.236C2.727 6.36 6.36 2.727 10.91 2.236V0h2.182v2.236c4.549.491 8.182 4.124 8.673 8.673H24v2.182h-2.236c-.491 4.549-4.124 8.182-8.673 8.673V24h-2.182v-2.236c-4.549-.491-8.182-4.124-8.673-8.673zM12 4.364a7.636 7.636 0 100 15.272 7.636 7.636 0 000-15.272z" _fill="#4F4F4F"/>'
  }
})
