<template>

  <div
    class="sidebar-component flex flex-column flex-none fixed left-0"
    :class="{ collapsed: isSidebarCollapsed }"
  >
    <div class="p1">
      <button-component
        variant="minimal"
        shape="circle"
        @click="toggleSidebarOpened"
      >
        <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
      </button-component>
    </div>

    <header class="p2 flex items-center justify-between">
      <h3 class="m0 size-1">Channels</h3>
      <button-component
        variant="default"
        class="items-center"
        @click='show()'
      >
        <ioio-icon
          icon="fas-circle-plus"
          class="h-3.5 text-green-600 mr-2"
        />
        <span>Create</span>
      </button-component>
    </header>
    <header class="pb2 px1">
      <input-search-component :onSearchInput="handleInputSearch" size="small" placeholder="Search..." class="" style="max-height: 30px; background: transparent"/>
    </header>

    <nav class="channel-list-container px2 flex flex-column flex-auto">

      <DraggableHolderComponent
        @drop="triggerReorderChannelsList"
        group-name="channels-list"
        :get-child-payload="getChildPayload"
        :should-accept-drop="shouldAcceptDrop"
        drag-handle-selector=".drag-handle"
      >

        <DraggableComponent
          v-for="channel in filteredChannelsList"
          :key="channel.id"
          class="channel-tile-draggable"
        >
          <div
            @click="() => handleChannelChange(channel)"
            :class="{ 'bg-blue\+4': channel.guid === channelsMeta.selected.guid && !isChannelsReorderingEnabled, 'pointer': !isChannelsReorderingEnabled }"
            class="flex items-center p1 mb1 hover-bg-blue+5 rounded justify-between"
          >

            <a class="flex items-center link-reset size-2 gray-3 truncate">
              <span
                v-if="channel.active"
                class="square-05 circle bg-green inline-flex flex-none mr1"
              ></span>
              <span
                v-else
                class="square-05 circle bg-red inline-flex flex-none mr1"
              ></span>
              <span class="truncate" :class="{ 'pr3': isChannelsReorderingEnabled }">{{ channel.name }}</span>
            </a>
            <span
              :class="isChannelsReorderingEnabled ? '' : 'hide'"
              class="drag-handle pointer"
            >&#x2630;</span>
          </div>
        </DraggableComponent>
      </DraggableHolderComponent>
    </nav>

    <button-component
      @click="handleOpenInsightsOverview"
      variant="default"
      class="mb-2 mx-4"
      size="size-xs"
    >Insights overview
    </button-component>

    <div class="sidebar-footer mx2 py1 flex items-center justify-end">

      <button-component
        v-if="!isChannelsReorderingEnabled && !isFilteringInApplied"
        @click="enableChannelsReordering"
        variant="subtle"
        size="size-xs"
      >
        <ioio-icon
          icon="far-arrow-down-arrow-up"
          class="mr-2 w-4 h-4"
        /><span>Reorder</span>
      </button-component>

      <div v-else-if="isChannelsReorderingEnabled && !isFilteringInApplied">
        <button-component
          @click="saveChannelsOrder"
          variant="primary"
          size="size-xs"
          intent="success"
          class="mr-2"
        >
          <ioio-icon
            class="w-4 h-4"
            icon="fas-check"
          /><span>Save</span>
        </button-component>
        <button-component
          @click="cancelChannelsOrderChange"
          variant="subtle"
          size="size-xs"
          intent="danger"
        >
          <ioio-icon icon="fa-xmark" class="w-4 h-4"/>
          <span>Cancel</span>
        </button-component>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    isChannelsReorderingEnabled: false,
    dragOrigin: 'channelsReorderPanel',
    isFilteringInApplied: false,
  }),
  props: {},

  methods: {

    handleInputSearch(input) {

      const parsedInput = input.toLowerCase();

      const filteredChannels = this.channelsList.filter((channel) => {

        return (
          channel.name.toLowerCase().indexOf(parsedInput) !== -1 ||
          channel.guid.toLowerCase().indexOf(parsedInput) !== -1
        );
      });

      if (input) {

        this.applyFiltering(filteredChannels);
        this.isFilteringInApplied = true;

      } else {

        this.isFilteringInApplied = false;
      }
    },

    applyFiltering(filteredChannels) {

      this.filteredChannels = [...filteredChannels];
    },

    show() {
      this.$modal.show("create-channel");
    },

    enableChannelsReordering() {
      this.isChannelsReorderingEnabled = true;
    },

    saveChannelsOrder() {
      this.isChannelsReorderingEnabled = false;

      this.saveReorderedChannelsList().then(() => {

        this.$toasted.success('Your changes were saved successfully');
      });
    },

    cancelChannelsOrderChange() {
      this.isChannelsReorderingEnabled = false;
      this.setChannelsList(this.channelsListPristine);
    },

    ...mapMutations({
      toggleSidebarOpened: "app/TOGGLE_SIDEBAR_OPENED",
      setChannelsList: "channelManager/SET_CHANNELS_LIST",
      reorderChannelsList: "channelManager/REORDER_CHANNELS_LIST",
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      saveReorderedChannelsList: "channelManager/saveReorderedChannelsList",
      triggerChangeSelectedChannel: "channelManager/triggerChangeSelectedChannel",
      makeCheckIsChannelLockedRequest: "channelManager/makeCheckIsChannelLockedRequest",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    }),

    handleOpenInsightsOverview() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.openInsightsOverview()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.openInsightsOverview();
      }
    },

    openInsightsOverview() {

      this.$pushRoute('/statistics/channels');
    },

    handleChannelChange(channel) {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.changeSelectedChannel(channel)
        });

        this.raiseRedirectFlag(true);

      } else {

        this.changeSelectedChannel(channel);
      }
    },

    changeSelectedChannel(channel) {

      this.triggerChangeSelectedChannel(channel);

      /**
       * If the channel is locked for editing by another user
       * The controls to drag in the timeline will be disabled
       */
      this.makeCheckIsChannelLockedRequest(this.channelsMeta.selected.guid);

      /**
       * This will trigger a change in the playbackUrl, used in
       * LivePreview component. (If a preview was beeing played,
       * it will now play the newly opened channel preview)
       */
      this.setSingleSourcePlayed(null);

      /**
       * Update the query param with the selected channel guid,
       * since it will be used and loaded if a page reload occurs
       */
      this.$pushRoute({

        path: this.$route.fullPath,
        query: {
          'channel-guid': channel.guid
        }
      });

      this.toggleSidebarOpened();
    },

    triggerReorderChannelsList(dragData) {
      this.reorderChannelsList({
        newChannelIndex: dragData.addedIndex,
        ...dragData.payload
      });
    },

    getChildPayload(removedIndex) {
      return {
        oldChannelIndex: removedIndex,
        channelData: this.channelsList[removedIndex],
        origin: this.dragOrigin,
      };
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {
      if (sourceContainerOptions.groupName === "channels-list") {
        return true;
      }

      return false;
    }
  },

  computed: {
    ...mapGetters({
      sidebarState: "app/sidebarState",
      channelsList: "channelManager/channelsList",
      channelsListPristine: "channelManager/channelsListPristine",
      channelsMeta: "channelManager/channelsMeta",
      organizationSelected: "app/organizationSelected",
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    isSidebarCollapsed() {
      return this.sidebarState.isCollapsed;
    },

    filteredChannelsList() {

      return this.isFilteringInApplied ? this.filteredChannels : this.channelsList;
    }
  }
};
</script>

<style lang="scss" scoped>

/* sidebar */
.sidebar-component {
  background: var(--windowBgColor);
  color: var(--windowColor);
  width: 15rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.125);
  transform: translateX(-15rem);
  opacity: 0;
  transition: transform 0.2s cubic-bezier(0.03, 0.8, 0.28, 1),
  opacity 0.2s cubic-bezier(0.03, 0.8, 0.28, 1);
  will-change: transform, opacity;
  z-index: 7;
  height: 100%;
}

.channel-list-container {
  height: 0;
  margin-bottom: 1rem;
  margin-left: -.5rem;
  overflow: auto;
}

.sidebar-component.collapsed {
  transform: translateX(0);
  opacity: 1;
}

/* .drag-handle {
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
} */

.channel-tile-draggable {
  position: relative;

  &:last-of-type {
    margin-bottom: 50px;
  }
}

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

.green-1 {
    color: #57ad00;
}
</style>
