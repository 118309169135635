/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paragraph': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#111"><path pid="0" d="M9 10H2a1 1 0 01-1-1V2a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1zM9 23H2a1 1 0 01-1-1v-7a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1z"/><path pid="1" data-color="color-2" d="M13 2h10v2H13zM13 7h10v2H13zM13 15h10v2H13zM13 20h10v2H13z"/></g>'
  }
})
