<template>
  <div class="pagination-component" :class="{ hidden: calcedPageCount <= 1 }">

    <ioio-pagination
      @input="handleInput"
      :value="activePage"
      :pageCount="calcedPageCount"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({

  }),
  props: {
    pageSelected: Number,
    clickCallback: Function,
    perPageItems: {
      type: Number,
      required: true
    },
    totalItemsLength: {
      type: Number,
      default: 0,
      required: true
    },
  },
  methods: {

    handleInput(page) {

      if (!page) {

        return;
      }

      this.clickCallback(page);
    }
  },
  computed: {

    calcedPageCount() {

      return Math.ceil(this.totalItemsLength / this.perPageItems);
    },

    activePage: {

      get() {

        return this.pageSelected;
      },

      set(newName) {

        return newName;
      }
    }
  }
};
</script>

<style lang="scss">

.pagination-component {
  .ds-pagination {

    align-items: center;
  }
}
</style>
