<template>
<div class="transfer-acceleration-component">
  <div class="box bg-white rounded-lg mb-4">
    <div v-if="!isLoadingData && availableServise" class="flex items-center">
      <span class="text-xs truncate p-3">
        Transfer Acceleration is {{oldStatus ? 'enable' : 'disable'}}
      </span>
      <div class="p-3 pb-5">
        <ioio-checkbox
          size="small"
          v-model="defaultData.value"
          @input="(newVal) => defaultData.value = newVal"
        >
        </ioio-checkbox>
      </div>
      <div class="p-3">
        <ioio-button
          type="primary"
          size="small"
          :disabled="oldStatus === defaultData.value"
          @click="updateDefaultData()">Update
        </ioio-button>
      </div>
    </div>
    <div v-if="!isLoadingData && !availableServise" class="flex items-center">
      <span class="text-xs truncate p-3">
        This service is not available
      </span>
    </div>
    <div class="relative min-h-36" v-else-if="isLoadingData">
      <ioio-loader isShown />
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isLoadingData: true,
    defaultData: {},
    oldStatus: true,
    availableServise: false
  }),
  props: {},
  created () {

    this.getDefaultData();
  },
  methods: {

    getDefaultData() {

      this.getBrokerSettings('bucket-acceleration')
        .then((defaultData) => {

          this.availableServise = true;

          this.defaultData = defaultData;

          this.oldStatus = defaultData.value;
        })
        .catch(err => {
          if (err.code === 404) {

            this.availableServise = false;
          } else {

            this.$toasted.error(err.error);
          };
        })
        .finally(() => {

          this.isLoadingData = false;
        });
    },

    async updateDefaultData(){

      this.isLoadingData = true;

      const updateVal = this.defaultData.value;

      await this.updateBrokerSettings({

        params: 'bucket-acceleration',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultData();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
