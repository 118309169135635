<template>
<div class="sub-meta-form-component">
  <div class="mb-6">

    <ioio-toggle
      topic="editSub"
      size="medium"
      v-model="editDataFields.shouldEncode"
      labelLeft="Add to video">
    </ioio-toggle>
    <div class="text-xs text-black-400">This file will be processed when encoding the video. Once encoded you can't reverse it.</div>
  </div>

  <ioio-field
    topic="editSub"
    label="Title"
    type="text"
    required
    size="medium"
    placeholder="Subtitles title"
    v-model="editDataFields.screenName"
    class="mb-6"
  />

  <ioio-field
    topic="editSub"
    label="Description"
    type="textarea"
    size="medium"
    placeholder="Subtitles description"
    v-model="editDataFields.description"
    class="mb-6"
  />

  <ioio-dropdown
    v-model="editDataFields.lang"
    :items="languagesTranslateConfig"
    required
    multiple
    :multiselect="false"
    placeholder="Language"
    label="Select a language family"
    titleProp="label"
    valueProp="abbr"
    class="w-full mb-6"
  />

  <div class="mb-6">

    <ioio-toggle
      topic="editSub"
      size="medium"
      v-model="editDataFields.default"
      labelLeft="Make default subtitles">
    </ioio-toggle>
    <div class="text-xs text-black-400">Subtitles will be enabled as default</div>
  </div>

  <div class="mb-6">

    <ioio-toggle
      topic="editSub"
      size="medium"
      v-model="editDataFields.cc"
      labelLeft="Make close captions">
    </ioio-toggle>
    <div class="text-xs text-black-400">Captions include the dialogue and any other relevant sount (e.g. background noises) for more accessibility</div>
  </div>

  <div class="mb-6">

    <!-- @Stef autoTranslate is missing in this UI.. are labels ok -->
    <ioio-toggle
      topic="editSub"
      size="medium"
      v-model="editDataFields.autoTranslate"
      labelLeft="Auto translate">
    </ioio-toggle>
    <div class="text-xs text-black-400">Automatically generate a new file in a language set by your organization</div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {
    editDataFields: Object,
    languagesTranslateConfig: Array
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">
.sub-meta-form-component {
/**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/

  .multiselect__option .multiselect__option--highlight .multiselect__option--selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    @apply text-blue-grey-800;
  }
  .multiselect {
    width: 100%;
  }
  .multiselect__option.multiselect__option--highlight, .multiselect__option.multiselect__option--highlight:after {
    @apply text-blue-grey-800;

    /* background: orange !important; */
  }
  .multiselect__tag {

    margin-top: -3px;
    @apply text-blue-grey-800;
  }
  .multiselect__select {
    height: 18px;
  }
  .multiselect__tag-icon {

    line-height: 18px;
    margin-top: 2px;

    &:focus, &:hover {

      background: none;
    }
  }

  .multiselect__tag-icon:after {

    content: none;
  }

  .multiselect__single {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .multiselect__content-wrapper {

    /* background-color: var(--color-blue-50); */
  }
}
</style>
