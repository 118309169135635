<template>
<section class="cdn-settings-component">

  <section v-if="!isCdnDataUpdating">
    <vue-form :state="newCdnFormState" @submit.prevent>

      <div class="flex justify-center my3">

        <validate class="m0 mr2 ratio2-6" tag="label">
          <span class="mb1 block">Name</span>
          <input
            class="m0 input"
            type="text"
            placeholder="CDN Name"
            required
            name="newCdnName"
            v-model="newCdn.name"/>
          <field-messages name="newCdnName" show="$submitted || $dirty && $touched">
            <div slot="required">CDN Name is a required field</div>
          </field-messages>
        </validate>

        <validate class="m0 mr2 ratio2-6" tag="label">
          <span class="mb1 block">URL</span>
          <input
            class="m0 input"
            type="url"
            placeholder="CDN URL"
            required
            name="newCdnName"
            v-model="newCdn.url"/>
          <field-messages name="newCdnName" show="$submitted || $dirty && $touched">
            <div slot="required">CDN URL is a required field</div>
            <div slot="url">CDN URL should be a valid URL</div>
          </field-messages>
        </validate>

        <!-- NOTE: Type is yet to be discussed how it should be used. Hide for now -->
        <validate class="m0 mr1 ratio2-6" tag="label" v-if="false">
          <span class="mb1 block">Type</span>

          <select class="select m0" id="type" v-model="newCdn.type" name="selectedGrpAttachment">
            <option
            :key="item.guid"
            :value="item.guid"
            v-for="item in possibleCdnTypes"
            >{{ item.name }}</option>
          </select>
          <field-messages name="selectedGrpAttachment" show="$submitted || $dirty && $touched">

          </field-messages>
        </validate>

        <div class="pt1">
          <check-component
            type="checkbox"
            label="Default"
            class="mt3 size-0 "
            :val="newCdn.isDefault"
            :on-change="() => newCdn.isDefault = !newCdn.isDefault"/>
        </div>
        <footer class="ml3 mt3">
          <button-component variant="primary" intent="success" size="size-s" @click="sendCreateCdnRequest">Create</button-component>
        </footer>
      </div>

    </vue-form>

    <section>
      <h1 class="m0 pb2 size0">Added CDNs</h1>
      <div class="box bg-white rounded-3 mb2">
        <div class="table">
          <table>
            <thead>
              <tr>
                <th class="width+5">Name</th>
                <th class="width+3">URL</th>
                <!--th class="width+2">type</th-->
                <th class="width+2">Default</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!defaultCdnData.value">
                <td>
                  <span class="size-2">DEFAULT CDN</span>
                </td>
                <td>
                  <span class="size-2">DEFAULT CDN</span>
                </td>
                <!--td>
                  <span class="size-2">{{ cdn.type }}</span>
                </td-->
                <td>
                  <span class="size-2">
                    <ioio-icon
                      icon="fas-circle-check"
                      class="w-4 h-4 ml-3 text-green-600"
                    />
                  </span>
                </td>
                <td>
                </td>
              </tr>

              <tr v-for="(cdn, index) in availableCdns" :key="cdn.guid">
                <td>
                  <span class="size-2">{{ cdn.name }}</span>
                </td>
                <td>
                  <span class="size-2">{{ cdn.url }}</span>
                </td>
                <!--td>
                  <span class="size-2">{{ cdn.type }}</span>
                </td-->
                <td>
                  <span class="size-2" v-if="cdn.guid === defaultCdnData.value">
                    <ioio-icon
                      icon="fas-circle-check"
                      class="w-4 h-4 ml-3 text-green-600"
                    />
                  </span>
                </td>
                <td>
                  <div class="relative">


                    <button-component
                      variant="minimal"
                      size="size-s"
                      class="enable-btn"
                      shape="circle"
                      @click.stop="() => actionsVisibleForGuid = cdn.guid"
                      >
                      <ioio-icon icon="fas-ellipsis" class="h-4"/>
                    </button-component>
                    <dropdown-component v-if="actionsVisibleForGuid === cdn.guid" align="align-right" :onClose="() => actionsVisibleForGuid = false">
                      <ul class="list-reset m0">
                        <li
                          @click="setDefault(cdn)"
                          class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer">{{ cdn.guid === defaultCdnData.value ? 'Remove as Default' : 'Set as Default' }}
                        </li>
                        <li
                          class="p1 hover-blue hover-bg-blue+5 red flex items-center size-2 weight+1" style="cursor: default">

                          <delete-confirm-modal-component
                            dialogWidth="500"
                            dialogHeight="225"
                            dialogOpenBtnLabel="Delete"
                            dialogOpenBtnSize="size-xs"
                            :isDialogOpenBtnVisible="true"
                            delConfirmBtnLabel="Delete"
                            :delConfirmLabel="`Are you sure you want to delete CDN ${cdn.name}?`"
                            :delConfirmSecondaryLabel="`${cdn.guid === defaultCdnData.value ? 'This is the default CDN.' : '' }`"
                            :isDelConfirmBtnVisible="!isCdnDataUpdating"
                            :onDeleteConfirm="() => triggerDeleteCdn(cdn)"
                          />

                        </li>
                      </ul>
                    </dropdown-component>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </section>
  <logo-loader-component width="50px" height="50px" v-else class="mt3"/>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    actionsVisibleForGuid: false,

    availableCdns: [],

    possibleCdnTypes: [],

    defaultCdnData: {},
    newCdnFormState: {},
    newCdn: {
      name: '',
      type: '',
      url: '',
      isDefault: false,
    },

    isCdnDataUpdating: false
  }),
  props: {},

  created() {

    this.isCdnDataUpdating = true;

    this.getCdnSettings();

    this.updateCdnList();
  },

  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings",
      getCDNs: "channelManager/getCDNs",
      createCDN: "channelManager/createCDN",
      deleteCDN: "channelManager/deleteCDN",
    }),

    updateCdnList() {

      this.isCdnDataUpdating = true;

      this.getCDNs()
        .then(cdns => {

          this.availableCdns = cdns;

        }).finally(() => {

          this.isCdnDataUpdating = false;
        });
    },

    getCdnSettings() {

      this.isCdnDataUpdating = true;

      this.getBrokerSettings('cdn-guid').then((cdnData) => {

        this.defaultCdnData = cdnData
      })
      .finally(() => {

        this.isCdnDataUpdating = false;
      });
    },

    updateDefaultCdnSettings(cdnGuid) {

      this.isCdnDataUpdating = true;

      const updateVal = cdnGuid === this.defaultCdnData.value ? '' : cdnGuid;

      return this.updateBrokerSettings({
          params: 'cdn-guid',
          data: {

            value: updateVal
          }
        }).then(() => {

          this.getCdnSettings();
        });
    },

    resetNewCdnForm() {

      this.newCdn = {

        name: '',
        type: '',
        url: '',
        isDefault: false
      };
    },

    sendCreateCdnRequest() {

      if (this.newCdnFormState.$invalid) {

        this.newCdnFormState._submit();
        return;
      }

      this.isCdnDataUpdating = true;

      const { name, type, url } = this.newCdn;

      const payload = {

        name,
        type,
        url
      };

      this.createCDN(payload)
        .then((newCdnGuid) => {

          if (this.newCdn.isDefault) {

            this.updateDefaultCdnSettings(newCdnGuid)
              .then(() => {

                this.updateCdnList();
              });

          } else {

            this.updateCdnList();
          }

          this.resetNewCdnForm();
        })
        .catch(err => {

          this.isCdnDataUpdating = false;
        });
    },

    setDefault(cdn) {

      this.updateDefaultCdnSettings(cdn.guid);
    },

    triggerDeleteCdn(cdn) {

      this.isCdnDataUpdating = true;

      const isDeletedCdnDefault = cdn.guid === this.defaultCdnData.value;

      this.deleteCDN(cdn.guid).then(() => {

        if (isDeletedCdnDefault) {

          this.updateDefaultCdnSettings(cdn.guid).then(() => {

            this.updateCdnList();
          });

        } else {

          this.updateCdnList();
        }
      })
      .catch(err => {

        this.isCdnDataUpdating = false;
      });

    }
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>

.cdn-settings-component {
  th, label {
    @apply
    font-medium;
  }
}

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

.green-1 {
    color: #57ad00;
}


</style>
