const SET_STREAM_TO_NOW_OFFSET = (state, offset) => {

  state.streamToNowOffset = offset;
};


export {

  SET_STREAM_TO_NOW_OFFSET
};
