<template>

<vue-form
  :state="formState"
  @submit.prevent
  class="new-playlist-form-component">

  <section class="main-fields-section overflow-y p2" style="height: 530px">

    <validate class="mt-2">
      <label class="text-danger">Display Name <b>*</b></label>

      <input
        class="input truncate"
        type="text"
        placeholder="Display Name"
        v-model="formPlaylistData.name"
        required
        maxlen="100"
        name="formPlaylistDataName"/>
      <field-messages class="size-3" name="formPlaylistDataName" show="$submitted || $dirty && $touched">
        <div slot="required">Display Name is a required field</div>
        <div slot="maxlen">Display Name length should not be more than 100 characters</div>
      </field-messages>
    </validate>

    <validate class="mt-2">
      <label class="text-danger">Playlist Name</label>

      <input
        class="input truncate"
        type="text"
        placeholder="Playlist Name"
        v-model="formPlaylistData.title"
        maxlen="100"
        name="formPlaylistDataTitle"/>

      <field-messages class="size-3" name="formPlaylistDataTitle" show="$submitted || $dirty && $touched">
        <div slot="maxlen">Playlist Name length should not be more than 100 characters</div>
      </field-messages>
    </validate>

    <validate class="mt-2">
      <label>Description</label>
      <input
        class="input truncate"
        type="text"
        placeholder="Description"
        v-model="formPlaylistData.description"
        name="formPlaylistDataDescription"
        maxlen="500"
      >
      <field-messages class="size-3" name="formPlaylistDataDescription" show="$submitted || $dirty && $touched">
        <div slot="maxlen">Description length should not be more than 500 characters</div>
      </field-messages>
    </validate>
    <div class="flex mt-2">
      <div>
        <label>Image url</label>
        <input
        class="input"
        type="text"
        placeholder="Image url"
        v-model="formPlaylistData.imageUrl"
        >
      </div>

      <div class="relative ml2">
        <label>Color</label>
        <button-component @click="toggleDropdownOpened" :style="{backgroundColor: formPlaylistData.color || 'gray'}" style="width: 2rem; height: 2rem; border: 2px solid white; margin: 0.25rem 0px;">

        </button-component>

        <dropdown-component v-show="isDropdownOpened" :onClose="toggleDropdownOpened" width="20rem">
          <swatches
          v-model="formPlaylistData.color"
          :colors="possiblePlaylistColors"
          inline
          >
          </swatches>
        </dropdown-component>
      </div>

    </div>
    <div class="mt-2">
      <div>
        <label>External URL</label>
        <input
          type="text"
          class="input"
          placeholder="External URL"
          v-model="formPlaylistData.meta.externalUrl"
        >
      </div>
    </div>

    <custom-meta-fields-legacy-component
      class="pt2 mt3 border-top"
      :editedAsset="formPlaylistData.meta"
      metaResourceType="playlist" />

  </section>

  <section v-if="!isSaveUpdateInProgress" class="p2">

    <button-component
      v-if="isEditViable"
      variant="primary"
      intent=""
      size="size-m"
      :class="{ disabled: isSaveBtnDisabled }"
      class="mr-4"
      @click="handleOnUpdate"
    >{{ editBtnLabel }}</button-component>

    <button-component
      variant="default"
      intent=""
      size="size-m"
      class="mr-4"
      :class="{ disabled: isSaveBtnDisabled }"
      @click="handleOnSave"
    >{{ saveBtnLabel }}</button-component>


    <button-component
      variant="subtle"
      intent=""
      size="size-m"
      @click="onCancelSaveUpdate"
    >{{ cancelSaveUpdateBtnLabel }}</button-component>

  </section>

  <div class="m2" v-else>
    <loader-component class="mt3"/>
  </div>
</vue-form>
</template>

<script>

import { paletteColors } from "@utils/helpers";

export default {
  data: () => ({

    formState: {},
    isDropdownOpened: false,
    formPlaylistData: {
      color: '',
      imageUrl: '',
      name: '',
      title: '',
      description: '',
      meta: {

        externalUrl: '',
      }
    },

    possiblePlaylistColors: [...paletteColors()],

    isEditViable: false
  }),
  props: {
    isSaveDisabledFromOutsideConditions: Boolean,
    onSavePlaylist: Function,
    saveBtnLabel: {
      type: String,
      default: 'Save Playlist'
    },
    editedPlaylistInitialData: {
      type: Object
    },

    onUpdatePlaylist: Function,
    editBtnLabel: {
      type: String,
      default: 'Edit Playlist'
    },

    onCancelSaveUpdate: Function,
    cancelSaveUpdateBtnLabel: {
      type: String,
      default: 'Cancel'
    },
    isSaveUpdateInProgress: Boolean
  },

  mounted() {

    this.loadEditedPlaylistData(this.editedPlaylistInitialData);
  },

  methods: {

    handleOnSave() {

      this.onSavePlaylist(this.formPlaylistData);
    },

    handleOnUpdate() {

      this.onUpdatePlaylist(this.formPlaylistData);
    },

    toggleDropdownOpened() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },

    loadEditedPlaylistData(newVal) {

      if (newVal) {

        this.isEditViable = true;

        this.formPlaylistData = {

          color: newVal.color,
          imageUrl: newVal.imageUrl,
          name: newVal.name,
          title: newVal.title,
          description: newVal.description,
          meta: newVal.meta || {
            externalUrl: ''
          }
        };

      } else {

        this.isEditViable = false;

        this.formPlaylistData = {
          color: '',
          imageUrl: '',
          name: '',
          title: '',
          description: '',
          meta: {
            externalUrl: '',
          }
        };
      }
    }
  },
  computed: {

    isSaveBtnDisabled() {

      return this.isSaveDisabledFromOutsideConditions ||
        this.formState.$invalid;
    }
  },

  watch: {

    editedPlaylistInitialData(newVal) {

      this.loadEditedPlaylistData(newVal);
    }
  }
}
</script>

<style lang="scss" scoped>

  /**
   * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
   * Tailwind classes are present in the HTML, so deleting the following lines,
   * when styleberry is deprecated should still work.
  **/

  @import "@indexScss";

  .new-playlist-form-component {

    @include overwrite-styleberry-styles-inputs;
  }


</style>
