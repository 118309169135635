<template>
<section  class="listing-scheduler-component flex flex-column flex-auto calendar-section rounded-2 p2 pr4">

  <modal
    name="create-listing-modal"
    width="600"
    height="760"
    classes="v--modal p2 mt2"
  >
    <section class="h100 flex flex-column">
      <header class="ratio1-1 flex justify-between p2">

        <h1 class="m0 size+1 mb2">
          New Block
        </h1>
        <button-component class="" variant="minimal" shape="circle" @click.stop.prevent="() => closeCreateListingModal()">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
        </button-component>

      </header>

      <vue-form :state="createListingFormState" @submit.prevent class="overflow-y p2 flex flex-column flex-auto">



        <validate class="" tag="label">
          <label class="">Title <span class="text-danger">*</span></label>
          <input
            class="input"
            type="text"
            placeholder="Title"
            required
            name="title"
            maxlen="100"
            v-model="newListingData.title"/>
          <field-messages name="title" show="$submitted || $dirty && $touched">
            <div slot="required">Title is a required field</div>
            <div slot="maxlen">Title length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <div class="mr2 ratio1-2">
          <label class="mb1" for="start-date-time">Start Date & Time <span class="text-danger">*</span></label>

          <datetime
            v-model="newListingData.start"
            :min-datetime="newListingStartMinDateTime"
            :max-datetime="newListingStartMaxDateTime"
            input-id="startDateTime"
            type="datetime"
            use12-hour
            placeholder="Start Date Time"
            format="DDD t ZZZZ"
          ></datetime>

        </div>

        <div class="mr2 ratio1-2">
          <label class="mb1" for="end-date-time">End Date & Time <span class="text-danger">*</span></label>

          <datetime
            v-model="newListingData.end"
            :min-datetime="newListingEndMinDateTime"
            :max-datetime="newListingEndMaxDateTime"
            input-id="endDateTime"
            type="datetime"
            use12-hour
            placeholder="End Date Time"
            format="DDD t ZZZZ"
          ></datetime>

        </div>

        <validate>
          <label for="meta['description']">Description</label>
          <input
            type="text"
            id="meta['description']"
            placeholder="Description"
            v-model="newListingData.description"
            name="description"
            maxlen="500"
          >
          <field-messages name="description" show="$submitted || $dirty && $touched">
            <div slot="maxlen">Description length should not be more than 500 characters</div>
          </field-messages>
        </validate>
        <div>
          <label for="meta['imageUrl']">Image URL</label>
          <input
            type="text"
            id="meta['imageUrl']"
            placeholder="Image URL"
            v-model="newListingData.imageUrl"
          >
        </div>
        <div>
          <label for="meta['color']">Color </label>

          <swatches
            v-model="newListingData.color"
            :colors="possibleListingColors"
            inline
          >
          </swatches>
        </div>


        <custom-meta-fields-legacy-component
          class="py2 mt1 border-top"
          :editedAsset="newListingData.meta"
          metaResourceType="listing" />

        <footer class="flex justify-end">

          <button-component
            variant="primary"
            class="mr2"
            @click.stop.prevent="addListingToCallendar"
            v-if="!isSelectedChannelLocked()"
          >Done</button-component>
          <p class="m0 mr2" v-else>Another user is editing the program.</p>
          <button-component
            variant="default"
            @click.stop.prevent="closeCreateListingModal"
          >Cancel</button-component>
        </footer>
      </vue-form>

    </section>

  </modal>

  <modal
    name="edit-listing-modal"
    width="600"
    height="615"
    classes="v--modal p2"
  >

    <section class="h100 flex flex-column">
      <header class="ratio1-1 flex justify-between p2">

        <h1 class="m0 size+1 mb2">
          Edit Block
        </h1>
        <button-component class="" variant="minimal" shape="circle" @click.stop.prevent="() => closeEditListingModal()">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
        </button-component>

      </header>
      <vue-form :state="editListingFormState" @submit.prevent class="overflow-y p2 flex flex-column flex-auto">



        <validate class="" tag="label">
          <label class="">Title <span class="text-danger">*</span></label>
          <input
            class="input"
            type="text"
            placeholder="Title"
            required
            name="title"
            maxlen="100"
            v-model="editedListingData.title"/>
          <field-messages name="title" show="$submitted || $dirty && $touched">
            <div slot="required">Title is a required field</div>
            <div slot="maxlen">Title length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <validate>
          <label for="meta['description']">Description</label>
          <input
            type="text"
            id="meta['description']"
            placeholder="Description"
            v-model="editedListingData.description"
            name="description"
            maxlen="500"
          >
          <field-messages name="description" show="$submitted || $dirty && $touched">
            <div slot="maxlen">Description length should not be more than 500 characters</div>
          </field-messages>
        </validate>
        <div>
          <label>Image url</label>
          <input
            type="text"
            placeholder="Image url"
            v-model="editedListingData.imageUrl"
          >
        </div>
        <div>
          <label for="meta['color']">Color </label>

          <swatches
            v-model="editedListingData.color"
            :colors="possibleListingColors"
            inline
          >
          </swatches>
        </div>


        <custom-meta-fields-legacy-component
          class="py2 mt3 border-top"
          :editedAsset="editedListingData.meta"
          metaResourceType="listing|playlist" />

        <footer class="flex justify-between">

          <button-component
            variant="primary"
            intent="danger"
            class=""
            @click.stop.prevent="deleteListing"
            v-if="!isSelectedChannelLocked()"
          >Delete</button-component>
          <div v-else></div>

          <aside class="flex">
            <button-component
              variant="primary"
              class="mr2"
              @click.stop.prevent="editListingInCallendar"
              v-if="!isSelectedChannelLocked()"
            >Apply</button-component>

            <p class="m0 mr2" v-else>Another user is editing the program.</p>

            <button-component
              variant="default"
              @click.stop.prevent="closeEditListingModal"
            >Cancel</button-component>
          </aside>
        </footer>
      </vue-form>
    </section>
  </modal>



  <header class="mb3 border-bottom tab-toggle">
    <section class="flex">
      <button-component
        class="active dummy"
        variant="tab"
        size="size-m"
        >Program
      </button-component>

      <button-component
        variant="tab"
        size="size-m"
        @click="handleVideoTabChange()"
        >Videos
      </button-component>
    </section>

  </header>


  <div class="flex flex-auto relative mt1 calendar-holder" ref="calendarHolder"
      :class="isSelectedChannelLocked() ? 'locked' : ''">
    <aside class="store-listings-btns" v-if="isSaveBtnVisible && shouldSaveBeMade">

      <button-component
        variant="primary"
        size="size-m"
        class="mr2"
        intent="warning"
        @click="handleDiscardListings"
        >Discard
      </button-component>
      <button-component
        variant="primary"
        size="size-m"
        class="mr2"
        @click="saveCalendarState"
        >Save
      </button-component>
    </aside>







    <select class="select size-s m0 view-select" id="type"
      v-model="selectedCalendarView"
      @change="($event) => handleChangeCalendarView($event, $event.target.value)"
      name="selectedGrpAttachment">
      <option
      :key="key"
      :value="item"
      v-for="(key, item) in viewsLabelsConfig"
      >{{ key }}</option>
    </select>
    <h3 class="m0 size-1 view-title">{{ calendarApi && calendarApi.view.title }}</h3>

    <section class="custom-calendar-ctr flex">

      <button-component class="mr1" variant="minimal" shape="circle"
        @click.stop.prevent="navigateCalendar('goPrev')">
        <ioio-icon icon="fas-backward-step" class="w-6 h-6"/>
      </button-component>

      <button-component class="mr1" variant="minimal" shape="circle"
        @click.stop.prevent="navigateCalendar('goCurrentDate')">
        <ioio-icon icon="fas-location-dot" class="w-6 h-6"/>
      </button-component>
      <button-component class="" variant="minimal" shape="circle"
        @click.stop.prevent="navigateCalendar('goNext')">
        <ioio-icon icon="fas-forward-step" class="w-6 h-6"/>
      </button-component>

    </section>

    <div class="calendar-loader-container overflow-hidden flex items-center flex-auto" v-if="areCalendarListingsLoading">
      <logo-loader-component width="100px" height="100px" />
    </div>

    <ioio-icon icon="fas-gear" class="locked-icon"/>

    <FullCalendar
      class="listings-edit-calendar"
      ref="fullCalendar"
      defaultView="CustomWeek"
      :header="headerConfig"
      :views="viewsConfig"
      :plugins="calendarPlugins"
      :weekends="calendarWeekends"
      :events="loadedListings"
      @dateClick="onDateClick"
      @eventClick="onEventClick"
      @eventDrop="onEventDrop"
      @eventResize="onEventResize"
      :datesRender="loadListingsForSelectedCalendarPeriod"
      :snapDuration="'00:10:00'"
      :editable="true"
      :allDaySlot="false"
      :height="calendarHeight"
      :nowIndicator="true"
    />
  </div>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { paletteColors } from "@utils/helpers";


export default {

  data: () => ({

    headerConfig: {
      right: '', // custom controls are implemented
      left: '', // custom controls are implemented
    },

    viewsConfig: {

      CustomWeek: {
        type: 'timeGridWeek',
        duration: { days: 7 },
        buttonText: 'Week',
        dateIncrement: { days: 1 },

        columnHeaderHtml(date) {

          var dateObj = new Date(date);

          var day = dateObj.getDate();
          var dayName = dateObj.toLocaleString('en-us', { weekday:'short' });

          return `
            <div class="day-header mb1">
              <div class="day-digit-display">${day}</div>
              <div class="day-name-display">${dayName}</div>
            </div>
          `;
        },
      },

      dayGridMonth: {

        editable: false,

        columnHeaderHtml(date) {

          return '';
        },
      },

      timeGridDay: {

        columnHeaderHtml(date) {

          var dateObj = new Date(date);

          var day = dateObj.getDate();
          var dayName = dateObj.toLocaleString('en-us', { weekday:'short' });

          return `
            <div class="day-header mb1">
              <div class="day-digit-display">${day}</div>
              <div class="day-name-display">${dayName}</div>
            </div>
          `;
        },
      }
    },

    viewsLabelsConfig: {
      timeGridDay: 'Day',
      CustomWeek: 'Week',
      dayGridMonth: 'Month'
    },


    selectedCalendarView: 'CustomWeek',
    cachedCalendarView: 'CustomWeek', // use to reset selectedCalendarView, when preventDefault behavoir is needed.

    calendarPlugins: [],

    calendarWeekends: true,

    calendarHeight: 'parent',

    calendarApi: null,

    newListingStartMinDateTime: '',
    newListingStartMaxDateTime: '',
    newListingEndMinDateTime: '',
    newListingEndMaxDateTime: '',


    possibleListingColors: [...paletteColors()],

    newListingData: {
      start: '',
      end: '',
      title: '',
      color: '',
      description: '',
      imageUrl: '',
      meta: {}
    },
    createListingFormState: {},
    prevEventBound: '',
    nextEventBound: '',

    editListingFormState: {},
    editedListingData: {

      // NOTE editable props
      id: '',
      start: '',
      end: '',

      // editable props
      title: '',
      color: '',
      description: '',
      imageUrl: '',
      meta: {}
      // `start` and `end` will not be editable from this form, since the overlap logic can not be easily applied from here
    },
    editedListingRef: null,
    lastAffectedStamp: 0,
    firstAffectedStamp: 0,

    currentDropAffectedSchedules: {

      prev: {},
      curr: {},
      next: {}
    },

    loadedPeriodStart: 0,
    loadedPeriodEnd: 0,

    lastMovedEvent: {},

    intendedSpliceDirection: '',

    loadedListings: [],

    // Should be used whenever sorting is critical, since the
    // Fullcalendar library doesn't keep the loaded events sorted.
    // This variable should be kept up to date every time the listings
    // count changes (one is added or deleted; the view changes
    // and new listings are loaded)
    sortedEvents: [],

    areCalendarListingsLoading: false,

    uniqueListingIdReached: 1, // increment when creating new listings and assign to them

    shouldSaveBeMade: false,
  }),

  props: {

    toggleTimelineVisible: Function
  },

  created() {

    // Require the plugins, since they are NOT SSR compliant and can not be imported
    const dayGridPlugin =
      require('../../../node_modules/@fullcalendar/daygrid/main.js').default;
    const timeGridPlugin =
      require('../../../node_modules/@fullcalendar/timegrid/main.js').default;
    const interactionPlugin =
      require('../../../node_modules/@fullcalendar/interaction/main.js').default;

    this.calendarPlugins = [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin
    ];
  },
  mounted() {

    window.l = this;

    this.calendarApi = this.$refs.fullCalendar.getApi();

    this.loadListingsForSelectedCalendarPeriod();
  },

  methods: {

    isSelectedChannelLocked() {

      return this.lockedChannelsGuids[this.channelsMeta.selected.guid];
    },

    handleVideoTabChange() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.openVideoTab()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.openVideoTab();
      }
    },

    openVideoTab() {

      this.setActiveSection('videos');

      this.$pushRoute({

        path: this.$route.fullPath,
        query: {
          'channel-guid': this.selectedChannel.guid,
          section: 'videos'
        }
      });
    },

    loadListingsForSelectedCalendarPeriod() {

      if (!this.calendarApi || !this.selectedChannel.guid) {

        return;
      }

      this.loadedPeriodStart = this.calendarApi.view.activeStart;
      this.loadedPeriodEnd = this.calendarApi.view.activeEnd;

      this.areCalendarListingsLoading = true;

      this.getListings({

        start: this.loadedPeriodStart.getTime(),

        end: this.loadedPeriodEnd.getTime(),

        channel: this.selectedChannel.guid,

        current: "1"

      }).then((listings) => {

        this.stageListings(listings);

        // Null the stamp, since it is not relevant for the new listings
        this.lastAffectedStamp = 0;
        this.firstAffectedStamp = 0;

      }).finally(() => {

        this.areCalendarListingsLoading = false;
      })
    },

    stageListings(listings) {

      this.uniqueListingIdReached = listings.length + 1;

      // The dead zone is 1 hour for now and only set on loading the events in the calendar
      // Later on, when saving, every event that starts after10Min now will NOT be saved!
      const now = new Date();
      const deadZoneEnd = new Date(now.getTime() + this.deadzoneBufferMs);

      listings.forEach((l, index) => {

        l.id = index + 1;

        const shouldEventBeEditable = l.start > deadZoneEnd;

        if (!shouldEventBeEditable) {

          l.editable = false;
        }

        // Fullcalendar rounds down start and end to the nearest second. Keep the original offset, so that it can be added to the fullcalendar value and returned to the BE
        l.startOffset = l.start % 1000;
        l.endOffset = l.end % 1000;
      });

      this.loadedListings = listings;
    },

    navigateCalendar(directionFn) {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            // Null calendar state, before loading from the BE
            this.stageListings([]);
            this.calendarApi.removeAllEvents();

            this[directionFn]();
            this.shouldSaveBeMade = false;
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        this[directionFn]();
      }
    },

    goPrev() {

      if (this.calendarApi.view.type === 'CustomWeek') {

        // NOTE: default call to prev() is not used due to a bug
        // https://github.com/fullcalendar/fullcalendar/issues/4678#issuecomment-527848221
        this.calendarApi.incrementDate({ days: -1 });

      } else {

        this.calendarApi.prev();
      }
    },

    goCurrentDate() {

      this.calendarApi.today();
    },

    goNext() {

      this.calendarApi.next();
    },

    sortEvents(events) {
      // NOTE:  quicker sort algorithm can be used

      const filteredEventsFromDuplicates = events.filter((v,i,a) => a.findIndex(t => (t.start.getTime() === v.start.getTime())) === i);

      this.sortedEvents = filteredEventsFromDuplicates.sort((a, b) => a.start - b.start);

console.log(66, events.length, filteredEventsFromDuplicates.length, filteredEventsFromDuplicates)

      /**
            const sortedEvents = events.sort((a, b) => a.start - b.start);

      const sortedAndCleanedEvents = [];

      for (let i = 0; i < sortedEvents.length; i++) {

        sortedAndCleanedEvents.push(sortedEvents[i]);
      }

      this.sortedEvents = sortedAndCleanedEvents;
      */
    },

    calcShouldAcceptDrop(events, noSorting) {

      // noSorting flag should be provided when calcShouldAcceptDrop
      // is used after resize event, since the sorting of events doesn't change then
      if (!noSorting) {

        this.sortEvents(events);
      }

      for (let i = 0; i < this.sortedEvents.length; i++) {

        const e = this.sortedEvents[i];
        const nextEvent = this.sortedEvents[i + 1] || {};

        /**
        * Cache the lastMovedEvent
        * Compare e and nextEvent to it and depending
        * which one is the lastMovedEvent adjust indeces of prev curr next
        */

        if (e.end > nextEvent.start) {

          console.log('overlappppppppppppppppp', e, nextEvent, this.lastMovedEvent)

          let prevEvent = e;
          let overlapEvent = nextEvent;
          let eventAfterOverlap = this.sortedEvents[i + 2] || {};

          let intendedSpliceDirection = 'prev';

          if (this.lastMovedEvent.id === e.id) {

            prevEvent = this.sortedEvents[i - 1] || {};
            overlapEvent = e;
            eventAfterOverlap = this.sortedEvents[i + 1] || {};
            intendedSpliceDirection = 'next';
          }

          this.intendedSpliceDirection = intendedSpliceDirection;

          this.cacheCurrentDropAffectedSchedules(prevEvent, overlapEvent, eventAfterOverlap);

          return false;
        }
      }

      return true;
    },

    willScheduleFit(prev, curr, next) {

      const currDuration = curr.end - curr.start;
      console.log(777, prev.title, curr.title, next.title)

      const nextBound = next.start || this.loadedPeriodEnd;
      const prevBound = prev.end || this.loadedPeriodStart;

      const gap = nextBound - prevBound;

      console.log(888,gap, currDuration)

      if (gap >= currDuration) {

        return true;
      }

      return false;
    },

    cacheCurrentDropAffectedSchedules(prev, curr, next) {

      this.currentDropAffectedSchedules = {
        prev, curr, next
      };
    },

    cacheLastMovedEvent(e) {

      this.lastMovedEvent = e;
    },

    checkIsEventStartInTheDeadZone(eventStartDate) {

      const now = new Date();
      const deadZoneEnd = new Date(now.getTime() + this.deadzoneBufferMs);

      return eventStartDate < deadZoneEnd;
    },

    onEventDrop(a) {

      this.cacheLastMovedEvent(a.event);

      const isDropInThePast = this.checkIsEventStartInTheDeadZone(a.event.start);

      if (isDropInThePast) {

        this.$toasted.error('You can not drop an event in the past or 10 minutes from now.');

        a.revert();
        return;
      }

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the program.');

        a.revert();
        return;
      }

      // if shouldAcceptDrop no further checks are needed.
      const shouldAcceptDrop = this.calcShouldAcceptDrop(this.calendarApi.getEvents());

      if (!shouldAcceptDrop) {

        console.log('overlap, but check if will fit in between');

        const { prev, curr, next } = this.currentDropAffectedSchedules;

        if (this.willScheduleFit(prev, curr, next)) {

          console.log('Will fit in between! Dont revert')

          let newStart;

          if (this.intendedSpliceDirection === 'prev') {

            newStart = prev.end.getTime();
          }

          if (this.intendedSpliceDirection === 'next') {

            const movedEventDuration = this.lastMovedEvent.end - this.lastMovedEvent.start;
            newStart = next.start.getTime() - movedEventDuration;
          }

          this.shouldSaveBeMade = true;
          curr.setStart(newStart, { maintainDuration: true });

        } else {

          console.log('revert');
          a.revert();
        }

      } else {

        this.shouldSaveBeMade = true;
      }
    },

    onEventResize(a) {

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the program.');

        a.revert();
        return;
      }

      this.cacheLastMovedEvent(a.event);

      const shouldAcceptDrop = this.calcShouldAcceptDrop(this.calendarApi.getEvents());

      console.log('onEventResize',a, shouldAcceptDrop);

      if (!shouldAcceptDrop) {

        console.log('overlap, but check if will fit in between');

        const { prev, curr, next } = this.currentDropAffectedSchedules;

        if (this.willScheduleFit(prev, curr, next)) {

          console.log('Will fit in between! Dont revert', this.intendedSpliceDirection);

          // NOTE: this case will only be hit if eventResizableFromStart
          // is enabled in calendar settings
          if (this.intendedSpliceDirection === 'prev') {

            const newStart = prev.end.getTime();

            curr.setStart(newStart, { maintainDuration: true });
          }

          // Maximise resize to next event start
          if (this.intendedSpliceDirection === 'next') {

            const newEnd = next.start.getTime();

            curr.setEnd(newEnd, { maintainDuration: false });
          }

        } else {

          // NOTE: if the desired behavior in the future is to
          // prevent resize from happening if the newly resized
          // event won't fit, call a.revert() instead of the following logic

          console.log('maximise to available space');

          const newStart = prev.end.getTime();
          const newEnd = next.start.getTime();
          curr.setDates(newStart, newEnd);
        }

      }

      this.shouldSaveBeMade = true;
    },

    adjustCalendarHeight(view) {

      if (view === 'dayGridMonth') {

        this.toggleTimelineVisible(false);

        setTimeout(() => {

          this.calendarHeight = this.$refs.calendarHolder.offsetHeight;
        });

      } else {

        this.toggleTimelineVisible(true)

        if (this.calendarHeight !== 'parent') {

          this.calendarHeight = 'parent';

          // NOTE: for whatever reason the resize event should be fired
          // several times with time between the calls, so that
          // the calendar shrinks correctly
          window.dispatchEvent(new Event('resize'));

          setTimeout(() => {

            window.dispatchEvent(new Event('resize'));
          }, 100);
        }
      }
    },

    handleChangeCalendarView(event, view, dateStr) {

      if (this.isActiveConfirmGuardSet) {


        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            // Null calendar state, before loading from the BE
            this.stageListings([]);
            this.calendarApi.removeAllEvents();

            this.changeCalendarView(view, dateStr);
            this.shouldSaveBeMade = false;
          },
          rejFn: () => {

            // restore the input's value
            this.selectedCalendarView = this.cachedCalendarView;
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        // Null calendar state, before loading from the BE
        this.stageListings([]);
        this.calendarApi.removeAllEvents();

        this.changeCalendarView(view, dateStr);
      }
    },

    changeCalendarView(view, dateStr) {

      this.selectedCalendarView = view;

      this.cachedCalendarView = this.selectedCalendarView;

      this.calendarApi.changeView(view, dateStr);

      this.adjustCalendarHeight(view);
    },

    onEventClick(element) {

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the program.');
        return;
      }


      const event = element.event;
      console.log(event);

      if (this.calendarApi.view.type === 'dayGridMonth') {

        // open the week period, edits are disabled in month view
        this.changeCalendarView('CustomWeek', event.start);
        return;
      }

      const isClickInThePast = this.checkIsEventStartInTheDeadZone(event.start);

      if (isClickInThePast) {

        this.$toasted.error('You can not edit an event in the past or 10 minutes from now.');

        return;
      }

      if (event.durationEditable === false) {

        return;
      }

      const {
        id,
        start,
        end,
        title
      } = event;

      const {
        color,
        description,
        imageUrl,
        meta
      } = event.extendedProps;

      this.editedListingData = {
        id,
        start,
        end,
        title,
        color: color || event.backgroundColor,
        description,
        imageUrl,
        meta: meta || {}
        // copy additional props from extendedProps object
      };

      // use when the editing is confirm in editListingInCallendar in
      // order to delete the old event and insert the edited one as new.
      this.editedListingRef = event;

      this.$modal.show('edit-listing-modal');
      // this.calendarApi.updateEvent(event);

      // on UPDATE EVENT click, the original event will have to be removed event.remove()
      // / new event should be added, cvontaining the editedListingData from the edit modal
    },

    editListingInCallendar() {

      if (this.editListingFormState.$invalid) {

        this.editListingFormState._submit();
        return;
      }

      this.editedListingRef.remove();

      this.calendarApi.addEvent(this.editedListingData);

      this.shouldSaveBeMade = true;

      this.closeEditListingModal();
    },

    closeEditListingModal() {

      this.editedListingData = {
        id: '',
        start: '',
        end: '',
        title: '',
        color: '',
        description: '',
        imageUrl: '',
        meta: {}
      };

      this.editedListingRef = null;

      this.$modal.hide('edit-listing-modal');
    },

    onDateClick(arg) {

      console.log(87,arg);

      if (this.isSelectedChannelLocked()) {

        this.$toasted.error('Another user is editing the program.');

        return;
      }

      const isClickInThePast = this.checkIsEventStartInTheDeadZone(arg.date);

      if (isClickInThePast) {

        this.$toasted.error('You can not add an event in the past or 10 minutes from now.');

        return;
      }

      if (this.calendarApi.view.type === 'dayGridMonth') {

        // open the week period
        this.changeCalendarView('CustomWeek', arg.dateStr);
        return;
      }

      this.newListingData.start = arg.dateStr

      const { prev, next } = this._findNewEventBounds(arg.date);
      this.prevEventBound = prev;
      this.nextEventBound = next;

      this.newListingStartMinDateTime = this.prevEventBound ?
        this.prevEventBound.end.toISOString() :
        this.calendarApi.view.activeStart.toISOString();

      this.newListingStartMaxDateTime = this.newListingData.end;

      this.newListingEndMinDateTime = this.newListingData.start;

      this.newListingEndMaxDateTime = this.nextEventBound ?
        this.nextEventBound.start.toISOString() :
        this.calendarApi.view.activeEnd.toISOString();


      const endDate = new Date(arg.dateStr);
      endDate.setHours(arg.date.getHours() + 1);

      const endDateStamp = Math.min(
        endDate.getTime(), new Date(this.newListingEndMaxDateTime).getTime());

      this.newListingData.end = new Date(endDateStamp).toISOString();

      this.$modal.show('create-listing-modal', arg);
    },

    _findNewEventBounds(startTime) {

      this.sortEvents(this.calendarApi.getEvents());

      const events = this.sortedEvents;

      let nextClosestEvent = null;
      let nextClosestEventIndex;

      events.forEach((e, index) => {

        if (e.start > startTime) {

          if (!nextClosestEvent || e.start < nextClosestEvent.start) {

            nextClosestEvent = e;
            nextClosestEventIndex = index;
          }
        }
      });

      console.log(989, nextClosestEvent)

      let prevEvent = nextClosestEventIndex !== undefined ?
        events[nextClosestEventIndex - 1] :
        events[events.length - 1];

      // there is only one NEXT event
      if (prevEvent && nextClosestEvent && prevEvent.id === nextClosestEvent.id) {

        prevEvent = '';
      }

      return {
        prev: prevEvent,
        next: nextClosestEvent
      };
    },

    closeCreateListingModal() {

      this.$modal.hide('create-listing-modal');

      this.newListingData = {
        start: '',
        end: '',
        title: '',
        color: '',
        description: '',
        imageUrl: '',
        meta: {}
      };
    },

    setFirstLastAffectedStamps(listing) {

      // Since the BE requires explicit start-end for the period save
      // use lastAffectedStamp on save so that the deleted event is not ommited
      this.lastAffectedStamp = new Date(Math.max(listing.end, this.lastAffectedStamp)).getTime();

      this.firstAffectedStamp = new Date(this.firstAffectedStamp ?
        Math.min(listing.start, this.firstAffectedStamp) :
        listing.start).getTime();
    },

    deleteListing() {

      this.setFirstLastAffectedStamps(this.editedListingRef);

      this.editedListingRef.remove();

      this.shouldSaveBeMade = true;

      this.closeEditListingModal();
    },

    addListingToCallendar() {

      if (this.createListingFormState.$invalid) {

        this.createListingFormState._submit();
        return;
      }

      // Check if the start is before end
      if (this.newListingData.start >= this.newListingData.end) {

        this.$toasted.error('Start time should be before end time.');

        return;
      }

      // Check if the new listing fits inside the bounds just in case
      if (this.prevEventBound && new Date(this.newListingData.start) < this.prevEventBound.end ||
        this.nextEventBound && new Date(this.newListingData.end) > this.nextEventBound.start) {
        this.$toasted.error('Block will not fit. Please adjust it\'s duration');

        return;
      }

      this.calendarApi.addEvent({
        // add new event data
        id: this.uniqueListingIdReached,
        start: new Date(new Date(this.newListingData.start).getTime()),
        end: this.newListingData.end,
        title: this.newListingData.title,
        color: this.newListingData.color,
        description: this.newListingData.description,
        imageUrl: this.newListingData.imageUrl,
        meta: this.newListingData.meta,
      });

      this.uniqueListingIdReached++;

      this.closeCreateListingModal();

      this.shouldSaveBeMade = true;
    },

    handleDiscardListings() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.discardListingsForPeriod()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.discardListingsForPeriod();
      }
    },

    discardListingsForPeriod() {

      // Null calendar state, before loading from the BE
      this.stageListings([]);
      this.calendarApi.removeAllEvents();

      this.loadListingsForSelectedCalendarPeriod();

      this.shouldSaveBeMade = false;
    },

    saveCalendarState() {

      this.sortEvents(this.calendarApi.getEvents());

      const listingsPayload = [];
      const unableToBeSaved = [];

      const now = new Date();
      const after10Min = new Date(now.getTime() + 600000);

      this.sortedEvents.forEach(e => {

        if (e.start < after10Min) {

          unableToBeSaved.push(e);
          return;
        }

        listingsPayload.push({

          start: e.start.getTime(),
          end: e.end.getTime(),
          title: e.title,
          color: e.backgroundColor,
          ...e.extendedProps
        });
      });

      const lastNotSavedListing = unableToBeSaved[unableToBeSaved.length - 1];
      const lastSavedListing = listingsPayload[listingsPayload.length - 1];

      if (lastNotSavedListing) {

        this.firstAffectedStamp = Math.max(after10Min, lastNotSavedListing.end);
      }

      if (lastSavedListing) {

        this.lastAffectedStamp = Math.max(lastSavedListing.end, this.loadedPeriodEnd.getTime());
      }

      /*
        NOTE: the following adjustment is needed since Fullcalendar
        rounds down each event timestamp to the nearest full second.
        If a request is made, that tries to save a listing, that is after
        another running one, that is NOT parsed to a full second, it will error out.

        Example:
        Last listing end on the BE - 1631632868440
        New listing start rounded from the callendar 1631632868000
        Diff is 440ms and the workaround is to adjust the new save request
        start and the first listing start by the diff amount.
      */

      const startOffsetAdjustment = listingsPayload[0] &&
        listingsPayload[0].startOffset;

      const lastUnsavedListingEndAdjustment = lastNotSavedListing &&
        lastNotSavedListing.extendedProps.endOffset;

      const lastUnsavedListingAdjustedEndStamp = lastUnsavedListingEndAdjustment ?
        (new Date(lastNotSavedListing.end).getTime() + lastUnsavedListingEndAdjustment) :
        lastNotSavedListing ? lastNotSavedListing.end : listingsPayload[0] ? listingsPayload[0].start : 0;

      console.log('startOffsetAdjustment', startOffsetAdjustment)

      console.log('lastUnsavedListingEndAdjustment', lastNotSavedListing, startOffsetAdjustment)

      console.log('lastUnsavedListingAdjustedEndStamp', lastUnsavedListingAdjustedEndStamp)

      if (startOffsetAdjustment && lastUnsavedListingAdjustedEndStamp > listingsPayload[0].start) {

        console.log('FIXIIIn')
        listingsPayload[0].start += startOffsetAdjustment;
        this.firstAffectedStamp += startOffsetAdjustment;
      }

      console.log('Unable or should not be saved: ', unableToBeSaved)

      this.storeListingsToBE(listingsPayload);
    },

    storeListingsToBE(newListings) {

      if (!newListings.length && (!this.lastAffectedStamp || !this.firstAffectedStamp)) {

        this.$toasted.error('Nothing can be saved in this selection.')
        return;
      }

      let startAffectedStamp = this.firstAffectedStamp;
      let endAffectedStamp = this.lastAffectedStamp;

      if (newListings.length) {

        startAffectedStamp = this.firstAffectedStamp ?
          Math.min(newListings[0].start, this.firstAffectedStamp) :
          newListings[0].start;

        endAffectedStamp =
          Math.max(newListings[newListings.length - 1].end, this.lastAffectedStamp);
      }

      const payload = {

        start: startAffectedStamp,
        end: endAffectedStamp,
        listings: newListings,
        channel: this.selectedChannel.guid,
      };

      console.log('storeListingsToBE;',payload)

      this.areCalendarListingsLoading = true;

      this.saveMultipleListings(payload)
        .then(() => {

          this.addMsgToMsgBus({
            type: 'UPDATE_STATIC_TIMELINE_DATA'
          });

          this.$toasted.success('Your changes were saved successfully.');

          this.shouldSaveBeMade = false;
        })
        .finally(() => {

          this.areCalendarListingsLoading = false;
        });
    },


    ...mapMutations({

      setActiveSection: 'channelManager/SET_ACTIVE_SECTION',
      addMsgToMsgBus: 'app/ADD_MSG_TO_MSG_BUS',
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      getListings: "channelManager/makeGetListingsForChannelRequest",
      saveMultipleListings: 'channelManager/saveMultipleListings',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      lockedChannelsGuids: "channelManager/lockedChannelsGuids",
      deadzoneBufferMs: 'channelManager/deadzoneBufferMs',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',
      availableDeployments: "app/availableDeployments",
      notificationSocket: "app/notificationSocket",
    }),

    selectedChannel() {

      return this.channelsMeta.selected;
    },

    isSaveBtnVisible() {

      return this.selectedCalendarView !== 'dayGridMonth';
    }
  },

  watch: {

    selectedChannel(newVal, oldVal) {

      if (newVal.guid === oldVal.guid) {

        return;
      }

      if (!this.isActiveConfirmGuardSet) {

        this.loadListingsForSelectedCalendarPeriod();

        this.shouldSaveBeMade = false;
      }
    },

    shouldSaveBeMade(newVal) {

      const brokerDeployment = this.availableDeployments.find(d => d.type === 'broker');

      if (newVal) {

        this.setRedirectGuard({
          redirectMsg: 'Your program changes will not be saved if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });


        /**
         * A modification in the program has occured =>
         * Lock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_lock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));

      } else {

        this.setRedirectGuard(false);


        /**
         * There was a modification in the program, that has now
         * resolved => Unlock the channel for other users in the organization
         */
        this.notificationSocket.send(JSON.stringify({

          action:"channel_unlock",
          deployment: brokerDeployment.guid,
          data: {

            channelGuid: this.channelsMeta.selected.guid
          }
        }));
      }
    }

  }
}
</script>

<style lang="scss">


.listing-scheduler-component {

  background-color: #FFF;

  .btn.tab {

    min-width: auto !important;
    background: none !important;
    border: none !important;

    &:not(.active) {

      box-shadow: none !important;
    }
  }

  .btn.tab.active {
    box-shadow: 0 1px 0 0 var(--highlightColor), 0 -1px 0 0 var(--highlightColor) inset;
  }

  .tab-toggle section {

    width: 150px;
  }

  .store-listings-btns {

    position: absolute;
    top: -86px;
    right: 0;
  }

  .custom-calendar-ctr {

    position: absolute;
    top: 22px;
    right: -48px;
    background: #fff;
    z-index: 2;
  }

  .view-select {

    position: absolute;
    top: -20px;
    left: 0;
  }

  .view-title {

    position: absolute;
    top: -16px;
    left: 120px;
  }

  .calendar-holder {
    .locked-icon {

      display: none;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 275px;
      right: calc(50% - 25px);
      z-index: 4;
    }

    &.locked {

      .listings-edit-calendar {

        opacity: 0.5;
      }

      .locked-icon {

        display: block;
      }
    }
  }

  .calendar-loader-container {

    position: absolute;
    top: 50%;
    right: calc(50% - 150px);
    z-index: 5;
  }
  /* fullcalendar styles */
  .fc-time-grid .fc-slats .fc-minor td {

    border: none !important;
  }

  .fc-view-container {

    height: 100%;
  }

  .fc-time-grid-event.fc-event {

    left: 0 !important;
    right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .fc-time-grid-event:not(.fc-draggable) {

    opacity: 0.7;
  }

  .fc-day-header, .fc-widget-header {

    border: none !important;
  }

  .fc-today {
    .day-digit-display {
      background: #0077FF;
      color: white;
    }
  }

  .day-header {
    width: 46px;
    margin-left: 42px;
  }

  .fc-CustomWeek-view {

    .day-header {

      margin-left: auto;
      margin-right: auto;
    }
  }

  .day-digit-display {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 50%;
  }


  .fc .fc-now-indicator-arrow {

    border-color: #0077FF;
  }

  .fc-time-grid .fc-now-indicator-line {
    border-top-width: 2px;
    border-color: #151E33;
  }
}
</style>
