<template>
  <div class="tag" :class="[variant, size]" :variant="variant" >
    <div class="text-content flex items-center">
      <slot/>
    </div>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  props: {
    variant: {
      type: String,
      required: false,
      default: "default"
    },
    size: {
      type: String,
      required: false,
      default: "size-s"
    }
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  }
};
</script>

<style lang="scss" scoped>


/* Reset the default browser btn styles */

.tag {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
}

.text-content {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.tag.max-content {

  .text-content {

    max-width: 100%;
  }
}

.tag.flex-content {

  .text-content {

    display: flex;
    max-width: none;
  }
}

.tag.size-circle {
  width: .5rem;
  height: .5rem;
  line-height: 0;
  font-size: 0;
  padding: 0;
  text-indent: -9999px;
  border-radius: 50%;
}

.tag.size-s {
  height: 1.125rem;
  line-height: 1.8rem;
  font-size: .75rem;
  padding: .7rem .61rem;
}

.tag.size-m {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  border-radius: 1.5rem;
}

.tag.size-auto {
  height: auto;
  min-height: 1.125rem;
  font-size: 0.625rem;
  padding: 0 0.325rem;
  line-height: inherit;
}

.tag.default {
  color: var(--color-gray-700);
  background: var(--color-blue-gray-50);
  &.size-circle {
    background: var(--color-blue-gray-500);
  }
}
.tag.white {
  color: var(--color-white);
}
.tag.source, .tag.video, .tag.image {
  color: var(--color-white);
  background: var(--color-green-600);
}
.tag.ad {
  color: var(--color-gray-800);
  background: var(--color-yellow-600);
}

.tag.duration {
  color: var(--color-white);
  background: rgba(0, 0, 0, 0.7);
}


.tag.active {
  color: var(--color-white);
  background: var(--color-green-600);
  &.size-circle {
    background: var(--color-green-500);
  }

}
.tag.inactive {
  color: var(--color-white);
  background: var(--color-gray-600);
  &.size-circle {
    background: var(--color-gray-500);
  }
}


.tag.success {
  color: var(--color-green-900);
  background: var(--color-green-50);
  &.size-circle {
    background: var(--color-green-500);
  }
}
.tag.error {
  color: var(--color-red-900);
  background: var(--color-red-50);
  &.size-circle {
    background: var(--color-red-500);
  }
}
.tag.warning {
  color: var(--color-orange-900);
  background: var(--color-orange-50);
  &.size-circle {
    background: var(--color-orange-500);
  }
}
.tag.action {
  color: var(--color-blue-800);
  background: var(--color-blue-50);
  &.size-circle {
    background: var(--color-blue-500);
  }
}

.tag.process {
  color: var(--color-purple-800);
  background: var(--color-purple-50);
  &.size-circle {
    background: var(--color-purple-500);
  }
}
.tag.live {
  color: var(--color-red-50);
  background: var(--color-red-400);
  &.size-circle {
    background: var(--color-red-500);
  }
}
</style>
