<template>

  <section
    class="location-stats-widget-component flex"
    :class="{ 'is-verticle' : shouldDisplayVerticle }"
  >

    <aside class="flex-1 border-right locations-table" v-show="show != 'chart'">
      <section class="pt-10 pr-20 pb-6 pl-8 flex flex-auto flex-column h-full">
        <h1 class="m-0 mb-12 text-2xl text-black-700 font-semibold section-title">Location</h1>

        <section class="flex flex-auto pb-8 overflow-auto">
          <ioio-table class="p-0 border-0 shadow-none">
            <template slot="thead">
              <tr class="w-full grid grid-cols-3">
                <th class="text-left text-base text-black-400 font-normal">City</th>
                <th class="text-left text-base text-black-400 font-normal">
                  Total views
                </th>
                <th class="text-left text-base text-black-400 font-normal">
                  Unique views
                </th>
              </tr>
            </template>
            <template slot="tbody">
              <tr class="w-full grid grid-cols-3 items-center" v-for="location in locationData">
                <td class="text-base text-black-900">{{ location.name }}</td>

                <td class="text-base text-black-800 font-normal flex items-center"
                  >{{ location.total_views }}
                </td>

                <td class="text-base text-black-800 font-normal flex items-center"
                  >{{ location.unique_views }}
                </td>
              </tr>

              <tr v-if="!locationData.length">No information found.</tr>
            </template>
          </ioio-table>
        </section>
      </section>
    </aside>

    <aside class="flex-1" v-show="show != 'table'">
      <section class="p-8 geochart-container h-full">
        <GChart
          class="h-full"
          :settings="{ packages: ['geochart'], mapsApiKey: 'AIzaSyA4h6hYLW5eYzWVZHmOpwNnIp4QJrq2guA' }"
          type="GeoChart"
          :data="locationChartData"
          :options="locationChartOptions"
          ref="gChart"
          :class="{'geo-chart-hidden': isGeoChartHidden}"
          @ready="visualizeChart"
        />
        <p v-if="!locationData.length" class="pt-4">No information found.</p>
      </section>

    </aside>

  </section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isGeoChartHidden: true,

    locationChartData: [],

    locationChartOptions: {
      chart: {
        title: 'Total Views',
        displayMode: 'auto'
      },
    }
  }),
  mounted() {
    window.ewe = this;
  },
  props: {
    locationData: Array,
    shouldDisplayVerticle: Boolean,
    show: {
      type: String,
      default: ''
    }
  },

  methods: {
    visualizeChart() {

      const newLocationChartData = new google.visualization.DataTable();

      newLocationChartData.addColumn('number', 'Latitude');
      newLocationChartData.addColumn('number', 'Longitude');
      newLocationChartData.addColumn('string', 'Label');
      newLocationChartData.addColumn('number', 'Unique Views');
      newLocationChartData.addColumn('number', 'Total Views');

      const locationChartEntries = [];

      this.locationData.forEach(l => {

        locationChartEntries.push([+l.location.lat, +l.location.lon, l.name, +l.unique_views, +l.total_views]);
      });


      newLocationChartData.addRows(locationChartEntries);

      if (this.locationData.length) {

        this.isGeoChartHidden = false;

      } else {

        this.isGeoChartHidden = true;
      }

      this.locationChartData = newLocationChartData;
    }
  },

  watch: {
    locationData() {

      this.visualizeChart();
    }
  },
}
</script>

<style lang="scss">


.location-stats-widget-component {

  height: 440px;
  max-height: 440px;

  &.is-verticle {

    flex-direction: column;

    aside {

      border: none;
      min-height: 350px;
    }

    .locations-table {

      &>section {

        @apply
        pr-8;
      }
    }
  }

  .geo-chart-hidden {

    height: 0 !important;
    overflow: hidden;
  }
}
</style>
