<template>
<div class="library-vod-hubs-tab-edit-component">

  <p v-for="(hub, index) in editedVodMeta.hubs" :key="hub.name" class="mb-4 text-sm flex justify-between hub-row">
    <span>{{ hub.name }}</span>
    <ioio-button
      type="secondary"
      variant="text"
      size="small"
      iconLeft="fas-trash-can"
      @click="removeHubFromVod(index)"
    >Remove
    </ioio-button>
  </p>

  <p class="mb-4 text-sm" v-if="!editedVodMeta.hubs || !editedVodMeta.hubs.length">No hubs added yet.</p>

  <ioio-button
    v-if="!isAddHubSectionVisible"
    @click="toggleAddHubSectionVisible"
    class="mt-2 self-start"
    type="secondary"
    variant="outline"
    iconLeft="fa-solid fa-plus"
    size="small">Add to hub
  </ioio-button>

  <section class="flex mt-2" v-else-if="availableHubs.length">

    <ioio-dropdown

      class="mr-4 flex-auto"
      :items="availableHubs"
      valueProp="name"
      titleProp="label"
      v-model="newSelectedHub"
      size="small"
      @select="onHubShortlisted"
    />

    <ioio-button
      @click="toggleAddHubSectionVisible"
      type="secondary"
      variant="outline"
      class="mr-4"
      size="small">Cancel
    </ioio-button>

    <ioio-button
      @click="addHubToVod"
      type="primary"
      :disabled="!newSelectedHub"
      size="small">Add
    </ioio-button>
  </section>

  <p v-else>No Hubs configured for this organization</p>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    availableHubs: [],

    isAddHubSectionVisible: false,

    newSelectedHub: null
  }),
  props: {
    editedVodMeta: Object,
    editedVodGuid: String,
    onHubsChanged: Function
  },

  mounted() {
    window.hh = this;

    this.updateInitialOptions();
  },

  methods: {

    updateInitialOptions() {

      this.getAvailableHubs();
    },

    onHubShortlisted(hubName) {

      this.newSelectedHub = hubName;
    },

    removeHubFromVod(index) {

      this.editedVodMeta.hubs.splice(index, 1);

      this.onHubsChanged && this.onHubsChanged();
    },

    addHubToVod() {

      if (this.editedVodMeta.hubs && this.editedVodMeta.hubs.some(h => h.name === this.newSelectedHub)) {

        this.$toasted.error(`The hub ${this.newSelectedHub} is already added.`);
        return;
      }

      const newSelectedHubFullDetails = this.availableHubs.find(h => h.name === this.newSelectedHub);

      const hubs = this.editedVodMeta.hubs || [];

      hubs.push({
        id: newSelectedHubFullDetails.id,
        name: newSelectedHubFullDetails.name,
        permissions: ['internal']
      });

      this.editedVodMeta.hubs = hubs;

      this.newSelectedHub = null;

      this.onHubsChanged && this.onHubsChanged();
    },

    toggleAddHubSectionVisible() {

      this.isAddHubSectionVisible = !this.isAddHubSectionVisible;
    },

    getAvailableHubs() {

      if (!this.siteBuilderApps.length) {

        return;
      }

      const hubs = [];

      this.siteBuilderApps.forEach((app, index) => {

        app.hubs && app.hubs.forEach(h => {

          hubs.push({
            label: h.name,
            name: h.name,
            id: h.id
          });
        });
      });

      this.availableHubs = hubs;
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({

      siteBuilderApps: 'app/siteBuilderApps'
    })
  },
  watch: {

    siteBuilderApps() {

      this.updateInitialOptions();
    }
  }
}
</script>

<style lang="scss">


</style>
