<template>
  <div class="subtitles-settings-component">
    <div class="box bg-white rounded-3 mb2">
      <div v-show="!isLoadingData" class="table">
        <table>
          <thead>
            <tr>
              <th class="width+5">Setting</th>
              <th class="width+1">Default</th>
              <th></th>
              <th class="width+2">Edit</th>
              <th class="width+1">Update</th>
            </tr>
          </thead>
          <tbody>
            <medical-transcription-component @loading="isLoadingMedicalData = $event"/>
            <auto-subs-component @loading="isLoadingAutoSubs = $event" @autoSubs="toggleAutoSubs"/>
            <auto-translated-languages-component @loading="isLoadingTranslatedData = $event" :autoSubs="autoSubs"/>
          </tbody>
        </table>
      </div>
      <div v-show="isLoadingData">
        <logo-loader-component width="50px" height="50px" class="mt3"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isLoadingAutoSubs: false,
    isLoadingMedicalData: false,
    isLoadingTranslatedData: false,
    autoSubs: false
  }),
  props: {},

  methods: {

    toggleAutoSubs(value) {

      this.autoSubs = value;
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

    })
  },
  computed: {

    isLoadingData() {

      let loading = this.isLoadingMedicalData && this.isLoadingTranslatedData && this.isLoadingAutoSubs;

      return loading;
    },

    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">
  .subtitles-settings-component {
    th {
      @apply
      font-medium;
    }
  }

</style>
