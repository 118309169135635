const currentlyPlayingListing = (state) => state.currentlyPlayingListing;
const currentlyPlayingSchedule = (state) => state.currentlyPlayingSchedule;
const nextPlayingListing = (state) => state.nextPlayingListing;
const nextPlayingSchedule = (state) => state.nextPlayingSchedule;
const streamToNowOffset = (state) => state.streamToNowOffset;

export {

  currentlyPlayingListing,
  currentlyPlayingSchedule,
  nextPlayingListing,
  nextPlayingSchedule,
  streamToNowOffset
}