/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play_circle_filled_outlined': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.333 20C3.333 10.8 10.8 3.333 20 3.333c9.2 0 16.667 7.467 16.667 16.667 0 9.2-7.467 16.667-16.667 16.667C10.8 36.667 3.333 29.2 3.333 20zm23.334 0l-10-7.5v15l10-7.5z" _fill="#4F4F4F"/>'
  }
})
