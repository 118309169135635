import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { routesConfig } from '../configs/routesAuth.config'


const routes = [];

for (let routePath in routesConfig) {

  routes.push({

    path: routePath,
    name: routesConfig[routePath].component,
    meta: routesConfig[routePath].meta,
    component: () => import(/* webpackChunkName: "[request]" */ `../views/${routesConfig[routePath].view}/index.vue`)
  });
}

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// Set page title dynamically, based on config
router.beforeEach((to, from, next) => {

  let pageTitle = 'IOIO.TV';

  if (to.meta.pageTitle) {

    pageTitle = `${to.meta.pageTitle} | ${pageTitle}`;
  }

  document.title = pageTitle;

  next();
});


export default router
