<template>
  <section class="add-source-component h100 flex flex-column items-center">

    <div class="flex" v-if="availableIngestService && !prompts.length">
      <ioio-dropdown-base
        class="mb-4 mr-2"
        alignX="left"
        :fullWidth="false"
        @change="toggleChevron">
        <template slot="title">
          <ioio-button
            type="primary"
            variant="outline"
            fullWidth=""
            :iconRight="isImportOpen ? 'fal-chevron-up' : 'fal-chevron-down'"
            size="small"
          >
            Import
          </ioio-button>
        </template>
        <div
          v-for="distributor in availableDistributors"
          :key="distributor.guid"
          class="dropdown__menu-item border-bottom"
        >
          <div class="flex justify-between">
            <ioio-button
              type="minimal"
              :fullWidth="true"
              :class="(distributor.guid === 'google-drive' && isUserGoogleDriveConnect) || (distributor.enabled && distributor.guid != 'google-drive') ? 'distributor-enabled' : 'distributor-connect'"
              :disabled="(distributor.guid === 'google-drive' && !isUserGoogleDriveConnect) || (!distributor.enabled && distributor.guid != 'google-drive') "
              @click="navigateToIngest(distributor)"
            >
              {{ distributor.title }}
            </ioio-button>
            <ioio-button
              type="minimal"
              v-if="distributor.guid === 'google-drive' && !isUserGoogleDriveConnect || !distributor.enabled"
              class="connect"
              @click="navigateToIngest(distributor)"
            >
              Connect
            </ioio-button>
          </div>
        </div>
      </ioio-dropdown-base>
      <ioio-button
        type="primary"
        @click="browse"
        size="small"
      >
        Browse
      </ioio-button>
    </div>

    <vue-dropzone
      :options="dropzoneOptions"
      :include-styling="false"
      id="customdropzone"
      :class="{ 'staged-files-present': prompts.length, 'upload-in-progress': isUploadInProgress }"
      ref="dropzoneComponent"
      :useCustomSlot="true"
    >
      <div v-if="prompts.length" class="dropzone-custom-content flex items-center">
        <ioio-icon icon="fad-cloud" class="w-8 h-8 text-blue-600 mr-4"/>
        <div>
          <h3 class="dropzone-custom-title size0 m0">
            Add more files
          </h3>
          <h5 class="subtitle size-2 m0">
            Supported file types: .mpeg, .mov, .avi, .ts, .m2v, .mkv, .mpg, .mp4,
            .m4v, .mxf, .webm
          </h5>
        </div>
      </div>
      <div v-else class="dropzone-custom-content flex items-center flex-column">
        <ioio-icon icon="fad-cloud" class="w-14 h-14 text-black-700"/>
        <h3 class="dropzone-custom-title size0">
          Drag and drop to upload content!
        </h3>
        <div class="subtitle size-2">
          ...or click to select a file from your computer
        </div>
        <h5 class="subtitle size-2">
          Supported file types: .mpeg, .mov, .avi, .ts, .m2v, .mkv, .mpg, .mp4,
          .m4v, .mxf, .webm
        </h5>
      </div>
    </vue-dropzone>
    <div class="width+10 main-content" v-if="prompts.length">
      <div class="flex items-center justify-between my1 bg-blue+5 p1 rounded-2 width+10 px-4">
        <div class="items-center size-3 ">
          <check-component
            label="Select All"
            type="checkbox"
            :val="isSelectAllOptionApplied"
            :on-change="() => toggleSelectAllOptionApplied()"/>
        </div>
        <div class="settings">
          <div class="actions flex items-center">
            <div class="items-center m-2 flex">
              <span class="settings-label mr-025  size-3">Type</span>
              <select
                class="select size-s m0"
                style="margin: 0;"
                placeholder="Type"
                v-model="bulkType">
                <option value>Select Type</option>
                <option value="ad">Ad</option>
                <option value="source">Video</option>
              </select>
            </div>
            <div class="items-center m-2 flex">
              <span class="settings-label mr-025  size-3">Group</span>
              <input
                class="input size-s m-0"
                placeholder="group"
                v-model="bulkGroup"/>
            </div>
            <div class="items-center m-2 flex">
              <span class="settings-label mr-025  size-3">Genre</span>
          <!--
            NOTE: there are not specific options for this field to be a select

            <multiselect v-model="value" :options="options" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Select Genre" label="name" track-by="name" ></multiselect>
          -->

              <input
                class="input size-s m-0"
                placeholder="Genre"
                v-model="bulkGenre"/>
            </div>
          </div>
          <div class="actions flex items-center my-2">

            <div class="items-center ml-2 size-3 ">
              <check-component
                label="Encode"
                type="checkbox"
                class="size-3"
                :val="bulkEncode"
                :on-change="() => toggleBulkEncode()"/>
            </div>

            <div class="items-center ml-4 size-3 ">
              <check-component
                label="Auto Subs"
                type="checkbox"
                class="size-3"
                :val="bulkAutoSubs"
                :on-change="() => toggleBulkAutoSubs()"/>
            </div>

            <div class="items-center ml-4 size-3 ">
              <check-component
                label="Auto Translate"
                :disabled="!bulkAutoSubs"
                type="checkbox"
                class="size-3"
                :val="bulkAutoTranslate"
                :on-change="() => toggleBulkAutoTranslate()"/>
            </div>

            <div class="items-center ml-4 size-3 ">
              <check-component
                label="Medical Transcription"
                type="checkbox"
                class="size-3"
                :val="bulkMedicalTranscribe"
                :on-change="() => toggleBulkMedicalTranscribe()"/>
            </div>

            <div class="items-center ml-4 flex">
              <select
                :disabled="!bulkMedicalTranscribe"
                class="select size-s m0"
                style="margin: 0;"
                id="medical"
                v-model="bulkMedicalTranscribeType">
                <option
                  :key="i"
                  :value="type"
                  v-for="(type,i) in possibleMedicalTranscription"
                >{{ type }}</option>
              </select>
              <span class=" size-3 ml-1">MT Type</span>
            </div>
          </div>
        </div>
        <button-component
          class="ml1 w-20"
          style="height: 3rem;"
          :class="{'disabled': !bulkSelectedCount}"
          :variant="bulkSelectedCount ? 'primary' : ''"
          @click="applyToBulkSelected">Apply to Selected({{ bulkSelectedCount }})
        </button-component>
      </div>

      <div class="flex flex-column bg-gray+5 rounded-3 p1 staged-vods-container">

        <div class="flex flex-column items-center py2 bg-white border-bottom" v-for="(prompt, index) in prompts"
          :key="index"
        >

          <section class="flex items-center settings-section width+10 px-2">
            <check-component
              type="checkbox"
              :val="!!bulkList[prompt.id]"
              :on-change="() => toggleAddPromptToBulkList(prompt)"/>
            <div class="settings">
              <div class="flex items-center px-4 my-2">
                <tag-component :variant="prompt.data.type || 'video'">{{prompt.data.type || 'video'}}</tag-component>
                <h5 class="m0 mx-2 text-sm file-name truncate">{{prompt.file.name}}</h5>
                <div class="ml-auto flex items-center">
                  <!--<span class="size-2 mr1">{{prompt.status}}</span>-->
                  <progress-component class="small" style="width:7rem;"
                    showText :progress="prompt.progressPercentage" v-if="prompt.status !== 'error'"/>
                  <h6 v-else>There was an error processing this vod.</h6>
                </div>
                <h4 v-if="prompt.status === 'uploaded'" class="my-2 size-2 ml-14">Uploaded</h4>
              </div>
              <section v-if="prompt.status !== 'uploaded'">
                <section class="flex items-center flex-auto my-2 px-4" >
                  <div class="flex items-center">
                    <tag-component v-if="prompt.data.group" class="mr-2">{{prompt.data.group}}</tag-component>
                    <tag-component v-if="prompt.data.genre" class="mr-2">{{prompt.data.genre}}</tag-component>
                  </div>
                  <section class="flex items-center">

                    <div class="items-center size-2">
                      <check-component
                        label="Encode"
                        type="checkbox"
                        :val="prompt.data.encode"
                        :on-change="() => togglePromptEncodeStatus(prompt)"/>
                    </div>

                    <div class="items-center ml2 size-2">
                      <check-component
                        label="Auto Subs"
                        type="checkbox"
                        :val="prompt.data.autoSubs"
                        :on-change="() => togglePromptAutoSubsStatus(prompt)"/>
                    </div>

                    <div  class="items-center ml2 size-2">
                      <check-component
                        label="Auto Translate"
                        :disabled="!prompt.data.autoSubs"
                        type="checkbox"
                        :val="prompt.data.autoTranslate"
                        :on-change="() => togglePromptAutoTranslateStatus(prompt)"/>
                    </div>

                    <div class="items-center ml2 size-2">
                      <check-component
                        label="Medical Transcription"
                        type="checkbox"
                        :val="prompt.data.medicalTranscribe"
                        :on-change="() => togglePromptMedicalTranscribeStatus(prompt)"/>
                    </div>

                    <div class="items-center ml2 flex">
                      <select class="select size-s m-0" :disabled="!prompt.data.medicalTranscribe" style="margin:0" id="type" v-model="prompt.data.medicalTranscribeType">
                        <option
                          :key="i"
                          :value="type"
                          v-for="(type,i) in possibleMedicalTranscription"
                        >{{ type }}</option>
                        </select>
                      <span class="size-2  ml-1">MT Type</span>
                    </div>

                  </section>

                </section>

              </section>
            </div>
            <div class="flex settings-end">
              <button-component
                class="mr1 ml2 flex-none"
                size="size-s"
                @click="togglePromptFormVisible(prompt)"
                v-tooltip="'edit file'"
                >
                <ioio-icon icon="fas-pen" class="w-4 h-4"/>

              </button-component>

              <button-component
                class="flex-none"
                size="size-s"
                @click="unstageFileFromUpload(index)"
                v-tooltip="'remove file'"
                >
                <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>

              </button-component>
            </div>
          </section>

          <vue-form
            :state="prompt.formState"
            class="width+10 bg-blue+5 rounded-3 p2 mt2"
            v-show="prompt.isFormVisible"
          >

            <div class="flex justify-between">

              <div class="flex items-start">

                <validate class="mr1">
                  <label :for="`title_${index}`" class="size-2">Title
                    <span class="gray+1">(required)</span>
                  </label>
                  <input
                  type="text"
                  class="input size-s"
                  :class="{'is-invalid': isValidTitle(index)}"
                  :id="`title_${index}`"
                  v-model="prompts[index].data.title"
                  placeholder="Title"
                  required
                  maxlen="100"
                  name="title" />
                  <field-messages name="title" show="$submitted || $dirty && $touched">
                    <div class="red size-3" slot="required">Title is a required field</div>
                    <div slot="maxlen">Title length should not be more than 100 characters</div>
                  </field-messages>
                </validate>

                <validate class="mr1">
                  <label :for="`type_${index}`" class="size-2" >Type
                    <!-- <span class="gray+1">(required)</span> -->
                  </label>
                  <select class="select size-s"
                    :class="{'is-invalid': isValidType(index)}"
                    :id="`type_${index}`" v-model="prompts[index].data.type"
                    name="type" required>
                    <option value>Select Type</option>
                    <option value="ad">Ad</option>
                    <option value="source">Video</option>
                  </select>
                  <!-- <field-messages name="type" show="$submitted || $dirty && $touched">
                    <div class="red size-3" slot="required">Type is a required field</div>
                  </field-messages> -->
                </validate>


                <validate class="mr1">

                  <label
                  :for="`description_${index}`"
                  class="size-2"
                  >Description</label>
                  <textarea
                  class="input area size-s"
                  :class="{'is-invalid': isValidDescription(index)}"
                  :id="`description_${index}`"
                  v-model="prompts[index].data.description"
                  placeholder="Description"
                  maxlen="500"
                  name="description"
                  rows="3"/>
                  <field-messages name="description" show="$submitted || $dirty && $touched">
                    <div slot="maxlen">Description length should not be more than 500 characters</div>
                  </field-messages>
                </validate>

                <div class="flex items-center">
                  <validate class="form-group mr1">
                    <label
                      :for="`group_${index}`"
                      class="size-2"
                    >Group</label>
                    <input
                      type="text"
                      class="input size-s"
                      :id="`group_${index}`"
                      v-model="prompts[index].data.group"
                      placeholder="Optional"
                      maxlen="64"
                      name="group"
                    >
                    <field-messages name="group" show="$submitted || $dirty && $touched">
                      <div slot="maxlen">Group length should not be more than 64 characters</div>
                    </field-messages>
                  </validate>

                  <validate class="form-group">
                    <label
                      :for="`genre_${index}`"
                      class="size-2"
                    >Genre</label>
                    <input
                      type="text"
                      class="input size-s"
                      :id="`genre_${index}`"
                      v-model="prompts[index].data.genre"
                      placeholder="Optional"
                      maxlen="64"
                      name="genre"
                    >
                    <field-messages name="genre" show="$submitted || $dirty && $touched">
                      <div slot="maxlen">Genre length should not be more than 64 characters</div>
                    </field-messages>
                  </validate>
                </div>
              </div>
              <augmented-vod-meta-component class="ml2 mt-5" :ref="`augMeta${prompt.id}`" />
            </div>

          </vue-form>

        </div>
      </div>
      <div class="flex items-center justify-between width+10 my1 bg-blue+5 p1 rounded-2 width+10">

        <p class="disclaimer size-3  mr4">Please note, that auto subtitles for videos longer than 4 hours will not be processed.</p>

        <button-component
          type="submit"
          variant="success"
          size="size-m"
          :class="{ disabled: isUploadInProgress }"
          @click="onSubmitAll()"
        >Upload</button-component>
      </div>
    </div>

  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import md5 from "md5";
import { CognitoIdentity } from "aws-sdk/clients/all";

export default {
  mounted() {
    window.asd = this;

    this.dropzoneComponent = this.$refs.dropzoneComponent.dropzone;
  },
  data() {
    const vm = this;

    return {
      prompts: [],
      promptUniqueIDReached: 1,
      isImportOpen: false,

      dropzoneComponent: {},

      dropzoneOptions: {
        // The URL will be changed for each new file being processing
        timeout: null,
        maxFilesize: null,
        url: "/",
        // Since we're going to do a `PUT` upload to S3 directly
        method: "put",
        addRemoveLinks: true,

        // // Content-Type should be included, otherwise you'll get a signature
        // // mismatch error from S3. We're going to update this for each file.
        // headers: {},
        // Here we request a signed upload URL when a file being accepted
        accept(file, done) {

          const acceptedFiles = '.mpeg, .mov, .avi, .ts, .m2v, .mkv, .mpg, .mp4, .m4v, .mxf, .webm';

          var fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);

          if (!acceptedFiles.includes(fileExtension.toLowerCase())) {

            const errMsg = `The file ${vm.$options.filters.truncate(file.name, 64, "...")} is not a supported type.`;

            vm.$toasted.error(errMsg)

            /**
             * Calling done(...) with a parameter (errMsg) to tell Dropzone,
             * that the file type check has failed.
             */
            done(errMsg);

            return;
          }

          file["promptIndex"] = vm.prompts.length;

          const trimmedName = file.name.substr(0, file.name.lastIndexOf('.'));

          vm.prompts.push({
            file,
            done,
            data: {
              title: trimmedName,
              description: '',
              type: 'source',
              encode: !vm.defaultData['encode-later'],
              autoSubs: vm.defaultData['auto-subs'],
              autoTranslate: vm.defaultData['auto-translate'],
              medicalTranscribeType: vm.defaultData['medical-transcribe-type'],
              medicalTranscribe: vm.defaultData['medical-transcribe']
            },
            isDirty: false,
            status: "staged",
            formState: {},
            isFormVisible: false,
            id: vm.promptUniqueIDReached
          });

          vm.promptUniqueIDReached++;

          vm.toggleAddPromptToBulkList(vm.prompts[vm.prompts.length - 1]);
        },

        sending(file, xhr) {
          /**
           * Hijack the native send request, since it is not
           * used and only throws an error if present
           */
          xhr.send = () => null;
        },
      },

      isSelectAllOptionApplied: false,
      bulkList: {},
      bulkType: 'source',
      bulkGroup: '',
      bulkGenre: '',
      bulkEncode: false,
      bulkAutoSubs: false,
      bulkAutoTranslate: false,
      bulkMedicalTranscribeType: "dictation",
      bulkMedicalTranscribe: false,
      possibleMedicalTranscription: [
        "dictation",
        "conversation"
      ],
      defaultData: {},

      isUploadInProgress: false
    };

  },
  props: {},
  computed: {
    ...mapGetters({
      uploadConfig: "app/uploadConfig",
      distributors: 'ingest/ingestDistributors',
      availableDeployments: "app/availableDeployments",
      userGoogleDriveData: 'ingest/userGoogleDriveData',
      userGoogleDriveTempData: 'ingest/userGoogleDriveTempData',
      userData: 'app/userData'
    }),

    availableDistributors() {

      return this.distributors.filter(el => el.guid != 'zoom-ia');
    },

    isUserGoogleDriveConnect() {

      const googleDistributor = this.distributors.find(el => el.guid === "google-drive");

      if ( !googleDistributor
        || !this.userGoogleDriveData && !this.userGoogleDriveTempData
        || this.userGoogleDriveData && this.userGoogleDriveData.userGuid != this.userData.guid
        || !googleDistributor.hasOwnProperty('seedings')
        || this.userGoogleDriveData && !googleDistributor.seedings.hasOwnProperty(this.userGoogleDriveData.email)
        || this.userGoogleDriveTempData && !googleDistributor.seedings.hasOwnProperty(this.userGoogleDriveTempData.email) ) {

        return false
      };

      return true;
    },
    availableIngestService() {

      return (this.availableDeployments.some(deployment => deployment.type === 'ingest') && this.$auth(['sources_list','sources_ingest']));
    },
    bulkSelectedCount() {

      return Object.keys(this.bulkList).length;
    }
  },
  methods: {
    ...mapActions({
      getMultipartUploadConfigs: "app/getMultipartUploadConfigs",
      makeUploadFileMetaRequest: "channelManager/makeUploadFileMetaRequest",
      getBrokerSettings: "channelManager/getBrokerSettings",
    }),

    ...mapMutations({

      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      setPendingUploadAsset: 'app/SET_PENDING_UP_ASSET_KEY',
      setVodSettings: 'channelManager/SET_VOD_SETTINGS'
    }),

    browse() {

      this.dropzoneComponent.hiddenFileInput.click();
    },

    toggleChevron(state) {

      this.isImportOpen = state;
    },

    navigateToIngest(distributor) {

      let queries = {};

       if ((distributor.guid === 'google-drive' && this.isUserGoogleDriveConnect) || (distributor.guid != 'google-drive' && distributor.enabled)) {

        queries.type = 'import';
        queries.distributor = distributor.guid;
      } else if (!distributor.enabled || (distributor.guid === 'google-drive' && !this.isUserGoogleDriveConnect)){

        queries.type = 'connect';
        queries.distributor = distributor.guid;
      }

      const routeParams = {

          path: "/vod/library/ingest"
        };

      routeParams.query = queries;

      return this.$pushRoute(routeParams);

    },

    getOrganizationsSettings() {

      this.getBrokerSettings('').then((defaultData) => {

        this.defaultData = defaultData.reduce((acc,item) => {
          acc[item.guid] = item.value;
          return acc;
        },{} );

      })
      .finally(() => {

        this.setDefaultData();

      });


    },

    setDefaultData() {

      this.bulkAutoSubs = this.defaultData['auto-subs'];
      this.bulkAutoTranslate = this.defaultData['auto-translate'];
      this.bulkMedicalTranscribeType = this.defaultData['medical-transcribe-type'];
      this.bulkEncode = !this.defaultData['encode-later'];
      this.bulkMedicalTranscribe = this.defaultData['medical-transcribe'];

      const settings = this.defaultData;
      this.setVodSettings(settings);

    },

    togglePromptFormVisible(prompt) {

      const newVal = !prompt.isFormVisible;

      this.prompts.splice(prompt.file.promptIndex, 1, {

        ...prompt,
        isFormVisible: newVal
      });
    },

    toggleSelectAllOptionApplied() {

      const newVal = !this.isSelectAllOptionApplied;

      const updatedBulkList = {};

      if (newVal === true) {

        this.prompts.forEach((prompt) => {

          updatedBulkList[prompt.id] = prompt;
        });
      }

      this.bulkList = updatedBulkList;

      this.isSelectAllOptionApplied = newVal;
    },

    toggleBulkMedicalTranscribe() {

      this.bulkMedicalTranscribe = !this.bulkMedicalTranscribe;
    },

    toggleBulkEncode() {

      this.bulkEncode = !this.bulkEncode;
    },

    toggleBulkAutoSubs() {

      this.bulkAutoSubs = !this.bulkAutoSubs;

      this.bulkAutoSubs ? null : this.bulkAutoTranslate = false;
    },

    toggleBulkAutoTranslate() {

      this.bulkAutoSubs ? this.bulkAutoTranslate = !this.bulkAutoTranslate : null;
    },

    cleanPromptsState(errorsFound) {

      if (errorsFound) {

        const filteredPrompts = this.prompts.filter(p => p.status === 'error');

        this.prompts = filteredPrompts;
        this.promptUniqueIDReached = this.prompts.length + 1;

        return;
      }

      this.prompts = [];
      this.promptUniqueIDReached = 1;
    },

    togglePromptEncodeStatus(prompt) {

      const promptIndex = prompt.file.promptIndex;

      const newEncodeVal = !prompt.data.encode;

      const updatedPrompt = {

        ...this.prompts[promptIndex],

        data: {

          ...prompt.data,
          encode: newEncodeVal
        }
      };

      this.prompts.splice(promptIndex, 1, updatedPrompt);
    },

    togglePromptMedicalTranscribeStatus(prompt) {

      const promptIndex = prompt.file.promptIndex;

      const newMedicalTranscribeVal = !prompt.data.medicalTranscribe;

      const updatedPrompt = {

        ...this.prompts[promptIndex],

        data: {

          ...prompt.data,
          medicalTranscribe: newMedicalTranscribeVal
        }
      };

      this.prompts.splice(promptIndex, 1, updatedPrompt);
    },

    togglePromptAutoSubsStatus(prompt) {

      const promptIndex = prompt.file.promptIndex;

      const newSubsVal = !prompt.data.autoSubs;

      const updatedPrompt = {

        ...this.prompts[promptIndex],

        data: {

          ...prompt.data,
          autoSubs: newSubsVal
        }
      };

      this.prompts.splice(promptIndex, 1, updatedPrompt);
    },

    togglePromptAutoTranslateStatus(prompt) {

      const promptIndex = prompt.file.promptIndex;

      const newSubsVal = !prompt.data.autoTranslate;

      const updatedPrompt = {

        ...this.prompts[promptIndex],

        data: {

          ...prompt.data,
          autoTranslate: newSubsVal
        }
      };

      this.prompts.splice(promptIndex, 1, updatedPrompt);
    },


    toggleAddPromptToBulkList(prompt) {

      const updatedBulkList = {
        ...this.bulkList
      };

      if (!updatedBulkList[prompt.id]) {

        updatedBulkList[prompt.id] = prompt;

      } else {

        delete updatedBulkList[prompt.id];
      }

      this.bulkList = updatedBulkList;

      this.evaluateIsSelectAllApplied();
    },

    applyToBulkSelected() {

      const updatedPrompts = this.prompts.map(prompt => {

        if (!this.bulkList[prompt.id]) {

          return prompt;
        }

        return {

          ...prompt,

          data: {

            ...prompt.data,

            type: this.bulkType,
            group: this.bulkGroup,
            genre: this.bulkGenre,
            encode: this.bulkEncode,
            autoSubs: this.bulkAutoSubs,
            autoTranslate: this.bulkAutoTranslate,
            medicalTranscribeType: this.bulkMedicalTranscribeType,
            medicalTranscribe: this.bulkMedicalTranscribe
          }
        }
      });

      this.prompts = [...updatedPrompts];

      /**
       * Null the bulk inputs' values
       */
      this.bulkList = {};
      this.isSelectAllOptionApplied = false;
      this.bulkType = '';
      this.bulkGroup = '';
      this.bulkGenre = '';

      this.setDefaultData()
    },

    evaluateIsSelectAllApplied() {

      if (this.prompts.length === this.bulkSelectedCount) {

        this.isSelectAllOptionApplied = true;

      } else {

        this.isSelectAllOptionApplied = false;
      }
    },

    onSubmitAll() {

      let isAnyFormInvalid = false;

      for(var i = 0; i < this.prompts.length; i++) {

        const prompt = this.prompts[i];

        prompt.isDirty = true;

        if (prompt.formState.$invalid) {

          isAnyFormInvalid = true;

          /**
           * These calls will display the forms' validation messages
           */
          prompt.isFormVisible = true;
          prompt.formState._submit();
          prompt.formState._validate();
        }
      }

      if (isAnyFormInvalid) {

        this.$toasted.error('Some of the files you selected have incomplete data. Please fill the required fields.')
        return;
      }

      this.isUploadInProgress = true;

      this.$Amplify.Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;

        this.prompts.forEach(prompt => {

          this.sendUploadRequest(prompt, token);
        });
      });
    },

    sendUploadRequest(prompt, token) {

      prompt["status"] = "submitted";

      const file = prompt.file;

      let meta = {
        title: prompt.data.title,
        type: prompt.data.type,
        originalFileName: file.name,
      };

      if (prompt.data.description) {

        meta.description = prompt.data.description;
      }

      if (!prompt.data.encode) {

        meta.encodeLater = '1';
      } else {

        meta.encodeLater = '0';
      }

      if (prompt.data.autoSubs) {

        meta.autoSubs = '1';
      } else {

        meta.autoSubs = '0';
      }

      if (prompt.data.autoTranslate) {

        meta.autoTranslate = '1';
      } else {

        meta.autoTranslate = '0';
      }

      if (prompt.data.group) {
        meta.group = prompt.data.group;
      }

      if (prompt.data.genre) {
        meta.genre = prompt.data.genre;
      }

      if (prompt.data.imageUrl) {
        meta.imageUrl = prompt.data.imageUrl;
      }

      if (prompt.data.medicalTranscribe) {

        meta.medicalTranscribe = '1';
        meta.medicalTranscribeType = prompt.data.medicalTranscribeType;
      } else {

        meta.medicalTranscribe = '0';
      }

      // new meta fields should be added here
      const augMetaRefId = `augMeta${prompt.id}`;

      const additionalAugmentedMeta = this.$refs[augMetaRefId][0].gatherMeta();

      meta = {

        ...meta,
        ...additionalAugmentedMeta
      }

      /**
       * There are problems, uploading NON ASCII strings to S3 in the meta.
       * The makeUploadFileMetaRequest makes it possible to first upload
       * the meta, regardless of the strings in it, then the main upload
       * only requires metaGuid
       */
      this.makeUploadFileMetaRequest({ meta }).then((data) => {

        const augmentedMeta = {

          metaGuid: data.guid
        };

        prompt.file["meta"] = augmentedMeta;

        const {
          bucket,
          bucketRegion,
          cognitoRegion,
          identityPoolId,
          logins
        } = this.uploadConfig;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
          {
            IdentityPoolId: identityPoolId,
            Logins: {
              [logins.cognito]: token
            }
          },
          {
            region: cognitoRegion
          }
        );

        if (this.uploadConfig.hasOwnProperty('hasAcceleration')) {

          AWS.config.useAccelerateEndpoint = this.uploadConfig.hasAcceleration;
        };

        /**
         * Get the file extension, generate an md5 hash of the file name.
         * Add a timestamp to insure an unique key will be generated.
         */
        var extension = file.name.substr(file.name.lastIndexOf(".") + 1);

        const key = `${md5(file.name + new Date().getTime())}.${extension}`;

        this.setPendingUploadAsset({

          key,
          assetData: meta,
          isPending: true
        });

        let params = {
          Bucket: bucket,
          Key: key,
          Body: file,
          Metadata: augmentedMeta,
          StorageClass: 'STANDARD_IA'
        };

        const fileSize = file.size;
        const partSizeMininum = 5 * 1024 * 1024; // (5mb)
        const maxPartChunks = 10000;

        const fileSizeDivided = fileSize / maxPartChunks;

        const partSizeOptimized = fileSizeDivided < partSizeMininum ?
          partSizeMininum : fileSizeDivided;

        const maxQueueSize = 20;
        const filesCount = this.prompts.length;

        const queueSizeDivided = Math.floor(maxQueueSize / filesCount);

        const queueSizeOptimized = queueSizeDivided > 1 ? queueSizeDivided : 1;

        file.s3upload = new S3.ManagedUpload({
          params: params,

          partSize: partSizeOptimized,
          queueSize: queueSizeOptimized,
        });

        file.s3upload.on("httpUploadProgress", progress => {
          if (progress.total) {

            var percent = (progress.loaded * 100) / progress.total;

            const currentPercentage = this.prompts[file.promptIndex].progressPercentage;

            if (currentPercentage > percent) {

              return;
            }

            this.dropzoneComponent.emit(
              "uploadprogress",
              file,
              percent,
              progress.loaded
            );

            const updatedFileWithProgress = {

              ...this.prompts[file.promptIndex],
              progressPercentage: percent
            };

            this.prompts.splice(file.promptIndex, 1, updatedFileWithProgress);
          }
        });

        prompt.status = "loading";

        file.s3upload.send((err, data) => {

          if (err) {

            console.error("s3upload err", err);

            const currentPromptIndex = prompt.file.promptIndex;

            this.prompts.splice(currentPromptIndex, 1, {
              ...prompt,
              status: 'error'
            });

            this.onFileUploadFinished(prompt, err);

          } else {

            let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

            let params = {
              Bucket: bucket,
              ACL: "bucket-owner-full-control",
              Key: key
            };

            s3.putObjectAcl(params, (err, data) => {

              if (err) {

                console.error("putObjectAcl err", err);

              } else {


                const currentPromptIndex = prompt.file.promptIndex;

                this.prompts.splice(currentPromptIndex, 1, {
                    ...prompt,
                    file: {

                      uploadURL: data,
                      // copy name && promptIndex manually, since they are read only
                      name: prompt.file.name,
                      promptIndex: currentPromptIndex
                    },
                    status: 'uploaded'
                  });

                prompt.done();

                this.onFileUploadFinished(prompt);
              }
            });
          }
        });
      });
    },

    onFileUploadFinished(prompt, err) {

      let areAllFilesDoneUploading = true;
      let isErrorFoundUploading = false;

      for (var i = 0; i < this.prompts.length; i++) {
        /**
        * Prevent the Add Source Modal from closing, since there
        * are still videos, that haven't uploaded yet
        */
        if (this.prompts[i].status !== "uploaded" &&
          this.prompts[i].status !== "error") {

          areAllFilesDoneUploading = false;
        }

        if (this.prompts[i].status === "error") {

          isErrorFoundUploading = true;
        }

        if (!areAllFilesDoneUploading && isErrorFoundUploading) {

          break;
        }
      }

      if (err) {

        this.$toasted.error(err);

      } else {

        this.$toasted.success(`${this.$options.filters.truncate(prompt.data.title, 64, "...")} was uploaded successfully.`);
      }

      if (areAllFilesDoneUploading) {

        this.cleanPromptsState(isErrorFoundUploading);

        this.isUploadInProgress = false;
      }
    },

    isValidTitle(index) {
      const prompt = this.prompts[index];

      return !prompt.data.title.trim().length && prompt.isDirty;
    },

    isValidType(index) {
      const prompt = this.prompts[index];

      return !prompt.data.type.trim().length && prompt.isDirty;
    },

    isValidDescription(index) {
      const prompt = this.prompts[index];

      return !prompt.data.description.trim().length && prompt.isDirty;
    },

    unstageFileFromUpload(fileIndex) {

      const prompt = this.prompts[fileIndex];

      const file = prompt.file;

      const updatedBulkList = {

        ...this.bulkList
      };

      delete updatedBulkList[prompt.id];

      this.bulkList = updatedBulkList;

      this.dropzoneComponent.removeFile(file);

      this.prompts.splice(fileIndex, 1);

      this.evaluateIsSelectAllApplied();
    },
  },

  watch: {
    prompts() {

      if (this.prompts.length) {

        this.setRedirectGuard({
          redirectMsg: 'Your video/videos may not be uploaded if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    }
  },
  created() {
    this.getMultipartUploadConfigs();
    this.getOrganizationsSettings();
  }
};
</script>

<style lang="scss">


$headerHeight: 46px;
$footerHeight: 68px;

.add-source-component {

  min-height: 200px;

  label, .tag {
    @apply font-medium;
  }

  .staged-vods-container {

    max-height: calc(100% - (#{$headerHeight} + #{$footerHeight}));
    overflow: auto;
  }

  .silver {

    @apply text-black-400;
  }

  .upload-in-progress {

    height: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    border: none !important;
  }

  .settings {
    display: flex;
  }
  .settings-label {
    display: none;
  }
  .file-name {
    width: 12rem;
  }
  .settings-end {
    margin-left: auto;
  }
  .ds-dropdown__menu {
    .connect {
      @apply
      text-sm
      font-medium
      text-blue-600;
    }
    .distributor-enabled {
      @apply
      text-sm
      font-medium
      text-black-700;
    }

    .distributor-connect {
      @apply
      text-sm
      font-medium
      text-black-400;
    }

   .border-bottom:not(:last-of-type) {
      @apply
      border-b
      border-black-50
      border-solid;
    }
  }
  @media only screen and (max-width: 1700px){
    .file-name {
      width: 7.5rem;
    }
  }
  @media only screen and (max-width: 1550px){
    .settings-section {
      justify-content: space-between;
    }
    .settings {
      display: block;
    }
    .settings-label {
      display: block;
    }
    .file-name {
      width: 12rem;
    }
    .settings-end {
      margin-left: 0;
    }
  }


  /**
   * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
   * Tailwind classes are present in the HTML, so deleting the following lines,
   * when styleberry is deprecated should still work.
  **/

  @import "@indexScss";


  /* @include overwrite-styleberry-styles-inputs; */

  @include overwrite-styleberry-styles-tags;
  @include overwrite-styleberry-styles-htags-margin;
  @include overwrite-styleberry-styles-htags-font-size;

  .main-content {

    height: calc(100% - 6rem);
  }

  #customdropzone {
    background-color: var(--sceneBgColor);
    border-radius: 8px;
    border: 2px dashed var(--sceneBrColor);
    color: var(--sceneColor);
    transition: background-color 0.2s linear;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  #customdropzone:hover {
    background-color: var(--color-blue-50);
    border: 2px dashed var(--windowBrColor);
  }
  #customdropzone.staged-files-present {
    height: 6rem;
  }
  #customdropzone .dz-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  #customdropzone {

    .dz-preview.dz-file-preview,
    .dz-error.dz-preview {

      display: none;
    }
  }
}



</style>
