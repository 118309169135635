<template>
<div class="download-vod-modal-component">

  <ioio-modal
    class=""
    :show.sync="isModalOpened"
    size="medium"
    headerClasses=""
    :noClose="isRequestPending"
    :clickOutside="!isRequestPending"
  >

    <template slot="header" >

      <div class="pb-4">
         {{ headerText }}
      </div>
    </template>

    <section>
      <ioio-radio
        v-if="qualityOptions.length"
        topic="downloadOptions"
        name="downloadOptions"
        class="w-full mb-4"
        :options="qualityOptions"
        size="small"
        v-model="selectedQualityOption"
        :checkedVal="selectedQualityOption"
        :disabled="isRequestPending"
      />

      <div class="py-8" v-if="isRequestPending">

        <ioio-loader isShown width="100" class="my-2"/>
      </div>

    </section>


    <template slot="footer">
      <div class="flex justify-end">
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="closeModal"
          :disabled="isRequestPending"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="primary"
          @click.stop.prevent="download"
          :disabled="isRequestPending"
        >
          Download
        </ioio-button>
      </div>
    </template>
  </ioio-modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { downloadVodFromUrl } from '@utils/helpers';

export default {
  data: () => ({

    proxyUrl: '',
    qualityOptions: [],

    selectedQualityOption: '',
    isRequestPending: false,
  }),
  props: {

  },
  created() {
    window.ds = this;
  },
  methods: {

    closeModal() {

      this.toggleVodActionModalOpened({ flagName: 'isDownloadModalOpened', newVal: false });
    },

    download(filename) {

      this.isRequestPending = true;

      if (!this.qualityOptions.length) {

        this.makeGetVodSignedUrlRequest(this.forModalActionVod.guid)
          .then(response => {

            this.setupDownloadSignedUrlLink(response.downloadLink);
          }).finally(() => {

            this.closeModal();
          });

        return;
      }

      downloadVodFromUrl(this.selectedQualityOption)
        .catch((err) => {

          this.$toasted.error('There was a problem downloading this video.');
        })
        .finally(() => {

          this.isRequestPending = false;
          this.closeModal();
        });
    },

    setupDownloadSignedUrlLink(url) {

      const element = document.createElement('a');

      element.setAttribute('href', url);
      element.setAttribute('download', this.forModalActionVod.meta.title);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    ...mapMutations({
      toggleVodActionModalOpened: "library/TOGGLE_ACTION_MODAL_OPENED",
    }),
    ...mapActions({

      makeGetVodSignedUrlRequest: "channelManager/makeGetVodSignedUrlRequest",

      getVodCompressedFormats: 'channelManager/getVodCompressedFormats',
    }),

  },
  computed: {
    ...mapGetters({
      forModalActionVod: 'library/forModalActionVod',
      isDownloadModalOpened: 'library/isDownloadModalOpened'
    }),

    isModalOpened: {

      get() {

        return this.isDownloadModalOpened;
      },

      set(newVal) {

        this.toggleVodActionModalOpened({ flagName: 'isDownloadModalOpened', newVal });
      }
    },

    headerText() {

      if (this.isRequestPending && !this.qualityOptions.length) {

        return 'Download';

      } else if (!this.isRequestPending && !this.qualityOptions.length) {

        return 'Download source';

      } else {

        return 'Choose format';
      }
    }
  },

  watch: {

    isDownloadModalOpened() {

      if (this.isDownloadModalOpened) {

        this.qualityOptions = [];

        this.selectedQualityOption = '';

        // The vod doesn't have mp4 options to choose from. Download from signedUrl
        if (!this.forModalActionVod.mp4Outputs || !this.forModalActionVod.mp4Outputs.length) {

          return;
        }

        this.isRequestPending = true;

        let formatOptions = [];

        let qualityOption = '';

        // fetch possible compressed formats
        this.getVodCompressedFormats({ guid: this.forModalActionVod.guid })
          .then(response => {

            const formats = response.sort((a, b) => a.height - b.height);

            for (let i = 0; i < formats.length; i++) {

              let formatType;
              let disclaimer;
              qualityOption = `${formats[i].cdnUrl}`;

              if (i === formats.length - 1) {

                formatType = 'Original';
                disclaimer = 'Original size and quality of video';

              } else {

                formatType = 'Compressed';
                disclaimer = 'The video will have reduced size and quality';
              }

              const currentFormat = {

                value: qualityOption,
                label: `${formatType} (${formats[i].height}p)`,
                disclaimer
              };

              formatOptions.push(currentFormat);
            }

            this.qualityOptions = [ ...formatOptions ];

            this.selectedQualityOption = qualityOption;
          })
          .finally(() => {

            this.isRequestPending = false;
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>



</style>
