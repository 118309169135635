<template>
<ioio-tabs
  size="medium"
  class="library-nav-new-component"
  @input="onLibraryDivisionChange">
  <ioio-tab
    v-for="(item, i) in availableNavItems"
    :selected="activeNavPath === item.redirectUrl"
    :key="i"
    :name="i"
  >
  </ioio-tab>
</ioio-tabs>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  data: () => ({

    activeNavPath: '',
    navItemsConfig: {
      'Video Assets': {
        redirectUrl: '/vod/library-new'
      },
      // NOTE: hide for now
      // 'Playlists': {
      //   redirectUrl: '/vod/library/playlists'
      // },
      // 'Workflow Archives': {
      //   redirectUrl: '/vod/library/workflows',
      //   permission: 'WORKFLOW'
      // },
      // 'Syndication': {
      //   redirectUrl: '/vod/library/syndication',
      //   permission: 'syndication_lists_list'
      // },
      // 'Images': {
      //   redirectUrl: '/images-library',
      //   permission: 'images_list'
      // }
    },

    isNavOpened: false
  }),

  created() {

    this.activeNavPath = this.$route.path;

    // NOTE: hide for now
    // if (this.availableDeployments.some(deployment => deployment.type === 'ingest')) {

    //   this.navItemsConfig['Ingest'] = {

    //     redirectUrl: '/vod/library/ingest',
    //     permission: ['sources_list','sources_ingest']
    //   };
    // }
  },
  computed: {
    ...mapGetters({
      availableDeployments: "app/availableDeployments"
    }),

    // filtered items, based on permissions
    availableNavItems() {

      const items = {};

      for (let key in this.navItemsConfig) {

        if (this.$auth(this.navItemsConfig[key].permission)) {

          items[key] = this.navItemsConfig[key];
        }
      }

      return items;
    }
  },

  methods: {

    onLibraryDivisionChange(newVal) {

      // Navigate to the requested state
      this.$pushRoute(this.navItemsConfig[newVal].redirectUrl);
    },

    toggleNavOpened() {

      this.isNavOpened = !this.isNavOpened;
    }
  }
};
</script>

<style lang="scss">


</style>
