<template>
<div class="er" id="pdf" v-if="!isRequestPending">

  <ioio-modal
    :show.sync="isDownload"
    size="medium"
    :noScroll="false"
    headerClasses="text-center pt-9"
    footerClasses=""
    :noClose="true"
  >
    <template slot="header">The PDF file is being generated...</template>
  </ioio-modal>

  <div class="er__title-block">
    <h1 class="er__main-title">
      Reports
    </h1>
    <div id="name" class="hidden mb-4">
      <h2 class="mb-2 text-2xl"> {{$attrs.eventDetails.name}} </h2>
      <p class="text-blue-grey-600 font-normal"> {{ moment($attrs.eventDetails.startTime).format('DD MMM, h:mm a') }} - {{ moment($attrs.eventDetails.endTime).format('h:mm a') }} </p>
    </div>

    <div class="er__export-buttons">
      <ioio-button
        class="mr-4"
        type="primary"
        @click="generatePDF"
      >Download PDF
      </ioio-button>


      <ioio-button
        type="primary"
        variant="outline"
        @click="exportToCsv"
      >Export CSV
      </ioio-button>
    </div>

  </div>
  <!-- Top statistics -->
  <div class="er__top-stats">
    <div class="er__top-stats-el">
      <div class="er__top-stats-value">
        {{topbarStats.registrations}}
      </div>
      <div class="er__top-stats-name">
        Registrations
        <ioio-tooltip text="Total number of registrations for the event.">
          <ioio-icon
            class="er__infobox"
            icon="far-circle-info"/>
        </ioio-tooltip>
      </div>
    </div>
    <div class="er__top-stats-el">
      <div class="er__top-stats-value">
        {{topbarStats.attendees}}
      </div>
      <div class="er__top-stats-name">
        Attendees
        <ioio-tooltip text="Total number of attendees in the event.">
          <ioio-icon
            class="er__infobox"
            icon="far-circle-info"/>
        </ioio-tooltip>
      </div>
    </div>
    <div class="er__top-stats-el">
      <div class="er__top-stats-value">
        {{topbarStats.attendanceRate}}%
      </div>
      <div class="er__top-stats-name">
        Attendance rate
        <ioio-tooltip text="Percentage rate of attendees in total registrations.">
          <ioio-icon
            class="er__infobox"
            icon="far-circle-info"/>
        </ioio-tooltip>
      </div>
    </div>
    <div class="er__top-stats-el">
      <div class="er__top-stats-value">
        {{topbarStats.avgWatchTime}}
      </div>
      <div class="er__top-stats-name">
        Avg watch time
        <ioio-tooltip text="Average time attendees watched the event.">
          <ioio-icon
            class="er__infobox"
            icon="far-circle-info"/>
        </ioio-tooltip>
      </div>
    </div>
  </div>
  <!-- End of Top statistics -->
  <!-- Registrations -->
  <div class="er__chartbox">
    <div class="er__chartbox-rows-data">

      <div class="er__chartbox-main-stat">
        <div class="er__chartbox-main-stat-value">
          {{registrationPageStats.registrations}}
        </div>
        <div class="er__chartbox-main-stat-name">
          Total registrations
        </div>
      </div>

      <div class="er__chartbox-rows">
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-browser"/>
            <p class="er__chartbox-name">Page visits</p>
            <ioio-tooltip text="Total number of page visits.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{registrationPageStats.pageVisits}}</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-user"/>
            <p class="er__chartbox-name">Unique page visits</p>
            <ioio-tooltip text="Number of unique visitors of the page.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{registrationPageStats.uniqueVisitors}}</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-clock-three"/>
            <p class="er__chartbox-name">Registrations</p>
            <ioio-tooltip text="Total number of registrations for the event.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{registrationPageStats.totalRegistrations}}</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-percent"/>
            <p class="er__chartbox-name">Sign-up rate</p>
            <ioio-tooltip
              text="Percentage of registrations within unique page visits (registrations / unique page visits)."
              multiline
            >
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{registrationPageStats.signUpRate}}%</div>
        </div><!-- End of row stat -->
      </div>
    </div>

    <div class="er__chartbox-chart">
      <div class="er__chartbox-chart-top-bar">
        <h2 class="er__chartbox-chart-title">Registration page</h2>
        <!-- <ioio-dropdown
          id="registration"
          v-model="registrationPeriod"
          :items="periodOptions"
          titleProp="name"
          valueProp="id"
          placeholder="Select type"
          @change="changePeriod"
        /> -->
        <div id="registrationPDF" >
          Since event creation
        </div>

      </div>
      <apexchart width="100%" height="400" type='bar' :options="registrationsOptions" :series="registrationsSeries" ></apexchart>
    </div>
  </div>
  <!-- End of Registrations -->
  <!-- Post-Event Reports -->
  <div class="er__chartbox" v-if="finishedEvent">
    <div class="er__chartbox-rows-data">

      <div class="er__chartbox-main-stat">
        <div class="er__chartbox-main-stat-value">
          {{liveEngagementStats.attendees}}
        </div>
        <div class="er__chartbox-main-stat-name">
          Total attendees
        </div>
      </div>

      <div class="er__chartbox-rows">
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-user-clock"/>
            <p class="er__chartbox-name">Avg. watch time</p>
            <ioio-tooltip text="Average time attendees watched the event.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{liveEngagementStats.avgWatchTime}}</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-percent"/>
            <p class="er__chartbox-name">Attendance rate</p>
            <ioio-tooltip multiline text="Percentage rate of attendees in total registrations.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{liveEngagementStats.attendanceRate}}%</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-clock-three"/>
            <p class="er__chartbox-name">Sessions length</p>
            <ioio-tooltip multiline text="Total duration of the event. That is the total length of all sessions of the event as planned.">
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{liveEngagementStats.sessionsLength}}</div>
        </div><!-- End of row stat -->
        <!-- row stat -->
        <div class="er__chartbox-row">
          <div class="er__chartbox-row-text">
            <ioio-icon class="er__chartbox-icon" icon="fal-comment"/>
            <p class="er__chartbox-name">Chat messages</p>
            <ioio-tooltip
              text="Number of chat messages during the event. (that is in the public chat)."
              multiline
            >
              <ioio-icon
                class="er__infobox"
                icon="far-circle-info"/>
            </ioio-tooltip>
          </div>

          <div class="er__chartbox-value">{{liveEngagementStats.chatMessages}}</div>
        </div><!-- End of row stat -->
      </div>
    </div>

    <div class="er__chartbox-chart">
      <div class="er__chartbox-chart-top-bar">
        <h2 class="er__chartbox-chart-title">Live engagement</h2>
        <div class="w-64">
          <ioio-dropdown
            class="engagement"
            v-model="selectEngagement"
            :items="engagementOptions"
            titleProp="name"
            valueProp="id"
            placeholder="Select type"
            @change="changeOptions"
            fullWidth
          />
        </div>
        <div id="engagementPDF" class="hidden">
          {{engagementOptions[selectEngagement].name}}
        </div>
      </div>

      <apexchart width="100%" height="400" type='area' :options="liveEngagementOptions" :series="liveEngagementSeries" ></apexchart>
    </div>
  </div>
  <!-- End of Post-Event Reports -->
  <div class="er__chartbox">
    <div class="er__chartbox-rows-location">
      <section class="pt-10 pr-10 pb-6 pl-8 flex flex-auto flex-column h-full">
        <h2 class="font-medium text-lg mb-6">Locations</h2>
        <ioio-table
          id="location"
          class="p-0 border-0 shadow-none"
          :group= true
          :tableData="locationData"
          :headData="headData"
          :keyConfig="keyConfig"
        >
        </ioio-table>
      </section>
    </div>
    <div class="w-1/2">
      <section class="p-8 geochart-container h-full">
        <p v-if="!locationData.length" id="noData" class="py-2">No information found</p>
        <GChart
          class="h-full"
          :settings="{ packages: ['geochart'], mapsApiKey: 'AIzaSyA4h6hYLW5eYzWVZHmOpwNnIp4QJrq2guA' }"
          type="GeoChart"
          :data="locationChartData"
          :options="locationChartOptions"
          ref="gChart"
          :class="{'geo-chart-hidden': isGeoChartHidden}"
          @ready="onChartReady"
        />
      </section>
    </div>
  </div>
</div>
<div v-else>
  <ioio-loader isShown/>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  liveEngagementOptions,
  registrationsOptions
  } from './charts.config.js';

import { downloadCSV, downloadPDF } from '@utils/helpers';

import moment from "moment-timezone";

export default {
  data: () => ({
    locationChartOptions: {
      legend: 'none',
      colorAxis: {colors: ['#12c312', '#006400']},
      title: 'Total Views',
      displayMode: 'auto',
    },
    headData: [
      "Country",
      "City",
      "Registered",
      "Attended"
    ],
    keyConfig: {
      'groupKey' : "country",

      'keyOrder' : [
        "country",
        "name",
        "registered",
        "attended"
      ],
      'accKeys' : [
        "registered",
        "attended"
      ],
    },

    isGeoChartHidden: true,

    topbarStats: {},
    liveEngagementStats: {},
    registrationPageStats: {},

    liveEngagementOptions: liveEngagementOptions,
    liveEngagementSeries:[],
    allLiveEngagementSeries:[],
    liveEngagementColors: ['#6179FF', '#6FCF97'],

    registrationsOptions: registrationsOptions,
    registrationsSeries: [],

    isRequestPending: false,
    isDownload: false,

    chartData:[],
    locationData: [],
    locationChartData: [],

    moment: {},

    periodOptions: [
      {"id":0,"name":"Since event creation", "value":"All"},
      {"id":1,"name":"last 7 days", "value":"7d"},
      {"id":2,"name":"last 14 days", "value":"15d"}
    ],
    registrationPeriod:  0,

    engagementOptions: [
      {"id":0,"name":"All"},
      {"id":1,"name":"Attendees"},
      {"id":2,"name":"Chat messages"}
    ],
    selectEngagement: 0

  }),
  props: {},

  created () {

    this.moment = moment;
    this.setEventsStats();
  },

  mounted () {

  },
  methods: {

    changePeriod(id) {

      this.isRequestPending = true;

      const newPeriod = this.periodOptions.find(period => period.id === id).value;

      const params = {

        eventGuid: this.$attrs.eventDetails.guid,

        type: 'rv_report',
      };

      if (newPeriod != 'All') {

        params['query'] = {

          tr : newPeriod
        }

      }

      this.getEventReportStats(params)

        .then(stats => {

          this.registrationsSeries = [...stats.chart.DTO.series];

          this.registrationsOptions = {
            ...this.registrationsOptions,
            xaxis: {
              ...this.registrationsOptions.xaxis,
              categories: [
                ...stats.chart.DTO.categories
              ]
            },
            yaxis: {
              ...this.registrationsOptions.yaxis,
              ...stats.chart.DTO.yaxis
            },
          };

          ApexCharts.exec('registrations', 'updateOptions', this.registrationsOptions);

          this.registrationPageStats = {...stats.rows};

          setTimeout(() => {

            const th = document.querySelectorAll('.rtable th, .rtable td');

            for (let index = 0; index < th.length; index++) {

              th[index].style.minWidth = "0px";
            }

          }, 200);

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    changeOptions(id) {

      let newOptionName = this.engagementOptions.find(option => option.id === id).name;

      if (newOptionName === 'All') {

        this.liveEngagementSeries = this.allLiveEngagementSeries;

        ApexCharts.exec('liveEngagement', 'updateOptions', {

          colors: [...this.liveEngagementColors]

        });

      } else {

        let index = this.allLiveEngagementSeries.findIndex(option => option.name === newOptionName)

        this.liveEngagementSeries = [this.allLiveEngagementSeries[index]];

        ApexCharts.exec('liveEngagement', 'updateOptions', {

          colors: [this.liveEngagementColors[index]]

        }, false, true);

      }

    },

    setEventsStats() {

      this.isRequestPending = true;

      const params = {

        eventGuid: this.$attrs.eventDetails.guid,

        type: 'report',
      };

      this.getEventReportStats(params)

        .then(stats => {

          this.allLiveEngagementSeries = [...stats.peReport.chart.DTO.series];
          this.liveEngagementSeries = [...stats.peReport.chart.DTO.series];

          this.liveEngagementOptions = {
            ...this.liveEngagementOptions,
            yaxis: {
              ...this.liveEngagementOptions.yaxis,
              ...stats.peReport.chart.DTO.yaxis
            }
          };

          this.registrationsSeries = [...stats.rvReport.chart.DTO.series];

          this.registrationsOptions = {
            ...this.registrationsOptions,
            xaxis: {
              ...this.registrationsOptions.xaxis,
              categories: [
                ...stats.rvReport.chart.DTO.categories
              ]
            },
            yaxis: {
              ...this.registrationsOptions.yaxis,
              ...stats.rvReport.chart.DTO.yaxis
            },
          };

          this.topbarStats = {...stats.topBar};

          this.liveEngagementStats = {...stats.peReport.rows};

          this.registrationPageStats = {...stats.rvReport.rows};

          this.setChartData(stats.locations);

          setTimeout(() => {

            const th = document.querySelectorAll('.rtable th, .rtable td');

            for (let index = 0; index < th.length; index++) {

              th[index].style.minWidth = "0px";
            }

          }, 200);

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setChartData(stats) {

      this.locationData = [...stats];

      let group = {};

      this.locationData.forEach(element => {

        if (element.hasOwnProperty('name')) {

          let newName = element.name.split(',')[0] + ', ' + element.state;

          element.name = newName;
        } else {

          element['name'] = element.city + ', ' + element.state;
        }

        if (group[element[this.keyConfig.groupKey]]) {

          this.keyConfig.accKeys.forEach(key =>{

            group[element[this.keyConfig.groupKey]][key] += +element[key];
          })

        } else {

          let newElement = {};

          for (const key in element) {

            newElement[key] = '';
          }

          newElement[this.keyConfig.groupKey] = element[this.keyConfig.groupKey];

          this.keyConfig.accKeys.forEach(key =>{

            newElement[key] = +element[key];
          })

          group[element[this.keyConfig.groupKey]] = newElement;

        }
      });

      for (const key in group) {

        const elementsGroup = group[key];

        this.chartData.push(elementsGroup);
      }

    },

    onChartReady(chart,google) {

      const newLocationChartData = new google.visualization.DataTable();

      newLocationChartData.addColumn('string', 'Country');
      newLocationChartData.addColumn('string', 'Label');
      newLocationChartData.addColumn('number', 'Registered');
      newLocationChartData.addColumn('number', 'Attended');


      const locationChartEntries = [];

      this.chartData.forEach(l => {

        locationChartEntries.push([l.country,  l.country, +l.registered, +l.attended]);
      });

      newLocationChartData.addRows(locationChartEntries);

      if (this.chartData.length) {

        this.isGeoChartHidden = false;

      } else {

        this.isGeoChartHidden = true;
      }

      this.locationChartData = newLocationChartData;

      chart.draw(this.locationChartData, this.locationChartOptions);
    },

    exportToCsv() {
      // To change!!!!!!!!!!!!!!!!!!!

      this.isRequestPending = true;
      const params = {

        eventGuid: this.$attrs.eventDetails.guid,

        type: 'report',

        query: {

          org_id: this.orgGuid,

          is_csv: true
        }
      };


      this.getEventReportStats(params)
        .then(stats => {
          console.log(stats)
          downloadCSV(stats, 'stats.csv')
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    generatePDF () {

      this.isDownload = true;

      setTimeout(() => {

        this.exportToPDF();
      }, 0);
    },

    exportToPDF () {

      const chartboxValue = document.getElementsByClassName('er__chartbox-value');
      const chartboxName = document.getElementsByClassName('er__chartbox-name');
      // const registration = document.getElementById('registration');
      // const registrationPDF = document.getElementById('registrationPDF');
      const engagement = document.getElementsByClassName('engagement');
      const engagementPDF = document.getElementById('engagementPDF');
      const title = document.getElementsByClassName('er__main-title');
      const newTitle = document.getElementById("name");
      const element = document.getElementById("pdf");
      const svgFiles = document.querySelectorAll('.er__infobox, .fa-angle-up, .fa-angle-down');
      const buttons = document.getElementsByClassName('er__export-buttons');
      const fileName = this.$attrs.eventDetails.name + "_" + this.moment().format("MM-DD-YYYY");
      const noData = document.getElementById('noData');

      noData ? noData.style.paddingBottom = "1rem" : null;

      for (let i = 0; i < chartboxValue.length; i++) {
        chartboxValue[i].style.paddingBottom = "1rem";
      }

      for (let i = 0; i < chartboxName.length; i++) {
        chartboxName[i].style.paddingBottom = "1rem";
      }

      // registration.style.display = "none";

      // registrationPDF.style.display = "block";

      engagement[0] && (engagement[0].style.display = "none");

      engagementPDF && (engagementPDF.style.display = "block");

      title[0] && (title[0].style.display = "none");

      newTitle && (newTitle.style.display = "block");

      element && (element.style.backgroundColor= '#EEEFF1');

      for (let i = 0; i < svgFiles.length; i++) {

        svgFiles[i].style.display = "none";
      }

      buttons[0] && (buttons[0].style.display = "none");

      downloadPDF(element,fileName);

      engagement[0] && (engagement[0].style.display = "block");

      // registrationPDF.style.display = "none";

      // registration.style.display = "block";

      engagementPDF && (engagementPDF.style.display = "none");

      for (let i = 0; i < svgFiles.length; i++) {

        svgFiles[i].style.display = "var(--fa-display, inline-block)";
      }

      buttons[0] && (buttons[0].style.display = "flex");

      title[0] && (title[0].style.display = "block");

      newTitle && (newTitle.style.display = "none");

      for (let i = 0; i < chartboxValue.length; i++) {
        chartboxValue[i].style.paddingBottom = "0";
      }

      for (let i = 0; i < chartboxName.length; i++) {
        chartboxName[i].style.paddingBottom = "0";
      }

      noData ? noData.style.paddingBottom = "0.5rem" : null;

      setTimeout(() => {

        this.isDownload = false;
      }, 2500);

    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getEventReportStats: "statistics/getEventReportStats",
    })
  },
  computed: {

    finishedEvent() {

      return Date.now() > this.$attrs.eventDetails.endTime;
    },
    ...mapGetters({
      orgGuid: "app/orgGuid"
    })
  },
}
</script>

<style lang="scss" scoped>
.er {
  @apply
  max-w-7xl
  mx-auto
  flex
  flex-col
  flex-auto
  p-12;

  &__loader {
    @apply
    hidden;

    // &-app {
    //   top: -20px;
    //   right: 26px;
    //   bottom: 20px;
    //   left: -172px;
    // }
  }



  &__export-buttons {
    @apply
    flex;
  }

  &__title-block {
    @apply
    w-full
    mb-5
    flex
    justify-between
    items-center;
  }

  &__infobox {
    @apply text-black-300 ml-2;
  }

  &__main-title {
    @apply text-xl;
  }

  // *********************************************
  // Top statistics
  // *********************************************

  &__top-stats {
    @apply
    grid
    grid-cols-2
    lg:grid-cols-4
    gap-5;

    &-el {
      @apply
      bg-white
      pl-6
      py-7
      flex
      flex-col
      rounded
      shadow-card;
    }

    &-value {
      @apply text-2xl mb-1;
    }

    &-name {
      @apply text-blue-grey-600 font-normal;
    }
  }

  // *********************************************
  // Registrations and attendees
  // *********************************************


  &__chartbox {
    @apply mt-5 flex rounded bg-white overflow-hidden;

    &-rows-data {
      @apply
      pb-9
      w-96
      border-r
      border-black-50
      px-9;
    }

    &-rows-location {
      @apply
      w-1/2
      border-r
      border-black-50;

      th, td {
        min-width: 0px;

      }

    }

    &-main-stat {
      @apply
      flex
      flex-col
      items-center
      justify-center
      py-12;

      &-value {
        @apply text-5xl py-2 font-normal;
      }

      &-name {
        @apply text-blue-grey-600 font-normal;
      }
    }

    &-row {
      @apply
      py-6
      flex
      justify-between
      items-center
      border-b
      border-black-50;

      &:last-child {
        @apply border-none;
      }

      &-text {
        @apply flex items-center;
      }
    }

    &-icon {
      @apply
      text-2xl
      mr-3
      text-black-600;
    }

    &-name {
      @apply
      mr-2
      whitespace-nowrap
      text-blue-grey-600
      text-sm;
    }

    &-chart {
      @apply
      flex-auto
      flex
      flex-col
      pl-9
      pr-6
      py-6;

      &-top-bar {
        @apply
        mb-6
        flex
        justify-between
        items-center;
      }

      &-title {
        @apply text-lg whitespace-nowrap mr-2;
      }
    }
  }

}

</style>
