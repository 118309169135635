<template>

<section class="assigned-images-to-vod-component">

  <section v-if="stagedImages && stagedImages.length" class="flex flex-column flex-auto overflow-auto h-full">
    <ul class="list-reset">
      <li v-for="(img, index) in stagedImages" :key="index" class="flex justify-between py-2 cropped-item relative w-full">
        <section class="img-description-section flex w-full">
          <div
            :style="{ background:`url('${img.url}&q=80&f=jpeg') no-repeat center center / contain` }"
            class="thumb-image">
          </div>
          <aside class="flex-auto">
            <p class="mb-2 text-black-400 text-sm">{{ img.width }}x{{ img.height }} &#x2022; {{ img.variant }} &#x2022; {{ languagesTwoLetterConfig[img.lang] }} </p>

            <h6 class="truncate mb-2 text-black-900 text-sm">{{ img.title }}</h6>

            <p class="text-black-600 truncate text-sm">{{ img.description }}</p>
          </aside>
        </section>

        <aside class="img-ctrls flex items-top">

          <ioio-button
            type="secondary"
            variant="outline"
            size="small"
            @click="() => copyToClipboard(index)"
            class="mr-1 w-8 flex-none">
            <ioio-icon
              icon="fa-light fas-clone"
              class="w-3 h-3"
            />
          </ioio-button>
          <input
            class="copy-to-clipboard-input"
            type="text"
            :id="`image-url-${index}`"
            :value="img.url">

          <aside v-if="isEditable" class="flex">

            <ioio-button
              type="secondary"
              variant="outline"
              size="small"
              @click="() => openEditForStagedImg(index)"
              class="mr-1 w-8 flex-none">
              <ioio-icon
                icon="fas-pen"
                class="w-3 h-3"
              />
            </ioio-button>

            <ioio-button
              type="secondary"
              variant="outline"
              size="small"
              @click="() => removeStagedImg(index)"
              class="mr-1 w-8 flex-none">
              <ioio-icon
                icon="fa-trash-can"
                class="w-3 h-3"
              />
            </ioio-button>
          </aside>

        </aside>
      </li>
    </ul>
  </section>

  <h3 v-else>This Asset doesn't have any Images associated with it.</h3>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { languagesTwoLetterConfig } from "@utils/constants";
import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({
    languagesTwoLetterConfig
  }),
  props: {
    stagedImages: Array,
    openEditForStagedImg: Function,
    removeStagedImg: Function,
    isEditable: {

      type: Boolean,
      default: true
    }
  },
  methods: {

    copyToClipboard(index) {

      const elementId = `image-url-${index}`;

      copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">

.copy-to-clipboard-input {
  /**
  * Hide the visibility via z-index, since hiding it in
  * any other way will make the copy to clipboard not work.
  */
  position: fixed;
  z-index: -1;
}
</style>
