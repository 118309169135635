<template>
  <div class="syndication-actions-component relative">
    <button-component
      variant="minimal"
      size="size-l"
      class="enable-btn"
      shape="circle"
      @click.stop="toggleActionsVisible(true)"
      v-if="!isRequestPending"
    >
      <ioio-icon icon="fas-ellipsis" class="h-4"/>
    </button-component>
    <div v-else class="overflow-hidden mt2" style="width: 48px; height: 48px">
      <logo-loader-component width="20px" height="20px" class=""/>
    </div>
    <dropdown-component v-if="areActionsVisible" align="align-right" :onClose="() => toggleActionsVisible(false)">
      <ul class="list-reset m0">
        <li
          class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1"
          style="cursor: default">

          <delete-confirm-modal-component
            dialogWidth="500"
            dialogHeight="225"
            dialogOpenBtnLabel="Delete"
            dialogOpenBtnSize="size-xs"
            :isDialogOpenBtnVisible="true"
            delConfirmBtnLabel="Delete"
            :delConfirmLabel="`Are you sure you want to delete this Syndication List?`"
            :isDelConfirmBtnVisible="!isRequestPending"
            :onDeleteConfirm="() => deleteSyndicationList()"
          />
        </li>
      </ul>
    </dropdown-component>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({

    areActionsVisible: false
  }),
  props: {
    deleteSyndicationList: {
      type: Function,
      default: null
    },

    isRequestPending: {
      type: Boolean,
      default: false
    },
  },
  methods: {

    toggleActionsVisible(newVal) {

      this.areActionsVisible = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>



</style>
