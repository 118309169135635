/**
 * Filter groups [VOD, Playlists...]
 */

const _vodLibraryFiltering = (items, searchOpts) => {

  const {

    vodPrivacy,
    vodStatus,
    vodType,
    pageNum,
    perPageItems,
    urlSearchQuery,
  } = searchOpts;

  const filteredItems = items.filter(item => {

    if (vodStatus === "content" && (item.status === "archive" || item.status ===  "error")) {

      return false;
    }

    if (vodStatus && vodStatus !== "content" && item.status !== vodStatus) {

      return false;
    }

    if (vodType && item.meta && item.meta.type !== vodType) {

      return false;
    }

    if (vodPrivacy && vodPrivacy === 'privacy' && !item.meta.privacy) {

      return false;
    }

    if (vodPrivacy && vodPrivacy === 'public' && item.meta.privacy) {

      return false;
    }

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    // Hanlde possible missing or wrong type props with the try-catch
    try {

      if (urlSearchQuery && item.meta && !(

        ((item.meta.title &&
          item.meta.title.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.meta.description &&
          item.meta.description.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.meta.genre &&
          item.meta.genre.toLowerCase().indexOf(loweredSearchStr) >=
            0) ||
        (item.meta.group &&
          item.meta.group.toLowerCase().indexOf(loweredSearchStr) >=
            0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }
    } catch(e) {

      return false;
    }

    // If the item doesn't have any metadata, it is because of unhandled error in AWS.
    // Since there aren't any other properties, only try to mach such files by their guid
    if (urlSearchQuery && !item.meta && item.guid !== loweredSearchStr) {

      return false;
    }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;
    const endPaginationSelectionIndex = (pageIndex + 1) * perPageItems; // may not be needed

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _playlistLibraryFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery,
  } = searchOpts;

  const filteredItems = items.filter(item => {

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && !(

          ((item.name &&
            item.name.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
          (item.description &&
            item.description.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
          (item.title &&
            item.title.toLowerCase().indexOf(loweredSearchStr) >=
              0) ||
          (item.guid === loweredSearchStr)
    ))) {

      return false;
    }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _imagesLibraryFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery,
  } = searchOpts;

  const filteredItems = items.filter(item => {

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && item.meta && !(

        ((item.meta.title &&
          item.meta.title.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.meta.description &&
          item.meta.description.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.meta.genre &&
          item.meta.genre.toLowerCase().indexOf(loweredSearchStr) >=
            0) ||
        (item.meta.group &&
          item.meta.group.toLowerCase().indexOf(loweredSearchStr) >=
            0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _eventsLibraryFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery,
    upcommingEvents
  } = searchOpts;

  const filteredItems = items.filter(item => {

    const now = new Date().getTime();

    if (upcommingEvents && item.startTime < now) {

      return false;
    }

    if (!upcommingEvents && item.startTime >= now) {

      return false;
    }

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && !(

        ((item.name &&
          item.name.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.description &&
          item.description.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  if (upcommingEvents) {

    filteredItems.sort((a,b) => a.startTime - b.startTime);
  } else {

    filteredItems.sort((a,b) => b.startTime - a.startTime);
  }

  let filteredAndPaginatedItems = [ ...filteredItems ];

  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _eventAttendeesFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery
  } = searchOpts;

  const filteredItems = items.filter(item => {

    if (item.role !== 'attendee') {

      return false;
    }

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && !(

        ((item.name &&
          item.name.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _eventSpeakersFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery
  } = searchOpts;


  const filteredItems = items.filter(item => {

    if (item.role !== 'talent') {

      return false;
    }

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && !(

        ((item.name &&
          item.name.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};

const _eventSessionsFiltering = (items, searchOpts) => {

  const {

    pageNum,
    perPageItems,
    urlSearchQuery
  } = searchOpts;


  const filteredItems = items.filter(item => {

    const loweredSearchStr = urlSearchQuery && urlSearchQuery.toLowerCase();

    if (urlSearchQuery && !(

        ((item.name &&
          item.name.toLowerCase().indexOf(loweredSearchStr) >= 0) ||
        (item.guid === loweredSearchStr)

      ))) {

        return false;
      }

    // If all checks pass, the item should pass to the filtered list
    return true;
  });

  let filteredAndPaginatedItems = [ ...filteredItems ];


  if (pageNum) {

    const pageIndex = pageNum - 1;
    const startPaginationSelectionIndex = pageIndex * perPageItems;

    filteredAndPaginatedItems =
      filteredAndPaginatedItems.splice(startPaginationSelectionIndex, perPageItems);
  }

  return {
    filteredItems: filteredAndPaginatedItems,
    filteredItemsNotPaginated: filteredItems
  };
};


const _filterTypesMap = {

  vodLibrary: {

    func: _vodLibraryFiltering,
    opts: 'vodSearchOptions'
  },

  playlistLibrary: {

    func: _playlistLibraryFiltering,
    opts: 'playlistsSearchOptions'
  },

  imagesLibrary: {

    func: _imagesLibraryFiltering,
    opts: 'imagesSearchOptions'
  },

  eventsLibrary: {

    func: _eventsLibraryFiltering,
    opts: 'eventsSearchOptions'
  },


  eventAttendeesLibrary: {

    func: _eventAttendeesFiltering,
    opts: 'eventAttendeesSearchOptions'
  },

  eventSpeakersLibrary: {

    func: _eventSpeakersFiltering,
    opts: 'eventSpeakersSearchOptions'
  },

  eventSessionsLibrary: {

    func: _eventSessionsFiltering,
    opts: 'eventSessionsSearchOptions'
  },

};

/**
 * END Filter groups [VOD, Playlists...]
 */


/**
 * Common filter helpers
 */
// ... add here filter if-s, that are common (paginate, searchQuery)
// and use them in the specific filter groups methods


/**
 * Make pristine copies of the items to be filtered on the current view.
 * This action should be called in the component that will display the
 * to-be-filtered list when it is first present
 */
const stageForFiltering = ({ state, commit }, { items, division, addOriginalOrderIndex }) => {

  commit('SET_ORIGINAL_ITEMS', {
    items,
    division,
    // addOriginalOrderIndex
  });

  commit('SET_CLONED_ITEMS', {
    items,
    division,
    addOriginalOrderIndex
  });
};

const unstageFromFiltering = ({ state, commit }, division) => {

  commit('SET_ORIGINAL_ITEMS', { items: [], division });
  commit('SET_CLONED_ITEMS', { items: [], division });

  commit('SET_FILTERED_ITEMS', { items: [], division });
  commit('SET_FILTERED_ITEMS_NOT_PAGINATED', { items: [], division });


  commit('NULL_FILTERS_APPLIED', division);
};

const applyFiltering = ({ state, commit }, options) => {

  const { searchOpts, filterType } = options;

  const filterFunctionName = _filterTypesMap[filterType].func;
  const filterOptionsName = _filterTypesMap[filterType].opts;

  const combinedSearchOpts = {
    ...state[filterOptionsName],
    ...searchOpts
  };

  commit('SET_FILTERS_APPLIED', {

    filtersApplied: combinedSearchOpts,
    division: filterOptionsName
  });

  const filteredItemsData =
    filterFunctionName(state.clonedItems[filterType], combinedSearchOpts);

  const { filteredItems, filteredItemsNotPaginated } = filteredItemsData;

  commit('SET_FILTERED_ITEMS', { items: filteredItems, division: filterType });
  commit('SET_FILTERED_ITEMS_NOT_PAGINATED', { items: filteredItemsNotPaginated, division: filterType });
};

export {
  stageForFiltering,
  unstageFromFiltering,
  applyFiltering
}