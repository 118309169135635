import { deepClone } from '@utils/helpers';


const SET_ORIGINAL_ITEMS = (state, { items, division }) => {

  state.originalItems = {

    ...state.originalItems,
    [division]: deepClone(items)
  };
};

const SET_CLONED_ITEMS = (state, { items, division, addOriginalOrderIndex }) => {

  let clonedItems = deepClone(items);

  if (addOriginalOrderIndex) {

    clonedItems = clonedItems
      .map((item, index) => ({ ...item, _originalIndex: index }));
  }

  state.clonedItems = {

    ...state.clonedItems,
    [division]: clonedItems
  };
};

const SET_FILTERED_ITEMS = (state, { items, division }) => {

  state.filteredItems = {

    ...state.filteredItems,
    [division]: items
  };
};

const SET_FILTERED_ITEMS_NOT_PAGINATED = (state, { items, division }) => {

  state.filteredItemsNotPaginated = {

    ...state.filteredItemsNotPaginated,
    [division]: items,
  };
};

const SET_FILTERS_APPLIED = (state, options) => {

  const { filtersApplied, division } = options;

  state[division] = filtersApplied;
};

const NULL_FILTERS_APPLIED = (state, division) => {

  const divisionMap = {

    vodLibrary: {
      name: 'vodSearchOptions',
      initialState: {

        vodStatus: '',
        vodType: '',
        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    playlistLibrary: {
      name: 'playlistsSearchOptions',
      initialState: {

        vodStatus: 'complete',
        vodType: '',
        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    imagesLibrary: {
      name: 'imagesSearchOptions',
      initialState: {

        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    eventsLibrary: {
      name: 'eventsSearchOptions',
      initialState: {

        upcommingEvents: true,
        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    eventAttendeesLibrary: {
      name: 'eventAttendeesSearchOptions',
      initialState: {

        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    eventSpeakersLibrary: {
      name: 'eventSpeakersSearchOptions',
      initialState: {

        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },

    eventSessionsLibrary: {
      name: 'eventSessionsSearchOptions',
      initialState: {

        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },
  };

  const filtersName = divisionMap[division].name;

  state[filtersName] = divisionMap[division].initialState;
};


export {

  SET_ORIGINAL_ITEMS,
  SET_CLONED_ITEMS,
  SET_FILTERED_ITEMS,
  SET_FILTERED_ITEMS_NOT_PAGINATED,
  SET_FILTERS_APPLIED,
  NULL_FILTERS_APPLIED
}
