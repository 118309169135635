/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'privacy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" d="M21.217 3.023l-9-2a1.024 1.024 0 00-.434 0l-9 2A1 1 0 002 4v10a10 10 0 0020 0V4a1 1 0 00-.783-.977zM17 17a1 1 0 01-1 1H8a1 1 0 01-1-1v-5a1 1 0 011-1h1V9a3 3 0 016 0v2h1a1 1 0 011 1z"/><path pid="1" d="M12 8a1 1 0 00-1 1v2h2V9a1 1 0 00-1-1z"/></g>'
  }
})
