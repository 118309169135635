import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const state = {
  eventDetails: {},

  mixerSettings: null,

  isAssignSpeakersToEventModalVisible: false,

  isAssignImagesOpened: false,
  isOverlayBgImagesOpened: false,
  isOverlayImagesOpened: false,

  isAssignVideosModalOpened: false,

  isEventStreamOptionModalOpened: false,
  isEventStreamAssetsModalOpened: false,
  isEventStreamOverlaysModalOpened: false,
  isEventStreamChannelModalOpened: false,
  isEventStreamUrlModalOpened: false,
  isEventCoverImageModalOpened: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}