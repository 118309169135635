/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash-can': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M3.87 21.214A3.01 3.01 0 006.862 24h10.276a3.01 3.01 0 002.992-2.786L21.074 8H2.926z"/><path pid="1" data-color="color-2" d="M23 4h-6V2a2 2 0 00-2-2H9a2 2 0 00-2 2v2H1a1 1 0 000 2h22a1 1 0 000-2zM9 2h6v2H9z"/></g>'
  }
})
