/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '+1m': {
    width: 19,
    height: 7,
    viewBox: '0 0 19 7',
    data: '<path pid="0" d="M.81 3.913h1.701V2.122h.756v1.791h1.746v.648H3.267v1.8h-.756v-1.8H.81v-.648zM6.594 6.28h1.395V1.519l-1.54.477-.206-.531L8.196.592 8.835.7v5.58h1.25V7H6.595v-.72zm9.958-3.987c.522 0 .915.141 1.179.423.264.276.396.684.396 1.224V7h-.846V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.522.066-.756.198a1.456 1.456 0 00-.55.531c.007.054.01.138.01.252V7h-.846V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.52.066-.747.198a1.55 1.55 0 00-.558.522V7h-.846V2.374h.693l.09.63a1.92 1.92 0 011.53-.711c.726 0 1.203.27 1.43.81.409-.54.943-.81 1.603-.81z" _fill="#000"/>'
  }
})
