/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hierarchy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#fca311"><path pid="0" _fill="#002c65" d="M14 18h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM23 18h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM5 18H1a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1z"/><path pid="1" data-color="color-2" d="M4 13h7v3h2v-3h7v3h2v-4a1 1 0 00-1-1h-8V8h-2v3H3a1 1 0 00-1 1v4h2v-3z"/><circle pid="2" _fill="#002c65" cx="12" cy="3" r="3"/></g>'
  }
})
