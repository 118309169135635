/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '-1m': {
    width: 17,
    height: 7,
    viewBox: '0 0 17 7',
    data: '<path pid="0" d="M.45 3.769h3.303v.72H.45v-.72zM5.038 6.28h1.395V1.519l-1.539.477-.207-.531L6.64.592 7.28.7v5.58h1.25V7H5.038v-.72zm9.958-3.987c.522 0 .915.141 1.18.423.263.276.395.684.395 1.224V7h-.846V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.522.066-.756.198a1.456 1.456 0 00-.549.531c.006.054.01.138.01.252V7h-.847V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.519.066-.747.198a1.55 1.55 0 00-.558.522V7H9.65V2.374h.693l.09.63a1.92 1.92 0 011.53-.711c.726 0 1.203.27 1.431.81.408-.54.942-.81 1.602-.81z" _fill="#000"/>'
  }
})
