import sdk from "@sdk";
import jwtDecode from 'jwt-decode'
import { SOCKET } from '@configs/apiUrls.config.js';

import { deepClone } from '@utils/helpers';

import Amplify from 'aws-amplify'

function _hasTokenExpired(expTimestamp) {

  return expTimestamp * 1000 < new Date().getTime();
}

function _handleRequestErr({ commit, dispatch }, err) {

  if (!err.handleSoft && err.code === 401) {

    Amplify.Auth.signOut().then(() => {

      commit('FORCE_LOGOUT');
    });

    return;
  }

  /**
   * Throw the error again, so that it can be catched,
   * if needed, where he call is made
   */
  throw err;
}

const makeRequest = ({ state, commit, dispatch }, { type, baseUrlType, params, payload }) => {

  /**
   * If baseUrlType is provided, the method will try to match it from the store.
   * It will default to BASE_BROKER_URL, but can also be
   * BASE_LIVE_URL, BASE_LIVE_ELEMENTAL_URL, BASE_IMAGE_URL or BASE_SYNDICATION_URL, BASE_EVENTS_URL.
   */

  const matchedRequest = sdk[type];
  const isAuthSuccessful = state.isAuthSuccessful;

  const urlDevision = baseUrlType ? baseUrlType : 'BASE_BROKER_URL';

  const baseUrl = state[urlDevision] ? state[urlDevision] : '';

  if (!matchedRequest) {

    throw new Error(`No such endpoint defined in apiSDK: ${type} !`);
  }

  /**
   * The user is authenticated, so a check for a valid
   * token should be made before any request
   */
  if (isAuthSuccessful) {

    return Amplify.Auth.currentSession().then(session => {

      const token = session.idToken.jwtToken;

      return matchedRequest({ baseUrl, token, params, payload })
        .catch(err => _handleRequestErr({ commit, dispatch }, err));
    });
  }
};

const makeUnauthRequest = ({ state, commit, dispatch }, { type, params }) => {

  const matchedRequest = sdk[type];

  if (!matchedRequest) {

    throw new Error(`No such endpoint defined in apiSDK: ${type} !`);
  }

  return matchedRequest(params)
    .catch(err => _handleRequestErr({ commit, dispatch }, err));
};

const makeExternalApiRequest = ({ state, commit, dispatch }, { requestData, params, payload }) => {

  const { type, baseUrl, token } = requestData;

  const matchedRequest = sdk[type];

  if (!matchedRequest) {

    throw new Error(`No such endpoint defined in apiSDK: ${type} !`);
  }

  return matchedRequest({ baseUrl, token, params, payload })
    .catch(err => _handleRequestErr({ commit, dispatch }, { err, handleSoft: true }));
};
// const displayNotification = ({ state, commit, dispatch }, notification) => {

  // commit('ADD_NOTIFICATION', {
  //   ...notification,
  //   timeoutId: setTimeout(() => commit('CLOSE_NOTIFICATION', notification.type), 8000)
  // });

//   console.log('action: displayNotification triggered with: ',notification)
// };

const amplifyAuthorizeUser = ({ state, commit, dispatch }, token) => {

  const decodedToken = jwtDecode(token);

  commit('LOGIN_SUCCESS');

  const organizationGuid = decodedToken['custom:Organization'];

  commit('SET_ORG_GUID', organizationGuid);

  /**
   * The token is for the master organization, present the option
   * to select an organization from the /admin/manage-organizations page.
   * Get it's deployments and permissions after the choice is made.
   */
  if (organizationGuid === '8381a5f2-fb11-4900-99d6-ee8fcdd44d00') {

    commit('SET_IS_ADMIN_USER');
  }

  return dispatch('getUserData', decodedToken);
};

const getTokenPermissions = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
    type: 'getTokenPermissions',
    params
  })
  .then(response => {

    // Null possible stored deployments data (BASE_URLs) from `Login As` or other edge cases
    const possibleCurrentDeployments = state.deploymentTypesArr;

    possibleCurrentDeployments.forEach(deploymentName => {

      // Found a supported deployment, trigger it's setup action
      if (state.deploymentTypesRequiringAdditionalSetup[deploymentName]) {

        dispatch(state.deploymentTypesRequiringAdditionalSetup[deploymentName].action, { baseUrl: '' });
      }
    });

    const deployments = response.deployments;

    deployments.forEach(deployment => {

      // Found a supported deployment, trigger it's setup action
      if (state.deploymentTypesRequiringAdditionalSetup[deployment.type]) {

        dispatch(state.deploymentTypesRequiringAdditionalSetup[deployment.type].action, deployment);
      }
    });

    commit('SET_USER_PERMISSIONS', deployments);

    commit('SET_AVAILABLE_DEPLOYMENTS', deployments);

    const socketParams =
      state.organizationSelected &&
      state.organizationSelected.guid ? {
        organization: state.organizationSelected.guid
      } : null;

    dispatch('setupSocketChannel', socketParams);
  });
};

const setupBrokerDeployment = ({ state, commit, dispatch }, brokerDeployment) => {

  commit('SET_BASE_BROKER_URL', brokerDeployment.baseUrl);
};

const setupLiveDeployment = ({ state, commit, dispatch }, liveDeployment) => {

  commit('SET_BASE_LIVE_URL', liveDeployment.baseUrl);
};

const setupLiveElementalDeployment = ({ state, commit, dispatch }, liveElementalDeployment) => {

  commit('SET_BASE_LIVE_ELEMENTAL_URL', liveElementalDeployment.baseUrl);
};

const setupImageDeployment = ({ state, commit, dispatch }, imageDeployment) => {

  commit('SET_BASE_IMAGE_URL', imageDeployment.baseUrl);
};

const setupSyndicationDeployment = ({ state, commit, dispatch }, syndicationDeployment) => {

  commit('SET_BASE_SYNDICATION_URL', syndicationDeployment.baseUrl);
};

const setupIngestDeployment = ({ state, commit, dispatch }, ingestDeployment) => {

  commit('SET_BASE_INGEST_URL', ingestDeployment.baseUrl);
};

const setupEventsDeployment = ({ state, commit, dispatch }, eventsDeployment) => {

  commit('SET_BASE_EVENTS_URL', eventsDeployment.baseUrl);
};

const setupSocketChannel = ({ state, rootState, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
    type: 'getSocketToken',
    params
  })
  .then(wsToken => {

    console.log('RENEW socket token', wsToken, new Date());

    // Check and clear old pings setup if any
    dispatch('clearSocketAndPings');

    state.NOTIFICATION_SOCKET = new WebSocket(`${SOCKET.url}?token=${wsToken}`);

    dispatch('setupSocketChannelPings');

    state.NOTIFICATION_SOCKET.onclose = function(msg) {

      console.log('NOTIFICATION_SOCKET closing', msg, new Date())
      /**
       * Prevent the logic for automatic reconnect from triggering
       */
      if (!state.isAuthSuccessful) {

        return;
      }

      const socketParams =
        state.organizationSelected &&
        state.organizationSelected.guid ? {
          organization: state.organizationSelected.guid
        } : null;

      dispatch('setupSocketChannel', socketParams)
        .then(() => {

          /**
           * Check wheter a channel was locked when the connection broke
           * and notify the subscribers through the newly setup connection.
           */
          const channelsMeta = rootState.channelManager.channelsMeta.selected;

          if (channelsMeta.guid &&
            rootState.channelManager.firstAffectedTimestamp) {

              const brokerDeployment =
                state.availableDeployments.find(d => d.type === 'broker');

              state.NOTIFICATION_SOCKET.onopen = () => {

                state.NOTIFICATION_SOCKET.send(JSON.stringify({

                  action:"channel_lock",
                  deployment: brokerDeployment.guid,
                  data: {

                    channelGuid: channelsMeta.guid
                  }
                }));
              };
          }
        });
    };

    state.NOTIFICATION_SOCKET.onmessage = function(msg) {
      // TODO: refactor the onmessage handler to support multiple message types, possibly defined in a config

      const parsedMsg = JSON.parse(msg.data);

      const msgPayload = parsedMsg.data;

      console.log('NOTIFICATION socket msg type, data ', parsedMsg.type, msgPayload);

      commit('app/ADD_MSG_TO_MSG_BUS', parsedMsg, {
        root: true
      });


      switch (parsedMsg.type) {

        case 'captions_status_change':
          console.log('captions_status_change', msgPayload);

          if (msgPayload.status === 'success') {

            state.toastCtrl.info(`${msgPayload.file} was converted successfully.`);
          }
          break;

        case 'translation_job_status_change':
          console.log('translation_job_status_change', msgPayload);

          if (msgPayload.jobStatus === 'SUBMITTED') {

            state.toastCtrl.info(`Translation job submitted successfully.`);
          }

          if (msgPayload.jobStatus === 'IN_PROGRESS') {

            state.toastCtrl.info(`Translation job started.`);
          }

          if (msgPayload.jobStatus === 'COMPLETED') {

            state.toastCtrl.info(`Translation job completed successfully.`);
          }

          if (msgPayload.jobStatus === 'ERROR') {

            state.toastCtrl.info(`Translation job failed.`);
          }
          break;

        case 'vod_status_change':

          if (msgPayload.status === 'ingest' || msgPayload.status === 'encode-later') {

            // The new msg is for an asset, that the current user has submitted
            if (state.pendingUploadAssetKeys[msgPayload.srcVideo]) {

              const pendingAssetUpdateData = {
                key: msgPayload.srcVideo,
                isPending: false
              };


              commit('app/LOG_WORKED_ON_ITEM', {
                title: msgPayload.meta.title,
                type: 'Video',
                loggedAt: new Date().getTime(),
                url: `/vod/library?id=${msgPayload.guid}`
              }, {
                root: true
              });

              commit('app/SET_PENDING_UP_ASSET_KEY', pendingAssetUpdateData, {
                root: true
              });
            }

            commit('channelManager/ADD_VOD_LIST_ITEM', msgPayload, {
              root: true
            });

            if (msgPayload.status === 'ingest') {

              state.toastCtrl.info(`We started processing ${msgPayload.meta.title}. Please stand by…`);

            } else {
              // TODO: possibly add a different msg notification?
            }

          } else {

            if (msgPayload.status === 'error') {
              state.toastCtrl.error('There was a problem processing your VOD');

              return;
            }

            commit('channelManager/UPDATE_VOD_LIST_ITEM', msgPayload, {
              root: true
            });

            state.toastCtrl.info(`${msgPayload.meta.title} was processed successfully.`);

            const singleSourcePlayed = rootState.channelManager.singleSourcePlayed;

            if (singleSourcePlayed &&
              singleSourcePlayed.guid === msgPayload.guid) {

              commit('channelManager/SET_SINGLE_SOURCE_PLAYED', msgPayload, {
                root: true
              });
            }
          }
          break;

        case 'vod_proxy_status_change':

          commit('channelManager/UPDATE_VOD_LIST_ITEM', msgPayload, {
            root: true
          });

          // This info can optionally be shown!
          // state.toastCtrl.info(`Proxy url for ${msgPayload.meta.title} was generated successfully.`);

          const singleSourcePlayed = rootState.channelManager.singleSourcePlayed;

          if (singleSourcePlayed &&
            singleSourcePlayed.guid === msgPayload.guid) {

            commit('channelManager/SET_SINGLE_SOURCE_PLAYED', msgPayload, {
              root: true
            });
          }

          break;

        case 'program_lock':

            commit('channelManager/TOGGLE_CHANNEL_LOCKED', {
              guid: msgPayload.channelGuid,
              isLocked: true
            }, {
              root: true
            });

            state.toastCtrl.info(msgPayload.message);
          break;

        case 'program_unlock':

            commit('channelManager/TOGGLE_CHANNEL_LOCKED', {
              guid: msgPayload.channelGuid,
              isLocked: false
            }, {
              root: true
            });

            state.toastCtrl.info(msgPayload.message);
          break;

        case 'source_puller_status_change':

            if (msgPayload.status === 'running') {

              state.toastCtrl.info(`We started processing archive ${msgPayload.guid}. Please stand by…`);

            } else if (msgPayload.status === 'completed') {

              state.toastCtrl.info(`${msgPayload.guid} was processed successfully.`);

            } else if (msgPayload.status === 'error') {

              state.toastCtrl.error('There was a problem processing your archive.');

              return;
            }
          break;


        case 'vod_clipping_status_change':

          if (msgPayload.status === 'clipping') {

            state.toastCtrl.info(`We started clipping VOD ${msgPayload.guid}. Please stand by…`);

          } else if (msgPayload.status === 'complete') {

            state.toastCtrl.info(`${msgPayload.guid} was processed successfully.`);

          } else if (msgPayload.status === 'error') {

            state.toastCtrl.error('There was a problem processing your VOD.');

            return;
          }
          break;


        /**
         * LIVE specific cases
         *
         * Since some updates require HTTP request to be made, they sould
         * only be applied to relevant pages (if the user is currently on one).
         */
        case 'channel_status_changed':
        case 'CHANNEL_STATUS_CHANGED':

          var toastType = msgPayload.status === 'RUNNING' ?
            'success' : 'info';

          state.toastCtrl[toastType](msgPayload.message);

          commit('live/SET_LATEST_CHANGED_WORKFLOW_ID', msgPayload.resourceId, {
            root: true
          });

          break;


        case 'workflow_deleted':
        case 'WORKFLOW_DELETED':
        case 'workflow_created':
        case 'WORKFLOW_CREATED':

          state.toastCtrl.success(msgPayload.message);

          commit('live/SET_LATEST_CHANGED_WORKFLOW_ID', msgPayload.resourceId, {
            root: true
          });

          break;


        case 'workflow_create_failed':
        case 'workflow_delete_failed':
        case 'WORKFLOW_DELETE_FAILED':
        case 'WORKFLOW_CREATE_FAILED':

          state.toastCtrl.error(msgPayload.message);

          commit('live/SET_LATEST_CHANGED_WORKFLOW_ID', msgPayload.resourceId, {
            root: true
          });

          break;


        case 'channel_alert_set':
        case 'channel_alert_cleared':
        case 'CHANNEL_ALERT_CLEARED':
        case 'CHANNEL_ALERT_SET':

          commit('live/SET_LATEST_CHANGED_WORKFLOW_ID', msgPayload.resourceId, {
            root: true
          });
          break;



        case 'new_image':

          // The new msg is for an asset, that the current user has submitted
          if (state.pendingUploadAssetKeys[msgPayload.originalFileName]) {

            const updateData = {
              key: msgPayload.originalFileName,
              isPending: false
            };

            commit('app/LOG_WORKED_ON_ITEM', {
              title: msgPayload.meta.title,
              type: 'Image',
              loggedAt: new Date().getTime(),
              url: `/images-library?id=${msgPayload.guid}`
            }, {
              root: true
            });

            commit('app/SET_PENDING_UP_ASSET_KEY', updateData, {
              root: true
            });
          }
          break;

        default:
          break;
      }
    };
  });
};

const setupSocketChannelPings = ({ state, commit, dispatch }) => {

  // Check and clear old pings setup if any
  dispatch('clearSocketAndPings');

  const socketParams =
    state.organizationSelected &&
    state.organizationSelected.guid ? {
      organization: state.organizationSelected.guid
    } : null;

  // state.socketReconnectInterval =
  //   setInterval(() => dispatch('setupSocketChannel', socketParams), 7200000);


  state.socketPingInterval =
    setInterval(() => state.NOTIFICATION_SOCKET.send(JSON.stringify({
      action: 'ping'
    })), 30000);
};

const clearSocketAndPings = ({ state, commit, dispatch }) => {

  if (state.socketPingInterval && state.NOTIFICATION_SOCKET) {

    /**
     * Clear the onclose handler, since it contains logic
     * to refresh the conection, possibly bound to the
     * previously setup socket and selected organization
     */
    state.NOTIFICATION_SOCKET.onclose = null;

    state.NOTIFICATION_SOCKET.close();
    clearInterval(state.socketPingInterval);
    // clearInterval(state.socketReconnectInterval);

    state.NOTIFICATION_SOCKET = null;
    state.socketPingInterval = null;
    // state.socketReconnectInterval = null;
  }
};

const getMultipartUploadConfigs = ({ state, commit, dispatch }) => {

  return dispatch('makeRequest', {
    type: 'getMultipartUploadConfigs'
  })
  .then(response => {

    commit('STORE_UPLOAD_CONFIG', response);
  });
};

const getUserData = ({ state, commit, dispatch }, userDataFromToken) => {

  return dispatch('makeRequest', {
    type: 'getUserData'
  })
  .then(response => {

    // combine the data from the token with the one from the request,
    // since neither of them contains all needed data for the user
    let parsedDataFromToken = { };

    if (userDataFromToken) {

      parsedDataFromToken = {

        organization: userDataFromToken['custom:Organization'],
        guid: userDataFromToken.sub,
        roleGuid: userDataFromToken['custom:Role'],
      };
    };

    commit('STORE_USER_DATA', {

      ...parsedDataFromToken,
      ...response
    });
  });
};

const getGitlabBranches = ({ state, commit, dispatch }, params) => {

  const { requestData, data } = params;

  return dispatch('makeExternalApiRequest', {
    requestData: {
      ...requestData,
      type: 'getGitlabBranches',
    },
    payload: data
  });
};

const getGitlabTags = ({ state, commit, dispatch }, params) => {

  const { requestData, data } = params;

  return dispatch('makeExternalApiRequest', {
    requestData: {
      ...requestData,
      type: 'getGitlabTags',
    },
    payload: data
  });
};

const getGitlabTriggers = ({ state, commit, dispatch }, params) => {

  const { requestData, data } = params;

  return dispatch('makeExternalApiRequest', {
    requestData: {
      ...requestData,
      type: 'getGitlabTriggers',
    },
    payload: data
  });
};

const getGitlabJobs = ({ state, commit, dispatch }, params) => {

  const { requestData, data } = params;

  return dispatch('makeExternalApiRequest', {
    requestData: {
      ...requestData,
      type: 'getGitlabJobs',
    },
    payload: data
  });
};

const getDeploymentPipelines = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
    type: 'getDeploymentPipelines',
    params
  });
};

const getOrgDeploymentPipelines = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
    type: 'getOrgDeploymentPipelines',
    params
  });
};

const executeDeployScript = ({ state, commit, dispatch }, payload) => {

  const { requestData, data } = payload;

  return dispatch('makeExternalApiRequest', {
    requestData: {
      ...requestData,
      type: 'executeDeployScript',
    },
    payload: data
  });
};


/**
 * Organizations && deployments
 */

const getCognitoSettings = ({ state, commit, dispatch }, params) => {

  return dispatch('makeUnauthRequest', {
      type: 'getCognitoSettings',
      params
    }).then(settings => {

      state.cognitoSettings = {
        ...settings
      };

      return settings;
    });
};

const getAwsAccounts = ({ state, commit, dispatch }) => {

  return dispatch('makeRequest', {
      type: 'getAwsAccounts'
    });
};

const updateAwsAccount = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'updateAwsAccount',
      payload
    });
};

const deleteAwsAccount = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'deleteAwsAccount',
      payload
    });
};

const addAwsAccount = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'addAwsAccount',
      payload
    });
};

const getOrganizations = ({ state, commit, dispatch }) => {

  return dispatch('makeRequest', {
      type: 'getOrganizations'
    })
    .then(organizations => {

      return {
        organizations
      }
    });
};

const getOrganizationDetails = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
      type: 'getOrganizationDetails',
      params
    });
};

const getOrganizationBasicDetails = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
      type: 'getOrganizationBasicDetails',
      params
    });
};


const getOrganizationSocketNotifications = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
      type: 'getOrganizationSocketNotifications',
      params
    });
};

const editOrganizationSocketNotifications = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'editOrganizationSocketNotifications',
      payload
    });
};

const getOrganizationNotificationSubscriptions = ({ state, commit, dispatch }, params) => {

  return dispatch('makeRequest', {
      type: 'getOrganizationNotificationSubscriptions',
      params
    });
};

const addOrganizationNotification = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'addOrganizationNotification',
      payload
    });
};

const deleteOrganizationNotification = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'deleteOrganizationNotification',
      payload
    });
};

const updateOrganization = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'updateOrganization',
      payload
    })
    .then(response => {

      console.log('org updated', response)

    });
};

const addOrganization = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'addOrganization',
      payload
    });
};

const deleteOrganization = ({ state, commit, dispatch }, guid) => {

  return dispatch('makeRequest', {
      type: 'deleteOrganization',
      payload: guid
    })
    .then(response => {

      console.log('org deleted', response)

    });
};

const getDeployments = ({ state, commit, dispatch }, orgGuid) => {

  return dispatch('makeRequest', {
      type: 'getDeployments',
      payload: orgGuid
    });
};

const updateDeployment = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'updateDeployment',
      payload
    });
};

const createDeployment = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'createDeployment',
      payload
    });
};

const deleteDeployment = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'deleteDeployment',
      payload
    })
    .then(response => {

      console.log('deleted Deployment', response)

    });
};

const getDeploymentDetails = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getDeploymentDetails',
      payload
    });
};

const getDeploymentSettings = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getDeploymentSettings',
      payload
    });
};

const getPossibleDeploymentPermissions = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getPossibleDeploymentPermissions',
      payload
    });
};

const syncRolePermissionsForDeployment = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'syncRolePermissionsForDeployment',
      payload
    });
};

const getPossibleRoles = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getPossibleRoles',
      payload
    });
};

const createRole = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'createRole',
      payload
    });
};

const updateRole = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'updateRole',
      payload
    });
};

const deleteRole = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'deleteRole',
      payload
    });
};

const storeNewUser = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'storeNewUser',
      payload
    });
};

const getUsersList = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getUsersList',
      payload
    });
};

const getUsersWhoUploadedVod = ({ state, commit, dispatch }) => {

  const params =  {
    type: {

      resource: 'source'
    },
    options: {
      size: 0, // We don't need sources in this request response, only interested in response.aggregations.hostEmail.buckets && response.aggregations.userEmail.buckets
      aggs: {
        userEmail: {
          terms: {
            field: 'meta.user.email.keyword',
            size: 1000
          }
        },
        hostEmail: {
          terms: {
            field: 'meta.hostEmail.keyword',
            size: 1000
          }
        }
      }
    }
  };

  return dispatch('makeRequest', {
    type: 'getUsersWhoUploadedVod',
    params
  })
  .then(resp => {
    const aggs = resp.aggregations;
    return dispatch('resolveUsersWhoUploadedVodEmails',aggs);

  });
};

const resolveUsersWhoUploadedVodEmails = ({dispatch},aggs) => {

  const hostBuckets = aggs.hostEmail.buckets || [];
  const userBuckets = aggs.userEmail.buckets || [];

  const emails = [];

  hostBuckets.forEach(email =>{
    emails.push({
      count: email.doc_count,
      label: email.key + ` (${email.doc_count})`,
      value: email.key
    });
  })

  userBuckets.forEach(email =>{
    let oldEmail = emails.find(e => e.value === email.key)
    if (oldEmail) {
      oldEmail.count =  oldEmail.count + email.doc_count;
      oldEmail.label =  oldEmail.value + ` (${oldEmail.count})`
    } else {
      emails.push({
        count: email.doc_count,
        label: email.key +` (${email.doc_count})`,
        value: email.key
      });
    }
  })

  return emails;
}

const getVodCategories = ({ state, commit, dispatch }) => {

  const params =  {
    type: {

      resource: 'source'
    },
    options: {
      size: 0, // We don't need sources in this request response, only interested in response.aggregations.categories.buckets
      aggs: {
        categories: {
          terms: {
            field: 'meta.categories.keyword',
            size: 1000
          }
        }
      }
    }
  };

  return dispatch('makeRequest', {
    type: 'getVodCategories',
    params
  })
  .then(resp => {
    const aggs = resp.aggregations;
    return dispatch('resolveCategories',aggs);
  });
};

const resolveCategories = ({dispatch},aggs) => {

  const categoriesBuckets = aggs.categories.buckets || [];

  const categories = [];

  for (let i = 0; i < categoriesBuckets.length; i++) {

    categories.push({
      label: categoriesBuckets[i].key+` (${ categoriesBuckets[i].doc_count})`,
      value: categoriesBuckets[i].key
    });
  }

  return categories;
}

const getVodTags = ({ state, commit, dispatch }) => {

  const params =  {
    type: {

      resource: 'source'
    },
    options: {
      size: 0, // We don't need sources in this request response, only interested in response.aggregations.tags.buckets
      aggs: {
        tags: {
          terms: {
            field: 'meta.tags.keyword',
            size: 1000
          }
        }
      }
    }
  };

  return dispatch('makeRequest', {
    type: 'getVodTags',
    params
  })
  .then(resp => {
    const aggs = resp.aggregations;
    return dispatch('resolveTags',aggs);
  });

};

const resolveTags = ({dispatch},aggs) => {

  const tagsBuckets = aggs.tags.buckets || [];

  const tags = [];

  for (let i = 0; i < tagsBuckets.length; i++) {

    tags.push({
      label: tagsBuckets[i].key+` (${ tagsBuckets[i].doc_count})`,
      value: tagsBuckets[i].key
    });
  }

  return tags;
}

const getVodGroups = ({ state, commit, dispatch }) => {

  const params =  {
    type: {

      resource: 'source'
    },
    options: {
      size: 0, // We don't need sources in this request response, only interested in response.aggregations.group.buckets
      aggs: {
        group: {
          terms: {
            field: 'meta.group.keyword',
            size: 1000
          }
        }
      }
    }
  };

  return dispatch('makeRequest', {
    type: 'getVodGroups',
    params
  })
  .then(resp => {
    const aggs = resp.aggregations;
    return dispatch('resolveGroups',aggs);
  });
};

const resolveGroups = ({dispatch},aggs) => {

  const groupsBuckets = aggs.group.buckets || [];

  const groups = [];

  for (let i = 0; i < groupsBuckets.length; i++) {

    groups.push({
      label: groupsBuckets[i].key+` (${groupsBuckets[i].doc_count})`,
      value: groupsBuckets[i].key
    });
  }
  return groups;
}

const getVodGenres = ({ state, commit, dispatch }) => {

  const params =  {
    type: {

      resource: 'source'
    },
    options: {
      size: 0, // We don't need sources in this request response, only interested in response.aggregations.genre.buckets
      aggs: {
        genre: {
          terms: {
            field: 'meta.genre.keyword',
            size: 1000
          }
        }
      }
    }
  };

  return dispatch('makeRequest', {
    type: 'getVodGenres',
    params
  })
  .then(resp => {
    const aggs = resp.aggregations;
    return dispatch('resolveGenres',aggs);
  });
};

const resolveGenres = ({dispatch},aggs) => {

  const genresBuckets = aggs.genre.buckets || [];

  const genres = [];

  for (let i = 0; i < genresBuckets.length; i++) {

    genres.push({
      label: genresBuckets[i].key+` (${genresBuckets[i].doc_count})`,
      value: genresBuckets[i].key
    });
  }

  return genres;
}

const updateUser = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'updateUser',
      payload
    });
};

const getSingleUserData= ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'getSingleUserData',
      payload
    });
};

const sendPassword= ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'sendPassword',
      payload
    });
};


const deleteUser = ({ state, commit, dispatch }, payload) => {

  return dispatch('makeRequest', {
      type: 'deleteUser',
      payload
    });
};

const getMetaFieldGroupAttachments = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getMetaFieldGroupAttachments',
      params
    }, {
      root: true
    });
};

const getMetaFieldsGroups = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getMetaFieldsGroups',
      params
    }, {
      root: true
    });
};

const getMetaFieldsByGroup = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getMetaFieldsByGroup',
      params
    }, {
      root: true
    });
};

const storeMetaFieldGroup = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'storeMetaFieldGroup',
      payload
    }, {
      root: true
    });
};

const updateMetaFieldGroup = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateMetaFieldGroup',
      payload
    }, {
      root: true
    });
};

const deleteMetaFieldGroup = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteMetaFieldGroup',
      payload
    }, {
      root: true
    });
};


const getActivityStream = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getActivityStream',
      params
    }, {
      root: true
    });
};


// Site builder configuration
const getSiteBuilderApps = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getSiteBuilderApps',
    params
  }, {
    root: true
  })
  .then((apps) => {

    state.siteBuilderApps = [...apps];

    commit('TOGGLE_ARE_SITEBUILDER_APPS_LOADED', true);
  })
  .catch(e => {

    state.siteBuilderApps = [];

    commit('TOGGLE_ARE_SITEBUILDER_APPS_LOADED', true);
  });
}

const getSiteBuilders = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getSiteBuilders',
    params
  }, {
    root: true
  });
}

const storeSiteBuilder = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeSiteBuilder',
    payload
  }, {
    root: true
  });
}

const updateSiteBuilder = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateSiteBuilder',
    payload
  }, {
    root: true
  });
}

const deleteSiteBuilder = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteSiteBuilder',
    params
  }, {
    root: true
  });
}


// Use for changes to the state, comming from btn clicks (not triggering a route change)
// This guard should be set and cleared in the component which state should be preserved.
// NOTE: in every possible component's function, where the guarded component's state
// will be cleared, a check should be made, if any present guard is set.
// If so, just call RAISE_REDIRECT_FLAG, true, so that the confirm modal is shown
const setupRedirectConfirmGuardLocal = ({ state, commit, dispatch }, payload) => {

  // Clear guard
  if (!payload) {

    state.activeConfirmDialog = null;

    commit('SET_REDIRECT_GUARD', false);

    commit('RAISE_REDIRECT_FLAG', false);

    return;
  }

  return new Promise((res, rej) => {

    const resolve = () => {

      // / null what is needed in the store
      state.activeConfirmDialog = null;

      commit('SET_REDIRECT_GUARD', false);

      commit('RAISE_REDIRECT_FLAG', false);

      payload.successFn();

      res();
    };

    const reject = () => {

      payload.rejFn && payload.rejFn();

      // / null what is needed in the store
      commit('RAISE_REDIRECT_FLAG', false);

      rej();
    };

    state.activeConfirmDialog = { resolve, reject };
  })
};


// use for actual route changes handling
const setupRedirectConfirmGuard = ({ state, commit, dispatch }, payload) => {

  // Clear guard
  if (!payload) {

    state.activeConfirmDialog = null;

    commit('SET_REDIRECT_GUARD', false);

    commit('RAISE_REDIRECT_FLAG', false);

    return;
  }

  return new Promise((res, rej) => {

    const resolve = () => {

      payload.successFn();
      // / null what is needed in the store
      state.activeConfirmDialog = null;

      commit('SET_REDIRECT_GUARD', false);

      commit('RAISE_REDIRECT_FLAG', false);

      res();
    };

    const reject = () => {

      payload.rejFn && payload.rejFn();

      // / null what is needed in the store
      commit('RAISE_REDIRECT_FLAG', false);

      rej();
    };

    state.activeConfirmDialog = { resolve, reject };
  })

};

export {
  makeRequest,
  makeUnauthRequest,
  makeExternalApiRequest,
  // displayNotification,
  amplifyAuthorizeUser,
  getTokenPermissions,
  setupBrokerDeployment,
  setupLiveDeployment,
  setupLiveElementalDeployment,
  setupImageDeployment,
  setupSyndicationDeployment,
  setupIngestDeployment,
  setupEventsDeployment,
  setupSocketChannel,
  setupSocketChannelPings,
  clearSocketAndPings,
  getMultipartUploadConfigs,
  getUserData,
  getGitlabBranches,
  getGitlabTags,
  getGitlabTriggers,
  getGitlabJobs,
  getDeploymentPipelines,
  getOrgDeploymentPipelines,
  executeDeployScript,

  getCognitoSettings,
  getAwsAccounts,
  updateAwsAccount,
  deleteAwsAccount,
  addAwsAccount,
  getOrganizations,
  getOrganizationDetails,
  getOrganizationBasicDetails,
  getOrganizationSocketNotifications,
  editOrganizationSocketNotifications,
  getOrganizationNotificationSubscriptions,
  addOrganizationNotification,
  deleteOrganizationNotification,
  updateOrganization,
  addOrganization,
  deleteOrganization,
  getDeployments,
  updateDeployment,
  createDeployment,
  deleteDeployment,
  getDeploymentDetails,
  getDeploymentSettings,
  getPossibleDeploymentPermissions,
  syncRolePermissionsForDeployment,
  getPossibleRoles,
  createRole,
  updateRole,
  deleteRole,
  storeNewUser,
  getUsersList,
  getUsersWhoUploadedVod,
  resolveUsersWhoUploadedVodEmails,
  getVodCategories,
  resolveCategories,
  getVodTags,
  resolveTags,
  getVodGroups,
  resolveGroups,
  getVodGenres,
  resolveGenres,
  updateUser,
  deleteUser,
  sendPassword,
  getSingleUserData,


  getMetaFieldGroupAttachments,
  getMetaFieldsGroups,
  getMetaFieldsByGroup,
  storeMetaFieldGroup,
  updateMetaFieldGroup,
  deleteMetaFieldGroup,

  getActivityStream,

  getSiteBuilderApps,
  getSiteBuilders,
  storeSiteBuilder,
  updateSiteBuilder,
  deleteSiteBuilder,


  setupRedirectConfirmGuardLocal,
  setupRedirectConfirmGuard,
}