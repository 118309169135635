/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 12c0 4.963-4.037 9-9 9s-9-4.037-9-9 4.037-9 9-9c1.989 0 3.873.65 5.43 1.833l-3.604 3.393 9.167.983L22.562 0l-3.655 3.442A10.915 10.915 0 0012 1C5.935 1 1 5.935 1 12s4.935 11 11 11 11-4.935 11-11h-2z" _fill="#5c7499"/>'
  }
})
