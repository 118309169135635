const possibleRegions = (state) => state.possibleRegions;
const blueprintsList = (state) => state.blueprintsList;
const selectedBlueprint = (state) => state.selectedBlueprint;
const selectedBlueprintModel = (state) => state.selectedBlueprintModel;
const selectedBlueprintMeta = (state) => state.selectedBlueprintMeta;
const workflowsList = (state) => state.workflowsList;
const workflowsListPaginationInfo = (state) => state.workflowsListPaginationInfo;

const isWorkflowsListLoading = (state) => state.isWorkflowsListLoading;
const isWorkflowsListFilteringApplied = (state) => state.isWorkflowsListFilteringApplied;
const selectedWorkflow = (state) => state.selectedWorkflow;
const selectedWorkflowChannelData = (state) => state.selectedWorkflowChannelData;
const selectedWorkflowChannelAlerts = (state) => state.selectedWorkflowChannelAlerts;
const selectedWorkflowChannelAlertsHistory = (state) => state.selectedWorkflowChannelAlertsHistory;
const selectedWorkflowChannelLogs = (state) => state.selectedWorkflowChannelLogs;
const selectedWorkflowChannelHealth = (state) => state.selectedWorkflowChannelHealth;
const areGeneralDetailsLoading = (state) => state.areGeneralDetailsLoading;
const areChannelDetailsLoading = (state) => state.areChannelDetailsLoading;
const areAlertsCurrentlyLoading = (state) => state.areAlertsCurrentlyLoading;
const areChannelLogsLoading = (state) => state.areChannelLogsLoading;
const selectedWorkflowForPreview = (state) => state.selectedWorkflowForPreview;
const latestChangedWorkflowId = (state) => state.latestChangedWorkflowId;



export {

  possibleRegions,
  blueprintsList,
  selectedBlueprint,
  selectedBlueprintModel,
  selectedBlueprintMeta,
  workflowsList,
  workflowsListPaginationInfo,
  isWorkflowsListLoading,
  isWorkflowsListFilteringApplied,
  selectedWorkflow,
  selectedWorkflowChannelData,
  selectedWorkflowChannelAlerts,
  selectedWorkflowChannelAlertsHistory,
  selectedWorkflowChannelLogs,
  selectedWorkflowChannelHealth,
  areGeneralDetailsLoading,
  areChannelDetailsLoading,
  areAlertsCurrentlyLoading,
  areChannelLogsLoading,
  selectedWorkflowForPreview,
  latestChangedWorkflowId,
}