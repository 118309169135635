/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'REV': {
    width: 34,
    height: 15,
    viewBox: '0 0 36 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.664 1.456C22.262.553 18.052.019 13.492.019c-1.27 0-2.513.042-3.72.121 5.99.161 11.26 1.246 14.703 2.857.384-.524.78-1.037 1.189-1.541zm8.283 5.883c0 4.043-9.158 7.32-20.455 7.32-.522 0-1.04-.007-1.552-.021 9.52-.627 16.733-3.61 16.733-7.199 0-.72-.29-1.416-.833-2.074.579-.904 1.125-1.788 1.63-2.596 2.8 1.252 4.477 2.841 4.477 4.57z" _fill="#C5D1CF"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.433 3.365c.063-.407.11-.517.755-.517.456 0 .597.314.597.8 0 1.314-.55 1.846-1.273 1.846-.284 0-.362-.094-.362-.298 0-.062.016-.14.032-.234l.25-1.597zm-.676 4.587c.048-.25.11-.344.362-.344.283 0 .346.093.503.688l.66 2.52c.063.282.362.47 1.178.47.63 0 2.17-.141 2.17-1.111 0-.142-.047-.298-.078-.423l-.975-2.614a1.678 1.678 0 01-.126-.58c0-.266.157-.438.487-.673.975-.704 1.321-1.55 1.321-2.567 0-2.129-1.43-2.74-3.364-2.74H4.01c-1.902 0-2.484.439-2.595 1.19L.031 10.52a1.766 1.766 0 00-.031.25c0 .439.676.517 1.54.517.913 0 1.698-.094 1.777-.595l.44-2.74zm10.148-3.163c.456 0 .676.188.676.61 0 .565-.19.909-.723.909h-.63c-.22 0-.345-.062-.345-.235 0-.313.094-.657.236-.877.173-.266.409-.407.786-.407zm.911 3.084c2.06-.156 2.641-.813 2.641-2.724 0-1.659-1.131-2.457-3.426-2.457-1.415 0-2.5.344-3.27 1.174-1.038 1.111-1.352 3.193-1.352 4.54 0 2.035 1.304 2.974 3.867 2.974 1.383 0 2.264-.157 2.64-.423.315-.219.598-.673.598-1.58 0-.565-.424-.658-.707-.596a9.292 9.292 0 01-1.87.188c-.975 0-1.306-.157-1.306-.517 0-.156.016-.25.063-.313.063-.078.173-.125.378-.14l1.744-.126zm14.159-5.32c-.843 1.295-1.926 3.144-3.12 4.837-.323.458-.659.907-1.008 1.345-1.953 2.435-1.938 2.608-4.585 2.629-3.181.024-2.192-1.948-2.075-3.596.079-1.102.44-2.206.552-2.982.119-.825.417-1.865 1.855-1.756.404.03 1.63.484 1.47 1.22-.315 1.457-.606 4.146-.638 4.195 0 0 3.242-5.042 4.674-6.468.683-.68 4.404-1.78 2.875.575z" _fill="#828282"/>'
  }
})
