/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Previous': {
    width: 30,
    height: 20,
    viewBox: '0 0 30 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.72 1.304c.169-.097.361-.148.557-.148.61 0 1.105.484 1.105 1.081v5.387l11.08-6.32c.169-.097.36-.148.556-.148.61 0 1.105.484 1.105 1.081v15.13c0 .192-.052.38-.15.545-.308.516-.984.69-1.511.389l-11.08-6.32v5.386c0 .192-.052.38-.15.545-.308.516-.985.69-1.512.389l-11.08-6.32v4.647a1.82 1.82 0 11-3.64 0V2.977a1.82 1.82 0 113.64 0v4.647l11.08-6.32z" _fill="#4F4F4F"/>'
  }
})
