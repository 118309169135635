/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ctrl-backward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.433 3.099a1 1 0 00-1.058.12L13 10.719V4a.999.999 0 00-1.625-.781l-10 8a1 1 0 000 1.562l10 8a.998.998 0 001.058.12A.999.999 0 0013 20v-6.719l9.375 7.5a.998.998 0 001.058.12A.999.999 0 0024 20V4a.999.999 0 00-.567-.901z" _fill="#002c65"/>'
  }
})
