<template>
<section class="unschedule-channel-pause-modal-component">

  <ioio-button
    v-if="isDialogOpenBtnVisible"
    :type="dialogOpenBtnVariant"
    :size="dialogOpenBtnSize"
    class="dialog-open-btn"
    :class="dialogOpenBtnClass"
    @click.stop.prevent="() => openActionModal()"
  >{{ dialogOpenBtnLabel }}
  </ioio-button>

  <ioio-modal
    :show.sync="isModalOpened"
    size="small"
    externalClasses="unschedule-channel-pause-modal-component">

    <template slot="header">

      <div class="">Manage pause/resume schedule</div>

    </template>

    <section
      class="flex flex-column my-8 pause-list"
      v-if="!isLoadingIndicatorVisible"
    >

      <section
        v-for="(pause, index) in selectedWorkflowChannelData.pauses"
        :key="pause.id"
      >

        <section
          class="flex justify-between mb-4"
        >
          <aside class="flex">

            <div style="min-width: 100px;">

              <ioio-label
                :text="pause.unpause ? 'Resume' : 'Pause'"
                :type="pause.unpause ? 'success' : 'warning'"
                size="small"
                class="mr-2"
              />
            </div>

            <readable-time-component
              :timestamp="pause.time"
            />
          </aside>

          <ioio-button
            type="danger"
            variant="outline"
            size="small"
            @click="() => triggerDeletePauseForChannel(index)"
            class=""

            v-if="pause.status === 'READY'"
          >
            Delete
          </ioio-button>

          <ioio-label
            :text="pause.status"
            size="small"
            v-else
          />

        </section>

      </section>

      <p v-if="!selectedWorkflowChannelData.pauses?.length">No pauses found</p>
    </section>


    <div v-else class="overflow-hidden">
      <ioio-loader isShown />
    </div>

    <footer class="flex items-center justify-end">

      <ioio-button
        @click.stop="closeActionModal"
        class=""
        type="secondary"
        variant="outline"
      >Close</ioio-button>

    </footer>
  </ioio-modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isModalOpened: false,
    pauseTime: '',
    isLoadingIndicatorVisible: false
  }),
  props: {

    // NOTE: Needed when there are more the one modals on a page!
    // If there are more than one modals with the same name on a given page,
    // they will all open up at once, leading to unexpected behavior.
    dialogId: {
      // type: String,
      default: '1',
    },

    dialogOpenBtnLabel: {
      type: String,
      default: 'Action',
    },

    dialogOpenBtnVariant: {
      type: String,
      default: 'default',
    },

    dialogOpenBtnSize: {
      type: String,
      default: 'size-xs',
    },

    dialogWidth: {
      type: String,
      default: '500px',
    },

    dialogHeight: {
      type: String,
      default: 'auto',
    },

    dialogOpenBtnClass: {
      type: String
    },

    isDialogOpenBtnVisible: {
      type: Boolean
    },

    actionConfirmLabel: {

      type: String,
      default: 'Are you sure you want to action?'
    },

    actionConfirmSecondaryLabel: {

      type: String
    },

    actionConfirmBtnLabel: {
      type: String,
      default: 'Confirm',
    },

    actionConfirmBtnVariant: {
      type: String,
      default: 'default',
    },

    isActionConfirmBtnVisible: {
      type: Boolean
    },

    onActionConfirm: {
      type: Function
    },

    onActionReject: {
      type: Function
    },

    selectedWorkflow: {
      type: Object
    },

    selectedWorkflowChannelData: {
      type: Object
    }
  },

  mounted() {

    window.wa = this;
  },
  methods: {

    openActionModal() {

      this.isModalOpened = true;
    },

    closeActionModal() {

      this.onActionReject && this.onActionReject();
      this.isModalOpened = false;
    },

    confirmAction() {

      this.isModalOpened = false;
      this.onActionConfirm && this.onActionConfirm({
        pauseRequestTime: this.pauseTime,
        id: this.selectedWorkflow.id
      });
    },

    triggerDeletePauseForChannel(pauseIndex) {
      //@Stef details on implementation

      const payload = {

        workflowId: this.selectedWorkflow.id,
        pauseId: this.selectedWorkflowChannelData.pauses[pauseIndex].id
      };

      this.isLoadingIndicatorVisible = true;

      this.deletePauseForChannel(payload)
        .then(() => {

          const updatedPauses = [...this.selectedWorkflowChannelData.pauses];

          updatedPauses.splice(pauseIndex, 1);

          console.log(updatedPauses);

          this.setSelectedWorkflowChannelData({

            ...this.selectedWorkflowChannelData,
            pauses: updatedPauses
          });
          // Inline remove the pause if no socket msg is received and it doesnt happen on its own
        }).finally(() => {

          this.isLoadingIndicatorVisible = false;
        });
    },
    ...mapMutations({

      setSelectedWorkflowChannelData: "live/SET_SELECTED_WORKFLOW_CHANNEL_DATA",
    }),
    ...mapActions({
      deletePauseForChannel: 'live/deletePauseForChannel',
    }),
  }
}
</script>

<style lang="scss">



.unschedule-channel-pause-modal-component {

  .pause-list {

    max-height: 500px;
    overflow: auto;
  }
  .ds-modal__dialog {

  }

  /* Needed so that it aligns with adjascent elements outside the component */
  .dialog-open-btn {

    float: left;
  }

}

</style>
